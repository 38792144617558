import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { ModalState } from 'state/modal';
import { closeModal, openModal } from 'state/modal/actions';
import { RootState } from 'state/root';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import { archiveApiKey, getApiKeys } from 'state/apiKeys/actions';
import { ApiKeysActionName } from 'state/apiKeys/types';
import config from 'config';

type deleteParams = {
  id: string;
  key: string;
};

interface ConfirmApiKeyDeletionParams {
  apiKeyData?: deleteParams;
  limitedAccessForm?: deleteParams;
}

const ConfirmApiKeyDeletion = () => {
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [ApiKeysActionName.ARCHIVE_API_KEY]);
  const error = errorSelector(requests, [ApiKeysActionName.ARCHIVE_API_KEY]);

  const confirmState = useSelector(
    ({ modal }: { modal: ModalState<ConfirmApiKeyDeletionParams> }) => modal.params
  );

  const key = confirmState?.apiKeyData?.key ?? confirmState?.limitedAccessForm?.key;
  const confirmText = confirmState?.apiKeyData?.key
    ? `Confirm deletion of API Key ${key}`
    : confirmState?.limitedAccessForm?.key
    ? `Confirm deletion of Limited Access ${key}`
    : '';

  return (
    <ConfirmModal
      isLoading={isLoading}
      errorMessage={error?.message}
      text={confirmText}
      submitBtnText={'Confirm'}
      onSubmit={async () => {
        if (!!confirmState?.apiKeyData) {
          await dispatch(archiveApiKey(confirmState.apiKeyData.id));
          dispatch(getApiKeys(undefined, true));
        }

        if (!!confirmState?.limitedAccessForm) {
          await dispatch(archiveApiKey(confirmState.limitedAccessForm.id));
          dispatch(getApiKeys({ types: 'portal-external' }, true));
        }

        dispatch(closeModal());
      }}
      onCancel={() => dispatch(closeModal())}
      timeout={parseInt(config.tokenExpirationSec, 10)}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
      }}
    />
  );
};
export default ConfirmApiKeyDeletion;
