import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useUser } from 'views/components/providers/UserProvider';
import { storeAccountKey } from 'utils/storage/storage';
import useIsPrivateContext from 'hooks/useIsPrivateContext';
import Modal from 'components/Modal';

const messages = {
  title: 'Upload Confirmation',
  descriptionPublic: 'By uploading this file, you agree to sharing with 3rd parties.',
  descriptionPrivate:
    'By uploading this file, you agree to share it with engines/sandbox selected in your private community.',
  remember: 'Remember this setting for 30 days',
  agree: 'Agree',
};

const FileShareDataModal = ({
  open = false,
  onSubmit,
  onCancel,
}: {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(open);
  const [rememberPreference, setRememberPreference] = useState(false);
  const isPrivateContext = useIsPrivateContext();
  const { classes } = useStyles();
  const { accountNumber } = useUser();

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <Modal
      open={modalOpen}
      submitText={messages.agree}
      title={messages.title}
      onSubmit={() => {
        if (rememberPreference) {
          storeAccountKey(accountNumber, 'shareData', new Date().toISOString());
        }
        onSubmit();
        setModalOpen(false);
      }}
      onCancel={() => {
        setModalOpen(false);
        onCancel && onCancel();
      }}
    >
      <div css={classes.container}>
        <p css={classes.description}>
          {isPrivateContext ? messages.descriptionPrivate : messages.descriptionPublic}
        </p>
        <div css={classes.remember}>
          <Checkbox
            color='primary'
            checked={rememberPreference}
            onChange={(_e) => {
              setRememberPreference(!rememberPreference);
            }}
          />
          <label htmlFor='remember'>{messages.remember}</label>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    description: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    remember: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '2rem',
      fontWeight: 300,
      fontSize: '1.3rem',
    },
  },
});
export default FileShareDataModal;
