import * as yup from 'yup';
import { IntlShape } from 'react-intl';
import { formSchema, formMessages } from 'utils/schema/formSchema';
import api from 'services/api';
import debounce from 'debounce-promise';

export const getValidationSchema = (intl: IntlShape) => {
  const schema = formSchema(intl);
  const { username, firstName, lastName, jobTitle, company } = schema;

  const checkUsernameAvailability = debounce((value: string) => {
    return api
      .checkUsername(value)
      .then((result) => !result.data)
      .catch(() => true);
  }, 200);
    return yup.object().shape({
      username: username
        .test({
          exclusive: true,
          name: 'unique',
          test: (value) => {
            if (!value || value.length < 3 || value.length > 15) {
              return true;
            }

            return checkUsernameAvailability(value);
          },
          message: 'Username is already taken. Please try a different username.',
        })
        .required(
          intl.formatMessage(formMessages.required, {
            label: intl.formatMessage(formMessages.username),
          })
        ),
      firstName: firstName.required(
        intl.formatMessage(formMessages.required, {
          label: intl.formatMessage(formMessages.firstName),
        })
      ),
      lastName: lastName.required(
        intl.formatMessage(formMessages.required, {
          label: intl.formatMessage(formMessages.lastName),
        })
      ),
      jobTitle,
      company,
    });
};
