import { AsyncAction } from 'state/types/actions';
import { CommunitiesRes, CommunityRes } from 'services/api/schema/communities';

export enum CommunitiesActionName {
  GET_ALL_COMMUNITIES = 'GET_ALL_COMMUNITIES',
  GET_COMMUNITY = 'GET_COMMUNITY',
}

export interface GetAllCommunitiesAction
  extends AsyncAction<CommunitiesActionName.GET_ALL_COMMUNITIES, CommunitiesRes> {}

export interface GetCommunityAction
  extends AsyncAction<CommunitiesActionName.GET_COMMUNITY, CommunityRes> {}

export type CommunitiesAction = GetAllCommunitiesAction | GetCommunityAction;
