import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PanelContent from 'views/components/layout/PanelContent';
import PanelLoader from 'views/components/Loader/PanelLoader';
import AllSandboxingTable from './AllSandboxingTable';
import warningImg from 'assets/images/warning.svg';
import { useAuth } from 'views/components/providers/AuthProvider';
import ErrorPageContent from 'views/components/error/ErrorPageContent';
import useHasFeature from 'hooks/useHasFeature';
import { ForbiddenOptions } from '../../EmptySandboxing';

type Filter = {
  key: string;
  value: string;
};

interface IAllSandboxingTableContainer {
  filters: Filter[];
  accessForbidden?: ForbiddenOptions;
  [key: string]: any;
}

const messages = defineMessages({
  warningText: {
    id: 'AllSandboxingTab.warningText',
    defaultMessage: 'Account must have 2FA enabled to proceed',
  },
  setup2fa: {
    id: 'AllSandboxingTab.setup2fa',
    defaultMessage: 'Setup 2FA',
  },
});

const AllSandboxingTableContainer = ({
  filters,
  accessForbidden,
  useAllSandboxingProps,
}: IAllSandboxingTableContainer) => {
  const { isAuthenticated } = useAuth();
  const intl = useIntl();
  const history = useHistory();
  const { hasPermission, loading: loadingPermission } = useHasFeature('sandbox_search');
  const { data, error, more, refetch, refetchItem, setAccess, loading } = useAllSandboxingProps;

  useEffect(() => {
    if (isAuthenticated && hasPermission) {
      setAccess(true);
    }
  }, [setAccess, isAuthenticated, hasPermission]);

  if (error) {
    return (
      <ErrorPageContent className='h-mt-lg h-mb-lg' heading='Error' text={error}></ErrorPageContent>
    );
  }
  if (loading || loadingPermission) return <PanelLoader />;

  return data ? (
    <AllSandboxingTable
      results={data}
      more={more}
      refetch={refetch}
      refetchItem={refetchItem}
      accessForbidden={accessForbidden}
    />
  ) : (
    <PanelContent
      heading={''}
      image={{
        src: warningImg,
        alt: intl.formatMessage(messages.warningText),
      }}
      text={intl.formatMessage(messages.warningText)}
      customButtons={[
        <Button
          component='button'
          color='primary'
          variant='contained'
          onClick={() => history.replace('/account/advanced')}
        >
          {intl.formatMessage(messages.setup2fa)}
        </Button>,
      ]}
    />
  );
};

export default AllSandboxingTableContainer;
