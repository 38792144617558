import { ExtendedUserInfo } from 'views/components/providers/UserProvider';
import { HAVE_WALLET_ACCESS } from 'models/Team';

export const canManageWallets = (user: ExtendedUserInfo): boolean => {
  if (!user.context) return false;
  if (user.context.team) {
    const accountNumber = user.context.accountNumber;
    const team = user.teams.find((team) => team.accountNumber === accountNumber);
    return !!team && HAVE_WALLET_ACCESS.some((role) => team.roles.includes(role));
  }
  return true;
};
