import {
  NotificationActionName,
  ShowNotificationAction,
  HideNotificationAction,
  ShowNotificationParams,
} from './types';

export const showNotification = (params: ShowNotificationParams): ShowNotificationAction => ({
  type: NotificationActionName.SHOW_NOTIFICATION,
  params,
});

export const hideNotification = (): HideNotificationAction => ({
  type: NotificationActionName.HIDE_NOTIFICATION,
});
