import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { CopyCode } from 'views/components/Code';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { isPivotingEnabled } from 'tenants/utils';

interface IToggle {
  id?: string;
  tab?: string;
  section: string;
  itemKey: string;
  itemValue: string;
  itemActualKey?: string | null;
  operator?: string;
  children: React.ReactNode;
  tooltipTitle?: string;
}

const removeDateTime = (value: string) => {
  if (!value) return value;
  const regex = /\d{2}:\d{2}:\d{2}/g;
  if (value.match && value.match(regex)) {
    return value.replace(regex, '').trim();
  }
  return value;
};

export const Toggle = ({
  id,
  tab,
  section,
  itemKey,
  itemValue,
  itemActualKey,
  operator,
  children,
  tooltipTitle,
}: IToggle) => {
  const { classes } = useStyles();
  const { active, isPivotable, filter, onSelectFilter, onSetActualKeyFilter } = usePivoting();
  const uniqueKey = id?.toLowerCase() || `${section} | ${itemKey}`;
  const value = removeDateTime(itemValue);
  const selected = filter[uniqueKey] && filter[uniqueKey].includes(value);
  const shouldRender =
    active &&
    isPivotingEnabled() &&
    isPivotable({
      id,
      tab,
      section,
      item: itemKey,
    }) &&
    value;

  return (
    <div css={[classes.container, shouldRender && classes.active]}>
      {shouldRender ? (
        <div
          className={selected ? '' : 'hidden'}
          onClick={() => {
            onSelectFilter(uniqueKey, value, operator);
            if (itemActualKey) {
              onSetActualKeyFilter(uniqueKey, itemActualKey);
            }
          }}
        >
          {selected ? (
            <Icon className='icon-remove' css={classes.icon} name='remove' />
          ) : (
            <Icon className='icon-plus' css={classes.icon} name='plus-alt' />
          )}
        </div>
      ) : (
        active && <div css={classes.iconMock}></div>
      )}
      {tooltipTitle ? (
        <Tooltip interactive title={<CopyCode>{tooltipTitle}</CopyCode>} placement='top-start'>
          <p css={classes.itemContent}>{children}</p>
        </Tooltip>
      ) : (
        <div style={{ width: active ? '85%' : '100%' }} css={classes.itemContent}>
          {children}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0.5rem',
      paddingLeft: '1rem',
      '& > .hidden': {
        visibility: 'hidden',
      },
      '&:hover > .hidden': {
        visibility: 'visible',
      },
    },
    iconMock: {
      height: '2rem',
      width: '2rem',
      marginRight: '1rem',
      marginBottom: '-0.4rem',
    },
    icon: {
      height: '2rem',
      width: '2rem',
      marginRight: '1rem',
      cursor: 'pointer',
      marginBottom: '-0.4rem',
    },
    itemContent: {
      fontSize: styles.font.size.p1,
      margin: `${styles.spacing.tiny} 0`,
      maxWidth: '40rem',
    },
  },
  light: {
    active: {
      '&:hover': {
        backgroundColor: styles.color.xxxLightGrey,
      },
    },
    icon: {
      '&.icon-remove': {
        color: styles.color.red,
      },
      '&.icon-plus': {
        color: styles.color.purple,
      },
    },
  },
  dark: {
    active: {
      '&:hover': {
        backgroundColor: styles.color.darkOffBlack,
      },
    },
    icon: {
      '&.icon-remove': {
        color: styles.color.red,
      },
      '&.icon-plus': {
        color: styles.color.lightBlue,
      },
    },
  },
});
