import React, { useState, ReactNode, useRef, useEffect, useCallback } from 'react';
import { ARROW_RIGHT_KEY_CODE, ARROW_LEFT_KEY_CODE, ARROW_DOWN_KEY_CODE } from 'utils/constants';

interface ITabPanel {
  className?: string;
  sections: ITabSection[];
  scrollToSection?: boolean;
  onChange?: (activeTab: string) => void;
  autoChangeTimeMS?: number;
}

export interface ITabProps {
  isActive?: boolean;
  isHovering?: boolean;
}

export interface ITabSection {
  id: string;
  tab: (props: ITabProps) => JSX.Element;
  section: ReactNode;
}

const keyCodes = [ARROW_RIGHT_KEY_CODE, ARROW_LEFT_KEY_CODE, ARROW_DOWN_KEY_CODE];

const TabPanel = ({
  className,
  sections,
  scrollToSection,
  onChange,
  autoChangeTimeMS,
}: ITabPanel) => {
  const [activeTab, setActiveTab] = useState(`tab-${sections[0].id}`);
  const [hoveredTab, setHoveredTab] = useState<string | undefined>();

  const tabslistRef = useRef<any>();
  const tabsRef = useRef<any>([]);
  const sectionsRef = useRef<any>([]);

  const _switchTab = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    setActiveTab(id);
    scrollToSection && tabslistRef.current.scrollIntoView({ behavior: 'smooth' });
    onChange && onChange(id);
  };

  const _setMouseEnter = (e: React.MouseEvent<HTMLAnchorElement>) =>
    setHoveredTab(e.currentTarget.id);

  const _setMouseLeave = () => setHoveredTab(undefined);

  const _onArrowKey = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (keyCodes.some((code) => code === e.which)) {
      e.preventDefault();

      if (e.which === ARROW_DOWN_KEY_CODE) {
        // On down key
        // Focus on the active section
        const section = sectionsRef.current.find(
          (el: HTMLElement) => el.id === e.currentTarget.href.split('#')[1]
        );
        section.focus();
      } else {
        // On left or right key
        // Select the next or previous tab
        const currentIndex: number = tabsRef.current.findIndex(
          (el: HTMLElement) => el.id === e.currentTarget.id
        );
        const index = e.which === ARROW_LEFT_KEY_CODE ? currentIndex - 1 : currentIndex + 1;
        const tab = tabsRef.current[index];
        if (tab) {
          tab.focus();
          setActiveTab(tab.id);
        }
      }
    }
  };

  const _switchToNextTab = useCallback(() => {
    const index = sections.findIndex(({ id }) => activeTab === `tab-${id}`);
    const nextIndex = (index + 1) % sections.length;
    setActiveTab(`tab-${sections[nextIndex].id}`);
  }, [activeTab, sections]);

  // adapated from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const savedCallback = useRef<() => void>();
  useEffect(() => {
    savedCallback.current = () => _switchToNextTab();
  }, [_switchToNextTab]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current !== undefined) savedCallback.current();
    }
    if (autoChangeTimeMS !== undefined) {
      const id = setInterval(tick, autoChangeTimeMS);
      return () => clearInterval(id);
    }
  }, [autoChangeTimeMS, activeTab]);

  return (
    <div className={className}>
      <ul ref={tabslistRef} role='tablist'>
        <li role='presentation'>
          {sections.map(({ id, tab }, index) => {
            const tabName = `tab-${id}`;
            const sectionName = `section-${id}`;
            return (
              <a
                ref={(el) => (tabsRef.current[index] = el)}
                key={tabName}
                id={tabName}
                tabIndex={tabName === activeTab ? 0 : -1}
                href={`#${sectionName}`}
                role='tab'
                onClick={(e) => _switchTab(e, tabName)}
                onMouseEnter={_setMouseEnter}
                onMouseLeave={_setMouseLeave}
                onKeyDown={_onArrowKey}>
                {tab({
                  isActive: tabName === activeTab,
                  isHovering: tabName === hoveredTab,
                })}
              </a>
            );
          })}
        </li>
      </ul>
      {sections.map(({ id, section }, index) => {
        const tabName = `tab-${id}`;
        const sectionName = `section-${id}`;
        return (
          <section
            ref={(el) => (sectionsRef.current[index] = el)}
            key={sectionName}
            id={sectionName}
            tabIndex={-1}
            hidden={tabName !== activeTab}
            aria-labelledby={tabName}
            role='tabpanel'>
            {section}
          </section>
        );
      })}
    </div>
  );
};

export default TabPanel;
