import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'views/components/providers/AuthProvider';

export const LoginPage = () => {
  const { login } = useAuth();
  const history = useHistory();

  useEffect(() => {
    history.replace('/');
    login();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
