import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-fail' viewBox='0 0 32 32'>
      <path d='M19.271 0.819l8 4.757c1.94 1.154 3.129 3.244 3.129 5.501v9.846c0 2.257-1.189 4.347-3.129 5.501l-8 4.757c-2.016 1.199-4.526 1.199-6.542 0l-8-4.757c-1.94-1.154-3.129-3.244-3.129-5.501v-9.846c0-2.257 1.189-4.347 3.129-5.501l8-4.757c2.016-1.199 4.526-1.199 6.542 0zM14.179 23.714h2.913v-2.914h-2.913v2.914zM14.146 19.002h2.953c0.015-0.591 0.1-1.079 0.257-1.466s0.436-0.758 0.837-1.116c0.102-0.087 0.175-0.157 0.219-0.208 0.583-0.532 1.034-0.973 1.351-1.323s0.578-0.749 0.782-1.198c0.204-0.448 0.306-0.953 0.306-1.515 0-0.926-0.199-1.712-0.596-2.357s-0.968-1.134-1.712-1.466c-0.744-0.332-1.637-0.498-2.68-0.498-0.977 0-1.843 0.164-2.598 0.492s-1.353 0.833-1.794 1.515c-0.441 0.682-0.669 1.537-0.684 2.565h3.423c0.015-0.605 0.179-1.057 0.492-1.356s0.7-0.448 1.159-0.448c1.050 0 1.575 0.569 1.575 1.706 0 0.445-0.139 0.859-0.416 1.241s-0.704 0.815-1.28 1.296c-0.598 0.51-1.014 1.079-1.247 1.706s-0.35 1.436-0.35 2.428z' />
    </symbol>

    <Global
      styles={css`
        .icon-fail {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
