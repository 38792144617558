import React from 'react';
import { useIntl } from 'react-intl';
import { NormalizedArtifactMetadata } from 'models/Submission';
import AccordionFileDetails from 'views/components/Accordion/AccordionFileDetails/AccordionFileDetails';
import { toolWhitelist } from 'views/pages/ScanPage/config';
import styles from 'views/styles';
import styled from '@emotion/styled';

interface IMetadataDetails {
  details?: Partial<NormalizedArtifactMetadata>;
}

const Wrapper = styled.div`
  margin: calc(${styles.spacing.grid} / 2) ${styles.spacing.grid};
`;

const Item = styled.div`
  margin-bottom: ${styles.spacing.grid};
  &:last-child {
    margin-bottom: 0;
  }
`;

const ArtifactDetails = ({ details }: IMetadataDetails) => {
  const intl = useIntl();

  if (!details) {
    return null;
  }

  return (
    <Wrapper>
      <Item>
        <AccordionFileDetails
          title={intl.formatMessage({ id: 'fileDetails.attributes' })}
          data={details.attributes as any}
          columns={2}
        />
      </Item>

      {Object.entries(details.metadata || {})
        .filter(([tool]) => toolWhitelist.includes(tool))
        .map(([tool, data]) => (
          <Item key={tool}>
            <AccordionFileDetails title={tool} data={data} columns={2} />
          </Item>
        ))}
    </Wrapper>
  );
};

export default ArtifactDetails;
