import { useEffect, useState } from 'react';
import { useUser } from 'views/components/providers/UserProvider';
import api from 'services/api';

interface Engine {
  id: string;
  name: string;
}

export const useDevelopmentResultsEngines = () => {
  const { accountNumber } = useUser();
  const [engines, setEngines] = useState<Engine[]>([]);
  const loading = engines.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await api.getUserDevelopmentResults(accountNumber);
      if (active) {
        setEngines(response.data.map((result: any) => result.engine));
      }
    })();

    return () => {
      active = false;
    };
  }, [accountNumber, loading]);

  return {
    loading,
    engines,
  };
};
