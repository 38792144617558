import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import TableCell from '@material-ui/core/TableCell';
import { Page } from 'models/Page';
import { Ruleset, RulesetValues } from 'models/Ruleset';
import { Row } from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import CustomTable from 'views/components/table/CustomTable';
import {
  CustomTablePagination,
  EPageDirection,
} from 'views/components/table/CustomTablePagination';
import RulesetRow from './RulesetRow';

interface IRulesetsTable {
  rulesets: Page<Ruleset>;
  cancelLiveHunt: (id: string) => void;
  runLiveHunt: (id: string, name: string) => void;
  runHistoricalHunt: (id: string, name: string) => void;
  updateRuleset: (id: string, values: RulesetValues) => void;
  duplicateRuleset: (values: RulesetValues) => void;
  deleteRuleset: (id: string) => void;
  showRulesetInfo: (title: string, liveHuntId: string | null, rulesetId: string) => void;
  getRuleset: (rulesetId: string) => Promise<Ruleset>;
  handleChangePage: (page: EPageDirection) => void;
  handleChangeRowsPerPage: (numRows: number) => void;
  isFirst: boolean;
}

const messages = defineMessages({
  liveHunt: {
    id: 'table.row.liveHunt',
    defaultMessage: 'Live Hunt',
  },
  rulesetName: {
    id: 'table.row.rulesetName',
    defaultMessage: 'Ruleset',
  },
});

const RulesetsTable = ({
  rulesets,
  cancelLiveHunt,
  runLiveHunt,
  runHistoricalHunt,
  updateRuleset,
  duplicateRuleset,
  deleteRuleset,
  showRulesetInfo,
  getRuleset,
  handleChangePage,
  handleChangeRowsPerPage,
  isFirst,
}: IRulesetsTable) => {
  const { formatMessage } = useIntl();

  const rows: Row<Ruleset>[] = [
    {
      id: 'id',
      numeric: false,
      label: formatMessage(messages.liveHunt),
      sortable: false,
    },
    {
      id: 'name',
      numeric: false,
      label: formatMessage(messages.rulesetName),
      sortable: false,
    },
  ];

  return (
    <CustomTable<Ruleset>
      rows={rulesets.results}
      renderTableHead={() => (
        <CustomTableHead
          rows={rows}
          actionAlign='left'
          showActions={true}
          renderHeaderCell={(row) => (
            <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
              {formatMessage({ id: row.label })}
            </TableCell>
          )}
        />
      )}
      renderTableRow={(row: Ruleset) => (
        <RulesetRow
          key={row.id}
          rowData={row}
          cancelLiveHunt={cancelLiveHunt}
          runLiveHunt={runLiveHunt}
          runHistoricalHunt={runHistoricalHunt}
          updateRuleset={updateRuleset}
          duplicateRuleset={duplicateRuleset}
          deleteRuleset={deleteRuleset}
          showRulesetInfo={showRulesetInfo}
          getRuleset={getRuleset}
        />
      )}
      renderPagination={
        <CustomTablePagination
          isFirst={isFirst}
          isLast={!rulesets.has_more}
          rowsPerPage={rulesets.limit}
          rowsPerPageOptions={[5, 25, 50, 75]}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      }
    />
  );
};

export default RulesetsTable;
