import React, { useState, Fragment, useEffect } from 'react';
import * as yup from 'yup';
import { uniq } from 'lodash';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { useIntl, IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import { RootState } from 'state/root';
import { getWebhooks } from 'state/webhooks/actions';
import formatBytes from 'utils/formatBytes';
import { formatErrorMessage, getErrorFields, getErrorMessages } from 'utils/error';
import { formSchema } from 'utils/schema/formSchema';
import { formMessages } from 'utils/schema/formSchema';
import { MicroengineValues, EMicroengineType } from 'models/Microengine';
import FormInput from 'views/components/form/FormInput';
import CustomForm from 'views/components/form/CustomForm';
import FormButtonBar from 'views/components/form/CustomForm/FormButtonBar';
import FormTagsInput from './FormTagsInput';
import BaseFormTagsInput from 'views/components/form/FormTagsInput';
import FormCheckboxArrayField from 'views/components/form/FormCheckboxArrayField';
import FormRadio from 'views/components/form/FormRadio';
import { useUser } from 'views/components/providers/UserProvider';
import FormSelect from 'views/components/form/FormSelect';
import { CheckboxOption } from 'views/pages/AuthCallbackPage/AccountSignupForm/CheckboxArrayField';
import { ConfirmCheckbox } from './ConfirmCheckbox';
import WebhookForm from '../WebhookForm';

interface FormData {
  mimetypes: string[];
  artifactTypes: CheckboxOption[];
}

interface IMicroengineForm {
  className?: string;
  formData: FormData;
  initialValues?: Partial<MicroengineValues>;
  heading: string;
  buttonText: string;
  isLoading: boolean;
  errorMessage?: string | null;
  errorData?: any;
  onSubmit: (values: MicroengineValues, actions: FormikHelpers<MicroengineValues>) => void;
  onCancel: () => void;
}

const getInitialValues = (values?: Partial<MicroengineValues>): MicroengineValues => {
  const [maxFileSize, sizeType] =
    values?.maxFileSize
      ? formatBytes(values.maxFileSize).split(' ')
      : [values?.maxFileSize, 'MB'];

  return {
    engineId: values?.engineId || '',
    name: values?.name || '',
    displayName: values?.displayName || '',
    address: values?.address || '',
    description: values?.description || '',
    vendorWebsite: values?.vendorWebsite || '',
    tags: values?.tags || [EMicroengineType.microengine],
    artifactTypes: values?.artifactTypes || [],
    engineType: EMicroengineType.microengine,
    mimetypes: values?.mimetypes || [],
    webhookId: values?.webhookId || '',
    maxFileSize: maxFileSize ? Math.floor(Number(maxFileSize)) : 33,
    sizeType,
  };
};

const getValidationSchema = (intl: IntlShape) => {
  const schema = formSchema(intl);
  const { name, description, tags, artifactTypes } = schema;
  return yup.object().shape({
    displayName: name.required(
      intl.formatMessage(formMessages.name, { label: intl.formatMessage(formMessages.name) })
    ),
    artifactTypes: artifactTypes.required(
      intl.formatMessage(formMessages.artifactTypes, {
        label: intl.formatMessage(formMessages.artifactTypes),
      })
    ),
    webhookId: yup.string().required(),
    description,
    tags,
  });
};

const useDefaultTags = (initialValues: Partial<MicroengineValues>) => {
  const defaultTags: string[] = []; // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialValues) {
      if (initialValues.engineType) {
        defaultTags.push(initialValues.engineType);
      }
      if (initialValues.artifactTypes) {
        if (initialValues.artifactTypes.indexOf('url') !== -1) {
          defaultTags.push('url');
        }
        if (initialValues.artifactTypes.indexOf('file') !== -1) {
          defaultTags.push('file');
        }
      }
    }
  }, [initialValues, defaultTags]);

  return defaultTags;
};

const DoubleField = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-column-gap: 0.5em;
`;

const ButtonText = styled(Button)`
  font-size: 1.5rem !important;
  margin-bottom: 2.4rem !important;
  margin-top: 0.5rem !important;
`;

const MicroengineForm = ({
  className,
  formData,
  initialValues,
  heading,
  buttonText,
  isLoading,
  errorMessage,
  errorData,
  onSubmit,
  onCancel,
}: IMicroengineForm) => {
  const intl = useIntl();
  const user = useUser();
  const dispatch = useDispatch();
  const [showWebhookForm, setShowWebhookForm] = useState<boolean>(false);
  const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false);
  const microengines = useSelector((state: RootState) => state.microengines.user);
  const webhooksData = useSelector((state: RootState) => state.webhooks);

  const usedWebhooksId = microengines.results?.map((engine) => engine.webhookId);
  const webhooks = webhooksData.results?.map(({ webhookId, url, status }) => ({
    status,
    value: webhookId,
    label: url,
    disabled: usedWebhooksId.includes(webhookId),
  }));

  const errorFields = getErrorFields(errorData);
  const errorMessages = getErrorMessages(errorData, errorMessage);

  const _initialValues = getInitialValues(initialValues);
  let defaultTags: string[] = useDefaultTags(_initialValues);

  // Get list of webhooks
  const _getWebhooks = () => {
    dispatch(getWebhooks({}, true));
  };

  // Populate received values with default engineType
  const _onSubmit = (values: MicroengineValues, actions: FormikHelpers<MicroengineValues>) => {
    const accountNumber = user.context?.accountNumber || user.accountNumber;

    return onSubmit(
      { ...values, accountNumber, engineType: EMicroengineType.microengine },
      actions
    );
  };

  const isEditing = _initialValues.displayName !== '';

  useEffect(() => {
    _getWebhooks();
  }, []); // eslint-disable-line

  return (
    <Formik
      initialValues={_initialValues}
      validationSchema={getValidationSchema(intl)}
      onSubmit={_onSubmit}>
      {(formikProps: FormikProps<MicroengineValues>) => {
        const handleArtifactTypesChange = (event: any) => {
          const values = formikProps.values['tags'].filter((tag) => tag !== event.target.value);
          defaultTags = defaultTags.filter((tag) => tag !== event.target.value);
          if (event.target.checked) {
            values.unshift(event.target.value);
            defaultTags.unshift(event.target.value);
          }
          formikProps.setFieldValue('tags', uniq(values));
        };
        const isWebhookSet = !formikProps.values.webhookId;

        return (
          <CustomForm
            testId='microenginesForm'
            className={className}
            isLoading={isLoading}
            heading={heading}
            renderFields={() => (
              <Fragment>
                <FormInput
                  className='h-mb-sm'
                  name='displayName'
                  label={intl.formatMessage(formMessages.name)}
                  errorOnTouched
                  disabled={isEditing}
                  error={errorFields.includes('displayName')}
                  helperText={formatErrorMessage(errorMessages['displayName'])}
                />
                <FormSelect
                  name='webhookId'
                  label='Webhook'
                  showStatus
                  info={intl.formatMessage(formMessages.webhookInfo)}
                  options={webhooks || []}
                />
                {!showWebhookForm && (
                  <ButtonText
                    color='primary'
                    variant='text'
                    onClick={() => setShowWebhookForm(true)}>
                    {intl.formatMessage(formMessages.createNewWebhook)}
                  </ButtonText>
                )}
                {showWebhookForm && (
                  <WebhookForm
                    closeForm={() => {
                      setShowWebhookForm(false);
                      _getWebhooks();
                    }}
                  />
                )}
                <FormInput
                  disabled={isWebhookSet}
                  className='h-mb-sm'
                  name='vendorWebsite'
                  optionalText={intl.formatMessage(formMessages.optional)}
                  label={intl.formatMessage(formMessages.vendorWebsite)}
                  errorOnTouched
                  error={errorFields.includes('vendorWebsite')}
                  helperText={formatErrorMessage(errorMessages['vendorWebsite'])}
                />
                <FormInput
                  disabled={isWebhookSet}
                  multiline
                  rows={3}
                  className='h-mb-sm'
                  name='description'
                  label={intl.formatMessage(formMessages.description)}
                  optionalText={intl.formatMessage(formMessages.optional)}
                  error={errorFields.includes('description')}
                  helperText={formatErrorMessage(errorMessages['description'])}
                />
                <DoubleField>
                  <FormInput
                    disabled={isWebhookSet}
                    className='h-mb-sm'
                    name='maxFileSize'
                    type='number'
                    label='Max file size'
                    optionalText={intl.formatMessage(formMessages.optional)}
                    error={errorFields.includes('maxFileSize')}
                    helperText={formatErrorMessage(errorMessages['maxFileSize'])}
                  />
                  <FormSelect
                    disabled={isWebhookSet}
                    className='h-mb-sm'
                    name='sizeType'
                    label='Type'
                    options={['KB', 'MB', 'GB'].map((type) => ({ label: type, value: type }))}
                  />
                </DoubleField>
                <FormTagsInput
                  disabled={isWebhookSet}
                  className='h-mb-sm'
                  name='tags'
                  label={intl.formatMessage(formMessages.tags)}
                  optionalText={intl.formatMessage(formMessages.optional)}
                  defaultValue={defaultTags}
                />
                <BaseFormTagsInput
                  disabled={isWebhookSet}
                  name='mimetypes'
                  className='h-mb-sm'
                  label='Supported mimetypes'
                  info={intl.formatMessage(formMessages.mimetypesInfo)}
                  addNew={false}
                  options={formData.mimetypes}
                  optionalText={intl.formatMessage(formMessages.optional)}
                />
                <FormRadio
                  disabled={isWebhookSet}
                  className='h-mb-xs'
                  name='engineType'
                  label='Engine type'
                  options={[{ value: EMicroengineType.microengine, label: 'Microengine' }]}
                />
                <FormCheckboxArrayField
                  disabled={isWebhookSet}
                  name='artifactTypes'
                  className='h-mb-xs'
                  label='Supported artifact types'
                  options={formData.artifactTypes}
                  onChange={handleArtifactTypesChange}
                />
                {!isEditing ? (
                  <ConfirmCheckbox value={agreementAccepted} onChange={setAgreementAccepted} />
                ) : null}
              </Fragment>
            )}
            renderButtonBar={() => (
              <FormButtonBar
                disabled={
                  !formikProps.values.displayName ||
                  !isEmpty(formikProps.errors) ||
                  (!isEditing && !agreementAccepted)
                }
                submitBtnText={buttonText}
                onCancel={() => {
                  formikProps.resetForm();
                  onCancel();
                }}
              />
            )}
          />
        );
      }}
    </Formik>
  );
};

export default MicroengineForm;
