import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-check-alt' viewBox='0 0 39 32'>
      <path d='M32.234 1.069c1.373-1.404 3.624-1.429 5.028-0.056s1.429 3.624 0.056 5.028l-21.595 22.080c-1.451 1.483-3.858 1.414-5.222-0.15l-9.625-11.040c-1.29-1.48-1.137-3.726 0.344-5.017s3.726-1.137 5.017 0.344l7.095 8.138 18.903-19.328z' />
    </symbol>

    <Global
      styles={css`
        .icon-check-alt {
          width: 3.9em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
