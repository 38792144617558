import React from 'react';
import BaseLayout from 'views/components/layout/BaseLayout';
import MicroenginesSidebar from './MicroenginesSidebar';
import MicroenginesPanel from './MicroenginesPanel';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

const MicroenginesPage = () => {
  const leftSidebar = <MicroenginesSidebar />;
  const panelClassNames = leftSidebar ? 'col-8 col-lg-9' : 'col-12 col-lg-12';

  return (
    <BaseLayout>
      <ScrollToTopOnMount />
      <div className='container h-mt-sm h-mb-lg'>
        <div className='row'>
          {leftSidebar && <div className='col-4 col-lg-3'>{leftSidebar}</div>}
          <div className={panelClassNames}>
            <MicroenginesPanel />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default MicroenginesPage;
