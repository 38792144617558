import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  teamAdmin: {
    id: 'team.role.teamAdmin',
    defaultMessage: 'Team Admin',
  },
  teamOwner: {
    id: 'team.role.teamOwner',
    defaultMessage: 'Team Owner',
  },
  billingAdmin: {
    id: 'team.role.billingAdmin',
    defaultMessage: 'Billing Admin',
  },
  communityAdmin: {
    id: 'team.role.communityAdmin',
    defaultMessage: 'Community Admin',
  },
  microengineAdmin: {
    id: 'team.role.microengineAdmin',
    defaultMessage: 'Microengine Admin',
  },
  walletAdmin: {
    id: 'team.role.walletAdmin',
    defaultMessage: 'Wallet Admin',
  },
  teamMember: {
    id: 'team.role.teamMember',
    defaultMessage: 'Team Member',
  },
  inviteSuccess: {
    id: 'teams.invite.success',
    defaultMessage: 'Invitation sent',
  },
  inviteFailure: {
    id: 'teams.invite.failure',
    defaultMessage: 'Error sending invitation. Please try again.',
  },
  updateMemberSuccess: {
    id: 'teams.members.update.success',
    defaultMessage: 'Team member updated',
  },
  updateMemberFailure: {
    id: 'teams.members.update.failure',
    defaultMessage: 'Error updating Team member.',
  },
  archiveMemberSuccess: {
    id: 'teams.members.archive.success',
    defaultMessage: 'Team member removed',
  },
  archiveMemberFailure: {
    id: 'teams.members.archive.failure',
    defaultMessage: 'Error removing team member. Please try again.',
  },
  archiveInvitationFailure: {
    id: 'teams.members.archiveInvitation.failure',
    defaultMessage: 'Error deleting invitation. Please try again.',
  },
  resendInvitationSuccess: {
    id: 'teams.members.resendInvitation.success',
    defaultMessage: 'Team invitation resent.',
  },
  resendInvitationFailure: {
    id: 'teams.members.resendInvitation.failure',
    defaultMessage: 'Error resending invitation. Please try again.',
  },
});
