import React, { FC, useEffect, createContext, useContext } from 'react';
import { ObjectInterpolation } from '@emotion/core';
import { useSelector } from 'react-redux';
import { merge } from 'lodash';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { RootState } from 'state/root';
import themeObj from 'views/styles/theme';
import { DEFAULT_THEME } from 'utils/constants';
import { ThemeProps, ThemeState } from 'models/Theme';

/**
 * The app's theme context.
 */
export const ThemeContext = createContext<ThemeState>(DEFAULT_THEME);

/**
 * Theme style object with properties of type T.
 */
export type ThemeRootStyle<T extends string> = { [key in T]?: ObjectInterpolation<any> };

/**
 * The base property of the style object.
 */
type ThemeBaseStyle<T extends string> = { base: ThemeRootStyle<T> };

/**
 * Optional theme properties of the style object.
 */
type ThemeOptionalStyle<T extends string> = {
  [theme in ThemeState]?: ThemeRootStyle<T>;
};

/**
 * A theme styles object with base and theme properties of type T.
 */
export type ThemeStyles<T extends string> = ThemeBaseStyle<T> & ThemeOptionalStyle<T>;

/**
 * Theme context hook.
 *
 * Pass it a style object and it returns a function which takes props and
 * returns an object with the theme and classes.
 *
 * `const useStyles = makeStyles(props => ({...}))`
 *
 * `const { theme, classes } = useStyles(props);`
 */
export const makeStyles =
  <ClassKey extends string = string>(
    themeStyles?: ThemeStyles<ClassKey> | ((props?: any) => ThemeStyles<ClassKey>)
  ): ((props?: any) => { theme: ThemeState; classes: ThemeRootStyle<ClassKey> }) =>
  (props?: any) => {
    const theme = useContext(ThemeContext);
    let classes: ThemeRootStyle<ClassKey> = {};
    if (themeStyles) {
      if (typeof themeStyles === 'function') {
        classes = merge(themeStyles(props).base, themeStyles(props)[theme]);
      } else {
        classes = merge(themeStyles.base, themeStyles[theme]);
      }
    }
    return { theme, classes };
  };

/**
 * Theme context hook.
 */
export const useTheme = () => useContext(ThemeContext);

/**
 * Provides theme support for the application.
 */
const ThemeProvider: FC<ThemeProps> = ({ theme, children }) => {
  const muiTheme = createMuiTheme(merge(themeObj.base, themeObj[theme]));
  return (
    <ThemeContext.Provider value={theme}>
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const ThemeProviderContainer: FC = ({ children }) => {
  const { theme } = useSelector((state: RootState) => state.settings);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProvider;
