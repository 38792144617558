import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-success' viewBox='0 0 28 32'>
      <path
        fill='#fff'
        d='M23.547 21.132c0 5.335-4.325 9.66-9.66 9.66s-9.66-4.325-9.66-9.66c0-5.335 4.325-9.66 9.66-9.66s9.66 4.325 9.66 9.66z'
      />
      <path d='M13.759 31.698c-5.835 0-10.566-4.731-10.566-10.566s4.731-10.566 10.566-10.566c5.835 0 10.566 4.731 10.566 10.566s-4.731 10.566-10.566 10.566zM13.759 29.887c4.835 0 8.755-3.92 8.755-8.755s-3.92-8.755-8.755-8.755c-4.835 0-8.755 3.92-8.755 8.755s3.92 8.755 8.755 8.755z' />
      <path d='M12.854 1.208c0-0.5 0.405-0.906 0.906-0.906s0.906 0.405 0.906 0.906v4.83c0 0.5-0.405 0.906-0.906 0.906s-0.906-0.405-0.906-0.906v-4.83z' />
      <path d='M26.041 8.153c0.364-0.343 0.937-0.327 1.28 0.037s0.327 0.937-0.037 1.28l-1.908 1.802c-0.364 0.343-0.937 0.327-1.28-0.037s-0.327-0.937 0.037-1.28l1.908-1.802z' />
      <path d='M1.829 8.153c-0.364-0.343-0.937-0.327-1.28 0.037s-0.327 0.937 0.037 1.28l1.908 1.802c0.364 0.343 0.937 0.327 1.28-0.037s0.327-0.937-0.037-1.28l-1.908-1.802z' />
      <path d='M12.492 24.72c-0.203 0-0.407-0.078-0.562-0.233l-3.372-3.372c-0.31-0.31-0.31-0.814 0-1.124s0.814-0.31 1.124 0l2.81 2.81 5.058-5.058c0.31-0.31 0.814-0.31 1.124 0s0.31 0.814 0 1.124l-5.62 5.62c-0.155 0.155-0.359 0.233-0.562 0.233z' />
    </symbol>

    <Global
      styles={css`
        .icon-success {
          width: 2.8em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
