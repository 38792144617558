import React, { forwardRef } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import Icon, { IIcon } from 'views/components/Icon';
import ShareTooltip from './ShareTooltip';

interface Props {
  className?: string;
  items: ActionItemProps[];
}

export interface ActionItemProps {
  icon: IIcon['name'];
  name: string;
  shareUrl?: string;
  disabled?: boolean;
  isShown?: boolean;
  onClick?: () => void;
  dataCy?: string;
  iconFilled?: boolean;
  tooltipTitle?: string | React.ReactNode;
  warning?: boolean;
}

export const SidebarActions = ({ className, items }: Props) => (
  <Card className={className} css={baseStyle}>
    {items.map((item) => {
      if (item.isShown === false) {
        return null;
      }
      return item.shareUrl ? (
        <ShareTooltip
          key={item.icon}
          url={item.shareUrl}
          actionItemProps={item}
          ChildComponent={ActionItem}
        />
      ) : (
        <ActionItem key={item.icon} {...item} />
      );
    })}
  </Card>
);

const ActionItem = forwardRef<HTMLButtonElement, ActionItemProps>(
  ({ icon, name, disabled, onClick, dataCy, iconFilled, tooltipTitle = '', warning }, ref) => {
    const { classes } = useStyles(iconFilled);

    return (
      <Tooltip title={tooltipTitle === null ? '' : tooltipTitle} placement='top'>
        <span>
          <button
            css={[classes.item, !!disabled && classes.disabledElement]}
            ref={ref}
            disabled={!!tooltipTitle || disabled}
            onClick={onClick}
            data-cy={dataCy}
          >
            {warning && <Icon css={classes.iconWarning} name='warning' />}
            <Icon
              disabled={disabled}
              css={warning ? { ...classes.icon, ...classes.warningNavigation } : classes.icon}
              name={icon}
            />
            <span
              css={[
                warning ? classes.warningNavigation : null,
                !!disabled && classes.disabledElement,
              ]}
            >
              {name}
            </span>
          </button>
        </span>
      </Tooltip>
    );
  }
);

const baseStyle = css`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
`;

const useStyles = makeStyles((isIconFilled = true) => ({
  base: {
    disabledElement: {
      cursor: 'not-allowed',
      color: styles.color.grey,
    },
    warningNavigation: {
      stroke: styles.color.grey,
      fill: styles.color.grey,
      color: styles.color.grey,
      '&:active': {
        color: styles.color.grey,
      },
    },
    iconWarning: {
      position: 'absolute',
      top: 8,
      right: 16,
      color: styles.color.lightRed,
      width: '1.4rem',
      height: '1.4rem',
    },
    item: {
      position: 'relative',
      cursor: 'pointer',
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.medium,
      padding: '1.4rem',
      lineHeight: 1,
      width: '100%',
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      opacity: 0.8,
      transition: `opacity ${styles.easing.time} ${styles.easing.main}, color ${styles.easing.time} ${styles.easing.main}`,
      '&:last-child': {
        borderRight: 'none',
      },
      '&:hover': {
        opacity: 1,
      },
      '&:active': {
        color: styles.color.darkPurple,
      },
      '&[disabled]': {
        cursor: 'not-allowed',
        opacity: 1,
      },
    },
    icon: {
      fontSize: '0.75rem',
      strokeWidth: isIconFilled ? 0 : 2,
      marginBottom: '0.7rem',
    },
  },
  light: {
    item: {
      color: styles.color.purple,
      borderRightColor: styles.border.color.grey,
      '&[disabled]': {
        color: styles.color.lightPurple,
      },
    },
    icon: {
      fill: isIconFilled ? styles.color.purple : styles.color.white,
      stroke: styles.color.purple,
    },
  },
  dark: {
    item: {
      color: styles.color.white,
      borderRightColor: styles.border.color.darkPurple,
      '&[disabled]': {
        color: styles.color.grey,
      },
    },
    icon: {
      fill: isIconFilled ? styles.color.white : styles.color.xDarkPurple,
      stroke: styles.color.white,
    },
  },
}));
export default SidebarActions;
