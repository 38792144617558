import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import Icon, { IIcon } from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import ConfigMenuItem from './ConfigMenuItem';
import IconContainer from 'views/components/Icon/IconContainer';

interface IConfigMenu {
  items: MenuOption[];
}

export interface MenuOption {
  id: string;
  iconName: IIcon['name'];
  label?: string;
  href?: string;
  onClick?: (...args: any[]) => void;
  items?: MenuOption[];
  isShown?: boolean;
  spacer?: boolean;
  dataCy?: string;
}

const ConfigMenu = ({ items }: IConfigMenu) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = useStyles();
  const muiClasses = useMuiStyles();

  const _handleOpen = (e: React.MouseEvent<any>) => setAnchorEl(e.currentTarget);

  const _handleClose = () => setAnchorEl(null);

  const _handleClick = (onItemClick?: () => void) => {
    onItemClick && onItemClick();
    _handleClose();
  };

  return (
    <div css={classes.root}>
      <IconContainer onClick={_handleOpen}>
        <Icon css={classes.icon} name='gear' />
      </IconContainer>
      <Menu
        id='config-menu'
        classes={{ paper: muiClasses.paper }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={_handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((item) => {
          if (item.isShown === false) {
            return null;
          }
          return (
            <ConfigMenuItem key={item.id} {...item} onClick={() => _handleClick(item.onClick)} />
          );
        })}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'inline-block',
      position: 'relative',
    },
    toggle: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
    userName: {
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.medium,
      marginRight: styles.spacing.xxs,
    },
    avatar: {
      height: '4em !important',
      width: '4em !important',
      img: {
        left: '0.1em !important',
      },
    },
    icon: {
      transform: 'scale(1.5)',
      cursor: 'pointer',
    },
  },
  light: {
    toggle: {
      color: styles.color.black,
      '&:hover, &:focus': {
        color: styles.color.purple,
      },
      '&:active': {
        color: styles.color.medPurple,
      },
    },
    icon: {
      color: styles.color.purple,
    },
  },
  dark: {
    toggle: {
      color: styles.color.white,
      '&:hover, &:focus': {
        color: styles.color.lightBlue,
      },
      '&:active': {
        color: styles.color.xLightBlue,
      },
    },
    icon: {
      color: styles.color.lightBlue,
    },
  },
});

const useMuiStyles = makeMuiStyles({
  paper: {
    width: '21.2rem',
  },
});

export default ConfigMenu;
