import { useState } from 'react';
import api from 'services/api';
import { getErrorMessage } from 'utils/error';
import { useUser } from 'views/components/providers/UserProvider';

export default function useRequestFeature() {
  const user = useUser();
  const accountNumber = user.context?.accountNumber || user.accountNumber;
  const [loading, setLoading] = useState<boolean>(false);

  async function requestFeature(feature: string) {
    let success = false;
    let errorMessage = '';

    try {
      setLoading(true);

      const response = await api.requestFeature(accountNumber, feature);
      if (response.status === 204) {
        success = true;
      } else {
        errorMessage = response.data.message;
      }
    } catch (error: any) {
      errorMessage = getErrorMessage(error as Error);
    } finally {
      setLoading(false);
    }

    return { success, errorMessage };
  }

  return { requestFeature, loading };
}
