import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { Note } from 'views/pages/HuntPage/Note';
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import CopyTextArea from 'views/components/CopyText/CopyTextArea';
import styles from 'views/styles';

const messages = defineMessages({
  note: {
    id: 'developmentResults.cliText',
    defaultMessage:
      "To test your engine, use the PolySwarm CLI to submit artifacts into your engine's Development Community.",
  },
  commandText: {
    id: 'developmentResults.commandText',
    defaultMessage:
      'To submit files into your Development Community, copy and run the following command in your terminal. Edit the path/to/file to point to a file on your system that you want to submit into the community',
  },
});

interface Command {
  apiKey: string;
  communityId: string;
}

const DevelopmentResults = (command: Command) => {
  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <div css={classes.container}>
      <Note css={classes.note}>
        {intl.formatMessage(messages.note)}{' '}
        <a
          css={classes.link}
          href='https://docs.polyswarm.io/consumers/polyswarm-customer-cli-v3#installation'
          target='_blank'
          rel='noopener noreferrer'>
          How to use PolySwarm CLI
        </a>
      </Note>
      <Note css={classes.note}>{intl.formatMessage(messages.commandText)}</Note>
      <CopyTextArea
        alignNotification='left'
        height='6vh'
        value={`polyswarm -a ${command.apiKey} -c ${command.communityId} scan file path/to/file`}
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      padding: '0.5rem 2.5rem',
    },
    note: {
      paddingBottom: '0.8rem',
      paddingLeft: '0.8rem',
    },
  },
  light: {
    note: {
      color: styles.color.lightGrey,
    },
    link: {
      color: styles.color.purple,
    },
  },
  dark: {
    note: {
      color: styles.color.xLightGrey,
    },
    link: {
      color: styles.color.lightBlue,
    },
  },
});

export default DevelopmentResults;
