import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import makeButton from './hoc/makeButton';
import { AnchorHTMLProps } from '.';

export const ButtonAnchor = makeButton((props: AnchorHTMLProps) => <a {...props} />);

export const ButtonLink = makeButton(Link);

export const ButtonNavLink = makeButton(NavLink);
