import React from 'react';
import Footer from './Footer';

interface IFooterContainer {
  className?: string;
}

const LanguageSelectContainer = ({ className }: IFooterContainer) => {
  return <Footer className={className} />;
};

export default LanguageSelectContainer;
