import React from 'react'; // eslint-disable-line
import { jsx, keyframes } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface Props {
  className?: string;
}

const ItemLoader = ({ className }: Props) => {
  const { classes } = useStyles();
  return (
    <div className={className} css={classes.root}>
      <svg css={classes.icon} viewBox='0 0 160 32'>
        <path
          css={classes.circle}
          d='M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z'
        />
        <path
          css={classes.circle}
          d='M96 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z'
        />
        <path
          css={classes.circle}
          d='M160 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z'
        />
      </svg>
    </div>
  );
};

const fadeInOut = (color: string) => {
  const animation = keyframes`
  to { fill: ${color}; }
`;

  return {
    '&:nth-of-type(1)': {
      animation: `${animation} 0.6s ${styles.easing.main} 0.1s infinite alternate`,
    },
    '&:nth-of-type(2)': {
      animation: `${animation} 0.6s ${styles.easing.main} 0.2s infinite alternate`,
    },
    '&:nth-of-type(3)': {
      animation: `${animation} 0.6s ${styles.easing.main} 0.3s infinite alternate`,
    },
  };
};

const useStyles = makeStyles({
  base: {
    root: {
      fontSize: '1em',
      display: 'inline-block',
    },
    icon: {
      width: '4em',
      height: '0.8em',
      display: 'block',
    },
    circle: {
      fill: styles.color.lightPurple,
    },
  },
  light: {
    circle: {
      ...fadeInOut(styles.color.purple),
    },
  },
  dark: {
    circle: {
      ...fadeInOut(styles.color.lightBlue),
    },
  },
});

export default ItemLoader;
