import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Icon from 'views/components/Icon';
import styles from 'views/styles';

interface IGoToTop {
  top?: number;
}

export const GoToTop = ({ top = 500 }: IGoToTop) => {
  const { classes } = useStyles();
  const [showGoToTop, setShowGoToTop] = useState<boolean>(false);
  const shouldShow = (pos: number) => pos < -1 * top;

  useScrollPosition(({ currPos }) => {
    return showGoToTop !== shouldShow(currPos.y) && setShowGoToTop(shouldShow(currPos.y));
  });

  return showGoToTop ? (
    <button css={classes.button} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <Icon css={classes.arrow} name='arrow' title='Go to top' />
    </button>
  ) : null;
};

const useStyles = makeStyles({
  base: {
    button: {
      position: 'fixed',
      bottom: '7rem',
      right: '2.5rem',
      color: styles.color.white,
      padding: '1rem',
      borderRadius: '100%',
      cursor: 'pointer',
      fontSize: '0.5rem',
      width: '4rem',
      height: '4rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    arrow: {
      transform: 'rotate(180deg)',
    },
  },
  light: {
    button: {
      backgroundColor: styles.color.purple,
    },
  },
  dark: {
    button: {
      backgroundColor: styles.color.lightBlue,
    },
  },
});
