import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Chip from '@material-ui/core/Chip';
import IconButtonMui from '@material-ui/core/IconButton';
import { isEmpty } from 'lodash';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Icon from 'views/components/Icon';
import styles from 'views/styles';
import styled from '@emotion/styled';
import DateSelector from '../DateSelector';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { useIntl } from 'react-intl';
import { formatMessageByKey } from 'utils/messageUtils';
import { Tooltip } from '@material-ui/core';
import Loader from 'views/components/Loader';
import metadataMessages from 'views/components/Accordion/AccordionFileDetails/metadataMessages';
import {
  whiteListIds,
  whiteListLabels,
  WhiteListIds,
} from 'views/components/providers/PivotingProvider/PivotingWhiteList';
import { isPivotingEnabled } from 'tenants/utils';

const IconButton = styled(IconButtonMui)`
  &.MuiIconButton-root:hover {
    background-color: transparent;
  }
`;

const PivotingFilter = () => {
  const { classes } = useStyles();
  const {
    filter: data,
    operator,
    error,
    loading,
    onSelectFilter,
    onClearFilter,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    onSearchFilter,
  } = usePivoting();
  const intl = useIntl();

  const filterItems = Object.keys(data);
  if (!isPivotingEnabled()) return null;
  return (
    <div id='pivot-search' className='col-11' css={classes.pivotingContainer}>
      <h4 className='h4' css={classes.title}>
        Pivot Search
      </h4>
      <div>
        <div css={classes.container}>
          <DateSelector
            label='Start'
            storageKey={'pivotingFilterStartDate'}
            defaultOption={startDate.option}
            defaultValue={startDate.value}
            onChange={(daysAgo, option) => setStartDate(option, daysAgo)}
          />
          <DateSelector
            label='End'
            storageKey={'pivotingFilterEndDate'}
            maxDays={startDate.option === 'TODAY' ? 'TODAY' : startDate.value}
            defaultOption={endDate.option}
            defaultValue={endDate.value}
            onChange={(daysAgo, option) => setEndDate(option, daysAgo)}
          />
        </div>
        <div css={classes.filter}>
          {loading && <Loader className='h-mb-xs' css={classes.loader} />}
          {!loading && (
            <div css={classes.filterContainer}>
              <div css={classes.filters}>
                {filterItems.map((key: string) => {
                  let formattedLabel;
                  const label = data[key as keyof typeof data];

                  if (whiteListIds.includes(key as WhiteListIds)) {
                    formattedLabel = whiteListLabels[key as WhiteListIds];
                  } else {
                    const section = key.split(' | ')[0];
                    const keyValue = key.split(' | ')[1];
                    formattedLabel = `${section} | ${formatMessageByKey(
                      intl,
                      keyValue,
                      metadataMessages
                    )}`;
                  }

                  formattedLabel = `${formattedLabel}${operator[key] || ':'} ${label.join(' or ')}`;

                  return (
                    <Tooltip title={formattedLabel} placement='top'>
                      <Chip
                        size='small'
                        css={classes.chip}
                        label={
                          formattedLabel.length > 70
                            ? `${formattedLabel.substring(0, 70)}…`
                            : formattedLabel
                        }
                        onDelete={() => onSelectFilter(key, '')}
                      />
                    </Tooltip>
                  );
                })}
              </div>
              <Tooltip title='Pivot to Metadata Search' placement='top'>
                <IconButton
                  aria-label='pivot search'
                  disableRipple
                  disableFocusRipple
                  onClick={onSearchFilter}
                  disabled={isEmpty(filterItems)}
                  style={{
                    position: 'relative',
                    top: 6,
                    left: 8,
                  }}
                >
                  <Icon css={classes.icon} name='arrow-right' />
                </IconButton>
              </Tooltip>
              {Object.keys(data).length > 0 && (
                <Tooltip title='Clear search terms' placement='top'>
                  <IconButton
                    onClick={onClearFilter}
                    aria-label='clear search terms'
                    disableRipple
                    disableFocusRipple
                  >
                    <Icon css={classes.iconRemove} name='close' />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}

          {error && <div css={classes.error}>{error}</div>}
        </div>
      </div>
    </div>
  );
};
export default PivotingFilter;

const useStyles = makeStyles({
  base: {
    iconButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    pivotingContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '3.4rem auto',
      padding: '4rem !important',
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
      borderRadius: '0.5rem',
    },
    container: {
      display: 'flex',
      marginTop: '3rem',
      marginBottom: '2rem',
    },
    filter: {
      width: '100%',
      borderRadius: '.5rem',
    },
    filterContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '.5rem',
      padding: '1rem',
    },
    input: {
      width: '100%',
      border: 'none',
      outline: 'none',
      borderBottom: '1px solid #3F3F3F',
      padding: '1rem',
      flexFlow: 'wrap',
    },
    filters: {
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      border: 'none',
      outline: 'none',
      borderBottom: '1px solid #3F3F3F',
      padding: '1rem',
      alignItems: 'center',
      flexFlow: 'wrap',
      maxHeight: '12rem',
      minHeight: '5.2rem',
      overflowY: 'scroll',
    },
    chip: {
      marginRight: '0.5rem',
      marginTop: '0.5rem',
    },
    iconRemove: {
      width: '0.8em',
      height: '0.8em',
      color: styles.color.red,
    },
    error: {
      color: styles.color.red,
      marginTop: '1rem',
    },
    loader: {
      fontSize: '0.3rem !important',
      textAlign: 'center',
    },
    icon: {
      width: '1.5em !important',
      height: '1.2em !important',
    },
    title: {
      fontWeight: 500,
    },
  },
  light: {
    icon: {
      color: styles.color.purple,
    },
    pivotingContainer: {
      backgroundColor: styles.color.white,
    },
    filterContainer: {
      backgroundColor: styles.color.xLightPurple,
    },
  },
  dark: {
    icon: {
      color: styles.color.lightBlue,
    },
    pivotingContainer: {
      backgroundColor: styles.color.darkXBlack,
    },
    filterContainer: {
      backgroundColor: styles.color.xDarkPurple,
    },
    chip: {
      '& .MuiChip-deleteIcon': {
        color: 'rgba(255, 255, 255, 0.6)',
      },
      '& .MuiChip-deleteIcon:hover': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
  },
});
