import React from 'react';
import styled from '@emotion/styled';
import { Integration } from 'models/Integration';
import { CreateIntegrationDialog } from './CreateIntegrationDialog';
import IntegrationsTable from './IntegrationsTable';

interface IIntegrationsTableContainer {
  integrations: Integration[];
  _createIntegration: (integrationData: Partial<Integration>) => void;
  _archiveIntegration: (key: string) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0.5rem 3rem 4rem 0;
`;

const IntegrationsTableContainer = ({
  integrations,
  _createIntegration,
  _archiveIntegration,
}: IIntegrationsTableContainer) => {
  return Array.isArray(integrations) ? (
    <Container>
      <IntegrationsTable results={integrations} archiveIntegration={_archiveIntegration} />
      <CreateIntegrationDialog onIntegrationCreate={_createIntegration} />
    </Container>
  ) : null;
};

export default IntegrationsTableContainer;
