import React, { ReactNode } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

export interface ISetting {
  text: string;
  isShown?: boolean;
  action: ReactNode;
}

const Setting = ({ text, isShown, action }: ISetting) => {
  const { classes } = useStyles();
  return isShown === false ? null : (
    <div css={classes.root}>
      <p className='p1' css={classes.text}>
        {text}
      </p>
      <div css={classes.control}>{action}</div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '100rem',
      margin: `1rem 0`,
    },
    text: {
      flex: 1.5,
    },
    control: {
      flex: 1,
      marginLeft: styles.spacing.grid,
    },
  },
});

export default Setting;
