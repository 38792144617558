import React, { Fragment } from 'react';
import * as yup from 'yup';
import { Formik, FormikHelpers, FormikProps, Field } from 'formik';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
import { formSchema, formMessages } from 'utils/schema/formSchema';
import { btnMessages } from 'views/components/Button';
import { SignupValues, EAccountPlans } from 'models/Account';
import styles from 'views/styles';
import FormInput from 'views/components/form/FormInput';
import FormRadio from 'views/components/form/FormRadio';
import FormSelect from 'views/components/form/FormSelect';
import FormButtonBar from 'views/components/form/CustomForm/FormButtonBar';
import CustomForm from 'views/components/form/CustomForm';
import { accountPlans, getPlanSlugByName } from 'state/utils/account';

interface IComparePlansForm {
  className?: string;
  initialValues?: Partial<IComparePlansFormValues>;
  heading: string;
  isLoading: boolean;
  errorMessage?: string | null;
  onSubmit: (
    values: IComparePlansFormValues,
    actions: FormikHelpers<IComparePlansFormValues>
  ) => void;
  onCancel: () => void;
}

export interface IComparePlansFormValues extends SignupValues {
  confirmEmail: string;
}

const getInitialValues = (values?: Partial<IComparePlansFormValues>): IComparePlansFormValues => ({
  userId: values && values.userId ? values.userId : '',
  teamId: values && values.teamId ? values.teamId : '',
  firstName: '',
  lastName: '',
  plan: values && values.plan ? values.plan : getPlanSlugByName(EAccountPlans.enterprise),
  email: '',
  confirmEmail: '',
  phone: '',
  contactMethod: 'email',
});

const getValidationSchema = (intl: IntlShape) => {
  const schema = formSchema(intl);
  const { firstName, lastName, email, confirmEmail, company, phone } = schema;
  return yup.object().shape({
    firstName: firstName.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.firstName),
      })
    ),
    lastName: lastName.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.lastName),
      })
    ),
    email: email.required(
      intl.formatMessage(formMessages.required, { label: intl.formatMessage(formMessages.email) })
    ),
    confirmEmail,
    company,
    phone,
  });
};

export const messages = defineMessages({
  plan: {
    id: 'plansform.signup.plan',
    defaultMessage: 'Select your plan',
  },
});

const ComparePlansForm = ({
  className,
  initialValues,
  heading,
  isLoading,
  errorMessage,
  onSubmit,
  onCancel,
}: IComparePlansForm) => {
  const intl = useIntl();
  return (
    <Formik
      initialValues={getInitialValues(initialValues)}
      validationSchema={getValidationSchema(intl)}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<IComparePlansFormValues>) => (
        <CustomForm
          testId='comparePlansForm'
          className={className}
          isLoading={isLoading}
          heading={heading}
          renderFields={() => (
            <Fragment>
              <FormRadio
                className='h-mt-sm h-mb-xs'
                name='plan'
                label={intl.formatMessage(messages.plan)}
                options={accountPlans
                  .filter((plan) => plan !== 'anonymous' && plan !== 'community')
                  .map((plan) => ({
                    value: plan,
                    label: intl.formatMessage({ id: `plans.${plan}` }),
                  }))}
              />
              <FormInput
                className='h-mb-sm'
                name='firstName'
                label={intl.formatMessage(formMessages.firstName)}
                errorOnTouched={true}
              />
              <FormInput
                className='h-mb-sm'
                name='lastName'
                label={intl.formatMessage(formMessages.lastName)}
                errorOnTouched={true}
              />
              <FormInput
                className='h-mb-sm'
                name='email'
                label={intl.formatMessage(formMessages.email)}
                errorOnTouched={true}
              />
              <FormInput
                className='h-mb-sm'
                name='confirmEmail'
                label={intl.formatMessage(formMessages.confirmEmail)}
                validate={(value: IComparePlansFormValues['email']) =>
                  value !== formikProps.values.email
                    ? intl.formatMessage(formMessages.confirmEmailMatch)
                    : undefined
                }
                errorOnTouched={true}
              />
              <FormInput
                className='h-mb-sm'
                name='phone'
                label={intl.formatMessage(formMessages.phone)}
                optionalText={intl.formatMessage(formMessages.optional)}
                errorOnTouched={true}
              />
              <FormInput
                className='h-mb-sm'
                name='company'
                label={intl.formatMessage(formMessages.company)}
                errorOnTouched={true}
              />
              <FormSelect
                className='h-mb-sm'
                name='contactMethod'
                label={intl.formatMessage(formMessages.contactMethod)}
                options={[
                  { value: 'email', label: 'Email' },
                  { value: 'phone', label: 'Phone' },
                ]}
              />
              <Field type='hidden' name='userId' />
              <Field type='hidden' name='teamId' />
              <p className='p1 h-mb-xs' style={{ color: styles.color.red }}>
                {errorMessage}
              </p>
            </Fragment>
          )}
          renderButtonBar={() => (
            <FormButtonBar
              disabled={!formikProps.values.email}
              submitBtnText={intl.formatMessage(btnMessages.signup)}
              onCancel={() => {
                formikProps.resetForm();
                onCancel();
              }}
            />
          )}
        />
      )}
    </Formik>
  );
};

export default ComparePlansForm;
