import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import DateRangePicker from './DateRangePicker';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import PanelLoader from 'views/components/Loader/PanelLoader';
import CardError from 'views/components/CardError';
import { getBalanceValues } from 'utils/balance';
import { UseTelemetrySummary } from '../hooks/useRewardsSummary';

export const RewardsSummary = ({ summary }: { summary: UseTelemetrySummary }) => {
  const { classes } = useStyles();
  const { rewardsSummary, loading, error, errorMessage, getRewardsSummary, refetch } = summary;

  let categories = Object.entries(rewardsSummary.categories || {})
    .map(([_, category]) => category)
    .map((category: any) => ({
      ...category,
      trucatedCategoryAmount: getBalanceValues(parseInt(category.category_amount, 10))[0],
    }));
  if (categories.length === 0) {
    // If no rewards, display 1 row with the following values
    categories = [
      {
        category_name: '---',
        category_amount: '--',
        trucatedCategoryAmount: '--',
      },
    ];
  }

  return (
    <div css={classes.rewardsSummary}>
      <span css={classes.title}>Rewards Summary</span>
      {error ? (
        <CardError error={error} errorMessage={errorMessage} retry={refetch} />
      ) : (
        <div css={classes.rewardsData}>
          <div style={{ width: '23rem' }}>
            <DateRangePicker onDateChange={(start, end) => getRewardsSummary({ start, end })} />
          </div>
          <div style={{ position: 'relative' }}>
            {loading ? (
              <PanelLoader />
            ) : (
              <div css={classes.data}>
                {categories.map((category) => (
                  <div css={classes.row}>
                    <span>{category.category_name}</span>
                    <span css={classes.bold}>{category.trucatedCategoryAmount}</span>
                  </div>
                ))}
                <hr style={{ border: `1px solid ${styles.color.black}`, margin: '2rem 0' }} />
                <div css={classes.row}>
                  <span css={classes.totalRange}>Total NCT for selected Range</span>
                  <span css={[classes.bold, classes.primary, classes.fontXl]}>
                    {rewardsSummary.truncatedAmount!}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    rewardsSummary: {
      gridRow: 'span 2',
      color: 'white',
      padding: '3rem',
      borderRadius: '1rem',
    },
    rewardsData: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridColumnGap: '4rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2rem',
      marginBottom: '1.5rem',
      display: 'block',
    },
    data: {
      color: styles.color.black,
      padding: '1rem',
      background: styles.color.white,
      borderRadius: '0.5rem',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.4rem',
      fontSize: '1.3rem',
      alignItems: 'baseline',
    },
    bold: {
      fontWeight: 600,
    },
    fontXl: {
      fontSize: '2.3rem',
    },
    totalRange: {
      marginRight: '1rem',
    },
  },
  light: {
    rewardsSummary: {
      backgroundColor: styles.color.purple,
    },
    primary: {
      color: styles.color.purple,
    },
  },
  dark: {
    rewardsSummary: {
      backgroundColor: styles.color.xxDarkPurple,
    },
    primary: {
      color: styles.color.blue,
    },
  },
});
