import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Dispatch } from 'state/types/thunk';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import styles from 'views/styles';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

const messages = defineMessages({
  button: {
    id: 'rewardsTab.faCheck.modal.button',
    defaultMessage: 'Check',
  },
  heading: {
    id: 'rewardsTab.faCheck.modal.heading',
    defaultMessage: '2FA Check',
  },
  description: {
    id: 'rewardsTab.faCheck.modal.description',
    defaultMessage:
      "You'll now be redirected to the PolySwarm authO login page to enter your two factor authentication",
  },
});

const FACheck = () => {
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();

  const { onSubmit, loading } = useSelector(
    ({ modal }: { modal: ModalState<{ onSubmit: () => void; loading: boolean }> }) => modal.params
  );

  const _closeModal = () => dispatch(closeModal());

  return (
    <ConfirmModal
      testId='faCheckModal'
      isLoading={loading}
      css={ownStyle}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.description)}
      submitBtnText={intl.formatMessage(messages.button)}
      disabled={false}
      onSubmit={onSubmit}
      onCancel={_closeModal}
      buttonsDirection='vertical'
    />
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default FACheck;
