import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { ModalState } from 'state/modal';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { closeModal } from 'state/modal/actions';
import styles from 'views/styles';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import successImg from 'assets/images/success.svg';

export interface RedeemPreviewModalParams {
  total: string;
  address: string;
}

const messages = defineMessages({
  button: {
    id: 'rewardsTab.redeemSucess.modal.button',
    defaultMessage: 'OK',
  },
  sentTo: {
    id: 'rewardsTab.redeemSucess.modal.sentTo',
    defaultMessage: 'Sent to',
  },
  redemptionSuccessful: {
    id: 'rewardsTab.redeemSucess.modal.redemptionSuccessful',
    defaultMessage: 'Redemption is being processed',
  },
  redemptionSuccessfulAmount: {
    id: 'rewardsTab.redeemSucess.modal.redemptionSuccessfulAmount',
    defaultMessage: 'Processing {total_amount_fees_included}',
  },
});

const RedeemPreview = () => {
  const intl = useIntl();
  const { theme } = useStyles();
  const dispatch = useDispatch();

  const { total, address } = useSelector(
    ({ modal }: { modal: ModalState<RedeemPreviewModalParams> }) => modal.params
  );

  return (
    <ConfirmModal
      testId='redeemSucessModal'
      isLoading={false}
      css={ownStyle}
      fullWidth={true}
      content={
        <div css={containerStyle} className={theme}>
          <img src={successImg} alt='success' />
          <span className='title'>{intl.formatMessage(messages.redemptionSuccessful)}</span>
          <div className='amountContainer'>
            <span className='amount'>
              {intl.formatMessage(messages.redemptionSuccessfulAmount, {
                total_amount_fees_included: total,
              })}
            </span>
            <span className='nct'>NCT redemption</span>
          </div>
          <div className='sentTo'>
            <span>{intl.formatMessage(messages.sentTo)}</span>
            <span>{address}</span>
          </div>
        </div>
      }
      submitBtnText={intl.formatMessage(messages.button)}
      onSubmit={() => dispatch(closeModal())}
    />
  );
};

const useStyles = makeStyles();

const ownStyle = css`
  padding: ${styles.spacing.xs};
  width: 600px;
`;

const containerStyle = css`
  & .title {
    font-size: 2.3rem;
    color: ${styles.color.purple};
    display: block;
  }
  &.dark .title {
    color: ${styles.color.blue};
  }

  & .amountContainer,
  .title,
  img {
    margin-bottom: 3rem;
  }

  & .amount {
    font-size: 3rem;
    color: ${styles.color.purple};
  }
  &.dark .amount {
    color: ${styles.color.blue};
  }

  & .nct {
    font-size: 2rem;
    margin-left: 0.3rem;
    font-weight: bold;
    color: ${styles.color.black};
  }

  & .sentTo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: '1.3rem';
    color: ${styles.color.lightGrey};
  }
  &.dark .sentTo,
  &.dark .nct {
    color: ${styles.color.xxLightGrey};
  }
`;

export default RedeemPreview;
