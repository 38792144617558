import React, { useContext } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { AuthContext } from 'views/components/providers/AuthProvider';
import ability from 'config/ability';

interface Props extends RouteProps {
  canI?: string;
  a?: string;
}

/**
 * Decorated route for protected pages.
 *
 * This component takes the same props as Route and conditionally renders the page
 * component depending on user authentication state. Unauthenticated renders are
 * redirected to the root page.
 *
 * To use this component, always pass `component` prop instead of `render` prop as this
 * component uses `render` internally.
 */
const ProtectedRoute = <T extends Props>({ component: Component, canI, a, ...rest }: T) => {
  const { isAuthenticated, login } = useContext(AuthContext);
  return (
    // @ts-ignore
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && Component) {
          if (canI && a && ability.cannot(canI, a)) {
            return <Redirect to='/' />;
          } else {
            return <Component {...props} />;
          }
        } else {
          login(props.location.pathname);
        }
      }}
    />
  );
};

export default ProtectedRoute;
