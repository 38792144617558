import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { PageQuery } from 'models/Page';
import { Ruleset } from 'models/Ruleset';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys } from 'utils/error';
import { closeModal } from 'state/modal/actions';
import { HuntingActionName } from 'state/hunting/types';
import { deleteRuleset } from 'state/hunting/actions';
import { btnMessages } from 'views/components/Button';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

export interface DeleteRulesetModalParams {
  id: string;
  queryParams: PageQuery<Ruleset>;
}

const messages = defineMessages({
  heading: {
    id: 'rulesets.modal.deleteHeading',
    defaultMessage: 'Delete Ruleset',
  },
  text: {
    id: 'rulesets.modal.deleteText',
    defaultMessage: 'Are you sure you want to delete this ruleset?',
  },
});

const errorKeys: ErrorKeys = {};

const DeleteRulesetModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [HuntingActionName.DELETE_RULESET]);
  const error = errorSelector(requests, [HuntingActionName.DELETE_RULESET]);

  const { id, queryParams } = useSelector(
    ({ modal }: { modal: ModalState<DeleteRulesetModalParams> }) => modal.params
  );

  return (
    <ConfirmModal
      testId='deleteRulesetModal'
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text)}
      submitBtnText={intl.formatMessage(btnMessages.delete)}
      submitBtnColor='secondary'
      onSubmit={() => dispatch(deleteRuleset(id, queryParams))}
      onCancel={() => dispatch(closeModal())}
    />
  );
};

export default DeleteRulesetModal;
