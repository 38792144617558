import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

export interface Section {
  label: string;
  title: string;
  active?: boolean;
}

interface IActiveSections {
  sections: Section[];
}

export const ActiveSections = ({ sections }: IActiveSections) => {
  const { classes } = useStyles();

  return (
    <div css={classes.container}>
      <div>
        {sections.map((section) => (
          <Tooltip title={section.title} placement='top'>
            <span
              onClick={
                section.active
                  ? () => (window.location.hash = section.title.toLowerCase().split(' ').join('_'))
                  : undefined
              }
              css={[classes.section, section.active ? classes.active : classes.inactive]}
            >
              {section.label}
            </span>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  base: {
    container: {
      marginBottom: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    section: {
      display: 'inline-block',
      padding: '0.5rem 1rem',
      marginRight: '1.5rem',
      color: styles.color.white,
    },
    active: {
      cursor: 'pointer',
    },
    inactive: {
      cursor: 'not-allowed',
    },
  },
  light: {
    section: {
      backgroundColor: styles.color.xxLightGrey,
    },
    active: {
      backgroundColor: styles.color.purple,
    },
  },
  dark: {
    section: {
      backgroundColor: styles.color.xDarkPurple,
    },
    active: {
      backgroundColor: styles.color.lightBlue,
    },
  },
}));
