import { Action } from 'redux';
import { AsyncAction } from 'state/types/actions';
import { NormalizedSearchResult } from 'models/Submission';
import { SubmissionResultRes } from 'services/api/schema/submission';

export enum SubmissionActionName {
  GET_FILE_SIZE_LIMIT = 'GET_FILE_SIZE_LIMIT',
  START_FILE_UPLOAD = 'START_FILE_UPLOAD',
  SUBMIT_FILE = 'SUBMIT_FILE',
  CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD',
  SUBMIT_URL = 'SUBMIT_URL',
  GET_SUBMISSION_UUID = 'GET_SUBMISSION_UUID',
  GET_SUBMISSION = 'GET_SUBMISSION',
  GET_HASH_SEARCH_RESULTS = 'GET_HASH_SEARCH_RESULTS',
  REFRESH_HASH_SEARCH_RESULTS = 'REFRESH_HASH_SEARCH_RESULTS',
  CLEAR_HASH_SEARCH_RESULTS = 'CLEAR_HASH_SEARCH_RESULTS',
  GET_FILE_DETAILS = 'GET_FILE_DETAILS',
  RESCAN_FILE = 'RESCAN_FILE',
  RESCAN_FILE_FAILURE = 'RESCAN_FILE_FAILURE',
  SET_LOADING_STATE = 'SET_LOADING_STATE',
}

export interface GetFileSizeLimitAction
  extends AsyncAction<SubmissionActionName.GET_FILE_SIZE_LIMIT, number> {}

export interface StartFileUploadAction extends Action<SubmissionActionName.START_FILE_UPLOAD> {
  cancel: () => void;
}

export interface SubmitFileAction
  extends AsyncAction<SubmissionActionName.SUBMIT_FILE, SubmissionResultRes> {}

export interface CancelFileUploadAction extends Action<SubmissionActionName.CANCEL_FILE_UPLOAD> {}

export interface SubmitUrlAction
  extends AsyncAction<SubmissionActionName.SUBMIT_URL, SubmissionResultRes> {}

export interface GetSubmissionUuidAction
  extends AsyncAction<SubmissionActionName.GET_SUBMISSION_UUID, SubmissionResultRes> {}

export interface GetSubmissionAction
  extends AsyncAction<SubmissionActionName.GET_SUBMISSION, SubmissionResultRes> {}

export interface GetHashSearchResultsAction
  extends AsyncAction<SubmissionActionName.GET_HASH_SEARCH_RESULTS, NormalizedSearchResult[]> {
  page: number;
}

export interface RefreshHashSearchResultsAction
  extends AsyncAction<SubmissionActionName.REFRESH_HASH_SEARCH_RESULTS, NormalizedSearchResult[]> {
  page: number;
}

export interface ClearHashSearchResultsAction
  extends Action<SubmissionActionName.CLEAR_HASH_SEARCH_RESULTS> {}

export interface GetFileDetailsAction
  extends AsyncAction<SubmissionActionName.GET_FILE_DETAILS, SubmissionResultRes> {}

export interface RescanFileAction extends Action<SubmissionActionName.RESCAN_FILE> {}

export interface RescanFileFailureAction extends Action<SubmissionActionName.RESCAN_FILE_FAILURE> {}

export interface SetLoadingStateAction extends Action<SubmissionActionName.SET_LOADING_STATE> {}

export type SubmissionAction =
  | GetFileSizeLimitAction
  | StartFileUploadAction
  | SubmitFileAction
  | CancelFileUploadAction
  | SubmitUrlAction
  | GetSubmissionUuidAction
  | GetSubmissionAction
  | GetHashSearchResultsAction
  | RefreshHashSearchResultsAction
  | ClearHashSearchResultsAction
  | GetFileDetailsAction
  | RescanFileAction
  | RescanFileFailureAction
  | SetLoadingStateAction;
