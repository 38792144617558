import React, { useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { openModal, closeModal } from 'state/modal/actions';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { useUser } from 'views/components/providers/UserProvider';
import { ReactComponent as Nectar } from 'assets/images/nec.svg';
import { RedeemTransaction } from '../modals/RedeemRewards';
import { loginWalletWithdrawal, getMFAData, removeMFAData } from 'state/auth/actions';
import { RewardsWalletRes, WithdrawalRes } from '../hooks/useRewardsWallet';
import { RewardsSummary } from './RewardsSummary';
import { Balance } from './Balance';
import { UseTelemetrySummary } from '../hooks/useRewardsSummary';

const messages = defineMessages({
  currentBalance: {
    id: 'rewardsTab.rewardsInfo.currentBalance',
    defaultMessage: 'Current Balance',
  },
});

export interface RewardsInfoProps {
  rewardsWallet: Partial<RewardsWalletRes> | null;
  loading: boolean;
  startWithdrawal: (
    amount: number | string,
    fee: number | string,
    feeTimestamp?: string,
    feeSign?: string,
  ) => Promise<WithdrawalRes>;
  summary: UseTelemetrySummary;
}

export const RewardsInfo: React.FC<RewardsInfoProps> = ({
  rewardsWallet,
  loading,
  startWithdrawal,
  summary,
}) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const accountNumber = user.context?.accountNumber || user.accountNumber;
  let enableActions = true;
  if (rewardsWallet && Number(rewardsWallet.balance) === 0) {
    enableActions = false;
  }
  const configureWithdrawalsAlert = () => dispatch(openModal('CONFIGURE_WITHDRAWALS_ALERT'));
  const requestMFA = (modalName: string, data: any = {}) =>
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWalletWithdrawal({
              modal: modalName,
              location: location.pathname,
              accountNumber,
              ...data,
            })
          );
        },
      })
    );
  const redeemPreview = ({
    mfa,
    amount,
    fee,
    total,
    feeTimestamp,
    feeSign,
    onSuccess,
  }: RedeemTransaction & { onSuccess: () => void }) =>
    dispatch(
      openModal('REDEEM_PREVIEW', {
        mfa,
        address: rewardsWallet?.withdrawal_address,
        amount,
        fee,
        total,
        feeTimestamp,
        feeSign,
        onSuccess,
      })
    );
  const startWithdrawalAction = async ({ amount, fee, total, feeTimestamp, feeSign }: RedeemTransaction) => {
    const response = await startWithdrawal(amount, fee, feeTimestamp, feeSign);
    if (response.success) {
      dispatch(
        openModal('REDEEM_SUCCESS', {
          address: rewardsWallet?.withdrawal_address,
          total,
        })
      );
    } else {
      dispatch(closeModal());
    }
  };
  const redeemRewards = () =>
    dispatch(
      openModal('REDEEM_REWARDS', {
        address: rewardsWallet?.withdrawal_address,
        balance: rewardsWallet?.fullBalance,
        limit: rewardsWallet?.fullLimit,
        withdrawalsLimitMin: rewardsWallet?.withdrawals_limit_min,
        onSubmit: ({ amount, fee, total, feeTimestamp, feeSign }: RedeemTransaction) =>
          redeemPreview({
            amount,
            fee,
            total,
            feeTimestamp,
            feeSign,
            onSuccess: () => {
              requestMFA('REDEEM_PREVIEW', {
                amount,
                fee,
                total,
                feeTimestamp,
                feeSign,
              });
            },
          }),
      })
    );
  const modal = rewardsWallet?.withdrawal_address ? redeemRewards : configureWithdrawalsAlert;

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal, ...data } = mfaStep;
      if (modal === 'REDEEM_PREVIEW') {
        redeemPreview({
          ...data,
          mfa: true,
          onSuccess: () => {
            startWithdrawalAction(data);
          },
        });
        removeMFAData();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return rewardsWallet && !loading ? (
    <div css={classes.container}>
      <div css={classes.currentBalance}>
        <Nectar css={classes.nectar} />
        <span css={classes.title}>{intl.formatMessage(messages.currentBalance)}</span>
        <span>
          <Balance
            truncatedValue={rewardsWallet.truncatedBalance!}
            value={rewardsWallet.fullBalance!}
          />
        </span>
      </div>
      <RewardsSummary summary={summary} />
      <div css={classes.redeemContainer}>
        <Button
          style={{ fontSize: '1.5rem' }}
          color='primary'
          variant='contained'
          onClick={modal}
          disabled={!enableActions}
        >
          Redeem Rewards
        </Button>
      </div>
    </div>
  ) : null;
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      gridTemplateRows: '3fr 1fr',
      padding: '3rem',
      gridGap: '3rem',
    },
    currentBalance: {
      overflow: 'hidden',
      position: 'relative',
      color: 'white',
      padding: '3rem',
      borderRadius: '1rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2rem',
      marginBottom: '1.5rem',
      display: 'block',
    },
    redeemContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    nectar: {
      position: 'absolute',
      left: '-5.74%',
      top: '9.02%',
      mixBlendMode: 'lighten',
      opacity: 0.7,
      pointerEvents: 'none',
    },
    data: {
      background: styles.color.white,
      padding: '1rem',
      color: styles.color.black,
      borderRadius: '0.5rem',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.4rem',
      fontSize: '1.3rem',
    },
    bold: {
      fontWeight: 600,
    },
    center: {
      textAlign: 'center',
    },
    calendar: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridGap: '0.5rem',
      background: styles.color.white,
      color: styles.color.grey,
      padding: '1rem',
      borderRadius: '0.4rem',
      alignItems: 'center',
      marginBottom: '1rem',
    },
  },
  light: {
    currentBalance: {
      backgroundColor: styles.color.purple,
    },
  },
  dark: {
    currentBalance: {
      backgroundColor: styles.color.xxDarkPurple,
    },
  },
});
