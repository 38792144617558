import React, { Fragment } from 'react';
import { TableCell } from '@material-ui/core';
import styled from '@emotion/styled';
import ActionBtn from 'views/components/table/ActionBtn';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import { HashRow } from '../types';
import { parseScanPageURL } from 'views/url';

interface ISearchTableRow {
  rowData: HashRow;
}

const HoverableTableRow = styled(BaseHoverableTableRow)`
  cursor: pointer;
`;

const Actions = styled.div`
  white-space: nowrap;
`;

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const SearchTableRow = ({ rowData }: ISearchTableRow) => {
  return (
    <Fragment>
      <HoverableTableRow
        renderCells={() => (
          <Fragment>
            <TableCell>
              <span>
                {rowData.hash} <CopyButton text={rowData.hash} />
              </span>
            </TableCell>
            <TableCell align='right'>
              <Actions>
                <ActionBtn
                  name='open-view'
                  onClick={() => window.open(parseScanPageURL(rowData.hash, { type: 'file' }), '_blank')}
                />
              </Actions>
            </TableCell>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default SearchTableRow;
