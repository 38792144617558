import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';
import styles from 'views/styles';

export default () => (
  <Fragment>
    <symbol id='icon-close' viewBox='0 0 32 32'>
      <path d='M31.484 3.040l-2.524-2.524-12.96 12.978-12.96-12.978-2.524 2.524 12.978 12.96-12.978 12.96 2.524 2.524 12.96-12.978 12.96 12.978 2.524-2.524-12.978-12.96z' />
    </symbol>

    <Global
      styles={css`
        .icon-close {
          width: ${styles.icon.width};
          height: ${styles.icon.height};
        }
      `}
    />
  </Fragment>
);
