import React, { useState, useEffect, useCallback, useRef } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage, defineMessages } from 'react-intl';
import { RequestError } from 'utils/error';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Button from '@material-ui/core/Button';
import styles from 'views/styles';
import ScanSearchInput from './ScanSearchInput';
import FileSelect from './FileSelect';
import { ZipPassword } from 'views/components/ZipPassword';
import Icon from 'views/components/Icon';
import { Checkbox } from '@material-ui/core';

interface Props {
  isDragActive?: boolean;
  isInputInitiallyVisible?: boolean;
  selectFileWhenMounted?: boolean;
  error?: RequestError;
  onFileSelect: (
    files: File[],
    checkZip?: boolean,
    zipConfig?: { isZip: boolean; zipPassword?: string },
    isQRCode?: boolean
  ) => void;
  onScanSearch: (query: string) => void;
  cleanError: () => void;
  file?: File;
  isZipFile?: boolean;
  couldBeQRCode?: boolean;
}

const messages = {
  scan: defineMessages({
    heading: {
      id: 'scanPage.fileupload.scan.heading',
      defaultMessage: 'Scan a file for threats',
    },
    text: {
      id: 'scanPage.fileupload.scan.text',
      defaultMessage: 'Drop your file to upload',
    },
  }),
  dragover: defineMessages({
    heading: {
      id: 'scanPage.fileupload.dragover.heading',
      defaultMessage: 'Drop your file',
    },
    text: {
      id: 'scanPage.fileupload.dragover.text',
      defaultMessage: 'Anywhere on the screen',
    },
  }),
};

const ScanFileState = ({
  isDragActive,
  selectFileWhenMounted,
  error,
  onFileSelect,
  onScanSearch,
  cleanError,
  file,
  isZipFile = false,
  couldBeQRCode = false,
}: Props) => {
  const inputRef = useRef<HTMLLabelElement>(null);
  const [zipConfig, setZipConfig] = useState<{
    isZip: boolean;
    zipPassword?: string;
  }>();
  const [isQRCode, setIsQRCode] = useState<boolean>(false);

  const { classes } = useStyles(isDragActive);

  const _clickFileInput = useCallback(
    () => inputRef.current && inputRef.current.click(),
    [inputRef]
  );

  // Only runs on mount to prevent multiple file select prompts
  useEffect(() => {
    if (selectFileWhenMounted) {
      _clickFileInput();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const title = isDragActive ? 'dragover' : 'scan';

  return (
    <div css={[classes.root, classes.dropZone]} data-cy='scanFileState'>
      <h2 className='h2 h-mb-xxs'>
        <FormattedMessage {...messages[title].heading} />
      </h2>
      {!file ? (
        <>
          <p className='p'>
            <FormattedMessage {...messages[title].text} />
          </p>

          <div className='h-mt-md h-mb-sm' css={classes.dragIconWrap} onClick={_clickFileInput}>
            <Icon css={classes.dragIcon} name='drag' />
          </div>

          <FileSelect
            id='scan-file-state-select'
            className='h-mb-xs'
            innerRef={inputRef}
            onSelection={onFileSelect}
          >
            <Button
              component='span'
              color='primary'
              variant='contained'
              style={{ fontSize: '2.4rem' }}
            >
              <FormattedMessage id='scanPage.fileupload.scan.button' defaultMessage='Select file' />
            </Button>
          </FileSelect>
        </>
      ) : (
        <div>{file.name}</div>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <ZipPassword isShown={isZipFile} handleChange={setZipConfig} />

        {couldBeQRCode && (
          <div css={classes.container}>
            <Checkbox
              color='primary'
              id='isQRCode'
              checked={isQRCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsQRCode(e.target.checked)}
            />
            <label css={classes.label} htmlFor='zipWithPassword'>
              Is QR Code (only QR Codes with URLs are supported)
            </label>
          </div>
        )}

        {(isZipFile || couldBeQRCode) && (
          <Button
            size='large'
            type='button'
            color='primary'
            variant='contained'
            onClick={() => {
              couldBeQRCode
                ? onFileSelect([file!], false, undefined, isQRCode)
                : onFileSelect([file!], false, zipConfig, false);
            }}
          >
            Scan
          </Button>
        )}
      </div>

      <ScanSearchInput
        onFileSelect={(file) => {
          onFileSelect([file!], false, undefined, true);
        }}
        onChange={cleanError}
        onSubmit={onScanSearch}
        error={error}
      />
    </div>
  );
};

const useStyles = makeStyles((isDragActive?: boolean) => ({
  base: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 1,
      textAlign: 'center',
    },
    dropZone: {
      '&:before': {
        content: '""',
        display: 'block',
        position: 'fixed',
        top: '1.6rem',
        right: '1.6rem',
        bottom: '1.6rem',
        left: '1.6rem',
        zIndex: 1,
        pointerEvents: isDragActive ? 'auto' : 'none',
        borderWidth: 2,
        borderStyle: 'dashed',
        borderRadius: styles.border.radius,
      },
    },
    dragIconWrap: {
      position: 'relative',
      display: 'inline-block',
      zIndex: 2,
    },
    dragIcon: {
      cursor: 'pointer',
      fontSize: '5.2rem',
      transition: isDragActive ? 'none' : `color ${styles.easing.time} ${styles.easing.main}`,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-1.2rem',
    },
    label: {
      fontSize: '1.2rem',
    },
  },
  light: {
    dropZone: {
      '&:before': {
        borderColor: isDragActive ? styles.color.purple : 'transparent',
        backgroundColor: isDragActive ? 'rgba(109, 58, 236, 0.07)' : 'transparent',
      },
    },
    dragIcon: {
      color: isDragActive ? styles.color.purple : styles.color.xxLightGrey,
      '&:hover': {
        color: styles.color.xLightGrey,
      },
      '&:active': {
        color: styles.color.purple,
      },
    },
  },
  dark: {
    dropZone: {
      '&:before': {
        borderColor: isDragActive ? styles.color.xxLightGrey : 'transparent',
        backgroundColor: isDragActive ? 'rgba(172, 172, 187, 0.07)' : 'transparent',
      },
    },
    dragIcon: {
      color: isDragActive ? styles.color.lightBlue : styles.color.medDarkPurple,
      '&:hover': {
        color: styles.color.xLightGrey,
      },
      '&:active': {
        color: styles.color.lightBlue,
      },
    },
  },
}));

export default ScanFileState;
