import { AsyncAction } from 'state/types/actions';
import { Action } from 'redux';
import {
  AccountPlansRes,
  AccountContextRes,
  PaymentMethodsRes,
  SubscriptionTransactionsRes,
  PaymentMethodRes,
} from 'services/api/schema/account';
import { IPaymentPlans } from 'models/Account';

export enum AccountActionName {
  GET_ACCOUNT_PLANS = 'GET_ACCOUNT_PLANS',
  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',
  GET_ACCOUNT_CONTEXT = 'GET_ACCOUNT_CONTEXT',
  GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS',
  ATTACH_PAYMENT_METHOD = 'ATTACH_PAYMENT_METHOD',
  DETACH_PAYMENT_METHOD = 'DETACH_PAYMENT_METHOD',
  SET_DEFAULT_PAYMENT_METHOD = 'SET_DEFAULT_PAYMENT_METHOD',
  GET_PAYMENT_PLANS = 'GET_PAYMENT_PLANS',
  GET_SUBSCRIPTION_TRANSACTIONS = 'GET_SUBSCRIPTION_TRANSACTIONS',
}

export interface GetAccountPlansAction
  extends AsyncAction<AccountActionName.GET_ACCOUNT_PLANS, AccountPlansRes> {}

export interface SwitchAccountAction
  extends AsyncAction<AccountActionName.SWITCH_ACCOUNT, AccountContextRes> {}

export interface GetAccountContextAction
  extends AsyncAction<AccountActionName.GET_ACCOUNT_CONTEXT, AccountContextRes> {}

export interface AttachPaymentMethodAction
  extends AsyncAction<AccountActionName.ATTACH_PAYMENT_METHOD, PaymentMethodRes> {}

export interface DetachPaymentMethodAction extends Action<AccountActionName.DETACH_PAYMENT_METHOD> {
  id: string;
}

export interface SetDefaultPaymentMethodAction
  extends Action<AccountActionName.SET_DEFAULT_PAYMENT_METHOD> {
  id: string;
}

export interface GetPaymentMethodsAction
  extends AsyncAction<AccountActionName.GET_PAYMENT_METHODS, PaymentMethodsRes> {}

export interface GetSubscriptionTransactionsAction
  extends AsyncAction<
    AccountActionName.GET_SUBSCRIPTION_TRANSACTIONS,
    SubscriptionTransactionsRes
  > {}

export interface GetPaymentPlansAction extends Action<AccountActionName.GET_PAYMENT_PLANS> {
  paymentPlans: IPaymentPlans;
}

export type AccountAction =
  | GetAccountPlansAction
  | SwitchAccountAction
  | GetAccountContextAction
  | GetPaymentMethodsAction
  | GetPaymentPlansAction
  | GetSubscriptionTransactionsAction
  | AttachPaymentMethodAction
  | DetachPaymentMethodAction
  | SetDefaultPaymentMethodAction;
