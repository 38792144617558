import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { useIntl, defineMessages } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

const messages = defineMessages({
  title: {
    id: 'termsPage.title',
    defaultMessage: 'Terms of service',
  },
});

const Wrapper = styled(Card)`
  padding: calc(30px * 1.5);
  margin-top: 3.2rem !important;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;
    margin-left: 2rem;

    li {
      padding: 0.5rem 0 0.5rem 0;
    }
  }

  ol {
    list-style: decimal;
    counter-reset: item;

    li {
      padding: 0.5rem 0 0.5rem 0;

      & > ol {
        margin-left: 0;
      }
    }
  }
`;

const Header = styled.h1`
  font-size: ${styles.font.size.h3};
`;

const SubHeader = styled.div`
  font-size: ${styles.font.size.h5};
`;

const LetterList = styled.ol`
  margin-left: 1.5rem;
  list-style: none !important;
  counter-reset: list !important;

  li:before {
    content: '(' counter(list, lower-alpha) ') ';
    counter-increment: list;
  }
`;

const TermsPageContent = () => {
  const { classes } = useStyles();

  return (
    <Wrapper className='container h-mt-lg h-mb-lg'>
      <ScrollToTopOnMount />
      <Typography paragraph>
        <Header css={classes.header}>TERMS OF SERVICE</Header>
        <SubHeader css={classes.subheader}>Last Updated: May 30, 2022</SubHeader>
      </Typography>
      <Typography paragraph>
        Thank you for using the PolySwarm Platform (as defined below), a decentralized threat
        detection marketplace that allows enterprise customers to offer bounties on potentially
        malicious files, hashes, or URLs (&ldquo;
        <strong>
          <em>Submitted Artifacts</em>
        </strong>
        &rdquo;) to independent, crowd-sourced security experts (&ldquo;
        <strong>
          <em>Security Experts</em>
        </strong>
        &rdquo;), who have developed anti-malware engines for the purpose of scanning Submitted
        Artifacts, and that operate within specified Public Communities or Private Communities
        within the marketplace.
      </Typography>
      <Typography paragraph>
        To be eligible to register for a PolySwarm account and use PolySwarm Platform, you must
        review and accept the terms of this PolySwarm Terms of Service (this &ldquo;
        <strong>
          <em>Agreement</em>
        </strong>
        &rdquo; or these &ldquo;
        <strong>
          <em>Terms</em>
        </strong>
        &rdquo;). PLEASE REVIEW THESE TERMS CAREFULLY. BY ACCEPTING THESE TERMS OR USING THE
        POLYSWARM PLATFORM, YOU AGREE TO THESE TERMS AND CONDITIONS WITH SWARM TECHNOLOGIES INC.
        (&ldquo;
        <strong>
          <em>PolySwarm</em>
        </strong>
        &rdquo;). IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, YOU SHOULD NOT CREATE A POLYSWARM
        ACCOUNT OR USE THE POLYSWARM PLATFORM.
      </Typography>
      <Typography paragraph>
        In this Agreement, &ldquo;you,&rdquo; &ldquo;your&rdquo; and &ldquo;Customer&rdquo; will
        refer to you. If you are registering for a PolySwarm account or using the PolySwarm Platform
        on behalf of an entity or other organization, you are agreeing to these Terms for that
        entity or organization and representing to PolySwarm that you have the authority to bind
        that entity or organization to these Terms (and, in which case, the terms &ldquo;you&rdquo;,
        &ldquo;your&rdquo; and &ldquo;Customer&rdquo; will refer to that entity or organization).
      </Typography>
      <Typography paragraph>
        PolySwarm may revise these Terms from time to time. If PolySwarm does revise these Terms,
        the revised Terms will supersede prior versions. Unless PolySwarm says otherwise, revisions
        will be effective upon the effective date indicated at the top of these Terms. PolySwarm
        will provide you advance notice of any material revisions. This notice will be provided via
        the account portal and/or via an email to the email address PolySwarm has on file. For other
        revisions, PolySwarm will update the effective date of these Terms at the top of the page.
        PolySwarm encourages you to check the effective date of these Terms whenever you visit
        PolySwarm&rsquo;s website or account portal. Your continued access or use of the PolySwarm
        Platform constitutes your acceptance of any revisions. If you do not agree to the revisions,
        you should stop using the PolySwarm Platform and PolySwarm is not obligated to provide you
        with the PolySwarm Platform.
      </Typography>
      <Typography paragraph>IMPORTANT NOTES:</Typography>
      <Typography component='div' paragraph>
        <ul>
          <li>
            POLYSWARM DOES NOT PROVIDE WARRANTIES OR INDEMNITIES FOR THE POLYSWARM PLATFORM, AND
            THESE TERMS LIMIT POLYSWARM&rsquo;S LIABILITY TO YOU.
          </li>
          <li>
            DISPUTES RELATING TO THIS AGREEMENT, YOUR ACCOUNT OR THE POLYSWARM PLATFORM MUST BE
            RESOLVED BY BINDING ARBITRATION AND ON AN INDIVIDUAL BASIS ONLY.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>1 Definitions</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        1.1 The following terms, when used in this Agreement will have the following meanings:
      </Typography>
      <Typography paragraph>
        <strong>
          <em>&ldquo;Confidential Information&rdquo;</em>
        </strong>
        &nbsp; means any information or data disclosed by either party that is marked or otherwise
        designated as confidential or proprietary or that should otherwise be reasonably understood
        to be confidential in light of the nature of the information and the circumstances
        surrounding disclosure. However, &ldquo;Confidential Information&rdquo; will not include any
        information which (a) is in the public domain through no fault of the receiving party; (b)
        was properly known to the receiving party, without restriction, prior to disclosure by the
        disclosing party; (c) was properly disclosed to the receiving party, without restriction, by
        another person with the legal authority to do so; or (d) is independently developed by the
        receiving party without the use of or reference to the disclosing party&rsquo;s Confidential
        Information.
      </Typography>
      <Typography paragraph>
        <strong>
          <em>&ldquo;Documentation&rdquo;</em>
        </strong>
        &nbsp; means the printed and digital instructions, on-line help files, technical
        documentation and user manuals made available by PolySwarm for the PolySwarm Platform.
      </Typography>
      <Typography paragraph>
        &ldquo;
        <strong>
          <em>Order Form</em>
        </strong>
        &rdquo; means an order form, online order page or other similar document that sets forth the
        components of the PolySwarm Platform to which Customer is obtaining a subscription,
        applicable subscription term, pricing therefor and other relevant terms, and that references
        this Agreement.
      </Typography>
      <Typography paragraph>
        <strong>
          <em>&ldquo;PolySwarm Platform&rdquo;</em>
        </strong>
        &nbsp; means the decentralized threat detection marketplace made available by PolySwarm, and
        all related documentation, browser extensions, software and intellectual property provided
        by PolySwarm in relation thereto.
      </Typography>
      <Typography paragraph>
        <strong>&ldquo;Private Community&rdquo;</strong> means a community in the PolySwarm Platform
        threat detection marketplace set up on behalf of an enterprise customer that (a) selects and
        approves all participants that join such community and (b) defines the rules governing
        storage, sharing, and distribution of Submitted Artifacts in such community.
      </Typography>
      <Typography paragraph>
        <strong>&ldquo;Public Community&rdquo;</strong> means a community in the PolySwarm Platform
        threat detection marketplace that is open to all participants.
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>2. PolySwarm Platform</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        2. 1 <u>Provision of PolySwarm Platform.</u> Subject to the terms and conditions of this
        Agreement, PolySwarm will use commercially reasonable efforts to make the PolySwarm Platform
        available to Customer pursuant to this Agreement, and hereby grants Customer a non-exclusive
        right to access and use the PolySwarm Platform with respect to the Submitted Artifacts, in
        each case during the subscription term of the applicable Order Form and subject to any usage
        limitations or other restrictions set forth in such Order Form. If you are using any
        component of the PolySwarm Platform other than in connection with an Order Form, then your
        right to use the PolySwarm Platform is for your own, personal, non-commercial purposes only
        By using any component of the PolySwarm Platform, you consent to PolySwarm’s collection, use
        and disclosure of personal data and other data as outlined in PolySwarm’s Privacy Policy
        located at https://polyswarm.network/privacy.
      </Typography>
      <Typography paragraph>
        2.2 <u>Customer Limitations.</u> The rights granted herein are subject to the following
        restrictions (the &ldquo;
        <strong>
          <em>License Restrictions</em>
        </strong>
        &rdquo;). Customer will not directly or indirectly:
        <LetterList>
          <li>
            attempt to probe, scan or test the vulnerability of the PolySwarm Platform, breach the
            security or authentication measures of the PolySwarm Platform without proper
            authorization or wilfully render any part of the PolySwarm Platform unusable;
          </li>
          <li>
            attack, or attempt to attack, the PolySwarm Platform using a denial-of-service attack, a
            distributed denial-of-service attack or any other attack;
          </li>
          <li>
            use or access the PolySwarm Platform to develop a product or service that is competitive
            with PolySwarm&rsquo;s products or Product;
          </li>
          <li>
            use the PolySwarm Platform to engage in any misleading or deceptive comparisons
            involving the PolySwarm Platform or other products or services;
          </li>
          <li>
            submit or allow any third party under Customer&rsquo;s control to submit Submitted
            Artifacts that are subject to the International Traffic in Arms Regulations
            (&ldquo;ITAR&rdquo;) maintained by the U.S. Department of State or do anything that
            would cause the PolySwarm Platform to provide a defense service, as defined by ITAR; or
          </li>
          <li>
            otherwise use the PolySwarm Platform (i) to engage in any illegal activity, (ii) to
            infringe or violate any third party rights, or (iii) otherwise outside the scope
            expressly permitted hereunder and in the applicable Order Form.
          </li>
        </LetterList>
      </Typography>
      <Typography paragraph>
        2.3 <u>Customer Responsibilities.</u> Customer will (a) be responsible for all use of the
        PolySwarm Platform under its account, (b) be solely responsible for the legality of
        Submitted Artifacts, (c) use commercially reasonable efforts to prevent unauthorized access
        to or use of the PolySwarm Platform and notify PolySwarm promptly of any such unauthorized
        access or use, (d) be responsible for obtaining and maintaining any equipment, software and
        ancillary services needed to connect to, access or otherwise use the PolySwarm Platform,
        including as set forth in the Documentation, and (e) ensure that the Submitted Artifacts do
        not contain any personal data or personally identifiable information. Customer will be
        solely responsible for using the current version of the APIs made available by PolySwarm,
        and PolySwarm will have no liability for such failure. Customer acknowledges that PolySwarm
        may change, deprecate or republish APIs for the PolySwarm Platform, and that it is
        Customer&rsquo;s responsibility to ensure that calls or requests Customer makes to the
        PolySwarm Platform are compatible with then-current APIs for the PolySwarm Platform.
        Customer acknowledges that open source libraries to communicate with APIs in the PolySwarm
        Platform (and the applicable open source licenses) are made available by PolySwarm upon
        request, and are licensed to Customer pursuant to the terms of the applicable open source
        license and not this Agreement.
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>3. Fees</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        3.1. <u>Fees.</u> If Customer has purchased a subscription or has otherwise agreed to pay
        any fees in an Order Form, Customer will pay PolySwarm the fees set forth in the applicable
        Order Form.
      </Typography>
      <Typography paragraph>
        3.2 <u>Payment.</u> All fees are quoted and payable in United States dollars, all payment
        obligations are non-cancelable and, except as expressly set forth herein, all fees paid are
        non-refundable. If Customer has purchased a subscription, Customer acknowledges that it will
        prepay for a certain number of API requests that it may use over a given period of time
        (e.g., a month), and that if Customer does not use all such allotted API requests, PolySwarm
        will have no obligation to refund any amounts for such unused API requests. If Customer uses
        all allotted API requests before the end of the given period, Customer will have the option
        to purchase additional API requests for that period or upgrade to a plan that provides a
        larger number of allotted API requests. All other fees are due within thirty (30) days of
        the date of the invoice. If Customer is overdue on any payment and fail to pay within ten
        (10) business days of a written notice of your overdue payment, then PolySwarm may assess a
        late fee and/or suspend Customer&rsquo;s account until Customer pays the amount Customer is
        overdue plus the late fee. The late fee will be either 1.5% per month, or the maximum amount
        allowable by law, whichever is less.
      </Typography>
      <Typography paragraph>
        3.3 <u>Net of Taxes.</u> All applicable use, sales and other similar taxes and government
        charges will be payable by Customer. Customer will not withhold any taxes from any amounts
        due to PolySwarm.
      </Typography>
      <Typography paragraph>
        <strong>4. Proprietary Rights and Confidentiality</strong>
      </Typography>
      <Typography paragraph>
        4.1 <u>PolySwarm&rsquo;s Ownership Rights.</u> As between the parties, PolySwarm exclusively
        owns all right, title and interest in and to the PolySwarm Platform. Except for the express
        rights granted hereunder, PolySwarm reserves all rights, title and interests in and to the
        PolySwarm Platform and PolySwarm&rsquo;s Confidential Information.
      </Typography>
      <Typography paragraph>
        4.2 <u>Feedback.</u> Customer may from time to time provide PolySwarm suggestions or
        comments for enhancements or improvements, new features or functionality or other feedback
        (&ldquo;
        <strong>
          <em>Feedback</em>
        </strong>
        &rdquo;) with respect to the PolySwarm Platform. PolySwarm will have full discretion to
        determine whether or not to proceed with the development of any requested enhancements, new
        features or functionality. PolySwarm will have the full, unencumbered right, without any
        obligation to compensate or reimburse Customer, to use, incorporate and otherwise fully
        exercise and exploit any such Feedback in connection with its products and services.
      </Typography>
      <Typography paragraph>
        4.3 <u>Submitted Artifacts.</u> Notwithstanding anything herein, (a) Customer agrees that
        all Submitted Artifacts submitted in Public Communities (and any related metadata and the
        results of any virus scans related thereto) are not Confidential Information of Customer and
        may be freely shared or disclosed by PolySwarm and the Security Experts during and after the
        term hereof, and (b) Security Experts will be required to maintain the confidentiality of
        Submitted Artifacts submitted in Private Communities and the results of any virus scans
        related thereto, except that if any Submitted Artifacts are flagged as containing malicious
        code, such Submitted Artifacts and any data or information related to such malicious code
        may be freely shared or disclosed by PolySwarm and the Security Experts during and after the
        term hereof.
      </Typography>
      <Typography paragraph>
        4.4 <u>Confidentiality.</u> Each party agrees that it will use the Confidential Information
        of the other party solely in accordance with the provisions of this Agreement and it will
        not disclose, or permit to be disclosed, the same directly or indirectly, to any third party
        without the other party&rsquo;s prior written consent, except as otherwise permitted
        hereunder. However, either party may disclose Confidential Information to its employees,
        officers, directors, attorneys, auditors, financial advisors and other representatives who
        have a need to know and are legally bound to keep such information confidential by
        confidentiality obligations consistent with those of this Agreement; and as required by law
        (in which case the receiving party will provide the disclosing party with prior written
        notification thereof, will provide the disclosing party with the opportunity to contest such
        disclosure, and will use its reasonable efforts to minimize such disclosure to the extent
        permitted by applicable law. Neither party will disclose the terms of this Agreement to any
        third party, except that either party may confidentially disclose such terms to actual or
        potential lenders, investors or acquirers. Each party agrees to exercise due care in
        protecting the Confidential Information from unauthorized use and disclosure. In the event
        of actual or threatened breach of the provisions of this Section or the License
        Restrictions, the non-breaching party will be entitled to seek immediate injunctive and
        other equitable relief, without waiving any other rights or remedies available to it. Each
        party will promptly notify the other in writing if it becomes aware of any violations of the
        confidentiality obligations set forth in this Agreement.
      </Typography>
      <Typography paragraph>
        4.5 <u>Aggregated Information.</u> Notwithstanding anything to the contrary, PolySwarm shall
        have the right to aggregate, collect and analyze data and other information relating to the
        provision, use and performance of the PolySwarm Platform and shall be free (during and after
        the term hereof) to (i) use such data and other information to develop and improve the
        PolySwarm Platform and other PolySwarm offerings, and (ii) disclose such data and other
        information solely in an aggregated and anonymized format that does not identify Customer or
        any individual.
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>5. Warranties and Disclaimers</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        5.1 <u>Security Experts.</u> Customer acknowledges that PolySwarm utilizes independent
        crowdsourced Security Experts that can elect (at their discretion) to accept an offer for a
        bounty to perform malware scanning on Submitted Artifacts. While PolySwarm uses commercially
        reasonable efforts to promote the quality of the malware scanning performed by such Security
        Experts, it cannot guarantee that all such malware scanning will be accurate or that any
        Security Expert will accept any offer for a bounty. PolySwarm will require all Security
        Experts to agree to terms that provide for such Security Experts to maintain the
        confidentiality of Customer&rsquo;s Confidential Information and, in the case of Submitted
        Artifacts submitted to a Private Community, only use such Submitted Artifacts to which they
        have access for the purpose of providing such malware scanning services and the improvement
        thereof, subject to Section 4.3.{' '}
        <strong>
          <em>
            NOTWITHSTANDING ANYTHING HEREIN, HOWEVER, POLYSWARM WILL NOT BE RESPONSIBLE FOR ANY
            MISUSE OF THE SUBMITTED ARTIFACTS BY A SECURITY EXPERT OR OTHERWISE HAVE ANY LIABILITY
            ARISING FROM ANY ACTS OR OMISSIONS OF A SECURITY EXPERT.
          </em>
        </strong>
      </Typography>
      <Typography paragraph>
        5.2 <u>Customer.</u> Customer warrants that it has the necessary rights, licenses, consents
        and permissions to use and make available Submitted Artifacts in connection with the
        PolySwarm Platform as contemplated herein.
      </Typography>
      <Typography paragraph>
        5.3 <u>DISCLAIMER.</u> THE POLYSWARM PLATFORM IS PROVIDED TO YOU &ldquo;AS IS&rdquo;.
        POLYSWARM DOES NOT PROVIDE YOU ANY INDEMNITIES, WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
        WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR
        PURPOSE, IN RELATION TO THE POLYSWARM PLATFORM OR ANY OTHER PRODUCT OR SERVICE. WITHOUT
        LIMITING THE FOREGOING, POLYSWARM DOES NOT REPRESENT OR WARRANT THAT ANY OR ALL MALICIOUS
        FILES OR SECURITY THREATS WILL BE DETECTED USING THE POLYSWARM PLATFORM OR ANY OTHER PRODUCT
        OR SERVICE.
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>6. Indemnification</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        Customer will indemnify, defend and hold harmless PolySwarm against any claim made or
        brought against PolySwarm by a third party arising out of or relating to Customer&rsquo;s
        use or misuse of the PolySwarm Platform. PolySwarm will cooperate as reasonably required in
        the defense of any such claim, at Customer&rsquo;s expense. PolySwarm reserves the right, at
        Customer&rsquo;s expense, to retain separate counsel for itself in connection with any such
        claim or, if Customer has not responded reasonably to such claim, to assume the exclusive
        defense and control of such claim. Customer will also be liable to PolySwarm for any costs
        and attorneys&rsquo; fees PolySwarm incurs to successfully establish or enforce
        PolySwarm&rsquo;s right to indemnification under this Section.
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>7. Limitation of Liability</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        UNDER NO LEGAL THEORY, WHETHER IN TORT, CONTRACT, OR OTHERWISE, WILL POLYSWARM BE LIABLE TO
        CUSTOMER FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY
        CHARACTER, INCLUDING DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS, LOST SALES OR BUSINESS,
        WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, LOST CONTENT OR DATA, OR FOR ANY AND ALL
        OTHER DAMAGES OR LOSSES, EVEN IF A REPRESENTATIVE OF POLYSWARM HAS BEEN ADVISED, KNEW OR
        SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, OR (B) ANY DIRECT DAMAGES, COSTS, OR
        LIABILITIES IN EXCESS OF THE AMOUNTS PAID BY CUSTOMER UNDER THE APPLICABLE ORDER FORM DURING
        THE TWELVE (12) MONTHS PRECEDING THE INCIDENT OR CLAIM. THE FOREGOING PROVISIONS ALLOCATE
        THE RISKS UNDER THIS AGREEMENT BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THE
        LIMITATIONS SET FORTH HEREIN IN DETERMINING WHETHER TO ENTER INTO THIS AGREEMENT.
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>8. Termination</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        8.1 <u>Term.</u> The term of this Agreement will commence on the date these Terms are
        accepted by you and continue until your account is terminated as set forth below.
      </Typography>{' '}
      <Typography paragraph>
        8.2 <u>Termination and Suspension.</u> You may terminate your account at any time through
        the account management tools made available through the PolySwarm Platform. PolySwarm may
        terminate or suspend your account in the event you commit any material breach of any
        provision of this Agreement and fail to fix that breach within five (5) days after written
        notice of that breach. PolySwarm may also terminate or suspend your account immediately for
        cause if: (a) there is reason to believe the traffic created from your use of the PolySwarm
        Platform or your use of the PolySwarm Platform is fraudulent or negatively impacting the
        operating capability of PolySwarm Platform; (b) PolySwarm determines, in its sole
        discretion, that providing the PolySwarm Platform is prohibited by law, or it has become
        impractical or unfeasible for any legal or regulatory reason to provide the PolySwarm
        Platform; or (c) subject to applicable law, upon your liquidation, commencement of
        dissolution proceedings, disposal of your assets or change of control, a failure to continue
        business, assignment for the benefit of creditors, or if you become the subject of
        bankruptcy or similar proceeding. If PolySwarm suspends your account, PolySwarm will make a
        reasonable attempt to notify you. PolySwarm may also terminate your access to any component
        of the PolySwam Platform and/or your account for any reason or no reason; provided that if
        you have subscribed to any portion of the PolySwam Platform, in PolySwarm&rsquo;s election,
        either (i) such termination will be effective upon expiration of your subscription or (ii)
        PolySwarm will provide you with a pro rata refund of prepaid fees for unearned services.
        Except as expressly set forth above, no refund will be provided in the event of any
        suspension or termination of your account or access to any component of the PolySwarm
        Platform. .
      </Typography>
      <Typography paragraph>
        8.3 <u>Survival.</u> Upon termination of this Agreement all rights and obligations will
        immediately terminate except that any terms or conditions that by their nature should
        survive such termination will survive, including the License Restrictions and terms and
        conditions relating to proprietary rights and confidentiality, disclaimers, indemnification,
        limitations of liability and termination and the general provisions below.
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>9. General</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        9.1 <u>Export Compliance.</u> Each party will comply with the export laws and regulations of
        the United States, European Union and other applicable jurisdictions in providing and using
        the PolySwarm Platform.
      </Typography>
      <Typography paragraph>
        9.2 <u>Publicity.</u> Customer agrees that PolySwarm may refer to Customer&rsquo;s name and
        trademarks in PolySwarm&rsquo;s marketing materials, the Polyswarm Platform or
        Polyswarm&rsquo;s website. PolySwarm will not use Customer&rsquo;s name or trademarks in any
        other publicity (e.g., press releases, customer references and case studies) without
        Customer&rsquo;s prior written consent (which may be by email).
      </Typography>
      <Typography paragraph>
        9.3 <u>Assignment; Delegation.</u> Neither party hereto may assign or otherwise transfer
        this Agreement, in whole or in part, without the other party&rsquo;s prior written consent,
        except that either party may assign this Agreement without consent to a successor to all or
        substantially all of its assets or business related to this Agreement. Any attempted
        assignment, delegation, or transfer by either party in violation hereof will be null and
        void. Subject to the foregoing, this Agreement will be binding on the parties and their
        successors and assigns.
      </Typography>
      <Typography paragraph>
        9.4 <u>Waiver.</u> No waiver of any rights hereunder will be effective unless agreed to in
        writing by both parties. Any such waiver will be only to the specific provisions and under
        the specific circumstances for which it was given, and will not apply with respect to any
        repeated or continued violation of the same provision or any other provision. Failure or
        delay by either party to enforce any provision of this Agreement will not be deemed a waiver
        of future enforcement of that or any other provision.
      </Typography>
      <Typography paragraph>
        9.5 <u>Relationship.</u> Nothing contained herein will in any way constitute any
        association, partnership, agency, employment or joint venture between the parties hereto, or
        be construed to evidence the intention of the parties to establish any such relationship.
        Neither party will have the authority to obligate or bind the other in any manner, and
        nothing herein contained will give rise or is intended to give rise to any rights of any
        kind to any third parties.
      </Typography>
      <Typography paragraph>
        9.6 <u>Unenforceability.</u> If a court of competent jurisdiction determines that any
        provision of this Agreement is invalid, illegal, or otherwise unenforceable, such provision
        will be enforced as nearly as possible in accordance with the stated intention of the
        parties, while the remainder of this Agreement will remain in full force and effect and bind
        the parties according to its terms.
      </Typography>
      <Typography paragraph>
        9.7 <u>Governing Law; Venue.</u> The enforceability and interpretation of the arbitration
        provisions below will be determined by the Federal Arbitration Act (including its procedural
        provisions). Apart from such arbitration provisions, ehis Agreement will be governed by the
        laws of the State of California, exclusive of its rules governing choice of law and conflict
        of laws, all disputes arising out of the Agreement will be subject to the exclusive
        jurisdiction and venue of the state and federal courts of San Francisco, California, and the
        parties hereby consent to the personal jurisdiction of these courts. This Agreement will not
        be governed by the United Nations Convention on Contracts for the International Sale of
        Goods.
      </Typography>
      <Typography paragraph>
        9.8 <u>Notices.</u> Any notice required or permitted to be given hereunder will be given in
        writing by personal delivery, certified mail, return receipt requested, or by overnight
        delivery. Notices to Customer must be sent to Customer&rsquo;s email or other address as set
        forth in Customer&rsquo;s account information. Notices to PolySwarm must be sent to the
        following address: Swarm Technologies, Inc., 548 Market Street, #37005, San Francisco, CA
        94104, Attn: Legal.
      </Typography>
      <Typography paragraph>
        9.9 <u>Entire Agreement.</u> This Agreement comprises the entire agreement between Customer
        and PolySwarm with respect to its subject matter, and supersedes all prior and
        contemporaneous proposals, statements, sales materials or presentations and agreements (oral
        and written). No oral or written information or advice given by PolySwarm, its agents or
        employees will create a warranty or in any way increase the scope of the warranties in this
        Agreement.
      </Typography>
      <Typography paragraph>
        9.10 <u>Force Majeure.</u> Neither Party will be deemed in breach hereunder for any
        cessation, interruption or delay in the performance of its obligations due to causes beyond
        its reasonable control (&ldquo;
        <strong>
          <em>Force Majeure Event</em>
        </strong>
        &rdquo;), including earthquake, flood, or other natural disaster, act of God, labor
        controversy, civil disturbance, terrorism, war (whether or not officially declared), cyber
        attacks (e.g., denial of service attacks), or the inability to obtain sufficient supplies,
        transportation, or other essential commodity or service required in the conduct of its
        business, or any change in or the adoption of any law, regulation, judgment or decree.
      </Typography>
      <Typography paragraph>
        9.11 <u>Government Terms.</u> PolySwarm provides the PolySwarm Platform, including related
        software and technology, for ultimate federal government end use solely in accordance with
        the terms of this Agreement. If Customer (or any of its customers) is an agency, department,
        or other entity of any government, the use, duplication, reproduction, release,
        modification, disclosure, or transfer of the PolySwarm Platform, or any related
        documentation of any kind, including technical data, software, and manuals, is restricted by
        the terms of this Agreement. All other use is prohibited and no rights than those provided
        in this Agreement are conferred. The PolySwarm Platform were developed fully at private
        expense.
      </Typography>
      <Typography paragraph>
        9.12 <u>Interpretation.</u> For purposes hereof, &ldquo;including&rdquo; means
        &ldquo;including without limitation&rdquo;.
      </Typography>
      <Typography paragraph>
        <strong>
          <strong>10. Agreement to Arbitrate</strong>
        </strong>
      </Typography>
      <Typography paragraph>
        10.1 <u>First Try Customer Support.</u> If you have any issues with the PolySwarm Platform
        or PolySwarm, you must try to resolve the issue first through PolySwarm customer support
      </Typography>
      <Typography paragraph>
        10.2 <u>Agreement to Arbitrate.</u> If the parties are not able to the dispute through
        PolySwarm customer support, you and PolySwarm agree to resolve any dispute arising under
        this Agreement, or under PolySwarm&rsquo;s Privacy Policy, or in relation to the PolySwarm
        Platform by binding arbitration in San Francisco, California, or in another location that
        both parties agree to. This applies to all claims under any legal theory, unless the claim
        fits in one the exceptions below. It also applies even after you have stopped using your
        PolySwarm account or have deleted it. If the parties have a dispute about whether this
        agreement to arbitrate can be enforced or applies to such dispute, the parties agree that
        the arbitrator will decide that too.
      </Typography>
      <Typography paragraph>
        10.3 <u>Exceptions to Agreement to Arbitrate.</u> You and PolySwarm agree that the parties
        will go to court to resolve disputes relating to your or PolySwarm&rsquo;s intellectual
        property (e.g., trademarks, trade dress, domain names, trade secrets, copyrights or
        patents). Also, either party can bring a claim in small claims court either in San
        Francisco, California or some other place both parties agree on, if it qualifies to be
        brought in that court. In addition, if either party brings a claim in court that should be
        arbitrated or either party refuses to arbitrate a claim that should be arbitrated, the other
        party can ask a court to force the parties to go to arbitration to resolve the claim (i.e.,
        compel arbitration). Either party may also ask a court to halt a court proceeding while an
        arbitration proceeding is ongoing.
      </Typography>
      <Typography paragraph>
        10.4 <u>Details of Arbitration Procedure. </u>
        <LetterList>
          <li>
            Prior to filing any arbitration, both parties jointly agree to seek to resolve any
            dispute between the parties by mediation conducted by the American Arbitration
            Association (&ldquo;
            <strong>
              <em>AAA</em>
            </strong>
            &rdquo;), with all mediator fees and expenses paid equally by the parties. If mediation
            is not successful, either party may initiate an arbitration proceeding with AAA. You can
            look at AAA&rsquo;s rules and procedures on their website http://www.adr.org or you can
            call them at 1-800-778-7879. The arbitration will be governed by the then-current
            version of AAA&rsquo;s Commercial Arbitration Rules (the "
            <strong>
              <em>Rules</em>
            </strong>
            ") and will be held with a single arbitrator appointed in accordance with the Rules. To
            the extent anything described in this agreement to arbitrate conflicts with the Rules,
            the language of this agreement to arbitrate applies.
          </li>
          <li>
            Each party will be entitled to get a copy of non-privileged relevant documents in the
            possession or control of the other party and to take a reasonable number of depositions.
            All such discovery will be in accordance with procedures approved by the arbitrator.
            This agreement to arbitrate does not alter in any way the statute of limitations that
            would apply to any claims or counterclaims asserted by either party.
          </li>
          <li>
            The arbitrator&rsquo;s award will be based on the evidence admitted and the substantive
            law of the State of California and the United States, as applicable, and will contain an
            award for each issue and counterclaim. The award will provide in writing the factual
            findings and legal reasoning for such award. The arbitrator will not be entitled to
            modify this Agreement.
          </li>
          <li>
            Except as provided in the Federal Arbitration Act, the arbitration award will be final
            and binding on the parties. Judgment may be entered in any court of competent
            jurisdiction.
          </li>
        </LetterList>
      </Typography>
      <Typography paragraph>
        10.5 <u>Class Action Waiver.</u> Both you and PolySwarm agree that any claims or
        controversies between the parties must be brought against each other on an individual basis
        only. That means neither you nor PolySwarm can bring a claim as a plaintiff or class member
        in a class action, consolidated action, or representative action against the other party.
        The arbitrator cannot combine more than one person&rsquo;s or entity&rsquo;s claims into a
        single case, and cannot preside over any consolidated, class or representative proceeding
        (unless both parties agree otherwise). And, the arbitrator&rsquo;s decision or award in one
        person&rsquo;s or entity&rsquo;s case can only impact the person or entity that brought the
        claim, not other PolySwarm customers or users, and cannot be used to decide other disputes
        with other customers or users. If a court decides that this class action waiver is not
        enforceable or valid, then the entire agreement to arbitrate will be null and void, but the
        rest of this Agreement will still apply.
      </Typography>
    </Wrapper>
  );
};

const TermsPage = () => {
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <TermsPageContent />
      </BaseLayout>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {
    header: {
      color: styles.color.purple,
    },
    subheader: {
      color: styles.color.lightGrey,
    },
  },
  dark: {
    header: {
      color: styles.color.xLightGrey,
    },
    subheader: {
      color: styles.color.xLightGrey,
    },
  },
});

export default TermsPage;
