import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-malicious' viewBox='0 0 32 32'>
      <path d='M19.271 0.819l8 4.757c1.94 1.154 3.129 3.244 3.129 5.501v9.846c0 2.257-1.189 4.347-3.129 5.501l-8 4.757c-2.016 1.199-4.526 1.199-6.542 0l-8-4.757c-1.94-1.154-3.129-3.244-3.129-5.501v-9.846c0-2.257 1.189-4.347 3.129-5.501l8-4.757c2.016-1.199 4.526-1.199 6.542 0zM17.603 17.624v-9.624h-3.195v9.624h3.195zM14.408 24.055h3.184l0.011-3.252h-3.206l0.011 3.252z' />
    </symbol>

    <Global
      styles={css`
        .icon-malicious {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
