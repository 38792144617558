import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { Toggle } from 'views/components/Toggle';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { Tooltip } from '@material-ui/core';
import Icon from 'views/components/Icon';

export interface ISidebarDetail {
  className?: string;
  center?: boolean;
  label?: string;
  value: string | string[];
  copy?: boolean;
  copyText?: string;
  info?: string;
  monospace?: boolean;
  dataCy?: string;
  bold?: boolean;
  filter?: boolean;
  blurred?: boolean;
}

const SidebarDetail = ({
  className,
  center = false,
  label,
  bold = false,
  value,
  copy,
  copyText,
  info,
  monospace,
  filter = false,
  dataCy,
  blurred = false,
}: ISidebarDetail) => {
  const { classes } = useStyles();
  const { active } = usePivoting();

  const val = (() => {
    if (typeof value === 'string') {
      return value;
    }
    if (isArray(value)) {
      return value.map((item, index) => <ul key={`${item}-${index}`}>{item}</ul>);
    }
    return value;
  })();

  return (
    <div className={className} css={[classes.root, center ? classes.center : null]}>
      {!isEmpty(label) && (
        <div css={classes.labelWrap}>
          {typeof value === 'string' && filter ? (
            <Toggle
              tab='Summary'
              section='Summary'
              itemKey='Family Name'
              itemActualKey='family_name'
              itemValue={value}
            >
              <p
                css={[
                  classes.label,
                  ...(active ? [classes.active] : []),
                  ...(bold ? [classes.bold] : []),
                ]}
              >
                {label}
              </p>
            </Toggle>
          ) : (
            <p css={[classes.label, ...(bold ? [classes.bold] : [])]}>{label}</p>
          )}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: center ? 'center' : 'flex-end',
          overflow: 'hidden',
        }}
      >
        <div
          style={{ textAlign: center ? 'center' : 'end' }}
          css={[classes.content, classes.ellipticalLabelContainer]}
          data-cy={dataCy}
        >
          <p
            css={[
              classes.text,
              ...(monospace ? [classes.mono] : []),
              ...(bold ? [classes.bold] : []),
              blurred && classes.blurred,
            ]}
          >
            {val}
          </p>
        </div>
        {typeof value === 'string' && copy && (
          <CopyTextButton css={classes.copyBtn} text={copyText || value} />
        )}
        {info && (
          <Tooltip title={info} placement='top'>
            <Icon css={classes.info} name='info' title={info} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 8,
      alignItems: 'baseline',
    },
    center: {
      alignItems: 'center',
      flexDirection: 'column',
    },
    labelWrap: {
      textAlign: 'right',
      color: styles.color.xLightGrey,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    label: {
      width: 'auto',
      fontSize: styles.font.size.p1,
      whiteSpace: 'nowrap',
    },
    content: {
      fontSize: styles.font.size.p1,
      color: styles.color.medGrey,
      wordWrap: 'break-word',
      textAlign: 'end',
    },
    text: {
      display: 'inline',
    },
    copyBtn: {
      fontSize: '0.4rem',
      marginLeft: '0.6rem',
      verticalAlign: 'middle',
    },
    mono: {
      fontFamily: styles.font.family.mono,
      fontSize: '1.3rem',
    },
    bold: {
      fontWeight: 'bold',
    },
    blurred: {
      filter: 'blur(4px)',
    },
    ellipticalLabelContainer: {
      maxHeight: '12em',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '> p': {
        '-webkit-line-clamp': '8',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
    },
    info: {
      fontSize: '0.5rem',
      marginLeft: '0.5rem',
    },
  },
  light: {
    content: {
      color: styles.color.medGrey,
    },
    active: {
      color: styles.color.purple,
    },
  },
  dark: {
    content: {
      color: styles.color.white,
    },
    active: {
      color: styles.color.lightBlue,
    },
  },
});
export default SidebarDetail;
