import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';

interface Props {
  onRetry: () => void;
}

const LoginError = ({ onRetry }: Props) => {
  return (
    <Card css={style.root} data-cy='loginError'>
      <h3 className='h3 h-mb-tiny'>
        <FormattedMessage id='login.error.heading' defaultMessage='Login error' />
      </h3>
      <p className='p h-mb-xxs'>
        <FormattedMessage
          id='login.error.text'
          defaultMessage='An error occurred while trying to log you in.'
        />
      </p>
      <Button color='primary' variant='contained' onClick={onRetry}>
        <FormattedMessage {...btnMessages.retry} />
      </Button>
    </Card>
  );
};

const style = {
  root: css`
    text-align: center;
    padding: ${styles.spacing.md};
    width: 500px;
    margin: 0 auto;
  `,
};

export default LoginError;
