import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface IHero {
  heading: string;
  text: string;
  icon?: {
    src: string;
    alt: string;
  };
}

const Hero = ({ heading, text, icon }: IHero) => {
  const { classes } = useStyles();
  return (
    <div css={classes.root}>
      <div css={classes.content}>
        {icon && <img src={icon.src} alt={icon.alt} />}
        <h1 className='h2'>{heading}</h1>
        <p className='p'>{text}</p>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      padding: `${styles.spacing.xl} 0`,
      textAlign: 'center',
    },
    content: {
      maxWidth: '52rem',
      margin: '0 auto',
    },
    icon: {
      maxWidth: '12rem',
      marginBottom: styles.spacing.tiny,
    },
  },
  light: {
    root: {
      backgroundColor: styles.color.offWhite,
    },
  },
  dark: {
    root: {
      backgroundColor: styles.color.darkBlack,
    },
  },
});

export default Hero;
