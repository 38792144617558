import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import AccordionData from 'views/components/Accordion/AccordionData';
import { useLocation } from 'react-router-dom';
import { mapDroppedFiles } from './mapper';

type TriageDroppedFiles = {
  name: string;
  origin: string;
  sha256: string;
  path: string;
};

const checkForPresentValues = (data: Record<string, any>, provider: 'cape' | 'triage') => {
  if (!data) return false;
  if (provider === 'cape') return Boolean(data?.length);
  const valuePresent = Object.keys(data).map((key) => {
    if (Boolean(data[key])) {
      if ('value' in data[key]) {
        return Boolean(data[key]?.value);
      }
      return true;
    }
    return false;
  });

  return valuePresent.some(Boolean);
};

export const getDroppedFilesData = (report: Record<string, any>, provider: 'cape' | 'triage') => {
  const files = provider === 'cape' ? report?.dropped || report?.dumped : report?.dumped || [];

  const data =
    provider === 'triage'
      ? files.map((file: TriageDroppedFiles) => ({
          name: file.name,
          path: file.path,
          origin: file.origin,
          sha256: file.sha256,
        }))
      : [];

  return {
    data: provider === 'cape' ? files : data,
    hasData: Boolean(files?.length),
  };
};

const DroppedTab = ({ data, provider }: Record<string, any>) => {
  const location = useLocation();
  const providerKey = provider === 'cape' ? 'cape_sandbox_v2' : 'triage_sandbox_v0';

  useEffect(() => {
    if (location.hash) {
      document.querySelector(location.hash)?.scrollIntoView();
    }
  }, [location.hash]);

  return (
    <div css={style.root}>
      {checkForPresentValues(data, provider)
        ? providerKey === 'cape_sandbox_v2'
          ? mapDroppedFiles(data).map((file, index: number) => (
              <AccordionData
                id={`${providerKey}.dropped`}
                key={`accordion-data-${index}`}
                css={style.item}
                title={`Dropped File ${index + 1}`}
                data={file}
                columns={2}
              />
            ))
          : data.map((file: Record<string, any>, index: number) => (
              <AccordionData
                id={`${providerKey}.extracted`}
                key={`accordion-data-${index}`}
                css={style.item}
                title={`Dropped File ${index + 1}`}
                data={file}
                columns={2}
              />
            ))
        : 'No Dropped Files Data'}
    </div>
  );
};

const style = {
  root: css`
    margin: calc(${styles.spacing.grid} / 2) ${styles.spacing.grid};
  `,
  item: css`
    margin-bottom: ${styles.spacing.grid};
    &:last-child {
      margin-bottom: 0;
    }
  `,
};

export default DroppedTab;
