import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import styled from '@emotion/styled';
import { CONSUMER_DOCS_URL } from 'utils/constants';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import { InfoTooltip } from 'views/components/InfoTooltip';
import { PolyScoreBar } from '../PolyScoreBar';
import { EPolyScoreThreshold } from '../PolyScore';

interface IPolyScoreTableBar {
  polyscore: number | null;
}

const messages = defineMessages({
  title: {
    id: 'polyscoreTableBar.title',
    defaultMessage:
      "Why does this emerging threat have a low PolyScore? Sometimes engines that detect important emerging threats do not increase the PolyScore much, even though the file is malicious. We still think it's important to show because it means a file warrants additional review, and yes, we believe it's malware.",
  },
  label: {
    id: 'polyscoreTableBar.label',
    defaultMessage: 'PolyScore info',
  },
});

const StyledPolyScoreBar = styled(PolyScoreBar)`
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 2.3rem);
  max-width: 80px;
  height: 6px;
  margin-right: 1rem;
`;

const PolyScoreBarTooltip = styled(InfoTooltip)`
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  font-size: 0.45rem;
  margin-bottom: 1px;
  color: ${styles.color.xLightGrey};
`;

export const PolyScoreTableBar = ({ polyscore }: IPolyScoreTableBar) => {
  const intl = useIntl();
  return (
    <div>
      <StyledPolyScoreBar polyscore={polyscore} />
      {!polyscore || polyscore < EPolyScoreThreshold.caution ? (
        <span>
          <PolyScoreBarTooltip
            title={
              <span>
                {intl.formatMessage(messages.title)}
                <br />
                <a
                  className='p a'
                  href={`${CONSUMER_DOCS_URL}/emerging-threats`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {intl.formatMessage(btnMessages.readMore)}
                </a>
              </span>
            }
            aria-label={intl.formatMessage(messages.label)}
            interactive
          />
        </span>
      ) : null}
    </div>
  );
};
