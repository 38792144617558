import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-shovel' viewBox='0 0 13 27'>
      <path
        d='M3.40603 5.06088L3.40603 2.72751L8.86064 2.72751L8.86065 5.06088C8.86065 5.60634 8.40609 6.06089 7.84548 6.07604L4.4212 6.07604C3.84543 6.07604 3.39088 5.62149 3.40603 5.06088Z'
        fill='none'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M10.2101 13.1437H2C1.44772 13.1437 1 13.5914 1 14.1437V18.5132C1 20.2131 1.61581 21.8555 2.73345 23.1363C4.51668 25.1799 7.69346 25.1799 9.47669 23.1363C10.5943 21.8555 11.2101 20.2131 11.2101 18.5132V14.1437C11.2101 13.5914 10.7624 13.1437 10.2101 13.1437Z'
        fill='none'
        strokeWidth='2'
      />
      <line x1='6.37573' y1='5.85077' x2='6.37573' y2='15.3316' strokeWidth='2' />
    </symbol>

    <Global
      styles={css`
        .icon-shovel {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
