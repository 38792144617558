import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-accuracy' viewBox='0 0 32 32'>
      <path d='M22.667 7.255v-1.921l4-4v3.802h3.802l-4 4h-1.912l-7.462 7.462c-0.521 0.521-1.365 0.521-1.886 0s-0.521-1.365 0-1.886l7.457-7.457zM21.2 4.985l-2.034 2.034c-0.988-0.356-2.055-0.55-3.166-0.55-5.16 0-9.333 4.173-9.333 9.333s4.173 9.333 9.333 9.333c5.16 0 9.333-4.173 9.333-9.333 0-1.101-0.19-2.156-0.539-3.136l2.036-2.036c0.749 1.566 1.169 3.32 1.169 5.172 0 6.627-5.373 12-12 12s-12-5.373-12-12c0-6.627 5.373-12 12-12 1.863 0 3.627 0.425 5.2 1.182zM16.978 9.207l-3.45 3.45c-0.931 0.732-1.528 1.869-1.528 3.146 0 2.21 1.79 4 4 4 1.123 0 2.138-0.463 2.865-1.208 0.013-0.012 0.027-0.025 0.040-0.038l3.696-3.696c0.044 0.308 0.066 0.622 0.066 0.942 0 3.683-2.983 6.667-6.667 6.667s-6.667-2.983-6.667-6.667c0-3.683 2.983-6.667 6.667-6.667 0.332 0 0.659 0.024 0.978 0.071z' />
    </symbol>

    <Global
      styles={css`
        .icon-accuracy {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
