import React from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import Button from '@material-ui/core/Button/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { SandboxFilter, FilterProps } from './SandboxFilter';
import ActionPopover from 'components/ActionPopover';

interface IActionButtons {
  filters?: Partial<FilterProps>;
  active: 'all' | 'my';
  disabled?: boolean;
  filterOpen: boolean;
  onFilterOpen: () => void;
  onFilterClose: () => void;
  onSubmitToSandbox?: () => void;
  onFilterApply: (filters: Partial<FilterProps>, type: 'my' | 'all') => void;
}

const messages = {
  filter: 'Filter',
  submitToSandbox: 'Submit to Sandbox',
};

export const ActionButtons = ({
  filters,
  active,
  onSubmitToSandbox,
  onFilterApply,
  disabled = false,
  filterOpen,
  onFilterOpen,
  onFilterClose,
}: IActionButtons) => {
  const { classes } = useStyles();

  const onToggleFilter = () => {
    onFilterOpen && onFilterOpen();
  };

  return (
    <div css={classes.container}>
      {active === 'all' ? (
        <ActionPopover
          onToggle={onToggleFilter}
          onClose={onFilterClose}
          name={messages.filter}
          isOpen={filterOpen}
          disabled={disabled}
        >
          <SandboxFilter
            sandboxType='all'
            onFilterClose={onFilterClose}
            filters={filters}
            handleApply={onFilterApply}
          />
        </ActionPopover>
      ) : (
        <div css={classes.mySandboxButtons}>
          <ActionPopover
            onToggle={onToggleFilter}
            onClose={onFilterClose}
            name={messages.filter}
            isOpen={filterOpen}
            disabled={disabled}
          >
            <SandboxFilter
              sandboxType='my'
              onFilterClose={onFilterClose}
              filters={filters}
              isFullFilter={true}
              handleApply={onFilterApply}
            />
          </ActionPopover>
          <Button
            css={classes.submitToSandboxButton}
            size='small'
            component='button'
            color='primary'
            variant='contained'
            onClick={onSubmitToSandbox}
            disabled={disabled}
          >
            {messages.submitToSandbox}
          </Button>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      position: 'absolute',
      right: '5rem',
      top: '2rem',
    },
    button: {
      padding: '0.7rem 2rem !important',
    },
    submitToSandboxButton: {
      padding: '0.9rem 2rem !important',
    },
    mySandboxButtons: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: styles.color.white,
      border: '2px solid #000',
      boxShadow: styles.shadow.medium,
    },
  },
  light: {},
  dark: {},
});
