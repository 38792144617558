import React, { useState } from 'react'; // eslint-disable-line
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import Loader from 'views/components/Loader';
import styled from '@emotion/styled';

import AccountSignupFormContent from './AccountSignupFormContent';
import { getValidationSchema } from './schema';
import { IAccountSignupValues } from 'models/Account';

interface IAccountSignupFormProps {
  style?: React.CSSProperties;
  errorMessage?: string | null;
  initialValues?: IAccountSignupValues;
  isSignupLoading: boolean;
  onSubmit: (values: IAccountSignupValues, actions: FormikHelpers<IAccountSignupValues>) => void;
}

const StyledLoader = styled(Loader)`
  position: absolute;
  font-size: 0.6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const AccountSignupForm = ({
  style,
  errorMessage,
  initialValues: _initialValues,
  isSignupLoading,
  onSubmit,
}: IAccountSignupFormProps) => {
  const intl = useIntl();

  const [initialValues, setInitialValues] = useState(_initialValues);

  const _handleSubmit = (
    newValues: IAccountSignupValues,
    helpers: FormikHelpers<IAccountSignupValues>
  ) => {
    setInitialValues(newValues);
    return onSubmit(newValues, helpers);
  };

  if (isSignupLoading) {
    return <StyledLoader />;
  }

  return (
    <div style={style}>
      <Formik
        initialValues={initialValues || {}}
        validationSchema={getValidationSchema(intl)}
        onSubmit={_handleSubmit}
        initialTouched={Object.keys(initialValues || {})}
        validateOnMount
      >
        {(formik: FormikProps<IAccountSignupValues>) => {
          return (
            <AccountSignupFormContent
              formik={formik}
              errorMessage={errorMessage}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export default AccountSignupForm;
