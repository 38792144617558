export type UrlScanType = 'ip' | 'url' | 'domain';

export const urlRegex = /^(https?:\/\/).+/;

const domainRegex =
  /^(?!:\/\/)(?!-)(?!\d+(\.\d+){3})(?:[a-zA-Z0-9-]{1,63}|xn--[\w-]{1,59})(?<!-)(?:\.(?:[a-zA-Z0-9-]{1,63}|xn--[\w-]{1,59}))+\.?$/;

export const isIP = (value: string) => {
  const regex =
    /^((([0-9]{1,3}\.){3}[0-9]{1,3})|(\[[0-9a-fA-F:]+\])|(([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}|(([0-9A-Fa-f]{1,4}:){1,7}:)))?(:[0-9]+)?(\/.*)?$/;

  return regex.test(value);
};

export const isDomain = (value: string) => {
  const result = domainRegex.test(value);
  return result;
};

export const isUrl = (value: string) => {
  const result = urlRegex.test(value) || isDomain(value);
  return result;
};

const getUrlType = (value: string): UrlScanType | undefined => {
  if (isDomain(value)) {
    return 'domain';
  } else if (isIP(value)) {
    return 'ip';
  } else if (isUrl(value)) {
    return 'url';
  }
};
export default getUrlType;
