import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-power' viewBox='0 0 32 32'>
      <path d='M16.002 2.667v0c-0.736 0-1.333 0.597-1.333 1.333v10.667c0 0.736 0.597 1.333 1.333 1.333v0c0.736 0 1.333-0.597 1.333-1.333v-10.667c0-0.736-0.597-1.333-1.333-1.333z' />
      <path d='M7.521 5.623c0.598-0.49 1.481-0.401 1.97 0.197s0.401 1.481-0.197 1.97c-2.448 2.002-3.894 4.986-3.894 8.209 0 5.854 4.746 10.6 10.6 10.6s10.6-4.746 10.6-10.6c0-3.229-1.451-6.217-3.906-8.219-0.599-0.489-0.689-1.371-0.2-1.97s1.371-0.689 1.97-0.2c3.1 2.529 4.936 6.31 4.936 10.389 0 7.401-5.999 13.4-13.4 13.4s-13.4-5.999-13.4-13.4c0-4.072 1.83-7.848 4.921-10.377z' />
    </symbol>

    <Global
      styles={css`
        .icon-power {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
