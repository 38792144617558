import React, { Fragment } from 'react';
import * as yup from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useIntl, IntlShape, defineMessages } from 'react-intl';
import styles from 'views/styles';
import FormInput from 'views/components/form/FormInput';
import FormButtonBar from 'views/components/form/CustomForm/FormButtonBar';
import CustomForm from 'views/components/form/CustomForm';
import { formMessages } from 'utils/schema/formSchema';
import { ITeamValues } from 'models/Team';

interface ITeamForm {
  className?: string;
  initialValues?: Partial<ITeamValues>;
  heading: string;
  buttonText: string;
  isLoading: boolean;
  errorMessage?: string | null;
  onSubmit: (values: ITeamValues, actions: FormikHelpers<ITeamValues>) => void;
  onCancel: () => void;
}

const getInitialValues = (values?: Partial<ITeamValues>): ITeamValues => ({
  name: values && values.name ? values.name : '',
  description: values && values.description ? values.description : '',
});

const getValidationSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup
      .string()
      .label(intl.formatMessage(messages.name))
      .required(
        intl.formatMessage(formMessages.required, { label: intl.formatMessage(messages.name) })
      )
      .matches(/^[a-zA-Z0-9_\-\\+\s+]+$/, intl.formatMessage(formMessages.allowedChars))
      .max(255, 'Team name must be at most 255 characters'),
    description: yup.string().label(intl.formatMessage(messages.description)),
  });

const messages = defineMessages({
  name: {
    id: 'teams.form.name.label',
    defaultMessage: 'Team name',
  },
  description: {
    id: 'teams.form.description.label',
    defaultMessage: 'Description',
  },
});

const TeamForm = ({
  className,
  initialValues,
  heading,
  buttonText,
  isLoading,
  errorMessage,
  onSubmit,
  onCancel,
}: ITeamForm) => {
  const intl = useIntl();
  return (
    <Formik
      initialValues={getInitialValues(initialValues)}
      validationSchema={getValidationSchema(intl)}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<ITeamValues>) => (
        <CustomForm
          testId='teamForm'
          className={className}
          isLoading={isLoading}
          heading={heading}
          renderFields={() => (
            <Fragment>
              <FormInput
                className='h-mb-sm'
                name='name'
                label={intl.formatMessage(messages.name)}
                errorOnTouched={true}
                autoFocus
              />
              <FormInput
                className='h-mb-sm'
                name='description'
                label={intl.formatMessage(messages.description)}
                errorOnTouched={true}
              />
              <p className='p1 h-mb-xs' style={{ color: styles.color.red }}>
                {errorMessage}
              </p>
            </Fragment>
          )}
          renderButtonBar={() => (
            <FormButtonBar
              disabled={!formikProps.values.name}
              submitBtnText={buttonText}
              onCancel={() => {
                formikProps.resetForm();
                onCancel();
              }}
            />
          )}
        />
      )}
    </Formik>
  );
};

export default TeamForm;
