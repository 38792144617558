import React, { useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl, defineMessages } from 'react-intl';
import { DATE_FORMAT, TEAM_AVATAR_URL } from 'utils/constants';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Icon from 'views/components/Icon';
import { TagsList } from '../TagsList/TagsList';
import AvatarBackground from 'views/components/layout/Sidebar/AvatarSidebar/AvatarBackground';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { useUser } from 'views/components/providers/UserProvider';
import Loader from 'views/components/Loader';
import { canManageMicroengine } from 'views/pages/MicroenginesPage/auth';
import { MicroengineListing } from 'models/Microengine';
import styled from '@emotion/styled';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { useDevelopmentCommunity } from '../hooks';

interface IMicroengineDetailSidebarProps {
  microengine: MicroengineListing;
  onClose: () => void;
}

const messages = defineMessages({
  description: {
    id: 'sidebar.details.label.description',
    defaultMessage: 'Description',
  },
  none: {
    id: 'placeholder.none',
    defaultMessage: 'None',
  },
  vendorWebsite: {
    id: 'placeholder.vendorWebsite',
    defaultMessage: 'Website',
  },
  claimed: {
    id: 'sidebar.details.label.claimed',
    defaultMessage: 'Claimed',
  },
});

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const MicroengineDetailSidebar = ({ microengine }: IMicroengineDetailSidebarProps) => {
  const intl = useIntl();
  const user = useUser();
  const { developmentCommunity, getDevelopmentCommunity } = useDevelopmentCommunity();
  const { classes } = useStyles();

  const {
    engineId,
    address,
    name,
    displayName,
    description,
    vendorWebsite,
    tags,
    createdAt,
    accountNumber,
  } = microengine;
  const userAccountNumber = user.context?.accountNumber || user.accountNumber;
  const showDevelopmentCommunity =
    microengine.developmentCommunity &&
    userAccountNumber === accountNumber &&
    canManageMicroengine(user);

  useEffect(() => {
    if (
      showDevelopmentCommunity &&
      microengine.developmentCommunity &&
      microengine.developmentCommunity !== developmentCommunity.name
    ) {
      getDevelopmentCommunity(microengine.developmentCommunity);
    }
  }, [
    showDevelopmentCommunity,
    microengine.developmentCommunity,
    developmentCommunity.name,
    getDevelopmentCommunity,
  ]);

  return (
    <Card css={classes.root}>
      <AvatarBackground apiUrl={`${TEAM_AVATAR_URL}/${encodeURIComponent(name)}`} />

      <div css={classes.details}>
        <h4 className='h5' css={classes.heading}>
          {displayName}
        </h4>
        {showDevelopmentCommunity && (
          <div css={[classes.info, classes.container]}>
            <Icon name='token' css={classes.infoIcon} />
            <div>
              {engineId && (
                <div>
                  <span>ID: {engineId}</span>
                  <CopyButton text={engineId} />
                </div>
              )}
              {address && (
                <div>
                  <span>Address: {`${address.slice(0, 20)}...`}</span>
                  <CopyButton text={address} />
                </div>
              )}
            </div>
          </div>
        )}
        {vendorWebsite && (
          <div css={classes.info}>
            <Icon name='benign' css={classes.infoIcon} />
            <span>Website {vendorWebsite}</span>
          </div>
        )}
        <div css={classes.info}>
          <Icon name='benign' css={classes.infoIcon} />
          <span>Created on {formatDate(parseDate(createdAt), DATE_FORMAT)}</span>
        </div>
        {microengine.engineType && (
          <div css={classes.info}>
            <Icon name='microengine' css={classes.infoIcon} />
            <span>Participant Type: {microengine.engineType}</span>
          </div>
        )}
        {microengine.artifactTypes && (
          <div css={classes.info}>
            <Icon name='gear' css={classes.infoIcon} />
            <span>
              Artifact Types:{' '}
              {microengine.artifactTypes.map((type, idx) => (
                <span>
                  {type}
                  {idx < microengine.artifactTypes.length - 1 ? ', ' : ''}
                </span>
              ))}
            </span>
          </div>
        )}
        {showDevelopmentCommunity && (
          <React.Fragment>
            <h4 className='h5' css={classes.smallHeading}>
              Development Community
            </h4>
            <div css={classes.info}>
              <Icon name='globe' css={classes.infoIcon} />
              <span>Name: </span>
              {developmentCommunity.name ? (
                <React.Fragment>
                  <span css={classes.small}>{developmentCommunity.name}</span>
                  <CopyButton text={developmentCommunity.name} />
                </React.Fragment>
              ) : (
                <Loader css={classes.loader} />
              )}
            </div>
            <div css={classes.info}>
              <Icon name='token' css={classes.infoIcon} />
              <span>Api Key: </span>
              {developmentCommunity.apiKey ? (
                <React.Fragment>
                  <span css={classes.small}>{developmentCommunity.apiKey}</span>
                  <CopyButton text={developmentCommunity.apiKey} />
                </React.Fragment>
              ) : (
                <Loader css={classes.loader} />
              )}
            </div>
            <div css={classes.info}>
              <Icon name='check' css={classes.infoIcon} />
              <span>Accepting Bounties: </span>
              {developmentCommunity.enabled !== null ? (
                <span>{developmentCommunity.enabled ? 'Enabled' : 'Disabled'}</span>
              ) : (
                <Loader css={classes.loader} />
              )}
            </div>
          </React.Fragment>
        )}

        <div css={classes.description}>{description || intl.formatMessage(messages.none)}</div>
        <TagsList tags={tags} limit={9} css={classes.tagsList} tagStyle={{ marginBottom: '5px' }} />
      </div>
    </Card>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      position: 'relative',
    },
    heading: {},
    breadcrumb: {
      borderRadius: '24px',
      color: styles.color.black,
      backgroundColor: styles.color.white,
      zIndex: 1,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    info: {
      fontSize: '1.2rem',
      fontWeight: 500,
      color: styles.color.xLightGrey,
      paddingTop: styles.spacing.tiny,
    },
    infoIcon: {
      width: '1.2em !important',
      height: '1.2em !important',
      position: 'relative',
      top: '2px',
      marginRight: '3px',
    },
    closeIcon: {
      width: '2rem',
      height: '2rem',
      cursor: 'pointer',
      color: '#FFFFFF',
    },
    description: {
      fontSize: '1.5rem',
      paddingTop: styles.spacing.xxs,
    },
    tagsList: {
      paddingTop: styles.spacing.xs,
    },
    details: {
      marginTop: '10%',
      flex: '1 0 auto',
      padding: `0 ${styles.spacing.xs} ${styles.spacing.sm}`,
    },
    smallHeading: {
      marginTop: '0.9em',
      fontSize: '0.9em',
    },
    loader: {
      fontSize: '0.09em !important',
      display: 'inline-flex',
      marginLeft: '1rem',
    },
    small: {
      fontSize: '1.1rem',
    },
  },
});

export default MicroengineDetailSidebar;
