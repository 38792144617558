import { useEffect, RefObject, useRef } from 'react';

type Props = {
  topOffset?: number;
  scrollMargin?: number;
  stickyActive?: boolean;
};

function useSticky<T extends HTMLElement>({
  topOffset = 0,
  scrollMargin = 0,
  stickyActive = true,
}: Props = {}) {
  const handleScroll = () => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();

      if (top <= topOffset && window.scrollY > scrollMargin) {
        ref.current.style.position = 'fixed';
        ref.current.style.top = `${topOffset}px`;
        ref.current.style.zIndex = '1';

        if (nextElementRef.current) {
          nextElementRef.current.style.marginLeft = `${ref.current.offsetWidth}px`;
        }
      } else {
        ref.current.style.position = 'static';
        ref.current.style.zIndex = '0';
        ref.current.style.width = 'auto';

        if (nextElementRef.current) {
          nextElementRef.current.style.marginLeft = '0';
        }
      }
    }
  };

  const ref: RefObject<T> = useRef<T>(null);
  const nextElementRef: RefObject<T> = useRef<T>(null);

  useEffect(() => {
    if (stickyActive) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyActive]);

  return [ref, nextElementRef];
}

export default useSticky;
