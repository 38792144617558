import React from 'react'; // eslint-disable-line
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import styles from 'views/styles';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import Modal from 'views/components/Modal';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Divider from '@material-ui/core/Divider';
import Close from '@material-ui/icons/Close';

interface Data {
  key: string;
  value: string | null;
}

export interface Ruleset {
  id: string;
  ruleName: string;
  results: number;
}

export interface HistoricalInfoModalParams {
  rulesets: Ruleset[];
  totalMatches: number;
  data: Data[];
}

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const HistoricalInfoModal = () => {
  const dispatch = useDispatch();

  const _closeModal = () => dispatch(closeModal());
  const { data, totalMatches, rulesets } = useSelector(
    ({ modal }: { modal: ModalState<HistoricalInfoModalParams> }) => modal.params
  );

  return (
    <Modal>
      <Close css={closeIcon} onClick={_closeModal} />
      <div css={container}>
        <h3 className='h3'>Historical Hunt Info</h3>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Rule Name</TableCell>
              <TableCell align='right'>Results</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rulesets &&
              rulesets.map((ruleset: Ruleset) => (
                <TableRow key={ruleset.id}>
                  <TableCell>{ruleset.ruleName}</TableCell>
                  <TableCell align='right'>{ruleset.results}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Divider />
        <div css={[row, padding]}>
          <span css={label}>Total Matches</span>
          <span>{totalMatches}</span>
        </div>
        <Divider />
        <div css={table}>
          {data.length &&
            data.map(
              ({ key, value }) =>
                value && (
                  <div css={row}>
                    <span css={label}>{key}</span>
                    <div>
                      <span>{value}</span>
                      <CopyButton text={value} />
                    </div>
                  </div>
                )
            )}
        </div>
      </div>
    </Modal>
  );
};

const container = css`
  padding: ${styles.spacing.lg};
`;
const closeIcon = css`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`;
const table = css`
  width: 300px;
  margin-top: 3rem;
`;
const row = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1.5rem;
`;
const padding = css`
  padding-top: 2rem;
  padding-bottom: 2.2rem;
`;
const label = css`
  font-weight: bold;
`;

export default HistoricalInfoModal;
