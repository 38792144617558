// @ts-nocheck
import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Button from '@material-ui/core/Button/Button';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { statusList, providerList } from 'models/Sandboxing';
import useFormBuilder from 'components/FormBuilder';

export type FilterProps = {
  status: string;
  sandboxProvider: string;
  triggeredBy: string;
  hash: string;
  startDate: DateSelectorProps;
  endDate: DateSelectorProps;
  sha256?: string;
  last_updated?: string; // for refetching purposes
};

interface ISandboxFilter {
  sandboxType: 'my' | 'all';
  onFilterClose: () => void;
  filters?: Partial<FilterProps> | {};
  handleApply: (filters: Partial<FilterProps>, type: 'my' | 'all') => void;
  isFullFilter?: boolean;
}

type DateSelectorProps = {
  option: 'DAYS' | 'TODAY';
  value: number | '';
};

const messages = {
  title: 'Filter Results',
  status: 'Status',
  sandboxProvider: 'Sandbox Provider',
  triggeredBy: 'Triggered By',
  hash: 'SHA-256',
  dateRange: 'Date Range',
  startDate: 'Start',
  endDate: 'End',
  reset: 'Reset',
  search: 'Search',
  searchByHash: 'Search by Hash',
};

const filterDefaultValues: FilterProps = {
  status: 'Any',
  sandboxProvider: 'All',
  triggeredBy: 'Anyone',
  hash: '',
  startDate: {
    option: 'DAYS',
    value: 30,
  },
  endDate: {
    option: 'TODAY',
    value: '',
  },
};

const isThereAnyFilter = (filters: FilterProps, defaultValues: Partial<FilterProps>) => {
  const defaults = { ...filterDefaultValues, ...defaultValues };
  return Object.entries(filters).some(
    ([key, value]) => value !== defaults[key as keyof FilterProps]
  );
};

export const SandboxFilter = ({
  sandboxType,
  filters,
  handleApply,
  isFullFilter = false,
  onFilterClose,
}: ISandboxFilter) => {
  const { classes } = useStyles();

  const defaultFilters = {
    ...filterDefaultValues,
    startDate: { option: 'DAYS', value: sandboxType === 'all' ? 90 : 30 },
  };

  const { FormComponent } = useFormBuilder([
    {
      elementType: 'select',
      name: messages.status,
      id: 'status',
      defaultValue: 'Any',
      defaultOptions: ['Any'].concat(statusList),
    },
    {
      elementType: 'select',
      name: messages.sandboxProvider,
      id: 'sandboxProvider',
      defaultValue: 'All',
      defaultOptions: ['All'].concat(providerList),
    },
    /* {
      elementType: 'select',
      name: messages.triggeredBy,
      id: 'triggeredBy',
      defaultValue: { value: 'Anyone', options: ['Anyone'].concat(memberList) },
    },*/
    {
      elementType: 'input',
      name: messages.hash,
      id: 'hash',
      placeholder: messages.searchByHash,
      defaultValue: '',
    },
    {
      name: messages.dateRange,
      combine: [
        {
          elementType: 'dateSelect',
          name: messages.startDate,
          id: 'startDate',
          defaultValue: { value: sandboxType === 'all' ? 90 : 30, option: 'DAYS' },
        },
        {
          elementType: 'dateSelect',
          name: messages.endDate,
          id: 'endDate',
          defaultValue: { value: '', option: 'TODAY' },
        },
      ],
    },
  ]);

  const onApply = (selectedFilters: FilterProps) => {
    if (isFullFilter) {
      handleApply(selectedFilters, 'my');
    } else {
      handleApply(
        Object.fromEntries(
          Object.entries(selectedFilters).filter(([key]) => !['triggeredBy', 'hash'].includes(key))
        ) as unknown as FilterProps,
        'all'
      );
    }
  };

  return (
    <FormComponent
      filters={filters}
      onSubmit={(data) => {
        onApply(data);
        onFilterClose();
      }}
      footerRender={({ dirtyFields }) => {
        return (
          <div css={classes.buttonsContainer}>
            <Button
              onClick={() => {
                onApply(defaultFilters);
              }}
              size='small'
              variant='text'
              color='primary'
              css={classes.button}
            >
              Reset
            </Button>
            <Button
              type='submit'
              size='small'
              variant='contained'
              color='primary'
              disabled={!isThereAnyFilter(dirtyFields)}
              css={classes.button}
            >
              Search
            </Button>
          </div>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      borderRadius: '0.5rem',
      marginTop: '1rem',
      padding: '5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: '70rem',
      position: 'relative',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
    },
    selectContainer: {
      width: '35rem',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
    },
    button: {
      padding: '0.7rem 2rem !important',
    },
    title: {
      fontSize: '2.2rem',
      fontWeight: 600,
      lineHeight: '2.2rem',
    },
    iconClose: {
      width: '1.5rem',
      cursor: 'pointer',
    },
    dateRange: {
      display: 'flex',
      width: '35rem',
      justifyContent: 'space-between',
    },
  },
  light: {
    container: {
      background: styles.color.white,
      border: `1px solid ${styles.color.xxLightGrey}`,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    title: {
      color: styles.color.black,
    },
  },
  dark: {
    container: {
      background: styles.color.xxDarkPurple,
      border: `1px solid ${styles.color.xDarkPurple}`,
      boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.15)',
    },
    title: {
      color: styles.color.white,
    },
  },
});
