import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { translateError, ErrorKeys } from 'utils/error';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { UserActionName } from 'state/user/types';
import { closeModal, openModal } from 'state/modal/actions';
import { RootState } from 'state/root';
import request from 'state/utils/request';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import api from 'services/api/api';
import config from 'config';

const messages = defineMessages({
  heading: {
    id: 'account.advanced.mfa.modal.remove.heading',
    defaultMessage: 'Remove Two-Factor Auth (2FA)?',
  },
  text: {
    id: 'account.advanced.mfa.modal.remove.text',
    defaultMessage:
      'Once removed, the security code from your authenticator app will not longer be valid.',
  },
  mfaSuccess: {
    id: 'account.advanced.mfa.modal.remove.success',
    defaultMessage: 'Two-factor authentication removed',
  },
  mfaFailure: {
    id: 'account.advanced.mfa.modal.remove.failure',
    defaultMessage: 'Error removing 2FA authentication. Please try again.',
  },
});

const errorKeys: ErrorKeys = {};

const ConfirmRemoveMultifactorAuthModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [UserActionName.SET_MULTIFACTOR_AUTH]);
  const error = errorSelector(requests, [UserActionName.SET_MULTIFACTOR_AUTH]);

  return (
    <ConfirmModal
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text)}
      submitBtnText={intl.formatMessage({ id: 'button.disable' })}
      onSubmit={async () => {
        await dispatch(
          request(
            () => api.disableUserMfa().then(() => ({ mfaEnabled: false })),
            { type: UserActionName.SET_MULTIFACTOR_AUTH },
            { success: messages.mfaSuccess, failure: messages.mfaFailure }
          )
        );
        dispatch(closeModal());
      }}
      onCancel={() => dispatch(closeModal())}
      testId='mfaModal'
      timeout={parseInt(config.tokenExpirationSec, 10)}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
      }}
    />
  );
};

export default ConfirmRemoveMultifactorAuthModal;
