import { defineMessages } from 'react-intl';
import api from 'services/api';
import request from 'state/utils/request';
import { PageQuery } from 'models/Page';
import { WebhooksActionName } from './types';
import { Thunk } from 'state/types/thunk';
import { WebhooksRes, WebhookRes, WebhookAttemptRes } from 'services/api/schema/webhooks';
import { WebhookData } from 'models/Webhook';

const messages = defineMessages({
  createFailure: {
    id: 'account.webhooks.create.failure',
    defaultMessage: 'Error creating Webhook. Please try again',
  },
  testFailure: {
    id: 'account.webhooks.test.failure',
    defaultMessage: 'Error testing Webhook. Please try again',
  },
  archiveFailure: {
    id: 'account.webhooks.archive.failure',
    defaultMessage: 'Error deleting Webhook. Please try again.',
  },
});

export const getWebhooks = (
  params?: PageQuery<WebhookData>,
  refresh?: boolean
): Thunk<Promise<WebhooksRes>> => (dispatch, getState) => {
  const accountNumber =
    getState().account?.context?.accountNumber || getState().user.profile!.accountNumber;
  return dispatch(
    request(
      () => api.getWebhooks(accountNumber, params).then((res) => res.data),
      refresh
        ? { type: WebhooksActionName.REFRESH_WEBHOOKS }
        : { type: WebhooksActionName.GET_WEBHOOKS }
    )
  );
};

export const createWebhook = (values: any): Thunk<Promise<void>> => async (dispatch, getState) => {
  const accountNumber =
    getState().account?.context?.accountNumber || getState().user.profile!.accountNumber;
  await dispatch(
    request(
      () => api.createWebhook(accountNumber, values).then((res) => res.data),
      { type: WebhooksActionName.CREATE_WEBHOOK },
      { failure: messages.createFailure }
    )
  );
};

export const testWebhook = (webhookId: string): Thunk<Promise<WebhookAttemptRes>> => (
  dispatch,
  getState
) => {
  const accountNumber =
    getState().account?.context?.accountNumber || getState().user.profile!.accountNumber;
  return dispatch(
    request(
      () => api.testWebhook(accountNumber, webhookId).then((res) => res.data),
      { type: WebhooksActionName.TEST_WEBHOOK },
      { failure: messages.testFailure }
    )
  );
};

export const archiveWebhook = (webhookId: string): Thunk<Promise<WebhookRes>> => (
  dispatch,
  getState
) => {
  const accountNumber =
    getState().account?.context?.accountNumber || getState().user.profile!.accountNumber;
  return dispatch(
    request(
      () => api.archiveWebhook(accountNumber, webhookId).then((res) => res.data),
      { type: WebhooksActionName.ARCHIVE_WEBHOOK },
      { failure: messages.archiveFailure }
    )
  );
};
