import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styles from 'views/styles';
import { ButtonNavLink, ButtonNavLinkProps } from 'views/components/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface Props extends ButtonNavLinkProps {
  label: MessageDescriptor;
  matches?: string[];
  dataCy?: string;
}

const NavigationLink = ({ to, disabled, label, matches, dataCy }: Props) => {
  const { classes } = useStyles();
  return (
    <ButtonNavLink
      css={classes.root}
      to={to}
      disabled={disabled}
      data-cy={dataCy}
      activeClassName='is-active'
      onClick={(e) => {
        if (e.ctrlKey) {
          window.open(e.currentTarget.href, '_blank');
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      isActive={(match, location) => {
        if (!!match) return true;
        if (matches && matches.length) {
          return matches.some((url) => location.pathname === url);
        }
        return false;
      }}>
      <FormattedMessage {...label} />
    </ButtonNavLink>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      textDecoration: 'none',
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.medium,
      transition: `color ${styles.easing.time} ${styles.easing.main}, background-color ${styles.easing.time} ${styles.easing.main}`,

      '&[disabled], &.is-disabled': {
        cursor: 'not-allowed',
      },
    },
  },
  light: {
    root: {
      color: styles.color.black,

      '&[disabled], &.is-disabled': {
        color: styles.color.lightPurple,
      },

      '&:not([disabled]):not(.is-disabled)': {
        '&:hover, &.is-active': {
          color: styles.color.purple,
        },
        '&:active': {
          transition: 'none',
          backgroundColor: styles.color.green,
          color: styles.color.black,
        },
      },
    },
  },
  dark: {
    root: {
      color: styles.color.xLightGrey,

      '&[disabled], &.is-disabled': {
        color: styles.color.xLightGrey,
        opacity: 0.6,
      },

      '&:not([disabled]):not(.is-disabled)': {
        '&:hover, &.is-active': {
          color: styles.color.white,
        },
        '&:active': {
          transition: 'none',
          backgroundColor: styles.color.lightBlue,
          color: styles.color.white,
        },
      },
    },
  },
});

export default NavigationLink;
