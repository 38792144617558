import api from 'services/api';
import request from 'state/utils/request';
import { SandboxActionName } from './types';
import { SandboxProviders } from 'models/Sandbox';

export const getLatestSandboxesByHash = (hash: string) =>
  request(
    () => {
      return Promise.allSettled([
        api.getLatestSandboxByHash(hash, SandboxProviders.TRIAGE),
        api.getLatestSandboxByHash(hash, SandboxProviders.CAPE),
      ]).then((res) => {
        const result = res
          .filter((r) => r.status === 'fulfilled')
          .map((r) => r.status === 'fulfilled' && r.value.data);
        if (!result.length) {
          return Promise.reject({ message: 'invalid_sandbox' });
        }
        return result;
      });
    },
    {
      type: SandboxActionName.GET_SANDBOXES_HASH,
    }
  );

export const getSandboxTaskByHashAndId = (sha256: string, taskId: string) =>
  request(() => api.getSandboxTaskByHashAndId(sha256, taskId).then((res) => res.data), {
    type: SandboxActionName.GET_SANDBOX_TASK_ID,
  });
