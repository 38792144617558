import { useState, useEffect } from 'react';
import api from 'services/api';
import isEmpty from 'lodash/isEmpty';
import useNotification from 'hooks/useNotification';
import Carousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e: React.MouseEvent) => e.preventDefault();

const ScreenshotTab = ({ artifactIds }: { artifactIds: string[] }) => {
  const [images, setImages] = useState<string[]>([]);
  const notification = useNotification();

  useEffect(() => {
    (async () => {
      if (!isEmpty(artifactIds)) {
        try {
          const response = await Promise.all(
            artifactIds.map((id) => api.downloadSandboxArtifact(id))
          );
          const urls = response.map((item) => item.data.url);
          setImages(urls);
        } catch (e) {
          notification.failure(
            'Download failed. Please try again later or contact the admin.',
            45000
          );
        }
      }
    })();
  }, [artifactIds]); // eslint-disable-line

  const imageElements = images.map((imgSrc) => (
    <img src={imgSrc} onDragStart={handleDragStart} alt='presentation' />
  ));

  return (
    <div
      style={{
        gap: '4rem',
        padding: '4rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Carousel mouseTracking items={imageElements} />
    </div>
  );
};

export default ScreenshotTab;
