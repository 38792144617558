import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import numeral from 'numeral';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Icon from 'views/components/Icon';
import styles from 'views/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { AccountFeature } from 'models/Account';

type Feature = AccountFeature & {
  isIncludedOnPlan: boolean;
  totalAvail: number;
  feature: string;
};
interface ExpandableTableProps {
  features: Array<Feature>;
}

export const featuresTexts = {
  sandboxRequest: {
    label: 'Sandbox Request',
    info: 'Submit a File to the Sandbox',
  },
  sandboxSearch: {
    label: 'Sandbox Search',
    info: 'Search Sandbox results',
  },
  apiRequestLimit: {
    label: 'Daily API Request Limit',
    info: 'The maximum total daily usage for an account across all features.',
  },
  monthlyApiLimit: {
    label: 'Monthly API Request Limit',
    info: 'The maximum total monthly usage for an account across all features.',
  },
  hasSupport: {
    label: 'Enterprise-Class Support',
    info: 'We have your back. You can contact us via Discord, Slack, and Email to get assistance.',
  },
  createTeams: {
    label: 'Team Access',
    info: 'You can create team accounts to share a plan with your colleagues. Large enterprises may opt to create separate teams for different parts of the organization. Team accounts can only purchase plans with Team Access.',
  },
  integrationOk: {
    label: 'Integrations Support',
    info: 'Individual Plans only support the Autopsy Plugin Integration. Enterprise Plans support all TIPs, Autopsy Plugin, and Automation tools.',
  },
  integrationsOk: {
    label: 'Integrations Support',
    info: 'Individual Plans only support the Autopsy Plugin Integration. Enterprise Plans support all TIPs, Autopsy Plugin, and Automation tools.',
  },
  privateComm: {
    label: 'Private Communities',
    info: 'Invite-only communities to support specific use cases (e.g. mutual NDA among participants, GDPR compliance, etc).',
  },
  iocUser: {
    label: 'IOC Searches',
    info: 'Search for an artifact in the PolySwarm network by IOC',
  },
  tagsView: {
    label: 'Tags View',
    info: 'View tags and families associated with an artifact in the PolySwarm network',
  },
  maxArtifactSize: {
    label: 'Max Artifact Size',
    info: 'Maximum size of an artifact that you can Scan in the PolySwarm network”, the size varies per account, so check their settings and then display the number rounded to the nearest megabyte (MB)',
  },
  taxii: {
    label: 'Stix / Taxii Access',
    info: 'Access PolySwarm threat intelligence feeds via Stix/Taxii',
  },
  taxiiServerAccess: {
    label: 'Taxii Server Access',
    info: 'Access PolySwarm threat intelligence feeds via Stix/Taxii',
  },
  overageSupport: {
    label: 'Overages',
    info: 'You can exceed your monthly quota allotment by the Overage amount set on each feature that has a counting quota.',
  },
  taxiiTrustgroupAccess: {
    label: 'Taxii Trustgroup Access',
    info: 'Access the Trustgroup threat intelligence feed via Stix/Taxii',
  },
  taxiiRansomwareAccess: {
    label: 'Taxii Ransomware Access',
    info: 'Access the Ransomware threat intelligence feed via Stix/Taxii',
  },
  hasNotifications: {
    label: 'Notifications',
    info: 'Notifications',
  },
  hasStream: {
    label: 'Streams & Feeds',
    info: 'Stream Access',
  },
  scans: {
    label: 'Scans',
    info: 'Scan a file or URL.',
  },
  downloads: {
    label: 'Downloads',
    info: 'Download artifacts from the PolySwarm network.',
  },
  hashSearches: {
    label: 'Hash Searches',
    info: 'Search for an artifact on the PolySwarm network by hash.',
  },
  metadataSearches: {
    label: 'Metadata Searches',
    info: 'Search for an artifact on the PolySwarm network by metadata.',
  },
  liveHunts: {
    label: 'Live Hunts',
    info: 'Live Hunting will match your active YARA ruleset against new artifacts as they are submitted.',
  },
  historicalHunts: {
    label: 'Historical Hunts',
    info: 'Historical Hunting will match your YARA ruleset against previously submitted artifacts.',
  },
  huntingYaraRules: {
    label: 'Hunting YARA Rules',
    info: 'YARA Rules used for Live and Historical Hunting in the PolySwarm network.',
  },
} as const;
type FeatureName = keyof typeof featuresTexts;
const columns = ['Name', 'Base Quota'];

const getFeatureLabel = (feature: string) =>
  featuresTexts[feature as FeatureName]?.label || feature;
const getFeatureInfo = (feature: string) => featuresTexts[feature as FeatureName]?.info || feature;
const formatValue = (value: string | number) =>
  Number.isInteger(value as number) ? numeral(value).format('0,0') : value;

const getQuota = (feature: Feature, period: 'day' | 'month') => {
  const notAvailable = feature.remainingUses === 0 && !feature.isIncludedOnPlan && !feature.value;
  const baseQuotaColor =
    feature.remainingUses === 0 && feature.isIncludedOnPlan && !feature.value
      ? styles.color.red
      : feature.remainingUses < feature.totalAvail && feature.isIncludedOnPlan && !feature.value
      ? styles.color.yellow
      : notAvailable
      ? styles.color.purple
      : null;

  return (
    <p
      style={{
        width: '200px',
        textAlign: 'left',
        color: baseQuotaColor || undefined,
      }}
    >
      {feature.totalAvail === Infinity
        ? 'UNLIMITED'
        : feature.baseUses === 0 && feature.value === false
        ? 'Requires Upgrade'
        : formatValue(feature.remainingUses) +
          ' of ' +
          formatValue(feature.totalAvail) +
          ' / ' +
          period}
    </p>
  );
};

const mapFeatures = (
  quotaFeatures: Array<
    AccountFeature & {
      isIncludedOnPlan: boolean;
      totalAvail: number;
      feature: string;
    }
  >
) => {
  const dailyApiLimitQuota = quotaFeatures.find((usage) => usage.tag === 'daily_api_limit');
  const monthlyApiLimitQuota = quotaFeatures.find((usage) => usage.tag === 'monthly_api_limit');
  const dailyBackingFeatures = quotaFeatures.filter(
    (usage) => usage.backingFeature === 'daily_api_limit' && usage.baseUses === 0
  );
  const monthlyBackingFeatures = quotaFeatures.filter(
    (usage) => usage.backingFeature === 'monthly_api_limit' && usage.baseUses === 0
  );
  const restOfFeatures = quotaFeatures
    .filter(
      (usage) =>
        dailyBackingFeatures.findIndex((feature) => feature.tag === usage.tag) === -1 ||
        monthlyBackingFeatures.findIndex((feature) => feature.tag === usage.tag) === -1
    )
    .filter((feature) => {
      if (dailyBackingFeatures.length > 0 && feature.tag === 'daily_api_limit') {
        return false;
      }
      if (monthlyBackingFeatures.length > 0 && feature.tag === 'monthly_api_limit') {
        return false;
      }
      return true;
    });

  const features = [
    dailyApiLimitQuota && dailyBackingFeatures.length > 0
      ? {
          name: 'PolySwarm Scan & Search',
          info: 'The maximum total daily usage for an account across all features',
          quota: getQuota(dailyApiLimitQuota, 'day'),
          subRows: dailyBackingFeatures.map((feature) => ({
            name: getFeatureLabel(feature.feature),
            info: getFeatureInfo(feature.feature),
            value: true,
          })),
        }
      : null,
    monthlyApiLimitQuota && monthlyBackingFeatures.length > 0
      ? {
          name: 'PolySwarm Intelligence',
          info: 'The maximum total monthly usage for an account across all features',
          quota: getQuota(monthlyApiLimitQuota, 'month'),
          subRows: monthlyBackingFeatures.map((feature) => ({
            name: getFeatureLabel(feature.feature),
            info: getFeatureInfo(feature.feature),
            value: true,
          })),
        }
      : null,
    ...restOfFeatures.map((feature) => ({
      name: getFeatureLabel(feature.feature),
      info: getFeatureInfo(feature.feature),
      quota: getQuota(feature, 'month'),
    })),
  ].filter((feature) => feature) as {
    name: string;
    info: string;
    quota: JSX.Element;
    subRows?: { name: string; info: string; value: boolean }[];
  }[];

  return features;
};

const UsageTable = ({ features }: ExpandableTableProps) => {
  const { classes } = useStyles();
  const rows = mapFeatures(features);
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

  const toggleRow = (index: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div css={classes.container}>
      <div css={classes.columns}>
        {columns.map((column, index) => (
          <div
            key={index}
            style={index === columns.length - 1 ? { width: '200px', textAlign: 'left' } : {}}
          >
            {column}
          </div>
        ))}
      </div>

      <div css={classes.rows}>
        {rows.map((row, index) => (
          <div key={index}>
            <div css={classes.rowContainer}>
              <div css={classes.row}>
                <span>{row.name}</span>
                <Tooltip placement='top' title={row.info}>
                  <div>
                    <Icon css={classes.helpIcon} name='info' />
                  </div>
                </Tooltip>
                {row.subRows && row.subRows.length > 0 ? (
                  <button onClick={() => row.subRows && toggleRow(index)}>
                    <Icon
                      name={expandedRows[index] ? 'chevron-up' : 'chevron-down'}
                      css={classes.chevronIcon}
                    />
                  </button>
                ) : null}
              </div>
              {row.quota}
            </div>

            {expandedRows[index] && row.subRows && row.subRows.length > 0 && (
              <div css={classes.expandedRowContainer}>
                {row.subRows.map((subRow, subIndex) => (
                  <div key={subIndex} css={classes.expandedRow}>
                    <div css={classes.row}>
                      <span>{subRow.name}</span>
                      <Tooltip placement='top' title={subRow.info}>
                        <div>
                          <Icon css={classes.helpIcon} name='info' />
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        width: '200px',
                        textAlign: typeof subRow.value === 'boolean' ? 'right' : 'left',
                      }}
                    >
                      {typeof subRow.value === 'boolean' ? (
                        subRow.value ? (
                          <Icon name='check-alt' css={classes.tickIcon} />
                        ) : (
                          '-'
                        )
                      ) : (
                        subRow.value
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      paddingLeft: '3rem',
    },
    columns: {
      display: 'flex',
      justifyContent: 'space-between',
      color: styles.color.xLightGrey,
      marginBottom: '2rem',
    },
    rows: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    rowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0.5rem',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    expandedRowContainer: {
      paddingLeft: '1rem',
      marginBottom: '1rem',
      marginTop: '3rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    expandedRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0.25rem',
    },
    tickIcon: {
      width: '0.9em !important',
      height: '0.9em !important',
      color: 'var(--main)',
      marginRight: '5.85rem',
      '@media (max-width: 1024px)': {
        marginRight: '9.15rem',
      },
    },
    chevronIcon: {
      width: '1.6em !important',
      height: '1.6em !important',
      marginTop: '-0.3em',
      marginLeft: '-0.3em',
      color: styles.color.grey,
    },
    helpIcon: {
      cursor: 'help',
      fontSize: '0.5rem',
      paddingLeft: '0.2rem',
      marginTop: '0.5rem',
      position: 'relative',
      color: styles.color.xLightGrey,
    },
  },
  light: {},
  dark: {},
});

export default UsageTable;
