import React from 'react';
import ErrorPageContent from 'views/components/error/ErrorPageContent';
import { useIntl, defineMessages } from 'react-intl';
import Button from '@material-ui/core/Button';
import { CONSUMER_DOCS_URL } from 'utils/constants';
import { btnMessages } from 'views/components/Button';

const messages = defineMessages({
  title: {
    id: 'metadataSearch.hunting.huntingError',
    defaultMessage: 'Failed to parse query',
  },
  text: {
    id: 'metadataSearch.hunting.text',
    defaultMessage:
      'Try another query, or check out our documentation for tips on how to improve your query.',
  },
  documentation: {
    id: 'metadataSearch.hunting.documentation',
    defaultMessage: 'documentation',
  },
});

export const HuntingError = () => {
  const intl = useIntl();

  return (
    <ErrorPageContent
      className='h-mt-lg h-mb-md'
      heading={intl.formatMessage(messages.title)}
      text={intl.formatMessage(messages.text)}
    >
      <Button
        style={{ fontSize: '2.4rem' }}
        color='primary'
        variant='contained'
        href={`${CONSUMER_DOCS_URL}/polyswarm-customer-api-v2#metadata-searching`}
      >
        {intl.formatMessage(btnMessages.readDocs)}
      </Button>
    </ErrorPageContent>
  );
};
