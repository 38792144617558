import React, { ChangeEvent } from 'react';
import { FieldArray } from 'formik';
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ETeamRole } from 'models/Team';
import styles from 'views/styles';
import CheckboxField from '../CheckboxField';

type CheckboxOption = {
  value: any;
  label: string;
};

interface ICheckboxArrayField {
  className?: string;
  name: string;
  label?: string;
  errorMessage?: string;
  options: CheckboxOption[];
  values: string[];
  onChange: (field: string, value: any) => void;
}

const CheckboxArrayField = ({
  className,
  name,
  label,
  values,
  options,
  errorMessage,
  onChange,
}: ICheckboxArrayField) => {
  const muiClasses = useMuiStyles();

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const value = target.value as ETeamRole;
    const valueArray = [...values];

    if (target.checked) {
      valueArray.push(value);
    } else {
      valueArray.splice(valueArray.indexOf(value), 1);
    }

    onChange(name, valueArray);
  };

  return (
    <div className={className}>
      <FieldArray
        name={name}
        render={() => (
          <FormControl error={!!errorMessage}>
            {label && <FormLabel className={muiClasses.label}>{label}</FormLabel>}
            <div className='h-mt-tiny'>
              <FormGroup>
                {options.map((opt, idx) => {
                  return (
                    <CheckboxField
                      key={idx}
                      className={muiClasses.checkbox}
                      label={opt.label}
                      checked={values.includes(opt.value)}
                      name={`${name}.${idx}`}
                      value={opt.value}
                      onChange={_onChange}
                    />
                  );
                })}
              </FormGroup>
              {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </div>
          </FormControl>
        )}
      />
    </div>
  );
};

const useMuiStyles = makeMuiStyles({
  label: {
    fontSize: styles.font.size.p1,
    fontWeight: styles.font.weight.medium,
  },
  checkbox: {
    margin: '0.4rem 0',
  },
});

export default CheckboxArrayField;
