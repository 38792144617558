import React, { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { FileUpload } from 'views/components/FileUpload';
import Card from '@material-ui/core/Card';
import MalwareTable from './MalwareTable';
import styled from '@emotion/styled';
import styles from 'views/styles';
import { useSystem } from 'views/components/providers/SystemProvider';
import { Notification } from 'views/components/Notification/Notification';
import { useUser } from 'views/components/providers/UserProvider';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';

const StyledCard = styled(Card)`
  padding: ${styles.spacing.md} ${styles.spacing.xl};
  margin: ${styles.spacing.sm};
`;

const messages = defineMessages({
  notificationText: {
    id: 'homePage.notification.text',
    defaultMessage: 'NectarNet Rewards Program is Live',
  },
  notificationLink: {
    id: 'homePage.notification.link',
    defaultMessage: 'Learn more',
  },
});

const HomePage = () => {
  const user = useUser();
  const intl = useIntl();
  const ctx = contextAccount(store);

  const { isMaintenanceMode } = useSystem();
  return (
    <Fragment>
      <SEO />
      <BaseLayout>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <StyledCard>
                <FileUpload isScan />
              </StyledCard>
              {!Boolean(ctx?.isPrivate) && !isMaintenanceMode && (
                <StyledCard>
                  <MalwareTable />
                </StyledCard>
              )}
            </div>
          </div>
        </div>
      </BaseLayout>
      {!Boolean(ctx?.isPrivate) && (
        <Notification id='REWARDS_LIVE' show={user.id !== -1}>
          {intl.formatMessage(messages.notificationText)}&nbsp;&nbsp;
          <a className='a' href='/account/rewards'>
            {intl.formatMessage(messages.notificationLink)}
          </a>
        </Notification>
      )}
    </Fragment>
  );
};

export default HomePage;
