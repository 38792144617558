import { Global, css } from '@emotion/core';

export default () => (
  <>
    <symbol id='icon-toggle-close' width='11' height='11' viewBox='0 0 11 11' fill='none'>
      <rect width='11' height='11' fill='#D9D9D9' />
      <line x1='2' y1='5.5' x2='9' y2='5.5' strokeWidth='1px' stroke='#3F3F3F' />
    </symbol>
    <Global
      styles={css`
        .icon-toggle-close {
          width: 0.6875em;
          height: 0.6875em;
        }
      `}
    />
  </>
);
