import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styles from 'views/styles';

interface Props {
  className?: string;
}

const PrivacyTerms = ({ className }: Props) => (
  <p className={className} css={ownStyle}>
    <FormattedMessage
      id='fileupload.privacy'
      defaultMessage='By proceeding you agree to our {privacy} & {terms}'
      values={{
        privacy: (
          <a className='a' href='/privacy' target='_blank' rel='noopener noreferrer'>
            <FormattedMessage id='fileupload.privacy.text' defaultMessage='Privacy Policy' />
          </a>
        ),
        terms: (
          <Link className='a' to='/terms'>
            <FormattedMessage id='fileupload.privacy.terms' defaultMessage='Terms of Service' />
          </Link>
        ),
      }}
    />
  </p>
);

const ownStyle = css`
  font-size: ${styles.font.size.p1};
  color: ${styles.color.medLightGrey};
`;

export default PrivacyTerms;
