import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Loader from 'views/components/Loader';

interface Props {
  style?: React.CSSProperties;
  heading: string;
  text: string;
  dataCy?: string;
}

const AuthLoader = ({ style, heading, text, dataCy }: Props) => {
  const { classes } = useStyles();
  return (
    <div style={style} css={classes.root} data-cy={dataCy}>
      <Loader />
      <h3 css={classes.heading}>{heading}</h3>
      <p>{text}</p>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    heading: {
      fontWeight: styles.font.weight.bold,
      fontSize: '3rem',
    },
  },
  light: {
    heading: {
      color: styles.color.black,
    },
  },
  dark: {
    heading: {
      color: styles.color.white,
    },
  },
});

export default AuthLoader;
