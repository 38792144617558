import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import CopyToClipboard from 'react-copy-to-clipboard';
import { AnalyticsEventType, useAnalytics } from 'views/components/providers/AnalyticsProvider';
import Icon from 'views/components/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface ICopyButtonProps {
  hash: string;
  analyticsEvent?: AnalyticsEventType['options'];
}

const CopyButton = ({ hash, analyticsEvent }: ICopyButtonProps) => {
  const { event: gaEvent } = useAnalytics();
  const { classes } = useStyles();

  const _handleClick = () => {
    if (!!analyticsEvent) {
      gaEvent(analyticsEvent);
    }
  };

  return (
    <Tooltip title={hash} placement='top'>
      <CopyToClipboard text={hash} onCopy={() => _handleClick()}>
        <div>
          <Icon css={classes.icon} name='copy' />
        </div>
      </CopyToClipboard>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  base: {
    icon: {
      margin: '0 0.6rem 0 0.6rem !important',
      width: '1.2em  !important',
      height: '1.2em  !important',
      fontSize: '1.2em !important',
      cursor: 'pointer',
    },
  },
  light: {
    icon: {
      color: styles.color.purple,
    },
  },
  dark: {
    icon: {
      color: styles.color.lightBlue,
    },
  },
});

export default CopyButton;
