import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys, errorMessages } from 'utils/error';
import { closeModal } from 'state/modal/actions';
import { HuntingActionName } from 'state/hunting/types';
import { runHistoricalHunt } from 'state/hunting/actions';
import { btnMessages } from 'views/components/Button';
import { formMessages } from 'utils/schema/formSchema';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import TextField from '@material-ui/core/TextField';

export interface RunHistoricalHuntModalParams {
  id: string;
  name: string;
}

const messages = defineMessages({
  heading: {
    id: 'rulesets.modal.histHuntHeading',
    defaultMessage: 'Run Historical Hunt',
  },
  text: {
    id: 'rulesets.modal.histHuntText',
    defaultMessage: 'Name this historical hunt and click Run.',
  },
});

const errorKeys: ErrorKeys = {
  rate_limit_exceeded: errorMessages.rateLimit.id,
};
const validFormat = new RegExp(/^[a-zA-Z0-9_\-\\+\s+]+$/);

/**
 * The RunHistoricalHuntModal component
 */
const RunHistoricalHuntModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [rulesetName, setRulesetName] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [HuntingActionName.RUN_HISTORICAL_HUNT]);
  const error = errorSelector(requests, [HuntingActionName.RUN_HISTORICAL_HUNT]);
  const { event: gaEvent } = useAnalytics();

  useEffect(() => {
    if (rulesetName && !validFormat.test(rulesetName)) {
      setFormError(intl.formatMessage(formMessages.allowedChars));
    } else {
      setFormError(null);
    }
  }, [intl, rulesetName]);

  const { id, name } = useSelector(
    ({ modal }: { modal: ModalState<RunHistoricalHuntModalParams> }) => modal.params
  );

  const _runHistoricalHunt = (id: string, name: string) => {
    dispatch(runHistoricalHunt(id, name));
    gaEvent({
      category: 'Hunting',
      action: 'Historical Hunt Started',
    });
  };

  return (
    <ConfirmModal
      testId='runHistoricalHuntModal'
      isLoading={isLoading}
      fullWidth={true}
      content={
        <div style={{ padding: '2.5rem 0' }}>
          <TextField
            onChange={(e) => setRulesetName(e.target.value)}
            fullWidth={true}
            defaultValue={name}
            error={!!formError}
            helperText={formError || ''}
            label='Ruleset Name'
          />
        </div>
      }
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text)}
      submitBtnText={intl.formatMessage(btnMessages.run)}
      disabled={!!formError}
      onSubmit={() => _runHistoricalHunt(id, rulesetName || name)}
      onCancel={() => dispatch(closeModal())}
    />
  );
};

export default RunHistoricalHuntModal;
