import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { resetRequest } from 'state/requests/actions';
import { getErrorMessage, getErrorData } from 'utils/error';
import { MicroenginesActionName } from 'state/microengines/types';
import { createClaimUserMicroengine } from 'state/microengines/actions';
import { closeModal } from 'state/modal/actions';
import { PageTimeQuery } from 'models/TimeSeries';
import { MicroengineListing } from 'models/Microengine';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import Modal from 'views/components/Modal';
import MicroengineForm from '../forms/MicroengineForm';
import { CheckboxOption } from 'views/pages/AuthCallbackPage/AccountSignupForm/CheckboxArrayField';

interface FormData {
  mimetypes: string[];
  artifactTypes: CheckboxOption[];
}

export interface CreateMicroengineModalParams {
  refresh: boolean;
  queryParams: PageTimeQuery<MicroengineListing>;
  formData: FormData;
}

const messages = defineMessages({
  heading: {
    id: 'microengines.add.heading',
    defaultMessage: 'Add engine',
  },
});

const CreateMicroengineModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);

  const isLoading = loadingSelector(requests, [
    MicroenginesActionName.CREATE_USER_MICROENGINE,
    MicroenginesActionName.CLAIM_USER_MICROENGINE,
  ]);

  const error = errorSelector(requests, [
    MicroenginesActionName.CREATE_USER_MICROENGINE,
    MicroenginesActionName.CLAIM_USER_MICROENGINE,
  ]);

  const { refresh, queryParams, formData } = useSelector(
    ({ modal }: { modal: ModalState<CreateMicroengineModalParams> }) => modal.params
  );

  return (
    <Modal>
      <MicroengineForm
        css={ownStyle}
        formData={formData}
        heading={intl.formatMessage(messages.heading)}
        buttonText={intl.formatMessage(btnMessages.claim)}
        isLoading={isLoading}
        errorMessage={error && getErrorMessage(error)}
        errorData={error && getErrorData(error)}
        onSubmit={({ accountNumber, ...values }) => {
          return dispatch(createClaimUserMicroengine(values, accountNumber!, queryParams, refresh));
        }}
        onCancel={() => {
          dispatch(closeModal());
          dispatch(resetRequest(MicroenginesActionName.CREATE_USER_MICROENGINE));
          dispatch(resetRequest(MicroenginesActionName.CLAIM_USER_MICROENGINE));
        }}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default CreateMicroengineModal;
