import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { isNil } from 'lodash';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { ToolMetadata } from 'models/Submission';
import { formatMessageByKey, IntlMessages } from 'utils/messageUtils';
import metadataMessages from './metadataMessages';
import styles from 'views/styles';
import Accordion from '../Accordion';
import { Toggle } from 'views/components/Toggle';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { generalMessages } from '../../../index';

interface IAccordionFileDetails {
  id?: string;
  className?: string;
  title: string;
  data?: ToolMetadata | null | undefined;
  helpText?: string;
  columns?: number;
  openByDefault?: boolean;
  dataCy?: string;
}

/**
 * Get translation strings from metadata
 * See: metadataMessages
 */
const getTranslatableMetadata = (data: ToolMetadata, messages: IntlMessages) => {
  return Object.entries(data)
    .filter(
      ([key, value]) =>
        messages.hasOwnProperty(key) &&
        (Array.isArray(value) ? value.length : value !== '' && !isNil(value))
    )
    .reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {});
};

/**
 * Get translation string from booleans
 */
export const formatAccordionValue = (key: string, value: any, intl: IntlShape) => {
  if (key === 'first_seen' || key === 'last_scanned') {
    return formatDate(parseDate(value), DATE_TIME_FORMAT);
  }
  if (typeof value === 'boolean') {
    return value
      ? intl.formatMessage(generalMessages.true)
      : intl.formatMessage(generalMessages.false);
  } else if (typeof value === 'string') {
    return value;
  }
};

/**
 * The AccordionFileDetails Component
 */
const AccordionFileDetails = (props: IAccordionFileDetails) => {
  const { className, title, id, data, helpText, openByDefault, dataCy } = props;
  const intl = useIntl();
  const { classes } = useStyles(props);
  const { active, isPivotable } = usePivoting();

  if (isNil(data)) {
    return null;
  }

  const metadata = getTranslatableMetadata(data, metadataMessages);

  return (
    <Accordion
      className={className}
      title={title}
      helpText={helpText}
      openByDefault={openByDefault || true}
      dataCy={dataCy}
    >
      <div css={classes.contentWrap}>
        <div css={classes.contentInner}>
          {Object.keys(metadata).length <= 0 ? (
            <p css={classes.noneFound}>
              <FormattedMessage {...generalMessages.noData} />
            </p>
          ) : (
            <ul css={classes.content}>
              {Object.entries(metadata).map(([key, value]) => (
                <li key={key} css={classes.item}>
                  <h4
                    key={`h-${key}`}
                    css={[
                      classes.itemHeading,
                      active && classes.mleft,
                      active &&
                        isPivotable({
                          id,
                          section: title,
                          item: key,
                        }) &&
                        classes.headingHighlighted,
                    ]}
                  >
                    {formatMessageByKey(intl, key, metadataMessages)}
                  </h4>
                  {Array.isArray(value) ? (
                    value.map((item) => (
                      <Toggle id={id} section={title} itemKey={key} itemValue={item}>
                        {formatAccordionValue(key, item, intl)}
                      </Toggle>
                    ))
                  ) : (
                    <Toggle
                      id={id}
                      section={title}
                      itemKey={key}
                      itemValue={formatAccordionValue(key, value, intl) || ''}
                    >
                      {formatAccordionValue(key, value, intl)}
                    </Toggle>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Accordion>
  );
};

const useStyles = makeStyles(({ columns }: IAccordionFileDetails) => ({
  base: {
    contentWrap: {
      position: 'relative',
    },
    contentInner: {
      maxWidth: '60rem',
      [`@media (min-width: ${styles.breakpoint.lg})`]: {
        maxWidth: '74rem',
      },
      [`@media (min-width: ${styles.breakpoint.xl})`]: {
        maxWidth: '80rem',
      },
      [`@media (min-width: ${styles.breakpoint.xxl})`]: {
        maxWidth: '90rem',
      },
    },
    noneFound: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: styles.font.weight.medium,
      paddingBottom: styles.spacing.grid,
      fontSize: styles.font.size.p1,
    },
    content: {
      position: 'relative',
      [`@media (min-width: ${styles.breakpoint.sm})`]: {
        columns,
      },
    },
    itemHeading: {
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.medium,
      marginBottom: '0.3rem',
      marginLeft: '1rem',
    },
    item: {
      breakInside: 'avoid',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      marginBottom: styles.spacing.xxs,
      '&:last-child': {
        marginBottom: 0,
      },
      maxHeight: '40rem',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '0.5rem',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '0.4rem',
      },
    },
    itemContent: {
      fontSize: styles.font.size.p1,
      margin: `${styles.spacing.tiny} 0`,
      maxWidth: '40rem',
    },
    mleft: {
      marginLeft: '3.9rem',
    },
  },
  light: {
    noneFound: {
      color: styles.color.xLightGrey,
    },
    content: {
      background: styles.color.white,
    },
    itemHeading: {
      color: styles.color.xLightGrey,
    },
    item: {
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, .5)',
      },
    },
    headingHighlighted: {
      color: styles.color.purple,
    },
  },
  dark: {
    noneFound: {
      color: styles.color.xLightGrey,
    },
    content: {
      background: styles.color.darkXBlack,
    },
    itemHeading: {
      color: styles.color.xxxLightGrey,
    },
    item: {
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, .5)',
      },
    },
    headingHighlighted: {
      color: styles.color.lightBlue,
    },
  },
}));

export default AccordionFileDetails;
