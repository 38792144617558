import { css } from '@emotion/css';

import graphikBlack from 'assets/tenants/accenture/graphik-black-web.woff2';
import graphikBold from 'assets/tenants/accenture/graphik-bold-web.woff2';
import graphikRegular from 'assets/tenants/accenture/graphik-regular-web.woff2';
import graphikSemibold from 'assets/tenants/accenture/graphik-semibold-web.woff2';

export const fonts = css`
  @font-face {
    font-family: 'Graphik';
    src: url(${graphikRegular}) format('woff2');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Graphik';
    src: url(${graphikSemibold}) format('woff2');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Graphik';
    src: url(${graphikBold}) format('woff2');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Graphik';
    src: url(${graphikBlack}) format('woff2');
    font-weight: 900;
  }
`;
