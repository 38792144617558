import { ModalState } from '.';
import { ModalAction, ModalActionName } from './types';

export const initialState: ModalState = {
  currentModal: null,
  params: {},
};

const modal = (state: ModalState = initialState, action: ModalAction): ModalState => {
  switch (action.type) {
    case ModalActionName.OPEN_MODAL: {
      return {
        currentModal: action.name,
        params: action.params,
      };
    }

    case ModalActionName.CLOSE_MODAL: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default modal;
