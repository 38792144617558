import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { defineMessages, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ability from 'config/ability';
import { Row } from 'views/components/table/CustomTable';
import { AccountUsage } from 'models/Account';
import TableCell from '@material-ui/core/TableCell';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import { openModal } from 'state/modal/actions';
import UsageTableRow from './UsageTableRow';
import {
  hasMadeScopedMFARecently,
  loginWriteTeams,
  getMFAData,
  removeMFAData,
} from 'state/auth/actions';
import useSetOverage from './useSetOverage';
import { useUser } from 'views/components/providers/UserProvider';
import { getTeamAccount } from 'state/team/actions';

export interface Quotas extends AccountUsage {
  baseQuota: number;
  overageQuota?: number;
  isIncludedOnPlan?: boolean;
}

interface Props {
  usage: AccountUsage[];
  overageSupportFeature?: boolean;
}

type QuotasOrSeparator = Quotas | { separator: boolean };

const messages = defineMessages({
  feature: {
    id: 'team.usage.feature',
    defaultMessage: 'Name',
  },
  baseQuota: {
    id: 'team.usage.baseQuota',
    defaultMessage: 'Base Quota',
  },
  overageQuota: {
    id: 'team.usage.overageQuota',
    defaultMessage: 'Overage Quota',
  },
  totalAvail: {
    id: 'team.usage.totalAvail',
    defaultMessage: 'Plan Allowance',
  },
  editOverage: {
    id: 'team.usage.editOverage',
    defaultMessage: 'Edit Overages',
  },
  done: {
    id: 'team.usage.done',
    defaultMessage: 'Done',
  },
});

const rows: Row<Quotas>[] = [
  {
    id: 'feature',
    numeric: false,
    label: messages.feature.id,
    sortable: false,
    width: 300,
  },
  {
    id: 'baseQuota',
    numeric: true,
    label: messages.baseQuota.id,
    sortable: false,
    width: 200,
  },
  {
    id: 'overageQuota',
    numeric: true,
    label: messages.overageQuota.id,
    sortable: false,
    width: 250,
  },
];

const mapUsage = (usage: AccountUsage) => {
  // const isUnlimited = usage.totalAvail === Infinity;
  return {
    ...usage,
    baseQuota: usage.baseQuota,
    overageQuota: usage.overage,
    totalAvail: usage.totalAvail,
    remainingUses: usage.remainingUses,
  };
};

const UsageTable = ({ usage, overageSupportFeature }: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const { setOverage } = useSetOverage();
  const user = useUser();
  const accountNumber = user.context?.accountNumber || user.accountNumber;

  const groupedUsage: QuotasOrSeparator[] = [];
  usage
    .map((x) => mapUsage(x))
    .forEach((x: Quotas) => {
      groupedUsage.push(x);
      if (['apiRequestLimit', 'iocUser'].includes(x.feature)) {
        groupedUsage.push({ separator: true });
      }
    });

  const _faCheck = (data: { location: string; editing: boolean }) => {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({
              ...data,
              modal: 'CHANGE_QUOTA',
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal, editing } = mfaStep;
      if (modal === 'CHANGE_QUOTA') {
        setEditing(editing);
        removeMFAData();
      }
    }
  }, []);

  return (
    <CustomTable<QuotasOrSeparator>
      rows={groupedUsage}
      renderTableHead={() => (
        <CustomTableHead<Row<Quotas>>
          rows={rows.filter((row) => {
            if (!overageSupportFeature && row.id === 'overageQuota') {
              return false;
            }
            return true;
          })}
          renderHeaderCell={(row) => (
            <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
              {intl.formatMessage({ id: row.label })}
            </TableCell>
          )}
        />
      )}
      renderTableRow={(usage) => {
        if ('separator' in usage) {
          return (
            <TableCell colSpan={4} style={{ borderBottom: 'none' }}>
              <hr
                className='separator'
                style={{
                  margin: `2rem 0.5rem 2rem 30px`,
                  border: '0.05rem solid #ccc',
                }}
              />
            </TableCell>
          );
        }
        return (
          <UsageTableRow
            overageSupportFeature={overageSupportFeature}
            key={usage.feature}
            usage={usage}
            isEditing={editing}
            onEdit={(feature, value) => {
              setOverage(feature, value);
            }}
          />
        );
      }}
      renderFab={() => {
        return overageSupportFeature && ability.can('manage', 'Quota') ? (
          <Button
            color='primary'
            style={{ fontSize: '1.4rem', fontWeight: 'bold' }}
            variant='contained'
            onClick={async () => {
              if (!hasMadeScopedMFARecently()) {
                _faCheck({
                  location: location.pathname,
                  editing: !editing,
                });
              } else {
                if (editing) {
                  await dispatch(getTeamAccount(accountNumber));
                }
                setEditing(!editing);
              }
            }}
          >
            {!editing
              ? intl.formatMessage(messages.editOverage)
              : intl.formatMessage(messages.done)}
          </Button>
        ) : null;
      }}
    />
  );
};

export default UsageTable;
