import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { skeletonBgShine, skeletonGradientBg } from 'views/components/Loader/Skeleton/Skeleton';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface Props {
  className?: string;
}

const ScanItemSkeleton = ({ className }: Props) => {
  const { classes } = useStyles();
  return (
    <div className={className} css={classes.root}>
      <span css={classes.text} />
      <span css={classes.icon} />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      flex: '1 0 calc(50% - 15px)',
      maxWidth: 'calc(50% - 15px)',
    },
    text: {
      display: 'block',
      borderRadius: styles.border.radius,
      height: 20,
      width: 'calc(100% - 60px)',
      margin: `calc(${styles.spacing.grid} / 2) 0`,
      '&:after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundSize: '50px 200px',
        backgroundPosition: '-20% 0',
        backgroundRepeat: 'repeat-y',
        animation: `${skeletonBgShine} 1.25s infinite`,
      },
    },
    icon: {
      display: 'block',
      borderRadius: styles.border.radius,
      height: 20,
      width: 20,
      margin: `calc(${styles.spacing.grid} / 2) 0`,
    },
  },
  light: {
    text: {
      backgroundColor: styles.color.xLightPurple,
      '&:after': {
        backgroundImage: skeletonGradientBg(255, 255, 255),
      },
    },
    icon: {
      backgroundColor: styles.color.xLightPurple,
    },
  },
  dark: {
    text: {
      backgroundColor: styles.color.xxDarkPurple,
      '&:after': {
        backgroundImage: skeletonGradientBg(78, 70, 124),
      },
    },
    icon: {
      backgroundColor: styles.color.xxDarkPurple,
    },
  },
});

export default ScanItemSkeleton;
