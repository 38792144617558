import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorPageContent, { messages } from 'views/components/error/ErrorPageContent';
import BaseLayout from 'views/components/layout/BaseLayout';
import { ButtonLink } from 'views/components/Button';

const PageNotFound = () => {
  const intl = useIntl();
  return (
    <BaseLayout>
      <div className='container h-mt-xl h-mb-xxl' data-cy='pageNotFound'>
        <div className='row'>
          <div className='col-12'>
            <ErrorPageContent
              status='404'
              heading={intl.formatMessage(messages.heading404)}
              text={intl.formatMessage(messages.text404)}
            >
              <ButtonLink variant='large' to='/' dataCy='errorButton'>
                <FormattedMessage {...messages.button404} />
              </ButtonLink>
            </ErrorPageContent>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default PageNotFound;
