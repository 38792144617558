import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage, defineMessages } from 'react-intl';
import styles from 'views/styles';
import Button from '@material-ui/core/Button';
import { btnMessages } from 'views/components/Button';
import { ScannerDetailsModalParams } from '.';
import Modal from 'views/components/Modal';

interface Props extends ScannerDetailsModalParams {
  onClick: () => void;
}

const messages = defineMessages({
  heading: {
    id: 'engineMeta.heading',
    defaultMessage: 'Scanner Details',
  },
  authorName: {
    id: 'engineMeta.name',
    defaultMessage: 'Name',
  },
  author: {
    id: 'engineMeta.address',
    defaultMessage: 'Address',
  },
  architecture: {
    id: 'engineMeta.architecture',
    defaultMessage: 'Architecture',
  },
  operating_system: {
    id: 'engineMeta.os',
    defaultMessage: 'Operating System',
  },
  signatures_version: {
    id: 'engineMeta.signaturesVersion',
    defaultMessage: 'Signatures Version',
  },
  vendor_version: {
    id: 'engineMeta.vendorVersion',
    defaultMessage: 'Vendor Version',
  },
  version: {
    id: 'engineMeta.version',
    defaultMessage: 'Version',
  },
});

const ScannerDetailsModal = ({ authorName, author, metadata, onClick }: Props) => {
  const items = {
    authorName,
    author,
    architecture: metadata.scanner ? metadata.scanner.architecture : '',
    operating_system: metadata.scanner ? metadata.scanner.operating_system : '',
    signatures_version: metadata.scanner ? metadata.scanner.signatures_version : '',
    vendor_version: metadata.scanner ? metadata.scanner.vendor_version : '',
    version: metadata.scanner ? metadata.scanner.version : '',
  };

  return (
    <Modal dataCy='scannerDetailsModal'>
      <div css={style.root}>
        <h3 className='h3 h-mb-tiny'>
          <FormattedMessage {...messages.heading} />
        </h3>
        <div>
          {Object.entries(items).map(([key, value]) => {
            const k = key as keyof typeof items;
            return (
              items[k] && (
                <div css={style.item}>
                  <h4 css={style.itemKey}>
                    <FormattedMessage {...messages[k]} />
                  </h4>
                  <p css={style.itemValue}>{value}</p>
                </div>
              )
            );
          })}
        </div>
        <div css={style.btnWrap}>
          <Button className='h-mt-xs' color='primary' variant='contained' onClick={onClick}>
            <FormattedMessage {...btnMessages.close} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const style = {
  root: css`
    padding: ${styles.spacing.md};
    width: 100%;
    max-width: 54rem;
    margin: 0 auto;
  `,
  item: css`
    margin-bottom: ${styles.spacing.tiny};
    &:last-child {
      margin-bottom: 0;
    }
  `,
  itemKey: css`
    font-weight: ${styles.font.weight.bold};
  `,
  itemValue: css`
    overflow-wrap: break-word;
    word-wrap: break-word;
  `,
  btnWrap: css`
    text-align: center;
  `,
};

export default ScannerDetailsModal;
