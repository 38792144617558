import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import styled from '@emotion/styled';
import styles from 'views/styles';

interface IPricingSidebarConfirm {
  onScan: () => void;
  onCreateAccount: () => void;
  isAuthenticated: boolean;
}

const Wrapper = styled.div`
  text-align: center;
`;

const Header = styled.h1`
  font-size: ${styles.font.size.h4};
  color: ${styles.color.purple};
`;

const Text2 = styled.p`
  font-weight: bold;
  color: ${styles.color.purple};
  text-align: center;
  margin-top: ${styles.spacing.md};
`;

const SubmitButton = styled(Button)`
  font-size: 1.6rem !important;
`;

const ContactConfirm = ({ onScan, onCreateAccount, isAuthenticated }: IPricingSidebarConfirm) => (
  <Wrapper data-cy='pricingSidebarConfirm'>
    <Header className='h2 h-mb-tiny'>
      <FormattedMessage id='pricingpage.confirm.header' defaultMessage='Thank you!' />
    </Header>
    {isAuthenticated ? (
      <Fragment>
        <p className='h-mt-xxs'>
          <FormattedMessage
            id='pricingpage.confirm.text1'
            defaultMessage='Our team will be in touch shortly.'
          />
        </p>
        <SubmitButton
          data-cy='pricingSidebarScanBtn'
          className='h-mt-xs'
          color='primary'
          variant='contained'
          onClick={onScan}
        >
          <FormattedMessage
            id='pricingpage.confirm.scanArtifactbtnText'
            defaultMessage='Scan an artifact'
          />
        </SubmitButton>
      </Fragment>
    ) : (
      <Fragment>
        <p className='h-mt-xxs'>
          <FormattedMessage
            id='pricingpage.confirm.text1.verify'
            defaultMessage='Please click on the confirmation link we sent you by email to validate your form submission.'
          />
        </p>
        <Text2 className='h-mt-xxs'>
          <FormattedMessage
            id='pricingpage.confirm.text2'
            defaultMessage='In the meantime, you can get started with our free community plan!'
          />
        </Text2>

        <SubmitButton
          data-cy='pricingSidebarAccountBtn'
          className='h-mt-xs'
          color='primary'
          variant='contained'
          onClick={onCreateAccount}
        >
          <FormattedMessage
            id='pricingpage.confirm.createAccountBtnText'
            defaultMessage='Create an account'
          />
        </SubmitButton>
      </Fragment>
    )}
  </Wrapper>
);

export default ContactConfirm;
