import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/root';
import { AccountFeature } from 'models/Account';

export default function useHasFeature(feature: string) {
  const [loading, setLoading] = useState(true);
  const { hasFeature, remainingUses = 0 } = useSelector<
    RootState,
    { hasFeature: boolean; remainingUses?: boolean }
  >(({ user, team, account }) => {
    // Get features for either team or user depending on context
    let features: AccountFeature[] = [];
    if (account.context && account.context.team && team.account) {
      features = team.account.features;
    } else if (user.account && user.account.features) {
      features = user.account.features;
    }

    const filteredFeature = features.find((f) => f.tag === feature);

    return {
      hasFeature: !!filteredFeature,
      remainingUses:
        filteredFeature && (filteredFeature.value || filteredFeature?.remainingUses > 0),
    };
  });

  // Wait for user's features to load
  setTimeout(() => {
    if (loading) setLoading(false);
  }, 1500);
  if (hasFeature && loading) setLoading(false);
  return { hasFeature, remainingUses, hasPermission: hasFeature, loading };
}
