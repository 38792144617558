import { useEffect, useState } from 'react';
import api from 'services/api';

export const useArtifactTypes = () => {
  const [artifactTypes, setArtifactTypes] = useState<string[]>([]);
  const loading = artifactTypes.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await api.getMicroengineArtifactTypes();
      if (active) {
        setArtifactTypes(response.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return {
    loading,
    artifactTypes: artifactTypes.length
      ? artifactTypes.map((artifactType) => ({
          value: artifactType,
          label: artifactType,
        }))
      : [],
  };
};
