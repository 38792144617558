import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { Hunt } from 'models/Ruleset';
import { showNotification } from 'state/notification/actions';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import TableCell from '@material-ui/core/TableCell';
import HoverableTableRow from 'views/components/table/HoverableTableRow';
import { btnMessages } from 'views/components/Button';
import ActionBtn from 'views/components/table/ActionBtn';
import { PolyScore } from 'views/components/PolyScore';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { toggleHuntsType } from 'views/pages/HuntPage/types';
import { useGetHuntResult } from 'views/pages/HuntPage/hooks';
import { formatDistanceToNow, parseUTCDate } from 'utils/date/date';
import { parseScanPageURL } from 'views/url';

type AnyHunt = Hunt & { active?: boolean };

interface IHuntingRow {
  rowData: AnyHunt;
  viewRuleset: (id: string) => void;
  downloadArtifact: (hash: string, url: string) => void;
  deleteHunt: (id: string) => void;
  showHuntingInfo: (liveHuntId: string, rulesetId: string) => void;
  modalTitle: string;
  selectedHunts: string[];
  toggleHunts: toggleHuntsType;
}

const messages = defineMessages({
  results: {
    id: 'huntingTable.row.results',
    defaultMessage: 'Results',
  },
  rulesetName: {
    id: 'huntingTable.row.rulesetName',
    defaultMessage: 'Ruleset Name',
  },
  ruleName: {
    id: 'huntingTable.row.ruleName',
    defaultMessage: 'Rule Name',
  },
  status: {
    id: 'huntingTable.row.status',
    defaultMessage: 'Status',
  },
  sha256: {
    id: 'huntingTable.row.sha256',
    defaultMessage: 'SHA256',
  },
  malwareFamily: {
    id: 'huntingTable.row.malwareFamily',
    defaultMessage: 'Malware Family Name',
  },
  polyscore: {
    id: 'huntingTable.row.polyscore',
    defaultMessage: 'PolyScore™',
  },
  detections: {
    id: 'huntingTable.row.detections',
    defaultMessage: 'Detections',
  },
  matchedOn: {
    id: 'huntingTable.row.matchedOn',
    defaultMessage: 'Matched On',
  },
  unnamed: {
    id: 'huntingTable.status.unnamed',
    defaultMessage: 'Unnamed',
  },
  errorDownloading: {
    id: 'huntingTable.status.errorDownloading',
    defaultMessage: 'Error downloading the artifact. Please try again.',
  },
  deleteAction: {
    id: 'huntingTable.actions.delete',
    defaultMessage: 'Delete This Match',
  },
  viewResult: {
    id: 'huntingTable.actions.viewResult',
    defaultMessage: 'View Scan Results and Metadata',
  },
  downloadArtifact: {
    id: 'huntingTable.actions.downloadArtifact',
    defaultMessage: 'Download Artifact',
  },
});

const HuntingRow = ({
  rowData,
  viewRuleset,
  deleteHunt,
  downloadArtifact,
  showHuntingInfo,
  modalTitle,
  selectedHunts,
  toggleHunts,
}: IHuntingRow) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { getHuntResult } = useGetHuntResult();

  const location = useLocation();
  const isLiveHuntPage = location.pathname === '/hunt/live';

  const { classes } = useStyles();

  const _showError = (error: string) =>
    dispatch(showNotification({ status: 'failure', message: error }));

  return (
    <HoverableTableRow
      dataCy='huntingResultRow'
      renderCells={() => (
        <Fragment>
          <TableCell>
            <Checkbox
              color='primary'
              // @ts-ignore
              checked={selectedHunts.includes(rowData.id)}
              onChange={(value) => {
                toggleHunts(value.target.checked, rowData.id);
              }}
            />
          </TableCell>
          <TableCell>
            <span>{rowData?.community || '-'}</span>
          </TableCell>
          <TableCell>
            <span>{rowData.rule_name}</span>
          </TableCell>
          <TableCell>
            <Tooltip title={rowData.sha256} placement='top-start'>
              <span>
                {`${rowData.sha256.substring(0, 10)}…`} <CopyButton text={rowData.sha256} />
              </span>
            </Tooltip>
          </TableCell>
          <TableCell>
            <span>{rowData.malware_family}</span>
          </TableCell>
          <TableCell>
            <PolyScore classNumber={classes.polyscore} polyscore={rowData.polyscore} bar={false} />
          </TableCell>
          <TableCell>
            <span>
              {rowData.detections?.malicious}/{rowData.detections?.total}
            </span>
          </TableCell>
          <TableCell>
            <span>{`${formatDistanceToNow(parseUTCDate(rowData.created))} ago`}</span>
          </TableCell>
          <TableCell css={classes.actionsCell} align='left'>
            <Fragment>
              <Tooltip title={formatMessage(messages.viewResult)} placement='top'>
                <span>
                  <ActionBtn
                    name='open-view'
                    aria-label={formatMessage(btnMessages.info)}
                    onClick={() =>
                      window.open(
                        parseScanPageURL(rowData.sha256, {
                          type: 'file',
                          instanceId: rowData.instance_id,
                        }),
                        '_blank'
                      )
                    }
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(messages.downloadArtifact)} placement='top'>
                <span>
                  <ActionBtn
                    name='download'
                    aria-label={formatMessage(btnMessages.download)}
                    onClick={async () => {
                      try {
                        const result = await getHuntResult(
                          rowData.id,
                          isLiveHuntPage ? 'live' : 'historical',
                          !isLiveHuntPage ? 'true' : undefined
                        );
                        if (result.download_url) {
                          downloadArtifact(rowData.sha256, result.download_url);
                        } else {
                          _showError(formatMessage(messages.errorDownloading));
                        }
                      } catch (error) {
                        _showError(formatMessage(messages.errorDownloading));
                      }
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(btnMessages.viewRuleset)} placement='top'>
                <span>
                  <ActionBtn
                    style={{ marginLeft: 8 }}
                    name='list'
                    aria-label={formatMessage(btnMessages.viewRuleset)}
                    data-cy='huntingViewRulesetBtn'
                    onClick={(e) => {
                      e.stopPropagation();
                      viewRuleset(rowData.id);
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title={modalTitle} placement='top'>
                <span>
                  <ActionBtn
                    name='info'
                    aria-label={formatMessage(btnMessages.info)}
                    onClick={() => showHuntingInfo(rowData.livescan_id, rowData.instance_id)}
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(messages.deleteAction)} placement='top-start'>
                <span>
                  <ActionBtn
                    style={{ marginLeft: 8 }}
                    name='delete'
                    aria-label={formatMessage(btnMessages.delete)}
                    data-cy='huntingDeleteBtn'
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteHunt(rowData.id);
                    }}
                  />
                </span>
              </Tooltip>
            </Fragment>
          </TableCell>
        </Fragment>
      )}
    />
  );
};

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const useStyles = makeStyles({
  base: {
    polyscore: {
      fontSize: '1.6rem',
      textAlign: 'left',
    },
    actionsCell: {
      width: '18rem',
    },
  },
  light: {},
  dark: {},
});

export default HuntingRow;
