import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import Loader from 'views/components/Loader';

interface IPanelLoaderProps {
  className?: string;
}

const PanelLoader = ({ className }: IPanelLoaderProps) => (
  <div css={style.loaderSpace} className={className}>
    <div css={style.loaderWrap}>
      <Loader css={style.loader} />
    </div>
  </div>
);

const style = {
  loaderSpace: css`
    min-height: 70rem;
  `,
  loaderWrap: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  loader: css`
    font-size: 0.75rem;
  `,
};

export default PanelLoader;
