import styled from '@emotion/styled';
import Chip from '@material-ui/core/Chip';

interface IColor {
  background: string;
  font: string;
  bordered?: boolean;
  tooltip?: {
    id: string;
    defaultMessage: string;
  };
  notification?: {
    id: string;
    defaultMessage: string;
  };
}

export interface ITagStatusConfig {
  [key: string]: IColor;
}

const TagStatus = styled(Chip)<{ tagConfig: any }>`
  color: ${({ tagConfig }) => `${tagConfig.font} !important`};
  border: ${({ tagConfig }) =>
    tagConfig.bordered ? `2px solid ${tagConfig.background} !important` : 'none'};
  margin-right: 5px !important;
  font-size: 1.2rem !important;
  background-color: ${({ tagConfig }) =>
    tagConfig.bordered ? 'white !important' : `${tagConfig.background} !important`};
`;

export default TagStatus;
