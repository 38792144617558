import { useState, useEffect } from 'react';
import { RootState } from 'state/root';
import warningImg from 'assets/images/warning.svg';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import {
  ILimitedAccessMember,
  ILimitedAccessMemberForm,
  LimitedAccessMembersPage,
} from 'models/Team';
import PanelContent from 'views/components/layout/PanelContent';
import { extractPageQueryParams } from 'utils/pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getApiKeys } from 'state/apiKeys/actions';
import { PageQuery } from 'models/Page';
import { useHistory, useLocation } from 'react-router-dom';
import { getMFAData, loginWriteApiKey, removeMFAData } from 'state/auth/actions';
import { contextAccount } from 'state/auth/selectors';
import { openModal } from 'state/modal/actions';
import { store } from 'state/store';
import { ApiKeysActionName } from 'state/apiKeys/types';
import Button from '@material-ui/core/Button';
import FabButton from 'views/components/Button/FabButton';
import Can from 'views/components/auth/Can';
import { useUser } from 'views/components/providers/UserProvider';
import AppLoader from 'views/components/request/AppLoader';
import { Fields } from 'views/pages/AccountPage/AccountPanel/AccountTabs/ApiKeysTab/modals/AddLimitedAccessModal';
import TeamLimitedAccessTable from './TeamLimitedAccessTable';
import ActionPopover from 'components/ActionPopover';
import { FilterProps, TeamLimitedAccessFilter } from './Filter';
import useHasPrivateCommunity from 'hooks/useHasPrivateCommunity';

const messages = {
  warningText: 'Account must have 2FA enabled to proceed',
  setup2fa: 'Setup 2FA',
};

const TeamLimitedAccessTab = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { mfaEnabled } = useUser();
  const [isFilterToggle, setFilterToggle] = useState(false);
  const { apiKeys } = useSelector((state: RootState) => state);
  const members = (!!apiKeys?.results
    ? apiKeys
    : { results: [] }) as unknown as LimitedAccessMembersPage;
  const ctx = contextAccount(store);
  const teamAccountNumber = ctx?.accountNumber!;
  const hasPrivateCommunity = useHasPrivateCommunity(ctx);

  const _createLimitedAccessMember = async (limitedAccessForm: ILimitedAccessMemberForm) => {
    if (mfaEnabled) {
      dispatch(
        loginWriteApiKey({
          location: location.pathname,
          modal: 'CONFIRM_APIKEY_CREATION',
          limitedAccessForm,
        })
      );
    }
  };

  const _archiveApiKey = async (id: string, key: string) => {
    if (mfaEnabled) {
      dispatch(
        loginWriteApiKey({
          location: location.pathname,
          modal: 'CONFIRM_APIKEY_DELETION',
          limitedAccessForm: { id, key },
        })
      );
    }
  };

  const openAddLimitedAccessModal = () => {
    dispatch(
      openModal('ADD_LIMITED_ACCESS', {
        hasPrivateCommunity,
        onSubmit: async (values: Fields) => {
          const data = {
            type: 'portal-external',
            teamAccountNumber: `${teamAccountNumber}`,
            emails: values.emails,
            community: values.isPrivate ? 'private' : 'public',
            tags: [`team:${values.accountNumber}`].concat(values.tags),
            scope: { feature_tags: values.scope },
            expiration: values?.expire ? '' : new Date(values.date).toISOString(),
          };
          _createLimitedAccessMember(data);
        },
        filters: {},
      })
    );
  };

  const _getApiKeys = (refresh: boolean, query?: PageQuery<ILimitedAccessMember>) => {
    const params = Object.assign({}, extractPageQueryParams(apiKeys), query, {});
    dispatch(getApiKeys({ ...params, types: 'portal-external' }, refresh));
  };

  // Only need to fetch data on mount
  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal, limitedAccessForm } = mfaStep;
      if (modal === 'CONFIRM_APIKEY_CREATION') {
        dispatch(
          openModal(modal, {
            limitedAccessForm,
          })
        );
        removeMFAData();
      } else if (modal === 'CONFIRM_APIKEY_DELETION') {
        dispatch(
          openModal(modal, {
            limitedAccessForm,
          })
        );
        removeMFAData();
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (mfaEnabled) {
      _getApiKeys(false);
    }
  }, [mfaEnabled]); // eslint-disable-line

  if (!mfaEnabled) {
    return (
      <PanelContent
        heading={''}
        image={{
          src: warningImg,
          alt: messages.warningText,
        }}
        text={messages.warningText}
        customButtons={[
          <Button
            component='button'
            color='primary'
            variant='contained'
            onClick={() => history.replace('advanced')}
          >
            {messages.setup2fa}
          </Button>,
        ]}
      />
    );
  }

  const onFilterApply = (filters: Partial<FilterProps>) => {
    const params = qs.stringify(filters);
    history.push(`${location.pathname}?${params}`);
  };

  const filters = qs.parse(location.search) as Partial<FilterProps>;

  const filteredData = members.results.filter((member: ILimitedAccessMember) =>
    !!filters.teamAccountNumber && !!filters.teamName
      ? member?.team_external?.account_number?.toString() === filters.teamAccountNumber &&
        member?.team_external?.name?.toString() === filters.teamName
      : member?.team_external?.account_number?.toString() === filters.teamAccountNumber ||
        member?.team_external?.name?.toString() === filters.teamName
  );

  const data = isEmpty(filters)
    ? members
    : ({ results: filteredData } as unknown as LimitedAccessMembersPage);

  return (
    <AppLoader
      loadingActions={[ApiKeysActionName.GET_API_KEYS]}
      errorActions={[ApiKeysActionName.GET_API_KEYS]}
      onReload={() => _getApiKeys(false)}
    >
      {!!data?.results && (
        <Can I='manage' a='Team'>
          <div>
            <ActionPopover
              containerStyling={{ display: 'flex', justifyContent: 'flex-end', margin: '8px 18px' }}
              onToggle={() => setFilterToggle((prevState) => !prevState)}
              onClose={() => setFilterToggle(false)}
              isOpen={isFilterToggle}
              name={'Filter'}
            >
              <TeamLimitedAccessFilter
                onFilterClose={() => setFilterToggle(false)}
                filters={filters}
                handleApply={onFilterApply}
              />
            </ActionPopover>
          </div>
          <TeamLimitedAccessTable
            teamAccountNumber={teamAccountNumber}
            teamMembers={data}
            archiveApiKey={_archiveApiKey}
          />{' '}
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              padding: '2rem 5rem',
            }}
          >
            <FabButton
              icon='plus'
              onClick={openAddLimitedAccessModal}
              testId='openAddLimitedAccessModal'
            />
          </div>
        </Can>
      )}
    </AppLoader>
  );
};
export default TeamLimitedAccessTab;
