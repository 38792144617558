import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol preserveAspectRatio='none' id='icon-arrow-right' viewBox='0 0 32 32'>
      <path
        width='100%'
        height='100%'
        d='M9.10466 0.357964L16.6526 8.1358C17.1158 8.61308 17.1158 9.38692 16.6526 9.8642L9.10466 17.642C8.64148 18.1193 7.89052 18.1193 7.42734 17.642C6.96416 17.1647 6.96416 16.3909 7.42734 15.9136L12.9506 10.2222L8.93651e-07 10.2222L6.7996e-07 7.77783L12.9506 7.77783L7.42734 2.08637C6.96416 1.60909 6.96416 0.835251 7.42734 0.357964C7.89052 -0.119322 8.64148 -0.119322 9.10466 0.357964Z'
      />
    </symbol>
    <Global
      styles={css`
        .icon-arrow-right {
          width: 2.2em;
          height: 2.2em;
        }
      `}
    />
  </Fragment>
);
