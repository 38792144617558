import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage } from 'react-intl';
import { AnalyticsEventType, useAnalytics } from 'views/components/providers/AnalyticsProvider';
import styled from '@emotion/styled';

import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { parseScanPageURL } from 'views/url';

interface IScanResultsButtonProps {
  hash: string;
  analyticsEvent?: AnalyticsEventType['options'];
}

const Button = styled.a`
  color: #ffffff;
  border-radius: 5rem;
  line-height: 2rem;
  padding: 0.2rem 3rem;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  font-size: 1.3rem;
`;

const ScanResultsButton = ({ hash, analyticsEvent }: IScanResultsButtonProps) => {
  const { event: gaEvent } = useAnalytics();
  const { classes } = useStyles();
  const scanURL = parseScanPageURL(hash, { type: 'file' });

  return (
    <Button
      href={scanURL}
      onClick={(e) => {
        if (!!analyticsEvent) {
          gaEvent(analyticsEvent);
        }
        window.open(scanURL, '_blank');
        e.preventDefault();
        e.stopPropagation();
      }}
      css={classes.button}>
      <FormattedMessage
        id='scanPage.malwaretable.viewResultsBtn'
        defaultMessage='View scan results'
      />
    </Button>
  );
};

const useStyles = makeStyles({
  base: {
    button: {
      backgroundColor: styles.color.purple,
    },
  },
  light: {
    button: {
      backgroundColor: styles.color.purple,
    },
  },
  dark: {
    button: {
      backgroundColor: styles.color.lightBlue,
    },
  },
});
export default ScanResultsButton;
