const PivotingWhiteList = {
  Summary: {
    Summary: ['Family Name', 'Polyscore'],
  },
  Detections: {
    Details: ['Detection-name', 'R/G'],
  },
  'File Details': {
    'Artifact Attributes': ['Last Scanned', 'MIME Type', 'Extended Type'],
    exiftool: [],
    pefile: [
      'File size',
      'Compile Date',
      'Imported Functions',
      'Is DLL',
      'Is Driver',
      'Is EXE',
      'Import Libraries',
      'PE Paths',
      'PE String',
      'PDB Path',
      'PDB GUIDs',
      'Mutex',
      'Section hashes',
      'Resource hashes',
    ],
    lief: ['Import Libraries', 'External Functions', 'Imported Functions'],
    hash: ['ssdeep', 'TLSH', 'IMPASH'],
    certificate: ['Certificate hash', 'Certificate fingerprint', 'Certificate serial'],
  },
  Network: {
    IOCs: ['IPs', 'URLs', 'Domains'],
    C2: ['Config 2'],
    Network: ['IPs', 'URLs', 'domains', 'JARM'],
  },
  Sandbox: {
    'Dropped Files': [
      'Name',
      'Path',
      'Size',
      'Type',
      'Data',
      'SHA256',
      'MD5',
      'SHA1',
      'SHA3_384',
      'SHA512',
      'SSDEEP',
      'TLSH',
      'TLSH Quartiles',
    ],
    'OS Autostarts': [
      'Autostart method',
      'Install path',
      'Registry Key Name',
      'Name',
      'Watchdog Path',
    ],
    'OS Autorun': ['Autorun Control', 'Autorun Name'],
    MITRE: ['TTP'],
  },
  'File Data': {},
  'Extracted Config': {
    'Encryption Keys': { keys: ['key', 'kind', 'value'] },
  },
  Analysis: {
    MITRE: ['TTP'],
  },
} as { [key: string]: { [key: string]: string[] | { [key: string]: string[] } } };

export const whiteListIds = [
  // Extracted Config -> Malware Config
  'cape_sandbox_v2.extracted.config.campaign',
  'cape_sandbox_v2.extracted.config.extracted_pe',
  'triage_sandbox_v0.extracted.credentials.username',
  'triage_sandbox_v0.extracted.config.campaign',
  'triage_sandbox_v0.extracted.config.extracted_pe',
  'triage_sandbox_v0.extracted.config.attr.autorun.control',
  'triage_sandbox_v0.extracted.config.attr.autorun.name',
  'triage_sandbox_v0.extracted.config.attr.autostart_method',
  'triage_sandbox_v0.extracted.config.attr.installpath',
  'triage_sandbox_v0.extracted.config.attr.access_type',

  // Extracted Config -> Process
  'cape_sandbox_v2.cape_filter.pid',

  // Extracted Config -> Encryption Keys
  'triage_sandbox_v0.extracted.config.keys.key',
  'triage_sandbox_v0.extracted.config.keys.kind',
  'triage_sandbox_v0.extracted.config.keys.value',
  'cape_sandbox_v2.extracted.config.keys.value',
  'cape_sandbox_v2.extracted.config.keys.kind',
  'cape_sandbox_v2.extracted.config.keys.key',

  // Network -> IOCs
  'cape_sandbox_v2.network.hosts.ip',
  'cape_sandbox_v2.network.domains.ip',
  'cape_sandbox_v2.network.dead_hosts',
  'triage_sandbox_v0.targets.iocs.ips',
  'triage_sandbox_v0.targets.iocs.domains',
  'triage_sandbox_v0.targets.iocs.urls',

  // Network -> Domains
  'cape_sandbox_v2.network.domains.domain',
  'cape_sandbox_v2.network.dns.request',
  'triage_sandbox_v0.extracted.credentials.host',
  'triage_sandbox_v0.extracted.config.attr.backup_connection_host',
  'triage_sandbox_v0.extracted.config.attr.backup_dns_server',
  'triage_sandbox_v0.extracted.config.attr.dns_servers',

  // Network -> C2
  'cape_sandbox_v2.extracted.config.c2',
  'triage_sandbox_v0.extracted.config.c2',
  'triage_sandbox_v0.extracted_c2_ips',

  // Suricata TLS
  'cape_sandbox_v2.suricata.tls.dstip',

  // SMTP

  'cape_sandbox_v2.network.smtp.dst',
  'cape_sandbox_v2.network.smtp.raw',

  // Network -> JARM
  'jarm.hosts.host',
  'jarm.hosts.jarm',
  'jarm.hosts.port',

  // Network -> Suricata Alerts
  'cape_sandbox_v2.suricata_alerts.category',
  'cape_sandbox_v2.suricata_alerts.dstip',
  'cape_sandbox_v2.suricata_alerts.dstport',
  'cape_sandbox_v2.suricata_alerts.protocol',
  'cape_sandbox_v2.suricata_alerts.signature',

  // Dropped Files
  'cape_sandbox_v2.dropped.name',
  'cape_sandbox_v2.dropped.size',
  'cape_sandbox_v2.dropped.type',
  'cape_sandbox_v2.dropped.sha256',
  'cape_sandbox_v2.dropped.md5',
  'cape_sandbox_v2.dropped.sha3_384',
  'cape_sandbox_v2.dropped.ssdeep',
  'cape_sandbox_v2.dropped.tlsh',

  'triage_sandbox_v0.extracted.config.attr.auth_value',

  // Analysis -> MITRE
  'cape_sandbox_v2.ttp.id',
  'triage_sandbox_v0.ttp.id',

  // Analysis -> Registry Keys
  'triage_sandbox_v0.extracted.config.attr.regKey',

  // Analysis -> Yara
  'cape_sandbox_v2.target.file.yara.name',
  'triage_sandbox_v0.targets.signatures.indicators.yara_rule',

  // Analysis -> OS Autorun
  'cape_sandbox_v2.extracted.config.attr.autorun.name',
  'cape_sandbox_v2.extracted.config.attr.autorun.control',

  // Analysis -> Signatures

  'triage_sandbox_v0.targets.signatures.name',
  'triage_sandbox_v0.targets.signatures.label',

  // Analysis -> Registry Keys
  'cape_sandbox_v2.behavior.enhanced.regKey',
  'cape_sandbox_v2.behavior.enhanced.data.content',
  'cape_sandbox_v2.behavior.enhanced.data.moduleaddress',
  'cape_sandbox_v2.behavior.enhanced.data.pathtofile',
  'cape_sandbox_v2.behavior.enhanced.event',

  // Analysis -> Tags
  'triage_sandbox_v0.analysis.tags',
] as const;

export type WhiteListIds = (typeof whiteListIds)[number];

export const whiteListLabels: Record<WhiteListIds, string> = {
  // Extracted Config -> Malware Config
  'cape_sandbox_v2.extracted.config.campaign': 'Malware Config | Campaign',
  'cape_sandbox_v2.extracted.config.extracted_pe': 'Malware Config | Config PE',
  'triage_sandbox_v0.extracted.credentials.username': 'Malware Config | Username',
  'triage_sandbox_v0.extracted.config.campaign': 'Malware Config | Campaign',
  'triage_sandbox_v0.extracted.config.extracted_pe': 'Malware Config | Config PE',
  'triage_sandbox_v0.extracted.config.attr.autorun.control': 'Malware Config | Autorun Control',
  'triage_sandbox_v0.extracted.config.attr.autorun.name': 'Malware Config | Autorun Name',
  'triage_sandbox_v0.extracted.config.attr.autostart_method': 'Malware Config | Autostart Method',
  'triage_sandbox_v0.extracted.config.attr.installpath': 'Malware Config | Install Path',
  'triage_sandbox_v0.extracted.config.attr.access_type': 'Malware Config | Access Type',

  // Extracted Config -> Process
  'cape_sandbox_v2.cape_filter.pid': 'Process | PID',

  // Extracted Config -> Encryption Keys
  'triage_sandbox_v0.extracted.config.keys.key': 'Triage | Encryption Keys | Key',
  'triage_sandbox_v0.extracted.config.keys.kind': 'Triage | Encryption Keys | Kind',
  'triage_sandbox_v0.extracted.config.keys.value': 'Triage | Encryption Keys | Value',
  'cape_sandbox_v2.extracted.config.keys.value': 'Cape | Encryption Keys | Value',
  'cape_sandbox_v2.extracted.config.keys.kind': 'Cape | Encryption Keys | Kind',
  'cape_sandbox_v2.extracted.config.keys.key': 'Cape | Encryption Keys | Key',

  // Dropped Files
  'triage_sandbox_v0.extracted.config.attr.auth_value': 'Dropped Files | Auth Value',

  // Network -> IOCs
  'cape_sandbox_v2.network.domains.domain': 'Network | Domain',
  'cape_sandbox_v2.network.hosts.ip': 'Network | IP',
  'cape_sandbox_v2.network.domains.ip': 'Network | IP',
  'cape_sandbox_v2.network.dead_hosts': 'Network | Dead Hosts',
  'cape_sandbox_v2.network.dns.request': 'Network | Request',
  'triage_sandbox_v0.targets.iocs.ips': 'Network Details | IPs',
  'triage_sandbox_v0.targets.iocs.domains': 'Network Details | Domains',
  'triage_sandbox_v0.targets.iocs.urls': 'Network Details | URLs',

  // Network -> Domains
  'cape_sandbox_v2.extracted.config.c2': 'Cape | Domains | C2',
  'triage_sandbox_v0.extracted.config.c2': 'Triage | Domains | C2',
  'triage_sandbox_v0.extracted_c2_ips': 'Triage | Domains | C2',
  'triage_sandbox_v0.extracted.credentials.host': 'Domains | Host',
  'triage_sandbox_v0.extracted.config.attr.backup_connection_host':
    'Domains | Backup Connection Host',
  'triage_sandbox_v0.extracted.config.attr.backup_dns_server': 'Domains | Backup DNS Server',
  'triage_sandbox_v0.extracted.config.attr.dns_servers': 'Domains | DNS Servers',

  // Network -> IPs

  // Suricata TLS
  'cape_sandbox_v2.suricata.tls.dstip': 'IPs | Suricata TLS',

  // SMTP

  'cape_sandbox_v2.network.smtp.dst': 'SMTP | Destination',
  'cape_sandbox_v2.network.smtp.raw': 'SMTP | Raw',

  // Network -> JARM
  'jarm.hosts.host': 'JARM | Host',
  'jarm.hosts.jarm': 'JARM | JARM',
  'jarm.hosts.port': 'JARM | Port',

  // Network -> Suricata Alerts
  'cape_sandbox_v2.suricata_alerts.category': 'Suricata Alerts | Category',
  'cape_sandbox_v2.suricata_alerts.dstip': 'Suricata Alerts | Destination IP',
  'cape_sandbox_v2.suricata_alerts.dstport': 'Suricata Alerts | Destination Port',
  'cape_sandbox_v2.suricata_alerts.protocol': 'Suricata Alerts | Protocol',
  'cape_sandbox_v2.suricata_alerts.signature': 'Suricata Alerts | Signature',

  // Dropped Files
  'cape_sandbox_v2.dropped.name': 'Dropped Files | Name',
  'cape_sandbox_v2.dropped.size': 'Dropped Files | Size',
  'cape_sandbox_v2.dropped.type': 'Dropped Files | Type',
  'cape_sandbox_v2.dropped.sha256': 'Dropped Files | SHA256',
  'cape_sandbox_v2.dropped.md5': 'Dropped Files | MD5',
  'cape_sandbox_v2.dropped.sha3_384': 'Dropped Files | SHA3_384',
  'cape_sandbox_v2.dropped.ssdeep': 'Dropped Files | SSDEEP',
  'cape_sandbox_v2.dropped.tlsh': 'Dropped Files | TLSH',

  // Analysis -> MITRE
  'cape_sandbox_v2.ttp.id': 'Cape | MITRE | ID',
  'triage_sandbox_v0.ttp.id': 'Triage | MITRE | ID',

  // Analysis -> Registry Keys
  'triage_sandbox_v0.extracted.config.attr.regKey': 'Registry Keys | Key',

  // Analysis -> Yara
  'cape_sandbox_v2.target.file.yara.name': 'Yara | Name',
  'triage_sandbox_v0.targets.signatures.indicators.yara_rule': 'Yara | Rule',

  // Analysis -> OS Autorun
  'cape_sandbox_v2.extracted.config.attr.autorun.name': 'OS Autorun | Name',
  'cape_sandbox_v2.extracted.config.attr.autorun.control': 'OS Autorun | Control',

  // Analysis -> Signatures
  'triage_sandbox_v0.targets.signatures.name': 'Signatures | Name',
  'triage_sandbox_v0.targets.signatures.label': 'Signatures | Label',

  // Analysis -> Registry Keys
  'cape_sandbox_v2.behavior.enhanced.regKey': 'Registry Keys | RegKey',
  'cape_sandbox_v2.behavior.enhanced.data.content': 'Registry Keys | Content',
  'cape_sandbox_v2.behavior.enhanced.data.moduleaddress': 'Registry Keys | Module Address',
  'cape_sandbox_v2.behavior.enhanced.data.pathtofile': 'Registry Keys | Path to File',
  'cape_sandbox_v2.behavior.enhanced.event': 'Registry Keys | Event',

  // Analysis -> Tags
  'triage_sandbox_v0.analysis.tags': 'Tags',
};

export default PivotingWhiteList;
