import React from 'react';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { KeyboardArrowLeft, KeyboardArrowRight, FirstPage } from '@material-ui/icons';
import styled from '@emotion/styled';

interface IListPagination {
  isFirst: boolean;
  offset: number;
  total: number;
  isLast: boolean;
  hasMore?: boolean;
  rowsPerPage: number;
  onChangePage: (direction: EPageDirection) => void;
  onFirstPage?: () => void;
}

export enum EPageDirection {
  PREV,
  NEXT,
  FIRST,
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

const useTextStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

export const ListPagination = ({
  rowsPerPage,
  onChangePage,
  onFirstPage,
  isFirst,
  isLast,
  offset,
  total,
  hasMore,
}: IListPagination) => {
  const textStyles = useTextStyles();

  const init = offset + 1;
  const end = Math.min(offset + rowsPerPage, total);

  return (
    <Wrapper>
      <Typography classes={textStyles} variant='body2'>
        {init}-{end} of {total}
        {hasMore && '+'}
      </Typography>
      <IconButton
        disabled={isFirst}
        onClick={
          onFirstPage
            ? () => !isFirst && onFirstPage()
            : () => !isFirst && onChangePage(EPageDirection.FIRST)
        }
      >
        <FirstPage />
      </IconButton>
      <IconButton disabled={isFirst} onClick={() => !isFirst && onChangePage(EPageDirection.PREV)}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton disabled={isLast} onClick={() => !isLast && onChangePage(EPageDirection.NEXT)}>
        <KeyboardArrowRight />
      </IconButton>
    </Wrapper>
  );
};
