import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Popover from 'views/components/Popover';
import Radio from '@material-ui/core/Radio';
import { useUser } from 'views/components/providers/UserProvider';
import { storeAccountKey } from 'utils/storage/storage';
import useDebouncedValue from 'hooks/useDebouncedValue';

enum DateOptions {
  TODAY = 'TODAY',
  DAYS = 'DAYS',
}
type DateType = keyof typeof DateOptions;
type OptionalValue = number;
interface IDataSelector {
  label: string;
  storageKey?: string;
  defaultOption?: DateType | string;
  defaultValue?: OptionalValue;
  maxDays?: OptionalValue | DateType;
  onChange?: (value: OptionalValue, option: DateType) => void;
}

export default ({
  label,
  storageKey = `${label}_date`,
  defaultOption,
  defaultValue = 30,
  maxDays,
  onChange,
}: IDataSelector) => {
  const { classes } = useStyles();
  const [option, setOption] = useState(defaultOption || DateOptions.TODAY);
  const [daysAgo, setDaysAgo] = useState<OptionalValue>(defaultValue);
  const [userChanges, setUserChanges] = useState(false);
  const debouncedDaysAgo = useDebouncedValue(daysAgo, 500);
  const { accountNumber } = useUser();

  useEffect(() => {
    if (typeof maxDays === 'number') {
      if (Number(daysAgo) > Number(maxDays)) {
        setDaysAgo(maxDays);
      }
    } else if (maxDays === 'TODAY') {
      setOption(DateOptions.TODAY);
      onChange && onChange(0, DateOptions.TODAY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDays, daysAgo]);

  useEffect(() => {
    storeAccountKey(accountNumber, storageKey, { option, value: daysAgo });
  }, [accountNumber, storageKey, option, daysAgo]);

  useEffect(() => {
    if (!!option && userChanges && debouncedDaysAgo !== defaultValue) {
      onChange && onChange(debouncedDaysAgo, option as DateType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDaysAgo, userChanges, defaultValue, option]);

  return (
    <Popover
      button={
        <div css={classes.button}>
          {label}: {option === DateOptions.TODAY ? 'Today' : `${daysAgo || 0} Days Ago`}
        </div>
      }
      content={
        <div css={classes.popover}>
          <div css={classes.radio}>
            <Radio
              disabled={false}
              color='primary'
              checked={option === DateOptions.TODAY}
              onClick={() => {
                setOption(DateOptions.TODAY);
                onChange && onChange(0, DateOptions.TODAY);
              }}
              id={DateOptions.TODAY}
            />
            <label htmlFor={DateOptions.TODAY}>Today</label>
          </div>
          <div css={classes.radio}>
            <Radio
              disabled={maxDays === 'TODAY'}
              color='primary'
              checked={option === DateOptions.DAYS}
              onClick={() => {
                setOption(DateOptions.DAYS);
                onChange && onChange(daysAgo, DateOptions.DAYS);
              }}
              id={DateOptions.DAYS}
            />
            <label htmlFor={DateOptions.DAYS}>Days</label>
            <input
              type='number'
              min={1}
              max={maxDays || Infinity}
              value={daysAgo}
              disabled={option === DateOptions.TODAY || maxDays === 'TODAY'}
              css={classes.inputNumber}
              onKeyDown={(e) => {
                [...Array.from(Array(10).keys()).map((x) => x.toString()), 'Backspace'].includes(
                  e.key
                ) || e.preventDefault();
              }}
              onChange={(e) => {
                setDaysAgo(parseInt(e.target.value, 10) || 0);
                setUserChanges(true);
              }}
              onBlur={(e) => {
                onChange && onChange(parseInt(e.target.value, 10) || 0, option as DateType);
              }}
            />
          </div>
        </div>
      }
    />
  );
};

const useStyles = makeStyles({
  base: {
    button: {
      border: '1px solid #979797',
      borderRadius: '0.5rem',
      padding: '0.3rem 1rem',
      marginRight: '1rem',
      fontWeight: 600,
      cursor: 'pointer',
    },
    radio: {
      display: 'flex',
      alignItems: 'center',
    },
    popover: {
      padding: '1rem',
      width: '20rem',
      border: '1px solid #979797',
      borderRadius: '0.5rem',
      marginTop: '1rem',
    },
    inputNumber: {
      borderBottom: '1px solid black',
      width: '5rem',
      marginLeft: '2rem',
      padding: '0.3rem',
    },
  },
  light: {
    popover: {
      backgroundColor: styles.color.white,
    },
    inputNumber: {
      borderBottom: '1px solid black',
    },
  },
  dark: {
    popover: {
      backgroundColor: styles.color.darkXBlack,
    },
    inputNumber: {
      color: '#979797',
      borderBottom: '1px solid #979797',
    },
  },
});
