import React, { FC } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import VisibilitySensor from 'react-visibility-sensor';
import styles from 'views/styles';

interface IHowItWorksSection {
  onVisibilityChange: (isVisible: boolean) => void;
}

const HowItWorksSection: FC<IHowItWorksSection> = ({ onVisibilityChange, children }) => {
  const { classes } = useStyles();

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ bottom: 200, top: 200 }}
      onChange={onVisibilityChange}>
      <div css={classes.root}>{children}</div>
    </VisibilitySensor>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      marginTop: '3rem',
      padding: styles.spacing.lg,
      borderWidth: 2,
      borderStyle: 'solid',
      borderRadius: styles.border.radius,
      position: 'relative',
    },
  },
  light: {
    root: {
      borderColor: styles.color.purple,
      backgroundColor: styles.color.white,
    },
  },
  dark: {
    root: {
      borderColor: styles.color.lightBlue,
      backgroundColor: styles.color.darkOffBlack,
    },
  },
});

export default HowItWorksSection;
