import { useState, useEffect } from 'react';
import { ProviderList } from 'models/Sandboxing';
import api from 'services/api';

export default function useGetVMs() {
  const [isLoading, setLoading] = useState(false);
  const [providers, setProvidersVMs] = useState<ProviderList>([]);

  useEffect(() => {
    async function onload() {
      try {
        setLoading(true);
        const response = await api.getProviderVMs();
        const data = response.data.result;
        setProvidersVMs(
          Object.keys(data).map((key) => {
            return {
              ...data[key],
              vms: Object.keys(data[key].vms).map((vm) => {
                return data[key].vms[vm];
              }),
            };
          })
        );
      } catch (error) {
        console.error(error);
        setProvidersVMs([]);
      }
      setLoading(false);
    }

    if (providers.length === 0) {
      onload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { providers, isLoading };
}
