import { AsyncAction } from 'state/types/actions';
import { SystemStatusRes } from 'services/api/schema/system';

export enum SystemActionName {
  GET_SYSTEM_STATUS = 'GET_SYSTEM_STATUS',
}

export interface GetSystemStatusAction
  extends AsyncAction<SystemActionName.GET_SYSTEM_STATUS, SystemStatusRes> {}

export type SystemAction = GetSystemStatusAction;
