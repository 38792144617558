import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import MicroenginesPage from './MicroenginesPage';

const messages = defineMessages({
  title: {
    id: 'microenginesPage.title',
    defaultMessage: 'Engines',
  },
});

const MicroenginesRoutes = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <Switch>
        <Route path='/engines' component={MicroenginesPage} />
      </Switch>
    </Fragment>
  );
};

export default MicroenginesRoutes;
