import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { defineMessages, useIntl } from 'react-intl';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { Ruleset, RulesetValues } from 'models/Ruleset';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import TableCell from '@material-ui/core/TableCell';
import HoverableTableRow from 'views/components/table/HoverableTableRow';
import { btnMessages } from 'views/components/Button';
import ActionBtn from 'views/components/table/ActionBtn';
import { formatDistanceToNow, parseUTCDate } from 'utils/date/date';

interface IRulesetRow {
  rowData: Ruleset;
  cancelLiveHunt: (id: string) => void;
  runLiveHunt: (id: string, name: string) => void;
  runHistoricalHunt: (id: string, name: string) => void;
  updateRuleset: (id: string, values: RulesetValues) => void;
  duplicateRuleset: (values: RulesetValues) => void;
  deleteRuleset: (id: string) => void;
  showRulesetInfo: (title: string, liveHuntId: string | null, rulesetId: string) => void;
  getRuleset: (rulesetId: string) => Promise<Ruleset>;
}

const messages = defineMessages({
  toogleTooltip: {
    id: 'rulesetTable.row.toogleTooltip',
    defaultMessage: 'Enable/Disable a Live Hunt using this Ruleset',
  },
  timeAgo: {
    id: 'rulesetTable.row.timeAgo',
    defaultMessage: 'ago',
  },
  infoModalTitle: {
    id: 'rulesetTable.row.infoModalTitle',
    defaultMessage: 'Ruleset Information',
  },
  viewEditRuleset: {
    id: 'rulesetTable.row.viewEditRuleset',
    defaultMessage: 'View/Edit Ruleset',
  },
  duplicateRuleset: {
    id: 'rulesetTable.row.duplicateRuleset',
    defaultMessage: 'Copy Ruleset',
  },
  infoRuleset: {
    id: 'rulesetTable.row.infoRuleset',
    defaultMessage: 'View Ruleset Info',
  },
  deleteRuleset: {
    id: 'rulesetTable.row.deleteRuleset',
    defaultMessage: 'Delete Ruleset',
  },
});

const RulesetRow = ({
  rowData,
  runLiveHunt,
  cancelLiveHunt,
  runHistoricalHunt,
  updateRuleset,
  duplicateRuleset,
  deleteRuleset,
  showRulesetInfo,
  getRuleset,
}: IRulesetRow) => {
  const { formatMessage } = useIntl();

  const { classes } = useStyles();

  return (
    <HoverableTableRow
      dataCy='huntingResultRow'
      renderCells={() => (
        <Fragment>
          <TableCell>
            <Tooltip title={formatMessage(messages.toogleTooltip)} placement='top'>
              <span>
                <Switch
                  color='primary'
                  checked={!!rowData.livescan_id}
                  onChange={
                    !!rowData.livescan_id
                      ? () => cancelLiveHunt(rowData.id)
                      : () => runLiveHunt(rowData.id, rowData.name)
                  }
                />{' '}
                {rowData.livescan_id && rowData.livescan_created && (
                  <span css={classes.date}>
                    {formatDistanceToNow(parseUTCDate(rowData.livescan_created))}{' '}
                    {formatMessage(messages.timeAgo)}
                  </span>
                )}
              </span>
            </Tooltip>
          </TableCell>
          <TableCell>
            <div>
              <span css={classes.bold}>{rowData.name}</span>
              <span css={classes.date}>
                {formatDistanceToNow(parseUTCDate(rowData.created))}{' '}
                {formatMessage(messages.timeAgo)}
              </span>
            </div>
          </TableCell>
          <TableCell css={classes.actionsCell} align='left'>
            <Fragment>
              <Tooltip title={formatMessage(btnMessages.histHunt)} placement='top'>
                <span>
                  <ActionBtn
                    name='run'
                    aria-label={formatMessage(btnMessages.histHunt)}
                    onClick={() => runHistoricalHunt(rowData.id, rowData.name)}
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(messages.viewEditRuleset)} placement='top'>
                <span>
                  <ActionBtn
                    name='edit'
                    disabled={!!rowData.livescan_id}
                    aria-label={formatMessage(messages.viewEditRuleset)}
                    onClick={async () => {
                      const rulesetData = await getRuleset(rowData.id);
                      updateRuleset(rulesetData.id, {
                        name: rulesetData.name,
                        yara: rulesetData.yara,
                        description: rulesetData.description,
                      });
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(messages.duplicateRuleset)} placement='top'>
                <span>
                  <ActionBtn
                    name='copy'
                    aria-label={formatMessage(messages.duplicateRuleset)}
                    onClick={async () => {
                      const rulesetData = await getRuleset(rowData.id);
                      duplicateRuleset({
                        name: rulesetData.name,
                        yara: rulesetData.yara,
                        description: rulesetData.description,
                      });
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(messages.infoRuleset)} placement='top'>
                <span>
                  <ActionBtn
                    name='info'
                    aria-label={formatMessage(messages.infoRuleset)}
                    onClick={() =>
                      showRulesetInfo(
                        formatMessage(messages.infoModalTitle),
                        rowData.livescan_id,
                        rowData.id
                      )
                    }
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(messages.deleteRuleset)} placement='top'>
                <span>
                  <ActionBtn
                    name='delete'
                    disabled={!!rowData.livescan_id}
                    aria-label={formatMessage(messages.deleteRuleset)}
                    onClick={() => deleteRuleset(rowData.id)}
                    data-cy='deleteRulesetBtn'
                  />
                </span>
              </Tooltip>
            </Fragment>
          </TableCell>
        </Fragment>
      )}
    />
  );
};

const useStyles = makeStyles({
  base: {
    bold: {
      fontWeight: 'bold',
    },
    date: {
      color: styles.color.xLightGrey,
      marginLeft: '2rem',
      fontSize: '1.4rem',
    },
    actionsCell: {
      width: '16rem',
    },
  },
  light: {},
  dark: {},
});

export default RulesetRow;
