import React, { useEffect, useState } from 'react';
import BaseFormTagsInput from 'views/components/form/FormTagsInput';
import { IFormTagsInputProps } from 'views/components/form/FormTagsInput/FormTagsInput';
import api from 'services/api';

const FormTagsInput = (props: IFormTagsInputProps) => {
  const [options, setOptions] = useState<string[]>([]);
  const loading = options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await api.getMicroengineTags();
      if (active) {
        setOptions(response.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return <BaseFormTagsInput {...props} loading={loading} options={options} />;
};

export default FormTagsInput;
