import React, { useRef, useEffect } from 'react';
import { FieldProps, Field } from 'formik';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { InputProps } from '@material-ui/core/Input';

interface OwnProps {
  errors?: boolean;
  errorOnTouched?: boolean;
  errorMessage?: string | null;
  optionalText?: string;
  InputProps?: InputProps;
  validate?: (value: any) => string | Promise<void> | undefined;
}

type Props = OwnProps & TextFieldProps;

const FormInput = (props: Props) => {
  const inputRef = useRef<null | HTMLDivElement>();
  const {
    className,
    name,
    label,
    errors,
    errorOnTouched,
    errorMessage,
    optionalText,
    validate,
    ...rest
  } = props;

  useEffect(() => {
    if (rest.error) {
      inputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [rest.error]);

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        const error = errorMessage || meta.error;
        let isError = !!errorMessage || !!error;
        if (errorOnTouched) isError = !!meta.touched && isError;

        return (
          <div className={className}>
            <TextField
              inputRef={inputRef}
              fullWidth={true}
              label={`${label || ''} ${optionalText || ''}`}
              error={errors !== false && isError}
              helperText={errors !== false && isError && error}
              {...field}
              {...rest}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default FormInput;
