import polyswarm from './sites/polyswarm/config';
import accentureUS from './sites/accenture/config';
import { getTenant } from './utils';

const tenants = {
  polyswarm,
  accenture: accentureUS,
};

export const currentTenant = getTenant() as keyof typeof tenants;
export const isPolyswarmTenant = currentTenant === 'polyswarm';

export default tenants[currentTenant] || tenants.polyswarm;
