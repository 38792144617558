import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { Field, FieldProps } from 'formik';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import styles from 'views/styles';

interface FormRadioProps {
  className?: string;
  name?: string;
  label?: string;
  margin?: 'none' | 'normal' | 'dense';
  errorOnTouched?: boolean;
  options: {
    value: string;
    label: string;
  }[];
  disabled?: boolean;
}

const FormRadio = ({
  className,
  name,
  label,
  options,
  margin,
  errorOnTouched,
  disabled,
}: FormRadioProps) => (
  <div className={className}>
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const error = meta.error;
        let isError = !!error;
        if (errorOnTouched) isError = !!meta.touched && isError;

        return (
          <FormControl disabled={disabled} error={isError} margin={margin} fullWidth>
            {label && <FormLabel css={style.label}>{label}</FormLabel>}
            <RadioGroup
              aria-label={name}
              css={style.group}
              value={field.value}
              onChange={(e) => form.setFieldValue(field.name, (e.target as any).value)}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  css={style.option}
                  value={option.value}
                  control={<Radio color='primary' style={{ marginRight: 5 }} />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {isError && <FormHelperText error={isError}>{error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  </div>
);

const style = {
  label: css`
    font-size: ${styles.font.size.p1} !important;
    font-weight: ${styles.font.weight.medium};
    margin-bottom: ${styles.spacing.xxs};
  `,
  group: css`
    flex-direction: row !important;
    margin-left: 1.2rem;
  `,
  option: css`
    padding-right: ${styles.spacing.xs};
    &:last-child {
      padding-right: 0;
    }
  `,
};

export default FormRadio;
