import React from 'react';
import styled from '@emotion/styled';
import Chip from '@material-ui/core/Chip';
import TagsTooltip from './TagsTooltip';

export interface ITagsListProps {
  className?: string;
  tagStyle?: any;
  tags: string[];
  limit: number;
  onClick?: (tag: string) => void;
}

const Tag = styled(Chip)<{ pointer: boolean }>`
  margin-right: 5px !important;
  font-size: 1.2rem !important;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')} !important;
`;

export const TagsList = ({ className, tags, tagStyle, limit, onClick }: ITagsListProps) => {
  const hasMore = (tags || []).length > limit;

  return (
    <div className={className}>
      {(tags || []).slice(0, limit).map((tag: string) => (
        <Tag
          key={tag}
          label={tag}
          pointer={onClick !== undefined}
          onClick={() => onClick && onClick(tag)}
          style={tagStyle}
        />
      ))}
      {hasMore && (
        <TagsTooltip label={`+${tags.length - limit}`} tags={tags.slice(limit)} onClick={onClick} />
      )}
    </div>
  );
};
