import React, { FC } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage } from 'react-intl';
import { AssertionMetadata } from 'models/Submission';
import { excerpt } from 'utils/text';
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { FormattedNCT } from 'views/components/utils';
import Icon from 'views/components/Icon';
import ItemLoader from 'views/components/Loader/ItemLoader';

interface IScanItem {
  className?: string;
  windowClosed: boolean;
  failed: boolean;
  author: string;
  authorName: string | null;
  bid?: string;
  metadata: AssertionMetadata | null;
  verdict: boolean | null;
  onClick: (authorName: string | null, author: string, metadata: AssertionMetadata) => void;
}

const TRUNCATE_VAL = 20;

const ScanItem = (props: IScanItem) => {
  const { windowClosed, failed, verdict } = props;
  const isFailed = failed || (windowClosed && verdict === null);
  const isScanning = !windowClosed;
  const isMalicious = windowClosed && verdict === true && !failed;
  const isBenign = windowClosed && verdict === false && !failed;

  if (isFailed) {
    return <FailedResult {...props} />;
  } else if (isScanning) {
    return <ScanningState {...props} />;
  } else if (isMalicious) {
    return <MaliciousResult {...props} />;
  } else if (isBenign) {
    return <BenignResult {...props} />;
  }
  return <FailedResult {...props} />;
};

const ResultItem: FC<IScanItem> = ({
  className,
  windowClosed,
  author,
  authorName,
  bid,
  metadata,
  onClick,
  children,
}) => {
  const { classes } = useStyles();
  const muiClasses = useMuiStyles();

  const disabled = !metadata || !metadata.scanner;

  const _onClick = () => {
    if (!disabled) {
      onClick(authorName, author, metadata!);
    }
  };

  return (
    <div className={className} css={classes.root} data-cy='assertion'>
      <span css={classes.textWrap}>
        <button
          css={classes.button}
          onClick={_onClick}
          data-cy='scannerDetailsBtn'
          disabled={disabled}>
          {authorName ? (
            <span css={classes.text}>{authorName}</span>
          ) : (
            <span css={[classes.text, classes.textMono]}>
              <Tooltip classes={{ tooltip: muiClasses.tooltip }} title={author} placement='bottom'>
                <span>{excerpt(author, 7)}</span>
              </Tooltip>
            </span>
          )}
        </button>
        {windowClosed && bid && (
          <span css={classes.bid}>
            <span css={classes.bidLabel}>
              <FormattedMessage id='scanItem.bid' defaultMessage='Bid:' />
            </span>{' '}
            <FormattedNCT nct={bid === '' || bid === 'None' ? '0' : bid} long />
          </span>
        )}
      </span>
      {children}
    </div>
  );
};

const MalwareFamily = ({ metadata }: IScanItem) => {
  const { classes } = useStyles();
  const malware_family = metadata && metadata.malware_family;
  return (
    <div css={classes.familyWrap} data-cy='malwareFamily'>
      {malware_family && malware_family.length > TRUNCATE_VAL ? (
        <Tooltip title={malware_family} placement='bottom'>
          <p css={classes.family}>{excerpt(malware_family, TRUNCATE_VAL)}</p>
        </Tooltip>
      ) : (
        <p css={classes.family}>{malware_family}</p>
      )}
    </div>
  );
};

const FailedResult = (props: IScanItem) => {
  const { classes } = useStyles();
  return (
    <ResultItem {...props}>
      <span css={classes.failIcon}>
        <Icon name='fail' />
      </span>
    </ResultItem>
  );
};

const ScanningState = (props: IScanItem) => {
  const { classes } = useStyles();
  return (
    <ResultItem {...props}>
      <span css={classes.scanningIcon}>
        <ItemLoader />
      </span>
    </ResultItem>
  );
};

const MaliciousResult = (props: IScanItem) => {
  const { classes } = useStyles();
  return (
    <ResultItem {...props}>
      <MalwareFamily {...props} />
      <span css={classes.maliciousIcon}>
        <Icon name='malicious' />
      </span>
    </ResultItem>
  );
};

const BenignResult = (props: IScanItem) => {
  const { classes } = useStyles();
  return (
    <ResultItem {...props}>
      <span css={classes.benignIcon}>
        <Icon name='benign' />
      </span>
    </ResultItem>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      flex: '1 0 calc(50% - 15px)',
      maxWidth: 'calc(50% - 15px)',
      fontSize: styles.font.size.p1,
    },
    button: {
      cursor: 'pointer',
      fontSize: styles.font.size.p,
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
      textAlign: 'left',
      '&[disabled]': {
        cursor: 'default',
      },
    },
    textWrap: {
      display: 'block',
      width: '8rem',
      marginRight: styles.spacing.xxs,
      padding: `calc(${styles.spacing.grid} / 2) 0`,
    },
    text: {
      display: 'block',
      fontWeight: styles.font.weight.medium,
    },
    bid: {
      display: 'block',
      marginTop: '0.6rem',
      fontsize: '1.3rem',
    },
    bidLabel: {
      color: styles.color.xLightGrey,
      fontWeight: styles.font.weight.medium,
    },
    familyWrap: {
      width: '18rem',
    },
    family: {
      color: styles.color.red,
    },
    textMono: {
      fontWeight: styles.font.weight.normal,
      fontFamily: styles.font.family.mono,
    },
    scanningIcon: {
      fontSize: '0.5rem',
    },
    maliciousIcon: {
      fontSize: '0.7rem',
      color: styles.color.red,
    },
    benignIcon: {
      fontSize: '0.7rem',
      color: styles.color.medGreen,
    },
    failIcon: {
      fontSize: '0.7rem',
      color: styles.color.xLightGrey,
    },
  },
  light: {
    button: {
      color: styles.color.black,
      '&:hover:not([disabled])': {
        color: styles.color.purple,
      },
    },
    bid: {
      color: styles.color.black,
    },
  },
  dark: {
    button: {
      color: styles.color.white,
      '&:hover:not([disabled])': {
        color: styles.color.lightBlue,
      },
    },
    bid: {
      color: styles.color.white,
    },
  },
});

const useMuiStyles = makeMuiStyles({
  tooltip: {
    fontFamily: styles.font.family.mono,
  },
});

export default ScanItem;
