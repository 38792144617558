import React from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'views/components/providers/AuthProvider';
import { useUser } from 'views/components/providers/UserProvider';
import { useSystem } from 'views/components/providers/SystemProvider';
import { changeTheme } from 'state/settings/actions';
import { openModal } from 'state/modal/actions';
import Header from '.';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';

interface IHeaderContainer {
  className?: string;
}

const HeaderContainer = ({ className }: IHeaderContainer) => {
  const dispatch = useDispatch();
  const { isMaintenanceMode } = useSystem();
  const user = useUser();
  const ctx = contextAccount(store);
  const auth = useAuth();

  return (
    <Header
      className={className}
      isMaintenanceMode={isMaintenanceMode}
      onSwitchTheme={(theme) => dispatch(changeTheme(theme))}
      onCreateTeam={() => dispatch(openModal('CREATE_TEAM'))}
      user={user}
      ctx={ctx}
      {...auth}
    />
  );
};

export default HeaderContainer;
