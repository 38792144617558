import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import base from './base';
import light from './light';
import dark from './dark';

interface MuiThemeOptions {
  base: ThemeOptions;
  light: ThemeOptions;
  dark: ThemeOptions;
}

const theme: MuiThemeOptions = {
  base,
  light,
  dark,
};

export default theme;
