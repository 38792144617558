import React, { useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import { useUser } from 'views/components/providers/UserProvider';
import { storeAccountKey } from 'utils/storage/storage';
import useIsPrivateContext from 'hooks/useIsPrivateContext';

interface ConfirmIntegrationCreationParams {
  onAccept: () => void;
  onCancel: () => void;
}

const messages = {
  title: 'Upload Confirmation',
  descriptionPublic: 'By uploading this file, you agree to sharing with 3rd parties.',
  descriptionPrivate:
    'By uploading this file, you agree to share it with engines/sandbox selected in your private community.',
  remember: 'Remember this setting for 30 days',
  agree: 'Agree',
};

const FileShareDataModal = () => {
  const [rememberPreference, setRememberPreference] = useState(false);
  const isPrivateContext = useIsPrivateContext();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { accountNumber } = useUser();
  const loading = false;
  const error = '';

  const { onAccept, onCancel } = useSelector(
    ({ modal }: { modal: ModalState<ConfirmIntegrationCreationParams> }) => modal.params
  );

  return (
    <ConfirmModal
      isLoading={loading}
      errorMessage={error}
      content={
        <div css={classes.container}>
          <h2 css={classes.title}>{messages.title}</h2>
          <p css={classes.description}>
            {isPrivateContext ? messages.descriptionPrivate : messages.descriptionPublic}
          </p>
          <div css={classes.remember}>
            <Checkbox
              color='primary'
              checked={rememberPreference}
              onChange={(_e) => {
                setRememberPreference(!rememberPreference);
              }}
            />
            <label htmlFor='remember'>{messages.remember}</label>
          </div>
        </div>
      }
      submitBtnText={messages.agree}
      onSubmit={() => {
        if (rememberPreference) {
          storeAccountKey(accountNumber, 'shareData', new Date().toISOString());
        }
        onAccept();
        dispatch(closeModal());
      }}
      onCancel={() => {
        onCancel();
        dispatch(closeModal());
      }}
    />
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontSize: '3rem',
      fontWeight: 500,
      marginBottom: '4rem',
    },
    description: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    remember: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '2rem',
      fontWeight: 300,
      fontSize: '1.3rem',
    },
  },
  light: {
    title: {
      color: styles.color.purple,
    },
  },
  dark: {
    title: {
      color: styles.color.blue,
    },
  },
});
export default FileShareDataModal;
