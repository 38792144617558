import React, { Fragment, useEffect, useState } from 'react'; // eslint-disable-line
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { uniq } from 'lodash';
import qs from 'query-string';
import { defineMessages, useIntl } from 'react-intl';
import useHasFeature from 'hooks/useHasFeature';
import useNotification from 'hooks/useNotification';
import {
  getHashSearchResults,
  clearHashSearchResults,
  viewScanResult,
  downloadArtifact,
} from 'state/submission/actions';
import { RootState } from 'state/root';
import { loadingSelector } from 'state/requests/selectors';
import { SubmissionActionName } from 'state/submission/types';
import { btnMessages } from 'views/components/Button';
import { isErrorOfType, errorMessages } from 'utils/error';
import { DEFAULT_LIMIT, extractUrlQueryParams } from 'utils/pagination';
import { useAuth } from 'views/components/providers/AuthProvider';
import { useUser } from 'views/components/providers/UserProvider';
import styles from 'views/styles';
import HashSearchField from './HashSearchField';
import HashSearchTable from './HashSearchTable';
import PanelContent from 'views/components/layout/PanelContent';
import AppLoader from 'views/components/request/AppLoader';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';
import { EPageDirection } from 'views/components/table/CustomTablePagination';

const messages = defineMessages({
  search: {
    id: 'hashSearch.search',
    defaultMessage: 'Search File Hash',
  },
  searchMulti: {
    id: 'hashSearch.searchMulti',
    defaultMessage: 'Search List of File Hashes',
  },
  text: {
    id: 'hashSearch.text',
    defaultMessage:
      'Enter a file hash in the search bar above. Toggle the "Search Multiple" button to enter a list of hashes. Click the magnifying glass icon to submit your hash search.',
  },
  heading: {
    id: 'hashSearch.emtpyHeading',
    defaultMessage: 'Hash Searching',
  },
  noneFoundHeading: {
    id: 'hashSearch.noneFound.heading',
    defaultMessage: 'No results found',
  },
  noneFoundText: {
    id: 'hashSearch.noneFound.text',
    defaultMessage: 'Maybe try another search?',
  },
  rateLimit: {
    id: 'hashSearch.rateLimit.text',
    defaultMessage: 'Request Limit Reached',
  },
});

type PageQuery = { page: number; limit?: number };

const HashSearchTab = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [hashes, setHashes] = useState<string[]>([]);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const { hasPermission: downloadPermission, remainingUses: downloadRemainingUses } =
    useHasFeature('download');
  const notification = useNotification();

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { isAuthenticated, login } = useAuth();
  const { context } = useUser();
  const numPages = Math.ceil(hashes.length / limit);

  const { requests } = useSelector((state: RootState) => state);
  const { hashSearchResults } = useSelector((state: RootState) => state.submission);

  const isLoading = loadingSelector(requests, [SubmissionActionName.GET_HASH_SEARCH_RESULTS]);

  const _viewScanResult = (hash: string) => dispatch(viewScanResult(hash, true));
  const _downloadArtifact = (hash: string) => {
    if (!downloadPermission) {
      notification.failure(
        'Your subscription does not include this feature. Please contact your sales representative to add it.'
      );
    } else if (downloadRemainingUses === 0) {
      notification.failure(
        'Your subscription 0 remaining quota for this feature for this month. Please wait until your quota resets next month and try again.'
      );
    } else {
      dispatch(downloadArtifact(hash));
    }
  };

  const _getHashesFromTerm = (term: string) => uniq(term.split(/\s/).map((v) => v.trim()));

  const _checkUrlParams = () => {
    const search = qs.parse(location.search);
    const { page, limit } = extractUrlQueryParams(search);
    if (typeof search.term === 'string' && page !== undefined) {
      _handleSubmit(search.term, { page, limit });
    }
  };

  const _handleHashSearch = (hashArray: string[], pageQuery: PageQuery, refresh?: boolean) => {
    const { page } = pageQuery;
    const pageLimit = pageQuery.limit || limit;
    setCurrentPage(page);
    if (refresh || !hashSearchResults[page]) {
      dispatch(getHashSearchResults(hashArray, page, pageLimit, refresh));
    }
  };

  const _handleSubmit = (term: string, pageQuery: PageQuery = { page: 0 }) => {
    // Set page
    const { page } = pageQuery;
    const pageLimit = pageQuery.limit || limit;
    setCurrentPage(page);
    setLimit(pageLimit);

    // Set hashes
    const hashArray = _getHashesFromTerm(term);
    setHashes(hashArray);

    // Request results
    _handleHashSearch(hashArray, pageQuery, true);

    // Set URL params
    history.replace(`${match.url}?${qs.stringify({ term, page, limit: pageLimit })}`);
  };

  const _handleChangePage = (page: EPageDirection) => {
    let nextPage = currentPage;
    if (page === EPageDirection.FIRST) {
      nextPage = 0;
    } else if (page === EPageDirection.NEXT) {
      nextPage += 1;
    } else if (page === EPageDirection.PREV) {
      if (nextPage > 0) {
        nextPage -= 1;
      }
    }

    const { term } = qs.parse(location.search);
    setCurrentPage(nextPage);
    _handleHashSearch(hashes, { page: nextPage });
    history.replace(`${match.url}?${qs.stringify({ term, page: nextPage, limit })}`);
  };

  const _handleChangeRowsPerPage = (numRows: number) => {
    const { term } = qs.parse(location.search);
    setLimit(numRows);
    _handleHashSearch(hashes, { page: 0, limit: numRows }, true);
    history.replace(`${match.url}?${qs.stringify({ term, page: 0, limit: numRows })}`);
  };

  useEffect(() => {
    dispatch(clearHashSearchResults());
    _checkUrlParams();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div css={style.search}>
        <HashSearchField
          label={intl.formatMessage(messages.search)}
          textAreaLabel={intl.formatMessage(messages.searchMulti)}
          onSubmit={(term) => _handleSubmit(term)}
          isLoading={isLoading}
          isInitialMulti={hashes.length > 1}
          initialValue={hashes.join('\n')}
        />
      </div>
      <AppLoader
        loadingActions={[
          SubmissionActionName.GET_HASH_SEARCH_RESULTS,
          SubmissionActionName.REFRESH_HASH_SEARCH_RESULTS,
        ]}
        errorActions={[]}
        onReload={() => {}}
      >
        {() => {
          if (!location.search) {
            return (
              <PanelContent
                testId='hashSearchEmpty'
                imageComponent={<RulesetImg />}
                heading={intl.formatMessage(messages.heading)}
                text={intl.formatMessage(messages.text)}
                buttons={[
                  {
                    text: intl.formatMessage(btnMessages.readDocs),
                    href: 'https://docs.polyswarm.io/consumers/searching#hash-searching',
                    variant: 'outlined',
                  },
                ]}
              />
            );
          }

          if (!Object.keys(hashSearchResults).length)
            return (
              <PanelContent
                testId='hashSearchNoneFound'
                imageComponent={<RulesetImg />}
                heading={intl.formatMessage(messages.noneFoundHeading)}
                text={intl.formatMessage(messages.noneFoundText)}
              />
            );

          if (
            Object.values(hashSearchResults).some((result) =>
              result.some(({ error }) => error && isErrorOfType('rate_limit_exceeded', error))
            )
          )
            return (
              <PanelContent
                testId='hashSearchRateLimit'
                imageComponent={<RulesetImg />}
                heading={intl.formatMessage(messages.rateLimit)}
                text={intl.formatMessage(errorMessages.rateLimit)}
                buttons={[
                  isAuthenticated
                    ? {
                        text: intl.formatMessage(btnMessages.viewUsage),
                        onClick: () =>
                          context && context.team
                            ? history.push('settings/team/usage')
                            : history.push('/account/usage'),
                        variant: 'outlined',
                      }
                    : {
                        text: intl.formatMessage(btnMessages.login),
                        onClick: () => login(location.pathname + location.search),
                        variant: 'outlined',
                      },
                ]}
              />
            );

          return (
            <HashSearchTable
              results={hashSearchResults[currentPage]}
              viewScanResult={_viewScanResult}
              downloadArtifact={_downloadArtifact}
              rowsPerPage={limit}
              onChangePage={_handleChangePage}
              onFirstPage={() => _handleChangePage(EPageDirection.FIRST)}
              onChangeRowsPerPage={_handleChangeRowsPerPage}
              isFirst={currentPage === 0}
              isLast={currentPage + 1 === numPages}
            />
          );
        }}
      </AppLoader>
    </Fragment>
  );
};

const style = {
  search: css`
    padding: 0 ${styles.spacing.grid} ${styles.spacing.grid};
  `,
  loader: css`
    text-align: center;
    color: ${styles.color.xLightGrey};
    font-weight: ${styles.font.weight.medium};
    margin: ${styles.spacing.xxs} 0;
    font-size: ${styles.font.size.p1};
  `,
};

export default HashSearchTab;
