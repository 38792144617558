import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import styles from 'views/styles';

const light: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: styles.color.purple,
    },
    text: {
      primary: styles.color.black,
    },
    action: {
      disabled: styles.color.xLightGrey,
    },
    background: {
      paper: styles.color.white,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: styles.color.black,
      },
    },
    MuiFormLabel: {
      root: {
        color: styles.color.xLightGrey,
      },
    },
    MuiMenuItem: {
      root: {
        color: styles.color.black,
      },
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: styles.color.xxLightPurple,
        },
      },
    },
    MuiTableCell: {
      body: {
        color: styles.color.black,
      },
      head: {
        color: styles.color.xLightGrey,
      },
    },
    MuiTableSortLabel: {
      root: {
        '&$active': {
          color: styles.color.black,
          '&& $icon': {
            color: styles.color.black,
          },
        },
      },
    },
    MuiSelect: {
      root: {
        color: styles.color.black,
      },
      icon: {
        color: styles.color.black,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: styles.color.white,
        color: styles.color.black,
      },
    },
    MuiTablePagination: {
      toolbar: {
        color: styles.color.black,
      },
    },
    MuiButton: {
      containedPrimary: {
        color: styles.color.white,
        backgroundColor: styles.color.purple,
        '&:hover': {
          backgroundColor: styles.color.darkPurple,
          '&$disabled': {
            color: styles.color.white,
            backgroundColor: styles.color.purple,
          },
        },
        '&$disabled': {
          color: styles.color.white,
          backgroundColor: styles.color.purple,
        },
      },
      outlinedPrimary: {
        color: styles.color.purple,
        border: `2px solid ${styles.color.purple}`,
        '&:hover': {
          color: styles.color.purple,
          border: `2px solid ${styles.color.purple}`,
          '&$disabled': {
            color: styles.color.purple,
            borderColor: styles.color.purple,
          },
        },
        '&$disabled': {
          color: styles.color.purple,
          border: `2px solid ${styles.color.purple}`,
        },
      },
      textPrimary: {
        color: styles.color.purple,
        '&$disabled': {
          color: styles.color.purple,
        },
      },
      containedSecondary: {
        color: styles.color.white,
        backgroundColor: styles.color.red,
        '&:hover': {
          backgroundColor: styles.color.darkRed,
          '&$disabled': {
            color: styles.color.white,
            backgroundColor: styles.color.red,
          },
        },
        '&$disabled': {
          color: styles.color.white,
          backgroundColor: styles.color.red,
        },
      },
      outlinedSecondary: {
        color: styles.color.red,
        border: `2px solid ${styles.color.red}`,
        '&:hover': {
          color: styles.color.red,
          border: `2px solid ${styles.color.red}`,
          '&$disabled': {
            color: styles.color.red,
            borderColor: styles.color.red,
          },
        },
        '&$disabled': {
          color: styles.color.red,
          border: `2px solid ${styles.color.red}`,
        },
      },
      textSecondary: {
        color: styles.color.red,
        '&$disabled': {
          color: styles.color.red,
        },
      },
    },
    MuiFab: {
      root: {
        color: styles.color.white,
        backgroundColor: styles.color.purple,
        '&:hover': {
          backgroundColor: styles.color.darkPurple,
          '@media (hover: none)': {
            backgroundColor: styles.color.purple,
          },
          '&$disabled': {
            backgroundColor: styles.color.purple,
          },
        },
        '&$disabled': {
          color: styles.color.white,
          backgroundColor: styles.color.purple,
        },
      },
    },
    MuiTab: {
      root: {
        '&:hover': {
          color: styles.color.purple,
        },
      },
      textColorInherit: {
        color: styles.color.black,
        '&$selected': {
          color: styles.color.purple,
        },
        '&$disabled': {
          color: styles.color.xLightGrey,
        },
      },
    },
    MuiTabs: {
      root: {
        '&:before': {
          backgroundColor: styles.border.color.purple,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        color: styles.color.white,
        backgroundColor: styles.color.black,
      },
    },
  },
};

export default light;
