import { useState, useEffect } from 'react';
import api from 'services/api';
import { useUser } from 'views/components/providers/UserProvider';
import { WalletRes } from 'services/api/schema/wallets';
import { getBalanceValues } from 'utils/balance';

export default function useUserWallets(loadOnStart = true): {
  refetch: () => void;
  loading: boolean;
  error: boolean;
  userWallets: WalletRes[] | null
} {
  const user = useUser();
  const accountNumber = user.context?.accountNumber || user.accountNumber;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [userWallets, setUserWallets] = useState<WalletRes[] | null>(null);

  const refetch = () => setReload(!reload);

  useEffect(() => {
    async function onload() {
      try {
        setError(false);
        setLoading(true);
        const response = await api.getUserWallets(accountNumber);
        setUserWallets(
          response.data.map((engine) => {
            const [truncatedBalance, fullBalance] = getBalanceValues(engine.balance);
            return { ...engine, truncatedBalance, fullBalance };
          })
        );
        setLoading(false);
      } catch (error) {
        setError(true);
      }
    }

    if (loadOnStart) {
      onload();
    }
  }, [accountNumber, loadOnStart, reload]);

  return { userWallets, refetch, loading, error };
}
