import React from 'react';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import Skeleton from 'views/components/Loader/Skeleton';

const SidebarLoading = () => (
  <Card style={{ padding: styles.spacing.grid }}>
    <Skeleton className='h-mb-xs' height={35} width='45%' />
    <Skeleton className='h-mb-xs' height={20} width='80%' />
    <Skeleton className='h-mb-xs' height={150} />
    <Skeleton className='h-mb-sm' height={20} width='50%' />
    <Skeleton className='h-mb-xs' height={35} width='50%' />
    <Skeleton className='h-mb-xs' height={20} />
    <Skeleton className='h-mb-xs' height={20} width='75%' />
    <Skeleton className='h-mb-xs' height={20} />
    <Skeleton height={20} width='80%' />
  </Card>
);

export default SidebarLoading;
