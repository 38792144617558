import { CSSProperties } from 'react';

const styles: { [key: string]: CSSProperties } = {
  cell: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    alignSelf: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'visible',
  },
  column: {
    alignSelf: 'center',
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'visible',
  },
  columnHeader: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
};

export default styles;
