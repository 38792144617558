import tenant from 'tenants';

export const isPivotingEnabled = () => {
  return (
    !tenant.tabsEnabled?.search ||
    tenant.tabsEnabled?.search === 'all' ||
    tenant.tabsEnabled?.search.includes('metadata')
  );
};

export const getTenant = (): string => {
  const tenant = process.env.REACT_APP_TENANT || 'polyswarm';
  return tenant.split('-stage')[0];
};
