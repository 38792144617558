import React from 'react'; // eslint-disable-line
import { css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import reset from './reset';
import grid from './grid';
import breakpoint from './breakpoint';
import typography from './typography';
import font, { fonts } from './font';
import { spacingHelpers } from './spacing';
import color from './color';
import { ThemeState } from 'models/Theme';
import { currentTenant } from 'tenants';

const base = (theme: ThemeState) => {
  let baseColor;
  let baseBgColor;
  switch (theme) {
    case 'dark':
      baseColor = color.xLightGrey;
      baseBgColor = color.darkBlack;
      break;
    default:
      baseColor = color.black;
      baseBgColor = color.white;
      break;
  }

  return css`
    ${reset}
    ${grid}
  ${fonts}
  ${spacingHelpers}

  html {
      font-size: 9px;

      @media (min-width: ${breakpoint.lg}) {
        font-size: 10px;
      }

      @media (min-width: ${breakpoint.xxl}) {
        font-size: 12px;
      }
    }

    body {
      font-family: ${font.family.main};
      font-weight: ${font.weight.normal};
      color: ${baseColor};
      background-color: ${baseBgColor};
      font-size: ${font.size.p};
      line-height: 1.5;

      --highlight: ${color.green};
      --polyswarm: ${color.isPolySwarm ? 'block' : 'none'};
      --accenture: ${currentTenant === 'accenture' ? 'block' : 'none'};
    }

    .MuiTooltip-tooltip {
      max-width: 500px !important;
    }

    body[data-theme='dark'] {
      --color-logo: ${color.white};
      --main: ${color.lightBlue};
    }

    body[data-theme='light'] {
      --color-logo: ${color.black};
      --main: ${color.purple};
    }

    ${typography(theme)}
  `;
};

export default base;
