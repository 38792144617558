import { SystemAction, SystemActionName } from './types';
import { SystemState } from '.';

export const initialState: SystemState = {
  isMaintenanceMode: false,
};

const system = (state: SystemState = initialState, action: SystemAction): SystemState => {
  switch (action.type) {
    case SystemActionName.GET_SYSTEM_STATUS: {
      return action.result || state;
    }

    default: {
      return state;
    }
  }
};

export default system;
