import React, { useState, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import styled from '@emotion/styled';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { btnMessages } from 'views/components/Button';
import { Tooltip } from '@material-ui/core';
import ActionBtn from '../ActionBtn';

interface IDeleteWebhookDialog {
  disabled: boolean;
  disabledReason?: string;
  onDeleteWebhook: () => void;
}

const ContentWrapper = styled.div`
  width: 548px;
  padding: ${styles.spacing.md};

  & .cancelBtn {
    margin-left: 1rem;
  }
`;

export const DeleteWebhookDialog = ({
  onDeleteWebhook,
  disabled,
  disabledReason,
}: IDeleteWebhookDialog) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const DeleteBtn = ({ disabled }: { disabled: boolean }) => {
    return (
      <ActionBtn
        name='delete'
        disabled={disabled}
        aria-label={intl.formatMessage(btnMessages.delete)}
        onClick={onOpen}
        data-cy='webhooksDeleteBtn'
      />
    );
  };

  return (
    <Fragment>
      {disabled && disabledReason ? (
        <Tooltip title={disabledReason} placement='top'>
          <div>
            <DeleteBtn disabled={disabled} />
          </div>
        </Tooltip>
      ) : (
        <DeleteBtn disabled={disabled} />
      )}
      <Dialog open={open} onClose={onClose}>
        <ContentWrapper>
          <div css={classes.header}>
            <FormattedMessage
              id='account.webhooks.deleteWebhook.header'
              defaultMessage='Delete Webhook'
            />
          </div>
          <div css={classes.subtitle}>
            <FormattedMessage
              id='account.webhooks.deleteWebhook.subtitle'
              defaultMessage='Confirm you want to delete the webhook'
            />
          </div>
          <div css={classes.buttonsContainer}>
            <Button
              type='button'
              color='secondary'
              variant='contained'
              onClick={onDeleteWebhook}
              data-testid='submitBtn'>
              <FormattedMessage
                id='account.webhooks.deleteWebhook.submitBtn'
                defaultMessage='Delete'
              />
            </Button>
            <Button
              className='cancelBtn'
              color='primary'
              variant='outlined'
              onClick={onClose}
              data-testid='cancelBtn'>
              <FormattedMessage {...btnMessages.cancel} />
            </Button>
          </div>
        </ContentWrapper>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    header: {
      textAlign: 'left',
      fontSize: '2.6rem',
      fontWeight: 'bold',
      paddingBottom: styles.spacing.xs,
    },
    subtitle: {
      paddingBottom: styles.spacing.xs,
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
  },
});
