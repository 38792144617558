import React, { Fragment } from 'react'; // eslint-disable-line
import { css, jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import { StatusColor as StatusColorComponent } from 'views/components/StatusColor';
import { AmountColor as AmountColorComponent } from 'views/components/AmountColor';
import { SmallCopyButton } from 'views/components/CopyText/CopyTextButton';
import config from 'config';
import { ITransactions } from '../types';
import { formatTransactionHash } from 'views/components/utils';
import styles from 'views/styles';

export interface ITransactionsTableRow {
  rowData: ITransactions;
}

const TransactionsTableRow = ({ rowData }: ITransactionsTableRow) => {
  const style = [cellStyle];
  let AmountColor = AmountColorComponent;
  let StatusColor = StatusColorComponent;

  if (rowData.status === 'canceled') {
    StatusColor = ({ status }) => <span>{status}</span>;
    AmountColor = ({ amount }) => <span>{amount}</span>;
    style.push(greyStyle);
  }

  return (
    <Fragment>
      <BaseHoverableTableRow
        renderCells={() => (
          <Fragment>
            <TableCell css={style}>{rowData.timestamp}</TableCell>
            <TableCell css={style}>
              {rowData.id && (
                <Tooltip
                  interactive
                  title={
                    <div>
                      {rowData.id}
                      <SmallCopyButton text={rowData.id} />
                    </div>
                  }
                  placement='top'
                >
                  <a
                    href={config.etherscanUrl.replace('{txid}', rowData.id)}
                    className='a'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {formatTransactionHash(rowData.id)}
                  </a>
                </Tooltip>
              )}
            </TableCell>
            <TableCell css={style}>
              <StatusColor status={rowData.status} />
            </TableCell>
            <TableCell css={style}>Withdrawal</TableCell>
            <TableCell css={style}>
              <Tooltip
                title={
                  <div>
                    <AmountColor amount={rowData.fullAmount} />
                  </div>
                }
                placement='top-start'
              >
                <div>
                  <AmountColor amount={rowData.amount} />
                </div>
              </Tooltip>
            </TableCell>
            <TableCell css={style}>
              <Tooltip
                title={
                  <div>
                    <AmountColor amount={rowData.fullFee} />
                  </div>
                }
                placement='top-start'
              >
                <div>
                  <AmountColor amount={rowData.fee} />
                </div>
              </Tooltip>
            </TableCell>
            <TableCell css={style}>
              <Tooltip
                title={
                  <div>
                    <AmountColor amount={rowData.fullTotal} />
                  </div>
                }
                placement='top-start'
              >
                <div>
                  <AmountColor amount={rowData.total} />
                </div>
              </Tooltip>
            </TableCell>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

const cellStyle = css`
  font-size: ${styles.font.size.tableCompact} !important;
  padding-left: 8px !important;
`;

const greyStyle = css`
  color: ${styles.color.xLightGrey} !important;
`;

export default TransactionsTableRow;
