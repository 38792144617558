import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { storeAccountKey, getAccountStoredKey } from 'utils/storage/storage';
import { useUser } from 'views/components/providers/UserProvider';
import { makeStyles } from 'views/components/providers/ThemeProvider';

export function ZipPassword({
  fullWidth = false,
  isShown = false,
  handleChange,
}: {
  fullWidth?: boolean;
  isShown?: boolean;
  handleChange: (config: { isZip: boolean; zipPassword?: string }) => void;
}) {
  const { classes } = useStyles();
  const { accountNumber } = useUser();
  const zipPassword = getAccountStoredKey<{ isZip: boolean; zipPassword: string }>(
    accountNumber,
    'zipPassword'
  );
  const [isZipFile, setIsZipFile] = useState(false);
  const [password, setPassword] = useState<string>(zipPassword ? zipPassword.zipPassword : '');
  const [rememberPassword, setRememberPassword] = useState<boolean>(
    zipPassword && zipPassword.zipPassword ? true : false
  );

  if (!isShown) {
    return null;
  }

  return (
    <div>
      <div css={classes.container}>
        <Checkbox
          color='primary'
          id='zipWithPassword'
          checked={isZipFile}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const base = { isZip: !isZipFile };
            const withPassword = {
              zipPassword: password,
              ...base,
            };
            const data = password && rememberPassword ? withPassword : base;

            setIsZipFile(!isZipFile);
            storeAccountKey(accountNumber, 'zipPassword', data);
            handleChange(withPassword);
          }}
        />
        <label css={classes.label} htmlFor='zipWithPassword'>
          Extract zip
        </label>
      </div>
      {isZipFile && (
        <>
          <TextField
            onChange={(e) => {
              const base = { isZip: true };
              const withPassword = {
                zipPassword: e.target.value,
                ...base,
              };
              const data = e.target.value && rememberPassword ? withPassword : base;

              setPassword(e.target.value);
              storeAccountKey(accountNumber, 'zipPassword', data);
              handleChange(withPassword);

              if (!e.target.value) {
                setRememberPassword(false);
              }
            }}
            type='password'
            value={password}
            fullWidth={fullWidth}
            placeholder='Password (optional)'
          />
          <div css={classes.container}>
            <Checkbox
              color='primary'
              disabled={!password}
              id='rememberZipPassword'
              checked={rememberPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setRememberPassword(!rememberPassword);

                if (rememberPassword) {
                  storeAccountKey(accountNumber, 'zipPassword', {
                    isZip: isZipFile,
                  });
                } else {
                  storeAccountKey(accountNumber, 'zipPassword', {
                    zipPassword: password,
                    isZip: isZipFile,
                  });
                }
              }}
            />
            <label css={classes.label} htmlFor='rememberZipPassword'>
              Remember password
            </label>
          </div>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-1.2rem',
    },
    label: {
      fontSize: '1.2rem',
    },
  },
  light: {},
  dark: {},
});
