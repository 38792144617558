import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { useIntl, defineMessages } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

const messages = defineMessages({
  title: {
    id: 'privacyPage.title',
    defaultMessage: 'Privacy Policy',
  },
});

const Wrapper = styled(Card)`
  padding: calc(30px * 1.5);
  margin-top: 3.2rem !important;

  strong {
    font-weight: bold;
  }

  h2 {
    font-size: 2rem;
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;
    margin-left: 2rem;

    li {
      padding: 0.5rem 0 0.5rem 0;
    }
  }

  ol {
    list-style: decimal;
    counter-reset: item;

    li {
      padding: 0.5rem 0 0.5rem 0;

      & > ol {
        margin-left: 0;
      }
    }
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 15px;
  }
  th {
    color: #656573;
    font-size: 1.8rem;
  }
`;

const Header = styled.h1`
  font-size: ${styles.font.size.h3};
`;

const SubHeader = styled.div`
  font-size: ${styles.font.size.h5};
`;

const PrivacyPageContent = () => {
  const { classes } = useStyles();

  return (
    <Wrapper className='container h-mt-lg h-mb-lg'>
      <ScrollToTopOnMount />

      <Typography paragraph>
        <Header css={classes.header}>Privacy Policy</Header>
        <SubHeader css={classes.subheader}>Last Updated: May 31, 2022</SubHeader>
      </Typography>
      <Typography paragraph>
        Thank you for choosing to be part of our community at PolySwarm Pte. Ltd. ("Company", "we",
        "us", or "our"). We are committed to protecting your personal information and your right to
        privacy. If you have any questions or concerns about our policy, or our practices with
        regards to your personal information, please contact us at{' '}
        <a css={classes.links} href='mailto:privacy@polyswarm.io'>
          privacy@polyswarm.io.
        </a>
      </Typography>
      <Typography paragraph>
        This Privacy Policy describes what personal information we collect, how we use and share
        that information, and what choices you have when you visit our websites (including{' '}
        <a css={classes.links} href='https://polyswarm.io'>
          https://polyswarm.io
        </a>{' '}
        and{' '}
        <a css={classes.links} href='https://polyswarm.network'>
          https://polyswarm.network
        </a>
        ), and use our services, including our browser extension (collectively, our “Services”).
      </Typography>
      <Typography paragraph>
        Please note that you are responsible for any files you submit to, review or analyze using
        our Services and it is your responsibility under our{' '}
        <a css={classes.links} href='https://polyswarm.network/terms'>
          Terms of Service
        </a>{' '}
        to ensure there is no protected information within the files you share with us.
      </Typography>
      <Typography paragraph>
        <h2>What Information Do We Collect?</h2>
      </Typography>
      <Typography paragraph>
        <strong>Personal information you disclose to us</strong>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We collect personal information that you provide to us such as
        name, address, contact information, passwords and security data, payment information, and
        social media login data.
      </Typography>
      <Typography paragraph>
        We collect the following categories of personal information that you provide to us:
      </Typography>
      <Typography paragraph>
        <strong>Name and Contact Data.</strong> We collect your first and last name, email address,
        postal address, phone number, account credentials, and other similar contact data.
      </Typography>
      <Typography paragraph>
        <strong>Payment and Transaction Data.</strong> We collect payment information, such as
        credit card number, and information about any Services you purchase from us. If you purchase
        an account directly through our website, we use a third-party payment processor, Stripe, to
        handle payments on our Services and Stripe collects your payment information directly. For
        more information, please read Stripe’s Services Agreement{' '}
        <a
          css={classes.links}
          href='https://protect-us.mimecast.com/s/BashCNk87jfx2lzlszzSiA?domain=stripe.com'>
          here
        </a>
        , and Privacy Policy{' '}
        <a
          css={classes.links}
          href='https://protect-us.mimecast.com/s/O8tPCOYZJkTX6RMRtjzrCM?domain=stripe.com'>
          here.
        </a>
      </Typography>
      <Typography paragraph>
        <strong>Social Media Login Data.</strong> We may provide you with the option to register
        using social media account details, like your Google, Github, Facebook, Twitter or other
        social media account. If you choose to register in this way, we collect your social media
        handle and other information as described below.
      </Typography>
      <Typography paragraph>
        <strong>Marketing information.</strong> This includes your preferences for receiving
        communications about our activities and publications, and details about how you engage with
        our communications, and what events, webinars and other arrangements you sign up for.
      </Typography>
      <Typography paragraph>
        <strong>Feedback or correspondence.</strong> This includes information you provide when
        contacting us with questions, or provide feedback, product reviews, or otherwise correspond
        with us.
      </Typography>
      <Typography paragraph>
        <strong>Information automatically collected</strong>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> Some information - such as IP address and/or browser and device
        characteristics - is collected automatically when you use our Services.
      </Typography>
      <Typography paragraph>
        We automatically collect certain information when you use our Services, such as the type of
        computer or mobile device accessing our Services, and interactions over time with our
        Services. This information includes device and usage information, such as your IP address,
        browser and device characteristics, operating system, language preferences, referring URLs
        and URLs you visit when the Service is enabled, device name, country, location, information
        about how and when you use our Sites and other technical information.
      </Typography>
      <Typography paragraph>
        Like many businesses, we also collect information through cookies and similar technologies.
        You can find out more about this in the "Cookies Policy" section below.
      </Typography>
      <Typography paragraph>
        <strong>Information collected from other sources</strong>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We may collect limited data from public databases, marketing
        partners, social media platforms, and other outside sources.
      </Typography>
      <Typography paragraph>
        We may obtain information about you from other sources, such as public databases, joint
        marketing partners, social media platforms (such as Google, Github, Facebook and Twitter, if
        you log in using these services), as well as from other third parties. The information we
        receive from other sources includes: social media profile information (your name, gender,
        birthday, email, current city, state and country, user identification numbers for your
        contacts, profile picture URL and any other information that you choose to make public);
        marketing leads and search results and links, including paid listings (such as sponsored
        links).
      </Typography>
      <Typography paragraph>
        <h2>How Do We Use Your Information?</h2>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We process your information to operate and deliver the Services,
        for business and product development, for marketing and advertising, to comply with
        applicable laws, for compliance, fraud prevention, and safety, and to create anonymous
        statistics.
      </Typography>
      <Typography paragraph>We use personal information for the following purposes:</Typography>
      <Typography paragraph>
        <strong>To operate and deliver the Services,</strong> including:
      </Typography>
      <Typography component='div' paragraph>
        <ul>
          <li>
            Account creation, including registering and identifying you as a user setting up your
            profile, and logging and saving your account and profile settings;
          </li>
          <li>
            Providing, operating, maintaining, and securing your account and the Services, and
            fulfilling transactions;
          </li>
          <li>
            Communicating about the Services, including by responding to questions, providing
            customer support, and sending announcements, updates, security alerts, and support and
            administrative messages; and
          </li>
          <li>Resolving disputes, collecting fees, and troubleshooting problems.</li>
        </ul>
      </Typography>
      <Typography paragraph>
        <strong>For business and product development,</strong> including to analyze and improve the
        Services, develop new products and services, identify usage trends, and operate and expand
        our business activities.
      </Typography>
      <Typography paragraph>
        <strong>For marketing and advertising,</strong> including:
      </Typography>
      <Typography component='div' paragraph>
        <ul>
          <li>
            <strong>Direct marketing.</strong> This includes sending you newsletters and email
            marketing in accordance with your preferences, providing you offers, guides, events,
            webinars, and relevant promotions, and tailoring communications with you to accommodate
            your areas of interest and focus.
          </li>
          <li>
            <strong>Interest-based advertising.</strong> We may engage third-party advertising
            companies to display ads promoting our services across the web. These companies may use
            cookies and similar technologies to collect information about interactions over time
            across the Internet and use that information to serve online ads that they think will be
            of interest.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        <strong>To comply with law.</strong> As we believe necessary or appropriate to comply with
        applicable laws, lawful requests, and legal process, such as to respond to subpoenas or
        requests from government authorities.
      </Typography>
      <Typography paragraph>
        <strong>For compliance, fraud prevention, and safety,</strong> including to: protect our or
        others’ rights, privacy, safety or property (including by making and defending legal
        claims); enforce the terms and conditions that govern the Services; and protect, investigate
        and deter against fraudulent, harmful, unauthorized, unethical, or illegal activity.
      </Typography>
      <Typography paragraph>
        <strong>To create anonymous statistics,</strong> including by aggregating information and
        removing information that makes the data identifiable, which we use to develop, improve and
        promote our Services, and for forecasting purposes.
      </Typography>
      <Typography paragraph>
        We generally use personal information for the purposes described above where it is necessary
        for our commercial interests, your interests or the interests of a third party provided that
        such uses do not unfairly prejudice your rights. We may also use your personal information
        where we ask your consent to do so or where we are required by law.
      </Typography>
      <Typography paragraph>
        <h2>How Do We Share Your Information?</h2>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We share your information with your consent, to comply with laws,
        to protect your rights, or to fulfill business obligations.
      </Typography>
      <Typography paragraph>
        We share your information with the following categories of recipients:
      </Typography>
      <Typography component='div' paragraph>
        <ul>
          <li>
            <strong>Service Providers,</strong> including hosting services, email services,
            advertising and marketing services, payment processors, customer support services, and
            analytics services.
          </li>
          <li>
            <strong>Professional Advisors,</strong> such as lawyers and accountants, where doing so
            is necessary to facilitate the services they render to us.
          </li>
          <li>
            <strong>Business Transaction Recipients,</strong> such as counterparties and others
            assisting with a merger, acquisition, financing, reorganization, bankruptcy,
            receivership, dissolution, asset sale, or similar transaction, and with successors or
            affiliates as part of or following that transaction.
          </li>
          <li>
            <strong>Government Authorities,</strong> where required to do so to comply with
            applicable laws or regulatory requirements, to comply with court orders, and to enforce,
            defend, protect and assert our legal rights.
          </li>
          <li>
            <strong>Affiliates: </strong> We may share personal information with our current and
            future affiliates, meaning an entity that controls, is controlled by, or is under common
            control with us. Our affiliates may use the personal information we share in a manner
            consistent with this Privacy Policy.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        <h2>Is Your Information Transferred Internationally?</h2>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We may transfer, store, and process your information in countries
        other than your own.
      </Typography>
      <Typography paragraph>
        Our servers are located in the U.S. If you are accessing our Sites from outside, please be
        aware that your information may be transferred to, stored, and processed by us in our
        facilities and by those third parties with whom we may share your personal information, in
        the U.S. and other countries.
      </Typography>
      <Typography paragraph>
        If you are a resident in the European Economic Area, then these countries may not have data
        protection or other laws as comprehensive as those in your country. We will however take all
        necessary measures to protect your personal information in accordance with this Privacy
        Policy and applicable law. For more information, please contact us at{' '}
        <a css={classes.links} href='mailto:privacy@polyswarm.io'>
          privacy@polyswarm.io
        </a>
        .
      </Typography>
      <Typography paragraph>
        <h2>How Long Do We Keep Your Information?</h2>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We keep your information for as long as necessary to fulfill the
        purposes outlined in this privacy policy unless otherwise required by law.
      </Typography>
      <Typography paragraph>
        We will keep your personal information for as long as it is necessary for the purposes set
        out in this privacy policy, unless a longer retention period is required or permitted by law
        (such as tax, accounting or other legal requirements).
      </Typography>
      <Typography paragraph>
        When we have no ongoing legitimate business need to process your personal information, we
        will take steps to delete, archive, aggregate or anonymize it. We may retain backup archives
        that we securely store and isolate from further processing.
      </Typography>
      <Typography paragraph>
        <h2>How Do We Keep Your Information Safe?</h2>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We aim to protect your personal information through a system of
        organizational and technical security measures.
      </Typography>
      <Typography paragraph>
        We have implemented appropriate technical and organizational security measures designed to
        protect the security of any personal information we process. Although we will do our best to
        protect your personal information, we cannot guarantee that our security measures will be
        sufficient to protect against all types of threats.
      </Typography>
      <Typography paragraph>
        <h2>Do We Collect Information from Minors?</h2>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We do not knowingly collect data from or market to children under
        18 years of age.
      </Typography>
      <Typography paragraph>
        We do not knowingly solicit data from or market to children under 18 years of age. By using
        the Sites, you represent that you are at least 18 or that you are the parent or guardian of
        such a minor and consent to such minor dependent’s use of the Sites. If we learn that
        personal information from users less than 18 years of age has been collected, we will
        deactivate the account and take reasonable measures to promptly delete such data from our
        records. If you become aware of any data we have collected from children under age 18,
        please contact us at{' '}
        <a css={classes.links} href='mailto:privacy@polyswarm.io'>
          privacy@polyswarm.io
        </a>
        .
      </Typography>
      <Typography paragraph>
        <h2>What Are Your Privacy Rights and Choices?</h2>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> We provide you with choices concerning how we processing your
        information, and you may have rights that allow you greater access to and control over your
        personal information. You may review, change, or terminate your account at any time.
      </Typography>
      <Typography paragraph>
        <strong>Access or update account information.</strong> Users who have registered for an
        account with us, may review and update certain personal information in their account profile
        by logging into the account.
      </Typography>
      <Typography paragraph>
        <strong>Opt out of marketing communications.</strong> Recipients of marketing emails may opt
        out of marketing-related emails by following the opt-out or unsubscribe instructions at the
        bottom of the email or by contacting us at privacy@polyswarm.io. Even if you opt out, you
        will continue to receive service-related and other non-marketing emails.
      </Typography>
      <Typography paragraph>
        <strong>Your privacy rights.</strong> The laws of your country of residence (including if
        you live in the European Union or United Kingdom or in California) may give you certain
        rights regarding your personal information, including the right to:
      </Typography>
      <Typography component='div' paragraph>
        <ul>
          <li>
            <strong>Access.</strong> Obtain information about our processing of your personal
            information and receive access to your personal information.
          </li>
          <li>
            <strong>Correct.</strong> Update or correct inaccuracies in your personal information
          </li>
          <li>
            <strong>Delete.</strong> Delete your personal information.
          </li>
          <li>
            <strong>Transfer.</strong> Transfer a machine-readable copy of your personal information
            to you or a third party of your choice.
          </li>
          <li>
            <strong>Restrict.</strong> Restrict the processing of your personal information.
          </li>
          <li>
            <strong>Object.</strong> Object to certain processing activities involving your personal
            information.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        You may submit these requests by email to{' '}
        <a css={classes.links} href='mailto:privacy@polyswarm.io'>
          privacy@polyswarm.io
        </a>
        . We may request specific information from you to help us confirm your identity and process
        your request. Applicable law may require or permit us to decline your request. California
        residents can empower an “authorized agent” to submit requests on their behalf. We will
        require authorized agents to confirm their identity and authority, in accordance with
        applicable laws. If you would like to submit a complaint about our use of your personal
        information or our response to your requests regarding your personal information, you may
        contact us or submit a complaint to the data protection regulator in your jurisdiction.
      </Typography>
      <Typography paragraph>
        <strong>Online tracking opt-out.</strong> There are a number of ways to opt out of having
        online activity and device data collected through our Services. Please see out Cookie Policy
        (below) for more information.
      </Typography>
      <Typography paragraph>
        <h2>Do We Make Updates to This Policy?</h2>
      </Typography>
      <Typography paragraph>
        <strong>In Short:</strong> Yes, we may update this policy as necessary to stay compliant
        with relevant laws, to reflect changes to our practices and Services, and to make our
        Privacy Policy better.
      </Typography>
      <Typography paragraph>
        We may update this privacy policy from time to time. The updated version will be indicated
        by an updated "Revised" date and the updated version will be effective as soon as it is
        accessible. If we make material changes to this privacy policy that require us to notify you
        directly, we may notify you either by prominently posting a notice of such changes or by
        directly sending you a notification. We encourage you to review this privacy policy
        frequently to be informed of how we are protecting your information.
      </Typography>
      <Typography paragraph>
        <h2>How Can You Contact Us About This Policy?</h2>
      </Typography>
      <Typography paragraph>
        If you have questions or comments about this policy, you may contact us by email at{' '}
        <a css={classes.links} href='mailto:privacy@polyswarm.io'>
          privacy@polyswarm.io
        </a>
        , or by post to:
      </Typography>
      <Typography paragraph>
        <Typography>PolySwarm Pte. Ltd.</Typography>
        <Typography>548 Market Street #37005 </Typography>
        <Typography>San Francisco, CA 94104 United States</Typography>
      </Typography>
      <Typography paragraph>
        <h2>Cookie Policy</h2>
      </Typography>
      <Typography paragraph>
        This Cookie Policy explains how we use cookies and similar technologies in connection with
        our Services.
      </Typography>
      <Typography paragraph>
        <strong>What are cookies and similar technologies?</strong>
      </Typography>
      <Typography component='div' paragraph>
        <ul>
          <li>
            <strong>Cookies</strong> are text files that websites store on a visitor’s device to
            uniquely identify the visitor’s browser or to store information or settings in the
            browser for the purpose of helping you navigate between pages efficiently, remembering
            your preferences, enabling functionality, helping us understand user activity and
            patterns, and facilitating online advertising.
          </li>
          <li>
            <strong>Local storage technologies,</strong> like HTML5, provide cookie-equivalent
            functionality but can store larger amounts of data, including on your device outside of
            your browser in connection with specific applications.
          </li>
          <li>
            <strong>Web beacons,</strong> also known as pixel tags or clear GIFs, are used to
            demonstrate that a webpage or email was accessed or opened, or that certain content was
            viewed or clicked.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        We refer to all these technologies collectively as “cookies.”
      </Typography>
      <Typography paragraph>
        <strong>What types of cookies and similar technologies do we use?</strong>
      </Typography>
      <Typography paragraph>We use the following categories of cookies:</Typography>
      <TableContainer>
        <Table css={classes.table} aria-label='cookie table'>
          <TableHead>
            <TableRow>
              <TableCell>Purpose</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Essential</TableCell>
              <TableCell>
                These cookies are necessary to allow the technical operation of our services (e.g.,
                they enable you to move around on a website and to use its features).
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Functionality/ performance</TableCell>
              <TableCell>
                These cookies are used to enhance the functionality and performance of our website.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Analytics</TableCell>
              <TableCell>
                These cookies help us understand how our website is performing and being used. We
                use Google Analytics cookies for this purpose.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Advertising</TableCell>
              <TableCell>
                These cookies are used by advertising companies to collect information about how you
                use our website and to show you ads that may be relevant to your interests. We use
                social media cookies, such as cookies from Twitter, for this purpose.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography paragraph></Typography>
      <Typography paragraph>
        <strong>How can you control the use of cookies?</strong>
      </Typography>
      <Typography paragraph>
        Other than “essential cookies,” which are required to allow our Services to operate, you can
        control our use of cookies by:
      </Typography>
      <Typography component='div' paragraph>
        <ul>
          <li>
            <strong>Blocking cookies in your browser.</strong> Most browsers let you remove or
            reject cookies, including cookies used for interest-based advertising. To do this,
            follow the instructions in your browser settings. Many browsers accept cookies by
            default until you change your settings. For more information about cookies, including
            how to see what cookies have been set on your device and how to manage and delete them,
            visit{' '}
            <a css={classes.links} href='https://allaboutcookies.org/'>
              www.allaboutcookies.org.
            </a>
          </li>
          <li>
            <strong>Blocking advertising ID use in your mobile settings. </strong>Your mobile device
            settings may provide functionality to limit use of the advertising ID associated with
            your mobile device for interest-based advertising purposes.
          </li>
          <li>
            <strong>Using privacy plug-ins or browsers.</strong> You can block our websites from
            setting cookies used for interest-based ads by using a browser with privacy features,
            like{' '}
            <a css={classes.links} href='https://brave.com/'>
              Brave,
            </a>{' '}
            or installing browser plugins like{' '}
            <a css={classes.links} href='https://privacybadger.org/'>
              {' '}
              Privacy Badger,
            </a>{' '}
            <a css={classes.links} href='https://www.ghostery.com/'>
              Ghostery,
            </a>{' '}
            or{' '}
            <a css={classes.links} href='https://ublock.org/'>
              uBlock Origin,
            </a>{' '}
            and configuring them to block third party cookies/trackers. You can also opt out of
            Google Analytics by downloading and installing the browser plug-in available at:{' '}
            <a css={classes.links} href='https://tools.google.com/dlpage/gaoptout'>
              https://tools.google.com/dlpage/gaoptout.
            </a>
          </li>
          <li>
            <strong>Platform opt outs.</strong> The following advertising partners offer opt out
            features that let you opt out of use of your information for interest-based advertising:
            <ul>
              <li>
                Google:{' '}
                <a css={classes.links} href='https://adssettings.google.com/'>
                  adssettings.google.com
                </a>
              </li>
              <li>
                Twitter:{' '}
                <a
                  css={classes.links}
                  href='https://help.twitter.com/en/rules-and-policies/twitter-cookies'>
                  https://help.twitter.com/en/rules-and-policies/twitter-cookies
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>Advertising industry opt out tools.</strong> You can also use these opt out
            options to limit use of your information for interest-based advertising by participating
            companies:
            <ul>
              <li>
                Digital Advertising Alliance for Websites:{' '}
                <a css={classes.links} href='https://optout.aboutads.info/'>
                  optout.aboutads.info
                </a>
              </li>
              <li>
                Digital Advertising Alliance for Mobile Apps:{' '}
                <a css={classes.links} href='https://youradchoices.com/appchoices'>
                  https://youradchoices.com/appchoices
                </a>
              </li>
              <li>
                Network Advertising Initiative:{' '}
                <a css={classes.links} href='https://optout.networkadvertising.org/'>
                  optout.networkadvertising.org
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </Typography>
      <Typography paragraph>
        Note that because these opt out mechanisms are specific to the device or browser on which
        they are exercised, you will need to opt out on every browser and device that you use.
      </Typography>
      <Typography paragraph>
        <strong>Do Not Track.</strong> Some Internet browsers may be configured to send “Do Not
        Track” signals to the online services that you visit. We currently do not respond to "Do Not
        Track" or similar signals. To find out more about "Do Not Track," please visit{' '}
        <a css={classes.links} href='http://www.allaboutdnt.com'>
          http://www.allaboutdnt.com.
        </a>
      </Typography>
    </Wrapper>
  );
};

const PrivacyPage = () => {
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <PrivacyPageContent />
      </BaseLayout>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    table: {
      minWidth: 650,
    },
    tableHeader: {
      fontSize: '5rem',
    },
  },
  light: {
    header: {
      color: styles.color.purple,
    },
    subheader: {
      color: styles.color.lightGrey,
    },
    links: {
      color: styles.color.purple,
    },
  },
  dark: {
    header: {
      color: styles.color.xLightGrey,
    },
    subheader: {
      color: styles.color.xLightGrey,
    },
    links: {
      color: styles.color.blue,
    },
  },
});

export default PrivacyPage;
