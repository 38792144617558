import React from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

const Wrapper = styled.div`
  border: 2px solid ${styles.color.purple};
  border-radius: 2rem;
  width: auto;
  display: inline-block;
  padding: 0.3rem 1.2rem;
  font-size: 1rem;
  margin: 0 0.3rem 0 0.3rem;
  white-space: nowrap;
`;

const Tag: React.FC = (props) => {
  const { classes } = useStyles();
  return <Wrapper {...props} css={classes.tag} />;
};

const useStyles = makeStyles({
  base: {
    tag: {
      color: styles.color.purple,
      backgroundColor: 'transparent',
      borderColor: styles.color.purple,
    },
  },
  light: {
    tag: {
      color: styles.color.purple,
      backgroundColor: 'transparent',
      borderColor: styles.color.purple,
    },
  },
  dark: {
    tag: {
      color: styles.color.xxLightGrey,
      backgroundColor: styles.color.medDarkPurple,
      borderColor: styles.color.medDarkPurple,
    },
  },
});

export default Tag;
