import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { translateError, ErrorKeys } from 'utils/error';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { UserActionName } from 'state/user/types';
import { closeModal } from 'state/modal/actions';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { setMultifactorAuth } from 'state/user/actions';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

export interface MultifactorAuthModalParams {
  mfaEnabled: boolean;
}

const messages = {
  enable: defineMessages({
    heading: {
      id: 'account.advanced.mfa.modal.enable.heading',
      defaultMessage: 'Enable Two-Factor Auth (2FA)?',
    },
    text: {
      id: 'account.advanced.mfa.modal.enable.text',
      defaultMessage:
        'We are excited that you want to enable two-factor authentication (2FA).\n' +
        'Once enabled, use an app like Google Authenticator to obtain your security code each time you log in.',
    },
  }),
  disable: defineMessages({
    heading: {
      id: 'account.advanced.mfa.modal.disable.heading',
      defaultMessage: 'Disable Two-Factor Auth (2FA)?',
    },
    text: {
      id: 'account.advanced.mfa.modal.disable.text',
      defaultMessage:
        'Are you sure you want to disable two-factor authentication (2FA)?\n' +
        "If so, you'll now be redirected to the PolySwarm authO login page to enter your two factor authentication " +
        'to confirm the new configuration.',
    },
  }),
};

const errorKeys: ErrorKeys = {};

const MultifactorAuthModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(state.requests, [UserActionName.SET_MULTIFACTOR_AUTH]);
  const error = errorSelector(state.requests, [UserActionName.SET_MULTIFACTOR_AUTH]);

  const { mfaEnabled } = useSelector(
    ({ modal }: { modal: ModalState<MultifactorAuthModalParams> }) => modal.params
  );

  const enable = mfaEnabled ? 'enable' : 'disable';

  return (
    <ConfirmModal
      isLoading={isLoading || !!state.user.profile?.mfaChanged}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages[enable].heading)}
      text={intl.formatMessage(messages[enable].text)}
      submitBtnText={intl.formatMessage({ id: `button.${enable}` })}
      onSubmit={() =>
        dispatch(setMultifactorAuth(mfaEnabled, 'REMOVE_MULTIFACTOR_AUTH', location.pathname))
      }
      onCancel={() => dispatch(closeModal())}
      testId='mfaModal'
    />
  );
};

export default MultifactorAuthModal;
