import config from 'config';
import tenant from 'tenants';
import { ThemeState } from 'models/Theme';

export const ENTER_KEY_CODE = 13;
export const SPACE_KEY_CODE = 32;
export const ESC_KEY_CODE = 27;
export const ARROW_LEFT_KEY_CODE = 37;
export const ARROW_RIGHT_KEY_CODE = 39;
export const ARROW_DOWN_KEY_CODE = 40;
export const USER_AVATAR_URL = `${config.apiUrl}/v1/avatars/users`;
export const TEAM_AVATAR_URL = `${config.apiUrl}/v1/avatars/teams`;
export const COMMUNITY_AVATAR_URL = `${config.apiUrl}/v1/avatars/communities`;
export const EMAIL_WHITELIST = ['polyswarm.io', 'swarm.market'];
export const WEBSITE_URL = 'https://polyswarm.io';
export const DOCS_URL = tenant.docsUrl || 'https://docs.polyswarm.io';
export const CONSUMER_DOCS_URL = tenant.docsUrl || `${DOCS_URL}/consumers`;
export const SUPPLIER_DOCS_URL = tenant.docsUrl || `${DOCS_URL}/suppliers`;
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const FULL_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT = 'MMMM dd, yyyy';

/**
 * The app's supported languages.
 */
export const LANGS = {
  'en-US': {
    name: 'English',
    text: 'This page is available in English.',
    buttonText: 'View now',
  },
};

export type LocaleState = keyof typeof LANGS;

/**
 * The app's default language.
 */
export const DEFAULT_LANG: LocaleState = 'en-US';

/**
 * The app's default theme
 */
export const DEFAULT_THEME: ThemeState = 'light';
