// @ts-nocheck
import React, { useReducer, createContext } from 'react';

interface IDownloadItems {
  inProgressItems: string[];
  completedItems: string[];
  pendingItems: string[];
  error: boolean;
}

export const GlobalDownloadProgressContext = createContext<
  {
    dispatch: React.Dispatch;
  } & IDownloadItems
>({
  error: false,
  inProgressItems: [],
  completedItems: [],
  pendingItems: [],
  dispatch: () => null,
});

const initialState = {
  inProgressItems: [],
  completedItems: [],
  pendingItems: [],
  error: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'error': {
      return { ...initialState, error: true };
    }
    case 'add': {
      return { ...initialState, pendingItems: [].concat(action.filename) };
    }
    case 'progress': {
      return {
        ...state,
        pendingItems: state.pendingItems.filter((item) => item !== action.filename),
        inProgressItems: state.inProgressItems.concat(action.filename),
      };
    }
    case 'completed': {
      return {
        ...state,
        inProgressItems: state.inProgressItems.filter((item) => item !== action.filename),
        completedItems: state.completedItems.concat(action.filename),
      };
    }
    default: {
      throw new Error('Action not found');
    }
  }
};

const GlobalDownloadProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalDownloadProgressContext.Provider value={{ ...state, dispatch }}>
      {children}
    </GlobalDownloadProgressContext.Provider>
  );
};
export default GlobalDownloadProvider;
