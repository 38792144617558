import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { ICON_REGISTRY } from './IconBase';
import styles from 'views/styles';

export interface IIcon {
  className?: string;
  name: keyof typeof ICON_REGISTRY;
  title?: string;
  testId?: string;
  disabled?: boolean;
}

const Icon = ({
  disabled,
  className,
  name,
  title,
  testId,
  ...params
}: IIcon & React.SVGProps<SVGSVGElement>) => (
  <svg
    style={
      !!disabled
        ? { cursor: 'not-allowed', fill: styles.color.grey, stroke: styles.color.grey }
        : undefined
    }
    className={`icon-${name} ${className || ''}`}
    css={ownStyle}
    data-testid={testId}
    {...params}
  >
    {title && <title>{title}</title>}
    <use xlinkHref={`#icon-${name}`} />
  </svg>
);

const ownStyle = css`
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
`;

export default Icon;
