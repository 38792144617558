import React, { useEffect } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';
import merge from 'lodash/merge';
import flatMap from 'lodash/flatMap';
import flatMapDeep from 'lodash/flatMapDeep';
import isArray from 'lodash/isArray';
import zipObject from 'lodash/zipObject';
import styles from 'views/styles';
import { ActiveSections, Section } from 'views/components/ActiveSections';
import { SandboxProviders } from 'models/Sandbox';
import AccordionData from 'views/components/Accordion/AccordionData';
import { IAccordionData } from 'views/components/Accordion/AccordionData/AccordionData';
import { useLocation } from 'react-router-dom';

const filterPresentValues = (data: Record<string, any> | undefined) => {
  if (!data) return undefined;
  const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      const filteredValues = value.filter(Boolean);
      if (filteredValues.length) {
        acc[key] = filteredValues;
      }
    } else if (value) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);

  return filteredData;
};

const checkForPresentValues = (data: Record<string, any> | undefined) => {
  if (!data) return false;
  if (Array.isArray(data)) {
    if (data.length === 0) return false;
    return (
      data.length > 0 &&
      data.some((item) => {
        if (typeof item === 'object' && 'data' in item) {
          return item.data.length > 0;
        }
        return Boolean(item);
      })
    );
  } else if (typeof data === 'object') {
    return Object.values(data).some(checkForPresentValues);
  }
  return false;
};

const mapAndFlatten = (data: any) => (mapper: (item: any) => any) => {
  if (!data) return undefined;
  if (Array.isArray(data) && data.length === 0) return undefined;
  return data.map(mapper).filter(Boolean).flat(2);
};

const COBALT_STRIKE = ['BeaconType', 'C2Server', 'Watermark', 'Spawnto_x64'];

export const getExtractedConfigData = (
  data: Record<string, any> | null,
  provider: SandboxProviders
) => {
  const extracted = data?.extracted || [];
  const capeConfigData = data?.cape_config || [];
  const mapExtracted = mapAndFlatten(extracted);
  const providerKey = provider === SandboxProviders.CAPE ? 'cape_sandbox_v2' : 'triage_sandbox_v0';

  const extractedConfigData = {
    malwareConfig: {
      'config.extracted_pe': {
        label: 'Config PE',
        value: mapExtracted((item) => {
          return item?.config?.extracted_pe;
        }),
      },
      'config.campaign': {
        label: 'Campaign ID',
        value: mapExtracted((item) => {
          return item?.config?.campaign;
        }),
      },
      'config.attr.autorun.control': {
        label: 'Autorun Control',
        value: mapExtracted((item) => {
          return (
            item?.config?.attr?.autorun
              // @ts-ignore
              ?.map((item) => item.control)
              .filter(Boolean)
              .join(', ')
          );
        }),
      },
      'config.attr.autorun.name': {
        label: 'Autorun Name',
        value: mapExtracted((item) => {
          return (
            item?.config?.attr?.autorun
              // @ts-ignore
              ?.map((item) => item.name)
              .filter(Boolean)
              .join(', ')
          );
        }),
      },
      'config.attr.autostart_method': {
        label: 'Autostart Method',
        value: mapExtracted((item) => {
          return item?.config?.attr?.autostart_method;
        }),
      },
      'config.attr.InstallPath': {
        label: 'Install Path',
        value: mapExtracted((item) => {
          return item?.config?.attr?.InstallPath;
        }),
      },
      'config.attr.reg_key': {
        label: 'Reg Key',
        value: mapExtracted((item) => {
          return item?.config?.attr?.reg_key;
        }),
      },
      'config.attr.splitter': {
        label: 'Splitter',
        value: mapExtracted((item) => {
          return item?.config?.attr?.splitter;
        }),
      },
      'config.botnet': {
        label: 'Botnet',
        value: mapExtracted((item) => {
          return item?.config?.botnet;
        }),
      },
      'config.mutex': {
        label: 'Mutex',
        value: mapExtracted((item) => {
          return item?.config?.mutex?.join(', ');
        }),
      },
      'config.rule': {
        label: 'Rule',
        value: mapExtracted((item) => {
          return item?.config?.rule;
        }),
      },
      'config.dumped_file': {
        label: 'Dumped File',
        value: mapExtracted((item) => {
          return item?.config?.dumped_file;
        }),
      },
      'config.resource': {
        label: 'Resource',
        value: mapExtracted((item) => {
          return item?.config?.resource;
        }),
      },

      'credentials.username': {
        label: 'C2 Username',
        value: mapExtracted((item) => {
          return item?.credentials?.username;
        }),
      },
    },
    encryptionKeys: (provider === SandboxProviders.TRIAGE && [
      {
        id: `${providerKey}.extracted.config.keys`,
        component: 'table' as const,
        data: extracted.length
          ? // @ts-ignore
            mapExtracted((item) => item.config?.keys).filter(Boolean) || []
          : [],
      },
    ]) as IAccordionData['data'],
    cobaltStrike: [
      provider === SandboxProviders.CAPE && {
        id: `${providerKey}.cobaltStrike`,
        component: 'table' as const,
        // @ts-ignore
        data: filter(capeConfigData, (item) => {
          return Object.keys(item).some((item) => COBALT_STRIKE.includes(item));
        })
          .map((item) =>
            merge(
              zipObject(COBALT_STRIKE, Array(COBALT_STRIKE.length).fill(null)),
              pick(item, COBALT_STRIKE)
            )
          )
          .map((item) =>
            reduce(
              item,
              (acc, value, key) => {
                return { ...acc, [key]: isArray(value) ? flatMap(value).join(', ') : value };
              },
              {}
            )
          ),
      },
    ].filter(Boolean) as IAccordionData['data'],
    extracted: {
      'cape_config.Authorization': {
        label: 'Authorization',
        value: flatMap(
          capeConfigData
            // @ts-ignore
            .map((item) => {
              return item?.Authorization;
            })
            .filter(Boolean)
        ),
      },
      'cape_config.botnet': {
        label: 'BotNet',
        value: flatMap(
          capeConfigData
            // @ts-ignore
            .map((item) => {
              return item?.Botnet ?? item?.botnet;
            })
            .filter(Boolean)
        ),
      },
      'cape_config.Key': {
        label: 'Key',
        value: flatMap(
          capeConfigData
            // @ts-ignore
            .map((item) => {
              return item?.Key;
            })
            .filter(Boolean)
        ),
      },
      'cape_config.Control': {
        label: 'Control',
        value: flatMap(
          capeConfigData
            // @ts-ignore
            .map((item) => {
              return item?.Control;
            })
            .filter(Boolean)
        ),
      },
      'cape_config.controllers': {
        label: 'Controllers',
        value: flatMapDeep(
          capeConfigData
            // @ts-ignore
            .map((item) => {
              return item?.controllers;
            })
            .filter(Boolean)
        ),
      },
    },
  };

  return { extractedConfigData, hasPresentValues: checkForPresentValues(extractedConfigData) };
};

const ExtractedConfigTab = ({
  data,
  provider,
}: {
  data: ReturnType<typeof getExtractedConfigData>['extractedConfigData'];
  provider: SandboxProviders;
}) => {
  const location = useLocation();
  const providerKey = provider === SandboxProviders.CAPE ? 'cape_sandbox_v2' : 'triage_sandbox_v0';

  useEffect(() => {
    if (location.hash) {
      document.querySelector(location.hash)?.scrollIntoView();
    }
  }, [location.hash]);

  return (
    <div css={style.root}>
      {checkForPresentValues(data) ? (
        <>
          <ActiveSections
            sections={
              [
                provider === SandboxProviders.TRIAGE && {
                  label: 'M',
                  title: 'Malware Config',
                  active: checkForPresentValues(data.malwareConfig),
                },
                provider === SandboxProviders.TRIAGE && {
                  label: 'E',
                  title: 'Encryption Keys',
                  active: checkForPresentValues(data.encryptionKeys),
                },
                provider === SandboxProviders.CAPE && {
                  label: 'C',
                  title: 'Cobalt Strike',
                  active: checkForPresentValues(data.cobaltStrike),
                },
                provider === SandboxProviders.CAPE && {
                  label: 'E',
                  title: 'Extracted',
                  active: checkForPresentValues(data.extracted),
                },
              ].filter(Boolean) as Section[]
            }
          />
          {checkForPresentValues(data.malwareConfig) && (
            <AccordionData
              id={`${providerKey}.extracted`}
              key='Malware Config'
              css={style.item}
              title='Malware Config'
              data={filterPresentValues(data.malwareConfig)}
              columns={2}
            />
          )}
          {checkForPresentValues(data.encryptionKeys) ? (
            <AccordionData
              key='Encryption Keys'
              id={`${providerKey}.encryptionKeys`}
              css={style.item}
              title='Encryption Keys'
              data={data.encryptionKeys}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(data.cobaltStrike) ? (
            <AccordionData
              key='Cobalt Strike'
              id={`${providerKey}.cobaltStrike`}
              css={style.item}
              title='Cobalt Strike'
              data={data.cobaltStrike}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(data.extracted) ? (
            <AccordionData
              key='extracted'
              id={`${providerKey}.extracted`}
              css={style.item}
              title='Extracted'
              data={data.extracted}
              columns={2}
            />
          ) : null}
        </>
      ) : (
        'No Extracted Config Data'
      )}
    </div>
  );
};

const style = {
  root: css`
    margin: calc(${styles.spacing.grid} / 2) ${styles.spacing.grid};
  `,
  item: css`
    margin-bottom: ${styles.spacing.grid};
    &:last-child {
      margin-bottom: 0;
    }
  `,
};
export default ExtractedConfigTab;
