import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import styles from 'views/styles';
import { useIntl, defineMessages } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface IToggleButtonProps {
  open: boolean;
  onClick: () => void;
}

const messages = defineMessages({
  open: {
    id: 'developmentResults.openToggle.title',
    defaultMessage: 'View development results',
  },
  close: {
    id: 'developmentResults.closeToggle.title',
    defaultMessage: 'Hide development results',
  },
});

const ToggleButton = ({ open, onClick }: IToggleButtonProps) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const _onClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();

    return false;
  };

  const component = open ? (
    <button title={intl.formatMessage(messages.close)}>
      <KeyboardArrowUpIcon color='primary' />
    </button>
  ) : (
    <button title={intl.formatMessage(messages.open)}>
      <KeyboardArrowDownIcon color='primary' />
    </button>
  );

  return (
    <div css={classes.root} onClick={_onClick} data-cy='toggleDevelopmentResultsDropdown'>
      {component}
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'inline',
      marginLeft: 8,
      '& button': {
        color: styles.color.purple,
        cursor: 'pointer',
        fontSize: '0.32rem !important',
        padding: '0.5rem',
        outline: 'none',
        '&:hover': {
          transition: `background-color ${styles.easing.time} ${styles.easing.main}`,
        },
      },
    },
  },
  light: {
    root: {
      '& button': {
        '&:hover': {
          backgroundColor: styles.color.xxLightPurple,
        },
      },
    },
  },
  dark: {
    root: {
      '& button': {
        '&:hover': {
          backgroundColor: styles.color.xxDarkPurple,
        },
      },
    },
  },
});

export default ToggleButton;
