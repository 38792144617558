import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import Fab from '@material-ui/core/Fab';
import Icon from 'views/components/Icon';

interface Props {
  disabled?: boolean;
  text?: string;
  icon?: 'plus';
  testId?: string;
  onClick?: () => void;
}

const FabButton = ({ disabled, text, icon, testId, onClick }: Props) => (
  <Fab
    variant={text ? 'extended' : 'round'}
    disabled={disabled}
    onClick={onClick}
    aria-label={icon}
    data-testid={testId}
  >
    {text ? <span css={style.text}>{text}</span> : <Icon name={icon || 'plus'} />}
  </Fab>
);

const style = {
  text: css`
    font-size: ${styles.font.size.p1};
    font-weight: ${styles.font.weight.bold};
    text-transform: none;
    padding: 0 ${styles.spacing.xxs};
  `,
};

export default FabButton;
