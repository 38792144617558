import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'views/components/providers/AuthProvider';
import { useUser } from 'views/components/providers/UserProvider';
import { MultifactorAuthModalParams } from './modals/MultifactorAuthModal';
import { getMFAData, removeMFAData } from 'state/auth/actions';
import { openModal } from 'state/modal/actions';
import AdvancedSettings from './AdvancedSettings';

const AdvancedTab = () => {
  const dispatch = useDispatch();
  const { isPasswordUser } = useAuth();
  const { mfaEnabled } = useUser();

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal } = mfaStep;
      if (modal === 'REMOVE_MULTIFACTOR_AUTH') {
        dispatch(openModal(modal));
        removeMFAData();
      }
    }
  });

  const _handleMfaToggle = (_: React.ChangeEvent, mfaEnabled: boolean) =>
    dispatch(
      openModal<MultifactorAuthModalParams>('MULTIFACTOR_AUTH', { mfaEnabled })
    );

  const _handleArchiveUser = () => dispatch(openModal('ARCHIVE_USER'));

  return (
    <AdvancedSettings
      isPasswordUser={isPasswordUser}
      isMfaEnabled={mfaEnabled}
      onMfaToggle={_handleMfaToggle}
      onArchiveUser={_handleArchiveUser}
    />
  );
};

export default AdvancedTab;
