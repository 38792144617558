import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-search' viewBox='0 0 32 32'>
      <path d='M21.46 23.974c-4.874 3.654-11.82 3.265-16.253-1.168-4.86-4.86-4.86-12.739 0-17.599s12.739-4.86 17.599 0c4.433 4.433 4.822 11.379 1.168 16.253l7.632 7.632-2.514 2.514-7.632-7.632zM20.292 20.292c3.471-3.471 3.471-9.099 0-12.571s-9.099-3.471-12.571 0c-3.471 3.471-3.471 9.099 0 12.571s9.099 3.471 12.571 0z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-search {
          width: 2.1em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
