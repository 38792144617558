import React, { Fragment } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { IUpdateTeamMemberValues, TeamRole } from 'models/Team';
import { teamRoleOptions } from 'state/utils/team';
import { btnMessages } from 'views/components/Button';
import { useUser } from 'views/components/providers/UserProvider';
import styles from 'views/styles';
import FormButtonBar from 'views/components/form/CustomForm/FormButtonBar';
import CustomForm from 'views/components/form/CustomForm';
import CheckboxArrayField from 'views/components/form/CheckboxArrayField';

interface IUpdateTeamMemberForm {
  className?: string;
  initialValues: IUpdateTeamMemberValues;
  heading: string;
  isLoading: boolean;
  errorMessage?: string | null;
  onSubmit: (
    values: IUpdateTeamMemberValues,
    actions: FormikHelpers<IUpdateTeamMemberValues>
  ) => void;
  onCancel: () => void;
}

const isUserAllowedToChangeRole = (role: TeamRole, userRoles: TeamRole[]) => {
  if (userRoles.includes('teamOwner')) {
    return true;
  } else if (userRoles.includes('teamAdmin')) {
    if (role === 'teamOwner' || role === 'teamAdmin') {
      return false;
    }
    return true;
  }
  return false;
};

const UpdateTeamMemberForm = ({
  className,
  initialValues,
  heading,
  isLoading,
  errorMessage,
  onSubmit,
  onCancel,
}: IUpdateTeamMemberForm) => {
  const user = useUser();
  const intl = useIntl();

  const accountNumber = user?.context?.accountNumber || 0;
  const userTeam = user.teams.find((team) => team.accountNumber === accountNumber);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(formikProps: FormikProps<IUpdateTeamMemberValues>) => (
        <CustomForm
          testId='teamMemberForm'
          className={className}
          isLoading={isLoading}
          heading={heading}
          renderFields={() => (
            <Fragment>
              <CheckboxArrayField
                className='h-mb-sm'
                name='roles'
                options={teamRoleOptions(intl).filter((role) =>
                  isUserAllowedToChangeRole(role.value, (userTeam?.roles as TeamRole[]) || [])
                )}
                values={formikProps.values.roles}
                onChange={formikProps.setFieldValue}
              />
              {errorMessage && (
                <p className='p1 h-mb-xs' style={{ color: styles.color.red }}>
                  {errorMessage}
                </p>
              )}
            </Fragment>
          )}
          renderButtonBar={() => (
            <FormButtonBar
              submitBtnText={intl.formatMessage(btnMessages.update)}
              onCancel={() => {
                formikProps.resetForm();
                onCancel();
              }}
            />
          )}
        />
      )}
    </Formik>
  );
};

export default UpdateTeamMemberForm;
