import React from 'react';
import ResponsiveXYFrame from 'semiotic/lib/ResponsiveXYFrame';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

type tooltipValue = {
  date: string;
  count: number;
};

type frameProps = {
  theme: string;
  data: any;
  x: string;
  y: string;
  Tooltip: ({ date, count }: tooltipValue) => React.ReactNode;
};

const getFrameProps = ({ theme, data, x, y, Tooltip }: frameProps) => ({
  xAccessor: x,
  yAccessor: y,
  lines: data,
  size: [400, 190],
  responsiveWidth: true,
  margin: { top: 40, left: 30, right: 10, bottom: 40 },
  yExtent: [0],
  lineStyle: {
    stroke: theme === 'dark' ? styles.color.lightBlue : styles.color.purple,
    strokeWidth: 3,
    fill: 'none',
  },
  pointStyle: { fill: theme === 'dark' ? styles.color.lightBlue : styles.color.purple, r: 5 },
  showLinePoints: true,
  hoverAnnotation: true,
  tooltipContent: ({ date, count }: tooltipValue) => Tooltip({ date, count }),
});

const LineChart = ({ data, x, y, Tooltip }: Omit<frameProps, 'theme'>) => {
  const { theme } = useStyles();
  return <ResponsiveXYFrame {...getFrameProps({ theme, data, x, y, Tooltip })} />;
};

const useStyles = makeStyles(() => ({
  base: {},
}));

export default LineChart;
