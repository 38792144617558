import React from 'react';
import { Switch, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import joinUrl from 'utils/joinUrl';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import ProfileTab from './AccountTabs/ProfileTab';
import ApiKeysTab from './AccountTabs/ApiKeysTab';
import WebhooksTab from './AccountTabs/WebhooksTab';
import { AccountUsageTab } from './AccountTabs/AccountUsageTab';
import { WalletsTab } from './AccountTabs/WalletsTab';
import { RewardsTab } from './AccountTabs/RewardsTab';
import AdvancedTab from './AccountTabs/AdvancedTab';
import { useIsPageEnabled } from 'hooks/useIsPageEnabled';
import { Route, Tab } from 'tenants/components';

const messages = defineMessages({
  profile: {
    id: 'account.profile',
    defaultMessage: 'Profile',
  },
  apiKeys: {
    id: 'account.apiKeys',
    defaultMessage: 'API Keys',
  },
  webhooks: {
    id: 'account.webhooks',
    defaultMessage: 'Webhooks',
  },
  usage: {
    id: 'account.usage',
    defaultMessage: 'Usage',
  },
  wallets: {
    id: 'account.wallets',
    defaultMessage: 'Wallets',
  },
  rewards: {
    id: 'account.rewards',
    defaultMessage: 'Rewards',
  },
  advanced: {
    id: 'account.advanced',
    defaultMessage: 'Advanced',
  },
});

const AccountPanel = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const page = useIsPageEnabled();

  const urls = {
    profile: joinUrl(match.url, 'profile'),
    apiKeys: joinUrl(match.url, 'api-keys'),
    webhooks: joinUrl(match.url, 'webhooks'),
    usage: joinUrl(match.url, 'usage'),
    billing: joinUrl(match.url, 'billing'),
    myPlan: joinUrl(match.url, 'my-plan'),
    wallets: joinUrl(match.url, 'wallets'),
    rewards: joinUrl(match.url, 'rewards'),
    notifications: joinUrl(match.url, 'notifications'),
    advanced: joinUrl(match.url, 'advanced'),
  };

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) => history.push(pathname);

  return (
    <Card>
      <Tabs indicatorColor='primary' value={location.pathname} onChange={_handleChange}>
        <Tab
          value={urls.profile}
          label={intl.formatMessage(messages.profile)}
          data-cy='accountProfile'
          show={page.isEnabled('profile')}
        />
        <Tab
          value={urls.apiKeys}
          label={intl.formatMessage(messages.apiKeys)}
          data-cy='accountApiKeys'
          show={page.isEnabled('api-keys')}
        />
        <Tab
          value={urls.webhooks}
          label={intl.formatMessage(messages.webhooks)}
          data-cy='accountWebhooks'
          show={page.isEnabled('webhooks')}
        />
        <Tab
          value={urls.usage}
          label={intl.formatMessage(messages.usage)}
          data-cy='accountUsage'
          show={page.isEnabled('usage')}
        />
        <Tab
          value={urls.wallets}
          label={intl.formatMessage(messages.wallets)}
          data-cy='wallets'
          show={page.isEnabled('wallets')}
        />
        <Tab
          value={urls.rewards}
          label={intl.formatMessage(messages.rewards)}
          data-cy='rewards'
          show={page.isEnabled('rewards')}
        />
        {/* <Tab
          value={urls.notifications}
          label={intl.formatMessage(messages.notifications)}
          data-cy='accountNotifications'
        /> */}
        <Tab
          value={urls.advanced}
          label={intl.formatMessage(messages.advanced)}
          data-cy='accountAdvanced'
          show={page.isEnabled('advanced')}
        />
      </Tabs>
      <Panel>
        <Switch>
          <Route
            exact
            path={urls.profile}
            show={page.isEnabled('profile')}
            component={ProfileTab}
          />
          <Route
            exact
            path={urls.apiKeys}
            show={page.isEnabled('api-keys')}
            component={ApiKeysTab}
          />
          <Route
            exact
            path={urls.webhooks}
            show={page.isEnabled('webhooks')}
            component={WebhooksTab}
          />
          <Route
            exact
            path={urls.usage}
            component={AccountUsageTab}
            show={page.isEnabled('usage')}
          />
          <Route
            exact
            path={urls.wallets}
            component={WalletsTab}
            show={page.isEnabled('wallets')}
          />
          <Route
            exact
            path={urls.rewards}
            component={RewardsTab}
            show={page.isEnabled('rewards')}
          />
          <Route
            exact
            path={urls.advanced}
            component={AdvancedTab}
            show={page.isEnabled('advanced')}
          />
          <Redirect exact from={match.url} to={urls.profile} />
          <Redirect to='/404' />
        </Switch>
      </Panel>
    </Card>
  );
};

export default AccountPanel;
