import React, { useState, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import ContactContainer from './ContactContainer';
import { ContactDialogTrigger } from './ContactDialogTrigger';
import { IContactFormValues } from './ContactForm';
import { CheckboxOption } from 'views/pages/AuthCallbackPage/AccountSignupForm/CheckboxArrayField';

interface IContactDialogProps {
  trigger?: any;
  options?: CheckboxOption[];
  withOptions?: boolean;
  withMessage?: boolean;
  defaultValues?: IContactFormValues;
}

export const ContactDialog = ({
  trigger,
  options,
  withOptions,
  withMessage,
  defaultValues,
}: IContactDialogProps) => {
  const [open, setOpen] = useState(false);

  const _onClick = () => {
    setOpen(true);
  };

  const _onClose = () => {
    setOpen(false);
  };

  const Trigger = trigger || ContactDialogTrigger;

  return (
    <Fragment>
      <Trigger onClick={_onClick} />
      <Dialog open={open} onClose={_onClose}>
        <ContactContainer
          onClose={_onClose}
          options={options}
          withOptions={withOptions}
          withMessage={withMessage}
          defaultValues={defaultValues}
        />
      </Dialog>
    </Fragment>
  );
};
