import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { NormalizedArtifactMetadata, Artifacts } from 'models/Submission';
import MetadataSearchTableRow from './MetadataSearchTableRow';
import TableCell from '@material-ui/core/TableCell';
import { IColumnSort } from 'views/components/table/InfiniteScrollTable';
import metadataMessages from 'views/components/Accordion/AccordionFileDetails/metadataMessages';
import { Row, RowGeneric } from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import CustomTable from 'views/components/table/CustomTable';
import {
  CustomTablePagination,
  EPageDirection,
} from 'views/components/table/CustomTablePagination';
import { generalMessages } from 'views/index';

interface IMetadataSearchTableProps {
  isFirst: boolean;
  isLast: boolean;
  metadata: Artifacts;
  results: NormalizedArtifactMetadata[];
  rowsPerPage: number;
  onChangePage: (direction: EPageDirection) => void;
  onChangeRowsPerPage: (limit: number) => void;
  viewScanResult: (hash: string) => void;
  downloadArtifact: (hash: string) => void;
  sort?: IColumnSort<keyof NormalizedArtifactMetadata>;
  onSort?: (orderBy: keyof NormalizedArtifactMetadata) => void;
  onLoadMore?: () => Promise<void>;
  columns: string[];
}

const messages = defineMessages({
  scanDate: {
    id: 'table.row.scanDate',
    defaultMessage: 'First Seen',
  },
  lastScanDate: {
    id: 'table.row.lastScanDate',
    defaultMessage: 'Last Seen',
  },
  fileType: {
    id: 'table.row.fileType',
    defaultMessage: 'File Type',
  },
  fileSize: {
    id: 'table.row.fileSize',
    defaultMessage: 'File Size',
  },
  detections: {
    id: 'table.row.detections',
    defaultMessage: 'Detections',
  },
});

const rows: RowGeneric[] = [
  {
    id: 'sha256',
    numeric: false,
    label: metadataMessages.hash.id,
    sortable: false,
  },
  {
    id: 'malware_family',
    numeric: false,
    label: generalMessages.malwareFamily.id,
    sortable: false,
  },
  {
    id: 'submitted',
    numeric: false,
    label: messages.scanDate.id,
    sortable: false,
  },
  {
    id: 'last_seen',
    numeric: false,
    label: messages.lastScanDate.id,
    sortable: false,
  },
  {
    id: 'polyscore',
    numeric: false,
    label: 'polyscore',
    sortable: false,
  },
  {
    id: 'detections',
    numeric: false,
    label: messages.detections.id,
    sortable: false,
  },
  {
    id: 'file_size',
    numeric: false,
    label: messages.fileSize.id,
    sortable: false,
  },
  {
    id: 'file_type',
    numeric: false,
    label: messages.fileType.id,
    sortable: false,
  },
];

const MetadataSearchTable = ({
  results,
  viewScanResult,
  downloadArtifact,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  isFirst,
  isLast,
  columns,
}: IMetadataSearchTableProps) => {
  const intl = useIntl();

  return (
    <CustomTable<NormalizedArtifactMetadata>
      rows={results}
      renderTableHead={() => (
        <CustomTableHead
          rows={
            columns
              .map((column) => {
                const row = rows.filter((row) => row.id === column);
                if (row.length > 0) {
                  return row[0];
                }
                return null;
              })
              .filter(Boolean) as Row<NormalizedArtifactMetadata>[]
          }
          showActions={true}
          renderHeaderCell={(row) => (
            <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
              {intl.formatMessage({ id: row.label })}
            </TableCell>
          )}
        />
      )}
      renderTableRow={(row: NormalizedArtifactMetadata) => (
        <MetadataSearchTableRow
          key={row.sha256}
          columns={columns}
          rowData={row}
          viewScanResult={viewScanResult}
          downloadArtifact={downloadArtifact}
        />
      )}
      renderPagination={
        <CustomTablePagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100, 200]}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          isFirst={isFirst}
          isLast={isLast}
        />
      }
    />
  );
};

export default MetadataSearchTable;
