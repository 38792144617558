import { MessageDescriptor } from 'react-intl';
import { RootAction } from 'state/root';
import { Thunk, Dispatch } from 'state/types/thunk';
import { startRequest, finishRequest } from 'state/requests/actions';
import { showNotification } from 'state/notification/actions';

export type NotifyObject = {
  success?: MessageDescriptor;
  failure?: MessageDescriptor;
};

let requestCounter = 0;

/**
 * Asynchronous request action.
 * Dispatches start and finish actions when a request is made.
 * Each request is tracked by a monotonically-increasing identifier.
 */
const request =
  <R, A extends RootAction>(
    thunk: Thunk<Promise<R>>,
    action: A,
    notifyObject?: NotifyObject | false
  ) =>
  async (dispatch: Dispatch) => {
    // Don't increase requestId in test environment as to not create side effects.
    if (process.env.NODE_ENV !== 'test') {
      requestCounter = requestCounter + 1;
    }
    const requestId = requestCounter;

    dispatch(startRequest(requestId, action));

    try {
      const result = await dispatch(thunk);
      dispatch({ ...action, result });
      dispatch(finishRequest(requestId));
      notifyObject &&
        notifyObject.success &&
        dispatch(showNotification({ status: 'success', message: notifyObject.success }));
      return result;
    } catch (error: any) {
      dispatch({ ...action, error });
      dispatch(finishRequest(requestId, error));
      notifyObject &&
        notifyObject.failure &&
        dispatch(showNotification({ status: 'failure', message: notifyObject.failure }));
      throw error;
    }
  };

export default request;
