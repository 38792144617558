import { ETagsActionName, TagsAction } from './types';
import { TagsState } from '.';

export const initialState: TagsState = {
  threats: undefined,
};

const tags = (state: TagsState = initialState, action: TagsAction): TagsState => {
  switch (action.type) {
    // Handle emerging threats request
    case ETagsActionName.GET_EMERGING_THREATS: {
      return {
        ...state,
        threats: action.result,
      };
    }

    default: {
      return state;
    }
  }
};

export default tags;
