import React, { useCallback, RefObject, Fragment, forwardRef, PropsWithChildren } from 'react';
import { useDropzone } from 'react-dropzone';
import { onEnterOrSpaceKeyDown } from 'views/components/utils';

interface Props {
  className?: string;
  id: string;
  tabIndex?: number;
  ref?: RefObject<HTMLLabelElement>;
  onSelection: (files: File[]) => void;
  onClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  accept?: string;
}

const FileSelect = forwardRef<any, PropsWithChildren<Props>>(
  ({ className, id, tabIndex, onSelection, onClick, children, accept }, ref) => {
    const onDrop = useCallback(
      (files: File[]) => {
        return onSelection(files);
      },
      [onSelection]
    );
    const { getInputProps, inputRef } = useDropzone({ onDrop });
    const open = () => inputRef.current && inputRef.current.click();

    return (
      <Fragment>
        <input
          id={id}
          style={{ display: 'none' }}
          {...getInputProps()}
          multiple={false}
          accept={accept}
        />
        <label
          className={className}
          htmlFor={id}
          ref={ref}
          role='button'
          tabIndex={tabIndex}
          onKeyDown={onEnterOrSpaceKeyDown(open)}
          onClick={onClick}
        >
          {children}
        </label>
      </Fragment>
    );
  }
);

export default FileSelect;
