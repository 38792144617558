import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Slide from '@material-ui/core/Slide';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';

interface INotification {
  children: React.ReactNode;
  id: string;
  show?: boolean;
  delay?: number;
}

const getNotificationActivatedById = (id: string) => {
  const notification = localStorage.getItem(`NOTIFICATION_ACTIVATED_${id}`);
  return notification ? JSON.parse(notification) : true;
};

const setNotificationActivatedById = (id: string, value: boolean) => {
  localStorage.setItem(`NOTIFICATION_ACTIVATED_${id}`, JSON.stringify(value));
};

export const Notification = ({ children, id, show = true, delay = 1000 }: INotification) => {
  const { classes } = useStyles();
  const [isShown, setIsShown] = useState(false);

  const handleClose = () => {
    setNotificationActivatedById(id, false);
    setIsShown(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const isNotificationActivated = getNotificationActivatedById(id);
      setIsShown(isNotificationActivated && show);
    }, delay);
    return () => clearTimeout(timeout);
  }, [id, show, delay]);

  return (
    <Slide
      direction='left'
      in={isShown}
      timeout={{ appear: 500, enter: 500, exit: 500 }}
      mountOnEnter
      unmountOnExit>
      <div css={classes.container}>
        <div>{children}</div>
        <div onClick={handleClose}>
          <Icon css={classes.iconClose} name='close' />
        </div>
      </div>
    </Slide>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      position: 'absolute',
      bottom: '1.5rem',
      right: '1.5rem',
      zIndex: 999,
      padding: '1.5rem 3rem',
      borderRadius: '4rem',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'position 1s ease-in-out',
    },
    iconClose: {
      fontSize: '0.5rem',
      color: styles.color.xLightGrey,
      marginLeft: '2rem',
      marginTop: '0.5rem',
      cursor: 'pointer',
    },
  },
  light: {
    container: {
      border: `3px solid ${styles.color.purple}`,
      backgroundColor: styles.color.white,
    },
  },
  dark: {
    container: {
      border: `3px solid ${styles.color.lightBlue}`,
      backgroundColor: styles.color.xxDarkPurple,
    },
  },
});
