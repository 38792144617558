import { SandboxProviders } from 'models/Sandbox';
import styles from 'views/styles';

export const SCORE_MAP = {
  [SandboxProviders.CAPE]: [
    ['0-3', 'Benign', styles.color.black, styles.color.white],
    ['4-6', 'Suspicious', styles.color.darkYellow, styles.color.yellow],
    ['7-9', 'Malicious', styles.color.darkRed, styles.color.lightRed],
    ['10', 'Malicious', styles.color.darkRed, styles.color.lightRed],
  ],
  [SandboxProviders.TRIAGE]: [
    ['N/A', 'Not Available', styles.color.black, styles.color.white],
    [
      '1',
      'No (potentially) malicious behavior was detected.',
      styles.color.black,
      styles.color.white,
    ],
    ['2-5', 'Likely benign', styles.color.black, styles.color.white],
    ['6-7', 'Shows suspicious behavior', styles.color.darkYellow, styles.color.yellow],
    ['8-9', 'Likely malicious', styles.color.darkRed, styles.color.lightRed],
    ['10', 'Known bad', styles.color.darkRed, styles.color.lightRed],
  ],
};
