import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from 'state/auth/actions';
import { PRICING_SIDEBAR_STORAGE_KEY } from './ContactSteps';
import { useAuth } from 'views/components/providers/AuthProvider';
import ContactConfirm from './ContactConfirm';

const ContactConfirmContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  const _onCreateAccount = () => {
    let email = undefined;

    const savedUserDataRaw = localStorage.getItem(PRICING_SIDEBAR_STORAGE_KEY);
    if (savedUserDataRaw) {
      const savedUserData = JSON.parse(savedUserDataRaw);
      email = savedUserData.email;
    }

    dispatch(login(location.pathname, email));
  };

  const _onScan = () => {
    history.push('/scan');
  };

  return (
    <ContactConfirm
      onScan={_onScan}
      onCreateAccount={_onCreateAccount}
      isAuthenticated={isAuthenticated}
    />
  );
};

export default ContactConfirmContainer;
