import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface Props {
  className?: string;
  percentage: number;
}

const Progress = ({ className, percentage }: Props) => {
  const { classes } = useStyles();

  const dashArray = (percentage / 100) * 200 * Math.PI;

  return (
    <div className={className} css={classes.root}>
      <span css={classes.percentage}>{percentage}%</span>
      <svg
        css={classes.progress}
        viewBox='0 0 212 212'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g transform='translate(-30.000000, -30.000000)' strokeWidth='12'>
            <circle
              css={classes.circle}
              stroke={styles.color.lightPurple}
              opacity='0.200000003'
              cx='136'
              cy='136'
              r='100'
            />
            <path
              css={classes.path}
              stroke={styles.color.purple}
              strokeLinecap='round'
              strokeDasharray={`${dashArray}, 9999`}
              transform='translate(136.000000, 136.000000) rotate(-152.000000) translate(-136.000000, -136.000000)'
              d='M136,236 C191.228475,236 236,191.228475 236,136 C236,80.771525 191.228475,36 136,36 C80.771525,36 36,80.771525 36,136 C36,191.228475 80.771525,236 136,236 Z'
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'inline-block',
      maxWidth: '20rem',
      maxHeight: '20rem',
      position: 'relative',
    },
    progress: {
      width: '100%',
      transform: 'scaleX(-1) rotate(-29deg)',
    },
    circle: {
      opacity: 0.2,
    },
    path: {
      transition: `stroke-dasharray 0.4s ${styles.easing.authenticMotion}`,
    },
    percentage: {
      position: 'absolute',
      top: 'calc(50% + 0.3rem)',
      left: 'calc(50% + 0.3rem)',
      transform: 'translate(-50%, -50%)',
      fontWeight: styles.font.weight.black,
      fontSize: '5.2rem',
    },
  },
  light: {
    circle: {
      stroke: styles.color.purple,
    },
    path: {
      stroke: styles.color.purple,
    },
    percentage: {
      color: styles.color.purple,
    },
  },
  dark: {
    circle: {
      stroke: styles.color.lightBlue,
    },
    path: {
      stroke: styles.color.lightBlue,
    },
    percentage: {
      color: styles.color.lightBlue,
    },
  },
});

export default Progress;
