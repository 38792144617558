import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Table from 'views/components/Accordion/AccordionData/components';
import { UseTelemetrySummary } from '../hooks/useRewardsSummary';
import { defineMessages, useIntl } from 'react-intl';
import { DistributionSummaryRes } from 'services/api/schema/wallets';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { FULL_DATE_FORMAT } from 'utils/constants';
import { toNCT } from 'utils/nct';

interface IRewardsBreakdown {
  summary: UseTelemetrySummary;
}

const messages = defineMessages({
  nothingToDisplay: {
    id: 'breakdown.nothingToDisplay',
    defaultMessage: 'Nothing to display',
  },
});

export const RewardsBreakdown = ({ summary }: IRewardsBreakdown) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { rewardsSummary } = summary;

  const getRewardsData = () => {
    const data = rewardsSummary?.distributions?.map((distribution: DistributionSummaryRes) => {
      return {
        amount: String(toNCT(parseInt(distribution.amount, 10))),
        created_at: formatDate(parseDate(distribution.created_at), FULL_DATE_FORMAT),
        earned_at: distribution.earned_at,
        category_name:
          rewardsSummary.categories &&
          rewardsSummary.categories[distribution.category].category_name,
      };
    });

    if (data?.length === 0) {
      data.push({
        amount: intl.formatMessage(messages.nothingToDisplay),
        earned_at: ' - ',
        category_name: ' - ',
        created_at: ' - ',
      });
    }
    return data;
  };

  return (
    <div css={classes.container}>
      <Table
        columns={{
          amount: 'Amount(NCT)',
          earned_at: 'Earned On',
          category_name: 'Category',
          created_at: 'Rewarded On',
        }}
        data={getRewardsData()}
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      width: '94%',
      margin: '1rem auto',
    },
    empty: {
      width: '100%',
    },
  },
  light: {},
  dark: {},
});
