import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import TabPanel from 'views/components/layout/TabPanel';
import { ITabSection } from 'views/components/layout/TabPanel/TabPanel';
import { SubmitTab, CompeteTab, VerdictTab, GroundTruthTab, RewardTab } from './HowItWorksTabs';
import {
  SubmitSection,
  CompeteSection,
  VerdictSection,
  GroundTruthSection,
  RewardSection,
} from './HowItWorksSections';

const sections: ITabSection[] = [
  {
    id: 'submit',
    tab: (props) => <SubmitTab {...props} />,
    section: <SubmitSection />,
  },
  {
    id: 'compete',
    tab: (props) => <CompeteTab {...props} />,
    section: <CompeteSection />,
  },
  {
    id: 'verdict',
    tab: (props) => <VerdictTab {...props} />,
    section: <VerdictSection />,
  },
  {
    id: 'ground-truth',
    tab: (props) => <GroundTruthTab {...props} />,
    section: <GroundTruthSection />,
  },
  {
    id: 'reward',
    tab: (props) => <RewardTab {...props} />,
    section: <RewardSection />,
  },
];

const ownStyle = css`
  ul {
    text-align: center;
  }
  a,
  section {
    outline: none;
  }
`;

const HowItWorksTabPanel = () => (
  <TabPanel css={ownStyle} sections={sections} scrollToSection={true} autoChangeTimeMS={12000} />
);

export default HowItWorksTabPanel;
