import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-copy' viewBox='0 0 30 32'>
      <path d='M7.111 4.571v15.746c0 2.525 2.047 4.571 4.571 4.571h12.19c2.525 0 4.571-2.047 4.571-4.571v-15.746c0-2.525-2.047-4.571-4.571-4.571h-12.19c-2.525 0-4.571 2.047-4.571 4.571zM22.603 21.333h-9.651c-1.262 0-2.286-1.023-2.286-2.286v-13.206c0-1.262 1.023-2.286 2.286-2.286h9.651c1.262 0 2.286 1.023 2.286 2.286v13.206c0 1.262-1.023 2.286-2.286 2.286zM1.778 7.111v0c-0.982 0-1.778 0.796-1.778 1.778v18.54c0 2.525 2.047 4.571 4.571 4.571h14.984c0.982 0 1.778-0.796 1.778-1.778v0c0-0.982-0.796-1.778-1.778-1.778h-13.714c-1.262 0-2.286-1.023-2.286-2.286v-17.27c0-0.982-0.796-1.778-1.778-1.778z' />
    </symbol>

    <Global
      styles={css`
        .icon-copy {
          width: 3em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
