import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import coins2Img from '../shared/coins2.svg';
import coins3Img from '../shared/coins3.svg';
import coins5Img from '../shared/coins5.svg';

const Payout = ({
  bid,
  direction = 'bottom',
  showSign,
}: {
  bid?: number;
  direction?: 'top' | 'bottom';
  showSign?: boolean;
}) => {
  const { classes } = useStyles();
  return (
    <div className='payout' css={classes.root}>
      {bid && direction === 'top' ? <span>{`${showSign ? '+' : ''}${bid}`}</span> : null}
      {bid && bid > 0 && (
        <img
          src={bid === 5 || bid === 8 ? coins5Img : bid === 3 ? coins3Img : coins2Img}
          alt='coins'
        />
      )}
      {bid && direction === 'bottom' ? <span>{`${showSign ? '+' : ''}${bid}`}</span> : null}
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: styles.font.weight.medium,
      position: 'relative',
      width: 16,
    },
  },
  light: {
    root: {
      color: styles.color.purple,
    },
  },
  dark: {
    root: {
      color: styles.color.lightBlue,
    },
  },
});

export default Payout;
