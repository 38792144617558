import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-arrow' viewBox='0 0 51 32'>
      <path d='M24.96 30.080c0 0 0 0 0 0-1.28 0-1.92-0.64-3.2-1.28l-18.56-19.2c-1.28-1.92-1.28-4.48 0-5.76 1.92-1.28 4.48-1.28 5.76 0l15.36 16 17.28-16c1.92-1.28 4.48-1.28 5.76 0 1.28 1.92 1.28 4.48 0 5.76l-19.84 19.2c-0.64 0.64-1.92 1.28-2.56 1.28z' />
    </symbol>

    <Global
      styles={css`
        .icon-arrow {
          width: 5.1em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
