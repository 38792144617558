import React from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { TableCell } from '@material-ui/core';
import { Page } from 'models/Page';
import { ILimitedAccessMember } from 'models/Team';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import CustomTableHead from 'views/components/table/CustomTableHead';
import CustomTable from 'views/components/table/CustomTable';
import { Row } from 'views/components/table/CustomTable';
import { IColumnSort } from 'views/components/table/InfiniteScrollTable';
import TeamLimitedAccessRow from './TeamLimitedAccessRow';

interface TeamLimitedAccessTableProps {
  teamAccountNumber: number | string;
  teamMembers: Page<ILimitedAccessMember>;
  sort?: IColumnSort<keyof ILimitedAccessMember>;
  onSort?: (orderBy: keyof ILimitedAccessMember) => void;
  archiveApiKey: (id: string, key: string) => void;
  onLoadMore?: () => Promise<void>;
}

const TeaamLimitedAccessTable = ({
  teamAccountNumber,
  teamMembers,
  archiveApiKey,
}: TeamLimitedAccessTableProps) => {
  const { classes } = useStyles();

  const rows: Row<ILimitedAccessMember>[] = [
    {
      id: 'user.email',
      numeric: false,
      label: 'Email',
      sortable: true,
      width: 250,
    },
    {
      id: 'account_number',
      numeric: true,
      label: 'Team',
      sortable: false,
      width: 200,
    },
    {
      id: 'community',
      numeric: false,
      label: 'Is Private',
      sortable: true,
      width: 200,
    },
    {
      id: 'expiration',
      numeric: false,
      label: 'Expiration Date',
      sortable: true,
    },
  ];

  return (
    <>
      <CustomTable<ILimitedAccessMember>
        rows={teamMembers.results}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            actionAlign='left'
            renderHeaderCell={(row) => {
              return (
                <TableCell width={row.width} key={row.id} align={row.numeric ? 'right' : 'left'}>
                  <div css={classes.cellContainer}>{row.label}</div>
                </TableCell>
              );
            }}
          />
        )}
        renderTableRow={(row: ILimitedAccessMember) => {
          return (
            <TeamLimitedAccessRow
              teamAccountNumber={teamAccountNumber}
              key={row.account_number}
              rowData={row}
              teamMembers={teamMembers}
              archiveApiKey={archiveApiKey}
            />
          );
        }}
      />
    </>
  );
};

const useStyles = makeStyles({
  base: {
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
  },
  light: {},
  dark: {},
});
export default TeaamLimitedAccessTable;
