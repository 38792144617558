import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-eye' viewBox='0 0 32 32'>
      <path d='M16 6.463c-6.114 0-11.658 3.345-15.75 8.778-0.334 0.445-0.334 1.067 0 1.512 4.091 5.44 9.636 8.785 15.75 8.785s11.658-3.345 15.75-8.778c0.334-0.445 0.334-1.067 0-1.512-4.091-5.44-9.636-8.785-15.75-8.785zM16.439 22.716c-4.059 0.255-7.41-3.090-7.155-7.155 0.209-3.352 2.926-6.068 6.278-6.278 4.058-0.255 7.41 3.090 7.155 7.155-0.216 3.345-2.933 6.062-6.278 6.278zM16.236 19.613c-2.186 0.137-3.993-1.663-3.849-3.849 0.111-1.807 1.578-3.266 3.384-3.384 2.186-0.137 3.993 1.663 3.849 3.849-0.118 1.813-1.584 3.273-3.384 3.384z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-eye {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
