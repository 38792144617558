import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { defineMessages, useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import { openModal } from 'state/modal/actions';
import { RulesetModalParams } from 'views/pages/HuntPage/modals/RulesetModal';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import FabButton from 'views/components/Button/FabButton';
import ability from 'config/ability';

const messages = defineMessages({
  createRuleset: {
    id: 'huntTabs.newButton.createRuleset',
    defaultMessage: 'Create New Ruleset',
  },
});

export const NewButton = () => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const hasRulesetAbility =
    ability.can('live_hunt', 'Artifact') || ability.can('historical_hunt', 'Artifact');

  const _createRuleset = () =>
    dispatch(
      openModal<RulesetModalParams>('ADD_EDIT_RULESET', {
        refresh: true,
        queryParams: {},
      })
    );

  return hasRulesetAbility ? (
    <Tooltip title={formatMessage(messages.createRuleset)} placement='top'>
      <div css={classes.root}>
        <FabButton icon='plus' onClick={_createRuleset} testId='addRulesetFab' />
      </div>
    </Tooltip>
  ) : null;
};

const useStyles = makeStyles({
  base: {
    root: {
      position: 'absolute',
      right: '5rem',
      top: 15,
    },
  },
});
