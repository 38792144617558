import React, { Fragment, FC } from 'react';
import styled from '@emotion/styled';
import Tooltip from '@material-ui/core/Tooltip';
import styles from 'views/styles';

interface ITooltipTableCell {
  hints: string[];
}

const Hint = styled.div`
  display: block;
  font-size: ${styles.font.size.p1};
  color: ${styles.color.black};
  font-weight: ${styles.font.weight.medium};
  text-decoration: none;
  padding: 0.4rem;
  min-width: 10rem;
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:last-child {
    padding-bottom: 0;
  }
`;

const TooltipTableCell: FC<ITooltipTableCell> = ({ hints, children }) => {
  if (!hints.length) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Tooltip
      placement='bottom'
      title={hints.map((item, idx) => (
        <Hint key={idx}>
          <span>{item}</span>
        </Hint>
      ))}
      interactive>
      <span>{children}</span>
    </Tooltip>
  );
};

export default TooltipTableCell;
