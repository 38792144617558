const KB = 1024;

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes <= 0) return '0 Bytes';
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(KB));
  return `${parseFloat((bytes / Math.pow(KB, i)).toFixed(dm))} ${sizes[i]}`;
};

export const toBytes = (formattedBytes: string) => {
  const parts = formattedBytes.split(' ');
  const value = parseFloat(parts[0]);
  const unit = parts[1];
  const index = sizes.indexOf(unit);
  return Math.ceil(value * Math.pow(KB, index));
};

export default formatBytes;
