import { Ability, AbilityBuilder } from '@casl/ability';
import { store } from 'state/store';
import { RootState } from 'state/root';
import { UserState } from 'state/user';
import { TeamState } from 'state/team';
import { isUserOfTeamRole, userCanAccessFeature } from 'state/user/selectors';
import { AccountState } from 'state/account';
import { getEnumKeys } from 'utils/types';
import { EAccountFeatureTag, AccountFeatureTag } from 'models/Account';

/**
 * Create ability instance
 */
const ability = new Ability([]);

/**
 * Define abilities
 */
const updateAbilities = (state: RootState) => {
  const { can, rules } = AbilityBuilder.extract();

  // ---------  User abilities  ----------//

  // Anonymous users
  const anonymousFeatures: AccountFeatureTag[] = ['scan', 'hash_search'];
  can(anonymousFeatures, 'Artifact');

  // Everyone else
  getEnumKeys(EAccountFeatureTag).forEach((feature: any) => {
    if (userCanAccessFeature(feature, state)) {
      can([feature], 'Artifact');
    }
  });

  // ---------  Team Abilities  ----------//

  if (isUserOfTeamRole('teamOwner', state)) {
    can('manage', 'Team');
    can('manage', 'TeamConfigurations');
    can('manage', 'Integrations');
    can('manage', 'Microengines');
    can('manage', 'Webhooks');
    can('manage', 'TeamBilling');
    can('manage', 'Wallets');
    can('manage', 'Quota');
  }

  if (isUserOfTeamRole('teamAdmin', state)) {
    can('manage', 'Team');
    can('manage', 'Integrations');
    can('manage', 'Microengines');
    can('manage', 'Webhooks');
    can('manage', 'TeamBilling');
    can('manage', 'Wallets');
    can('manage', 'Quota');
  }

  if (isUserOfTeamRole('billingAdmin', state)) {
    can('manage', 'TeamBilling');
    can('manage', 'Quota');
  }

  if (isUserOfTeamRole('microengineAdmin', state)) {
    can('manage', 'Microengines');
    can('manage', 'Webhooks');
  }

  if (isUserOfTeamRole('walletAdmin', state)) {
    can('manage', 'Wallets');
  }

  return rules;
};

/**
 * Subscribe to desired states
 *
 * Note: component must be subscribed to the state as well for ability to update.
 */
let currentUser: UserState;
let currentTeam: TeamState;
let currentAccount: AccountState;

store.subscribe(() => {
  const state = store.getState();

  const prevUser = currentUser;
  currentUser = state.user;
  if (prevUser !== currentUser) {
    ability.update(updateAbilities(state));
  }

  const prevTeam = currentTeam;
  currentTeam = state.team;
  if (prevTeam !== currentTeam) {
    ability.update(updateAbilities(state));
  }

  const prevAccount = currentAccount;
  currentAccount = state.account;
  if (prevAccount !== currentAccount) {
    ability.update(updateAbilities(state));
  }
});

export default ability;
