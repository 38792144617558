import { Global, css } from '@emotion/core';

export default () => (
  <>
    <symbol id='icon-checkbox-checked' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM10 17L5 12.1923L6.4 10.8462L10 14.3077L17.6 7L19 8.34615L10 17Z'
        fill='#6D3AEC'
      />
    </symbol>
    <Global
      styles={css`
        .icon-checkbox-checked {
          width: 1.5em;
          height: 1.5em;
        }
      `}
    />
  </>
);
