import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { TableCell } from '@material-ui/core';
import { defineMessages, useIntl } from 'react-intl';
import { Integration } from 'models/Integration';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import IntegrationsTableRow from './IntegrationsTableRow';

interface IIntegrationsTableProps {
  results: Integration[];
  archiveIntegration: (key: string) => void;
}

const messages = defineMessages({
  key: {
    id: 'IntegrationsTable.row.key',
    defaultMessage: 'Name',
  },
  name: {
    id: 'IntegrationsTable.row.name',
    defaultMessage: 'Api Key',
  },
});

const IntegrationsTable = ({ results, archiveIntegration }: IIntegrationsTableProps) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const rows = [
    {
      id: 'key',
      numeric: false,
      label: intl.formatMessage(messages.key),
      sortable: false,
    },
    {
      id: 'name',
      numeric: false,
      label: intl.formatMessage(messages.name),
      sortable: false,
    },
  ];

  return (
    <div css={classes.container}>
      <CustomTable<Integration>
        rows={results}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            renderHeaderCell={(row) => {
              return (
                <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                  <div css={classes.cellContainer}>{intl.formatMessage({ id: row.label })}</div>
                </TableCell>
              );
            }}
          />
        )}
        renderTableRow={(row: Integration) => (
          <IntegrationsTableRow
            key={row.id}
            rowData={row}
            archiveIntegration={archiveIntegration}
          />
        )}
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: { margin: '3rem 0', width: '100%' },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    icon: {
      fontSize: '0.6rem',
    },
    iconConfig: {
      width: '2.5rem',
      height: '2.5rem',
      marginTop: '1rem',
      marginLeft: '2rem',
      marginRight: '2rem',
      cursor: 'pointer',
    },
    popover: {
      padding: '2rem 5rem 2rem 2rem',
      borderRadius: '0.5rem',
      marginTop: '1rem',
      fontWeight: 600,
      border: `1px solid ${styles.color.lightGrey}`,
    },
    listColumns: {
      marginTop: '1rem',
    },
    pane: {
      '&:hover': {
        cursor: 'grab',
      },
    },
  },
  light: {
    icon: {
      color: styles.color.black,
    },
    iconActive: {
      color: styles.color.purple,
    },
    iconConfig: {
      color: styles.color.purple,
    },
    popover: {
      backgroundColor: styles.color.ulGrey,
    },
  },
  dark: {
    icon: {
      color: styles.color.greyishPurple,
    },
    iconActive: {
      color: styles.color.lightBlue,
    },
    iconConfig: {
      color: styles.color.lightBlue,
    },
    popover: {
      backgroundColor: styles.color.darkOffBlack,
    },
  },
});

export default IntegrationsTable;
