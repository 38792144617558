import React from 'react'; // eslint-disable-line
import { jsx, ObjectInterpolation } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface IAmountColor {
  className?: string;
  classNumber?: ObjectInterpolation<any>;
  amount: string | number;
}

export const getAmountColor = (amount: number) => {
  return amount > 0 ? styles.color.medGreen : styles.color.red;
};

export const AmountColor = ({ className, classNumber, amount }: IAmountColor) => {
  const { classes } = useStyles(amount);
  const stringAmount = String(amount);
  const amountNumber = parseFloat(stringAmount.replace(/[^0-9.-]/g, ''));
  return (
    <div className={className}>
      <p css={[classes.num, classNumber]}>
        {!stringAmount.includes('-') && <span>{amountNumber > 0 ? '+' : '-'}</span>}
        <span css={classes.amount}>{stringAmount}</span>
      </p>
    </div>
  );
};

const useStyles = makeStyles((amount: number) => {
  const color = getAmountColor(amount);
  return {
    base: {
      num: {
        color,
      },
      amount: {
        fontFamily: styles.font.family.main,
      },
    },
  };
});
