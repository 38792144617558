import { ThemeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

type OwnStyle = ThemeStyles<'root'>;

const base: OwnStyle = {
  base: {
    root: {
      cursor: 'pointer',
      '&[disabled]': {
        cursor: 'not-allowed',
        opacity: 0.6,
      },
    },
  },
};

const normal: OwnStyle = {
  base: {
    root: {
      ...base.base.root,
      fontSize: '2rem',
      lineHeight: 1.5,
      display: 'inline-block',
      textDecoration: 'none',
      fontFamily: styles.font.family.main,
      fontWeight: styles.font.weight.bold,
      borderWidth: 2,
      borderStyle: 'solid',
      borderRadius: styles.border.radiusXl,
      padding: '0.5em 1em',
      transition: `color ${styles.easing.time} ${styles.easing.main},
    background-color ${styles.easing.time} ${styles.easing.main},
    border-color ${styles.easing.time} ${styles.easing.main}`,
      textAlign: 'center',
    },
  },
  light: {
    root: {
      color: styles.color.white,
      backgroundColor: styles.color.purple,
      borderColor: styles.color.purple,

      '&:not([disabled])': {
        '&:hover': {
          backgroundColor: styles.color.medPurple,
          borderColor: styles.color.medPurple,
        },
        '&:active': {
          backgroundColor: styles.color.darkPurple,
          borderColor: styles.color.darkPurple,
        },
      },
    },
  },
  dark: {
    root: {
      color: styles.color.lightBlue,
      backgroundColor: styles.color.xxxLightGrey,
      borderColor: styles.color.xxxLightGrey,

      '&:not([disabled])': {
        '&:hover': {
          backgroundColor: styles.color.offWhite,
          borderColor: styles.color.offWhite,
        },
        '&:active': {
          backgroundColor: styles.color.white,
          borderColor: styles.color.white,
        },
      },
    },
  },
};

const large: OwnStyle = {
  base: {
    root: {
      ...normal.base.root,
      fontSize: '2.4rem',
      padding: '0.8em 3.2em',
    },
  },
  light: normal.light,
  dark: normal.dark,
};

const small: OwnStyle = {
  base: {
    root: {
      ...normal.base.root,
      fontSize: '1.4rem',
      padding: '0.6em 1.6em',
    },
  },
  light: normal.light,
  dark: normal.dark,
};

const light = {
  base: {
    root: {
      ...normal.base.root,
      fontSize: '1.4rem',
      fontWeight: styles.font.weight.medium,
      padding: '0.7em 2.1em',
    },
  },
  light: {
    root: {
      ...normal.light!.root,
      color: styles.color.purple,
      backgroundColor: 'transparent',

      '&:not([disabled])': {
        '&:hover': {
          color: styles.color.white,
          backgroundColor: styles.color.medPurple,
          borderColor: styles.color.medPurple,
        },
        '&:active': {
          backgroundColor: styles.color.darkPurple,
          borderColor: styles.color.darkPurple,
        },
      },
    },
  },
  dark: {
    root: {
      ...normal.dark!.root,
      borderColor: styles.color.lightBlue,
      backgroundColor: 'transparent',

      '&:not([disabled])': {
        '&:hover': {
          color: styles.color.lightBlue,
          backgroundColor: styles.color.offWhite,
          borderColor: styles.color.offWhite,
        },
        '&:active': {
          backgroundColor: styles.color.white,
          borderColor: styles.color.white,
        },
      },
    },
  },
};

const text: OwnStyle = {
  base: {
    root: {
      ...base.base.root,
      fontSize: '2.4rem',
      lineHeight: 1.5,
      display: 'inline-block',
      textDecoration: 'none',
      fontFamily: styles.font.family.main,
      fontWeight: styles.font.weight.bold,
      background: 'transparent',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
  },
  light: {
    root: {
      color: styles.color.purple,

      '&:not([disabled])': {
        '&:hover': {
          color: styles.color.medPurple,
        },
        '&:active': {
          color: styles.color.darkPurple,
        },
      },
    },
  },
  dark: {
    root: {
      color: styles.color.xxxLightGrey,

      '&:not([disabled])': {
        '&:hover': {
          color: styles.color.offWhite,
        },
        '&:active': {
          color: styles.color.white,
        },
      },
    },
  },
};

const error: OwnStyle = {
  base: {
    root: {
      ...normal.base.root,
      color: styles.color.white,
      backgroundColor: styles.color.red,
      borderColor: styles.color.red,

      '&:not([disabled])': {
        '&:hover': {
          backgroundColor: styles.color.medRed,
          borderColor: styles.color.medRed,
        },
        '&:active': {
          backgroundColor: styles.color.darkRed,
          borderColor: styles.color.darkRed,
        },
      },
    },
  },
};

export default { base, normal, large, small, light, text, error };
