import { useRef, useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import api from 'services/api';

const VideoTab = ({ sandboxedArtifactId }: { sandboxedArtifactId: string }) => {
  const [videoURL, setVideoURL] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    (async () => {
      await api
        .downloadSandboxArtifact(sandboxedArtifactId, 'content_type=video/mp4')
        .then((res) => setVideoURL(res.data.url))
        .catch((error) => error);
    })();
  }, [videoURL, sandboxedArtifactId]);

  useEffect(() => {
    if (!!videoRef.current) {
      videoRef.current.load();
    }
  }, [videoURL]);

  const getScreenshot = () => {
    const video = document.querySelector('video');
    const canvas = document.querySelector('canvas');
    const context = canvas?.getContext('2d');

    if (!!video && !!canvas) {
      // Draw the current video frame to the canvas
      context?.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get the image data URL and set it as the src of the img element
      const dataURL = canvas?.toDataURL('image/png');

      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'video-screenshot.png';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div
      style={{
        gap: '4rem',
        padding: '4rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <video ref={videoRef} width={640} height={480} controls crossOrigin='anonymous'>
        {!!videoURL && <source src={videoURL} type='video/mp4' />}
      </video>
      <Button
        color='primary'
        size='small'
        variant='contained'
        disabled={isEmpty(videoURL)}
        onClick={getScreenshot}
        id='screenshot-button'
      >
        Take Screenshot
      </Button>
      <canvas id='canvas' width={640} height={480} style={{ display: 'none' }} />
    </div>
  );
};
export default VideoTab;
