import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import styles from 'views/styles';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';
import { useAuth } from 'views/components/providers/AuthProvider';
import { useSystem } from 'views/components/providers/SystemProvider';
import Card from 'views/components/layout/Card';

interface ISidebarDefault {
  heading: string;
  text: string;
  isLoginRequired?: boolean;
  icon?: string;
}

const SidebarDefault = ({ heading, text, icon, isLoginRequired }: ISidebarDefault) => {
  const intl = useIntl();
  const location = useLocation();
  const { isMaintenanceMode } = useSystem();
  const { isAuthenticated, login } = useAuth();

  return (
    <Card css={style.root}>
      <RulesetImg />
      <h4 className='h4 h-mt-xxs'>{heading}</h4>
      <p className='p1 h-mt-xxs h-mb-xxs'>{text}</p>
      {!isAuthenticated && isLoginRequired && (
        <Button
          css={style.button}
          color='primary'
          variant='contained'
          onClick={() => login(location.pathname)}
          disabled={isMaintenanceMode}
          data-cy='sidebarLogin'
        >
          {intl.formatMessage({ id: 'button.login' })}
        </Button>
      )}
    </Card>
  );
};

const style = {
  root: css`
    padding: ${styles.spacing.md} ${styles.spacing.sm} ${styles.spacing.sm};
    text-align: center;
  `,
  button: css`
    width: 100% !important;
    display: block !important;
    margin-top: ${styles.spacing.sm} !important;
  `,
};

export default SidebarDefault;
