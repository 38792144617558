import { SettingsState } from 'models/Settings';
import { SettingsAction, SettingsActionName } from './types';
import { DEFAULT_LANG, DEFAULT_THEME } from 'utils/constants';

export const initialState: SettingsState = {
  locale: DEFAULT_LANG,
  theme: DEFAULT_THEME,
};

const settings = (state: SettingsState = initialState, action: SettingsAction): SettingsState => {
  switch (action.type) {
    case SettingsActionName.CHANGE_LANGUAGE: {
      return { ...state, locale: action.locale };
    }

    case SettingsActionName.CHANGE_THEME: {
      return { ...state, theme: action.theme };
    }

    default: {
      return state;
    }
  }
};

export default settings;
