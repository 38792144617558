import React, { useState } from 'react'; // eslint-disable-line
import { jsx, keyframes } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import HowItWorksSection from '../HowItWorksSection';
import { Escrow, Engines, Bids } from '../HowItWorksSubsections';
import arrowImg from '../shared/arrow.svg';

const RewardSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { classes } = useStyles(isVisible);

  const _handleVisibilityChange = (isVisible: boolean) => setIsVisible(isVisible);

  return (
    <HowItWorksSection onVisibilityChange={_handleVisibilityChange}>
      <div css={classes.root}>
        <div css={classes.left}>
          <h3 className='h3' css={classes.heading}>
            <FormattedMessage id='rewardSection.heading' defaultMessage='Reward' />
          </h3>
          <p className='p' css={classes.text}>
            <FormattedMessage
              id='rewardSection.text'
              defaultMessage='Engines that made the right assertion are rewarded with the money from the initial bounty from the enterprise plus the money the losing engines included with their assertions.'
            />
          </p>
        </div>
        <div css={classes.right}>
          <Engines css={classes.engines} />
          <Bids
            items={[
              { result: 'M', bid: 8, showSign: true },
              { result: 'B', bid: -3 },
              { result: 'B', bid: -2 },
              { bid: 0 },
            ]}
            css={classes.bids}
          />
          <div css={classes.escrowArrows}>
            <img css={classes.escrowArrow} src={arrowImg} alt='arrow' />
          </div>
          <Escrow css={classes.escrow} />
        </div>
      </div>
    </HowItWorksSection>
  );
};

const fadeInUp = keyframes`
  to {
    opacity: 1;
    top: 0;
  }
`;

const fadeOutDown = keyframes`
  to {
    opacity: 0;
    top: 10px;
  }
`;

const hide = keyframes`
  to {
    opacity: 0;
  }
`;

const useStyles = makeStyles((isVisible: boolean) => ({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      maxWidth: '38rem',
      marginBottom: styles.spacing.xs,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    left: {
      width: '50%',
      position: 'relative',
    },
    escrow: {
      margin: `${styles.spacing.md} auto 0`,
      '.bounty': {
        animation: isVisible ? `${hide} 0.2s ease-in-out 1.5s forwards` : 'none',
        '.payout': {
          top: 0,
          animation: isVisible ? `${fadeOutDown} 0.2s ease-in-out 1.4s forwards` : 'none',
        },
      },
      '.assertions': {
        animation: isVisible ? `${hide} 0.2s ease-in-out 1.9s forwards` : 'none',
        '.payout': {
          '&:nth-of-type(1)': {
            top: 0,
            animation: isVisible ? `${fadeOutDown} 0.2s ease-in-out 1.4s forwards` : 'none',
          },
          '&:nth-of-type(2)': {
            top: 0,
            animation: isVisible ? `${fadeOutDown} 0.2s ease-in-out 1.6s forwards` : 'none',
          },
          '&:nth-of-type(3)': {
            top: 0,
            animation: isVisible ? `${fadeOutDown} 0.2s ease-in-out 1.8s forwards` : 'none',
          },
        },
      },
    },
    escrowArrows: {
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '1.8rem',
        left: '50%',
        transform: 'translateX(-50%)',
        height: '3rem',
        width: 2,
        backgroundColor: styles.color.xLightGrey,
      },
    },
    escrowArrow: {
      position: 'absolute',
      top: '1rem',
      left: '50%',
      transform: 'translateX(-50%) rotate(-90deg)',
    },
    right: {
      width: '50%',
      position: 'relative',
      textAlign: 'center',
    },
    engines: {
      margin: '0 auto',
      '.engine': {
        opacity: 0.5,
        '&:nth-of-type(1)': {
          opacity: 1,
        },
      },
    },
    bids: {
      position: 'relative',
      margin: '3.7rem auto 0',
      [`@media (min-width: ${styles.breakpoint.xxl})`]: {
        margin: '4rem auto 0',
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: '-1.1rem',
        left: '50%',
        height: 2,
        width: '26.6rem',
        transform: 'translateX(-55%)',
        backgroundColor: styles.color.xLightGrey,
        [`@media (min-width: ${styles.breakpoint.lg})`]: {
          bottom: '-1rem',
          width: '27.4rem',
        },
        [`@media (min-width: ${styles.breakpoint.xxl})`]: {
          bottom: '-1.1rem',
          width: '28.4rem',
          transform: 'translateX(-54%)',
        },
      },
      '.bid': {
        '.result': {
          position: 'relative',
          '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: '-3.2rem',
            left: '50%',
            height: '3rem',
            width: 2,
            transform: 'translateX(-50%)',
            backgroundColor: styles.color.xLightGrey,
          },
        },
        '&:nth-of-type(1)': {
          '.payout': {
            opacity: 0,
            top: 10,
            animation: isVisible ? `${fadeInUp} 0.2s ease-in-out 1.6s forwards` : 'none',
          },
        },
        '&:nth-of-type(2)': {
          '.payout': {
            opacity: 0,
            top: 10,
            animation: isVisible ? `${fadeInUp} 0.2s ease-in-out 1.8s forwards` : 'none',
          },
        },
        '&:nth-of-type(3)': {
          '.payout': {
            opacity: 0,
            top: 10,
            animation: isVisible ? `${fadeInUp} 0.2s ease-in-out 2s forwards` : 'none',
          },
        },
        '&:nth-of-type(4)': {
          '.payout': {
            opacity: 0,
            top: 10,
            animation: isVisible ? `${fadeInUp} 0.2s ease-in-out 2.2s forwards` : 'none',
          },
        },
      },
    },
  },
  light: {
    heading: {
      color: styles.color.purple,
    },
  },
  dark: {
    heading: {
      color: styles.color.lightBlue,
    },
  },
}));

export default RewardSection;
