import React from 'react';
import * as yup from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { IntlShape, useIntl } from 'react-intl';
import { formSchema } from 'utils/schema/formSchema';
import { ButtonProps } from '@material-ui/core/Button';
import FormInput from 'views/components/form/FormInput';
import FormButtonBar from 'views/components/form/CustomForm/FormButtonBar';
import CustomForm from 'views/components/form/CustomForm';

interface IConfirmForm {
  className?: string;
  isLoading: boolean;
  errorMessage?: string;
  name: string;
  label: string;
  heading: string;
  text: string;
  buttonText: string;
  buttonColor?: ButtonProps['color'];
  buttonVariant?: ButtonProps['variant'];
  testId?: string;
  onSubmit: (values: IConfirmFormValues, actions: FormikHelpers<IConfirmFormValues>) => void;
  onCancel: () => void;
}

export interface IConfirmFormValues {
  name: string;
}

const initialValues: IConfirmFormValues = {
  name: '',
};

const getValidationSchema = (intl: IntlShape, name: string, label: string) => {
  const { confirm } = formSchema(intl);
  return yup.object().shape({
    name: confirm(name, label),
  });
};

const ConfirmForm = ({
  className,
  isLoading,
  name,
  label,
  heading,
  text,
  buttonText,
  buttonColor,
  buttonVariant,
  errorMessage,
  onSubmit,
  onCancel,
  testId,
}: IConfirmForm) => {
  const intl = useIntl();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(intl, name, label)}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<IConfirmFormValues>) => (
        <CustomForm
          testId={testId}
          className={className}
          isLoading={isLoading}
          heading={heading}
          text={text}
          renderFields={() => (
            <FormInput
              className='h-mb-sm'
              name='name'
              label={label}
              errorMessage={errorMessage}
              autoFocus
            />
          )}
          renderButtonBar={() => (
            <FormButtonBar
              disabled={formikProps.values.name !== name}
              submitBtnText={buttonText}
              submitBtnColor={buttonColor}
              submitBtnVariant={buttonVariant}
              onCancel={() => {
                formikProps.resetForm();
                onCancel();
              }}
            />
          )}
        />
      )}
    </Formik>
  );
};

export default ConfirmForm;
