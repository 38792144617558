import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import styles from 'views/styles';
import api from 'services/api';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

type ModalProps = {
  instanceId: string;
  sha256: string;
};

const FalsePositiveModal = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch<Dispatch>();
  const _closeModal = () => dispatch(closeModal());
  const [loading, setLoading] = useState(false);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [description, setDescription] = useState('');

  const { instanceId, sha256 } = useSelector(
    ({ modal }: { modal: ModalState<ModalProps> }) => modal.params
  );

  async function reportFalsePositive() {
    try {
      setError(null);
      setLoading(true);

      const response = await api.reportFalsePositive({
        instanceId,
        sha256,
        description,
      });

      setLoading(false);

      if (response.status !== 204) {
        setError('Something went wrong. Please try again later.');
      } else {
        setIsFinalStep(true);
      }
    } catch (e) {
      setLoading(false);
      setError('Something went wrong. Please try again later.');
    }
  }

  const extraProps = isFinalStep
    ? {
        submitBtnText: 'Ok',
        onSubmit: _closeModal,
        message:
          'Thanks for reporting this artifact to our Threat Intelligence Team. We will investigate further.',
      }
    : {
        onCancel: _closeModal,
        cancelBtnText: 'Cancel',
        submitBtnText: 'Submit',
        onSubmit: reportFalsePositive,
        message: 'Why do you think the determination of this Artifact is a False Positive?',
      };

  return (
    <ConfirmModal
      css={classes.modal}
      isLoading={loading}
      content={
        <div css={classes.container}>
          <h2 css={classes.title}>Report False Positive</h2>
          {!isFinalStep && (
            <textarea
              placeholder={extraProps.message}
              rows={5}
              autoFocus={true}
              disabled={loading}
              css={classes.textarea}
              onChange={(e) => setDescription(e.target.value)}
            />
          )}
          {error && <p css={classes.error}>{error}</p>}
          {isFinalStep && !error && <p>{extraProps.message}</p>}
        </div>
      }
      submitBtnVariant='contained'
      disabled={loading || description.trim().length === 0}
      {...extraProps}
    />
  );
};

const useStyles = makeStyles({
  base: {
    modal: {
      width: '480px',
      padding: styles.spacing.xs,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.8rem',
    },
    error: {
      color: styles.color.red,
      fontSize: '1.6rem',
    },
    textarea: {
      resize: 'none',
      padding: '1.2rem',
      color: styles.color.lightGrey,
      background: styles.color.xxLightPurple,
      outlineColor: styles.color.purple,
    },
  },
  light: {
    title: { color: styles.color.purple },
  },
  dark: {
    title: { color: styles.color.lightBlue },
  },
});

export default FalsePositiveModal;
