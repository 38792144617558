import React from 'react'; // eslint-disable-line
import { css } from '@emotion/css';

import heebo from 'assets/fonts/Heebo-Regular.ttf';
import heeboMedium from 'assets/fonts/Heebo-Medium.ttf';
import heeboBold from 'assets/fonts/Heebo-Bold.ttf';
import heeboBlack from 'assets/fonts/Heebo-Black.ttf';

import fabrikBoldEot from 'assets/fonts/Fabrik-Bold.eot';
import fabrikBoldWoff from 'assets/fonts/Fabrik-Bold.woff';
import fabrikBoldWoff2 from 'assets/fonts/Fabrik-Bold.woff2';

import menlo from 'assets/fonts/Menlo-Regular.ttf';
import menloBold from 'assets/fonts/Menlo-Bold.ttf';

export const fonts = css`
  @font-face {
    font-family: 'Fabrik';
    src: url(${fabrikBoldEot}) format('embedded-opentype'), url(${fabrikBoldWoff2}) format('woff2'),
      url(${fabrikBoldWoff}) format('woff');
    font-weight: bold;
  }

  @font-face {
    font-family: 'Heebo';
    src: url(${heebo});
    font-weight: 400;
  }

  @font-face {
    font-family: 'Heebo';
    src: url(${heeboMedium});
    font-weight: 600;
  }

  @font-face {
    font-family: 'Heebo';
    src: url(${heeboBold});
    font-weight: 700;
  }

  @font-face {
    font-family: 'Heebo';
    src: url(${heeboBlack});
    font-weight: 900;
  }

  @font-face {
    font-family: 'Menlo';
    src: url(${menlo});
    font-weight: 400;
  }

  @font-face {
    font-family: 'Menlo';
    src: url(${menloBold});
    font-weight: 700;
  }
`;

const stack =
  '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif';

const font = {
  family: {
    main: `Heebo, ${stack}`,
    heading: `Fabrik, ${stack}`,
    mono: 'Menlo, monospace',
  },
  weight: {
    normal: 400,
    medium: 600,
    bold: 700,
    black: 900,
  },
  size: {
    h1: '4.4rem',
    h2: '5rem',
    h3: '3.2rem',
    h4: '2.4rem',
    h5: '2.2rem',
    h6: '1.8rem',
    p: '1.6rem',
    p1: '1.4rem',
    input: '1.8285714285714285rem',
    table: '1.5999999999999999rem',
    tableCompact: '1.2rem',
  },
};

export default font;
