import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import styled from '@emotion/styled';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Icon from 'views/components/Icon';

const Stat = styled.div`
  padding: 2rem 1rem;
  text-align: center;
  align-self: center;
`;

type StatsProps = {
  value?: number;
  prefix?: string;
  title: string;
  subtitle?: string;
  date?: string;
  helpText?: string;
  compact?: boolean;
};

const Stats = ({
  value = 0,
  prefix,
  title,
  subtitle,
  date,
  helpText,
  compact = true,
}: StatsProps) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const getCompactNumber = (num: number) => {
    const value = intl.formatNumber(num, { notation: 'compact' });
    return value.split(/([0-9.]+)/).filter(Boolean);
  };

  const [digits, unit] = getCompactNumber(value);
  const currency = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(value);

  return (
    <Stat>
      <div css={classes.value}>
        <span css={classes.prefix}>{prefix}</span>
        {compact ? (
          <React.Fragment>
            <span>{digits}</span>
            <span css={classes.unit}>{unit}</span>
          </React.Fragment>
        ) : (
          <span>{currency}</span>
        )}
      </div>
      <div css={classes.title}>
        <span>{title}</span>
      </div>
      <div css={classes.subtitle}>{subtitle}</div>
      {helpText && (
        <Tooltip title={helpText} placement='top'>
          <div css={classes.info} tabIndex={0}>
            <Icon name='info' />
          </div>
        </Tooltip>
      )}
      <div css={classes.date}>{date}</div>
    </Stat>
  );
};

const useStyles = makeStyles({
  base: {
    value: {
      fontFamily: styles.font.family.main,
      fontWeight: styles.font.weight.bold,
      fontSize: '4rem',
      lineHeight: 1.6,
    },
    title: {
      fontFamily: styles.font.family.main,
      fontWeight: styles.font.weight.bold,
      whiteSpace: 'nowrap',
      fontSize: '2rem',
    },
    info: {
      cursor: 'help',
      fontSize: '0.5rem',
      color: styles.color.xLightGrey,
      position: 'absolute',
      bottom: '1.5rem',
      right: '1.5rem',
    },
    subtitle: {
      fontFamily: styles.font.family.main,
      fontSize: '1.3rem',
    },
    unit: {
      marginLeft: '1rem',
    },
    prefix: {
      fontSize: '3.5rem',
      marginRight: '0.3rem',
    },
    date: {
      fontFamily: styles.font.family.main,
      fontSize: '1.2rem',
      marginTop: '2rem',
      // fontWeight: 700,
    },
  },
  light: {
    value: {
      color: styles.color.purple,
    },
    title: {
      color: styles.color.black,
    },
    info: {},
  },
  dark: {
    value: {
      color: styles.color.lightBlue,
    },
    title: {
      color: styles.color.white,
    },
    info: {},
  },
});

export default Stats;
