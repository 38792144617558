import React, { Fragment, useState, useRef, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import qs from 'query-string';
import useHasFeature from 'hooks/useHasFeature';
import useNotification from 'hooks/useNotification';
import { useHistory, useLocation } from 'react-router-dom';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { CONSUMER_DOCS_URL } from 'utils/constants';
import { RootState } from 'state/root';
import { NormalizedArtifactMetadata, Artifact, Artifacts } from 'models/Submission';
import { loadingSelector } from 'state/requests/selectors';
import { btnMessages } from 'views/components/Button';
import { viewScanResult, downloadArtifact } from 'state/submission/actions';
import { HuntingActionName } from 'state/hunting/types';
import { getMetadataSearchResults, resetMetadataSearch } from 'state/hunting/actions';
import { IColumnSort, ESortDirection } from 'views/components/table/InfiniteScrollTable';
import { PageQuery, PageAttributes } from 'models/Page';
import { extractPageQueryParams, extractUrlQueryParams } from 'utils/pagination';
import { toCSV } from 'utils/csv/csv';
import styles from 'views/styles';
import PanelContent from 'views/components/layout/PanelContent';
import Placeholder from 'views/components/request/Placeholder/PlaceholderContainer';
import NewMetadataSearchTable from './NewMetadataSearchTable';
import SearchField from 'views/components/form/SearchField';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';
import { EPageDirection } from 'views/components/table/CustomTablePagination';
import Icon from 'views/components/Icon';
import Popover from 'views/components/Popover';
import { TimeoutError } from './TimeoutError';
import { UsageLimitError } from './UsageLimitError';
import { HuntingError } from './HuntingError';
import Checkbox from '@material-ui/core/Checkbox';
import { SortablePane, Pane } from 'react-sortable-pane';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useUser } from 'views/components/providers/UserProvider';
import { getAccountStoredKey, storeAccountKey } from 'utils/storage/storage';

interface IMetadataSearchTabState {
  isLoading: boolean;
  metadataSearchTerm: string;
  metadataSearchResults: NormalizedArtifactMetadata[];
  metadataSearchPage: Artifacts;
}

interface IMetadataSearchQuery {
  offset?: string;
  limit: number;
}

const messages = defineMessages({
  text: {
    id: 'metadataSearch.text',
    defaultMessage: 'Enter your search query in the search bar above to get started.',
  },
  search: {
    id: 'metadataSearch.search',
    defaultMessage: 'Search Metadata',
  },
  noneFoundHeading: {
    id: 'metadataSearch.noneFound.Heading',
    defaultMessage: 'No results found',
  },
  noneFoundText: {
    id: 'metadataSearch.noneFound.Text',
    defaultMessage:
      'Your metadata search query returned 0 results. Maybe try fewer or more generic terms. See our {documentation} for additional guidance.',
  },
  editColumns: {
    id: 'metadataSearch.editColumns',
    defaultMessage: 'Edit Result Columns',
  },
});

const columnLabels = {
  sha256: 'Hash',
  malware_family: 'Malware Family Name',
  submitted: 'First Seen',
  last_seen: 'Last Seen',
  polyscore: 'Polyscore',
  detections: 'Detections',
  file_size: 'File Size',
  file_type: 'File Type',
} as { [key: string]: string };

const [VISIBLE_COLUMNS_KEY, COLUMN_ORDER_KEY, LATEST_TERMS_KEY] = [
  'metadataSearchVisibleColumns',
  'metadataSeachColumnOrder',
  'metadataSeachLatestTerms',
];

const MetadataSearchTab = () => {
  const { accountNumber } = useUser();
  const [sort, setSort] = useState<IColumnSort<keyof NormalizedArtifactMetadata>>({
    orderBy: 'submitted',
    direction: ESortDirection.ASC,
  });
  const [isPivoting, setIsPivoting] = useState(false);
  const { hasPermission: downloadPermission, remainingUses: downloadRemainingUses } =
    useHasFeature('download');
  const notification = useNotification();

  const storedVisibleColumns = getAccountStoredKey(
    accountNumber,
    VISIBLE_COLUMNS_KEY,
    Object.keys(columnLabels)
  );
  const storedColumnOrder = getAccountStoredKey(
    accountNumber,
    COLUMN_ORDER_KEY,
    Object.keys(columnLabels)
  );
  const [visibleColumns, setVisibleColumns] = useState<string[]>(storedVisibleColumns);
  const [columnsOrder, setColumnsOrder] = useState<string[]>(storedColumnOrder);
  const [latestTerms, setLatestTerms] = useState<{ value: string }[]>(
    getAccountStoredKey(accountNumber, LATEST_TERMS_KEY, [])
  );

  const _offsets = useRef<string[]>([]);
  const _currentOffset = useRef<string | undefined>();

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const { isLoading, metadataSearchTerm, metadataSearchPage, metadataSearchResults } = useSelector<
    RootState,
    IMetadataSearchTabState
  >(({ hunting: { metadataSearchTerm, metadataSearchPage, metadataSearchResults }, requests }) => ({
    isLoading: loadingSelector(requests, [HuntingActionName.GET_METADATA_SEARCH_RESULTS]),
    metadataSearchTerm,
    metadataSearchPage,
    metadataSearchResults,
  }));

  const { event: gaEvent } = useAnalytics();

  const { limit } = qs.parse(location.search);

  const _handleMetadataSearch = (term: string, query?: PageQuery<Artifact>, loadMore?: boolean) => {
    const params = Object.assign({}, extractPageQueryParams(metadataSearchPage), query);
    history.push(`/search/metadata?${qs.stringify({ ...params, term, more: loadMore })}`);
  };

  const _onChangePage = (direction: EPageDirection, options?: IMetadataSearchQuery) => {
    const { term, limit } = qs.parse(location.search);

    if ([term, limit].every((param) => typeof param === 'string')) {
      const query: IMetadataSearchQuery = Object.assign(
        {
          limit: Number(limit),
        },
        options || {}
      );

      if (direction === EPageDirection.PREV) {
        _currentOffset.current = _offsets.current.pop();
        query.offset = _currentOffset.current;
      } else if (direction === EPageDirection.NEXT) {
        if (_currentOffset.current) {
          _offsets.current.push(_currentOffset.current);
        }
        _currentOffset.current = String(metadataSearchPage.offset);
        query.offset = _currentOffset.current;
      } else if (direction === EPageDirection.FIRST) {
        _currentOffset.current = undefined;
        _offsets.current = [];
      }

      _handleMetadataSearch(term as string, query, true);
    } else if (term && typeof term === 'string') {
      _handleMetadataSearch(term, {}, true);
    }

    return Promise.resolve();
  };

  const _onChangeRowsPerPage = (limit: number) => _onChangePage(EPageDirection.FIRST, { limit });

  const _viewScanResult = (hash: string) => dispatch(viewScanResult(hash, true));

  const _downloadArtifact = (hash: string) => {
    if (!downloadPermission) {
      notification.failure(
        'Your subscription does not include this feature. Please contact your sales representative to add it.'
      );
    } else if (downloadRemainingUses === 0) {
      notification.failure(
        'Your subscription 0 remaining quota for this feature for this month. Please wait until your quota resets next month and try again.'
      );
    } else {
      dispatch(downloadArtifact(hash));
    }
  };

  const _handleSort = ({ orderBy, direction }: any) => {
    setSort({ orderBy, direction });
    _handleMetadataSearch(metadataSearchTerm, {
      orderBy,
      direction,
    });
  };

  const _handleColumns = (column: string) => {
    let newVisibleColumns;
    if (visibleColumns.includes(column)) {
      newVisibleColumns = visibleColumns.filter((c) => c !== column);
    } else {
      newVisibleColumns = [...visibleColumns, column];
    }
    setVisibleColumns(newVisibleColumns);
    storeAccountKey(accountNumber, VISIBLE_COLUMNS_KEY, newVisibleColumns);
  };

  const _onSave = () => {
    if (metadataSearchResults.length) {
      toCSV(
        metadataSearchResults.map((results) => ({
          sha256: results.sha256,
          polyscore: results.latest_scan_polyscore,
          malware_family: results.polyunite_malware_family,
          detections: results.detections
            ? `${results.detections.malicious}/${results.detections.total}`
            : '',
          file_type: results.metadata?.exiftool?.filetype || '',
          file_size: results.metadata?.exiftool?.filesize || '',
          submitted: results.submitted,
          last_seen: results.last_seen,
        })),
        'metadata_results',
        columnsOrder.filter((column) => visibleColumns.includes(column))
      );
    }
  };

  useEffect(() => {
    const search = qs.parse(location.search);
    if (typeof search.term === 'string') {
      const query = extractUrlQueryParams<Artifact>(search);
      const params: Partial<PageAttributes<Artifact>> = Object.assign(
        {},
        extractPageQueryParams(metadataSearchPage),
        query
      );
      // Don't include orderBy in our params.
      delete params.orderBy;
      // Don't dispatch if edit comes as a query param.
      // This is to prevent the search from being triggered when it's generated
      // from the pivoting feature.
      if (!search.pivoting) {
        dispatch(getMetadataSearchResults(search.term, params, query.more === true));
      } else {
        dispatch({ type: HuntingActionName.SET_METADATA_SEARCH_TERM, term: search.term });
        setIsPivoting(true);
      }

      gaEvent({
        category: 'Search',
        action: 'Metadata Search',
      });
    }

    return () => {
      dispatch(resetMetadataSearch());
    };
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div css={classes.search}>
        <SearchField
          autocompleteItems={latestTerms}
          onRemoveAutocompleteItem={(term: string) => {
            const newTerms = latestTerms.filter((t) => t.value !== term);
            storeAccountKey(accountNumber, LATEST_TERMS_KEY, newTerms);
            setLatestTerms(newTerms);
          }}
          dataCy='metadataSearchField'
          label={intl.formatMessage(messages.search)}
          onSubmit={(value) => {
            _handleMetadataSearch(value);
            const newTerms = [
              { value },
              ...getAccountStoredKey(accountNumber, LATEST_TERMS_KEY, latestTerms).slice(0, 9),
            ].reduce(
              (unique, item) =>
                unique.map((u: any) => u.value).includes(item.value) ? unique : [...unique, item],
              []
            );
            storeAccountKey(accountNumber, LATEST_TERMS_KEY, newTerms);
            setLatestTerms(newTerms);
            setIsPivoting(false);
          }}
          initialValue={metadataSearchTerm}
          isLoading={isLoading}
        />
      </div>

      <Placeholder
        loadingActions={[
          HuntingActionName.GET_METADATA_SEARCH_RESULTS,
          HuntingActionName.GET_MORE_METADATA_SEARCH_RESULTS,
        ]}
        renderError={{
          request_timeout: <TimeoutError />,
          rate_limit_exceeded: <UsageLimitError />,
          hunting_error: <HuntingError />,
        }}
        onReload={() => _handleMetadataSearch(metadataSearchTerm, {})}
      >
        {() => {
          if (!metadataSearchTerm || (isPivoting && metadataSearchResults.length === 0)) {
            return (
              <PanelContent
                testId='metadataSearchEmpty'
                imageComponent={<RulesetImg />}
                heading={intl.formatMessage({ id: 'metadataSearch.heading' })}
                text={intl.formatMessage(messages.text)}
                buttons={[
                  {
                    text: intl.formatMessage(btnMessages.readDocs),
                    href: `${CONSUMER_DOCS_URL}/searching#metadata-searching`,
                    variant: 'outlined',
                  },
                ]}
              />
            );
          }

          if (!metadataSearchResults.length) {
            return (
              <PanelContent
                testId='metadataSearchNoneFound'
                imageComponent={<RulesetImg />}
                heading={intl.formatMessage(messages.noneFoundHeading)}
                text={intl.formatMessage(messages.noneFoundText, {
                  documentation: (
                    <a
                      css={classes.link}
                      href='https://docs.polyswarm.io/consumers/polyswarm-customer-api-v2#metadata-searching'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FormattedMessage
                        id='metadataSearch.noneFound.documentation'
                        defaultMessage='documentation'
                      />
                    </a>
                  ),
                })}
              />
            );
          }

          return (
            <div css={classes.container}>
              <div css={classes.alignRight}>
                <Popover
                  placement='bottom-end'
                  button={
                    <Tooltip title='Configure results table' placement='top'>
                      <div>
                        <Icon css={classes.iconConfig} name='gear' />
                      </div>
                    </Tooltip>
                  }
                  content={
                    <div css={classes.popover}>
                      <h3>{intl.formatMessage(messages.editColumns)}</h3>
                      <div
                        style={{ height: `${columnsOrder.length * 4.75}rem` }}
                        css={classes.listColumns}
                      >
                        <SortablePane
                          direction='vertical'
                          defaultOrder={columnsOrder}
                          onDragStart={(e) => e.stopPropagation()}
                          onDragStop={(e, _key, _ref, order) => {
                            e.stopPropagation();
                            setColumnsOrder(order);
                            storeAccountKey(accountNumber, COLUMN_ORDER_KEY, order);
                          }}
                        >
                          {columnsOrder.map((item) => (
                            <Pane
                              css={classes.pane}
                              defaultSize={{ width: '100%' }}
                              key={item}
                              resizable={{ x: false, y: false, xy: false }}
                            >
                              <label>
                                <Checkbox
                                  color='primary'
                                  checked={visibleColumns.includes(item)}
                                  onChange={(_e) => {
                                    _handleColumns(item);
                                  }}
                                />
                                {columnLabels[item]}
                              </label>
                            </Pane>
                          ))}
                        </SortablePane>
                      </div>
                    </div>
                  }
                />
                <div onClick={_onSave}>
                  <Tooltip title='Download list of results as CSV' placement='top'>
                    <div>
                      <Icon
                        css={[classes.icon, metadataSearchResults.length === 0 && classes.disabled]}
                        name='save'
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div css={classes.tableContainer}>
                <NewMetadataSearchTable
                  metadata={metadataSearchPage}
                  results={metadataSearchResults}
                  viewScanResult={_viewScanResult}
                  downloadArtifact={_downloadArtifact}
                  sort={sort}
                  onSort={_handleSort}
                  rowsPerPage={Number(limit)}
                  onChangePage={_onChangePage}
                  onChangeRowsPerPage={_onChangeRowsPerPage}
                  isFirst={!_currentOffset.current}
                  isLast={!metadataSearchPage.has_more}
                  columns={columnsOrder.filter((c) => visibleColumns.includes(c))}
                />
              </div>
            </div>
          );
        }}
      </Placeholder>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    search: {
      padding: `0 ${styles.spacing.grid} ${styles.spacing.grid}`,
      display: 'flex',
      alignItems: 'flex-start',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 3rem 0 1rem',
    },
    alignRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      alignItems: 'center',
    },
    tableContainer: {
      width: '100%',
    },
    link: {
      color: styles.color.medLightPurple,
    },
    icon: {
      width: '2.5rem !important',
      height: '2.5rem !important',
      marginTop: '1rem',
      cursor: 'pointer',
    },
    disabled: {
      cursor: 'not-allowed',
      color: styles.color.xLightGrey,
    },
    iconConfig: {
      width: '2.5rem !important',
      height: '2.5rem !important',
      marginTop: '1rem',
      marginLeft: '2rem',
      marginRight: '2rem',
      cursor: 'pointer',
    },
    popover: {
      padding: '2rem 4rem',
      width: '30rem',
      borderRadius: '0.5rem',
      marginTop: '1rem',
      fontWeight: 600,
      border: `1px solid ${styles.color.lightGrey}`,
    },
    listColumns: {
      marginTop: '1rem',
    },
    pane: {
      '&:hover': {
        cursor: 'grab',
      },
    },
  },
  light: {
    icon: {
      color: styles.color.purple,
    },
    iconConfig: {
      color: styles.color.purple,
    },
    popover: {
      backgroundColor: styles.color.ulGrey,
    },
  },
  dark: {
    icon: {
      color: styles.color.lightBlue,
    },
    iconConfig: {
      color: styles.color.lightBlue,
    },
    popover: {
      backgroundColor: styles.color.darkOffBlack,
    },
  },
});

export default MetadataSearchTab;
