import { isBefore, format, formatDistanceStrict } from 'date-fns';

export const formatDistanceToNow = (date: Date): string => {
  const now = new Date();
  const utc = convertToUTC(now);
  return formatDistanceStrict(utc, date, { addSuffix: false });
};

export const convertToUTC = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const parseUTCDate = (date: string): Date => {
  return convertToUTC(new Date(date));
};

export const daysFromNowDate = (days: number): Date => {
  const now = new Date();
  now.setDate(now.getDate() + days);
  return now;
};

export const toISODate = (date: Date): string => date.toISOString().split('T')[0];
export const toISODateTime = (date: string | Date): string => {
  if (!date) return '';
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return format(date, 'yyyy-MM-dd HH:mm');
};

export const isExpired = (time: Date, expireAfterMillis: number) =>
  new Date(time.getTime() + expireAfterMillis) <= new Date();

export const notExpired = (time: Date, expireAfterMillis: number) =>
  !isExpired(time, expireAfterMillis);

export const isDateBefore = isBefore;
