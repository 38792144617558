import React, { useEffect } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useUser } from 'views/components/providers/UserProvider';
import PanelContent from 'views/components/layout/PanelContent';
import { ReactComponent as IntegrationsImg } from 'assets/images/integrations.svg';
import { Integration } from 'models/Integration';
import IntegrationsTable from 'views/components/table/IntegrationsTable/IntegrationsTableContainer';
import { useIntegrations } from './useIntegrations';
import { Dispatch } from 'state/types/thunk';
import { RootState } from 'state/root';
import { openModal } from 'state/modal/actions';
import { getMFAData, loginWriteApiKey, removeMFAData } from 'state/auth/actions';
import { CreateIntegrationDialog } from 'views/components/table/IntegrationsTable/CreateIntegrationDialog';
import PanelLoader from 'views/components/Loader/PanelLoader';
import ErrorPageContent, {
  messages as errorMessages,
} from 'views/components/error/ErrorPageContent';
import { AccountFeature } from 'models/Account';

interface StateProps {
  hasPermission?: boolean;
}

const messages = defineMessages({
  title: {
    id: 'team.integrationsTab.type',
    defaultMessage: 'Create a new Integration',
  },
  text: {
    id: 'team.integrationsTab.text',
    defaultMessage:
      'Integrations allow Teams to integrate their account with an automated service. Integrations have an API Key that is accessible by all Team Admins.',
  },
  upgrateText: {
    id: 'team.integrationsTab.upgrate',
    defaultMessage: 'Upgrade to an Enterprise plan today to begin hunting!',
  },
  upgradeBtn: {
    id: 'team.integrationsTab.upgradeBtn',
    defaultMessage: 'Upgrade',
  },
  altImg: {
    id: 'team.integrationsTab.altImg',
    defaultMessage: 'Integrations image',
  },
});

export const TeamIntegrationsTab = () => {
  const { mfaEnabled } = useUser();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const { integrations, refetch, loading, error } = useIntegrations();

  const { hasPermission } = useSelector<RootState, StateProps>(({ team }) => {
    const features: AccountFeature[] = team?.account?.features ? team.account.features : [];
    const integrationsFeature = features.find((feature) => feature.tag === 'integration_ok');

    return {
      hasPermission: !!integrationsFeature,
    };
  });

  const _createIntegration = async (integrationData: Partial<Integration>) => {
    if (mfaEnabled) {
      dispatch(
        loginWriteApiKey({
          location: location.pathname,
          modal: 'CONFIRM_INTEGRATION_CREATION',
          integrationData,
        })
      );
    }
  };

  const _archiveIntegration = async (key: string) => {
    if (mfaEnabled) {
      dispatch(
        loginWriteApiKey({
          location: location.pathname,
          modal: 'CONFIRM_INTEGRATION_DELETION',
          key,
        })
      );
    }
  };

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal, integrationData, apiKeyId, key } = mfaStep;
      if (modal === 'CONFIRM_INTEGRATION_CREATION') {
        dispatch(
          openModal(modal, {
            integrationData,
            reloadIntegrations: () => {
              refetch();
            },
            removeMFAData,
          })
        );
      } else if (modal === 'CONFIRM_INTEGRATION_DELETION') {
        dispatch(
          openModal(modal, {
            apiKeyId,
            key,
            reloadIntegrations: () => {
              refetch();
            },
            removeMFAData,
          })
        );
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return (
      <ErrorPageContent
        className='h-mt-lg h-mb-lg'
        heading={intl.formatMessage(errorMessages.headingReload)}
        text={intl.formatMessage(errorMessages.textReload)}
      >
        <Button
          style={{ fontSize: '2.4rem' }}
          color='primary'
          variant='contained'
          onClick={refetch}
        >
          <FormattedMessage {...errorMessages.buttonReload} />
        </Button>
      </ErrorPageContent>
    );
  } else if (loading) {
    return <PanelLoader />;
  } else if (!integrations || integrations.length === 0 || !hasPermission) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '7rem',
        }}
      >
        <PanelContent
          extraStyles={{ paddingBottom: 0 }}
          imageComponent={<IntegrationsImg />}
          heading={intl.formatMessage(messages.title)}
          text={
            hasPermission
              ? intl.formatMessage(messages.text)
              : intl.formatMessage(messages.upgrateText)
          }
        />
        {hasPermission ? (
          <CreateIntegrationDialog onIntegrationCreate={_createIntegration} btnType='button' />
        ) : (
          <Button variant='contained' color='primary' onClick={() => history.push('/pricing')}>
            {intl.formatMessage(messages.upgradeBtn)}
          </Button>
        )}
      </div>
    );
  }

  return (
    <IntegrationsTable
      _createIntegration={_createIntegration}
      _archiveIntegration={_archiveIntegration}
      integrations={integrations as unknown as Integration[]}
    />
  );
};
