import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { RequestError, ErrorKeys, translateError, isErrorOfType } from 'utils/error';
import ScanSearchingState from './ScanSearchingState';

interface IHashSearchingState {
  isLoading: boolean;
  fileUploaded: boolean;
  error?: RequestError;
  uuid: string | null;
  submissionId?: string;
  hash: string;
  renderFailureBtn: () => JSX.Element;
  onCancel: () => void;
}

const messages = defineMessages({
  loading: {
    id: 'hash.searching.loading',
    defaultMessage: 'Scanning hash…',
  },
  error: {
    id: 'hash.searching.error',
    defaultMessage: 'Error scanning hash',
  },
  notFoundHeading: {
    id: 'hash.searching.notFound.heading',
    defaultMessage: 'Hash not found',
  },
  notFoundText: {
    id: 'hash.searching.notFound.text',
    defaultMessage: 'Please try a different hash',
  },
});

const errorKeys: ErrorKeys = {
  not_found_error: messages.notFoundText.id,
};

const HashSearchingState = ({
  isLoading,
  fileUploaded,
  error,
  uuid,
  submissionId,
  hash,
  renderFailureBtn,
  onCancel,
}: IHashSearchingState) => {
  const intl = useIntl();
  return (
    <ScanSearchingState
      isLoading={isLoading}
      fileUploaded={fileUploaded}
      loadingHeading={intl.formatMessage(messages.loading)}
      errorHeading={
        error && isErrorOfType('not_found_error', error)
          ? intl.formatMessage(messages.notFoundHeading)
          : intl.formatMessage(messages.error)
      }
      errorMessage={translateError(intl, errorKeys, error)}
      errorType={error && !isErrorOfType('not_found_error', error) ? 'error' : 'primary'}
      uuid={uuid}
      submissionId={submissionId}
      query={hash}
      renderFailureBtn={renderFailureBtn}
      onCancel={onCancel}
      type='hash'
    />
  );
};

export default HashSearchingState;
