import request from 'state/utils/request';
import api from 'services/api';
import { InvitationActionName, RemoveInviteTokenAction, SetInviteTokenAction } from './types';
import { Thunk } from 'state/types/thunk';
import { isAuthenticated } from 'state/auth/selectors';
import { history } from 'state/store';
import { openModal } from 'state/modal/actions';
import { TeamMemberInvitationModalParams } from 'views/pages/AuthCallbackPage/modals/TeamMemberInvitationModal';
import { login } from 'state/auth/actions';
import { InvitationResponseAction } from 'models/Invitation';

export const getInvitation = (token: string) =>
  request(() => api.getInvitation(token).then((res) => res.data), {
    type: InvitationActionName.GET_INVITATION,
  });

export const handleInvitation = (token: string, action: InvitationResponseAction) =>
  request(() => api.handleInvitation(token, action).then((res) => res.data), {
    type: InvitationActionName.HANDLE_INVITATION,
  });

export const setInviteToken = (inviteToken: string): SetInviteTokenAction => ({
  type: InvitationActionName.SET_INVITE_TOKEN,
  inviteToken,
});

export const removeInviteToken = (): RemoveInviteTokenAction => ({
  type: InvitationActionName.REMOVE_INVITE_TOKEN,
});

/**
 * Login and show modal assuming the user has an invite token.
 */
export const handleTeamInvitation =
  (location?: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { auth } = getState();
    const { inviteToken, lastLocation } = auth;
    if (isAuthenticated(auth) || (window as any).invitationState) {
      try {
        await dispatch(getInvitation(inviteToken!));
        const invitationRequestState = getState().invitation!.state;
        dispatch(
          openModal<TeamMemberInvitationModalParams>('TEAM_MEMBER_INVITATION', {
            invitationRequestState,
          })
        );
        history.replace(location || lastLocation || '/');
      } catch (error: any) {
        dispatch(
          openModal<TeamMemberInvitationModalParams>('TEAM_MEMBER_INVITATION', {
            errorModal: error,
          })
        );
        history.replace(location || lastLocation || '/');
      }
    } else {
      dispatch(login(location || lastLocation || '/'));
    }
  };
