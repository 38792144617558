import { css } from '@emotion/core';

const spacing = {
  grid: '30px',
  tiny: '0.8rem',
  xxs: '1.6rem',
  xs: '2.4rem',
  sm: '3.2rem',
  md: '4.8rem',
  lg: '7.2rem',
  xl: '9.6rem',
  xxl: '12.8rem',
};

export default spacing;

export const spacingHelpers = css`
  .h-mt-grid {
    margin-top: ${spacing.grid} !important;
  }
  .h-mt-tiny {
    margin-top: ${spacing.tiny} !important;
  }
  .h-mt-xxs {
    margin-top: ${spacing.xxs} !important;
  }
  .h-mt-xs {
    margin-top: ${spacing.xs} !important;
  }
  .h-mt-sm {
    margin-top: ${spacing.sm} !important;
  }
  .h-mt-md {
    margin-top: ${spacing.md} !important;
  }
  .h-mt-lg {
    margin-top: ${spacing.lg} !important;
  }
  .h-mt-xl {
    margin-top: ${spacing.xl} !important;
  }
  .h-mt-xxl {
    margin-top: ${spacing.xxl} !important;
  }
  .h-mb-grid {
    margin-bottom: ${spacing.grid} !important;
  }
  .h-mb-tiny {
    margin-bottom: ${spacing.tiny} !important;
  }
  .h-mb-xxs {
    margin-bottom: ${spacing.xxs} !important;
  }
  .h-mb-xs {
    margin-bottom: ${spacing.xs} !important;
  }
  .h-mb-sm {
    margin-bottom: ${spacing.sm} !important;
  }
  .h-mb-md {
    margin-bottom: ${spacing.md} !important;
  }
  .h-mb-lg {
    margin-bottom: ${spacing.lg} !important;
  }
  .h-mb-xl {
    margin-bottom: ${spacing.xl} !important;
  }
  .h-mb-xxl {
    margin-bottom: ${spacing.xxl} !important;
  }
  .h-pt-tiny {
    padding-top: ${spacing.tiny} !important;
  }
  .h-pt-xxs {
    padding-top: ${spacing.xxs} !important;
  }
  .h-pt-xs {
    padding-top: ${spacing.xs} !important;
  }
  .h-pt-sm {
    padding-top: ${spacing.sm} !important;
  }
  .h-pt-md {
    padding-top: ${spacing.md} !important;
  }
  .h-pt-lg {
    padding-top: ${spacing.lg} !important;
  }
  .h-pt-xl {
    padding-top: ${spacing.xl} !important;
  }
  .h-pt-xxl {
    padding-top: ${spacing.xxl} !important;
  }
  .h-pb-tiny {
    padding-bottom: ${spacing.tiny} !important;
  }
  .h-pb-xxs {
    padding-bottom: ${spacing.xxs} !important;
  }
  .h-pb-xs {
    padding-bottom: ${spacing.xs} !important;
  }
  .h-pb-sm {
    padding-bottom: ${spacing.sm} !important;
  }
  .h-pb-md {
    padding-bottom: ${spacing.md} !important;
  }
  .h-pb-lg {
    padding-bottom: ${spacing.lg} !important;
  }
  .h-pb-xl {
    padding-bottom: ${spacing.xl} !important;
  }
  .h-pb-xxl {
    padding-bottom: ${spacing.xxl} !important;
  }
`;
