import { AsyncAction } from 'state/types/actions';
import { UserRes, UserStatsRes } from 'services/api/schema/user';
import { AccountRes, CreateCustomerRes } from 'services/api/schema/account';
import { SelfContext } from 'models/Context';

export enum UserActionName {
  GET_USER = 'GET_USER',
  SET_USER = 'SET_USER',
  UPDATE_USER = 'UPDATE_USER',
  SET_MULTIFACTOR_AUTH = 'SET_MULTIFACTOR_AUTH',
  GET_USER_ACCOUNT = 'GET_USER_ACCOUNT',
  REFRESH_USER_ACCOUNT = 'REFRESH_USER_ACCOUNT',
  GET_USER_USAGE = 'GET_USER_USAGE',
  GET_USER_STATS = 'GET_USER_STATS',
  ARCHIVE_USER = 'ARCHIVE_USER',
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
}

export interface GetUserAction extends AsyncAction<UserActionName.GET_USER, UserRes | SelfContext> {}

export interface SetUserAction extends AsyncAction<UserActionName.SET_USER, UserRes> {}

export interface UpdateUserAction extends AsyncAction<UserActionName.UPDATE_USER, UserRes> {}

export interface SetMfaAction extends AsyncAction<UserActionName.SET_MULTIFACTOR_AUTH, UserRes> {}

export interface GetUserAccountAction
  extends AsyncAction<UserActionName.GET_USER_ACCOUNT, AccountRes> {}

export interface RefreshUserAccountAction
  extends AsyncAction<UserActionName.REFRESH_USER_ACCOUNT, AccountRes> {}

export interface GetUserUsageAction
  extends AsyncAction<UserActionName.GET_USER_USAGE, AccountRes> {}

export interface GetUserStatsAction
  extends AsyncAction<UserActionName.GET_USER_STATS, UserStatsRes> {}

export interface ArchiveUserAction extends AsyncAction<UserActionName.ARCHIVE_USER, {}> {}

export interface CreateCustomerAction
  extends AsyncAction<UserActionName.CREATE_CUSTOMER, CreateCustomerRes> {}

export type UserAction =
  | GetUserAction
  | SetUserAction
  | UpdateUserAction
  | SetMfaAction
  | GetUserAccountAction
  | RefreshUserAccountAction
  | GetUserUsageAction
  | GetUserStatsAction
  | ArchiveUserAction
  | CreateCustomerAction;
