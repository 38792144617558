import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import FilterTagsButton from './FilterTagsButton';
import styles from 'views/styles';
import styled from '@emotion/styled';
import TagInput from 'views/components/Tag/TagInput';
import { useOptions } from './hooks';

const Wrapper = styled.div`
  padding: ${styles.spacing.xs};
`;

const FilterTagsDialog = () => {
  const { loading, options } = useOptions();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const tags = query.has('tags') && query.get('tags') !== '' ? query.get('tags')!.split(',') : [];

  const onChange = (value: string[]) => {
    history.replace(`${location.pathname}?tags=${value.join(',')}`);
  };

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'tagsDialog',
  });

  return (
    <div>
      <FilterTagsButton {...bindTrigger(popupState)} />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Wrapper>
          <TagInput
            name='tags'
            label='Tags'
            width={400}
            options={options}
            value={tags}
            loading={loading}
            onChange={onChange}
          />
        </Wrapper>
      </Popover>
    </div>
  );
};

export default FilterTagsDialog;
