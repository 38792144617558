import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import formatBytes from 'utils/formatBytes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Panel from 'views/components/layout/Panel';
import ScanSearchInput from 'views/components/FileUpload/ScanSearchInput';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { errorMessages } from 'utils/error';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button/Button';
import Divider from '@material-ui/core/Divider';
import { Tooltip } from '@material-ui/core';
import styles from 'views/styles';
import Select from 'views/components/Select';
import Modal from 'views/components/Modal';
import { FileState, FileUpload } from 'views/components/FileUpload';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Icon from 'views/components/Icon';
import useGetVMs from './hooks/useGetVMs';
import { showNotification } from 'state/notification/actions';
import Switch from '@material-ui/core/Switch';
import useIsPrivateContext from 'hooks/useIsPrivateContext';
import { useUser } from 'views/components/providers/UserProvider';
import { SandboxProviders } from 'models/Sandbox';
import { UploadFile } from 'views/components/UploadFile';

interface ConfirmIntegrationCreationParams {
  onSubmit: () => void;
  instanceId: string;
  isResandbox: boolean;
  defaultType?: string;
  defaultHash?: string;
  defaultProvider?: string;
  modalShareData?: boolean;
}

const messages = {
  selectSandboxProvider: 'Select Sandbox Provider and Detonation VM',
  cancel: 'Cancel',
  submit: 'Submit',
  submitting: 'Submitting...',
  hint: "You need to press 'Enter' or click the search icon to sanbox via hash",
};

const fileUploadMessages = {
  hashText: {
    input: 'Sandbox a Hash',
  },
  urlText: {
    input: 'Sandbox a URL',
  },
  scan: {
    heading: 'Submit to Sandbox',
    text: 'Drop your file to upload',
  },
};

const SandboxSubmissionTabs = ({
  setTabId,
  tabId,
  setFile,
  setSandboxValue,
  modalShareData,
  file,
}: {
  setTabId: (id: string) => void;
  tabId: string;
  setFile: (file: FileState | null) => void;
  setSandboxValue: (value: { [K: string]: string }) => void;
  modalShareData: boolean;
  file: FileState | null;
}) => {
  const { classes } = useStyles();
  return (
    <>
      <Tabs centered indicatorColor='primary' value={tabId} onChange={(_, id) => setTabId(id)}>
        <Tab
          value='file'
          label='File'
          onClick={() => {
            setFile(null);
          }}
        />
        <Tab value='hash' label='Hash' />
        <Tab value='url' label='URL' />
        <Tab
          value='qr'
          label='QR Code'
          onClick={() => {
            setFile(null);
          }}
        />
      </Tabs>
      <Panel>
        {tabId === 'file' && (
          <FileUpload
            hasHeading={false}
            isScan={false}
            isModal={true}
            onFileInput={(file) => {
              setFile(file);
            }}
            modalShareData={modalShareData}
            messages={fileUploadMessages}
          />
        )}
        {(tabId === 'hash' || tabId === 'url') && (
          <ScanSearchInput
            key={tabId}
            onlyHash={tabId === 'hash'}
            onlyUrl={tabId === 'url'}
            messages={tabId === 'hash' ? fileUploadMessages.hashText : fileUploadMessages.urlText}
            onChange={(inputValue) => {
              setSandboxValue({ type: tabId, value: inputValue });
            }}
          />
        )}
        {tabId === 'qr' && (
          <div>
            {file && file.file ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ position: 'relative' }}>
                  <img src={URL.createObjectURL(file.file)} alt='QR code' width={100} />
                  <span css={classes.closeQrIcon} onClick={() => setFile(null)}>
                    <Icon name='close' style={{ width: '10px' }} />
                  </span>
                </div>
              </div>
            ) : (
              <UploadFile
                accept='.jpg,.jpeg,.png,.gif'
                btnText='Select image file'
                hintText='or drag and drop QR code'
                onFileSelect={(file) => {
                  setFile({ ...file, isQRCode: true });
                }}
              />
            )}
          </div>
        )}
      </Panel>
    </>
  );
};

const Heading = styled.h2`
  text-align: center;
  font-weight: 600;
  font-size: ${styles.font.size.h3};
  line-height: 1.2;
  color: #3f3f3f;
  padding-bottom: 1rem;
  font-weight: bold;
`;

const uploadToSandbox = async ({
  file,
  selectedProvider,
  selectedVM,
  artifactType,
  accountNumber,
}: {
  file: FileState;
  selectedProvider: string;
  selectedVM: string;
  artifactType: 'FILE' | 'URL';
  accountNumber: number;
}) => {
  try {
    const response = (
      await api.submitFileForSandBox(
        file.filename,
        {
          providerSlug: selectedProvider,
          vmSlug: selectedVM,
        },
        artifactType,
        file.zipConfig,
        file.isQRCode
      )
    )?.data;

    const { result = {} } = response;
    const { id, uploadUrl } = !isEmpty(result) ? result : { id: '', uploadUrl: '' };

    if (!!id && !!uploadUrl && !!file?.file) {
      await api.uploadFile('put', uploadUrl, file.file);
      await api.confirmSandboxFile(id);
    }
  } catch (e) {
    throw e;
  }
};

type SelectedSandboxesType = Record<SandboxProviders, string>;
type SandboxType = 'url' | 'file' | 'hash' | 'qr';

const SubmitToSandboxModal = () => {
  const isPrivateContext = useIsPrivateContext();
  const { accountNumber } = useUser();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { providers, isLoading: isProvidersLoading } = useGetVMs();
  const [isLoading, setLoading] = React.useState(false);
  const [sandboxValue, setSandboxValue] = React.useState<{
    type?: SandboxType;
    value?: string;
  }>({});
  const [selectedSandboxes, setSelectSandboxes] = React.useState<SelectedSandboxesType>(
    {} as SelectedSandboxesType
  );
  const [file, setFile] = React.useState<FileState | null>(null);
  const [networkEnabled, setNetworkEnabled] = React.useState<boolean>(false);
  const [tabId, setTabId] = useState('file');

  const {
    onSubmit: onSubmitAfterSandboxTaskCreation,
    instanceId,
    defaultHash,
    defaultType,
    defaultProvider,
    modalShareData = false,
    isResandbox = false,
  } = useSelector(
    ({ modal }: { modal: ModalState<ConfirmIntegrationCreationParams> }) => modal.params
  );

  const _onSubmit = async () => {
    setLoading(true);

    try {
      if (!!instanceId && isResandbox) {
        await Promise.all(
          Object.keys(selectedSandboxes).map((selectedProvider) =>
            api.submitInstanceToSandbox(instanceId, {
              providerSlugs: [selectedProvider],
              vmSlug: selectedSandboxes[
                selectedProvider as SandboxProviders
              ] as keyof SelectedSandboxesType,
              networkEnabled: networkEnabled,
            })
          )
        );
      } else if (!!file) {
        await Promise.all(
          Object.keys(selectedSandboxes).map((selectedProvider) =>
            uploadToSandbox({
              file,
              selectedVM: selectedSandboxes[
                selectedProvider as SandboxProviders
              ] as keyof SelectedSandboxesType,
              selectedProvider,
              artifactType: 'FILE',
              accountNumber,
            })
          )
        );
      } else if (sandboxValue.type === 'hash' && !!sandboxValue?.value) {
        await Promise.all(
          Object.keys(selectedSandboxes).map((selectedProvider) =>
            api.submitHashToSandBox(sandboxValue.value as string, {
              providerSlugs: [selectedProvider],
              vmSlug: selectedSandboxes[
                selectedProvider as SandboxProviders
              ] as keyof SelectedSandboxesType,
              networkEnabled: networkEnabled,
            })
          )
        );
      } else if (sandboxValue.type === 'url' && !!sandboxValue?.value) {
        const textContent = sandboxValue.value;

        const blob = new Blob([textContent], { type: 'text/plain' });
        const urlFile = new File([blob], textContent, { type: 'text/plain' });

        const filename = urlFile.name;
        const filesize = formatBytes(urlFile.size);

        await Promise.all(
          Object.keys(selectedSandboxes).map((selectedProvider) =>
            uploadToSandbox({
              file: { file: urlFile, filename, filesize },
              selectedVM: selectedSandboxes[
                selectedProvider as SandboxProviders
              ] as keyof SelectedSandboxesType,
              selectedProvider,
              artifactType: 'URL',
              accountNumber,
            })
          )
        );
      } else {
        dispatch(
          showNotification({
            status: 'failure',
            delay: 10000,
            message: errorMessages.server,
          })
        );
      }

      window.setTimeout(() => {
        dispatch(
          showNotification({
            status: 'success',
            delay: 10000,
            message:
              'The instance is queued for sandboxing. You can monitor the status on My Sandboxing tab.',
          })
        );
        onSubmitAfterSandboxTaskCreation();
        dispatch(closeModal());
      }, 4000);
    } catch (e) {
      setLoading(false);
      console.warn(e);

      // @ts-ignore
      if (e?.response?.status) {
        // @ts-ignore
        const serverError = e?.response?.data?.message;

        dispatch(
          showNotification({
            status: 'failure',
            delay: 10000,
            message:
              serverError ??
              `Hash not found. Artifact with hash ${sandboxValue.value} was not found.`,
          })
        );
      } else {
        dispatch(
          showNotification({
            status: 'failure',
            delay: 10000,
            message: errorMessages.server,
          })
        );
      }
    }

    setFile(null);
    setSandboxValue({});
  };

  useEffect(() => {
    if (defaultProvider && providers.length > 0) {
      const [provider, vmName] = defaultProvider.split(' - ');
      const vm = providers
        .find((p) => p.name === provider)
        ?.vms.find((v) => v.name === vmName || v.os_name === vmName);
      if (vm?.slug) {
        setSelectSandboxes((state) => ({ ...state, [provider]: vm.slug }));
      }
    } else if (providers.length > 0) {
      const vmSlug =
        providers[0].vms.find((vm) =>
          vm.supported_artifact_types.includes(
            tabId === 'hash' ? 'FILE' : tabId === 'qr' ? 'URL' : tabId.toUpperCase()
          )
        )?.slug ?? '';

      setSelectSandboxes((state) => ({ ...state, [providers[0].name]: vmSlug }));
    }
  }, [tabId, defaultProvider, providers]);

  useEffect(() => {
    if (defaultHash) {
      setSandboxValue({
        type: defaultType! as SandboxType,
        value: defaultHash,
      });
      setTabId(defaultType as SandboxType);
    }
  }, [defaultHash, defaultType]);

  useEffect(() => {
    setNetworkEnabled(!isPrivateContext);
  }, [isPrivateContext]);

  return (
    <Modal fullWidth maxWidth='md'>
      <div css={classes.container}>
        <Heading css={classes.header}>{fileUploadMessages.scan.heading}</Heading>
        {defaultHash ? (
          <span css={classes.fileOrHashLoaded}>
            {`File Hash: ${defaultHash}`}
            <button onClick={() => setSandboxValue({})}>
              <Icon css={classes.closeIcon} name='close' />
            </button>
          </span>
        ) : (
          <SandboxSubmissionTabs
            setTabId={setTabId}
            tabId={tabId}
            setFile={setFile}
            setSandboxValue={setSandboxValue}
            modalShareData={modalShareData}
            file={file}
          />
        )}
        <Divider />
        <div>
          <span css={classes.sectionTitle}>{messages.selectSandboxProvider}</span>
          {isProvidersLoading ? (
            <CircularProgress />
          ) : (
            providers?.map((provider) => (
              <div key={provider.name} css={classes.selectRow}>
                <div css={classes.provider}>
                  <Checkbox
                    color='primary'
                    checked={has(selectedSandboxes, provider.name)}
                    onChange={() => {
                      const [vm = { slug: '' }] =
                        provider.name !== SandboxProviders.TRIAGE
                          ? provider.vms
                          : Object.values(provider.vms).filter((item) =>
                              item.slug.includes('win10')
                            );

                      setSelectSandboxes((state) => {
                        if (has(state, provider.name)) {
                          const newState = omit(state, provider.name) as SelectedSandboxesType;
                          return newState;
                        }
                        return { ...state, [provider.name]: vm?.slug ?? '' };
                      });
                    }}
                  />
                  <span>{provider.name}</span>
                </div>
                <div css={classes.selectContainer}>
                  <Select
                    key={tabId}
                    placeholder='Select detonation VM'
                    onChange={(value) => {
                      setSelectSandboxes((state) => ({ ...state, [provider.name]: value }));
                    }}
                    value={
                      has(selectedSandboxes, provider.name)
                        ? selectedSandboxes?.[provider.name as SandboxProviders] ?? ''
                        : ''
                    }
                    options={provider.vms
                      .filter((vm) =>
                        vm.supported_artifact_types.includes(
                          tabId === 'hash' ? 'FILE' : tabId === 'qr' ? 'URL' : tabId.toUpperCase()
                        )
                      )
                      .map((vm) => vm.slug)}
                  />
                </div>
              </div>
            ))
          )}
        </div>
        <div>
          <div css={classes.infoContainer}>
            <span css={classes.infoTitle}>Internet Access</span>
            <Tooltip
              title='If the toggle is set to “disable” the detonated file will not be allowed to connect to the internet.'
              placement='bottom'
            >
              <div>
                <Icon name='info' css={classes.infoIcon} />
              </div>
            </Tooltip>
          </div>
          <div>
            <div>
              <span>Disabled</span>
              <Switch
                color='primary'
                onChange={(e) => setNetworkEnabled(e.target.checked)}
                checked={networkEnabled}
              />
              <span>Enabled</span>
            </div>
          </div>
        </div>
        <div css={classes.buttonsContainer}>
          <Button
            onClick={() => dispatch(closeModal())}
            size='medium'
            variant='outlined'
            color='primary'
          >
            {messages.cancel}
          </Button>
          <Button
            disabled={
              !(
                ((!!sandboxValue.type && !!sandboxValue.value) || !!file?.file) &&
                !!Object.keys(selectedSandboxes)?.length &&
                !!Object.values(selectedSandboxes)?.length
              )
            }
            onClick={_onSubmit}
            size='medium'
            variant='contained'
            color='primary'
          >
            {isLoading ? messages.submitting : messages.submit}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    header: {
      color: styles.color.black,
    },
    provider: {
      width: '12rem',
    },
    container: {
      padding: '5rem 7rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '2rem',
    },
    sectionTitle: {
      fontWeight: 'bold',
      display: 'block',
      marginBottom: '2rem',
    },
    selectRow: {
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'flex-end',
      width: '80%',
      marginBottom: '1rem',
    },
    selectContainer: {
      width: '30rem',
    },
    fileOrHashLoaded: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2rem',
      borderRadius: '1rem',
    },
    closeIcon: {
      width: '1.5rem !important',
      height: '2.5rem !important',
      cursor: 'pointer',
    },
    hint: {
      fontSize: '1.3rem',
      display: 'block',
      color: '#ccc',
      textAlign: 'center',
    },
    infoIcon: {
      width: '2rem !important',
      height: '3rem !important',
      cursor: 'help',
      color: styles.color.xLightGrey,
    },
    infoTitle: {
      fontWeight: 'bold',
      display: 'block',
    },
    infoContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    closeQrIcon: {
      position: 'absolute',
      top: '-8px',
      right: '-8px',
      cursor: 'pointer',
      background: 'rgba(0, 0, 0, 0.5)',
      width: '25px',
      height: '25px',
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
    fileOrHashLoaded: {
      backgroundColor: styles.color.xLightPurple,
      color: styles.color.purple,
      border: `2px dashed ${styles.color.purple}`,
    },
    closeIcon: {
      color: styles.color.purple,
    },
    hint: {
      color: styles.color.xLightGrey,
    },
  },
  dark: {
    header: {
      color: styles.color.xxLightGrey,
    },
    fileOrHashLoaded: {
      color: styles.color.lightBlue,
      border: `2px dashed ${styles.color.lightBlue}`,
    },
    closeIcon: {
      color: styles.color.lightBlue,
    },
    hint: {
      color: styles.color.xxLightPurple,
    },
  },
});
export default SubmitToSandboxModal;
