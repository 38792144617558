import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from 'views/styles';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { KeyboardArrowLeft, KeyboardArrowRight, FirstPage } from '@material-ui/icons';
import styled from '@emotion/styled';

interface ICustomTablePagination {
  isFirst: boolean;
  isLast: boolean;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  onChangePage: (direction: EPageDirection) => void;
  onFirstPage?: () => void;
  onChangeRowsPerPage: (limit: number) => void;
}

export enum EPageDirection {
  PREV,
  NEXT,
  FIRST,
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

const useTextStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
});

const useMenuStyles = makeStyles({
  root: {
    fontSize: styles.font.size.p1,
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 38,
    fontWeight: styles.font.weight.medium,
  },
});

const useSelectStyles = makeStyles({
  root: {
    padding: '8px 22px 8px 6px',
    lineHeight: 1,
    fontSize: styles.font.size.p1,
  },
  icon: {
    right: 6,
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

const useInputStyles = makeStyles({
  root: {
    margin: '0 4px 0 12px',
  },
});

export const CustomTablePagination = ({
  rowsPerPage,
  rowsPerPageOptions,
  onChangeRowsPerPage,
  onChangePage,
  onFirstPage,
  isFirst,
  isLast,
}: ICustomTablePagination) => {
  const textStyles = useTextStyles();
  const menuStyles = useMenuStyles();
  const selectStyles = useSelectStyles();
  const inputStyles = useInputStyles();
  return (
    <Wrapper>
      <Typography classes={textStyles} variant='body2'>
        <FormattedMessage id='pagination.rowsText' defaultMessage='Rows per page:' />
      </Typography>
      <Select
        classes={selectStyles}
        input={<InputBase classes={inputStyles} />}
        value={rowsPerPage}
        onChange={(e) => onChangeRowsPerPage(Number(e.target.value))}>
        {rowsPerPageOptions.map((value) => (
          <MenuItem key={value} classes={menuStyles} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      <IconButton
        disabled={isFirst}
        onClick={
          onFirstPage
            ? () => !isFirst && onFirstPage()
            : () => !isFirst && onChangePage(EPageDirection.FIRST)
        }>
        <FirstPage />
      </IconButton>
      <IconButton disabled={isFirst} onClick={() => !isFirst && onChangePage(EPageDirection.PREV)}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton disabled={isLast} onClick={() => !isLast && onChangePage(EPageDirection.NEXT)}>
        <KeyboardArrowRight />
      </IconButton>
    </Wrapper>
  );
};
