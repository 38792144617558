import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-delete' viewBox='0 0 32 32'>
      <path d='M21.333 12v13.333h-10.667v-13.333h10.667zM19.333 4h-6.667l-1.333 1.333h-4.667v2.667h18.667v-2.667h-4.667l-1.333-1.333zM24 9.333h-16v16c0 1.467 1.2 2.667 2.667 2.667h10.667c1.467 0 2.667-1.2 2.667-2.667v-16z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-delete {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
