import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import SidebarDefault from 'views/components/layout/Sidebar/SidebarDefault';

const messages = defineMessages({
  hashHeading: {
    id: 'hash.sidebar.heading',
    defaultMessage: 'Hash Searching',
  },
  hashText: {
    id: 'hash.sidebar.text',
    defaultMessage:
      'Hash searching allows you to gain insight by finding previously submitted artifacts by its hash.',
  },
  metadataHeading: {
    id: 'metadata.sidebar.heading',
    defaultMessage: 'Metadata Searching',
  },
  metadataText: {
    id: 'metadata.sidebar.text',
    defaultMessage:
      'Metadata searching allows you to gain insight by finding previously submitted artifacts according to its metadata.',
  },
  iocHeading: {
    id: 'ioc.sidebar.heading',
    defaultMessage: 'IOC Search',
  },
  iocText: {
    id: 'ioc.sidebar.text',
    defaultMessage:
      'IOC searching allows you to find the IOCs associated with a hash OR find the hashes associate with IOCs.',
  },
});

const SearchSidebar = () => {
  const intl = useIntl();
  const location = useLocation();

  if (location.pathname === '/search/hash') {
    return (
      <SidebarDefault
        heading={intl.formatMessage(messages.hashHeading)}
        text={intl.formatMessage(messages.hashText)}
      />
    );
  }

  if (location.pathname === '/search/metadata') {
    return (
      <SidebarDefault
        heading={intl.formatMessage(messages.metadataHeading)}
        text={intl.formatMessage(messages.metadataText)}
        isLoginRequired={true}
      />
    );
  }

  if (location.pathname === '/search/ioc') {
    return (
      <SidebarDefault
        heading={intl.formatMessage(messages.iocHeading)}
        text={intl.formatMessage(messages.iocText)}
        isLoginRequired={true}
      />
    );
  }

  // If route not found
  return null;
};

export default SearchSidebar;
