import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';
import styles from 'views/styles';

export default () => (
  <Fragment>
    <symbol id='icon-pivot' viewBox='0 0 19 20'>
      <path d='M17.092 1H1.957a.5.5 0 00-.41.786l6.042 8.685a.5.5 0 01.09.286v7.488a.5.5 0 00.695.46l3.25-1.376a.5.5 0 00.305-.46v-6.124a.5.5 0 01.077-.267l5.509-8.71A.5.5 0 0017.092 1z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-pivot {
          width: ${styles.icon.width};
          height: ${styles.icon.height};
        }
      `}
    />
  </Fragment>
);
