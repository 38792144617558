import { ISandboxData, ITriageData } from 'models/Sandbox';
import { uniq, uniqBy } from 'lodash';

interface NetworkValue {
  value: string;
  title: string;
}

const mapArray = (condition: boolean, data: any) => {
  return condition ? [data] : [];
};

const compareIpAddress = (a: NetworkValue, b: NetworkValue) => {
  const getNumber = (ipNumber: string) =>
    Number(
      ipNumber
        .split('.')
        .map((num: string) => `000${num}`.slice(-3))
        .join('')
    );
  const num1 = getNumber(a.value);
  const num2 = getNumber(b.value);
  return num1 - num2;
};

export const mapNetworkData = ({
  metadataIndex,
  triageIndex,
  networkData,
  triageData,
}: {
  metadataIndex: number;
  triageIndex: number;
  networkData: ISandboxData['tool_metadata']['network'];
  triageData: any;
}) => {
  if (!networkData) return null;

  const { ips, domains, urls, dead_hosts } = getNetworkData(
    metadataIndex,
    triageIndex,
    networkData,
    triageData
  );

  const network = mapArray(!!networkData, {
    component: 'list',
    data: {
      ...(ips.length && { ips }),
      ...(domains.length && { domains }),
      ...(urls.length && { urls }),
      ...(dead_hosts.length && { dead_hosts }),
    },
  });

  return network;
};

export const getNetworkData = (
  metadataIndex: number,
  triageIndex: number,
  networkData: ISandboxData['tool_metadata']['network'],
  triageData: ITriageData['tool_metadata']
) => {
  if (!networkData)
    return {
      ips: [],
      domains: [],
      urls: [],
      dead_hosts: [],
    };

  const toolMetadataKey = `metadata[${metadataIndex}].tool_metadata`;
  const traigeToolMetadataKey = `metadata[${triageIndex}].tool_metadata`;
  const networkDataKey = `${toolMetadataKey}.network`;
  const mapAndFlatten = (data: any = [], mapper: any, key: any) => {
    if (!data || !data.length) return [];
    const unique = uniq(data.map(mapper).flat(2).filter(Boolean));
    return unique.map((item, index) => ({
      value: item as string,
      title: `${key}[${index}]`,
    }));
  };

  const extractedC2Ips = mapAndFlatten(
    triageData?.extracted_c2_ips,
    (item: any) => item?.extracted_c2_ips,
    `${traigeToolMetadataKey}.extracted_c2_ips`
  );

  const c2Config = mapAndFlatten(
    triageData?.extracted,
    (item: any) => item?.config?.c2,
    `${traigeToolMetadataKey}.extracted.config.c2`
  );

  const iocsIps = mapAndFlatten(
    triageData?.targets,
    (item: any) => item?.iocs?.ips,
    `${traigeToolMetadataKey}.targets.iocs.ips`
  );

  const iocsDomains = mapAndFlatten(
    triageData?.targets,
    (item: any) => item?.iocs?.domains,
    `${traigeToolMetadataKey}.targets.iocs.domains`
  );

  const iocsUrls = mapAndFlatten(
    triageData?.targets,
    (item: any) => item?.iocs?.urls,
    `${traigeToolMetadataKey}.targets.iocs.urls`
  );

  const ips =
    networkData?.hosts?.map((host, index) => {
      return {
        value: host.ip,
        title: `${networkDataKey}.hosts[${index}].ip`,
      };
    }) || [];

  const domains =
    networkData?.domains?.map((domain, index) => {
      return {
        value: domain.domain,
        title: `${networkDataKey}.domains[${index}].domain`,
      };
    }) || [];

  const urls =
    networkData?.dns?.map((dns, index) => {
      return {
        value: dns.request,
        title: `${networkDataKey}.dns[${index}].request`,
      };
    }) || [];

  const dead_hosts = networkData?.dead_hosts?.map((dead_host, index) => {
    return {
      value: dead_host,
      title: `${networkDataKey}.dead_hosts[${index}]`,
    };
  });

  return {
    ips: uniqBy([...ips, ...extractedC2Ips, ...c2Config, ...iocsIps], (item) => item.value).sort(
      compareIpAddress
    ),
    dead_hosts: (dead_hosts || []).sort(compareIpAddress),
    domains: uniqBy([...domains, ...iocsDomains], (item) => item.value).sort(),
    urls: uniqBy([...urls, ...iocsUrls], (item) => item.value).sort(),
  };
};
