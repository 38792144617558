import React, { useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styles from 'views/styles';
import NavigationLink from './NavigationLink';
import Icon from 'views/components/Icon';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { history } from 'state/store';

interface Props {
  options: {
    id: number;
    to: string;
    hide?: boolean;
    label: MessageDescriptor;
    matches?: string[];
    dataCy?: string;
  }[];
}

const HeaderNav = ({ options }: Props) => (
  <>
    <HeaderDesktopNav options={options} />
    <HeaderMobileNav options={options} />
  </>
);

const HeaderDesktopNav = ({ options }: Props) => (
  <div css={desktopStyle}>
    <ul>
      {options
        .filter((option) => !option.hide)
        .map((option) => (
          <li key={option.id}>
            <NavigationLink
              to={option.to}
              label={option.label}
              matches={option.matches}
              dataCy={option.dataCy}
            />
          </li>
        ))}
    </ul>
  </div>
);

const HeaderMobileNav = ({ options }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const muiClasses = useMuiStyles();

  const _handleOpen = (e: React.MouseEvent<any>) => setAnchorEl(e.currentTarget);

  const _handleClose = () => setAnchorEl(null);
  const _handleClick = (to: string) => {
    history.push(to);
    _handleClose();
  };

  return (
    <div css={mobileStyle}>
      <Icon name='menu' onClick={_handleOpen} />
      <Menu
        id='mobile-menu'
        classes={{ paper: muiClasses.paper }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={_handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((item) => {
          if (item.hide) {
            return null;
          }
          return (
            <MenuItem
              key={String(item.id)}
              id={String(item.id)}
              onClick={() => _handleClick(item.to)}
            >
              <FormattedMessage {...item.label} />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

const desktopStyle = css`
  display: flex;
  flex: 1;
  li {
    display: inline;
  }
  a {
    margin-left: ${styles.spacing.sm};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const mobileStyle = css`
  display: none;
  backgroud-color: red;
  flex: 1;

  & svg {
    cursor: pointer;
    color: var(--main);
    width: 3rem;
    margin-left: 2rem;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const useMuiStyles = makeMuiStyles({
  paper: {
    minWidth: '21.2rem',
    width: 'auto',
    borderRadius: '1rem',
  },
});

export default HeaderNav;
