import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-check' viewBox='0 0 47 32'>
      <path d='M13.665 29.827l-12.531-12.634c-1.515-1.528-1.51-3.993 0.011-5.515v0c1.511-1.511 3.962-1.511 5.473 0 0.005 0.005 0.010 0.010 0.015 0.015l10.050 10.163c0.981 0.992 2.581 1.001 3.573 0.020 0.015-0.015 0.029-0.030 0.044-0.045l19.883-20.662c1.462-1.519 3.879-1.566 5.398-0.104 0.018 0.017 0.035 0.034 0.052 0.051v0c1.527 1.527 1.546 3.996 0.044 5.547l-22.391 23.106c-2.589 2.672-6.854 2.739-9.526 0.15-0.032-0.031-0.063-0.062-0.095-0.094z' />
    </symbol>

    <Global
      styles={css`
        .icon-check {
          width: 4.7em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
