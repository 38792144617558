import { SandboxAction, SandboxActionName } from './types';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import { Sandbox, SandboxReport } from 'models/Sandbox';
import intersection from 'lodash/intersection';
import { SandboxState } from '.';

export const initialState: SandboxState = { list: [] };

const sandbox = (state: SandboxState = initialState, action: SandboxAction): SandboxState => {
  switch (action.type) {
    case SandboxActionName.GET_SANDBOX_TASK_ID: {
      const sha256 = state.list?.[0]?.sha256;
      const result = action?.result ?? { sha256: '', id: '' };

      if (sha256 === result.sha256) {
        const foundSameTaskId = state.list.find((item) => item.id === result.id);

        if (isEmpty(foundSameTaskId)) {
          return { ...state, list: [action?.result as Sandbox<SandboxReport>].concat(state.list) };
        }
        return state;
      }

      return { ...state, list: [action?.result as Sandbox<SandboxReport>].filter(Boolean) };
    }
    case SandboxActionName.GET_SANDBOXES_HASH: {
      const newSandboxesList = ([] as Sandbox<SandboxReport>[]).concat(
        (action?.result ?? []) as Sandbox<SandboxReport>
      );

      /*
       * To have only new sandboxes
       */
      const newIds = newSandboxesList.map((item) => item.id);
      const currentIds = state.list.map((item) => item.id);
      const intersectionIds = intersection(newIds, currentIds);
      const newSandboxes = newSandboxesList.filter((item) => !intersectionIds.includes(item.id));

      const currentSha256 = uniq(newSandboxesList.map((item) => item.sha256))?.[0] ?? '';

      // @ts-ignore
      if (currentSha256 === state.list?.[0]?.sha256 ?? currentSha256) {
        return {
          ...state,
          list: state.list.concat(newSandboxes ?? []),
        };
      }

      return {
        ...state,
        list: isEmpty(newSandboxesList) ? state.list : newSandboxesList,
      };
    }
    default: {
      return state;
    }
  }
};
export default sandbox;
