import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from 'state/notification/actions';
import api from 'services/api';
import { getErrorMessage, RequestError } from 'utils/error';

export const useCreateIntegration = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');

  async function createIntegration(data: any) {
    try {
      setError('');
      setLoading(true);

      const response = await api.createIntegration(data);
      setLoading(false);
      return response.data;
    } catch (error: any) {
      const errorMessage = getErrorMessage(error as RequestError);
      setLoading(false);
      setError(errorMessage);
      dispatch(
        showNotification({
          status: 'failure',
          message: `Error creating integration: ${errorMessage}`,
        })
      );
      return error;
    }
  }

  return {
    createIntegration,
    loading,
    error,
  };
};
