import { AsyncAction } from 'state/types/actions';
import { Sandbox, SandboxReport } from 'models/Sandbox';

export enum SandboxActionName {
  GET_SANDBOXES_HASH = 'GET_SANDBOXES_HASH',
  GET_SANDBOX_TASK_ID = 'GET_SANDBOX_TASK_ID',
}
export interface GetSandboxesHashAction
  extends AsyncAction<SandboxActionName.GET_SANDBOXES_HASH, Sandbox<SandboxReport>> {}

export interface GetSandboxTaskIdAction
  extends AsyncAction<SandboxActionName.GET_SANDBOX_TASK_ID, Sandbox<SandboxReport>> {}

export type SandboxAction = GetSandboxTaskIdAction | GetSandboxesHashAction;
