import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/root';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import Placeholder, { ISharedPlacholderProps } from './Placeholder';

interface IPlaceholderConatiner extends ISharedPlacholderProps {
  loadingActions: any[];
  errorActions?: any[];
}

const PlaceholderContainer: FC<IPlaceholderConatiner> = (props) => {
  const { loadingActions, errorActions, children, ...rest } = props;
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, loadingActions);
  const error = errorSelector(requests, errorActions || loadingActions);
  return (
    <Placeholder isLoading={isLoading} error={error} {...rest}>
      {typeof children === 'function' ? children() : children}
    </Placeholder>
  );
};

export default PlaceholderContainer;
