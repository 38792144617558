import api from 'services/api';
import request from 'state/utils/request';
import { defineMessages } from 'react-intl';
import { Thunk } from 'state/types/thunk';
import { history } from 'state/store';
import { AccountActionName } from './types';
import { showNotification } from 'state/notification/actions';
import { ErrorKeys, errorMessages, reduxErrorBanner } from 'utils/error';
import { getUserAccount } from 'state/user/actions';
import { getTeamAccount } from 'state/team/actions';
import { PaymentMethodRes } from 'services/api/schema/account';
import { transformPaymentPlans } from './reducers';
import { closeModal } from 'state/modal/actions';
import { generalMessages } from 'views/index';
import { ContextAccount, setLastContextAccount } from 'state/auth/selectors';
import { parseSwitchURL } from '../../views/url';

const messages = defineMessages({
  toggleNotificationsFailure: {
    id: 'account.profile.toggleNotificationFailure',
    defaultMessage: 'There was an issue updating your settings. Please try again.',
  },
  cancelPendingPlanSuccess: {
    id: 'account.plans.cancelPendingSuccess',
    defaultMessage: 'Plan change cancelled successfully.',
  },
  cancelPendingPlanFailure: {
    id: 'account.plans.cancelPendingFailure',
    defaultMessage: 'There was an issue cancelling your plan change. Please try again.',
  },
});

const errorKeys: ErrorKeys = {
  api_key_not_found_for_account: errorMessages.noApiKeys.id,
};

export const getAccountPlans = () =>
  // @ts-ignore
  request(() => api.getAccountPlans().then((res) => res.data), {
    type: AccountActionName.GET_ACCOUNT_PLANS,
  });

export const getAccountContext =
  (ctx?: ContextAccount): Thunk<Promise<void>> =>
  async (dispatch) => {
    return api
      .getAccountContext(ctx)
      .then((res) => res.data)
      .then((result) => {
        // Hack to remember last session when the user comes back
        // from the same device / browser
        setLastContextAccount({
          accountNumber: result.accountNumber,
          context: result.context,
          isPrivate: false,
        });
        dispatch({
          type: AccountActionName.GET_ACCOUNT_CONTEXT,
          result: { ...result, isPrivate: ctx?.isPrivate },
        });
      });
  };

export const switchAccount = (accountNumber: number) =>
  request(() => api.switchAccount(accountNumber).then((res) => res.data), {
    type: AccountActionName.SWITCH_ACCOUNT,
  });

/**
 * Switch account and get account and account context.
 *
 * @param accountNumber user account number or team account number
 * @param isTeamContext is redirected to a team context?
 * @param customRedirectUrl URL to redirect if the switch is successful
 */
export const switchAndRefreshAccount =
  (
    accountNumber: number | string,
    isTeamContext?: boolean,
    customRedirectUrl?: string
  ): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    const user = getState().user.profile;
    let redirectUrl = '/account/profile';
    if (isTeamContext) {
      try {
        const userTeam = user?.teams.find(
          (t) => t.accountNumber.toString() === accountNumber.toString()
        );
        const userRoles = userTeam?.roles || [];
        const team = await api.getTeamByAccount(accountNumber).then((res) => res.data);
        if (team.forceMfa && !userRoles.includes('teamOwner') && !user?.mfaEnabled) {
          dispatch(
            showNotification({
              status: 'failure',
              message: errorMessages.teamForceMfa,
              delay: 1000 * 10,
            })
          );
          history.replace('/account/advanced');
          return;
        }
        redirectUrl = `/u/${accountNumber}/settings/team/settings`;
      } catch (error) {
        dispatch(
          showNotification({ status: 'failure', message: errorMessages.switchAccountFailure })
        );
      }
    }
    try {
      const result = await api.switchAccount(accountNumber).then((res) => res.data);
      dispatch({ type: AccountActionName.SWITCH_ACCOUNT, result });
      if (result.userKeyCreated || result.teamKeyCreated) {
        dispatch(showNotification({ status: 'success', message: generalMessages.newApiKey }));
      }
      const ctx = {
        accountNumber: result.accountNumber,
        context: result.context,
        isPrivate: false,
      };
      if (customRedirectUrl) {
        window.location.href = parseSwitchURL(ctx, customRedirectUrl);
        return;
      }
      await dispatch(getAccountContext(ctx));
      if (isTeamContext) {
        await dispatch(getTeamAccount(accountNumber));
      }
      // if on settings page, go to correct context
      const { pathname } = window.location;
      if (
        pathname.includes('/account') ||
        pathname.includes('/settings/team') ||
        pathname.includes('/teams')
      ) {
        history.replace(redirectUrl);
      }
    } catch (error: any) {
      dispatch(reduxErrorBanner(error, errorKeys, errorMessages.switchAccountFailure));
    }
  };

export const cancelPendingPlan =
  (context: 'team' | 'user', accountNumber: number): Thunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(closeModal());
    try {
      await dispatch(getPaymentPlans(context));
      if (context === 'team') {
        await dispatch(getTeamAccount(accountNumber));
      } else {
        await dispatch(getUserAccount());
      }
      dispatch(showNotification({ status: 'success', message: messages.cancelPendingPlanSuccess }));
    } catch (error) {
      dispatch(showNotification({ status: 'failure', message: messages.cancelPendingPlanFailure }));
    }
  };

export const getPaymentMethods = (accountNumber: number, customerId?: string | null) => {
  return request(() => api.getPaymentMethods(accountNumber, customerId).then((res) => res.data), {
    type: AccountActionName.GET_PAYMENT_METHODS,
  });
};

export const attachPaymentMethod = (paymetMethod: PaymentMethodRes) => ({
  type: AccountActionName.ATTACH_PAYMENT_METHOD,
  result: paymetMethod,
});

export const detachPaymentMethod = (paymentMethodId: string) => ({
  type: AccountActionName.DETACH_PAYMENT_METHOD,
  id: paymentMethodId,
});

export const setDefaultPaymentMethod = (paymentMethodId: string) => ({
  type: AccountActionName.SET_DEFAULT_PAYMENT_METHOD,
  id: paymentMethodId,
});

export const getSubscriptionTransactions = (accountNumber: number) => {
  return request(() => api.getSubscriptionTransactions(accountNumber).then((res) => res.data), {
    type: AccountActionName.GET_SUBSCRIPTION_TRANSACTIONS,
  });
};

export const getPaymentPlans =
  (context: 'team' | 'user'): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    await dispatch(getAccountPlans());
    const account = context === 'team' ? getState().team.account! : getState().user.account!;
    const plans = getState().account.plans;
    const paymentPlans = transformPaymentPlans(account, plans);
    dispatch({ type: AccountActionName.GET_PAYMENT_PLANS, paymentPlans });
  };
