import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from 'state/notification/actions';

const DEFAULT_DELAY = 1000 * 5;

export default function useNotification() {
  const dispatch = useDispatch();

  const notify = useCallback(
    ({ status, message, delay = DEFAULT_DELAY }: Parameters<typeof showNotification>[0]) => {
      dispatch(showNotification({ status, message, delay }));
    },
    [dispatch]
  );

  return {
    success: (message: string, delay = DEFAULT_DELAY) => {
      notify({
        status: 'success',
        message,
        delay,
      });
    },
    failure: (message: string, delay = DEFAULT_DELAY) => {
      notify({
        status: 'failure',
        message,
        delay,
      });
    },
    info: (message: string, delay = DEFAULT_DELAY) => {
      notify({
        status: 'info',
        message,
        delay,
      });
    },
  };
}
