import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import Modal from 'views/components/Modal';
import useFormBuilder from 'components/FormBuilder';
import Button from '@material-ui/core/Button/Button';

type ModalProps = {
  onSubmit: (values: any) => void;
  filters: Partial<{
    team: string;
    team_account_number: string;
    is_private: boolean;
  }>;
};

const FilterTeamAPIKeysModal = () => {
  const { classes } = useStyles();
  const _closeModal = () => dispatch(closeModal());
  const dispatch = useDispatch<Dispatch>();
  const { onSubmit, filters = {} } = useSelector(
    ({ modal }: { modal: ModalState<ModalProps> }) => modal.params
  );

  const { FormComponent } = useFormBuilder([
    {
      elementType: 'select',
      name: 'Team',
      id: 'team',
      defaultValue: 'Any',
      // TODO: Replace with actual team names
      defaultOptions: ['Any'].concat(['Team 1', 'Team 2']),
    },
    {
      elementType: 'select',
      name: 'Team Account Number',
      id: 'team_account_number',
      defaultValue: 'Any',
      // TODO: Replace with actual account numbers
      defaultOptions: ['Any'].concat(['Account 1', 'Account 2']),
    },
    {
      elementType: 'switch',
      name: 'Is Private',
      id: 'is_private',
      defaultValue: false,
      defaultOptions: ['Public', 'Private'],
    },
  ]);

  return (
    <Modal fullWidth={false} maxWidth='lg'>
      <div css={classes.container}>
        <h2 css={classes.title}>Filter Team API Keys</h2>
        <FormComponent
          customClass={classes.form}
          filters={filters}
          onSubmit={(values) => {
            onSubmit(values);
            _closeModal();
          }}
          footerRender={({ resetForm }) => {
            return (
              <div css={classes.buttonsContainer}>
                <Button
                  onClick={() => {
                    resetForm();
                  }}
                  variant='text'
                  color='primary'
                  css={classes.button}
                >
                  Reset
                </Button>
                <Button type='submit' variant='contained' color='primary' css={classes.button}>
                  Filter
                </Button>
              </div>
            );
          }}
        />
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: 'max-content',
      padding: '4rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.8rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '1rem',
      width: '65rem',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
    },
    button: {},
  },
  light: {},
  dark: {},
});

export default FilterTeamAPIKeysModal;
