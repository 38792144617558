import api from 'services/api';
import request from 'state/utils/request';
import { PageTimeQuery } from 'models/TimeSeries';
import { Thunk } from 'state/types/thunk';
import { MicroenginesActionName, SelectMicroengineAction } from './types';
import { closeModal } from 'state/modal/actions';
import { getUserStats } from 'state/user/actions';
import { history } from 'state/store';
import {
  IClaimUserMicroengineValues,
  MicroengineValues,
  UpdateMicroengineValues,
  EMicroengineType,
  PublicMicroengineListing,
  MicroengineListing,
} from 'models/Microengine';
import { UserMicroenginesRes, MicroengineRes } from 'services/api/schema/microengines';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  createFailure: {
    id: 'account.engines.create.failure',
    defaultMessage: 'Error creating Engine. Please try again',
  },
});

export const getAllMicroengines = (
  params?: PageTimeQuery<PublicMicroengineListing>,
  refresh?: boolean
) =>
  request(
    () => api.getAllMicroengines(params).then((res) => res.data),
    refresh
      ? { type: MicroenginesActionName.REFRESH_ALL_MICROENGINES }
      : { type: MicroenginesActionName.GET_ALL_MICROENGINES }
  );

export const getAllVerifiedMicroengines = (options: {
  engineType?: EMicroengineType;
  artifactType?: string;
}) =>
  request(
    () =>
      api
        .getAllMicroengines({ orderBy: 'name', limit: 50, direction: 'asc', ...options })
        .then((res) =>
          res.data.results.filter(
            (microengine) => microengine.name && microengine.status === 'verified'
          )
        ),
    { type: MicroenginesActionName.GET_ALL_VERIFIED_MICROENGINES }
  );

export const getMicroengine = (engineId: string, refresh?: boolean) =>
  request(
    () => api.getMicroengine(engineId).then((res) => res.data),
    refresh
      ? { type: MicroenginesActionName.REFRESH_MICROENGINE }
      : { type: MicroenginesActionName.GET_MICROENGINE }
  );

export const getUserMicroengines =
  (
    params?: PageTimeQuery<MicroengineListing>,
    refresh?: boolean
  ): Thunk<Promise<UserMicroenginesRes>> =>
  (dispatch, getState) => {
    const accountNumber =
      getState().account?.context?.accountNumber || getState().user.profile!.accountNumber;
    return dispatch(
      request(
        () => api.getUserMicroengines(accountNumber, params).then((res) => res.data),
        refresh
          ? { type: MicroenginesActionName.REFRESH_USER_MICROENGINES }
          : { type: MicroenginesActionName.GET_USER_MICROENGINES }
      )
    );
  };

export const createUserMicroengine =
  (accountNumber: number, values: MicroengineValues): Thunk<Promise<MicroengineRes>> =>
  (dispatch) => {
    return dispatch(
      request(
        () => api.createUserMicroengine(accountNumber, values).then((res) => res.data),
        { type: MicroenginesActionName.CREATE_USER_MICROENGINE },
        { failure: messages.createFailure }
      )
    );
  };

/**
 * Create a user microengine and immediately open claim modal.
 */
export const createClaimUserMicroengine =
  (
    values: MicroengineValues,
    accountNumber: number,
    params?: PageTimeQuery<MicroengineListing>,
    refresh?: boolean
  ): Thunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      request(
        () => api.createUserMicroengine(accountNumber, values).then((res) => res.data),
        { type: MicroenginesActionName.CREATE_USER_MICROENGINE },
        { failure: messages.createFailure }
      )
    );
    dispatch(closeModal());
    dispatch(getUserMicroengines(params, refresh));
  };

export const verifyUserMicroengine =
  (
    id: number,
    values: IClaimUserMicroengineValues,
    accountNumber: number,
    params?: PageTimeQuery<MicroengineListing>
  ): Thunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      request(() => api.verifyUserMicroengine(accountNumber, id, values).then((res) => res.data), {
        type: MicroenginesActionName.VERIFY_USER_MICROENGINE,
      })
    );

    dispatch(closeModal());
    dispatch(getUserMicroengines(params, true));
    dispatch(getUserStats());
  };

export const updateUserMicroengine =
  (
    id: number,
    values: UpdateMicroengineValues,
    accountNumber: number,
    params?: PageTimeQuery<MicroengineListing>
  ): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { engineId = '', ...rest } = values;
    await dispatch(
      request(() => api.updateUserMicroengine(accountNumber, id, rest).then((res) => res.data), {
        type: MicroenginesActionName.UPDATE_USER_MICROENGINE,
      })
    );
    const selected = getState().microengines?.selected;
    dispatch(closeModal());
    dispatch(getUserMicroengines(params, true));
    dispatch(getMicroengine(engineId));
    dispatch(selectMicroengine(null));
    dispatch(selectMicroengine(selected));
  };

export const archiveUserMicroengine =
  (
    id: number,
    accountNumber: number,
    params?: PageTimeQuery<MicroengineListing>
  ): Thunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      request(() => api.archiveUserMicroengine(accountNumber, id).then((res) => res.data), {
        type: MicroenginesActionName.ARCHIVE_USER_MICROENGINE,
      })
    );
    dispatch(closeModal());
    dispatch(getUserMicroengines(params, true));
    dispatch(getUserStats());
    history.replace('/engines/my-engines');
  };

export const selectMicroengine = (
  microengine: MicroengineListing | null
): SelectMicroengineAction => ({
  type: MicroenginesActionName.SELECT_MICROENGINE,
  microengine,
});
