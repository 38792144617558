import React, { Fragment, useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { defineMessages, useIntl } from 'react-intl';
import { TableCell } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Page } from 'models/Page';
import { TeamMember, TeamRole } from 'models/Team';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Popover from 'views/components/Popover';
import { Tooltip } from '@material-ui/core';
import Icon from 'views/components/Icon';
import Can from 'views/components/auth/Can';
import { teamRoleOptions } from 'state/utils/team';
import FabButton from 'views/components/Button/FabButton';
import CustomTableHead from 'views/components/table/CustomTableHead';
import CustomTable from 'views/components/table/CustomTable';
import { Row } from 'views/components/table/CustomTable';
import { IColumnSort } from 'views/components/table/InfiniteScrollTable';
import TeamMembersRow from './TeamMembersRow';

interface ITeamMembersTable {
  teamAccountNumber: number | string;
  teamMembers: Page<TeamMember>;
  sort?: IColumnSort<keyof TeamMember>;
  onSort: (orderBy: keyof TeamMember) => void;
  onInviteTeamMember: () => void;
  onUpdateTeamMember: (
    teamAccountNumber: number | string,
    accountNumber: number,
    name: string,
    roles: TeamRole[]
  ) => void;
  onLeaveTeam: () => void;
  onArchiveTeamMember: (accountNumber: number, name: string) => void;
  onLoadMore?: () => Promise<void>;
}

const messages = defineMessages({
  roles: {
    id: 'teamMembers.tableHeader.roles',
    defaultMessage: 'Roles',
  },
  joinedAt: {
    id: 'teamMembers.tableHeader.joinedAt',
    defaultMessage: 'Joined',
  },
});

const TeamMembersTable = ({
  teamAccountNumber,
  teamMembers,
  onInviteTeamMember,
  onUpdateTeamMember,
  onLeaveTeam,
  onArchiveTeamMember,
}: ITeamMembersTable) => {
  const intl = useIntl();
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const [filteredRoles, setFilteredRoles] = useState<string[]>([]);

  const rows: Row<TeamMember>[] = [
    {
      id: 'name',
      numeric: false,
      label: formatMessage({ id: 'table.row.name' }),
      sortable: true,
      width: 200,
    },
    {
      id: 'email',
      numeric: false,
      label: formatMessage({ id: 'table.row.email' }),
      sortable: true,
      width: 250,
    },
    {
      id: 'roles',
      numeric: false,
      label: formatMessage(messages.roles),
      sortable: false,
      width: 200,
    },
    {
      id: 'joinedAt',
      numeric: false,
      label: formatMessage(messages.joinedAt),
      sortable: true,
    },
  ];

  return (
    <Fragment>
      <CustomTable<TeamMember>
        rows={teamMembers.results.filter((teamMember) => {
          if (filteredRoles.length === 0) {
            return true;
          }
          return teamMember.roles.some((role) => filteredRoles.includes(role));
        })}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            actionAlign='left'
            renderHeaderCell={(row) => {
              return (
                <TableCell width={row.width} key={row.id} align={row.numeric ? 'right' : 'left'}>
                  <div css={classes.cellContainer}>
                    {row.label}
                    {row.label === 'Roles' && (
                      <Popover
                        placement='bottom-start'
                        button={
                          <Tooltip title='Filter by role' placement='top'>
                            <div css={classes.iconContainer}>
                              <Icon
                                css={[classes.icon, filteredRoles.length && classes.iconActive]}
                                name='dropdown'
                              />
                            </div>
                          </Tooltip>
                        }
                        content={
                          <div css={classes.popover}>
                            <div
                              style={{ height: `${teamRoleOptions(intl).length * 4.75}rem` }}
                              css={classes.listColumns}
                            >
                              {teamRoleOptions(intl).map((item) => (
                                <div css={classes.pane} key={item.label}>
                                  <label>
                                    <Checkbox
                                      color='primary'
                                      checked={filteredRoles.includes(item.value)}
                                      onChange={(_e) => {
                                        filteredRoles.includes(item.value)
                                          ? setFilteredRoles(
                                              filteredRoles.filter((role) => role !== item.value)
                                            )
                                          : setFilteredRoles([...filteredRoles, item.value]);
                                      }}
                                    />
                                    {item.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    )}
                  </div>
                </TableCell>
              );
            }}
          />
        )}
        renderTableRow={(row: TeamMember) => (
          <TeamMembersRow
            teamAccountNumber={teamAccountNumber}
            key={row.accountNumber}
            rowData={row}
            onLeaveTeam={onLeaveTeam}
            teamMembers={teamMembers}
            onUpdateTeamMember={onUpdateTeamMember}
            onArchiveTeamMember={onArchiveTeamMember}
          />
        )}
      />
      <div style={{ display: 'flex', justifyContent: 'end', padding: '2rem 5rem' }}>
        <Can I='manage' a='Team'>
          <FabButton icon='plus' onClick={onInviteTeamMember} testId='inviteTeamMemberFab' />
        </Can>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    icon: {
      fontSize: '0.6rem',
    },
    iconConfig: {
      width: '2.5rem',
      height: '2.5rem',
      marginTop: '1rem',
      marginLeft: '2rem',
      marginRight: '2rem',
      cursor: 'pointer',
    },
    popover: {
      padding: '2rem 5rem 2rem 2rem',
      borderRadius: '0.5rem',
      marginTop: '1rem',
      fontWeight: 600,
      border: `1px solid ${styles.color.lightGrey}`,
    },
    listColumns: {
      marginTop: '1rem',
    },
    pane: {
      '&:hover': {
        cursor: 'grab',
      },
    },
  },
  light: {
    icon: {
      color: styles.color.black,
    },
    iconActive: {
      color: styles.color.purple,
    },
    iconConfig: {
      color: styles.color.purple,
    },
    popover: {
      backgroundColor: styles.color.ulGrey,
    },
  },
  dark: {
    icon: {
      color: styles.color.greyishPurple,
    },
    iconActive: {
      color: styles.color.lightBlue,
    },
    iconConfig: {
      color: styles.color.lightBlue,
    },
    popover: {
      backgroundColor: styles.color.darkOffBlack,
    },
  },
});
export default TeamMembersTable;
