import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages, IntlShape } from 'react-intl';
import qs from 'query-string';
import { PageQuery } from 'models/Page';
import { Ruleset, RulesetValues } from 'models/Ruleset';
import { RootState } from 'state/root';
import { getAllRulesets } from 'state/hunting/actions';
import { HuntingActionName } from 'state/hunting/types';
import { openModal } from 'state/modal/actions';
import { getUserUsage } from 'state/user/actions';
import { CONSUMER_DOCS_URL } from 'utils/constants';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { EPageDirection } from 'views/components/table/CustomTablePagination';
import { extractPageQueryParams } from 'utils/pagination';
import { RunLiveHuntModalParams } from 'views/pages/HuntPage/modals/RunLiveHuntModal';
import { RunHistoricalHuntModalParams } from 'views/pages/HuntPage/modals/RunHistoricalHuntModal';
import { InfoModalParams } from 'views/pages/HuntPage/modals/InfoModal';
import { btnMessages } from 'views/components/Button';
import { DeleteRulesetModalParams } from 'views/pages/HuntPage/modals/DeleteRulesetModal';
import { RulesetModalParams } from 'views/pages/HuntPage/modals/RulesetModal';
import AppLoader from 'views/components/request/AppLoader';
import PanelContent from 'views/components/layout/PanelContent';
import RulesetsTable from './RulesetsTable';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';
import { useGetRuleset } from 'views/pages/HuntPage/hooks';

const messages = defineMessages({
  ruleset: {
    id: 'rulesetsTab.ruleset',
    defaultMessage: 'Ruleset',
  },
  rulesetHeading: {
    id: 'rulesetsTab.rulesetHeading',
    defaultMessage: 'Add YARA rulesets',
  },
  rulesetText: {
    id: 'rulesetsTab.rulesetText',
    defaultMessage:
      'Add your first YARA ruleset by clicking below. For information on searching and YARA rules, check out the docs.',
  },
  addRuleset: {
    id: 'rulesetsTab.addRuleset',
    defaultMessage: 'Add ruleset',
  },
  copy: {
    id: 'name.append.copy',
    defaultMessage: '(Copy)',
  },
});

const RulesetsTab = () => {
  const _offsets = useRef<string[]>([]);
  const _currentOffset = useRef<string | undefined>();

  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { getRuleset } = useGetRuleset();

  const { rulesets } = useSelector((state: RootState) => state.hunting);

  const _getAccountUsage = useCallback(() => dispatch(getUserUsage()), [dispatch]);
  useEffect(() => {
    _getAccountUsage();
  }, [_getAccountUsage]);

  const _getRuleset = (rulesetId: string): Promise<Ruleset> => {
    return getRuleset(rulesetId);
  };

  const _getAllRulesets = (refresh: boolean, query?: PageQuery<Ruleset>) => {
    const params = Object.assign({}, extractPageQueryParams(rulesets), query);
    dispatch(getAllRulesets(params, refresh));
  };

  const _createRuleset = () => {
    const queryParams = extractPageQueryParams(rulesets);
    dispatch(
      openModal<RulesetModalParams>('ADD_EDIT_RULESET', {
        refresh: true,
        queryParams: queryParams,
      })
    );
  };

  const _runLiveHunt = (id: string, name: string) =>
    dispatch(
      openModal<RunLiveHuntModalParams>('RUN_LIVE_HUNT', {
        id,
        name,
      })
    );

  const _runHistoricalHunt = (id: string, name: string) =>
    dispatch(openModal<RunHistoricalHuntModalParams>('RUN_HISTORICAL_HUNT', { id, name }));

  const _deleteRuleset = (id: string) => {
    const queryParams = extractPageQueryParams(rulesets);
    dispatch(
      openModal<DeleteRulesetModalParams>('DELETE_RULESET', {
        id,
        queryParams,
      })
    );
  };

  const _showRulesetInfo = (title: string, liveHuntId: string | null, rulesetId: string) =>
    dispatch(
      openModal<InfoModalParams>('INFO_MODAL', {
        title,
        data: [
          { key: 'Live Hunt ID', value: liveHuntId },
          { key: 'Ruleset ID', value: rulesetId },
        ],
      })
    );

  const _updateRuleset = (id: string, values: RulesetValues) => {
    const queryParams = extractPageQueryParams(rulesets);
    dispatch(
      openModal<RulesetModalParams>('ADD_EDIT_RULESET', {
        id,
        values,
        queryParams,
        refresh: true,
      })
    );
  };

  const _duplicateRuleset = (values: RulesetValues, intl: IntlShape) => {
    const queryParams = extractPageQueryParams(rulesets);
    dispatch(
      openModal<RulesetModalParams>('ADD_EDIT_RULESET', {
        values: { ...values, name: `${values.name} ${intl.formatMessage(messages.copy)}` },
        refresh: true,
        queryParams: queryParams,
      })
    );
  };

  const _cancelLiveHunt = async (id: string) => {
    const params = qs.parse(location.search);
    dispatch(openModal('CANCEL_LIVE_HUNT', { id, params }));
  };

  const _updateQueryParams = (newParams: PageQuery<Ruleset>) => {
    const params = qs.parse(location.search);
    history.replace(`${match.url}?${qs.stringify({ ...params, ...newParams })}`);
  };

  const _handleChangePage = (direction: EPageDirection) => {
    let offset;

    if (direction === EPageDirection.PREV) {
      _currentOffset.current = _offsets.current.pop();
      offset = _currentOffset.current;
    } else if (direction === EPageDirection.NEXT) {
      if (_currentOffset.current) {
        _offsets.current.push(_currentOffset.current);
      }
      _currentOffset.current = String(rulesets.offset);
      offset = _currentOffset.current;
    } else if (direction === EPageDirection.FIRST) {
      _currentOffset.current = undefined;
      _offsets.current = [];
    }

    _updateQueryParams({ offset: offset });
  };

  const _handleChangeRowsPerPage = (numRows: number) => {
    _currentOffset.current = undefined;
    _offsets.current = [];
    _updateQueryParams({ limit: numRows, offset: undefined });
  };

  useEffect(() => {
    const params = qs.parse(location.search);
    _getAllRulesets(true, params);
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppLoader
      loadingActions={[HuntingActionName.REFRESH_ALL_RULESETS]}
      onReload={() => _getAllRulesets(true)}
    >
      {() => {
        if (!rulesets.results) {
          return null;
        }
        return !rulesets.results.length ? (
          <PanelContent
            imageComponent={<RulesetImg />}
            heading={intl.formatMessage(messages.rulesetHeading)}
            text={intl.formatMessage(messages.rulesetText)}
            buttons={[
              {
                text: intl.formatMessage(messages.addRuleset),
                onClick: _createRuleset,
                testId: 'addRulesetBtn',
              },
              {
                text: intl.formatMessage(btnMessages.readDocs),
                href: CONSUMER_DOCS_URL,
                variant: 'outlined',
              },
            ]}
          />
        ) : (
          <RulesetsTable
            rulesets={rulesets}
            runLiveHunt={_runLiveHunt}
            cancelLiveHunt={_cancelLiveHunt}
            runHistoricalHunt={_runHistoricalHunt}
            updateRuleset={_updateRuleset}
            duplicateRuleset={(values) => _duplicateRuleset(values, intl)}
            deleteRuleset={_deleteRuleset}
            getRuleset={_getRuleset}
            showRulesetInfo={_showRulesetInfo}
            handleChangePage={_handleChangePage}
            handleChangeRowsPerPage={_handleChangeRowsPerPage}
            isFirst={!_currentOffset.current}
          />
        );
      }}
    </AppLoader>
  );
};

export default RulesetsTab;
