import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { uniq } from 'lodash';

export interface ITagInputItem {
  title?: string;
  value: string;
}

export interface ITagInputProps {
  name?: string;
  options?: string[];
  value?: string[];
  loading?: boolean;
  disabled?: boolean;
  defaultValue?: string[];
  label: string;
  info?: string;
  optionalText?: string;
  error?: boolean;
  helperText?: string;
  className?: string;
  width?: string | number;
  onChange?: (value: string[]) => void;
  addNew?: boolean;
}

const filter = createFilterOptions<ITagInputItem>();

const TagInput = ({
  name,
  options,
  value,
  defaultValue,
  loading,
  disabled = false,
  label,
  info,
  optionalText,
  error,
  helperText,
  className,
  width,
  onChange,
  addNew = true,
}: ITagInputProps) => {
  const { classes } = useStyles();
  // Convert array of tags to object
  const normalizeValues = (values?: string[]) => (values || []).map((value) => ({ value }));

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      loading={loading}
      value={normalizeValues(value)}
      options={normalizeValues(
        (options || []).filter((option) => (value || []).indexOf(option) === -1)
      )}
      renderTags={(value, getTagProps) =>
        (value || []).map((option, index) => (
          <Chip
            label={option.value}
            {...getTagProps({ index })}
            disabled={defaultValue && defaultValue.indexOf(option.value) !== -1}
          />
        ))
      }
      style={{ width: width || 500 }}
      filterOptions={(items, params) => {
        const filtered = filter(items, params);

        if (addNew && params.inputValue !== '') {
          filtered.push({
            value: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      getOptionLabel={(item: ITagInputItem) => {
        if (typeof item === 'string') {
          return item;
        } else if (item.title !== undefined) {
          return item.title;
        }

        return item.value;
      }}
      onChange={(event: any, newValue: ITagInputItem[], reason: string) => {
        if (onChange) {
          const values: ITagInputItem[] = newValue;

          if (defaultValue) {
            defaultValue.forEach((item) => {
              if (values.find(({ value }) => value === item) === undefined) {
                values.push({ value: item });
              }
            });
          }

          onChange(uniq(values.map((item) => item.value)));
        }
      }}
      renderInput={(params) => (
        <div className={className}>
          <TextField
            {...params}
            name={name}
            InputLabelProps={info ? { style: { pointerEvents: 'auto', zIndex: 999 } } : {}}
            label={
              info ? (
                <div css={classes.containerInfo}>
                  <Tooltip title={info} placement='top'>
                    <div css={classes.info}>
                      <Icon name='info' />
                    </div>
                  </Tooltip>
                  {`${label || ''} ${optionalText || ''}`}
                </div>
              ) : (
                `${label || ''} ${optionalText || ''}`
              )
            }
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            fullWidth
          />
        </div>
      )}
    />
  );
};

const useStyles = makeStyles({
  base: {
    containerInfo: {
      display: 'flex',
    },
    info: {
      cursor: 'help',
      fontSize: '0.5rem',
      marginRight: styles.spacing.tiny,
    },
  },
  light: {
    info: {
      color: styles.color.xLightGrey,
    },
  },
  dark: {
    info: {
      color: styles.color.xLightGrey,
    },
  },
});

export default TagInput;
