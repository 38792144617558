import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useIntl, defineMessages } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

const messages = defineMessages({
  extension: {
    id: 'rewardsTab.faq.extension',
    defaultMessage: 'PolySwarm Browser Extension',
  },
  documentation: {
    id: 'rewardsTab.faq.documentation',
    defaultMessage: 'Documentation',
  },
  question_1: {
    id: 'rewardsTab.faq.question_1',
    defaultMessage: 'How do I participate in the Rewards Program?',
  },
  answer_1: {
    id: 'rewardsTab.faq.answer_1',
    defaultMessage:
      'Install and setup the PolySwarm browser extension and then browse the internet.',
  },
  question_2: {
    id: 'rewardsTab.faq.question_2',
    defaultMessage: 'Which Browsers are supported by the PolySwarm browser extension?',
  },
  answer_2: {
    id: 'rewardsTab.faq.answer_2',
    defaultMessage: 'Only Chrome is supported right now. We’ll add Safari support soon.',
  },
  question_3: {
    id: 'rewardsTab.faq.question_3',
    defaultMessage: 'What information am I sending to PolySwarm when I use the browser extension?',
  },
  answer_3: {
    id: 'rewardsTab.faq.answer_3',
    defaultMessage:
      'The domain name and resolved IP address for each website you visit, the domain name and resolved IP address for each component within the website, the public IP address of your device, and your PolySwarm credentials.',
  },
  question_4: {
    id: 'rewardsTab.faq.question_4',
    defaultMessage:
      'Does anything about my browsing habits affect the amount of NCT Rewards I can earn?',
  },
  answer_4: {
    id: 'rewardsTab.faq.answer_4',
    defaultMessage:
      'Yes. Larger NCT Rewards are earned when PolySwarm’s paying customers search for information in the PolySwarm marketplace that is associated with the site(s) you browse.',
  },
  question_5: {
    id: 'rewardsTab.faq.question_5',
    defaultMessage: 'Why are there different categories of Rewards listed?',
  },
  answer_5: {
    id: 'rewardsTab.faq.answer_5',
    defaultMessage:
      'There are multiple categories for rewards, each having different rules for who gets a reward and when. You will get rewards for each category where your contributed information matched the rules.',
  },
});

type MessagesKey = keyof typeof messages;

const Question = ({ question, answer }: { question: string; answer: string }) => {
  const { classes } = useStyles();
  return (
    <div css={classes.question}>
      <h2 css={classes.primary} className='h5 h-mb-tiny'>
        {question}
      </h2>
      <p>{answer}</p>
    </div>
  );
};

export const FAQ = () => {
  const intl = useIntl();
  const { classes } = useStyles();
  return (
    <div css={classes.container}>
      <div css={classes.faq}>
        <div css={classes.links}>
          <a
            css={[classes.primary, classes.link]}
            href='https://github.com/polyswarm/ppdns-chrome-extension'
            target='_blank'
            rel='noopener noreferrer'>
            {intl.formatMessage(messages.extension)}
          </a>
          <a
            css={[classes.primary, classes.link]}
            href='https://docs.polyswarm.io/consumers/rewards'
            target='_blank'
            rel='noopener noreferrer'>
            {intl.formatMessage(messages.documentation)}
          </a>
        </div>
        <h1 className='h4 h-mb-tiny'>Frequently Asked Questions</h1>
        {Array.from(Array(5).keys()).map((index) => (
          <Question
            question={intl.formatMessage(messages[`question_${index + 1}` as MessagesKey])}
            answer={intl.formatMessage(messages[`answer_${index + 1}` as MessagesKey])}
          />
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      padding: '3rem',
    },
    faq: {
      padding: '3rem',
      borderRadius: '1rem',
    },
    links: {
      marginBottom: '1rem',
    },
    link: {
      marginRight: '1rem',
      fontWeight: 600,
    },
    question: {
      marginBottom: '1rem',
    },
  },
  light: {
    primary: {
      color: styles.color.xxxDarkPurple,
    },
    faq: {
      border: `2px solid ${styles.color.lightPurple}`,
      background: styles.color.xxLightGrey,
    },
    links: {
      marginBottom: '1rem',
      color: styles.color.purple,
    },
  },
  dark: {
    primary: {
      color: styles.color.xxLightGrey,
    },
    faq: {
      border: `2px solid ${styles.color.lightBlue}`,
      background: styles.color.xDarkPurple,
    },
    links: {
      marginBottom: '1rem',
      color: styles.color.blue,
    },
  },
});
