import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { ModalState } from 'state/modal';
import { ApiKey } from 'models/ApiKey';
import { closeModal, openModal } from 'state/modal/actions';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import { useCreateIntegration } from 'views/pages/TeamDetailPage/TeamDetailPanel/TeamDetailTabs/TeamIntegrationsTab/useCreateIntegration';
import config from 'config';

interface ConfirmIntegrationCreationParams {
  integrationData: Partial<ApiKey>;
  reloadIntegrations: () => void;
  removeMFAData: () => void;
}

const messages = defineMessages({
  textIntegration: {
    id: 'team.createIntegration.confirm.textIntegration',
    defaultMessage: 'Confirm creation of integration?',
  },
  mfaSuccess: {
    id: 'team.createIntegration.confirm.success',
    defaultMessage: 'API Key created',
  },
  mfaFailure: {
    id: 'team.createIntegration.confirm.failure',
    defaultMessage: 'Error creating API Key. Please try again.',
  },
});

const ConfirmIntegrationCreation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { createIntegration, loading, error } = useCreateIntegration();

  const { integrationData, reloadIntegrations, removeMFAData } = useSelector(
    ({ modal }: { modal: ModalState<ConfirmIntegrationCreationParams> }) => modal.params
  );

  return (
    <ConfirmModal
      isLoading={loading}
      errorMessage={error}
      text={intl.formatMessage(messages.textIntegration)}
      submitBtnText={intl.formatMessage({ id: 'button.confirm' })}
      onSubmit={async () => {
        await createIntegration(integrationData);
        dispatch(closeModal());
        reloadIntegrations();
        removeMFAData();
      }}
      onCancel={() => {
        dispatch(closeModal());
        removeMFAData();
      }}
      timeout={parseInt(config.tokenExpirationSec, 10)}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
        removeMFAData();
      }}
    />
  );
};

export default ConfirmIntegrationCreation;
