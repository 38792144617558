import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openModal, closeModal } from 'state/modal/actions';
import {
  loginWalletUpdate,
  getMFAData,
  getWalletNonce,
  removeMFAData,
  removeWalletNonce,
} from 'state/auth/actions';
import { Wallets } from 'models/Wallets';
import WalletsTableRow, { IWalletsActions } from './WalletsTableRow';
import TableCell from '@material-ui/core/TableCell';
import { Row } from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import CustomTable from 'views/components/table/CustomTable';
import { useUser } from 'views/components/providers/UserProvider';
import { ConfigureWithdrawalModalParams } from './modals/ConfigureWithdrawal';
import {
  WithdrawalConfig,
  WithdrawalRes,
} from 'views/pages/AccountPage/AccountPanel/AccountTabs/RewardsTab/hooks/useRewardsWallet';
import Accordion from 'views/components/Accordion/Accordion';
import Icon from 'views/components/Icon';
import TransactionsTable from './TransactionsTable/TransactionsTable';
import DateRangePicker from 'views/pages/AccountPage/AccountPanel/AccountTabs/RewardsTab/RewardsInfo/DateRangePicker';
import useTransactions from './hooks/useTransactions';
import { WalletsType } from './types';
import { generateWalletNonce } from 'utils/nct';

const Wrapper = styled.div`
  & th {
    white-space: nowrap !important;
  }

  & th:nth-child(1) {
    min-width: 180px !important;
  }
`;

const Title = styled.h3`
  font-size: 2rem;
  padding: 1rem 0;
  font-weight: bold;
  margin-left: 3rem;
`;

const AccordionTransactions = styled(Accordion)`
  width: 94%;
  margin: 2rem auto;
`;

const GoBack = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  & svg {
    width: 2rem;
    transform: rotate(90deg);
    margin: 0 1rem 0 2rem;
  }
`;

interface IWalletsTableProps {
  title: string;
  type: WalletsType;
  results: Wallets[];
  enableActions: boolean;
  configureWithdrawal: ({
    withdrawalsLimit,
    withdrawalAddress,
  }: WithdrawalConfig) => Promise<WithdrawalRes>;
  refetch: () => void;
  selected: { type?: WalletsType; id?: number };
  onWalletClick: (id: number) => void;
  goBackToWallets: () => void;
}

const Transactions = ({ id, type }: { id: number; type: WalletsType }) => {
  const { transactions, fetchTransactions, setTransactionDates } = useTransactions({ id, type });

  return (
    <AccordionTransactions
      title={transactions ? `Transactions (${transactions.length})` : 'Transactions'}
      onOpen={fetchTransactions}
      openByDefault={false}>
      <div style={{ width: '45rem' }}>
        <DateRangePicker
          defaultTimeAgo='weeks'
          onDateChange={(start, end) => (transactions ? setTransactionDates(start, end) : null)}
        />
      </div>
      <TransactionsTable transactions={transactions || []} />
    </AccordionTransactions>
  );
};

const WalletsTable = ({
  title,
  type,
  results,
  enableActions,
  configureWithdrawal,
  refetch,
  selected,
  onWalletClick,
  goBackToWallets,
}: IWalletsTableProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const accountNumber = user.context?.accountNumber || user.accountNumber;

  const rows: Row<Wallets>[] = [
    {
      id: 'engineId',
      numeric: false,
      label: 'Wallet',
      sortable: false,
      width: 250,
    },
    {
      id: 'balance',
      numeric: false,
      label: 'Balance (NCT)',
      sortable: false,
      width: 150,
    },
    {
      id: 'deposit_address',
      numeric: false,
      label: 'Deposit Address',
      sortable: false,
      width: 200,
    },
    {
      id: 'withdrawal_address',
      numeric: false,
      label: 'Withdrawal Address',
      sortable: false,
      width: 200,
    },
    {
      id: 'withdrawals_limit',
      numeric: false,
      label: 'Withdrawal Limit (NCT)',
      sortable: false,
      width: 150,
    },
  ];

  const configureWithdrawalAction = async ({
    limit,
    address,
    type,
  }: {
    limit: string;
    address: string;
    type: string;
  }) => {
    const response = await configureWithdrawal({
      withdrawalsLimit: limit,
      withdrawalAddress: address,
    });

    if (response.success) {
      dispatch(
        openModal('CONFIGURE_WITHDRAWAL_SUCCESS', {
          address,
          limit,
          type,
        })
      );
      refetch && refetch();
    } else {
      dispatch(
        openModal('ERROR_MODAL', {
          heading: 'Error',
          text: response.errorMessage,
          buttonText: 'Back to wallets',
          onClick: () => dispatch(closeModal()),
        })
      );
    }
  };

  const configureWithdrawalModal = ({
    address,
    limit,
  }: Partial<ConfigureWithdrawalModalParams>) => {
    const originalAddress = address;
    const originalLimit = limit;
    return dispatch(
      openModal('CONFIGURE_WITHDRAWAL', {
        address: address,
        limit: limit,
        onSuccess: async ({
          address,
          limit,
        }: Pick<ConfigureWithdrawalModalParams, 'address' | 'limit'>) => {
          const walletNonce = getWalletNonce();
          if (!walletNonce) {
            return dispatch(
              openModal('ERROR_MODAL', {
                heading: 'Verification Error',
                text: 'Make sure your browser is allowing local storage.',
                buttonText: 'Back to Wallets',
                onClick: () => dispatch(closeModal()),
              })
            );
          }
          removeWalletNonce();
          if (generateWalletNonce(accountNumber, originalAddress, originalLimit) !== walletNonce) {
            return dispatch(
              openModal('ERROR_MODAL', {
                heading: 'Verification Error',
                text: 'Invalid credentials ‒ Check your 2FA, maybe you logged in with the wrong account.',
                buttonText: 'Back to Wallets',
                onClick: () => dispatch(closeModal()),
              })
            );
          }
          configureWithdrawalAction({ limit, address, type: 'updated' });
        },
      })
    );
  };
  const requestMFA = (modalName: string, data: any = {}) =>
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWalletUpdate({
              modal: modalName,
              location: location.pathname,
              accountNumber,
              ...data,
            })
          );
        },
      })
    );

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal, ...data } = mfaStep;
      if (modal === 'CONFIGURE_WITHDRAWAL') {
        configureWithdrawalModal(data);
        removeMFAData();
      }
    }
  });

  let actions: IWalletsActions | null = null;
  if (enableActions) {
    actions = {
      configureWithdrawal: configureWithdrawalModal,
      requestMFA,
    };
  }

  return (
    <Wrapper>
      {selected.id ? (
        <GoBack onClick={goBackToWallets}>
          <Icon name='arrow' /> Back to Wallets
        </GoBack>
      ) : (
        <Title>{title}</Title>
      )}
      <CustomTable<Wallets>
        rows={results}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            actionAlign='left'
            showActions={actions !== null}
            renderHeaderCell={(row) => (
              <TableCell width={row.width} key={row.id} align={row.numeric ? 'right' : 'left'}>
                {row.label}
              </TableCell>
            )}
          />
        )}
        renderTableRow={(row: Wallets) => (
          <WalletsTableRow
            onClick={onWalletClick}
            type={type}
            key={row.engineId}
            rowData={row}
            actions={actions}
          />
        )}
      />
      {selected.id && <Transactions id={selected.id} type={type} />}
    </Wrapper>
  );
};

export default WalletsTable;
