import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import styles from 'views/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Modal from 'views/components/Modal';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import StatusIcon, { IStatusIcon } from 'views/components/StatusIcon';

export interface ErrorModalParams {
  heading: string;
  icon?: IStatusIcon;
  text: string;
  buttonText: string;
  buttonClass: ButtonProps['color'];
  onClick: () => void;
}

const ErrorModal = () => {
  const {
    heading,
    icon = 'error',
    text,
    buttonText = 'Back',
    buttonClass = 'primary',
    onClick,
  } = useSelector(({ modal }: { modal: ModalState<ErrorModalParams> }) => modal.params);

  return (
    <Modal>
      <div css={ownStyle}>
        <StatusIcon status={icon as IStatusIcon['status']} />
        <h3 className='h3 h-mb-tiny'>{heading}</h3>
        <p className='p'>{text}</p>
        <Button className='h-mt-xs' color={buttonClass} variant='contained' onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
};

const ownStyle = css`
  text-align: center;
  padding: ${styles.spacing.md};
  min-width: 54rem;
  margin: 0 auto;

  p {
    max-height: 30rem;
    overflow: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export default ErrorModal;
