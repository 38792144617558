import { Global, css } from '@emotion/core';

export default () => (
  <>
    <symbol id='icon-checkbox-minus-checked' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 13H7V12H17V13Z'
        fill='#6D3AEC'
      />
    </symbol>
    <Global
      styles={css`
        .icon-checkbox-minus-checked {
          width: 1.5em;
          height: 1.5em;
          font-size: 14px;
        }
      `}
    />
  </>
);
