// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import uniqueId from 'lodash/uniqueId';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { CombineComponent } from 'components/FormBuilder';

const METADATA_KEYS = [
  'Filename',
  'Hash',
  'Sandbox Task ID',
  'Instance ID',
  'Community',
  'Source',
  'Custom',
];

const getOptionDisabled = {
  getOptionDisabled: (option, formState) => {
    const formKeys = Object.keys(formState);
    const metaKeys = formKeys.filter((item) => item.includes('metaKey'));
    const options = metaKeys.map((key) => formState[key]);

    return option !== 'Custom' && options.includes(option);
  },
};

const CUSTOM_CONFIG = (metadataKeys, metaKeyId, metaCustomKeyId, metaCustomValueId) => [
  {
    name: 'Metadata',
    combine: [
      {
        elementType: 'select',
        id: metaKeyId ?? 'metaKey-0',
        name: 'Metadata',
        defaultValue: 'Custom',
        defaultOptions: metadataKeys,
        ...getOptionDisabled,
      },
      {
        elementType: 'input',
        id: metaCustomKeyId ?? 'metaCustomKey-0',
        placeholder: 'Metadata Key',
        defaultValue: '',
      },
      {
        elementType: 'input',
        id: metaCustomValueId ?? 'metaCustomValue-0',
        placeholder: 'Metadata Value',
        defaultValue: '',
      },
    ],
  },
];

const INPUT_CONFIG = (metadataKeys, metaKeyId, metaValueId, keyValue, value, options) => [
  {
    name: 'Metadata',
    combine: [
      {
        elementType: 'select',
        id: metaKeyId ?? 'metaKey-0',
        name: 'Metadata',
        placeholder: 'Metadata Key',
        defaultValue: !!keyValue ? keyValue : null,
        defaultOptions: metadataKeys,
        ...getOptionDisabled,
      },
      !!options
        ? {
            elementType: 'select',
            id: metaValueId ?? 'metaValue-0',
            placeholder: 'Metadata Value',
            defaultValue: !!value ? value : null,
            defaultOptions: options,
          }
        : {
            elementType: 'input',
            id: metaValueId ?? 'metaValue-0',
            placeholder: 'Metadata Value',
            defaultValue: !!value ? value : '',
          },
    ],
  },
];
const getExtraFilterArgs = (metaKey, metaValue) => {
  const value = !!metaValue ? metaValue : '';

  switch (metaKey) {
    case 'Community': {
      return [value, ['Any', 'Public', 'Private']];
    }
    case 'Source': {
      return [value, ['Any', 'Portal', 'Internal / API']];
    }
    default: {
      return [];
    }
  }
};

const MetadataInput = ({
  item,
  removeEnabled,
  removeConfig,
  addConfig,
  modifyInputConfig,
  inputConfig,
}) => {
  const { unregister, setValue, getValues } = useFormContext();
  const { classes } = useStyles();

  const [metaKeyItem, ...metaCustom] = item.hasOwnProperty('combine') ? item.combine : item;
  const metaKey = getValues()[metaKeyItem.id];

  let metaValue, customKey, customValue;

  if (metaCustom.length > 1) {
    customKey = getValues()[metaCustom[0].id];
    customValue = getValues()[metaCustom[1].id];
  } else {
    metaValue = getValues()[metaCustom[0].id];
  }

  useEffect(() => {
    if (metaKey === 'Custom' && metaCustom.length === 1) {
      const id = uniqueId();

      const customConfigList = inputConfig.map((item) => {
        const firstCombineItem = item.combine[0];

        if (firstCombineItem.id === metaKeyItem.id) {
          const [customConfig] = CUSTOM_CONFIG(
            METADATA_KEYS,
            `metaKey-${id}`,
            `metaCustomKey-${id}`,
            `metaCustomValue-${id}`
          );

          return customConfig;
        }
        return item;
      });

      modifyInputConfig(customConfigList);

      // Clean up unused form states
      unregister([metaKeyItem.id, metaCustom[0].id]);
    }

    if (!Boolean(metaKey) && metaCustom.length > 1) {
      setValue(metaKeyItem.id, 'Custom');
    }
  }, [inputConfig, metaCustom, metaKey, metaKeyItem.id, modifyInputConfig, setValue, unregister]);

  useEffect(() => {
    if (
      Boolean(metaKey) &&
      !Boolean(metaValue) &&
      metaKeyItem.defaultValue !== metaKey &&
      metaKey !== 'Custom'
    ) {
      const id = uniqueId();

      const customConfigList = inputConfig.map((item) => {
        const firstCombineItem = item.combine[0];

        if (firstCombineItem.id === metaKeyItem.id) {
          const [customConfig] = INPUT_CONFIG(
            METADATA_KEYS,
            `metaKey-${id}`,
            `metaValue-${id}`,
            metaKey,
            ...getExtraFilterArgs(metaKey)
          );

          return customConfig;
        }
        return item;
      });

      modifyInputConfig(customConfigList);

      unregister([metaKeyItem.id, metaCustom[0].id]);
      setValue(`metaKey-${id}`, metaKey);
    }
  }, [
    metaValue,
    inputConfig,
    unregister,
    setValue,
    metaCustom,
    metaKey,
    metaKeyItem,
    modifyInputConfig,
  ]);

  useEffect(() => {
    if (
      ['Community', 'Source'].includes(metaKey) &&
      ['Community', 'Source'].includes(metaKeyItem.defaultValue) &&
      metaKey !== metaKeyItem.defaultValue
    ) {
      const id = uniqueId();

      const customConfigList = inputConfig.map((item) => {
        const firstCombineItem = item.combine[0];

        if (firstCombineItem.id === metaKeyItem.id) {
          const [customConfig] = INPUT_CONFIG(
            METADATA_KEYS,
            `metaKey-${id}`,
            `metaValue-${id}`,
            metaKey,
            ...getExtraFilterArgs(metaKey)
          );

          return customConfig;
        }
        return item;
      });

      modifyInputConfig(customConfigList);

      unregister([metaKeyItem.id, metaCustom[0].id]);
      setValue(`metaKey-${id}`, metaKey);
    }
  }, [metaKey, metaKeyItem, inputConfig, metaCustom, modifyInputConfig, setValue, unregister]);

  const disabledAddButton = React.useMemo(() => {
    if (metaCustom.length > 1) {
      return !Boolean(metaKey) || !Boolean(customKey) || !Boolean(customValue);
    }
    return !Boolean(metaKey) || !Boolean(metaValue);
  }, [customKey, customValue, metaCustom?.length, metaKey, metaValue]);

  return (
    <div css={classes.row}>
      <div css={classes.metaKeyContainer}>
        <span>{item.name}</span>
        <div css={classes.actionButtons}>
          {/* probably move to container of input with absolute */}
          <Icon
            onClick={() =>
              removeConfig(metaKey, metaKeyItem.id, metaCustom[0].id, metaCustom?.[1]?.id)
            }
            css={removeEnabled ? classes.icon : [classes.iconRemove, classes.icon]}
            name='remove'
          />
          <Icon
            disabled={disabledAddButton}
            onClick={() => !disabledAddButton && addConfig()}
            css={classes.icon}
            name='plus-alt'
          />
        </div>
      </div>
      <CombineComponent item={item} />
    </div>
  );
};

const addInputConfig = (configList, metaKey, metaValue) => {
  const config = INPUT_CONFIG(METADATA_KEYS, metaKey, metaValue);
  return configList.concat(config);
};

const getAllCustomDefaultValues = (filters) => {
  const data = reduce(
    filters,
    (acc, value, key) => {
      if (key.includes('metaKey') || key.includes('metaValue')) {
        const [, index] = key.split('-');
        if (index !== '0') {
          if (value === 'Custom') {
            const customKey = `metaCustomKey-${index}`;
            const customValue = `metaCustomValue-${index}`;

            return {
              ...acc,
              [index]: {
                ...acc[index],
                [key]: value,
                [customKey]: filters[customKey],
                [customValue]: filters[customValue],
              },
            };
          }

          return { ...acc, [index]: { ...acc[index], [key]: value } };
        }
      }
      return acc;
    },
    {}
  );
  return Object.values(data);
};

const MetadataInputContainer = ({ filters }) => {
  const { unregister, getValues, setValue } = useFormContext();
  const [inputConfig, setInputConfig] = useState(() => {
    const customDefaultValues = getAllCustomDefaultValues(filters);

    if (!isEmpty(customDefaultValues)) {
      const configs = [].concat(
        customDefaultValues.map((items) => {
          const [key, valueKey] = Object.keys(items);
          const [, index] = key.split('-');

          /*
           * we check length === 3 for cases when row is a custom row which has
           * 3 input fields(1 select and 2 text fields).
           */
          const [config] =
            Object.keys(items).length === 3
              ? CUSTOM_CONFIG(
                  METADATA_KEYS,
                  `metaKey-${index}`,
                  `metaCustomKey-${index}`,
                  `metaCustomValue-${index}`
                )
              : INPUT_CONFIG(
                  METADATA_KEYS,
                  `metaKey-${index}`,
                  `metaValue-${index}`,
                  items[key],
                  ...getExtraFilterArgs(items[key], items[valueKey])
                );

          return config;
        })
      );

      return configs;
    }

    const id = uniqueId();
    return INPUT_CONFIG(METADATA_KEYS, `metaKey-${id}`, `metaValue-${id}`);
  });

  const addConfig = () => {
    setInputConfig((configList) => {
      const id = uniqueId();

      return addInputConfig(configList, `metaKey-${id}`, `metaValue-${id}`);
    });
  };

  const removeConfig = (metaKey, metaKeyId, meta1Id, meta2Id) => {
    setInputConfig((configList) => configList.filter((item) => item?.combine[0]?.id !== metaKeyId));

    // clean unused state
    unregister([metaKeyId, meta1Id, meta2Id]);

    // to have the form dirty when remove a custom field
    const defaultValues = getValues();
    const valueEntries = Object.entries(defaultValues);
    const [key, value] = valueEntries.find(([, value]) => typeof value === 'string');
    setValue(key, '', { shouldDirty: true });
    setValue(key, value, { shouldTouch: true });
  };

  return (
    <>
      {inputConfig.map((item, index) => (
        <MetadataInput
          modifyInputConfig={setInputConfig}
          inputConfig={inputConfig}
          removeEnabled={inputConfig.length > 1}
          removeConfig={removeConfig}
          addConfig={addConfig}
          key={`${item.name}-${index}`}
          item={item}
        />
      ))}
    </>
  );
};

const useStyles = makeStyles({
  base: {
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    iconRemove: {
      visibility: 'hidden !important',
    },
    icon: {
      height: '2rem',
      width: '2rem',
      marginRight: '1rem',
      cursor: 'pointer',
      marginBottom: '-0.4rem',
    },
    metaKeyContainer: {
      display: 'flex',
      '& > span': {
        fontWeight: 600,
      },
    },
    actionButtons: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '6rem',
    },
  },
  light: {
    icon: {
      '&.icon-remove': {
        color: styles.color.red,
      },
      '&.icon-plus-alt': {
        color: styles.color.purple,
      },
    },
  },
  dark: {
    icon: {
      '&.icon-remove': {
        color: styles.color.red,
      },
      '&.icon-plus-alt': {
        color: styles.color.lightBlue,
      },
    },
  },
});
export default MetadataInputContainer;
