import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { RequestError, ErrorKeys, translateError } from 'utils/error';
import { UrlScanType } from 'utils/isUrl';
import ScanSearchingState from './ScanSearchingState';

interface IUrlSearchingState {
  type: UrlScanType;
  isLoading: boolean;
  fileUploaded: boolean;
  error?: RequestError;
  uuid: string | null;
  submissionId?: string;
  url: string;
  renderFailureBtn: () => JSX.Element;
  onCancel: () => void;
}

export const urlSearchingMessages = defineMessages({
  ipLoading: {
    id: 'ip.searching.loading',
    defaultMessage: 'Scanning IP…',
  },
  ipError: {
    id: 'ip.searching.error',
    defaultMessage: 'Error scanning IP',
  },
  urlLoading: {
    id: 'url.searching.loading',
    defaultMessage: 'Scanning URL…',
  },
  urlError: {
    id: 'url.searching.error',
    defaultMessage: 'Error scanning URL',
  },
  domainLoading: {
    id: 'domain.searching.loading',
    defaultMessage: 'Scanning domain…',
  },
  domainError: {
    id: 'domain.searching.error',
    defaultMessage: 'Error scanning domain',
  },
});

const errorKeys: ErrorKeys = {};

const UrlSearchingState = ({
  type,
  isLoading,
  fileUploaded,
  error,
  uuid,
  submissionId,
  url,
  renderFailureBtn,
  onCancel,
}: IUrlSearchingState) => {
  const intl = useIntl();
  return (
    <ScanSearchingState
      isLoading={isLoading}
      fileUploaded={fileUploaded}
      loadingHeading={intl.formatMessage({ id: `${type}.searching.loading` })}
      errorHeading={intl.formatMessage({ id: `${type}.searching.error` })}
      errorMessage={translateError(intl, errorKeys, error)}
      uuid={uuid}
      submissionId={submissionId}
      query={url}
      renderFailureBtn={renderFailureBtn}
      onCancel={onCancel}
      type='url'
    />
  );
};

export default UrlSearchingState;
