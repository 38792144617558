import React, { Fragment, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl } from 'react-intl';
import Slider from '@material-ui/core/Slider';
import numeral from 'numeral';
import styles from 'views/styles';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'views/components/Icon';
import { Quotas } from './UsageTable';
import HoverableTableRow from 'views/components/table/HoverableTableRow';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface IUsageTableRow {
  usage: Quotas;
  isEditing?: boolean;
  overageSupportFeature?: boolean;
  onEdit: (feature: string, value: number) => void;
}

const formatValue = (value: string | number) =>
  Number.isInteger(value as number) ? numeral(value).format('0,0') : value;

const UsageTableRow = ({
  usage: {
    feature,
    tag,
    remainingUses,
    totalAvail,
    baseQuota,
    overageQuota,
    value,
    isIncludedOnPlan,
  },
  isEditing,
  onEdit,
  overageSupportFeature,
}: IUsageTableRow) => {
  const intl = useIntl();

  const { classes } = useStyles();
  const remainingUsesNumber = Number(remainingUses);
  const baseQuotaNumber = Number(baseQuota);
  const baseQuotaRemaining = Math.max(remainingUsesNumber, 0);
  const overageRemaining = Math.max(
    Number(overageQuota) - Math.abs(Math.min(remainingUsesNumber, 0)),
    0
  );
  let featurePeriodicity = '/ mo';
  const shouldUseColor = isIncludedOnPlan && !value;
  const baseQuotaColor =
    baseQuotaRemaining === 0 && shouldUseColor
      ? styles.color.red
      : baseQuotaRemaining < baseQuotaNumber && shouldUseColor
      ? styles.color.yellow
      : null;
  const overageColor =
    overageQuota && overageRemaining === 0 && shouldUseColor
      ? styles.color.red
      : overageQuota && overageRemaining < overageQuota && shouldUseColor
      ? styles.color.yellow
      : null;
  switch (feature) {
    case 'apiRequestLimit':
      featurePeriodicity = '/ day';
      break;
    case 'liveHunts':
      featurePeriodicity = 'Available';
      break;
  }

  const getCompactNumber = (num: number) => {
    const value = intl.formatNumber(num, { notation: 'compact' });
    return value.split(/([0-9.]+)/).filter(Boolean);
  };

  return (
    <HoverableTableRow
      dataCy='usageTableRow'
      renderCells={() => (
        <Fragment>
          <TableCell width={300}>
            <div css={classes.flexContainer}>
              <p css={[classes.feature, !isIncludedOnPlan && classes.grey]}>
                {intl.formatMessage({ id: `feature.${feature}` })}
              </p>
              <Tooltip
                placement='top'
                title={intl.formatMessage({
                  id: `feature.${feature}.description`,
                })}
              >
                <div css={classes.featureIcon}>
                  <Icon css={classes.helpIcon} name='info' />
                </div>
              </Tooltip>
            </div>
          </TableCell>
          <TableCell width={200} align='right'>
            <div css={[classes.flexContainer, { justifyContent: 'flex-end' }]}>
              <p
                css={[
                  classes.uses,
                  !isIncludedOnPlan && classes.primary,
                  baseQuotaColor ? { color: baseQuotaColor } : {},
                ]}
              >
                {isIncludedOnPlan
                  ? value
                    ? 'Unlimited'
                    : `${formatValue(baseQuotaRemaining)} of ${formatValue(
                        baseQuotaNumber
                      )} ${featurePeriodicity}`
                  : 'Requires Upgrade'}
              </p>
              {shouldUseColor ? (
                <Tooltip
                  placement='top'
                  title={`You have ${formatValue(
                    baseQuotaRemaining
                  )} available remaining uses of a total of ${formatValue(baseQuotaNumber)}`}
                >
                  <div css={classes.featureIcon}>
                    <Icon css={classes.helpIcon} name='info' />
                  </div>
                </Tooltip>
              ) : null}
            </div>
          </TableCell>
          {overageSupportFeature && (
            <TableCell width={250} align='right'>
              {!isEditing ? (
                <div css={[classes.flexContainer, { justifyContent: 'flex-end' }]}>
                  <p style={overageColor ? { color: overageColor } : {}}>
                    {isIncludedOnPlan && overageQuota && !value
                      ? `${formatValue(overageRemaining)} of ${formatValue(
                          overageQuota
                        )} ${featurePeriodicity}`
                      : '-'}
                  </p>
                  {isIncludedOnPlan && overageQuota && !value ? (
                    <Tooltip
                      placement='top'
                      title={`You have ${formatValue(
                        overageRemaining
                      )} overages remaining of a total of ${formatValue(overageQuota)}`}
                    >
                      <div css={classes.featureIcon}>
                        <Icon css={classes.helpIcon} name='info' />
                      </div>
                    </Tooltip>
                  ) : null}
                </div>
              ) : shouldUseColor ? (
                <div
                  style={{
                    paddingLeft: '6rem',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Slider
                    defaultValue={overageQuota || 0}
                    aria-labelledby='discrete-slider'
                    valueLabelDisplay='auto'
                    valueLabelFormat={getCompactNumber}
                    onChangeCommitted={(_e, value) => (tag ? onEdit(tag, Number(value)) : {})}
                    step={Math.round(Math.max(Number(totalAvail) / 10, 1))}
                    marks
                    min={Math.max((overageQuota || 0) - overageRemaining, 0)}
                    max={baseQuotaNumber}
                  />
                </div>
              ) : (
                <p>-</p>
              )}
            </TableCell>
          )}
        </Fragment>
      )}
    />
  );
};

const useStyles = makeStyles(() => {
  return {
    base: {
      primary: {
        fontSize: '1.3rem',
      },
      flexContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      feature: {
        fontWeight: styles.font.weight.normal,
      },
      uses: {
        fontWeight: styles.font.weight.normal,
      },
      totals: {
        color: styles.color.purple,
        fontWeight: styles.font.weight.normal,
      },
      grey: {
        color: styles.color.xLightGrey,
      },
      featureIcon: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'flex-end',
      },
      helpIcon: {
        cursor: 'help',
        fontSize: '0.8rem',
        paddingLeft: '1rem',
        position: 'relative',
        color: styles.color.xLightGrey,
      },
    },
    light: {
      feature: {
        color: styles.color.black,
      },
      totals: {
        color: styles.color.purple,
      },
      primary: {
        color: styles.color.purple,
      },
    },
    dark: {
      feature: {
        color: styles.color.white,
      },
      totals: {
        color: styles.color.lightBlue,
      },
      primary: {
        color: styles.color.lightBlue,
      },
    },
  };
});

export default UsageTableRow;
