import React from 'react';
import { FieldArray } from 'formik';
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import styles from 'views/styles';
import CheckboxField from 'views/components/form/CheckboxField';

export type CheckboxOption = {
  value: any;
  label: string;
};

interface ICheckboxArrayField {
  className?: string;
  name: string;
  label?: string;
  margin?: 'none' | 'normal' | 'dense';
  errorMessage?: string;
  errorOnTouched?: boolean;
  options: CheckboxOption[];
  required?: boolean;
}

const CheckboxArrayField = ({
  className,
  name,
  label,
  options,
  margin,
  errorMessage,
  errorOnTouched,
  required = false,
}: ICheckboxArrayField) => {
  const muiClasses = useMuiStyles();
  return (
    <div className={className}>
      <FieldArray
        name={name}
        render={({ form, push, remove }) => {
          const _error = form.errors[name];
          const touched = form.touched[name];
          const values = form.values[name];

          const error = errorMessage || _error;
          let isError = !!errorMessage || !!error;
          if (errorOnTouched) isError = !!touched && isError;

          return (
            <FormControl required={required} error={isError} margin={margin} fullWidth>
              {label && <FormLabel className={muiClasses.label}>{label}</FormLabel>}
              <FormGroup>
                {(options || []).map((opt, idx) => {
                  return (
                    <CheckboxField
                      key={idx}
                      label={opt.label}
                      checked={(values || []).includes(opt.value)}
                      name={`${name}.${idx}`}
                      value={opt.value}
                      onChange={(e) => {
                        if (e.target.checked) {
                          push(e.target.value);
                        } else {
                          remove(values.indexOf(e.target.value));
                        }
                      }}
                    />
                  );
                })}
              </FormGroup>
              {isError && <FormHelperText error={isError}>{error}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    </div>
  );
};

const useMuiStyles = makeMuiStyles({
  label: {
    fontSize: styles.font.size.p1,
    fontWeight: styles.font.weight.medium,
  },
});

export default CheckboxArrayField;
