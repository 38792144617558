import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import styles from 'views/styles';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';

export interface IFilter {
  value: string;
  label: string;
}

interface IFiltersParams {
  filters: IFilter[];
  value?: string | null;
  onChange: (arg0: string) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  flex: 0 0 auto;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3rem;
  padding-right: 15px;
`;

const ControlLabel = styled(FormControlLabel)`
  ${({ checked }) => !checked && `color: ${styles.color.medLightGrey} !important;`}

  & .MuiRadio-colorSecondary.Mui-checked {
    color: inherit !important;
  }

  & .MuiTypography-body1 {
    font-size: 1.3rem !important;
    font-weight: 600;
  }

  & .MuiRadio-colorSecondary.Mui-checked:hover,
  & .MuiIconButton-colorSecondary:hover {
    background-color: rgba(109, 58, 236, 0.08);
  }
`;

const Filters = ({ filters, value, onChange }: IFiltersParams) => {
  const { classes } = useStyles();

  return (
    <Wrapper data-cy='malwareFilters'>
      <Label css={classes.label}>
        <FormattedMessage
          id='scanPage.malwaretable.filter.label'
          defaultMessage='Emerging Threats'
        />
        :
      </Label>
      <RadioGroup value={value} onChange={(e, value) => onChange(value)} row>
        {filters.map((item, idx) => (
          <ControlLabel
            key={idx}
            value={item.value}
            control={<Radio checked={value === item.value} />}
            label={item.label}
            checked={value === item.value}
            css={classes.controlLabel}
          />
        ))}
      </RadioGroup>
    </Wrapper>
  );
};

const useStyles = makeStyles({
  base: {
    label: {
      color: styles.color.black,
    },
    controlLabel: {
      color: styles.color.purple,
    },
  },
  light: {
    label: {
      color: styles.color.black,
    },
    controlLabel: {
      color: styles.color.purple,
    },
  },
  dark: {
    label: {
      color: styles.color.xxLightGrey,
    },
    controlLabel: {
      color: styles.color.lightBlue,
    },
  },
});

export default Filters;
