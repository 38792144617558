import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeamMember, TeamMembers, TeamRole } from 'models/Team';
import { PageQuery } from 'models/Page';
import { RootState } from 'state/root';
import { TeamActionName } from 'state/team/types';
import { getTeamMembersByAccount } from 'state/team/actions';
import { openModal } from 'state/modal/actions';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';
import { UpdateTeamMemberModalParams } from 'views/pages/TeamDetailPage/modals/UpdateTeamMemberModal';
import { InviteTeamMemberModalParams } from 'views/pages/TeamDetailPage/modals/InviteTeamMemberModal';
import { ArchiveTeamMemberModalParams } from 'views/pages/TeamDetailPage/modals/ArchiveTeamMemberModal';
import AppLoader from 'views/components/request/AppLoader';
import TeamMembersTable from './TeamMembersTable';
import { extractPageQueryParams } from 'utils/pagination';
import { IColumnSort, ESortDirection } from 'views/components/table/InfiniteScrollTable';

interface StateProps {
  members: TeamMembers;
}

const TeamMembersTab = () => {
  const [sort, setSort] = useState<IColumnSort<keyof TeamMember>>({
    orderBy: 'name',
    direction: ESortDirection.ASC,
  });

  const dispatch = useDispatch();

  const { members } = useSelector<RootState, StateProps>(({ team }) => ({
    members: team.members,
  }));

  const teamAccountNumber = contextAccount(store)?.accountNumber!;
  const _getTeamMembers = useCallback(
    (query?: PageQuery<TeamMember>) => {
      if (!!teamAccountNumber) {
        const params = { ...extractPageQueryParams(members), ...query, fields: 'roles,mfa', limit: 75 };
        dispatch(getTeamMembersByAccount(params));
      }
    },
    [dispatch, members, teamAccountNumber]
  );

  const _inviteTeamMember = useCallback(
    () => dispatch(openModal<InviteTeamMemberModalParams>('INVITE_TEAM_MEMBER')), [dispatch]);

  const _updateTeamMember = useCallback(
    (teamAccountNumber: number | string, accountNumber: number, name: string, roles: TeamRole[]) =>
      dispatch(
        openModal<UpdateTeamMemberModalParams>('UPDATE_TEAM_MEMBER', {
          teamAccountNumber,
          accountNumber,
          name,
          values: { roles },
          queryParams: extractPageQueryParams(members),
        })
      ),
    [dispatch, members]
  );

  const _leaveTeam = useCallback(() => dispatch(openModal('LEAVE_TEAM')), [dispatch]);

  const _archiveTeamMember = useCallback(
    (accountNumber: number, teamMemberName: string) => {
      const queryParams = Object.assign({}, extractPageQueryParams(members));
      dispatch(
        openModal<ArchiveTeamMemberModalParams>('ARCHIVE_TEAM_MEMBER', {
          teamAccountNumber,
          accountNumber,
          teamMemberName,
          queryParams,
        })
      );
    },
    [dispatch, teamAccountNumber, members]
  );

  const _handleSort = useCallback(
    ({ orderBy, direction }: any) => {
      setSort({ orderBy, direction });
      _getTeamMembers({
        orderBy,
        direction,
      });
    },
    [_getTeamMembers]
  );

  useEffect(() => {
    _getTeamMembers();
  }, []); // eslint-disable-line

  return (
    <AppLoader
      loadingActions={[TeamActionName.REFRESH_TEAM_MEMBERS]}
      errorActions={[TeamActionName.REFRESH_TEAM_MEMBERS]}
      onReload={_getTeamMembers}>
      {members.results && (
        <TeamMembersTable
          teamAccountNumber={teamAccountNumber}
          teamMembers={members}
          sort={sort}
          onSort={_handleSort}
          onInviteTeamMember={_inviteTeamMember}
          onUpdateTeamMember={_updateTeamMember}
          onLeaveTeam={_leaveTeam}
          onArchiveTeamMember={_archiveTeamMember}
        />
      )}
    </AppLoader>
  );
};

export default TeamMembersTab;
