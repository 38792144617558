import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-upload' viewBox='0 0 24 24'>
      <path
        fill='#F8F7FC'
        fillRule='evenodd'
        d='M22.103 11.288c.09-.4.137-.81.14-1.22a5.93 5.93 0 0 0-5.93-5.91 5.6 5.6 0 0 0-2 .38 7 7 0 0 0-.83-.56 7.33 7.33 0 0 0-10 2.68 7.2 7.2 0 0 0-1 4.18 5 5 0 0 0 2.48 9.32h14a5 5 0 0 0 3.14-8.87m-11.14 4.87h2v-5h2l-3-3-3 3h2zm-6 2h14a3 3 0 0 0 3-3 3 3 0 0 0-1.56-2.59l-.72-.41.28-.79a3.88 3.88 0 0 0-3.65-5.21 3.76 3.76 0 0 0-1.68.43l-.63.31-.51-.47a4.7 4.7 0 0 0-1-.73 5.33 5.33 0 0 0-7.9 5.55l.15.82-.78.29a3 3 0 0 0 1 5.8'
        clipRule='evenodd'
      />
    </symbol>

    <Global
      styles={css`
        .icon-upload {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
