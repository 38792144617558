import React, { Fragment, ReactNode } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { Filter } from 'views/components/utils';
import { NotificationStatus } from 'state/notification';

interface IBanner {
  show: boolean;
  level?: NotificationStatus;
  text: string | ReactNode | null;
  onDismiss?: () => void;
}

const Banner = (props: IBanner) => {
  const { show, level, text, onDismiss } = props;

  const { classes } = useStyles(props);

  return (
    <div css={classes.root} data-cy={`${level}Banner`}>
      {show && (
        <Fragment>
          <div css={classes.content}>
            {text && (
              <Filter
                currentState={level || 'info'}
                states={{
                  info: <Icon css={classes.iconInfo} name='warning' />,
                  success: <Icon css={classes.iconSuccess} name='success' />,
                  failure: <Icon css={classes.iconFailure} name='warning' />,
                }}
              />
            )}
            <p css={classes.text}>{text}</p>
          </div>
          {onDismiss && (
            <button css={classes.close} onClick={onDismiss}>
              <Icon name='close' />
            </button>
          )}
        </Fragment>
      )}
    </div>
  );
};

const colors = {
  info: styles.color.lightPurple,
  success: styles.color.green,
  failure: styles.color.lightRed,
};

const useStyles = makeStyles(({ show, level }: IBanner) => ({
  base: {
    root: {
      height: show ? 100 : 0,
      width: '100vw',
      backgroundColor: colors[level || 'info'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      bottom: 0,
      zIndex: 1350, // z-index needs to be larger than the 1300 of modal overlay
      transition: `height ${styles.easing.time} ${styles.easing.authenticMotion},
        background-color ${styles.easing.time} ${styles.easing.authenticMotion}`,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      margin: '0 6rem',
    },
    iconInfo: {
      fontSize: '0.9rem',
      color: styles.color.purple,
      marginRight: '1.2rem',
    },
    iconSuccess: {
      fontSize: '1.4rem',
      color: styles.color.purple,
      marginRight: '1.2rem',
      position: 'relative',
      top: '-0.5rem',
    },
    iconFailure: {
      fontSize: '0.9rem',
      marginRight: '1.2rem',
    },
    text: {
      fontWeight: styles.font.weight.bold,
      maxWidth: '50rem',
    },
    close: {
      position: 'absolute',
      top: '50%',
      right: '3rem',
      transform: 'translateY(-50%)',
      fontSize: '0.5rem',
      cursor: 'pointer',
    },
  },
  light: {
    root: {
      color: styles.color.xDarkPurple,
    },
  },
  dark: {
    root: {
      color: styles.color.black,
    },
  },
}));

export default Banner;
