import { useCallback, useEffect, useState } from 'react';
import { useUser } from 'views/components/providers/UserProvider';
import { getAccountStoredKey, removeAccountKey } from 'utils/storage/storage';

const useOnSelection = ({
  isModal = false,
  modalShareData = false,
  onSubmit,
}: {
  isModal?: boolean;
  modalShareData?: boolean;
  onSubmit?: () => void;
}) => {
  const { accountNumber } = useUser();
  const fileShareAgreement = getAccountStoredKey(accountNumber, 'shareData');
  const [file, setFile] = useState<File[] | null>(null);
  const [shareData, setShareData] = useState<Date | null | boolean>(fileShareAgreement);

  useEffect(() => {
    if (!!fileShareAgreement) {
      setShareData(fileShareAgreement);
    }
  }, [fileShareAgreement]);

  const onSelection = useCallback(
    (onFileSelect: (files: File[]) => void) => (files: File[]) => {
      if (!shareData) {
        setShareData(null);
        setFile(() => null);
        setFile(() => files);
      } else {
        const now = new Date().getTime();
        const shareDataTime = new Date(typeof shareData === 'boolean' ? '' : shareData).getTime();

        if ((now - shareDataTime) / 1000 / 60 / 60 / 24 <= 30) {
          onFileSelect(files);
        } else {
          removeAccountKey(accountNumber, 'shareData');
          setShareData(null);
          setFile(() => null);
          setFile(() => files);
        }
      }
    },
    [accountNumber, shareData]
  );
  return { onSelection, file, hasShareData: shareData };
};
export default useOnSelection;
