import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import styles from 'views/styles';
import { ReactComponent as EmailIcon } from 'assets/images/email-icon.svg';

interface IVerifyEmail {
  style?: React.CSSProperties;
  email: string;
  onResend: () => void;
}

const VerifyEmail = ({ style, email, onResend }: IVerifyEmail) => {
  return (
    <div style={style} css={ownStyle.root} data-cy='verifyEmail'>
      <EmailIcon />
      <h1 className='h2 h-mt-xs h-mb-xs'>
        <FormattedMessage
          id='signup.verifyEmail.header'
          defaultMessage='Please verify your email address'
        />
      </h1>
      <p className='p h-mb-xs' css={ownStyle.text}>
        <FormattedMessage
          id='signup.verifyEmail.body'
          defaultMessage='Before you can contribute on the PolySwarm marketplace, we need you to verify your email address. An email containing verification instructions was sent to {email}.'
          values={{ email: <b>{email}</b> }}
        />
      </p>
      <p className='p' css={ownStyle.text}>
        <b>
          <FormattedMessage id='signup.verifyEmail.resend' defaultMessage="Didn't get the email?" />{' '}
          <button
            className='a'
            css={ownStyle.button}
            onClick={onResend}
            data-cy='resendVerifyEmailBtn'
          >
            <FormattedMessage
              id='signup.verifyEmail.resend.link'
              defaultMessage='Resend verification email'
            />
          </button>
        </b>
      </p>
    </div>
  );
};

const ownStyle = {
  root: css`
    text-align: center;
    max-width: 80rem;
    margin: 0 auto;
  `,
  button: css`
    cursor: pointer;
    font-size: ${styles.font.size.p};
    font-weight: ${styles.font.weight.medium};
    margin-bottom: 0.4rem;
  `,
  text: css`
    max-width: 70rem;
    margin: 0 auto;
    b {
      font-weight: ${styles.font.weight.medium};
    }
  `,
};

export default VerifyEmail;
