import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Payout from './Payout';

const Escrow = ({ className }: { className?: string }) => {
  const { classes } = useStyles();
  return (
    <div className={className} css={classes.escrow}>
      <div className='bounty' css={classes.bountyWrap}>
        <div css={classes.bounty}>
          <Payout bid={3} direction='top' />
        </div>
        <p>
          <FormattedMessage id='escrow.bounty' defaultMessage='Bounty' />
        </p>
      </div>
      <div className='assertions' css={classes.assertionsWrap}>
        <div css={classes.assertions}>
          <Payout bid={5} direction='top' />
          <Payout bid={3} direction='top' />
          <Payout bid={2} direction='top' />
        </div>
        <p>
          <FormattedMessage id='escrow.assertions' defaultMessage='Assertions' />
        </p>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    escrow: {
      position: 'relative',
      width: '100%',
      maxWidth: '40rem',
      height: '20rem',
      borderWidth: 2,
      borderStyle: 'dotted',
    },
    bountyWrap: {
      position: 'absolute',
      textAlign: 'center',
      bottom: '3rem',
      right: '3rem',
      p: {
        fontWeight: styles.font.weight.medium,
        marginTop: styles.spacing.tiny,
      },
    },
    bounty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    assertionsWrap: {
      position: 'absolute',
      textAlign: 'center',
      bottom: '3rem',
      left: '3rem',
      p: {
        fontWeight: styles.font.weight.medium,
        marginTop: styles.spacing.tiny,
      },
    },
    assertions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  },
  light: {
    escrow: {
      borderColor: styles.color.xLightGrey,
      backgroundColor: styles.color.white,
    },
    bountyWrap: {
      p: {
        color: styles.color.purple,
      },
    },
    assertionsWrap: {
      p: {
        color: styles.color.purple,
      },
    },
  },
  dark: {
    escrow: {
      borderColor: styles.color.xLightGrey,
      backgroundColor: styles.color.darkOffBlack,
    },
    bountyWrap: {
      p: {
        color: styles.color.lightBlue,
      },
    },
    assertionsWrap: {
      p: {
        color: styles.color.lightBlue,
      },
    },
  },
});

export default Escrow;
