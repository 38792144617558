import React from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { ComparisonTable } from './ComparisonTable';
import { pricingData } from './data';
import { Button } from '@material-ui/core';
import Icon from 'views/components/Icon';
import styles from 'views/styles';
import { ContactDialog } from '../PricingSidebar/ContactDialog/ContactDialog';

function PricingPlans() {
  const { classes } = useStyles();
  return (
    <div style={{ padding: '4rem' }}>
      <h2 css={classes.title}>Choose your plan</h2>
      <div css={classes.plansContainer}>
        {pricingData.plans.map((plan, index) => (
          <div css={classes.planBox}>
            <div css={classes.planBoxContainer}>
              <h3 css={classes.planTitle}>{plan.name}</h3>
              <div css={classes.planFeatures}>
                {plan.features.map((feature) => (
                  <div css={classes.planFeature}>
                    {'included' in feature && feature.included ? (
                      <Icon name='check-alt' css={classes.tickIcon} />
                    ) : null}
                    <span>{feature.label}</span>
                  </div>
                ))}
              </div>
            </div>
            <ContactDialog
              trigger={(props: any) => (
                <Button
                  color='primary'
                  size='small'
                  variant='contained'
                  css={classes.contactButton}
                  {...props}
                >
                  Contact Us
                </Button>
              )}
              defaultValues={{
                options: [{ label: plan.name, value: plan.name }],
              }}
            />
          </div>
        ))}
      </div>
      <ComparisonTable plans={pricingData.plans} />
    </div>
  );
}

const useStyles = makeStyles({
  base: {
    container: {
      padding: '2rem',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    title: {
      textAlign: 'center',
      fontSize: '2.5rem',
      marginBottom: '2rem',
      fontWeight: 'bold',
    },
    plansContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      gap: '2rem',
      marginBottom: '3rem',
      fontFamily: styles.font.family.main,
    },
    planBox: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '4rem',
      padding: '4rem 2rem',
      borderRadius: '8px',
      textAlign: 'center',
    },
    planBoxContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    planTitle: {
      fontSize: '1.6rem',
      color: 'var(--main)',
      fontWeight: 'bold',
    },
    planFeatures: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
    planFeature: {
      fontSize: '1.4rem',
      margin: '0.5rem 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.5rem',
    },
    contactButton: {
      padding: '1rem !important',
      minWidth: 'fit-content',
      width: '75%',
      alignSelf: 'center',
    },
    tickIcon: {
      width: '0.9em !important',
      height: '0.9em !important',
      color: 'var(--main)',
      marginRight: '0.25rem',
    },
  },
  light: {
    planBox: {
      backgroundColor: styles.color.white,
    },
  },
  dark: {
    planBox: {
      backgroundColor: styles.color.xDarkPurple,
    },
  },
});

export default PricingPlans;
