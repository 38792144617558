import React, { useState, useEffect } from 'react';
import { Welcome } from './Welcome';
import { RewardsInfo, FAQ, RewardsBreakdown } from './RewardsInfo';
import { useRewardsWallet } from './hooks';
import { useRewardsSummary } from './hooks/useRewardsSummary';
import PanelLoader from 'views/components/Loader/PanelLoader';
import ErrorPageContent from 'views/components/error/ErrorPageContent';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const messages = defineMessages({
  errorHeader: {
    id: 'rewardsTab.errorHeader',
    defaultMessage: 'Error',
  },
  refetch: {
    id: 'rewardsTab.refetch',
    defaultMessage: 'Try again',
  },
});

export const RewardsTab = () => {
  const [tab, setTab] = useState<'REWARDS_BREAKDOWN' | 'FAQ' | null>(null);
  const intl = useIntl();
  const {
    rewardsWallet,
    loading,
    error,
    errorMessage,
    startWithdrawal,
    enrollRewards,
    refetch,
  } = useRewardsWallet();
  const rewardsSummary = useRewardsSummary();

  useEffect(() => {
    if (rewardsSummary?.rewardsSummary?.amount && !tab) {
      setTab(parseInt(rewardsSummary.rewardsSummary.amount, 10) > 0 ? 'REWARDS_BREAKDOWN' : 'FAQ');
    }
  }, [rewardsSummary, tab]);

  if (loading) return <PanelLoader />;
  if (error)
    return (
      <ErrorPageContent
        className='h-mt-lg h-mb-lg'
        heading={intl.formatMessage(messages.errorHeader)}
        text={errorMessage}>
        <Button color='primary' variant='contained' onClick={refetch}>
          <FormattedMessage {...messages.refetch} />
        </Button>
      </ErrorPageContent>
    );
  return rewardsWallet?.tosAccepted ? (
    <div>
      <RewardsInfo
        rewardsWallet={rewardsWallet}
        loading={loading}
        startWithdrawal={startWithdrawal}
        summary={rewardsSummary}
      />
      <Tabs indicatorColor='primary' value={tab} onChange={(_e, newTab) => setTab(newTab)}>
        <Tab value={'REWARDS_BREAKDOWN'} label='Rewards Breakdown' />
        <Tab value={'FAQ'} label='FAQ' />
      </Tabs>
      {tab === 'REWARDS_BREAKDOWN' ? <RewardsBreakdown summary={rewardsSummary} /> : <FAQ />}
    </div>
  ) : (
    <Welcome telemetryIngest={rewardsWallet?.telemetryIngest || false} onAgree={enrollRewards} />
  );
};
