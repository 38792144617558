import {
  TeamInvitationRes,
  TeamRes,
  TeamMemberRes,
  TeamMemberInvitationRes,
} from 'services/api/schema/team';
import { Page } from './Page';

export interface Team extends TeamRes {}

export interface TeamMember extends TeamMemberRes {}

export type TeamMembers = Page<TeamMember>;

export interface TeamInvitation extends TeamInvitationRes {}

export interface TeamMemberInvitation extends TeamMemberInvitationRes {}

export type TeamMemberInvitations = Page<TeamMemberInvitation>;

export enum ETeamRole {
  teamAdmin = 'teamAdmin',
  teamOwner = 'teamOwner',
  teamMember = 'teamMember',
  billingAdmin = 'billingAdmin',
  communityAdmin = 'communityAdmin',
  microengineAdmin = 'microengineAdmin',
  walletAdmin = 'walletAdmin',
}

export const HAVE_WALLET_ACCESS = [ETeamRole.teamAdmin, ETeamRole.teamOwner, ETeamRole.walletAdmin];

export type TeamRole = keyof typeof ETeamRole;

export interface ITeamValues {
  name: string;
  description: string;
}

export interface IInviteTeamMemberValues {
  name: string;
  email: string;
  force?: boolean;
}

export interface IUpdateTeamMemberValues {
  roles: TeamRole[];
}

export interface ILimitedAccessMember {
  account_number: number;
  community: string;
  created: Date;
  expiration: string;
  id: string;
  name?: string;
  user: {
    accountNumber: number;
    email: string;
  };
  team_external?: {
    account_number: number;
    name: string;
  };
  team: {
    account_number: number;
    name: string;
  };
  scope: {
    feature_tags: string[];
  };
  tags: string[];
  type: string;
}

export interface ILimitedAccessMemberForm
  extends Omit<
    ILimitedAccessMember,
    'account_number' | 'id' | 'name' | 'created' | 'id' | 'team_external' | 'team' | 'user'
  > {
  emails: string[];
  teamAccountNumber: string;
}

export type LimitedAccessMembersPage = Page<ILimitedAccessMember>;
