import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Card from 'views/components/layout/Card';

import SilobreakerLogo from './Logo';

const Wrapper = styled.div<{ isDarkTheme: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 1rem;
  width: 70%;
  margin: 0 auto;

  & .MuiTimeline-root {
    width: fit-content;
    padding-top: 4rem;
  }

  & .MuiTimelineConnector-root {
    width: 1px;
  }

  & .MuiTimelineOppositeContent-root {
    flex: 0;
    padding: 0.5rem;
  }

  & .MuiTimelineDot-root {
    background-color: ${({ isDarkTheme }) =>
      isDarkTheme ? styles.color.lightBlue : styles.color.purple};

    & span {
      width: 2rem;
      height: 2rem;
      text-align: center;
    }
  }
`;

const LogoContainer = styled.div`
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  padding-bottom: 1rem;

  & h4 {
    margin-bottom: 0.5rem;
  }
`;

const Highlight: React.FC = ({ children }) => {
  const { classes } = useStyles();

  return <span css={classes.highlight}>{children}</span>;
};

const SilobreakerPanel = () => {
  const { classes, theme } = useStyles();
  const isDarkTheme = theme === 'dark';

  return (
    <Card>
      <Wrapper isDarkTheme={isDarkTheme}>
        <LogoContainer>
          <SilobreakerLogo width={200} height={39} color={isDarkTheme ? '#fff' : '#13263E'} />
        </LogoContainer>
        <h1 className='h3 h-mb-xxs'>
          Welcome <Highlight>Silobreaker</Highlight> users
        </h1>
        <p className='h-mt-xxs'>
          The following steps will allow you to display more details in the{' '}
          <Highlight>PolySwarm</Highlight> card in your <Highlight>Silobreaker</Highlight> instance
        </p>
        <Timeline>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <span>1</span>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent />
            <TimelineContent>
              <Content>
                <h4 className='h5'>
                  Create your <Highlight>PolySwarm</Highlight> account
                </h4>
                <p className='h-mb-xxs'>
                  Your account is assigned the free &ldquo;Community&rdquo; plan by default. At any
                  time, you can choose to purchase a paid plan to get higher usage limits and access
                  more features in the <Highlight>PolySwarm</Highlight> platform.
                </p>
              </Content>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <span>2</span>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent />
            <TimelineContent>
              <Content>
                <h4 className='h5'>
                  Copy your API key from your <Highlight>PolySwarm</Highlight> account
                </h4>
                <p className='h-mb-xxs'>
                  <a
                    href='https://polyswarm.network/account/api-keys'
                    target='_blank'
                    rel='noopener noreferrer'
                    css={classes.highlight}>
                    https://polyswarm.network/account/api-keys
                  </a>
                </p>
              </Content>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot css={classes.numberContainer}>
                <span>3</span>
              </TimelineDot>
              <TimelineConnector css={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineOppositeContent />
            <TimelineContent>
              <Content>
                <h4 className='h5'>Paste the API key</h4>
                <p className='h-mb-xxs'>
                  Paste the API key into the Silobreaker integration editor for{' '}
                  <Highlight>PolySwarm</Highlight>
                </p>
              </Content>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <span>4</span>
              </TimelineDot>
            </TimelineSeparator>
            <TimelineOppositeContent />
            <TimelineContent>
              <Content>
                <h4 className='h5'>Ready</h4>
                <p className='h-mb-xxs'>
                  With these steps you should be all set in your <Highlight>Silobreaker</Highlight>{' '}
                  instance. You are also able to directly use the <Highlight>PolySwarm</Highlight>{' '}
                  platform outside of <Highlight>Silobreaker</Highlight> if you need additional
                  functionality.
                </p>
              </Content>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Wrapper>
    </Card>
  );
};

const useStyles = makeStyles({
  base: {
    title: {
      fontFamily: styles.font.family.main,
      fontWeight: styles.font.weight.bold,
      fontSize: '2rem',
      marginBottom: '3rem',
    },
    paper: {
      padding: '6px 16px',
    },
    secondaryTail: {
      backgroundColor: styles.color.black,
    },
  },
  light: {
    title: {
      color: styles.color.black,
    },
    numberContainer: {
      background: styles.color.purple,
    },
    highlight: {
      color: styles.color.purple,
    },
  },
  dark: {
    title: {
      color: styles.color.white,
    },
    numberContainer: {
      background: styles.color.lightBlue,
    },
    highlight: {
      color: styles.color.lightBlue,
    },
  },
});

export default SilobreakerPanel;
