import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-drag' viewBox='0 0 28 32'>
      <path d='M11.954 24.388l-6.358 0.894c-1.387 0.195-2.67-0.771-2.864-2.159l-2.635-18.752c-0.195-1.387 0.771-2.67 2.159-2.864l10.299-1.447c0.664-0.093 1.339 0.080 1.875 0.483l3.529 2.649c0.539 0.405 0.895 1.008 0.989 1.675l1.257 8.943c-0.088-0.010-0.177-0.016-0.268-0.016-0.994 0-1.84 0.642-2.153 1.534-0.363-0.244-0.8-0.388-1.268-0.388-1.258 0-2.28 1.029-2.28 2.294v2.629l-1.112 1.117c-0.753 0.759-1.169 1.766-1.169 2.838v0.57zM8.773 13.228l1.254-0.176 0.199 1.415c0.046 0.326 0.348 0.554 0.674 0.508s0.554-0.348 0.508-0.674l-0.199-1.415 1.406-0.198c0.326-0.046 0.554-0.348 0.508-0.674s-0.348-0.554-0.674-0.508l-1.406 0.198-0.175-1.245c-0.046-0.326-0.348-0.554-0.674-0.508s-0.554 0.348-0.508 0.674l0.175 1.245-1.254 0.176c-0.326 0.046-0.554 0.348-0.508 0.674s0.348 0.554 0.674 0.508z' />
      <path d='M26.014 17.016l-0 0c1.097 0 1.989 0.892 1.989 1.989v6.465c0 3.564-2.9 6.464-6.464 6.464-2.057 0-3.991-0.8-5.444-2.255l-1.99-1.989c-0.658-0.658-1.020-1.533-1.020-2.463v-1.506c0-0.93 0.363-1.803 1.020-2.462l0.97-0.969v-2.28c0-1.097 0.892-1.989 1.989-1.989 0.409 0 0.79 0.124 1.106 0.336 0.273-0.774 1.011-1.331 1.878-1.331s1.606 0.557 1.877 1.331c0.316-0.212 0.697-0.336 1.106-0.336 0.866 0 1.605 0.557 1.878 1.331 0.316-0.212 0.697-0.336 1.106-0.336zM27.009 25.469l-0-6.464c0-0.548-0.445-0.995-0.995-0.995s-0.995 0.446-0.995 0.995l0.001 0.497c0 0.275-0.223 0.497-0.497 0.497s-0.497-0.223-0.497-0.497v-1.492c0-0.548-0.446-0.995-0.995-0.995s-0.995 0.446-0.995 0.995v1.491c0 0.275-0.223 0.497-0.497 0.497s-0.497-0.223-0.497-0.497v-2.486c0-0.548-0.446-0.995-0.995-0.995s-0.995 0.446-0.995 0.995v2.487c0 0.275-0.223 0.497-0.497 0.497s-0.497-0.223-0.497-0.497v-1.492c0-0.548-0.445-0.995-0.994-0.995s-0.995 0.446-0.995 0.995v4.475c0 0.275-0.223 0.497-0.497 0.497s-0.497-0.223-0.497-0.497v-0.789l-0.267 0.267c-0.469 0.469-0.728 1.094-0.728 1.758v1.506c0 0.663 0.258 1.288 0.728 1.758l1.99 1.989c1.265 1.266 2.949 1.963 4.74 1.963 3.016 0 5.471-2.454 5.471-5.47z' />
    </symbol>

    <Global
      styles={css`
        .icon-drag {
          width: 2.8em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
