import React, { useState, FC } from 'react'; // eslint-disable-line
import { jsx, keyframes } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import HowItWorksSection from '../HowItWorksSection';
import { Escrow, Engines, Bids } from '../HowItWorksSubsections';
import arrowImg from '../shared/arrow.svg';
import fileImg from '../shared/file.svg';

const CompeteSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { classes } = useStyles(isVisible);

  const _handleVisibilityChange = (isVisible: boolean) => setIsVisible(isVisible);

  return (
    <HowItWorksSection onVisibilityChange={_handleVisibilityChange}>
      <div css={classes.root}>
        <div css={classes.left}>
          <ol className='ol' css={classes.list}>
            <li>
              <FormattedMessage
                id='competeSection.bountyText'
                defaultMessage='{bounty} is provided with the artifact (by PolySwarm) to incentivize engine participation'
                values={{
                  bounty: (
                    <b>
                      <FormattedMessage id='competeSection.bounty' defaultMessage='A bounty' />
                    </b>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id='competeSection.scanText'
                defaultMessage='{scan} they can pass if unsure'
                values={{
                  scan: (
                    <b>
                      <FormattedMessage
                        id='competeSection.scan'
                        defaultMessage='Engines scan the artifact,'
                      />
                    </b>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id='competeSection.assertionText'
                defaultMessage='Engines make a determination {assertion} reflecting confidence in their assertion'
                values={{
                  assertion: (
                    <b>
                      <FormattedMessage
                        id='competeSection.assertion'
                        defaultMessage='backed by money,'
                      />
                    </b>
                  ),
                }}
              />
            </li>
          </ol>
          <Escrow css={classes.escrow} />
        </div>
        <div css={classes.right}>
          <Engines css={classes.engines} arrow={true} />
          <div css={classes.fileWrap}>
            <div css={classes.scanSquare}>
              <div css={classes.scanBar} />
            </div>
            <div css={classes.file}>
              <img css={classes.fileArrow} src={arrowImg} alt='arrow' />
              <img src={fileImg} alt='file' />
            </div>
          </div>
          <Bids
            items={[{ result: 'M', bid: 5 }, { result: 'B', bid: 3 }, { result: 'B', bid: 2 }, {}]}
            css={classes.bids}
          />
        </div>
      </div>
    </HowItWorksSection>
  );
};

const show = keyframes`
  to {
    opacity: 1;
  }
`;

const hide = keyframes`
  to {
    opacity: 0;
  }
`;

const dim = keyframes`
  to {
    opacity: 0.5;
  }
`;

const fadeInUp = keyframes`
  to {
    opacity: 1;
    top: 0
  }
`;

const showHide = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const scanSquareShow = keyframes`
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const scanBar = keyframes`
  0% {
    top: 50%;
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  10% {
    top: 0;
  }
  20% {
    top: 50%;
  }
  30% {
    top: 100%;
  }
  40% {
    top: 50%;
  }
  50% {
    top: 0;
  }
  60% {
    top: 50%;
  }
  70% {
    top: 100%;
  }
  80% {
    top: 50%;
  }
  90% {
    top: 0;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 50%;
  }
`;

const useStyles = makeStyles((isVisible: boolean) => ({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '6rem',
    },
    left: {
      width: '50%',
      position: 'relative',
    },
    list: {
      maxWidth: '42rem',
      b: {
        fontWeight: styles.font.weight.bold,
      },
      li: {
        opacity: 0,
        marginBottom: styles.spacing.xs,
        '&:nth-of-type(1)': {
          opacity: 1,
        },
        '&:nth-of-type(2)': {
          animation: isVisible ? `${show} 0.2s ease-in-out 4s forwards` : 'none',
        },
        '&:nth-of-type(3)': {
          animation: isVisible ? `${show} 0.5s ease-in-out 8s forwards` : 'none',
        },
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    escrow: {
      position: 'absolute',
      bottom: '-6rem',
      left: '3rem',
      animation: isVisible ? `${hide} 0.2s ease-in-out 3.5s forwards` : 'none',
      '.assertions': {
        display: 'none',
      },
      '.bounty': {
        opacity: 0,
        animation: isVisible ? `${show} 0.2s ease-in-out 0.2s forwards` : 'none',
        '.payout': {
          opacity: 0,
          top: 10,
          animation: isVisible ? `${fadeInUp} 0.2s ease-in-out 0.3s forwards` : 'none',
        },
      },
    },
    right: {
      width: '50%',
      position: 'relative',
      textAlign: 'center',
    },
    engines: {
      paddingBottom: '5rem',
      margin: '0 auto',
      '.engine': {
        '&:nth-of-type(4)': {
          animation: isVisible ? `${dim} 0.4s ease-in-out 10s forwards` : 'none',
        },
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: '-5rem',
          left: '50%',
          height: '3rem',
          width: 2,
          transform: 'translateX(-50%)',
          backgroundColor: styles.color.xLightGrey,
          opacity: 0,
          animation: isVisible ? `${showHide} 2.7s ease-in-out 1s forwards` : 'none',
        },
        '.arrow': {
          opacity: 0,
          animation: isVisible ? `${showHide} 2.7s ease-in-out 1s forwards` : 'none',
        },
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        height: 2,
        width: '27.7rem',
        transform: 'translateX(-50%)',
        backgroundColor: styles.color.xLightGrey,
        opacity: 0,
        animation: isVisible ? `${showHide} 2.8s ease-in-out 0.9s forwards` : 'none',
      },
    },
    fileWrap: {
      position: 'relative',
      marginTop: '4rem',
    },
    file: {
      display: 'inline-block',
      position: 'relative',
      opacity: 0,
      animation: isVisible ? `${showHide} 7.4s linear 0.6s forwards` : 'none',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '-4rem',
        left: '50%',
        height: '4rem',
        width: 2,
        transform: 'translateX(-50%)',
        backgroundColor: styles.color.xLightGrey,
        opacity: 0,
        animation: isVisible ? `${showHide} 2.9s ease-in-out 0.8s forwards` : 'none',
      },
    },
    fileArrow: {
      position: 'absolute',
      top: '-4rem',
      left: '50%',
      transform: 'translateX(-50%) rotate(-90deg)',
      opacity: 0,
      animation: isVisible ? `${showHide} 2.9s ease-in-out 0.8s forwards` : 'none',
    },
    scanSquare: {
      position: 'absolute',
      bottom: -22,
      left: '50%',
      transform: 'translateX(-50%)',
      height: 115,
      width: 115,
      borderWidth: 5,
      borderStyle: 'solid',
      borderRadius: 20,
      opacity: 0,
      animation: isVisible ? `${scanSquareShow} 4s linear 4s forwards` : 'none',
      '&:before': {
        content: '""',
        display: 'block',
        width: 120,
        height: 40,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      '&:after': {
        content: '""',
        display: 'block',
        width: 40,
        height: 120,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    scanBar: {
      width: 130,
      height: 5,
      borderRadius: 20,
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      animation: isVisible ? `${scanBar} 4s linear 4s forwards` : 'none',
    },
    bids: {
      margin: '3rem auto 0',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0,
      animation: isVisible ? `${show} 0.2s ease-in-out 8s forwards` : 'none',
      '.bid': {
        '.result span': {
          opacity: 0,
          top: 10,
        },
        '.payout': {
          opacity: 0,
          top: 10,
        },
        '&:nth-of-type(1)': {
          '.result span': {
            animation: isVisible ? `${fadeInUp} 0.4s ease-in-out 9s forwards` : 'none',
          },
          '.payout': {
            animation: isVisible ? `${fadeInUp} 0.4s ease-in-out 9.4s forwards` : 'none',
          },
        },
        '&:nth-of-type(2)': {
          '.result span': {
            animation: isVisible ? `${fadeInUp} 0.4s ease-in-out 9.8s forwards` : 'none',
          },
          '.payout': {
            animation: isVisible ? `${fadeInUp} 0.4s ease-in-out 10.2s forwards` : 'none',
          },
        },
        '&:nth-of-type(3)': {
          '.result span': {
            animation: isVisible ? `${fadeInUp} 0.4s ease-in-out 10.6s forwards` : 'none',
          },
          '.payout': {
            animation: isVisible ? `${fadeInUp} 0.4s ease-in-out 11s forwards` : 'none',
          },
        },
        '&:nth-of-type(4)': {
          animation: isVisible ? `${dim} 0.4s ease-in-out 10s forwards` : 'none',
        },
      },
    },
  },
  light: {
    scanSquare: {
      borderColor: styles.color.black,
      '&:before': {
        backgroundColor: styles.color.white,
      },
      '&:after': {
        backgroundColor: styles.color.white,
      },
    },
    scanBar: {
      backgroundColor: styles.color.black,
    },
  },
  dark: {
    scanSquare: {
      borderColor: styles.color.white,
      '&:before': {
        backgroundColor: styles.color.darkOffBlack,
      },
      '&:after': {
        backgroundColor: styles.color.darkOffBlack,
      },
    },
    scanBar: {
      backgroundColor: styles.color.white,
    },
  },
}));

export default CompeteSection;
