import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { closeModal } from 'state/modal/actions';
import { btnMessages } from 'views/components/Button';
import StatusModal from 'views/components/Modal/StatusModal';

const messages = defineMessages({
  heading: {
    id: 'modals.emailVerifiactionSuccess.heading',
    defaultMessage: 'Success',
  },
  text: {
    id: 'modals.emailVerifiactionSuccess.text',
    defaultMessage:
      'Thank you for confirming your email address. Your form has been successfully submitted. A member of our team will follow up within 24 hours.',
  },
});

const EmailVerifiactionSuccessModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  return (
    <StatusModal
      state='ok'
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text)}
      buttonText={intl.formatMessage(btnMessages.close)}
      onClick={() => dispatch(closeModal())}
    />
  );
};

export default EmailVerifiactionSuccessModal;
