import React, { Fragment, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import SEO from 'views/components/layout/SEO';
import styles from 'views/styles';
import Loader from 'views/components/Loader';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Logo from 'views/components/layout/Header/Logo';

const region = process.env.REACT_APP_REGION as 'us' | 'eu';

const RegionRedirect = () => {
  const { classes } = useStyles();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/login';
    }, 3000);
  }, []);

  return (
    <Fragment>
      <SEO />
      <div>
        <header css={classes.root}>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div css={classes.content}>
                  <Logo />
                </div>
              </div>
            </div>
          </div>
        </header>
        <div css={classes.container}>
          <h2 className='h2 h-mb-xxs'>Redirecting you to {region.toUpperCase()} site</h2>
          <Loader />
        </div>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      margin: '3rem auto',
      width: 'max-content',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '3rem',
      padding: '4rem 0',
    },
    icon: {
      fontSize: '8rem',
    },
    button: {
      fontSize: '2rem',
    },
    root: {
      padding: '1.8rem 0',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  light: {
    icon: {
      color: styles.color.xxLightGrey,
    },
  },
});

export default RegionRedirect;
