import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-clockface2' viewBox='0 0 70 70'>
      <circle
        cx='35.0004'
        cy='35.0004'
        r='31.0859'
        fill='var(--highlight)'
        stroke='var(--main)'
        strokeWidth='7.82905'
      />
      <path
        d='M34.998 17.499V38.3765L48.0465 46.2055'
        fill='transparent'
        stroke='var(--main)'
        strokeWidth='7.82905'
        strokeLinecap='round'
      />
    </symbol>

    <Global
      styles={css`
        .icon-clockface2 {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
