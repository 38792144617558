import React, { Fragment, useEffect } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import * as yup from 'yup';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { useIntl, FormattedMessage, IntlShape } from 'react-intl';
import { isEmpty } from 'lodash';
import debounce from 'debounce-promise';
import { formSchema } from 'utils/schema/formSchema';
import { formMessages } from 'utils/schema/formSchema';
import Button from '@material-ui/core/Button';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import FormInput from 'views/components/form/FormInput';
import CustomForm from 'views/components/form/CustomForm';
import UsernameField from 'views/pages/AuthCallbackPage/AccountSignupForm/UsernameField';
import api from 'services/api';
import { getMFAData, removeMFAData } from 'state/auth/actions';
import { openModal } from 'state/modal/actions';
import { useDispatch } from 'react-redux';

interface IProfileForm {
  initialValues: IProfileFormValues;
  isLoading: boolean;
  errorMessage?: string;
  showChangePasswordLink: boolean;
  onPasswordChange: (email: string) => void;
  onSubmit: (values: IProfileFormValues, actions: FormikHelpers<IProfileFormValues>) => void;
}

export interface IProfileFormValues {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  company?: string;
}

const getValidationSchema = (intl: IntlShape) => {
  const schema = formSchema(intl);
  const { username, firstName, lastName, email, company } = schema;

  const checkUsernameAvailability = debounce((value: string) => {
    return api.checkUsername(value).then((result) => !result.data);
  }, 200);

  return yup.object().shape({
    company,
    username: username
      .test({
        exclusive: true,
        name: 'unique',
        test: (value) => {
          if (!value || value.length < 3 || value.length > 15) {
            return true;
          }

          return checkUsernameAvailability(value);
        },
        message: 'Username is already taken. Please try a different username.',
      })
      .required(
        intl.formatMessage(formMessages.required, {
          label: intl.formatMessage(formMessages.username),
        })
      ),
    firstName: firstName.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.firstName),
      })
    ),
    lastName: lastName.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.lastName),
      })
    ),
    email: email.required(
      intl.formatMessage(formMessages.required, { label: intl.formatMessage(formMessages.email) })
    ),
  });
};

const ProfileForm = ({
  initialValues,
  isLoading,
  errorMessage,
  showChangePasswordLink,
  onPasswordChange,
  onSubmit,
}: IProfileForm) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal } = mfaStep;
      if (modal === 'CREATE_TEAM') {
        dispatch(openModal(modal));
        removeMFAData();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(intl)}
      onSubmit={onSubmit}>
      {(formikProps: FormikProps<IProfileFormValues>) => (
        <CustomForm
          testId='profileForm'
          isLoading={isLoading}
          renderFields={() => (
            <Fragment>
              <UsernameField
                className='h-mb-sm'
                name='username'
                label={intl.formatMessage(formMessages.username)}
              />
              <FormInput
                className='h-mb-sm'
                name='firstName'
                label={intl.formatMessage(formMessages.firstName)}
              />
              <FormInput
                className='h-mb-sm'
                name='lastName'
                label={intl.formatMessage(formMessages.lastName)}
              />
              <FormInput
                className='h-mb-sm'
                name='company'
                label={intl.formatMessage(formMessages.company)}
              />
              <FormInput
                className='h-mb-sm'
                name='email'
                label={intl.formatMessage(formMessages.email)}
                disabled
              />
              {showChangePasswordLink && (
                <button
                  className='a'
                  css={style.textBtn}
                  type='button'
                  onClick={() => onPasswordChange(initialValues.email)}>
                  <FormattedMessage
                    id='account.profile.passwordReset'
                    defaultMessage='Send change password email'
                  />
                </button>
              )}
              <p className='p1 h-mt-xs' css={style.error} data-cy='profileFormError'>
                {errorMessage}
              </p>
            </Fragment>
          )}
          renderButtonBar={() => (
            <Button
              className='h-mt-xs'
              css={style.button}
              type='submit'
              color='primary'
              variant='contained'
              disabled={!formikProps.values.username || !isEmpty(formikProps.errors)}>
              <FormattedMessage {...btnMessages.update} />
            </Button>
          )}
        />
      )}
    </Formik>
  );
};

const style = {
  button: css`
    width: 25rem;
  `,
  textBtn: css`
    cursor: pointer;
    font-size: ${styles.font.size.p};
    font-family: ${styles.font.family.main};
  `,
  error: css`
    color: ${styles.color.red};
  `,
};

export default ProfileForm;
