import styled from '@emotion/styled';
import styles from 'views/styles';

export const Note = styled.div`
  padding-left: calc(30px + 0.8rem);
  color: ${styles.color.lightGrey};
  font-size: 12px;
  font-style: italic;
`;

export const Bold = styled.span`
  font-weight: 600;
`;
