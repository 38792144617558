import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';

interface Props {
  className?: string;
  value: string;
  alignNotification?: 'right' | 'left';
  height?: string;
}

const CopyTextArea = ({ className, value, alignNotification, height }: Props) => {
  const { classes } = useStyles(height);
  return (
    <div className={className} css={classes.root}>
      <div css={classes.area}>
        <code css={classes.text}>
          <pre>{value}</pre>
        </code>
      </div>
      <CopyTextButton css={classes.copy} text={value} alignNotification={alignNotification} />
    </div>
  );
};

const useStyles = makeStyles((height?: string) => ({
  base: {
    root: {
      position: 'relative',
    },
    copy: {
      position: 'absolute',
      bottom: styles.spacing.xxs,
      right: styles.spacing.xs,
      fontSize: '0.5rem',
    },
    area: {
      resize: 'vertical',
      overflowX: 'auto',
      borderRadius: styles.border.radius,
      height: height || '24rem',
      width: '100%',
      padding: styles.spacing.xxs,
    },
    text: {
      whiteSpace: 'nowrap',
      fontFamily: styles.font.family.mono,
      fontSize: '1.2rem',
      lineHeight: 1.8,
    },
  },
  light: {
    area: {
      background: styles.color.xxLightPurple,
    },
    text: {
      color: styles.color.medGrey,
    },
  },
  dark: {
    area: {
      background: styles.color.darkOffBlack,
    },
    text: {
      color: styles.color.xxLightGrey,
    },
  },
}));

export default CopyTextArea;
