import api from 'services/api';
import { ScanType } from 'models/Ruleset';

export const useGetHuntResult = () => {
  const getHuntResult = async (resultId: string, scanType: ScanType, historicalResult?: string) => {
    const response = await api.getHuntResult(resultId, scanType, historicalResult);
    return response.data;
  };

  return { getHuntResult };
};
