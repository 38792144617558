import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import tenant from 'tenants';

export const PolyswarmLogo = () => {
  const { classes } = useStyles();
  return (
    <Link css={classes.root} to='/' data-cy='homeLogo'>
      <Icon css={classes.icon} name='wordmark' title='PolySwarm' />
      <FormattedMessage id='company.name' defaultMessage='PolySwarm' />
    </Link>
  );
};

export const Logo = () => {
  const { classes } = useStyles();
  if (tenant.assets?.logo) {
    const Logo = tenant.assets.logo;
    return (
      <Link css={classes.root} to='/' data-cy='homeLogo'>
        <Logo />
      </Link>
    );
  }
  return <PolyswarmLogo />;
};

const useStyles = makeStyles({
  base: {
    root: {
      fontSize: 0,
    },
    icon: {
      fontSize: '1.1rem',
    },
  },
  light: {
    root: {
      color: styles.color.purple,
    },
  },
  dark: {
    root: {
      color: styles.color.white,
    },
  },
});

export default Logo;
