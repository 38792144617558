import React from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';

import Icon from 'views/components/Icon';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

const SmallLogoWrapper = styled(Icon)`
  font-size: 0.3em;
  position: relative;
  top: 3px;
`;

const TinyLogoWrapper = styled(Icon)`
  font-size: 0.5em;
  position: relative;
  top: 3px;
  margin-right: 0.8rem;
`;

export const SmallLogo = (props: any) => {
  const { classes } = useStyles();
  return <SmallLogoWrapper {...props} name='logo' css={classes.icon} />;
};

export const TinyLogo: React.FC = (props: any) => {
  const { classes } = useStyles();
  return <TinyLogoWrapper {...props} name='logo' css={classes.icon} />;
};

const useStyles = makeStyles({
  base: {
    icon: {
      color: styles.color.purple,
    },
  },
  light: {
    icon: {
      color: styles.color.purple,
    },
  },
  dark: {
    icon: {
      color: styles.color.white,
    },
  },
});
