import { useState } from 'react';
import api from 'services/api';

export const useSetGroundTruth = (callback: () => void) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const setGroundTruth = async (
    communityName: string,
    instanceId: string,
    expectedVerdict: string
  ) => {
    setLoading(true);
    setError(false);
    try {
      const response = await api.setDevelopmentCommunityResult(communityName, instanceId, {
        expected_verdict: expectedVerdict,
      });
      if (response.data) {
        setError(false);
        callback();
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return {
    loading,
    error,
    setGroundTruth,
  };
};
