// @ts-nocheck
import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl } from 'react-intl'; // IntlShape
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { formMessages } from 'utils/schema/formSchema';
import { Filter } from 'models/Ruleset';
import Button from '@material-ui/core/Button/Button';
import useFormBuilder from 'components/FormBuilder';

interface IFilterForm {
  onFilterClose: () => void;
  initialValues?: Partial<Filter>;
  onSubmit: (values: Filter, actions: FormikHelpers<Filter>) => void;
}

export const filterDefaultValues = {
  rule_name: '',
  family: '',
  community: 'default',
  polyscore_lower: 0,
  polyscore_upper: 1,
};

const isThereAnyFilter = (filters: FilterProps, defaultValues: Partial<FilterProps>) => {
  const defaults = { ...filterDefaultValues, ...defaultValues };
  return Object.entries(filters).some(
    ([key, value]) => value !== defaults[key as keyof FilterProps]
  );
};

const FilterForm = ({ initialValues, onSubmit, onFilterClose }: IFilterForm) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const { FormComponent } = useFormBuilder([
    {
      elementType: 'input',
      name: intl.formatMessage(formMessages.ruleName),
      id: 'rule_name',
      placeholder: intl.formatMessage(formMessages.ruleName),
      defaultValue: '',
    },
    {
      elementType: 'select',
      name: 'Community',
      id: 'community',
      defaultValue: 'Default',
      defaultOptions: ['Default'].concat(['Public', 'Private']),
    },
    {
      elementType: 'input',
      name: intl.formatMessage(formMessages.malwareFamilyName),
      id: 'family',
      placeholder: intl.formatMessage(formMessages.malwareFamilyName),
      defaultValue: '',
    },
    {
      name: 'PolyScore',
      combine: (values) => [
        {
          elementType: { name: 'input', type: 'number' },
          placeholder: intl.formatMessage(formMessages.polyscoreLower),
          name: intl.formatMessage(formMessages.polyscoreLower),
          id: 'polyscore_lower',
          defaultValue: 0,
          inputOptions: { min: 0, max: 1, step: 0.1 },
        },
        {
          elementType: { name: 'input', type: 'number' },
          name: intl.formatMessage(formMessages.polyscoreUpper),
          placeholder: intl.formatMessage(formMessages.polyscoreUpper),
          id: 'polyscore_upper',
          defaultValue: 1,
          inputOptions: { min: values?.polyscore_lower ?? 0, max: 1, step: 0.1 },
        },
      ],
    },
  ]);

  return (
    <FormComponent
      onSubmit={(data) => {
        onSubmit && onSubmit(data);
        onFilterClose();
      }}
      filters={initialValues}
      footerRender={({ resetForm, dirtyFields }) => {
        return (
          <div css={classes.buttonsContainer}>
            <Button
              onClick={() => {
                resetForm();
                onSubmit && onSubmit(filterDefaultValues);
              }}
              size='small'
              variant='text'
              color='primary'
              css={classes.button}
            >
              Reset
            </Button>
            <Button
              type='submit'
              size='small'
              variant='contained'
              color='primary'
              disabled={!isThereAnyFilter(dirtyFields)}
              css={classes.button}
            >
              Search
            </Button>
          </div>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  base: {
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
    },
    button: {
      padding: '0.7rem 2rem !important',
    },
  },
});

export default FilterForm;
