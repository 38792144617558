import styled from '@emotion/styled';

const CenteredContent = styled('div')`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
`;

export default CenteredContent;
