import {
  TimeSeriesArray,
  TimeSeries,
  TimeSeriesAttributes,
  PageTimeSeries,
  TimeSeriesObject,
} from 'models/TimeSeries';
import { initialPagination, buildPaginatedData } from 'utils/pagination';
import { pick } from 'lodash';
import { subDays } from 'date-fns';
import { TimeSeriesDataRes } from 'services/api/schema/timeSeries';

const initialParams = {
  interval: 'day',
  from: subDays(new Date(), 1).toISOString(),
  until: new Date().toISOString(),
};

const initialValues = {
  results: null,
};

export const initialTimeSeries = Object.assign({}, initialParams, initialValues);

/**
 * Generates time series data with generic results
 */
export const createInitialTimeSeries = <T extends {}>(
  params?: Partial<TimeSeries<T>>
): TimeSeries<T> => Object.assign({}, initialTimeSeries, params);

/**
 * Generates paginated time series data
 */
export const createInitialPageTimeSeries = <T extends {}>(
  data?: Partial<PageTimeSeries<T>>
): PageTimeSeries<T> => Object.assign({}, initialPagination, initialTimeSeries, data);

export const extractTimeSeriesParams = <T extends {}>(data: TimeSeriesArray<T>) =>
  pick<TimeSeriesArray<T>>(data, Object.keys(initialParams)) as TimeSeriesAttributes;

/**
 * Extract the time series data out of an api response, type it, and return a new object.
 */
export const buildTimeSeriesData = <T>(res: any, results: T): TimeSeries<T> => {
  if (!res.results) {
    throw new Error(`Response does not include the results param.`);
  }
  const data = Object.keys(initialParams).reduce((acc: any, key) => {
    if (res[key] !== undefined) {
      acc[key] = res[key];
    }
    return acc;
  }, {}) as TimeSeries<T>;
  data.results = results;
  return data;
};

/**
 * Extract the pagination and time series data out of an api response, type it, and return a new object.
 */
export const buildPageTimeData = <T>(res: any, results: T[]): PageTimeSeries<T> => {
  if (!res.results) {
    throw new Error(`Response does not include the results param.`);
  }
  const pageData = buildPaginatedData(res, results);
  const timeSeriesData = buildTimeSeriesData(res, results);
  return { ...pageData, ...timeSeriesData };
};

/**
 * Convert a time series response to a TimeSeriesObject of type string or number.
 *
 * @param type 0 | '0' - pass in a string to infer type string, and number for type number
 * @param data the time series data you want to convert
 */
export const timeSeriesTo = <T>(type: T, data: TimeSeriesDataRes[]): TimeSeriesObject<T>[] =>
  typeof type === 'string'
    ? (data.map((event) => ({ ...event, value: String(event.value) })) as any)
    : (data.map((event) => ({ ...event, value: Number(event.value) })) as any);
