import React, { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import ErrorPageContent from 'views/components/error/ErrorPageContent';
import Button from '@material-ui/core/Button';
import PanelLoader from 'views/components/Loader/PanelLoader';
import WalletsTable from './WalletsTable';
import { useUser } from 'views/components/providers/UserProvider';
import useUserWallets from './hooks';
import { useRewardsWallet } from 'views/pages/AccountPage/AccountPanel/AccountTabs/RewardsTab/hooks/useRewardsWallet';
import { WalletRes } from 'services/api/schema/wallets';
import { WalletsType, WalletsTypeEnum } from './types';
import { SetupMfaPanel } from 'views/components/SetupMfaPanel/SetupMfaPanel';

const messages = defineMessages({
  webhook: {
    id: 'walletsTab.wallets',
    defaultMessage: 'wallets',
  },
  walletsHeading: {
    id: 'walletsTab.walletsHeading',
    defaultMessage: 'Create a PolySwarm Wallet',
  },
  errorHeader: {
    id: 'walletsTab.errorHeader',
    defaultMessage: 'Error',
  },
  errorText: {
    id: 'walletsTab.errorText',
    defaultMessage: 'Error getting wallets',
  },
  refetch: {
    id: 'walletsTab.refetch',
    defaultMessage: 'Try again',
  },
});

const WalletsTableContainer = () => {
  const intl = useIntl();
  const user = useUser();
  const [selectedWallet, setSelectedWallet] = useState<{ type?: WalletsType; id?: number }>({});
  const { rewardsWallet, configureWithdrawal, refetch: refetchRewardsWallet } = useRewardsWallet(user.mfaEnabled);
  const { userWallets, refetch, loading, error}: {
    userWallets: WalletRes[] | null;
    refetch: () => void;
    loading: boolean;
    error: boolean;
  } = useUserWallets(user.mfaEnabled);

  if (error)
    return (
      <ErrorPageContent
        className='h-mt-lg h-mb-lg'
        heading={intl.formatMessage(messages.errorHeader)}
        text={intl.formatMessage(messages.errorText)}>
        <Button color='primary' variant='contained' onClick={refetch}>
          <FormattedMessage {...messages.refetch} />
        </Button>
      </ErrorPageContent>
    );
  if (loading) return <PanelLoader />;
  if (!user.mfaEnabled) return <SetupMfaPanel />;

  const rewardsWalletRows =
    rewardsWallet && rewardsWallet.tosAccepted
      ? [{ ...rewardsWallet, engineName: 'NectarNet' }]
      : [];
  const refetchWallets = () => {
    refetch();
    refetchRewardsWallet();
  };

  const BaseWalletsTable = ({
    walletType,
    title,
    results,
  }: {
    walletType: WalletsType;
    title: string;
    results: WalletRes[];
  }) => {
    return (
      <WalletsTable
        onWalletClick={(id) => setSelectedWallet({ type: walletType, id })}
        key={`${walletType}WalletTable`}
        title={title}
        type={walletType}
        results={results}
        enableActions={true}
        configureWithdrawal={configureWithdrawal} // TODO change to a configureWithdrawal method from engines hook
        refetch={refetchWallets}
        selected={selectedWallet}
        goBackToWallets={() => setSelectedWallet({})}
      />
    );
  };

  return (
    <>
      {userWallets && selectedWallet.type !== WalletsTypeEnum.reward && (
        <BaseWalletsTable
          title='Engine Wallets'
          walletType={WalletsTypeEnum.engine}
          results={
            selectedWallet.id
              ? userWallets.filter((wallet) => wallet.id === selectedWallet.id)
              : userWallets
          }
        />
      )}
      {!user.context?.team &&
        rewardsWallet?.telemetryIngest &&
        selectedWallet.type !== WalletsTypeEnum.engine && (
          <BaseWalletsTable
            title='Rewards Wallets'
            walletType={WalletsTypeEnum.reward}
            results={
              selectedWallet.id
                ? (rewardsWalletRows.filter(
                    (wallet) => wallet.id === selectedWallet.id
                  ) as WalletRes[])
                : (rewardsWalletRows as WalletRes[])
            }
          />
        )}
    </>
  );
};

export default WalletsTableContainer;
