import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-link' viewBox='0 0 32 32'>
      <path d='M19.5599 2.20271C19.8015 2.67246 19.9471 3.18479 19.9883 3.71034C20.0295 4.23589 19.9656 4.76432 19.8001 5.26537C19.6347 5.76641 19.371 6.23022 19.0242 6.63021C18.6774 7.03019 18.2542 7.3585 17.779 7.59632L12.8768 10.062C12.7819 10.1097 12.6854 10.1529 12.5888 10.193C12.0697 10.4043 11.5118 10.5065 10.9506 10.493C10.3894 10.4795 9.8372 10.3505 9.32906 10.1144C9.15819 10.0364 8.99327 9.94627 8.83557 9.84468C8.23672 9.4604 7.75158 8.9257 7.42968 8.29516C7.10779 7.66463 6.96065 6.96084 7.0033 6.25562L8.0432 5.73321C8.2627 5.62321 8.50062 5.55376 8.74528 5.52825C8.60718 5.85712 8.54269 6.21171 8.55625 6.56761C8.56981 6.9235 8.6611 7.27227 8.82383 7.58991C8.98656 7.90755 9.21687 8.18652 9.49892 8.40763C9.78097 8.62874 10.1081 8.78674 10.4577 8.87075C10.6343 8.91599 10.8155 8.94081 10.9979 8.94472C11.4058 8.95293 11.8095 8.86136 12.1732 8.67812L17.0753 6.21247C17.6582 5.9132 18.0981 5.3982 18.2994 4.7795C18.5006 4.16081 18.447 3.48847 18.1501 2.90874C17.8531 2.32901 17.337 1.88879 16.7139 1.68385C16.0908 1.4789 15.4111 1.52581 14.8228 1.81437L11.1893 3.63741C10.503 3.25227 9.72727 3.05046 8.93832 3.05181C9.02861 2.99942 9.12046 2.94702 9.21542 2.89771L14.1176 0.43206C14.5923 0.194136 15.1097 0.0512019 15.6403 0.0114356C16.1708 -0.0283307 16.7041 0.035851 17.2096 0.200309C17.7151 0.364766 18.1828 0.626273 18.5861 0.969865C18.9894 1.31346 19.3203 1.73239 19.5599 2.20271V2.20271Z' />
      <path d='M12.5691 5.70559C12.8923 6.3338 13.0401 7.03606 12.9972 7.73976L11.9604 8.26063C11.7409 8.37062 11.503 8.44008 11.2583 8.46558C11.3964 8.13671 11.4609 7.78213 11.4473 7.42623C11.4338 7.07033 11.3425 6.72156 11.1798 6.40392C11.017 6.08628 10.7867 5.80731 10.5047 5.5862C10.2226 5.3651 9.89554 5.2071 9.54592 5.12308C9.36977 5.07879 9.18901 5.055 9.00729 5.0522C8.59884 5.04418 8.19467 5.13573 7.8304 5.31879L2.92826 7.78445C2.62385 7.92321 2.35085 8.12137 2.12562 8.36704C1.90039 8.61272 1.72756 8.90086 1.61749 9.21422C1.50741 9.52758 1.46235 9.8597 1.485 10.1907C1.50765 10.5217 1.59755 10.8448 1.74932 11.1406C1.90108 11.4364 2.11159 11.6988 2.36823 11.9122C2.62487 12.1255 2.92237 12.2854 3.24292 12.3823C3.56346 12.4792 3.90045 12.511 4.23371 12.4759C4.56697 12.4407 4.88964 12.3394 5.18241 12.1779L8.8127 10.358C9.4973 10.7434 10.2715 10.9457 11.0591 10.9451C10.9688 10.999 10.8769 11.0499 10.782 11.0992L5.87982 13.5649C4.92196 14.0455 3.81046 14.1298 2.78982 13.7992C1.76919 13.4685 0.923032 12.7501 0.437497 11.8019C-0.048038 10.8537 -0.133179 9.75342 0.200804 8.74308C0.534787 7.73273 1.26054 6.89511 2.21839 6.41447L7.12053 3.94882C7.21549 3.90105 7.31201 3.8579 7.40853 3.81783C7.67636 3.70758 7.95567 3.62696 8.24138 3.57743C9.06421 3.43249 9.9122 3.54394 10.6683 3.89642C10.8415 3.97306 11.0086 4.0627 11.168 4.16456C11.7652 4.54569 12.2489 5.07766 12.5691 5.70559V5.70559Z' />
    </symbol>

    <Global
      styles={css`
        .icon-link {
          width: 3.6rem;
          height: 3.6rem;
        }
      `}
    />
  </Fragment>
);
