import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import isEmpty from 'lodash/isEmpty';
import zip from 'lodash/zip';
import styles from 'views/styles';
import { IRequests, SandboxProviders } from 'models/Sandbox';
import { ActiveSections } from 'views/components/ActiveSections';
import AccordionData, {
  IAccordionData,
} from 'views/components/Accordion/AccordionData/AccordionData';
import { useLocation } from 'react-router-dom';

const mapData = (data: IRequests) => {
  const httpData = zip(data.http_request, data.http_response);

  return httpData
    ?.map((item, index) => {
      const [request, response] = item;

      // @ts-ignore
      return ([] as IAccordionData['data']).concat(
        {
          component: 'list' as const,
          title: `Transaction ${index + 1}`,
          id: `transaction_${index + 1}`,
          data: {
            method: {
              label: 'Method',
              value: request?.method ?? '-',
            },
            request: {
              label: 'Request',
              value: request?.request ?? '-',
            },
            url: {
              label: 'URL',
              value: request?.url ?? '-',
            },
            response: {
              label: 'Response',
              value: response?.response ?? '-',
            },
            status: {
              label: 'Status',
              value: response?.status ?? '-',
            },
          },
        },
        index < httpData.length - 1 ? { component: 'divider' } : <></>
      );
    })
    .flat();
};

export const getHttpData = (data: Record<string, any>, provider: SandboxProviders) => {
  const httpData = data?.requests?.filter(
    (item: IRequests) => !isEmpty(item.http_request) || !isEmpty(item.http_response)
  );
  return {
    data: httpData,
    hasPresentValues: !isEmpty(httpData) ?? [],
  };
};

const HttpRequestsTab = ({ data, provider }: { data: IRequests[]; provider: SandboxProviders }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      document.querySelector(location.hash)?.scrollIntoView();
    }
  }, [location.hash]);

  return (
    <div css={style.root}>
      {!isEmpty(data) ? (
        <>
          <ActiveSections
            sections={Array(data.length)
              .fill(1)
              .map((_, index) => {
                const num = index + 1;
                return {
                  label: `${num}`,
                  title: `Transaction ${num}`,
                  active: true,
                };
              })}
          />
          {data.map((item, index) => {
            const data = mapData(item);
            return (
              <AccordionData
                id={`transaction_${index + 1}`}
                key={`transaction_${index + 1}`}
                css={style.item}
                title={`Transaction ${index + 1}`}
                data={data}
                columns={2}
              />
            );
          })}
        </>
      ) : (
        'No HTTP Requests Data'
      )}
    </div>
  );
};

const style = {
  root: css`
    margin: calc(${styles.spacing.grid} / 2) ${styles.spacing.grid};
  `,
  item: css`
    margin-bottom: ${styles.spacing.grid};
    &:last-child {
      margin-bottom: 0;
    }
  `,
};
export default HttpRequestsTab;
