import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import ability from 'config/ability';
import { btnMessages } from 'views/components/Button';
import { combinedUserActions } from 'views/components/providers/UserProvider';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';
import PanelContent from 'views/components/layout/PanelContent';
import HistoricalHuntTab from './HistoricalHuntTab';
import { useUser } from 'views/components/providers/UserProvider';
import AppLoader from 'views/components/request/AppLoader';
import { toggleHuntsType } from 'views/pages/HuntPage/types';

interface IHistoricalHuntTabContainer {
  selectedHunts: string[];
  toggleHunts: toggleHuntsType;
}

const messages = defineMessages({
  heading: {
    id: 'historicalHuntTab.loggedOut.heading',
    defaultMessage: 'Historical Hunting',
  },
});

const HistoricalHuntTabContainer = ({
  toggleHunts,
  selectedHunts,
}: IHistoricalHuntTabContainer) => {
  const intl = useIntl();
  const history = useHistory();
  const { refreshUser } = useUser();

  return (
    <AppLoader loadingActions={combinedUserActions} onReload={refreshUser}>
      {() =>
        ability.cannot('historical_hunt', 'Artifact') ? (
          <PanelContent
            imageComponent={<RulesetImg />}
            heading={intl.formatMessage(messages.heading)}
            text={intl.formatMessage({ id: 'rulesetsTab.loggedOut.text' })}
            buttons={[
              {
                text: intl.formatMessage(btnMessages.upgrade),
                onClick: () => history.push('/pricing'),
              },
            ]}
          />
        ) : (
          <HistoricalHuntTab toggleHunts={toggleHunts} selectedHunts={selectedHunts} />
        )
      }
    </AppLoader>
  );
};

export default HistoricalHuntTabContainer;
