import defaultFont, { fonts as defaultFonts } from 'tenants/font';
import tenant from 'tenants';

// Configurations for the font of the site
const font = {
  ...defaultFont,
  ...(tenant.theme?.font || {}),
};

// Fonts imports
export const fonts = tenant.theme?.fonts || defaultFonts;

export default font;
