import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-sandbox' fill='none' viewBox='0 0 39 28'>
      <path
        d='M35.7871 16.6234H1V27.0005H35.7871V16.6234Z'
        strokeWidth='1.58949'
        strokeMiterlimit='10'
      />
      <path
        d='M1 19.7993H22.9122'
        strokeWidth='1.58949'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M25.2959 19.7993H27.6575'
        strokeWidth='1.58949'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M30.1096 19.7993H35.7863'
        strokeWidth='1.58949'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M2.8385 16.6246C3.8149 16.4203 5.58604 15.6028 7.17553 14.7172C10.1047 13.1051 10.4226 11.5837 13.1475 10.3121C14.7824 9.56277 16.5762 9.19946 18.3701 9.22216C20.5726 9.29028 22.7298 9.92608 24.6145 11.0614C27.0214 12.4466 27.1349 13.4911 29.5646 14.7854C31.2222 15.6709 33.0388 16.443 34.106 16.6246'
        strokeWidth='1.58949'
        strokeMiterlimit='10'
      />
      <path d='M33.0847 6.92682L27.2944 12.7625' strokeWidth='1.58949' strokeMiterlimit='10' />
      <path
        d='M31.7675 3.74844L33.5159 2L37.6031 6.08725L35.8547 7.83569C35.446 8.24441 34.7648 8.24441 34.3333 7.83569L31.7675 5.2698C31.336 4.83837 31.336 4.15716 31.7675 3.74844Z'
        strokeWidth='1.58949'
        strokeMiterlimit='10'
      />
    </symbol>

    <Global
      styles={css`
        .icon-sandbox {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
