import React, { useCallback, RefObject, Fragment, FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { onEnterOrSpaceKeyDown } from 'views/components/utils';

interface Props {
  className?: string;
  id: string;
  tabIndex?: number;
  innerRef?: RefObject<HTMLLabelElement>;
  onSelection: (files: File[]) => void;
  onClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
}

const FileSelect: FC<Props> = ({
  className,
  id,
  tabIndex,
  innerRef,
  onSelection,
  onClick,
  children,
}) => {
  const onDrop = useCallback((files: File[]) => onSelection(files), [onSelection]);
  const { getInputProps, inputRef } = useDropzone({ onDrop });
  const open = () => inputRef.current && inputRef.current.click();

  return (
    <Fragment>
      <input id={id} style={{ display: 'none' }} {...getInputProps()} multiple={false} />
      <label
        className={className}
        htmlFor={id}
        ref={innerRef}
        role='button'
        tabIndex={tabIndex}
        onKeyDown={onEnterOrSpaceKeyDown(open)}
        onClick={onClick}>
        {children}
      </label>
    </Fragment>
  );
};

export default FileSelect;
