import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';

interface ModalContainerProps {
  fullWidth?: DialogProps['fullWidth'];
  maxWidth?: DialogProps['maxWidth'];
  dataCy?: string;
  children: React.ReactNode;
}
const ModalContainer = ({ fullWidth, maxWidth, dataCy, children }: ModalContainerProps) => (
  <Dialog data-cy={dataCy} fullWidth={fullWidth} maxWidth={maxWidth} open>
    {children}
  </Dialog>
);

export default ModalContainer;
