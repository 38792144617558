import { useEffect, useState } from 'react';
import api from 'services/api';

export const useMimetypes = () => {
  const [mimetypes, setMimetypes] = useState<string[]>([]);
  const loading = mimetypes.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await api.getMicroengineMimeTypes();
      if (active) {
        setMimetypes(response.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return {
    loading,
    mimetypes,
  };
};
