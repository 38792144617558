import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import styled from '@emotion/styled';
import { defineMessages, useIntl } from 'react-intl';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Card from 'views/components/layout/Card';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import LineChart from 'views/components/LineChart';
import Loader from 'views/components/Loader';
import ErrorPageContent, {
  messages as errorPageMessages,
} from 'views/components/error/ErrorPageContent';

import { useAnalyticsStats } from '../hooks/';

import Stats from './Stats';
import TopList from './TopList';

type tooltipValue = {
  date: string;
  count: number;
};

type SectionData = {
  label: string;
  loading: boolean;
  historical?: any;
  latest: any;
};

const Wrapper = styled.div`
  padding: 3rem;

  @media (max-width: 1200px) {
    padding: 2rem;
  }
`;

const Blockchain = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3rem;

  @media (max-width: 1200px) {
    grid-column-gap: 2rem;
  }
`;

const ThreatIntelligence = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3rem;

  @media (max-width: 1200px) {
    grid-column-gap: 2rem;
  }
`;

const ErrorWrapper = styled.div`
  padding: 2rem;
`;

const InfoTransaction = styled.div`
  border-radius: 1rem;
  grid-column: span 2;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Transaction = styled.div<{ selectedTheme: 'dark' | 'light' }>`
  width: 100%;
  display: grid;
  grid-template-columns: 60% 1fr;
  justify-content: space-between;
  align-items: center;

  & circle.frame-hover {
    stroke: ${({ selectedTheme }) =>
      selectedTheme === 'dark' ? styles.color.lightBlue : styles.color.purple};
    stroke-width: 2;
    r: 10;
  }
`;

const Info = styled.div`
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-around;
`;

const ContainerInfo: React.FC<{ loading: boolean }> = ({ children, loading }) => {
  const { classes } = useStyles();
  return (
    <Info css={classes.background}>{loading ? <Loader css={classes.loader} /> : children}</Info>
  );
};

const messages = defineMessages({
  transactions: {
    id: 'marketplaceStatsPanel.stats.transactions',
    defaultMessage: 'Transactions',
  },
  ntcFlows: {
    id: 'marketplaceStatsPanel.stats.ntcFlows',
    defaultMessage: 'NCT flows',
  },
  ntcValue: {
    id: 'marketplaceStatsPanel.stats.ntcValue',
    defaultMessage: 'NCT value (in USD)',
  },
  samples: {
    id: 'marketplaceStatsPanel.stats.samples',
    defaultMessage: 'Malicious Samples',
  },
  topMalwarefamilies: {
    id: 'marketplaceStatsPanel.stats.topMalwarefamilies',
    defaultMessage: 'Top 5 Malware Families',
  },
  topMalwareTypes: {
    id: 'marketplaceStatsPanel.stats.topMalwareTypes',
    defaultMessage: 'Top 5 Malware Types',
  },
  topFileTypes: {
    id: 'marketplaceStatsPanel.stats.topFileTypes',
    defaultMessage: 'Top 5 File Types',
  },
  activeEngines: {
    id: 'marketplaceStatsPanel.stats.activeEngines',
    defaultMessage: 'Active Engines',
  },
  frequency: {
    id: 'marketplaceStatsPanel.stats.frequency',
    defaultMessage: '7-day daily average',
  },
  weekFrequency: {
    id: 'marketplaceStatsPanel.stats.weekFrequency',
    defaultMessage: '7-day daily average',
  },
});

const BlockchainSection = ({ label, loading, historical, latest }: SectionData) => {
  const intl = useIntl();
  const { classes, theme } = useStyles();

  const getCompactNumber = (num: number) => {
    const value = intl.formatNumber(num, { notation: 'compact' });
    return value.split(/([0-9.]+)/).filter(Boolean);
  };

  return latest ? (
    <div>
      <Chip size='medium' css={classes.sectionTitle} color='primary' label={label} />
      <Blockchain>
        <InfoTransaction css={classes.background}>
          {loading ? (
            <Loader css={classes.loader} />
          ) : (
            <Transaction selectedTheme={theme}>
              <div>
                <LineChart
                  data={historical}
                  x='day'
                  y='count'
                  Tooltip={({ date, count }: tooltipValue) => {
                    const [digits, unit] = getCompactNumber(count);
                    return (
                      <div css={classes.tooltip}>
                        <div css={classes.tooltipValue}>
                          {digits} {unit}
                        </div>
                        <div css={classes.tooltipDate}>{date}</div>
                      </div>
                    );
                  }}
                />
              </div>
              <Stats
                value={latest?.transactions_7_day_avg}
                helpText='Count of NCT Transactions – bounty creations, assertions, stakes, fees, and rewards'
                title={intl.formatMessage(messages.transactions)}
                subtitle={intl.formatMessage(messages.frequency)}
              />
            </Transaction>
          )}
        </InfoTransaction>
        <ContainerInfo loading={loading} css={classes.background}>
          <Stats
            value={latest?.ntc_flows_7_day_avg}
            title={intl.formatMessage(messages.ntcFlows)}
            subtitle={intl.formatMessage(messages.frequency)}
            helpText='Total value of NCT used in the Transactions'
          />
        </ContainerInfo>
        <ContainerInfo loading={loading} css={classes.background}>
          <Stats
            value={latest?.nct_value}
            prefix='$'
            compact={false}
            title={intl.formatMessage(messages.ntcValue)}
            subtitle={intl.formatMessage(messages.weekFrequency)}
            helpText='Total value, in USD, of NCT used in the Transactions.'
          />
        </ContainerInfo>
      </Blockchain>
    </div>
  ) : null;
};

const ThreatIntelligenceSection = ({ label, loading, latest }: SectionData) => {
  const intl = useIntl();
  const { classes } = useStyles();
  return latest ? (
    <div>
      <Chip size='medium' css={classes.sectionTitle} color='primary' label={label} />
      <ThreatIntelligence>
        <ContainerInfo loading={loading} css={classes.background}>
          <Stats
            value={latest.samples_7_day_avg}
            title={intl.formatMessage(messages.samples)}
            subtitle={intl.formatMessage(messages.frequency)}
          />
        </ContainerInfo>
        <ContainerInfo loading={loading} css={classes.background}>
          <TopList
            title={intl.formatMessage(messages.topMalwarefamilies)}
            list={latest.malware_families}
          />
        </ContainerInfo>
        <ContainerInfo loading={loading} css={classes.background}>
          <TopList title={intl.formatMessage(messages.topFileTypes)} list={latest.file_types} />
        </ContainerInfo>
        <ContainerInfo loading={loading} css={classes.background}>
          <Stats value={latest.active_engines} title={intl.formatMessage(messages.activeEngines)} />
        </ContainerInfo>
      </ThreatIntelligence>
    </div>
  ) : null;
};

const MarketplaceStatsPanel = () => {
  const intl = useIntl();
  const { classes } = useStyles();
  const { latest, historical, loading, error, refetch } = useAnalyticsStats();

  return (
    <Card>
      {error ? (
        <ErrorWrapper>
          <ErrorPageContent
            className='h-mt-lg h-mb-lg'
            heading={intl.formatMessage(errorPageMessages.headingReload)}
            text={intl.formatMessage(errorPageMessages.textReload)}>
            <Button
              style={{ fontSize: '2.4rem' }}
              color='primary'
              variant='contained'
              onClick={refetch}>
              {intl.formatMessage(errorPageMessages.buttonReload)}
            </Button>
          </ErrorPageContent>
        </ErrorWrapper>
      ) : loading ? (
        <Wrapper>
          <div css={classes.section}>
            <h3 className='h3'>Blockchain</h3>
            <Loader css={[classes.loader, classes.center]} />
          </div>
          <div css={[classes.section, classes.sectionTwo]}>
            <h3 className='h3'>Threat Intelligence</h3>
            <Loader css={[classes.loader, classes.center]} />
          </div>
        </Wrapper>
      ) : (
        <Wrapper>
          <div css={classes.section}>
            <h3 className='h3'>Blockchain</h3>
            <BlockchainSection
              loading={loading}
              label='Mainnet'
              historical={historical?.main_net}
              latest={latest?.main_net}
            />
          </div>
          <div css={[classes.section, classes.sectionTwo]}>
            <h3 className='h3'>Threat Intelligence</h3>
            <ThreatIntelligenceSection
              label='Mainnet'
              loading={loading}
              latest={latest?.main_net}
            />
          </div>
        </Wrapper>
      )}
    </Card>
  );
};

const useStyles = makeStyles({
  base: {
    title: {
      fontFamily: styles.font.family.main,
      fontWeight: styles.font.weight.bold,
      fontSize: '2rem',
      marginBottom: '3rem',
    },
    tooltipValue: {
      fontWeight: styles.font.weight.black,
      fontSize: '2.2rem',
    },
    tooltipDate: {
      fontSize: '1.4rem',
    },
    tooltip: {
      padding: '1rem 2rem',
      lineHeight: 'normal',
      whiteSpace: 'nowrap',
      borderRadius: '.5rem',
      textAlign: 'center',
      fontFamily: styles.font.family.main,
    },
    loader: {
      fontSize: '0.6rem !important',
      padding: '6rem 2rem',
    },
    center: {
      textAlign: 'center',
    },
    section: {
      borderRadius: '1rem',
      padding: '2rem',
      marginBottom: '2rem',
      display: 'grid',
      gridRowGap: '3rem',
    },
    sectionTwo: {
      marginBottom: '0',
    },
    sectionTitle: {
      marginBottom: '1rem',
    },
    background: {
      position: 'relative',
    },
  },
  light: {
    title: {
      color: styles.color.black,
    },
    tooltipValue: {
      color: styles.color.purple,
    },
    tooltip: {
      backgroundColor: styles.color.ulPurple,
      border: `1px solid ${styles.color.xxxLightGrey}`,
      color: styles.color.black,
    },
    background: {
      backgroundColor: styles.color.ulGrey,
    },
    section: {
      border: `2px solid ${styles.color.xxxLightPurple}`,
    },
  },
  dark: {
    title: {
      color: styles.color.white,
    },
    tooltipValue: {
      color: styles.color.lightBlue,
    },
    tooltip: {
      backgroundColor: styles.color.darkBlack,
      border: `1px solid ${styles.color.darkOffBlack}`,
      color: styles.color.white,
    },
    background: {
      backgroundColor: styles.color.xxDarkPurple,
    },
    section: {
      border: `2px solid ${styles.color.xxDarkPurple}`,
    },
  },
});

export default MarketplaceStatsPanel;
