import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { combinedUserActions } from 'views/components/providers/UserProvider';
import { useUser } from 'views/components/providers/UserProvider';
import SEO from 'views/components/layout/SEO';
import AppLoader from 'views/components/request/AppLoader';
import BaseLayout from 'views/components/layout/BaseLayout';
import PageSidebarLayout from 'views/components/layout/PageSidebarLayout';
import { AccountPlan } from 'views/components/account/AccountPlan';
import AccountSidebar from './AccountSidebar';
import AccountPanel from './AccountPanel';

const messages = defineMessages({
  title: {
    id: 'accountPage.title',
    defaultMessage: 'Account',
  },
});

const AccountPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const user = useUser();

  const { account } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    user.refreshUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleUpgrade = () => {
    history.push('/pricing');
  };

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <AppLoader loadingActions={combinedUserActions} onReload={user.refreshUser}>
          {user && account && (
            <PageSidebarLayout
              renderSidebar={() => (
                <Fragment>
                  <AccountSidebar className='h-mb-grid' user={user} />
                  <AccountPlan
                    accountPlanName={account.plan.name}
                    usagePeriodStart={account.windowStart}
                    usagePeriodEnd={account.windowEnd}
                    subscriptionStart={account.planPeriodStart}
                    subscriptionEnd={account.planPeriodEnd}
                    onClick={_handleUpgrade}
                  />
                </Fragment>
              )}
              renderPanel={() => <AccountPanel />}
            />
          )}
        </AppLoader>
      </BaseLayout>
    </Fragment>
  );
};

export default AccountPage;
