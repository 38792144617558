import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Downshift, { StateChangeOptions } from 'downshift';
import matchSorter from 'match-sorter';
import { btnMessages } from 'views/components/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import { Tooltip } from '@material-ui/core';
import Icon from 'views/components/Icon';

interface ISearchField {
  label: string;
  isLoading: boolean;
  initialValue?: string;
  autocompleteItems?: AutocompleteItem[];
  autocompleEnabled?: boolean;
  dataCy?: string;
  onRemoveAutocompleteItem?: (item: string) => void;
  onSubmit: (value: string) => void;
}

export type AutocompleteItem = { value: string };

const StyledMenuItem = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '25px',
  },
}))(MenuItem);

/**
 * Return a subset of autocomplete items by keyword
 */
const getAutocompleteItems = (keyword: string | null, items: AutocompleteItem[]) =>
  keyword ? matchSorter(items, keyword, { keys: ['value'] }) : items;

/**
 * Return a subset of strings from autocomplete items by keyword
 */
const getAutocompleteStrings = (filter: string | null, items: AutocompleteItem[]) =>
  getAutocompleteItems(filter, items).map(({ value }) => value);

/**
 * The SearchField Component
 */
const SearchField = ({
  label,
  isLoading,
  initialValue,
  autocompleteItems,
  onSubmit,
  onRemoveAutocompleteItem,
  autocompleEnabled = true,
  dataCy,
}: ISearchField) => {
  const [value, setValue] = useState(initialValue || '');

  const intl = useIntl();

  const { classes } = useStyles(isLoading);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  const _handleStateChange = ({ selectedItem, inputValue }: StateChangeOptions<string>) => {
    if (selectedItem) {
      setValue(selectedItem);
    } else if (inputValue) {
      setValue(inputValue);
    }
  };

  const _handleInputChange = (e: any) => setValue(e.currentTarget.value);

  const _handleSearch = (e: any) => {
    e.preventDefault();
    if (!value) {
      inputRef.current && inputRef.current.focus();
    } else if (onSubmit) {
      onSubmit(value);
    }
  };

  return (
    <form css={classes.form} onSubmit={_handleSearch}>
      <Downshift selectedItem={value} onStateChange={_handleStateChange}>
        {({ getInputProps, getMenuProps, getItemProps, isOpen, inputValue, highlightedIndex }) => {
          const autocomplete = getAutocompleteStrings(inputValue, autocompleteItems || []);
          const emptyAutocompleteItem = '';
          const autocompleteList = autocomplete.length ? autocomplete : [emptyAutocompleteItem];
          return (
            <div>
              <TextField
                {...(getInputProps({ onChange: _handleInputChange }) as TextFieldProps)}
                data-cy={dataCy}
                inputRef={inputRef}
                label={label}
                fullWidth={true}
                autoFocus={true}
                disabled={isLoading}
                multiline
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    _handleSearch(e);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <button
                        css={classes.searchBtn}
                        type='button'
                        onClick={_handleSearch}
                        disabled={isLoading}>
                        <Icon name='search' title={intl.formatMessage(btnMessages.search)} />
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              {autocompleEnabled ? (
                <Card css={classes.card} {...getMenuProps()}>
                  {isOpen &&
                    autocompleteList.map((item, index) => (
                      <StyledMenuItem
                        {...getItemProps({ item, index, key: item })}
                        selected={highlightedIndex === index}>
                        <Tooltip title={item} placement='top'>
                          <div css={classes.elipsis}>{item}</div>
                        </Tooltip>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            onRemoveAutocompleteItem && onRemoveAutocompleteItem(item);
                          }}>
                          {item !== emptyAutocompleteItem && (
                            <Icon css={classes.icon} name='close' />
                          )}
                        </div>
                      </StyledMenuItem>
                    ))}
                </Card>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    </form>
  );
};

const useStyles = makeStyles((isLoading: boolean) => ({
  base: {
    form: {
      width: '100%',
    },
    searchBtn: {
      cursor: isLoading ? 'not-allowed' : 'pointer',
      fontSize: '0.8rem',
    },
    loader: {
      fontSize: '0.16rem !important',
    },
    icon: {
      fontSize: '0.5rem',
      marginLeft: '1rem',
    },
    elipsis: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  light: {
    searchBtn: {
      color: isLoading ? styles.color.xLightGrey : styles.color.purple,
    },
    card: {
      '& li:nth-of-type(odd)': {
        backgroundColor: styles.color.xxxLightGrey,
      },
    },
  },
  dark: {
    searchBtn: {
      color: isLoading ? styles.color.xLightGrey : styles.color.lightBlue,
    },
    card: {
      '& li:nth-of-type(odd)': {
        backgroundColor: styles.color.darkBlack,
      },
    },
  },
}));

export default SearchField;
