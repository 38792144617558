import React, { Fragment } from 'react';
import ErrorPageContent, {
  messages as errorMessages,
} from 'views/components/error/ErrorPageContent';
import { useIntl, defineMessages } from 'react-intl';
import Button from '@material-ui/core/Button';

const messages = defineMessages({
  text: {
    id: 'metadataSearch.usageLimit.text',
    defaultMessage:
      'Account api-request limit reached for feature Monthly Metadata Search Allowance period.',
  },
  note: {
    id: 'metadataSearch.usageLimit.note',
    defaultMessage:
      'To obtain more usage, you can wait until the next usage period or you can upgrade your plan period',
  },
  currentUsageBtn: {
    id: 'metadataSearch.usageLimit.currentUsageBtn',
    defaultMessage: 'See your current usage',
  },
  upgradePlanBtn: {
    id: 'metadataSearch.usageLimit.upgradePlanBtn',
    defaultMessage: 'Upgrade your plan',
  },
});

export const UsageLimitError = () => {
  const intl = useIntl();

  return (
    <ErrorPageContent
      className='h-mt-lg h-mb-md'
      heading={intl.formatMessage(errorMessages.heading429)}
      text={
        <Fragment>
          <p className='p h-mb-xxs'>{intl.formatMessage(messages.text)}</p>
          <p className='p h-mb-xxs'>{intl.formatMessage(messages.note)}</p>
        </Fragment>
      }
    >
      <Button
        style={{ fontSize: '2rem', marginRight: '1rem' }}
        color='primary'
        variant='contained'
        href='/account/usage'
      >
        {intl.formatMessage(messages.currentUsageBtn)}
      </Button>
      <Button
        style={{ fontSize: '2rem', marginLeft: '1rem' }}
        color='primary'
        variant='contained'
        href='/account/my-plan'
      >
        {intl.formatMessage(messages.upgradePlanBtn)}
      </Button>
    </ErrorPageContent>
  );
};
