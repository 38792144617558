import React from 'react';
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { makeStyles as makeMuiStyles } from '@material-ui/core';

interface IPolyModal {
  maxWidth?: DialogProps['maxWidth'];
  title?: string;
  children: React.ReactNode;
  open?: boolean;
  submitText?: string;
  onSubmit?: () => void;
  cancelText?: string;
  onCancel?: () => void;
}
const PolyModal = ({
  submitText = 'Submit',
  onSubmit,
  onCancel,
  cancelText = 'Cancel',
  open = false,
  title,
  maxWidth = 'sm',
  children,
}: IPolyModal) => {
  const { classes } = useStyles();
  const dialogStyles = useDialogStyles();

  return (
    <Dialog classes={{ paper: dialogStyles.container }} fullWidth maxWidth={maxWidth} open={open}>
      {!!title && (
        <DialogTitle css={classes.title} disableTypography={true}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions classes={{ root: dialogStyles.buttons }}>
        <Button color='primary' variant='outlined' data-testid='cancelBtn' onClick={onCancel}>
          {cancelText}
        </Button>
        <Button
          type='button'
          color='primary'
          variant='contained'
          data-testid='submitBtn'
          onClick={onSubmit}
        >
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useDialogStyles = makeMuiStyles({
  container: {
    padding: '3rem',
  },
  buttons: {
    justifyContent: 'center',
  },
});

const useStyles = makeStyles({
  base: {
    title: {
      textAlign: 'center',
      fontSize: '3rem',
      fontWeight: 500,
    },
  },
  light: {
    title: {
      color: styles.color.purple,
    },
  },
  dark: {
    title: {
      color: styles.color.blue,
    },
  },
});
export default PolyModal;
