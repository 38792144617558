import color from './color';

const shadow = {
  default: '0 8px 24px rgba(0, 0, 0, 0.12)',
  medium: '0 8px 27px rgba(0, 0, 0, 0.25)',
  large: '0 8px 30px rgba(0, 0, 0, 0.50)',
  green: `0.6rem 0 0 ${color.green}, -0.6rem 0 0 ${color.green}`,
  xDarkPurple: `0.6rem 0 0 ${color.xDarkPurple}, -0.6rem 0 0 ${color.xDarkPurple}`,
};

export default shadow;
