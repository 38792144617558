import React from 'react';
import styled from '@emotion/styled';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Icon from 'views/components/Icon';
import styles from 'views/styles';

interface IInfoTooltip extends Omit<TooltipProps, 'children'> {}

const TooltipBtn = styled.button`
  cursor: help;
  &:focus {
    outline: 1px dotted ${styles.color.lightGrey};
  }
`;

export const InfoTooltip = ({ className, ...rest }: IInfoTooltip) => (
  <Tooltip {...rest}>
    <TooltipBtn>
      <Icon name='info' className={className} />
    </TooltipBtn>
  </Tooltip>
);
