import { saveAs } from 'file-saver';
import { union, keys, flatMap, isObject } from 'lodash';
import styles from 'views/styles';

export interface IFilter {
  key?: string;
  value?: string;
}

export interface IConfig {
  mode: 'includes' | 'exact';
}

export interface IMatches {
  isDarkTheme: boolean;
  currentMatch: number | null;
  nextMatch: (matches: NodeListOf<Element>) => number;
  setCurrentMatch: (newMatch: number) => void;
  isFirst?: boolean;
}

export const downloadJSON = ({ name, json }: { name: string; json: string }) =>
  saveAs(new Blob([json], { type: 'text/plain;charset=utf-8' }), `${name}.json`);

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${result
        .map((item) => parseInt(item, 16))
        .slice(1)
        .join(', ')})`
    : '';
};

export const scrollToTargetAdjusted = (
  elem: HTMLElement,
  { offset }: { offset: number } = { offset: 50 }
) => {
  const jsonViewer = document.querySelector('.ps-json-view');
  const element = elem.closest('.object-key-val') as HTMLElement;
  const elementPosition = element?.offsetTop || 0 - offset;

  if (typeof jsonViewer?.scrollTo === 'function') {
    jsonViewer?.scrollTo({
      top: elementPosition,
      behavior: 'smooth',
    });
  }
};

const exists = (elem: any) => elem !== undefined;
export const search = (config: IConfig, key?: any, value?: any): boolean => {
  if (!key || !value) return false;
  const current = key.toString().toLowerCase().trim();
  const target = value.toString().toLowerCase().trim();
  return config.mode === 'includes' ? current.includes(target) : current === target;
};

export const getAllKeys: any = (obj: any) =>
  union(
    keys(obj),
    flatMap(obj, (o: any) => (isObject(o) ? getAllKeys(o) : []))
  );

export const filterData = (
  obj: any,
  filter: IFilter,
  matches: any,
  config: IConfig,
  parent = false
): any => {
  if (!obj) return {};
  const filteredData = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      const getFilteredData = (filterBy: any, parentValue: boolean) =>
        filterData(filterBy, filter, matches, config, parentValue);
      const hasFilteredData = (filterBy: any, parentValue: boolean) => {
        const filteredData = getFilteredData(filterBy, parentValue);
        return [Object.keys(filteredData).length > 0, filteredData];
      };
      const getKeyObjectPair = (key: string, data: any) => [
        key,
        Object.fromEntries(Object.entries(data).filter(([key, value]) => key && value)),
      ];
      const checkKeyObjectPair = (value: any, parentValue: boolean = parent) => {
        if (typeof value === 'object') {
          const [has, data] = hasFilteredData(value, parentValue);
          if (has) {
            const [k, v] = getKeyObjectPair(key, data);
            return [k, v];
          }
        }
        return false;
      };

      const matcher = () => {
        const matchKey = search(config, key, filter.key);
        const matchValue = search(config, value, filter.value);
        let isParent = false;

        if (filter.key && filter.value) {
          if (matchKey && matchValue) {
            matches.matchesBoth++;
            return [key, value];
          }
        } else if (matchKey) {
          matches.matches++;
          const isNotObject = typeof value !== 'object';
          const isEmptyArray = Array.isArray(value) && !value.length;
          const isEmptyObject =
            value && typeof value === 'object' && Object.keys(value).length === 0;

          if (isNotObject || isEmptyArray || isEmptyObject) return [key, value];
          else isParent = true;
        } else if (matchValue) {
          matches.matches++;
          return [key, value];
        } else if (filter.value && Array.isArray(value)) {
          const values = value.filter((item) => {
            return checkKeyObjectPair(item);
            // if we want filter match with item list in the future
            // return filter.value && item.toString() === filter.value;
          });
          if (values.length > 0) {
            return [key, values];
          }
        }
        const checkedKeyObjectPair = checkKeyObjectPair(value, isParent);
        if (checkedKeyObjectPair) return checkedKeyObjectPair;
        if (parent) return [key, value];
        return [];
      };

      const match = matcher();
      return match.length ? match : [];
    })
  );
  return Object.fromEntries(
    Object.entries(filteredData).filter(([key, value]) => exists(key) && exists(value))
  );
};

const HIGHLIGHT_COLOR = hexToRgb(styles.color.medGreen);
const getPrettyJsonContainer = (color: string) =>
  document.querySelectorAll(`.pretty-json-container span[style*="background-color: ${color}"]`);
export const getHighlighted = () => getPrettyJsonContainer(HIGHLIGHT_COLOR);

export const deletePreviousMatch = (isDarkTheme: boolean) => {
  const previousMatch = Array.from(getHighlighted()) as HTMLSpanElement[];
  if (previousMatch.length) {
    previousMatch.forEach((element) => {
      element.style.backgroundColor = isDarkTheme ? styles.color.blue : styles.color.lightPurple;
    });
  }
};

export const highlightMatch = ({
  isDarkTheme,
  currentMatch,
  nextMatch,
  setCurrentMatch,
  isFirst = false,
}: IMatches) => {
  const matches = getPrettyJsonContainer(
    isDarkTheme ? hexToRgb(styles.color.blue) : hexToRgb(styles.color.lightPurple)
  );
  if (isFirst || (matches.length && currentMatch)) {
    const newMatch = typeof nextMatch === 'function' ? nextMatch(matches) : nextMatch;
    setCurrentMatch(newMatch);
    const match = matches[newMatch - 1] as HTMLSpanElement;
    if (match) {
      match.style.backgroundColor = HIGHLIGHT_COLOR;
      scrollToTargetAdjusted(match);
    }
  }
};
