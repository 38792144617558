import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-arrow-full' viewBox='0 0 8 12'>
      <path d='M4 11.3c-.5 0-.9-.3-.9-.7V1.9c0-.5.4-.8.9-.8s.9.4.9.8v8.7c0 .4-.4.7-.9.7z' />
      <path d='M7 4.9c-.3 0-.6-.2-.9-.5-.5-.5-.9-1-1.4-1.4-.2-.3-.5-.5-.7-.8L1.6 4.6c-.3.3-.9.3-1.2 0-.3-.3-.3-.9 0-1.2l3-3c.3-.3.9-.3 1.2 0l3 3c.3.3.3.9 0 1.2-.2.2-.4.3-.6.3z' />
    </symbol>

    <Global
      styles={css`
        .icon-arrow-full {
          width: 2.1em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
