import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';
import { useTheme } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

export default () => {
  const theme = useTheme();
  const isDarkMode = theme === 'dark';
  const glassesColor = isDarkMode ? styles.color.lightBlue : styles.color.purple;
  const hatLineColor = isDarkMode ? 'none' : styles.color.purple;
  const hatColor = isDarkMode ? styles.color.lightBlue : styles.color.xxxDarkPurple;

  return (
    <Fragment>
      <symbol id='icon-community_mode' viewBox='0 0 32 32'>
        <path
          d='M13.9936 23.5856C13.9936 25.3556 12.4436 26.7856 10.5236 26.7856C8.60359 26.7856 7.05359 25.3556 7.05359 23.5856C7.05359 23.1556 7.14359 22.7456 7.31359 22.3656'
          fill='none'
          stroke={glassesColor}
          strokeWidth={1.2}
          strokeMiterlimit='10'
          shapeRendering='geometricPrecision'
        />
        <path
          d='M25.0736 22.6156C25.1836 22.9256 25.2336 23.2456 25.2336 23.5856C25.2336 25.3556 23.6836 26.7856 21.7636 26.7856C19.8436 26.7856 18.2936 25.3556 18.2936 23.5856'
          fill='none'
          stroke={glassesColor}
          strokeWidth={1.2}
          strokeMiterlimit='10'
          shapeRendering='geometricPrecision'
        />
        <path
          d='M13.9436 24.1656C15.3736 23.8556 16.8036 23.8556 18.2436 24.1956'
          stroke={glassesColor}
          strokeWidth={1}
          strokeMiterlimit='10'
          shapeRendering='geometricPrecision'
        />
        <path
          d='M16.1536 17.4556C12.8536 17.3156 9.44357 16.6656 7.03357 15.4356C7.73357 15.3656 7.80357 14.0856 8.02357 12.6256C10.3636 13.7856 12.9836 14.2656 16.1536 14.3956C19.3236 14.2556 21.9536 13.7856 24.2836 12.6256C24.4936 14.0856 24.5736 15.3656 25.2736 15.4356C22.8636 16.6756 19.4536 17.3256 16.1536 17.4556Z'
          fill={hatLineColor}
          stroke={hatColor}
          strokeWidth={1}
          strokeLinejoin='round'
          shapeRendering='geometricPrecision'
        />
        <path
          d='M16.1536 14.3957C12.9836 14.2557 10.3536 13.7857 8.02356 12.6257C8.09356 12.0357 8.21356 11.4257 8.38356 10.8557C9.10356 8.54566 9.94356 6.20566 10.3536 6.04566C11.1636 5.73566 14.5636 7.10566 15.1236 7.10566C15.4936 7.10566 15.9136 7.13566 16.1436 7.14566C16.3836 7.13566 16.7936 7.10566 17.1636 7.10566C17.7236 7.10566 21.1236 5.73566 21.9336 6.04566C22.3436 6.20566 23.1836 8.54566 23.9036 10.8557C24.0736 11.4257 24.1936 12.0457 24.2636 12.6257C21.9236 13.7857 19.3036 14.2657 16.1336 14.3957H16.1536Z'
          fill={hatColor}
          stroke={hatColor}
          strokeWidth={1}
          strokeMiterlimit='10'
          shapeRendering='geometricPrecision'
        />
        <path
          d='M7.0336 15.4257C9.44359 16.6657 12.8536 17.3157 16.1536 17.4457C19.4536 17.3057 22.8636 16.6557 25.2736 15.4257C25.7236 15.4757 33.6136 17.4457 28.7036 20.5457C24.9136 22.9357 22.9036 23.2457 16.1536 23.2457C9.40359 23.2457 7.39359 22.9357 3.6036 20.5457C-1.3064 17.4457 6.5836 15.4757 7.0336 15.4257Z'
          fill={hatColor}
          stroke={hatColor}
          strokeWidth={1}
          strokeMiterlimit='10'
          shapeRendering='geometricPrecision'
        />
        <path
          d='M8.01365 12.6156C7.91365 12.5756 7.82365 12.5456 7.74365 12.4956'
          stroke={hatColor}
          strokeWidth={1}
          strokeMiterlimit='10'
          shapeRendering='geometricPrecision'
        />
        <path
          d='M24.2836 12.6156C24.3836 12.5756 24.4736 12.5456 24.5536 12.4956'
          stroke={hatColor}
          strokeWidth={1}
          strokeMiterlimit='10'
          shapeRendering='geometricPrecision'
        />
        <path
          d='M24.5636 12.4956C24.4736 12.5456 24.3936 12.5856 24.2936 12.6156'
          stroke={hatColor}
          strokeWidth={1}
          strokeMiterlimit='10'
          shapeRendering='geometricPrecision'
        />
      </symbol>

      <Global
        styles={css`
          .icon-community_mode {
            width: 3.2em;
            height: 3.2em;
          }
        `}
      />
    </Fragment>
  );
};
