import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl, FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { TooltipTableCell } from 'views/components/table/InfiniteScrollTable';
import { TeamMember, ETeamRole, TeamRole } from 'models/Team';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useUser } from 'views/components/providers/UserProvider';
import styles from 'views/styles';
import { Page } from 'models/Page';
import { TableCell } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import HoverableTableRow from 'views/components/table/HoverableTableRow';
import { btnMessages } from 'views/components/Button';
import ActionBtn from 'views/components/table/ActionBtn';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { FULL_DATE_FORMAT } from 'utils/constants';
import Can from 'views/components/auth/Can';

interface ITeamMembersRow {
  teamAccountNumber: number | string;
  teamMembers: Page<TeamMember>;
  rowData: TeamMember;
  onLeaveTeam: () => void;
  onUpdateTeamMember: (
    teamAccountNumber: number | string,
    accountNumber: number,
    name: string,
    roles: TeamRole[]
  ) => void;
  onArchiveTeamMember: (accountNumber: number, name: string) => void;
}

const MoreRoles = styled.span`
  color: ${styles.color.purple};
  font-size: 1.4rem;
  margin-left: 10px;
`;

const translateTeamRoles = (roles: ETeamRole[], formatMessage: Function) => {
  if (!roles || roles.length === 0) {
    return [formatMessage({ id: 'team.role.teamMember' })];
  }
  // Prioritize owner role and display it first if it exists
  const isOwner = roles.includes(ETeamRole.teamOwner);
  // Prioritize admin role and display it second if it exists
  const isAdmin = roles.includes(ETeamRole.teamAdmin);
  const displayedRoles =
    isOwner || isAdmin
      ? [
          isOwner && ETeamRole.teamOwner,
          isAdmin && ETeamRole.teamAdmin,
          ...roles.filter((role) => role !== ETeamRole.teamAdmin && role !== ETeamRole.teamOwner),
        ].filter(Boolean)
      : roles;
  return displayedRoles.map((role) => formatMessage({ id: `team.role.${role}` }));
};

const TeamMembersRow = ({
  teamAccountNumber,
  rowData,
  teamMembers,
  onLeaveTeam,
  onUpdateTeamMember,
  onArchiveTeamMember,
}: ITeamMembersRow) => {
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const user = useUser();

  const roles = translateTeamRoles(rowData.roles as ETeamRole[], formatMessage);

  return (
    <HoverableTableRow
      dataCy='teamMembersRow'
      renderCells={() => (
        <Fragment>
          <TableCell width={200}>
            <div css={classes.cellContainer}>
              <span>{rowData.name}</span>
              {rowData.mfaEnabled && (
                <div>
                  <Tooltip title='2FA Enabled' placement='top'>
                    <div css={classes.iconContainer}>
                      <Icon css={classes.icon} name='check' />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </TableCell>
          <TableCell width={250}>{rowData.email}</TableCell>
          <TableCell width={200}>
            <TooltipTableCell hints={roles.slice(1)}>
              {roles[0]}
              {roles.length > 1 && (
                <MoreRoles>
                  <FormattedMessage
                    id='team.moreRoles'
                    defaultMessage='+{numRoles} roles'
                    values={{ numRoles: roles.length - 1 }}
                  />
                </MoreRoles>
              )}
            </TooltipTableCell>
          </TableCell>
          <TableCell>
            {rowData.joinedAt ? formatDate(parseDate(rowData.joinedAt), FULL_DATE_FORMAT) : ''}
          </TableCell>
          <TableCell>
            {rowData.accountNumber === user.accountNumber && teamMembers.results.length > 1 && (
              <ActionBtn
                name='delete'
                data-testid='leaveTeamBtn'
                aria-label={formatMessage(btnMessages.edit)}
                onClick={() => onLeaveTeam()}
              />
            )}
            <Can I='manage' a='Team'>
              {rowData.accountNumber !== user.accountNumber && (
                <Fragment>
                  <ActionBtn
                    name='edit'
                    style={{ marginRight: 8 }}
                    title={formatMessage(btnMessages.edit)}
                    aria-label={formatMessage(btnMessages.edit)}
                    onClick={() =>
                      onUpdateTeamMember(
                        teamAccountNumber,
                        rowData.accountNumber,
                        rowData.name,
                        rowData.roles
                      )
                    }
                  />
                  <ActionBtn
                    name='delete'
                    data-cy='deleteTeamMemberBtn'
                    title={formatMessage(btnMessages.delete)}
                    aria-label={formatMessage(btnMessages.delete)}
                    onClick={() => onArchiveTeamMember(rowData.accountNumber, rowData.name)}
                  />
                </Fragment>
              )}
            </Can>
          </TableCell>
        </Fragment>
      )}
    />
  );
};

const useStyles = makeStyles({
  base: {
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    iconContainer: {
      height: '2.5rem',
      width: '2.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      padding: '0.6rem',
    },
    icon: {
      fontSize: '0.4rem',
    },
  },
  light: {
    iconContainer: {
      backgroundColor: styles.color.green,
      border: `2px solid ${styles.color.purple}`,
    },
    icon: {
      color: styles.color.purple,
    },
  },
  dark: {
    iconContainer: {
      backgroundColor: styles.color.green,
      border: `2px solid ${styles.color.lightBlue}`,
    },
    icon: {
      color: styles.color.lightBlue,
    },
  },
});

export default TeamMembersRow;
