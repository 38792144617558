import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Accordion from '../Accordion';
import Table from './components';
import Divider from '@material-ui/core/Divider';
import styled from '@emotion/styled';
import { Toggle } from 'views/components/Toggle';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { generalMessages } from '../../../index';

type Data = Record<string, string | string | React.ReactNode>;
export interface IAccordionData {
  id?: string;
  className?: string;
  title: string;
  data?:
    | Data
    | Record<string, string>
    | {
        id?: string;
        title?: string;
        data?: Data | Data[];
        component: 'list' | 'table' | 'divider';
      }[];
  helpText?: string;
  columns?: number;
  openByDefault?: boolean;
  dataCy?: string;
}

const StyledDivider = styled(Divider)`
  margin: 3rem 0 !important;
`;

/**
 * Get translation string from booleans
 */
export const formatAccordionValue = (value: any, intl: IntlShape) => {
  if (typeof value === 'boolean') {
    return value
      ? intl.formatMessage(generalMessages.true)
      : intl.formatMessage(generalMessages.false);
  } else if (typeof value === 'string') {
    return value;
  }
};

const List = ({
  id,
  title,
  data,
  columns,
}: {
  id?: string;
  title: string;
  data: any;
  columns: number;
}) => {
  const intl = useIntl();
  const { classes } = useStyles({ columns });
  const { active, isPivotable } = usePivoting();

  if (
    !data ||
    !Object.values(data).some((value) => {
      if (Array.isArray(value)) return value.length > 0;
      return !isEmpty(value);
    })
  )
    return null;

  return (
    <ul css={classes.content}>
      {Object.entries(data).map(([key, _value]) => {
        let component;
        let label = key;
        let value = _value;
        if (value && typeof value === 'object' && 'label' in value && 'value' in value) {
          const _value: { label: string; value: any } = value as any;
          label = _value.label;
          value = _value.value;
        }
        if (!value || (Array.isArray(value) && value.length === 0)) return null;

        const keyId = id ? `${id}.${key}` : undefined;

        if (Array.isArray(value)) {
          component = value.map((item, index) => {
            if (typeof item === 'object') {
              return (
                <Toggle
                  key={`${keyId}-${index}`}
                  id={keyId}
                  section={title}
                  itemKey={key}
                  itemValue={item?.value}
                  itemActualKey={item?.title?.replaceAll(/\[[0-9]\]/g, '')}
                  tooltipTitle={item?.title}
                >
                  {formatAccordionValue(item?.value, intl)}
                </Toggle>
              );
            }
            return (
              <Toggle id={keyId} section={title} itemKey={key} itemValue={item}>
                {formatAccordionValue(item, intl)}
              </Toggle>
            );
          });
        } else {
          component = (
            <Toggle id={keyId} section={title} itemKey={key} itemValue={`${value}` as string}>
              {formatAccordionValue(`${value}`, intl)}
            </Toggle>
          );
        }
        return (
          <div key={key} css={classes.itemContainer}>
            <h4
              css={[
                classes.itemHeading,
                active && classes.mleft,
                active &&
                  isPivotable({ id: keyId, section: title, item: key }) &&
                  classes.headingHighlighted,
              ]}
            >
              {label}
            </h4>
            <li key={key} css={classes.item}>
              {component}
            </li>
          </div>
        );
      })}
    </ul>
  );
};

/**
 * The AccordionData Component
 */
const AccordionData = (props: IAccordionData) => {
  const { className, title, data = [], helpText, id, openByDefault, dataCy } = props;
  const { classes } = useStyles(props);

  if (isEmpty(data)) {
    return null;
  }

  return (
    <Accordion
      className={className}
      title={title}
      helpText={helpText}
      openByDefault={openByDefault || true}
      dataCy={dataCy}
    >
      <div css={classes.contentWrap}>
        <div css={classes.contentInner}>
          {(Array.isArray(data) && data.length <= 0) || Object.keys(data).length <= 0 ? (
            <p css={classes.noneFound}>
              <FormattedMessage {...generalMessages.noData} />
            </p>
          ) : Array.isArray(data) ? (
            data.map((section) => {
              if (!section.data && section.component !== 'divider') return null;
              switch (section.component) {
                case 'list':
                  return (
                    <List
                      key={section.id}
                      id={section.id || id}
                      title={section.title ?? title}
                      data={section.data}
                      columns={props.columns || 1}
                    />
                  );
                case 'table':
                  return (
                    <div key={section.id || id}>
                      {section.title && <h4 css={[classes.itemHeading]}>{section.title}</h4>}
                      <Table section={title} id={section.id} data={section.data} />
                    </div>
                  );
                case 'divider':
                  return <StyledDivider />;
                default:
                  return null;
              }
            })
          ) : data ? (
            <List id={id} title={title} data={data} columns={props.columns || 1} />
          ) : null}
        </div>
      </div>
    </Accordion>
  );
};

const useStyles = makeStyles(({ columns }: IAccordionData) => ({
  base: {
    contentWrap: {
      position: 'relative',
      overflow: 'auto',
    },
    contentInner: {
      width: '90rem',
    },
    noneFound: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: styles.font.weight.medium,
      paddingBottom: styles.spacing.grid,
      fontSize: styles.font.size.p1,
    },
    content: {
      position: 'relative',
      [`@media (min-width: ${styles.breakpoint.sm})`]: {
        columns,
      },
    },
    itemHeading: {
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.medium,
      marginBottom: '0.3rem',
      marginLeft: '1rem',
    },
    itemContainer: {
      breakInside: 'avoid',
      paddingBottom: '2rem',
    },
    item: {
      breakInside: 'avoid',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      marginBottom: styles.spacing.xxs,
      '&:last-child': {
        marginBottom: 0,
      },
      maxHeight: '40rem',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '0.5rem',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '0.4rem',
      },
    },
    itemContent: {
      fontSize: styles.font.size.p1,
      margin: `${styles.spacing.tiny} 0`,
      maxWidth: '40rem',
    },
    mleft: {
      marginLeft: '3.9rem',
    },
  },
  light: {
    noneFound: {
      color: styles.color.xLightGrey,
    },
    content: {
      background: styles.color.white,
    },
    itemHeading: {
      color: styles.color.xLightGrey,
    },
    item: {
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, .5)',
      },
    },
    headingHighlighted: {
      color: styles.color.purple,
    },
  },
  dark: {
    noneFound: {
      color: styles.color.xLightGrey,
    },
    content: {
      background: styles.color.darkXBlack,
    },
    itemHeading: {
      color: styles.color.xxxLightGrey,
    },
    item: {
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, .5)',
      },
    },
    headingHighlighted: {
      color: styles.color.lightBlue,
    },
  },
}));

export default AccordionData;
