import React, { useState, useEffect, ReactNode } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import Tab from '@material-ui/core/Tab';
import Tabs, { TabsProps as MuiTabsProps } from '@material-ui/core/Tabs';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { Tooltip } from '@material-ui/core';

export type TabItem = {
  value: string;
  label: string;
  dataTestId?: string;
  disabled?: boolean;
  disabledText?: string;
  active?: boolean;
  className?: string;
  hidden?: boolean;
};

interface PaginatedTabsProps extends MuiTabsProps {
  tabs: TabItem[];
  children?: ReactNode;
  maxTabs?: number;
  minTabs?: number;
}

const StyledTabs = styled(Tabs)<{ tabWidth: number }>`
  flex: 1;

  .MuiTab-root {
    width: ${({ tabWidth }: { tabWidth: number }) => `${tabWidth}px !important`};
    margin-right: 0rem;
  }
`;

const PaginatedTabs = ({ tabs, children, maxTabs, minTabs = 4, ...params }: PaginatedTabsProps) => {
  const tabsRef = React.createRef<HTMLButtonElement>();
  const { theme, classes } = useStyles();
  const [tabWidth, setTabWidth] = useState(0);
  const filteredTabs = tabs.filter((tab) => !tab.hidden);
  const [visibleTabs, setVisibleTabs] = useState(maxTabs || filteredTabs.length);
  const activeIndex = filteredTabs.findIndex((tab) => tab.value === params.value);
  const [arrowDisabled, setArrowDisabled] = useState<{ left: boolean; right: boolean }>({
    left: activeIndex < minTabs,
    right: activeIndex === filteredTabs.length - 1,
  });

  useEffect(() => {
    let handleResize: () => void;

    if (tabsRef.current) {
      const handleTabResize = () => {
        const containerTabWidth = tabsRef.current?.querySelector('div')?.clientWidth!;

        if (containerTabWidth < 800) {
          setVisibleTabs(minTabs);
          setTabWidth(containerTabWidth / minTabs);
        } else {
          setVisibleTabs(filteredTabs.length);
          setTabWidth(containerTabWidth / filteredTabs.length);
        }
      };

      handleResize = () => {
        handleTabResize();

        setArrowDisabled({
          left: activeIndex < minTabs,
          right: activeIndex >= filteredTabs.length - 2,
        });
      };

      if (tabWidth === 0) {
        handleTabResize();
      }

      window.addEventListener('resize', handleResize);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [tabsRef, filteredTabs, tabWidth, minTabs, activeIndex]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: filteredTabs.length > visibleTabs ? '0 0.5rem 0 3rem' : 'none',
        gap: '1rem',
      }}
    >
      {filteredTabs.length > visibleTabs && (
        <div
          data-disabled={arrowDisabled.left ? 'true' : 'false'}
          onClick={
            arrowDisabled.left
              ? undefined
              : () => {
                  tabsRef.current
                    ?.querySelector('div')
                    ?.scrollBy({ left: tabWidth * -1, behavior: 'smooth' });

                  if (tabsRef.current?.querySelector('div')?.scrollLeft! <= tabWidth * 1.4) {
                    tabsRef.current
                      ?.querySelector('div')
                      ?.scrollTo({ left: 0, behavior: 'smooth' });
                    setArrowDisabled({ right: false, left: true });
                  } else {
                    setArrowDisabled({ right: false, left: false });
                  }
                }
          }
          css={classes.arrow}
          style={{ marginRight: '-30px' }}
        >
          <Icon css={classes.arrowLeft} name='arrow' />
        </div>
      )}
      <StyledTabs ref={tabsRef} tabWidth={tabWidth} {...params}>
        {filteredTabs.map((tab) =>
          tab.disabled ? (
            <Tooltip title={tab.disabledText ?? `No ${tab.label} data`} placement='bottom'>
              <Tab
                key={tab.label}
                value={tab.value}
                label={tab.label}
                data-test-id={tab.dataTestId}
                className={tab.className}
                disabled={tab.disabled}
                style={{
                  color: styles.color.xLightGrey,
                  borderBottom: tab.active ? `2px solid ${styles.color.xLightGrey}` : 'inherit',
                }}
              />
            </Tooltip>
          ) : (
            <Tab
              style={{
                color: theme === 'light' ? styles.color.purple : styles.color.greyishPurple,
              }}
              key={tab.label}
              value={tab.value}
              label={tab.label}
              data-test-id={tab.dataTestId}
            />
          ),
        )}
      </StyledTabs>
      {filteredTabs.length > visibleTabs && (
        <div
          data-disabled={arrowDisabled.right ? 'true' : 'false'}
          onClick={
            arrowDisabled.right
              ? undefined
              : () => {
                  tabsRef.current
                    ?.querySelector('div')
                    ?.scrollBy({ left: tabWidth, behavior: 'smooth' });

                  if (
                    tabsRef.current?.querySelector('div')?.scrollLeft! * 1.02 >=
                    tabsRef.current?.querySelector('div')?.clientWidth!
                  ) {
                    tabsRef.current?.querySelector('div')?.scrollTo({
                      left: tabsRef.current?.querySelector('div')?.scrollWidth!,
                      behavior: 'smooth',
                    });
                    setArrowDisabled({ left: false, right: true });
                  } else {
                    setArrowDisabled({ right: false, left: false });
                  }
                }
          }
          css={classes.arrow}
          style={{ right: '-18px' }}
        >
          <Icon css={classes.arrowRight} name='arrow' />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    arrowRight: {
      width: '1.8rem !important',
      transform: 'rotate(-90deg)',
      marginLeft: '-3.4rem',
      marginTop: '-0.6rem',
    },
    arrowLeft: {
      width: '1.8rem !important',
      transform: 'rotate(90deg)',
      marginTop: '-0.6rem',
    },
    arrow: {
      marginTop: '1.1rem',
      padding: '0',
      cursor: 'pointer',
      display: 'flex',
      '&[data-disabled="true"]': {
        color: styles.color.grey,
        cursor: 'not-allowed',
      },
      zIndex: 10,
    },
  },
  light: {
    arrow: {
      color: styles.color.purple,
    },
  },
  dark: {
    arrow: {
      color: styles.color.blue,
    },
  },
});

export default PaginatedTabs;
