import { Global, css } from '@emotion/core';

export default () => (
  <>
    <symbol id='icon-checkbox-empty' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z'
        fill='#ACACBB'
        fillOpacity='0.7'
      />
    </symbol>
    <Global
      styles={css`
        .icon-checkbox-empty {
          width: 1.5em;
          height: 1.5em;
        }
      `}
    />
  </>
);
