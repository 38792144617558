import React, { useEffect, useRef, useContext, useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import RootRef from '@material-ui/core/RootRef';
import { GlobalDownloadProgressContext } from 'views/components/providers/GlobalDownloadProvider';
import Icon from 'views/components/Icon';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import StatusIcon from 'views/pages/SandboxPage/SandboxPanel/StatusIcon';
import { SandboxInstanceStatus } from 'models/Sandbox';
import styled from '@emotion/styled';
// import styles from 'views/styles';

const ProgressContainer = styled.div`
  width: 232px;
  margin: 12px 24px;
`;

const ProgressLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

const ProgressItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AllProgressItemsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const FilenameDownloadableContainer = styled.div`
  width: 176px;
  word-wrap: break-word;
  font-size: 1rem;
`;

const DownloadProgress = () => {
  const domRef = useRef<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { inProgressItems, completedItems, pendingItems } = useContext(
    GlobalDownloadProgressContext
  );

  const { classes } = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!!pendingItems.length && !Boolean(anchorEl) && !!domRef.current) {
      setAnchorEl(domRef.current);
      domRef.current = null;
    }
  }, [pendingItems.length, anchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const allCompleted = Boolean(
    !Boolean(pendingItems.length || inProgressItems.length) && completedItems.length
  );

  return (
    <RootRef rootRef={domRef}>
      <IconButton
        onClick={handleClick}
        css={classes.buttonIcon}
        size='small'
        disableRipple
        disableFocusRipple
      >
        <Icon css={classes.icon} name='download-progress' />
      </IconButton>
      <Menu
        elevation={8}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorEl={anchorEl}
        container={domRef.current}
        keepMounted
        open={Boolean(anchorEl)}
      >
        <ProgressContainer>
          {!allCompleted && (
            <ProgressLabelContainer>
              <CircularProgress />
              <div>Download in progress</div>
            </ProgressLabelContainer>
          )}
          <AllProgressItemsContainer>
            {pendingItems.map((item) => (
              <ProgressItemContainer>
                <FilenameDownloadableContainer>{item}</FilenameDownloadableContainer>
                <StatusIcon withoutText status={SandboxInstanceStatus.PENDING} />
              </ProgressItemContainer>
            ))}
            {inProgressItems.map((item) => (
              <ProgressItemContainer>
                <FilenameDownloadableContainer>{item}</FilenameDownloadableContainer>
                <CircularProgress size={16} />
              </ProgressItemContainer>
            ))}
            {completedItems.map((item) => (
              <ProgressItemContainer>
                <FilenameDownloadableContainer>{item}</FilenameDownloadableContainer>
                <StatusIcon withoutText status={SandboxInstanceStatus.SUCCEEDED} />
              </ProgressItemContainer>
            ))}
          </AllProgressItemsContainer>
        </ProgressContainer>
      </Menu>
    </RootRef>
  );
};

const useStyles = makeStyles({
  base: {
    buttonIcon: {
      position: 'absolute',
      right: 10,
      top: 2,
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    icon: {
      width: '6rem',
      height: '3rem',
    },
  },
});
export default DownloadProgress;
