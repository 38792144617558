import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-logo' viewBox='0 0 34 32'>
      <path d='M3.624 8.457h16.707c2.5 0 4.534 2.018 4.534 4.497s-2.034 4.497-4.534 4.497h-9.040c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28h9.039c3.924 0 7.116-3.166 7.116-7.058s-3.192-7.058-7.116-7.058h-16.706c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28z' />
      <path d='M20.331 0h-6.479c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28h6.479c5.778 0 10.479 4.662 10.479 10.394s-4.701 10.393-10.479 10.393h-16.46c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28h16.459c7.202 0 13.060-5.811 13.060-12.954s-5.858-12.954-13.060-12.954z' />
      <path d='M12.846 12.954c0-0.706-0.579-1.28-1.291-1.28h-3.146c-0.712 0-1.291 0.574-1.291 1.28 0 0.709 0.571 1.28 1.043 1.28h3.146c0.906 0 1.539-0.527 1.539-1.28z' />
      <path d='M8.76 29.243h-7.469c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28h7.469c0.712 0 1.291-0.574 1.291-1.28s-0.579-1.28-1.291-1.28z' />
    </symbol>

    <Global
      styles={css`
        .icon-logo {
          width: 3.4em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
