import api from 'services/api';
import request from 'state/utils/request';
import { TeamActionName } from './types';
import { ITeamValues } from 'models/Team';
import { IInviteTeamMemberValues, IUpdateTeamMemberValues } from 'models/Team';
import { Thunk } from 'state/types/thunk';
import { getSelf } from 'state/user/actions';
import { history } from 'state/store';
import { closeModal } from 'state/modal/actions';
import { TeamMember, TeamMemberInvitation } from 'models/Team';
import { PageQuery, PageAttributes } from 'models/Page';
import { extractPageQueryParams } from 'utils/pagination';
import { getAccountContext, switchAccount, switchAndRefreshAccount } from 'state/account/actions';
import { messages } from './messages';
import { handleInvitation, removeInviteToken } from 'state/invitation/actions';
import { getUserTeamByName } from 'state/user/selectors';
import {
  TeamRes,
  TeamMembersRes,
  TeamMemberInvitationsRes,
  TeamMemberInvitationRes,
} from 'services/api/schema/team';

export const createTeam =
  (teamValues: ITeamValues): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    const userId = getState().user.profile?.id!;
    const { accountNumber } = await dispatch(
      request(() => api.createTeam(userId, teamValues).then((res) => res.data), {
        type: TeamActionName.CREATE_TEAM,
      })
    );
    await dispatch(switchAccount(accountNumber));
    dispatch(getAccountContext());
    dispatch(getSelf());
    dispatch(closeModal());
    history.push(`/u/${accountNumber}/settings/team/settings`);
  };

export const joinTeam =
  (inviteToken: string, team: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    await dispatch(handleInvitation(inviteToken, 'accept'));
    dispatch(removeInviteToken());
    dispatch(closeModal());
    await dispatch(getSelf());
    const user = getState().user.profile!;
    const { accountNumber } = getUserTeamByName(user, team)!;
    await dispatch(getTeamByAccount(accountNumber));
    await dispatch(switchAccount(accountNumber));
    dispatch(getAccountContext());
    history.push(`/u/${accountNumber}/settings/team/settings`);
    history.go(0);
  };

export const getTeamByAccount =
  (accountNumber: number | string): Thunk<Promise<TeamRes>> =>
  (dispatch) => {
    return dispatch(
      request(() => api.getTeamByAccount(accountNumber).then((res) => res.data), {
        type: TeamActionName.GET_TEAM,
      })
    );
  };

export const updateTeam =
  (teamAccountNumber: number | string, teamValues: ITeamValues): Thunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      request(() => api.updateTeam(teamAccountNumber, teamValues).then((res) => res.data), {
        type: TeamActionName.UPDATE_TEAM,
      })
    );
    dispatch(getSelf());
    dispatch(closeModal());
  };

export const archiveTeam =
  (teamAccountNumber: number | string, accountNumberTo?: number | string): Thunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      request(() => api.archiveTeam(teamAccountNumber).then((res) => res.data), {
        type: TeamActionName.ARCHIVE_TEAM,
      })
    );
    dispatch(closeModal());
    if (accountNumberTo) {
      dispatch(switchAndRefreshAccount(accountNumberTo, false, '/account/advanced'));
    }
  };

export const getAllTeamMembersByAccount =
  (props: { includeRoles?: boolean; includeMfa?: boolean } = {}): Thunk<Promise<TeamMembersRes>> =>
  (dispatch, getState) => {
    const params = extractPageQueryParams(getState().team.allMembers);
    const fields = [];
    if (props.includeRoles) {
      fields.push('roles');
    }
    if (props.includeMfa) {
      fields.push('mfa');
    }
    if (fields.length > 0) {
      params.fields = fields.join(',');
    }
    return dispatch(
      request(
        () =>
          api
            .getTeamMembersByAccount(params)
            .then((res) => res.data)
            .catch((e) => {
              return { results: [] };
            }),
        {
          type: TeamActionName.GET_ALL_TEAM_MEMBERS,
        }
      )
    );
  };

export const getTeamMembersByAccount =
  (params?: PageQuery<TeamMember>): Thunk<Promise<TeamMembersRes>> =>
  (dispatch) => {
    return dispatch(
      request(() => api.getTeamMembersByAccount(params).then((res) => res.data), {
        type: TeamActionName.REFRESH_TEAM_MEMBERS,
      })
    );
  };

export const inviteTeamMember =
  (
    memberValues: IInviteTeamMemberValues,
    params?: PageQuery<TeamMemberInvitation>,
    refresh?: boolean,
    force?: boolean
  ): Thunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      request(
        () => api.inviteTeamMember(memberValues, force).then((res) => res.data),
        { type: TeamActionName.INVITE_TEAM_MEMBER },
        { success: messages.inviteSuccess, failure: messages.inviteFailure }
      )
    );
    dispatch(closeModal());
    dispatch(getTeamInvitations(params, refresh));
  };

export const updateTeamMember =
  (
    accountNumber: number,
    memberValues: IUpdateTeamMemberValues,
    queryParams?: PageAttributes<TeamMember>
  ): Thunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      request(
        () => api.updateTeamMember(accountNumber, memberValues).then((res) => res.data),
        { type: TeamActionName.UPDATE_TEAM_MEMBER },
        { success: messages.updateMemberSuccess, failure: messages.updateMemberFailure }
      )
    );
    dispatch(closeModal());
    dispatch(getTeamMembersByAccount({ ...queryParams, fields: 'roles,mfa' }));
  };

export const leaveTeam = (): Thunk<Promise<void>> => async (dispatch, getState) => {
  const { accountNumber } = getState().user.profile!;
  try {
    await dispatch(
      request(() => api.archiveTeamMember(accountNumber).then((res) => res.data), {
        type: TeamActionName.LEAVE_TEAM,
      })
    );
    await dispatch(switchAccount(accountNumber));
    await dispatch(getAccountContext());
    dispatch(getSelf());
    dispatch(closeModal());
    history.replace('/');
  } catch (error) {
    // Error handled in redux.
  }
};

export const archiveTeamMember =
  (accountNumber: number, queryParams?: PageAttributes<TeamMember>): Thunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(
      request(
        () => api.archiveTeamMember(accountNumber).then((res) => res.data),
        { type: TeamActionName.ARCHIVE_TEAM_MEMBER },
        { success: messages.archiveMemberSuccess, failure: messages.archiveMemberFailure }
      )
    );
    dispatch(closeModal());
    dispatch(getTeamMembersByAccount({ ...queryParams, fields: 'roles,mfa' }));
  };

export const getTeamInvitations =
  (
    params?: PageQuery<TeamMemberInvitation>,
    refresh?: boolean
  ): Thunk<Promise<TeamMemberInvitationsRes>> =>
  (dispatch) => {
    return dispatch(
      request(
        () => api.getTeamInvitations(params).then((res) => res.data),
        refresh
          ? { type: TeamActionName.REFRESH_TEAM_INVITATIONS }
          : { type: TeamActionName.GET_TEAM_INVITATIONS }
      )
    );
  };

export const archiveTeamInvitation =
  (invitationId: number): Thunk<Promise<TeamMemberInvitationRes>> =>
  (dispatch) => {
    return dispatch(
      request(
        () => api.archiveTeamInvitation(invitationId).then((res) => res.data),
        { type: TeamActionName.ARCHIVE_TEAM_INVITATION },
        { failure: messages.archiveInvitationFailure }
      )
    );
  };

export const resendTeamInvitation =
  (invitationId: number): Thunk<Promise<TeamMemberInvitationsRes>> =>
  (dispatch) => {
    return dispatch(
      request(
        () => api.resendTeamInvitation(invitationId).then((res) => res.data),
        { type: TeamActionName.RESEND_TEAM_INVITATION },
        { success: messages.resendInvitationSuccess, failure: messages.resendInvitationFailure }
      )
    );
  };

export const getTeamAccount = (accountNumber: number | string) =>
  request(() => api.getAccount(accountNumber).then((res) => res.data), {
    type: TeamActionName.GET_TEAM_ACCOUNT,
  });

export const getTeamUsage = (accountNumber: number | string) =>
  request(() => api.getAccount(accountNumber).then((res) => res.data), {
    type: TeamActionName.GET_TEAM_USAGE,
  });
