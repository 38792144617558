import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-qr-code' viewBox='0 0 24 25'>
      <mask
        id='mask'
        // style='mask-type:alpha'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='25'
      >
        <rect y='0.571411' width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask)'>
        <path
          d='M2 7.57141V2.57141H7V4.57141H4V7.57141H2ZM2 22.5714V17.5714H4V20.5714H7V22.5714H2ZM17 22.5714V20.5714H20V17.5714H22V22.5714H17ZM20 7.57141V4.57141H17V2.57141H22V7.57141H20ZM17.5 18.0714H19V19.5714H17.5V18.0714ZM17.5 15.0714H19V16.5714H17.5V15.0714ZM16 16.5714H17.5V18.0714H16V16.5714ZM14.5 18.0714H16V19.5714H14.5V18.0714ZM13 16.5714H14.5V18.0714H13V16.5714ZM16 13.5714H17.5V15.0714H16V13.5714ZM14.5 15.0714H16V16.5714H14.5V15.0714ZM13 13.5714H14.5V15.0714H13V13.5714ZM19 5.57141V11.5714H13V5.57141H19ZM11 13.5714V19.5714H5V13.5714H11ZM11 5.57141V11.5714H5V5.57141H11ZM9.5 18.0714V15.0714H6.5V18.0714H9.5ZM9.5 10.0714V7.07141H6.5V10.0714H9.5ZM17.5 10.0714V7.07141H14.5V10.0714H17.5Z'
          fill='var(--main)'
        />
      </g>
    </symbol>

    <Global
      styles={css`
        .icon-qr-code {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
