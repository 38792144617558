import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { CONSUMER_DOCS_URL } from 'utils/constants';
import Hero from './Hero';
import CallToAction from './CallToAction';
import microengineImg from 'assets/images/microengine.svg';
import HowItWorksIntro from './HowItWorksIntro';
import workImg from 'assets/images/work.svg';

const messages = defineMessages({
  heroHeading: {
    id: 'howItWorks.hero.heading',
    defaultMessage: 'How It Works',
  },
  heroText: {
    id: 'howItWorks.hero.text',
    defaultMessage:
      'PolySwarm’s threat bounty™ provides precise economic incentives for suppliers to promote competition, specialization, and improved accuracy.',
  },
  introHeading: {
    id: 'howItWorks.intro.heading',
    defaultMessage: 'Incentivized Threat Detection',
  },
  introText: {
    id: 'howItWorks.intro.text',
    defaultMessage:
      'Unlike in any other multiscanner, in PolySwarm there is money at stake: threat detection engines back their opinions with money, at the artifact level (file, URL, etc.), and are economically rewarded and penalized based on the accuracy of their determinations.  The following process is automated and is executed by software (engines) in near real time.',
  },
  ctaHeading: {
    id: 'howItWorks.cta.heading',
    defaultMessage: 'Get Started',
  },
  ctaText: {
    id: 'howItWorks.cta.text',
    defaultMessage:
      'Try out the PolySwarm marketplace or check out our documentation to get started today.',
  },
  scan: {
    id: 'howItWorks.cta.scan',
    defaultMessage: 'Scan Anything',
  },
  docs: {
    id: 'howItWorks.cta.docs',
    defaultMessage: 'Documentation',
  },
});

const HowItWorksContent = () => {
  const intl = useIntl();
  const history = useHistory();
  return (
    <Fragment>
      <Hero
        heading={intl.formatMessage(messages.heroHeading)}
        text={intl.formatMessage(messages.heroText)}
        icon={{ src: workImg, alt: 'work' }}
      />
      <HowItWorksIntro
        heading={intl.formatMessage(messages.introHeading)}
        text={intl.formatMessage(messages.introText)}
      />
      <CallToAction
        image={{
          src: microengineImg,
          alt: intl.formatMessage({ id: 'myMicroenginesTab.microengine' }),
        }}
        heading={intl.formatMessage(messages.ctaHeading)}
        text={intl.formatMessage(messages.ctaText)}
        buttons={[
          {
            text: intl.formatMessage(messages.scan),
            onClick: () => history.push('/scan'),
          },
          {
            text: intl.formatMessage(messages.docs),
            href: CONSUMER_DOCS_URL,
            variant: 'outlined',
          },
        ]}
      />
    </Fragment>
  );
};

export default HowItWorksContent;
