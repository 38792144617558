import React, { FC, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { defineMessages } from 'react-intl';
import { Dispatch } from 'state/types/thunk';
import { RootState } from 'state/root';
import { getSystemStatus } from 'state/system/actions';
import { showNotification } from 'state/notification/actions';
import { useAuth } from 'views/components/providers/AuthProvider';
import { SystemStatusRes } from 'services/api/schema/system';

export interface SystemState extends SystemStatusRes {}

const messages = defineMessages({
  maintenance: {
    id: 'maintenance.banner.text',
    defaultMessage: 'Our systems are currently undergoing maintenance',
  },
});

const initialSystemValues: SystemState = {
  isMaintenanceMode: false,
};

/**
 * App-level system context
 */
export const SystemContext = React.createContext<SystemState>(initialSystemValues);

/**
 * System context hook.
 */
export const useSystem = () => useContext(SystemContext);

/**
 * Provides system status context to child components.
 */
const SystemProvider: FC = ({ children }) => {
  const [hasTriggered, setHasTriggered] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const { isAuthenticated, logout } = useAuth();

  const { isMaintenanceMode } = useSelector((state: RootState) => state.system);

  const _enableMaintenanceMode = () => {
    if (isAuthenticated) {
      logout();
    }
    dispatch(
      showNotification({
        status: 'info',
        message: messages.maintenance,
        isDismissible: true,
      })
    );
  };

  const _checkStatus = async () => {
    const { isMaintenanceMode } = await dispatch(getSystemStatus());
    if (isMaintenanceMode) {
      if (!hasTriggered) {
        _enableMaintenanceMode();
        setHasTriggered(true);
      }
    } else {
      setHasTriggered(false);
    }
  };

  useEffect(() => {
    _checkStatus();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return <SystemContext.Provider value={{ isMaintenanceMode }}>{children}</SystemContext.Provider>;
};

export default SystemProvider;
