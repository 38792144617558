import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { toCSV } from 'utils/csv/csv';
import { TableCell } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { defineMessages, useIntl } from 'react-intl';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { Row } from 'views/components/table/CustomTable';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import SearchTableRow from './SearchTableRow';
import { HashResult, HashRow } from '../types';

interface ISearchTableProps {
  results: HashResult;
}

const messages = defineMessages({
  hash: {
    id: 'SearchTable.row.hash',
    defaultMessage: 'sha256',
  },
});

const SearchTable = ({ results }: ISearchTableProps) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const rows: Row<HashRow>[] = [
    {
      id: 'hash',
      numeric: false,
      label: intl.formatMessage(messages.hash),
      sortable: false,
    },
  ];

  const _downloadCSV = () => {
    results &&
      results.length > 0 &&
      toCSV(
        results.map((result) => ({
          sha256: result.hash,
        })),
        'ioc_results',
        ['sha256']
      );
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1.3rem 0' }}>
        <Tooltip title='Download list of results as CSV' placement='top'>
          <div onClick={_downloadCSV}>
            <Icon
              css={[classes.icon, !results || !results.length ? classes.disabled : '']}
              name='save'
            />
          </div>
        </Tooltip>
      </div>
      <CustomTable<HashRow>
        rows={results}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            renderHeaderCell={(row) => (
              <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                {intl.formatMessage({ id: row.label })}
              </TableCell>
            )}
          />
        )}
        renderTableRow={(row: HashRow) => <SearchTableRow key={row.hash} rowData={row} />}
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    icon: {
      width: '2.5rem',
      height: '2.5rem',
      marginTop: '1rem',
      cursor: 'pointer',
    },
    disabled: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  light: {
    icon: {
      color: styles.color.purple,
    },
  },
  dark: {
    icon: {
      color: styles.color.lightBlue,
    },
  },
});

export default SearchTable;
