import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { add } from 'date-fns';
import { enUS } from 'date-fns/locale';
// @ts-ignore: START_DATE, END_DATE defined in react-nice-dates.d.ts
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'; // eslint-disable-line
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import styled from '@emotion/styled';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import 'react-nice-dates/build/style.css';

interface Props {
  defaultTimeAgo?: 'years' | 'months' | 'weeks' | 'days';
  onDateChange: (startDate: Date | null, endDate: Date | null) => void;
}

const Container = styled.div<{ isDark: boolean }>`
  margin-bottom: 2rem;

  & .nice-dates-day:before {
    background: ${({ isDark }) => (isDark ? styles.color.blue : styles.color.purple)} !important;
  }

  & .nice-dates-popover {
    margin-top: -8px !important;
    max-width: 360px !important;
    z-index: 100 !important;
  }

  & .nice-dates-grid {
    min-height: 300px !important;
  }

  & .nice-dates-day {
    min-height: 50px !important;
  }
`;

const inputStyle = { width: '100%', fontSize: '1.2rem' };

const PSDateRangePicker = ({ defaultTimeAgo = 'months', onDateChange }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const { theme, classes } = useStyles();

  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = add(today, { [defaultTimeAgo]: -1 });
    setStartDate(oneMonthAgo);
    setEndDate(new Date());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (startDate && endDate) {
      onDateChange(startDate, endDate);
    }
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container isDark={theme === 'dark'}>
      <DateRangePicker
        startDate={startDate as Date}
        endDate={endDate as Date}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        minimumLength={1}
        format='dd/MM/yyyy'
        locale={enUS}>
        {({ startDateInputProps, endDateInputProps, focus }) => (
          <div css={classes.calendar} className='date-range'>
            <input
              className={'input' + (focus === START_DATE ? ' -focused' : '')}
              {...startDateInputProps}
              style={inputStyle}
              placeholder='Start Date'
            />
            <ArrowForwardIcon style={{ fontSize: '1.1rem', color: '#ccc' }} />
            <input
              className={'input' + (focus === END_DATE ? ' -focused' : '')}
              {...endDateInputProps}
              style={inputStyle}
              placeholder='End Date'
            />
            <EventAvailableIcon style={{ fontSize: '1.9rem', color: '#ccc' }} />
          </div>
        )}
      </DateRangePicker>
    </Container>
  );
};

export default PSDateRangePicker;

const useStyles = makeStyles({
  base: {
    center: {
      textAlign: 'center',
    },
    calendar: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridGap: '0.5rem',
      background: styles.color.white,
      color: styles.color.grey,
      padding: '1rem',
      borderRadius: '0.4rem',
      alignItems: 'center',
      marginBottom: '1rem',
    },
  },
  light: {},
  dark: {},
});
