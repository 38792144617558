// TODO: Skeleton or spinner when loading data

import React from 'react';
import styled from '@emotion/styled';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {
  AutoSizer,
  Column,
  Table,
  InfiniteLoader,
  WindowScroller,
  TableRowProps,
} from 'react-virtualized';
import TableRow from '@material-ui/core/TableRow';
import baseStyles from 'views/styles';

import TableCell from './TableCell';
import TableFooter from './TableFooter';

import {
  IAutoSizer,
  IInfiniteScrollTable,
  IRowRender,
  IRenderHeader,
  EColumnAlign,
  ESortDirection,
  ICellRenderer,
} from './types';
import styles from './styles';

const Wrapper = styled.div`
  padding-left: ${baseStyles.spacing.grid};
  padding-right: calc(${baseStyles.spacing.grid} - 0.8rem);
  .ReactVirtualized__Grid {
    outline: none;
  }
  .ReactVirtualized__Table__Grid,
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

/**
 * Render single cell of table header
 */
const renderHeader = <T extends {}>({ column, sort, onSort }: IRenderHeader<T>) => {
  if (!column) {
    return null;
  }

  const _onClick = () => {
    if (onSort) {
      onSort({
        orderBy: column.id,
        direction:
          sort && sort.direction === ESortDirection.ASC ? ESortDirection.DESC : ESortDirection.ASC,
      });
    }
  };

  return (
    <TableCell
      component='div'
      variant='head'
      style={styles.cell}
      align={column.align === EColumnAlign.RIGHT ? 'right' : 'left'}
      data-cy={column.id}
    >
      {sort && column.sortable ? (
        <TableSortLabel
          hideSortIcon={column.sortable}
          active={column.sortable && sort.orderBy === column.id}
          direction={sort.direction}
          onClick={_onClick}
        >
          <span>{column.label}</span>
        </TableSortLabel>
      ) : (
        <span>{column.label}</span>
      )}
    </TableCell>
  );
};

/**
 * Render single cell of table body
 */
const cellRenderer = <T extends {}>({
  cellData,
  rowData,
  columnIndex,
  column,
}: ICellRenderer<T>) => {
  if (!column) {
    return null;
  }

  const value = column.dataRenderer
    ? column.dataRenderer({ cellData, rowData, columnIndex, column })
    : cellData;

  return (
    <TableCell
      component='div'
      variant='body'
      style={styles.cell}
      align={column.align === EColumnAlign.RIGHT ? 'right' : 'left'}
      data-cy={column.id}
    >
      {value}
    </TableCell>
  );
};

/**
 * Render single row
 */
const rowRenderer = <T extends {}>({
  key,
  columns,
  index,
  onRowClick,
  rowData,
  style,
}: IRowRender<T>) => {
  const _onClick = (event: any) => {
    if (onRowClick) {
      onRowClick({ event, index, rowData });
    }
  };

  return (
    <TableRow
      key={key}
      data-testid='infiniteScrollTableRow'
      component='div'
      onClick={_onClick}
      style={style}
      hover
    >
      {columns}
    </TableRow>
  );
};

const InfiniteScrollTable = <T extends {}>({
  loading,
  columns,
  data,
  rowHeight,
  rowStyle,
  headerHeight,
  onLoadMore,
  loadMoreTreshold,
  sort,
  onSort,
  hasMore,
  button,
  registerRef,
  rowRenderer: customRowRenderer,
  ...otherProps
}: IInfiniteScrollTable<T>) => {
  let reference: any = undefined;
  const rowCount = data.length;
  const totalRowCount = hasMore ? data.length + 1 : data.length;
  const rowGetter = ({ index }: { index: number }) => data[index];
  const isRowLoaded = ({ index }: { index: number }) => !!data[index];
  const loadMoreRows = () => {
    if (loading || !onLoadMore || !hasMore) {
      return Promise.resolve();
    }

    return onLoadMore();
  };

  // Merge provided row styling as a function or object with default
  // row styling.
  const _rowStyle = ({ index }: { index: number }) => {
    let _styles = { ...styles.row };
    if (typeof rowStyle === 'function') {
      _styles = { ..._styles, ...rowStyle({ index }) };
    } else if (typeof rowStyle === 'object') {
      _styles = { ..._styles, ...((rowStyle as object) || {}) };
    }

    return _styles;
  };

  const _rowRenderer = (props: TableRowProps) => {
    if (customRowRenderer) {
      return customRowRenderer({ ...props, parent: reference });
    } else {
      return rowRenderer({ ...props, parent: reference });
    }
  };

  return (
    <Wrapper>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={totalRowCount}
        threshold={loadMoreTreshold}
      >
        {({ onRowsRendered, registerChild }) => (
          <WindowScroller>
            {({ height, isScrolling, scrollTop }) => (
              <AutoSizer disableHeight>
                {({ width }: IAutoSizer) => (
                  <Table
                    ref={(ref) => {
                      reference = ref;
                      registerChild(ref);
                      if (registerRef && ref) {
                        registerRef(ref);
                      }
                    }}
                    isScrolling={isScrolling}
                    scrollTop={scrollTop}
                    overscanRowCount={10}
                    height={height}
                    width={width}
                    rowHeight={rowHeight!}
                    headerHeight={headerHeight!}
                    rowCount={rowCount}
                    rowGetter={rowGetter}
                    rowRenderer={_rowRenderer}
                    rowStyle={_rowStyle}
                    onRowsRendered={onRowsRendered}
                    autoHeight
                    {...otherProps}
                  >
                    {columns.map(({ id, label, width }, index) => (
                      <Column
                        key={id as string}
                        headerStyle={styles.columnHeader}
                        headerRenderer={(props) =>
                          renderHeader<keyof T>({
                            ...props,
                            columnIndex: index,
                            column: columns[index],
                            sort,
                            onSort,
                          })
                        }
                        cellDataGetter={({ rowData, dataKey }) => rowData[dataKey]}
                        cellRenderer={(props) =>
                          cellRenderer<keyof T>({
                            ...props,
                            column: columns[index],
                          })
                        }
                        dataKey={id as string}
                        label={label}
                        width={(width || 0) + 1000}
                        style={styles.column}
                      />
                    ))}
                  </Table>
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        )}
      </InfiniteLoader>

      <TableFooter>{button}</TableFooter>
    </Wrapper>
  );
};

InfiniteScrollTable.defaultProps = {
  rowHeight: 48,
  headerHeight: 48,
  sort: {},
};

export default InfiniteScrollTable;
