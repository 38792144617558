import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys, errorMessages } from 'utils/error';
import { TeamActionName } from 'state/team/types';
import { updateTeam } from 'state/team/actions';
import { closeModal, openModal } from 'state/modal/actions';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import TeamForm from 'views/pages/TeamDetailPage/forms/TeamForm';
import Modal from 'views/components/Modal';
import { useUser } from 'views/components/providers/UserProvider';
import { SetupMfaPanel } from 'views/components/SetupMfaPanel/SetupMfaPanel';
import { hasMadeTeamMFARecently, loginWriteTeams } from 'state/auth/actions';
import { ITeamValues } from 'models/Team';

export interface UpdateTeamModalParams {
  teamAccountNumber: number | string;
  values: Partial<ITeamValues>;
}

const messages = defineMessages({
  heading: {
    id: 'teams.modal.updateHeading',
    defaultMessage: 'Edit team',
  },
});

const errorKeys: ErrorKeys = {
  already_exists: errorMessages.teamNameExists.id,
};

const UpdateTeamModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const team = useSelector((state: RootState) => state.team.single);
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [TeamActionName.UPDATE_TEAM]);
  const error = errorSelector(requests, [TeamActionName.UPDATE_TEAM]);

  const params = useSelector(
    ({ modal }: { modal: ModalState<UpdateTeamModalParams> }) => modal.params
  );

  const teamAccountNumber = params.teamAccountNumber || team?.accountNumber!;
  const values: Partial<ITeamValues> = {
    name: params.values?.name || team?.name,
    description: params.values?.description || team?.description,
  };

  const _closeModal = () => dispatch(closeModal());

  if (!user.mfaEnabled)
    return (
      <Modal>
        <SetupMfaPanel onSetup={_closeModal} onCancel={_closeModal} />
      </Modal>
    );

  if (!hasMadeTeamMFARecently()) {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({
              modal: 'UPDATE_TEAM',
              location: location.pathname,
              teamAccountNumber,
              values,
            })
          );
        },
      })
    );
  }

  return (
    <Modal>
      <TeamForm
        css={ownStyle}
        initialValues={values}
        heading={intl.formatMessage(messages.heading)}
        buttonText={intl.formatMessage(btnMessages.save)}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        onSubmit={(teamValues) => dispatch(updateTeam(teamAccountNumber, teamValues))}
        onCancel={_closeModal}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default UpdateTeamModal;
