import React, { useRef, useEffect, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { getAllLiveHunts } from 'state/hunting/actions';
import { downloadArtifactUrl } from 'state/submission/actions';
import { extractPageQueryParams } from 'utils/pagination';
import { RootState } from 'state/root';
import { Hunt } from 'models/Ruleset';
import { PageQuery } from 'models/Page';
import { HuntingActionName } from 'state/hunting/types';
import { openModal } from 'state/modal/actions';
import { DeleteHuntModalParams } from 'views/pages/HuntPage/modals/DeleteHuntModal';
import { ViewRulesetModalParams } from 'views/pages/HuntPage/modals/ViewRulesetModal';
import { InfoModalParams } from 'views/pages/HuntPage/modals/InfoModal';
import { EPageDirection } from 'views/components/table/CustomTablePagination';
import HuntingTable from '../HuntingTable';
import PanelContent from 'views/components/layout/PanelContent';
import AppLoader from 'views/components/request/AppLoader';
import { toggleHuntsType } from 'views/pages/HuntPage/types';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';

interface ILiveHuntTab {
  showActive: boolean;
  selectedHunts: string[];
  toggleHunts: toggleHuntsType;
}

const messages = {
  heading: 'Choose a YARA ruleset',
  text: "Choose the YARA ruleset you'd like to use for your Live Hunt by clicking below. For information on searching and YARA rules, check out the docs.",
  chooseRuleset: 'Choose ruleset',
  ruleset: 'Ruleset',
  liveHuntInfo: 'Live Hunt Info',
};

const LiveHuntTab = ({ showActive, selectedHunts, toggleHunts }: ILiveHuntTab) => {
  const _offsets = useRef<string[]>([]);
  const _currentOffset = useRef<string | undefined>();

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();

  const { liveHunts } = useSelector((state: RootState) => state.hunting);

  const _getAllLiveHunts = (all: boolean, refresh: boolean, query?: PageQuery<Hunt>) => {
    const params = Object.assign({}, extractPageQueryParams(liveHunts), query);
    dispatch(getAllLiveHunts(all, refresh, params));
  };

  const _viewRuleset = (id: string) =>
    dispatch(openModal<ViewRulesetModalParams>('VIEW_RULESET', { id, scanType: 'live' }));

  const _deleteHunt = async (id: string) =>
    dispatch(
      openModal<DeleteHuntModalParams>('DELETE_HUNT', { all: !showActive, id, scanType: 'live' })
    );

  const _downloadArtifact = (hash: string, url: string) => dispatch(downloadArtifactUrl(hash, url));

  const _showHuntingInfo = (liveHuntId: string, liveHuntResultId: string) =>
    dispatch(
      openModal<InfoModalParams>('INFO_MODAL', {
        title: messages.liveHuntInfo,
        data: [
          { key: 'Live Hunt ID', value: liveHuntId },
          { key: 'Live Hunt Result ID', value: liveHuntResultId },
        ],
      })
    );

  const _updateQueryParams = (newParams: PageQuery<Hunt>) => {
    const params = qs.parse(location.search);
    history.replace(`${match.url}?${qs.stringify({ ...params, ...newParams })}`);
  };

  const _handleChangePage = (direction: EPageDirection) => {
    let offset;

    if (direction === EPageDirection.PREV) {
      _currentOffset.current = _offsets.current.pop();
      offset = _currentOffset.current;
    } else if (direction === EPageDirection.NEXT) {
      if (_currentOffset.current) {
        _offsets.current.push(_currentOffset.current);
      }
      _currentOffset.current = String(liveHunts.offset);
      offset = _currentOffset.current;
    } else if (direction === EPageDirection.FIRST) {
      _currentOffset.current = undefined;
      _offsets.current = [];
    }

    _updateQueryParams({ offset: offset });
  };

  const _handleChangeRowsPerPage = (numRows: number) => {
    _currentOffset.current = undefined;
    _offsets.current = [];
    _updateQueryParams({ limit: numRows, offset: undefined });
  };

  useEffect(() => {
    const params = qs.parse(location.search);
    _getAllLiveHunts(!showActive, true, params);
  }, [showActive, location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppLoader
      // @ts-ignore
      loadingActions={[HuntingActionName.REFRESH_ALL_LIVE_HUNTS]}
      onReload={() => _getAllLiveHunts(!showActive, true)}
    >
      {() => {
        if (!liveHunts.results) {
          return null;
        }
        return !liveHunts.results.length ? (
          <PanelContent
            testId='liveHuntEmpty'
            imageComponent={<RulesetImg />}
            heading={'No Results'}
          />
        ) : (
          <Fragment>
            <HuntingTable
              hunts={liveHunts}
              viewRuleset={_viewRuleset}
              deleteHunt={_deleteHunt}
              showHuntingInfo={_showHuntingInfo}
              modalTitle={messages.liveHuntInfo}
              selectedHunts={selectedHunts}
              toggleHunts={toggleHunts}
              handleChangePage={_handleChangePage}
              handleChangeRowsPerPage={_handleChangeRowsPerPage}
              isFirst={!_currentOffset.current}
              downloadArtifact={_downloadArtifact}
            />
          </Fragment>
        );
      }}
    </AppLoader>
  );
};

export default LiveHuntTab;
