import React, { ChangeEvent, ReactNode } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Field, FieldProps } from 'formik';

const CheckboxField = ({
  className,
  name,
  label,
  value,
  checked,
  disabled,
  onChange,
}: {
  className?: string;
  name: string;
  label: string | ReactNode;
  value?: any;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControlLabel
          disabled={disabled}
          className={className}
          control={
            <Checkbox
              color='primary'
              name={name}
              value={value || field.value}
              checked={checked !== undefined ? checked : field.value}
              onChange={typeof onChange === 'function' ? onChange : field.onChange}
            />
          }
          label={label}
        />
      )}
    </Field>
  );
};

export default CheckboxField;
