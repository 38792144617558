import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import PanelContent, { IPanelContent } from 'views/components/layout/PanelContent/PanelContent';

const CallToAction = (props: IPanelContent) => {
  const { classes } = useStyles();
  return (
    <div css={classes.root}>
      <PanelContent {...props} />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      padding: `${styles.spacing.xxs} 0 ${styles.spacing.tiny}`,
    },
  },
  light: {
    root: {
      backgroundColor: styles.color.offWhite,
    },
  },
  dark: {
    root: {
      backgroundColor: styles.color.darkBlack,
    },
  },
});

export default CallToAction;
