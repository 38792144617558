import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageQuery } from 'models/Page';
import { RootState } from 'state/root';
import { PublicMicroengineListing } from 'models/Microengine';
import {
  getAllMicroengines as getAllMicroenginesAction,
  selectMicroengine,
} from 'state/microengines/actions';
import { extractPageQueryParams } from 'utils/pagination';
import { MicroenginesActionName } from 'state/microengines/types';
import AppLoader from 'views/components/request/AppLoader';
import AllMicroenginesTable from './AllMicroenginesTable';
import { IColumnSort, ESortDirection } from 'views/components/table/InfiniteScrollTable';

const AllMicroenginesTab = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const microengines = useSelector((state: RootState) => state.microengines.all);

  const [sort, setSort] = useState<IColumnSort<keyof PublicMicroengineListing>>({
    orderBy: 'name',
    direction: ESortDirection.ASC,
  });

  // Get list of provied tags
  const tags = useMemo(() => {
    const searchQuery = new URLSearchParams(location.search);
    return searchQuery.has('tags') && searchQuery.get('tags') !== ''
      ? searchQuery.get('tags')!.split(',')
      : [];
  }, [location.search]);

  // Call API for list of all microengines
  const _getAllMicroengines = (refresh: boolean, query?: PageQuery<PublicMicroengineListing>) => {
    const params = Object.assign({ tags }, extractPageQueryParams(microengines), query);
    dispatch(getAllMicroenginesAction(params, refresh));
  };

  // Apply sorting
  const _handleSort = ({ orderBy, direction }: any) => {
    setSort({ orderBy, direction });
    _getAllMicroengines(true, {
      orderBy,
      direction,
    });
  };

  useEffect(() => {
    _getAllMicroengines(true);
  }, []); // eslint-disable-line

  useEffect(() => {
    _getAllMicroengines(true);
  }, [tags]); // eslint-disable-line

  const _onLoadMore = () => {
    const { offset, limit } = microengines;

    _getAllMicroengines(false, {
      offset: offset ? Number(offset) + limit : limit,
    });

    return Promise.resolve();
  };

  const _onClickEngine = (engine: PublicMicroengineListing) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    dispatch(selectMicroengine(engine));
  };

  return (
    <AppLoader
      loadingActions={[MicroenginesActionName.REFRESH_ALL_MICROENGINES]}
      onReload={() => _getAllMicroengines(true)}>
      {microengines.results && (
        <AllMicroenginesTable
          microengines={microengines}
          sort={sort}
          onSort={_handleSort}
          onLoadMore={_onLoadMore}
          onClickEngine={_onClickEngine}
        />
      )}
    </AppLoader>
  );
};

export default AllMicroenginesTab;
