import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import Modal from 'views/components/Modal';
import Icon from 'views/components/Icon';
import styles from 'views/styles';
import { JsonViewer } from 'views/components/JsonViewer';
import { Item } from 'views/pages/SandboxPage/JSONTab/components';
import CopyTextButton from 'views/components/CopyText/CopyTextButton/CopyTextButton';
import { downloadJSON } from 'views/pages/ScanPage/ScanResultsPage/ScanResultsPanel/ScanResultsTabs/JSONTab/utils';

export interface MetadataModalParams {
  jsonMetadata: string;
  eventType: string;
  timestamp: string;
}

const MetadataModal = () => {
  const [collapsed, setCollapsed] = useState<number | boolean>(1);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const _closeModal = () => dispatch(closeModal());
  const { eventType, timestamp, jsonMetadata } = useSelector(
    ({ modal }: { modal: ModalState<MetadataModalParams> }) => modal.params
  );

  return (
    <Modal maxWidth='xl'>
      <Icon css={classes.iconClose} name='close' onClick={_closeModal} />
      <div css={classes.container}>
        <h3 css={classes.title} className='h4'>
          {eventType} {timestamp} Metadata
        </h3>
        <div>
          <div css={classes.items}>
            <Item
              css={classes.item}
              onClick={() => {
                setCollapsed(false);
              }}
            >
              Expand All
            </Item>
            <Item
              css={classes.item}
              onClick={() => {
                setCollapsed(true);
              }}
            >
              Collapse All
            </Item>
            <Item data-testid='jsonCopyIcon'>
              <CopyTextButton label='Copy All' text={jsonMetadata} alignNotification='right' />
            </Item>
            <Item
              css={classes.item}
              onClick={() =>
                downloadJSON({ name: `${eventType}_${timestamp}`, json: jsonMetadata })
              }
            >
              <Icon name='download' className='icon-download' />
            </Item>
          </div>
          <JsonViewer
            maxHeight='30rem'
            collapsed={collapsed}
            collapseStringsAfterLength={80}
            data={JSON.parse(jsonMetadata)}
          />
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      padding: '5rem 4rem 4rem 4rem',
      minHeight: '40rem',
      maxHeight: '50rem',
      minWidth: '48rem',
    },
    items: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    title: {
      textAlign: 'center',
      marginBottom: '2rem',
    },
    iconClose: {
      position: 'absolute',
      top: '2rem',
      right: '2rem',
      cursor: 'pointer',
      width: '1rem !important',
    },
  },
  light: {
    item: {
      color: styles.color.purple,
    },
  },
  dark: {
    item: {
      color: styles.color.lightBlue,
    },
  },
});

export default MetadataModal;
