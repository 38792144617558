import { AuthState } from '.';
import { AuthAction, AuthActionName } from './types';
import { UserActionName, UserAction } from 'state/user/types';
import { InvitationActionName, InvitationAction } from 'state/invitation/types';

export const initialState: AuthState = {
  isLoggedIn: false,
  tokens: null,
};

const auth = (
  state: AuthState = initialState,
  action: AuthAction | UserAction | InvitationAction
): AuthState => {
  switch (action.type) {
    case AuthActionName.LOGIN: {
      const { lastLocation } = action;
      return { ...initialState, lastLocation, inviteToken: state.inviteToken };
    }

    case AuthActionName.SET_SESSION: {
      const { expiresAt, accessToken, idTokenPayload, scope } = action;
      return {
        ...state,
        isLoggedIn: true,
        tokens: { expiresAt, accessToken, idTokenPayload, scope: (scope || '').split(' ') },
      };
    }

    case AuthActionName.LOGOUT: {
      const { lastLocation } = action;
      return { ...initialState, lastLocation, inviteToken: state.inviteToken };
    }

    case UserActionName.ARCHIVE_USER: {
      return initialState;
    }

    case InvitationActionName.SET_INVITE_TOKEN: {
      return { ...state, inviteToken: action.inviteToken };
    }

    case InvitationActionName.REMOVE_INVITE_TOKEN: {
      return { ...state, inviteToken: undefined };
    }

    default: {
      return state;
    }
  }
};

export default auth;
