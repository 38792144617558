import React, { useEffect, useCallback } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamAccount } from 'state/team/actions';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Tooltip } from '@material-ui/core';
import { useUser } from 'views/components/providers/UserProvider';
import { RootState } from 'state/root';
import { Dispatch } from 'state/types/thunk';
import Icon from 'views/components/Icon';
import { useAuth } from 'views/components/providers/AuthProvider';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import SearchField from 'views/components/form/SearchField';
import AccordionData from 'views/components/Accordion/AccordionData';
import PanelContent from 'views/components/layout/PanelContent';
import ErrorPageContent, {
  messages as errorMessages,
} from 'views/components/error/ErrorPageContent';
import PanelLoader from 'views/components/Loader/PanelLoader';
import DateSelector from 'views/components/DateSelector';
import SeachTable from './SearchTable';
import { HashResult, SearchEnum, IocEnum, SearchType, IOCResult } from './types';
import { useIOCSearch } from './useIOCSearch';
import { storeAccountKey, getAccountStoredKey } from 'utils/storage/storage';
import styles from 'views/styles';

const messages = defineMessages({
  search: {
    id: 'iocSearch.search',
    defaultMessage: 'Search by {searchType}',
  },
  results: {
    id: 'iocSearch.results',
    defaultMessage: 'IOC Results',
  },
  goBack: {
    id: 'iocSearch.goBack',
    defaultMessage: 'Back',
  },
  searchByHash: {
    id: 'iocSearch.searchByHash',
    defaultMessage: 'By hash',
  },
  searchByIOC: {
    id: 'iocSearch.searchByIOC',
    defaultMessage: 'By IOC',
  },
  searchByIOCOptions: {
    id: 'iocSearch.searchByIOCOptions',
    defaultMessage: 'Search by IP, URL, MITRE TTP, or Imphash',
  },
  error: {
    id: 'iocSearch.error',
    defaultMessage: 'Error searching for IOC',
  },
});

const autoCompleteStorageKey = 'iocSearchAutoComplete';

const getAutocompleteListFromStorage = (
  accountNumber: string | number,
  type: 'hash' | IocEnum
): { value: string }[] => {
  return getAccountStoredKey(accountNumber, `${autoCompleteStorageKey}${type}`);
};

const setAutocompleteListToStorage = (
  accountNumber: string | number,
  type: 'hash' | IocEnum,
  value: { value: string }[]
) => {
  storeAccountKey(accountNumber, `${autoCompleteStorageKey}${type}`, value);
};

const IOCSearchTab = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const {
    results,
    fetchIOCSearch,
    cleanResults,
    retry,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    startDateKey,
    endDateKey,
    loading,
    error,
  } = useIOCSearch();
  const dispatch = useDispatch<Dispatch>();
  const { account } = useSelector(({ team, account }: RootState) => ({
    team,
    account,
  }));
  const { isAuthenticated, login } = useAuth();
  const { classes } = useStyles();
  const [searchText, setSearchText] = React.useState<string | undefined>('');
  const [selectedSearchType, setSelectedSearchType] = React.useState<SearchType>(SearchEnum.Hash);
  const [selectedIocType, setSelectedIocType] = React.useState<IocEnum>(IocEnum.IP);
  const { accountNumber } = useUser();

  const _getTeamAccount = useCallback(() => {
    if (account.context?.context === 'team' && account.context?.accountNumber) {
      return dispatch(getTeamAccount(account.context.accountNumber));
    }
  }, [dispatch, account]);
  const hasIOCUses = useSelector<RootState>(({ user, account, team }) => {
    if (!account.context || !user.account) {
      return null;
    }

    let features = user.account.features;

    if (account.context.team && team.account) {
      features = team.account.features;
    }

    return features.some((f) => f.tag === 'ioc_user' && (f.value || f.remainingUses > 0));
  });

  useEffect(() => {
    _getTeamAccount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _emptySearch = () => {
    setSearchText(searchText === undefined ? '' : undefined);
  };

  const autoCompleteKey = selectedSearchType === SearchEnum.Hash ? 'hash' : selectedIocType;
  const autoCompleteItems =
    (!!accountNumber && getAutocompleteListFromStorage(accountNumber, autoCompleteKey)) || [];

  if (isAuthenticated && hasIOCUses === null) {
    return <PanelLoader />;
  }
  if (!isAuthenticated || hasIOCUses === false) {
    return (
      <PanelContent
        heading={intl.formatMessage({ id: 'ioc.sidebar.heading' })}
        text={intl.formatMessage({ id: 'ioc.sidebar.text' })}
        buttons={[
          isAuthenticated
            ? {
                text: intl.formatMessage({ id: 'button.upgrade' }),
                onClick: () => history.push('/pricing'),
              }
            : {
                text: intl.formatMessage({ id: 'button.login' }),
                onClick: () => login(location.pathname + location.search),
              },
        ]}
      />
    );
  }

  return (
    <div css={classes.container}>
      {!results ? (
        <div>
          <span>{intl.formatMessage(messages.searchByHash)}</span>
          <Switch
            color='primary'
            checked={selectedSearchType === SearchEnum.IOC}
            onChange={(e) => {
              _emptySearch();
              setSelectedSearchType(e.target.checked ? SearchEnum.IOC : SearchEnum.Hash);
            }}
          />
          <div css={classes.flex}>
            {intl.formatMessage(messages.searchByIOC)}
            <Tooltip title={intl.formatMessage(messages.searchByIOCOptions)} placement='top'>
              <div css={classes.info}>
                <Icon name='info' />
              </div>
            </Tooltip>
          </div>
        </div>
      ) : null}
      {results ? (
        <div onClick={cleanResults} css={classes.goBack}>
          <Icon css={classes.arrowLeft} name='arrow' title={intl.formatMessage(messages.goBack)} />
          <span>{intl.formatMessage(messages.goBack)}</span>
        </div>
      ) : null}
      {selectedSearchType === SearchEnum.IOC && !results ? (
        <div css={classes.dateContainer}>
          <DateSelector
            label='Start'
            storageKey={startDateKey}
            defaultOption={startDate.option}
            defaultValue={startDate.value}
            onChange={(daysAgo, option) => setStartDate({ option, value: daysAgo })}
          />
          <DateSelector
            label='End'
            storageKey={endDateKey}
            maxDays={startDate.option === 'TODAY' ? 'TODAY' : startDate.value}
            defaultOption={endDate.option}
            defaultValue={endDate.value}
            onChange={(daysAgo, option) => setEndDate({ option, value: daysAgo })}
          />
        </div>
      ) : null}
      <div css={classes.searchBar}>
        {selectedSearchType === SearchEnum.IOC && !results ? (
          <FormControl>
            <InputLabel id='label'></InputLabel>
            <Select
              id='label'
              css={classes.select}
              value={selectedIocType}
              onChange={(e) => {
                _emptySearch();
                setSelectedIocType(e.target.value as IocEnum);
              }}
            >
              {Object.values(IocEnum).map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        <SearchField
          label={intl.formatMessage(messages.search, {
            searchType: selectedSearchType === SearchEnum.Hash ? 'hash' : selectedIocType,
          })}
          initialValue={searchText}
          isLoading={false}
          autocompleteItems={autoCompleteItems}
          onRemoveAutocompleteItem={(item: string) => {
            const newItems = autoCompleteItems.filter((t) => t.value !== item);
            !!accountNumber &&
              setAutocompleteListToStorage(accountNumber, autoCompleteKey, newItems);
          }}
          autocompleEnabled={autoCompleteItems?.length > 0}
          onSubmit={(value) => {
            fetchIOCSearch({
              seachType: selectedSearchType,
              iocType: selectedIocType,
              text: value,
            });
            const newItems = [{ value }, ...autoCompleteItems.slice(0, 9)].reduce(
              (unique, item) =>
                unique.map((u) => u.value).includes(item.value) ? unique : [...unique, item],
              [] as { value: string }[]
            );
            !!accountNumber &&
              setAutocompleteListToStorage(accountNumber, autoCompleteKey, newItems);
          }}
        />
      </div>
      <div css={classes.resultContainer}>
        {results && selectedSearchType === SearchEnum.IOC ? (
          <SeachTable results={results as HashResult} />
        ) : null}
        {results && selectedSearchType === SearchEnum.Hash ? (
          <AccordionData
            css={classes.accordion}
            title={intl.formatMessage(messages.results)}
            data={results as IOCResult}
            columns={2}
          />
        ) : null}
        {loading ? <PanelLoader /> : null}
        {error ? (
          <ErrorPageContent
            className='h-mt-lg h-mb-lg'
            heading={intl.formatMessage(messages.error)}
            text={error}
          >
            <Button
              style={{ fontSize: '2.4rem' }}
              color='primary'
              variant='contained'
              onClick={retry}
            >
              {intl.formatMessage(errorMessages.buttonReload)}
            </Button>
          </ErrorPageContent>
        ) : null}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      padding: '1rem 3rem 0 3rem',
    },
    dateContainer: {
      display: 'flex',
      marginTop: '2rem',
    },
    goBack: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      gap: '0.5rem',
      cursor: 'pointer',
    },
    searchBar: {
      display: 'flex',
      gap: '5rem',
      marginTop: '3rem',
      alignItems: 'flex-start',
    },
    arrowLeft: {
      transform: 'rotate(90deg)',
      fontSize: '0.5rem',
    },
    select: {
      minWidth: '12rem',
      minHeight: '3.6rem',
    },
    accordion: {
      marginTop: '2rem',
    },
    resultContainer: {
      minHeight: '30rem',
    },
    info: {
      cursor: 'help',
      fontSize: '0.45rem',
      color: styles.color.xLightGrey,
    },
    flex: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '0.8rem',
    },
  },
  light: {},
  dark: {},
});
export default IOCSearchTab;
