import React, { useCallback } from 'react';
import { TableCell } from '@material-ui/core';
import { TablePagination, EPageDirection } from 'views/components/table/TablePagination';
import { Row } from 'views/components/table/CustomTable';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import TeamActivityTableRow from './TeamActivityTableRow';
import { TeamActivityRow } from './types';
import { TeamActivityProps } from './hooks/useTeamActivity';

interface ITeamActivityTableProps {
  results: TeamActivityRow[];
  more: boolean;
  refetch: TeamActivityProps['refetch'];
  filters: { key: string; value: string }[];
  paginationProps: { [key: string]: any };
}

const TeamActivityTable = ({
  filters,
  results,
  more,
  refetch,
  paginationProps,
}: ITeamActivityTableProps) => {
  const { isFirst, isLast, offset, limit, onChangePage, onChangeRowsPerPage } = paginationProps;
  const paginatedData = results.slice(offset, offset + limit);

  const PaginatedComponent = useCallback(
    () =>
      paginatedData.length ? (
        <TablePagination
          rowsPerPage={limit}
          offset={offset}
          total={results.length}
          rowsPerPageOptions={[5, 25, 50, 100, 200]}
          onChangePage={(direction) => {
            if (direction === EPageDirection.NEXT && isLast && more) {
              refetch(filters);
            }
            onChangePage(direction);
          }}
          onChangeRowsPerPage={onChangeRowsPerPage}
          isFirst={isFirst}
          hasMore={more}
          isLast={isLast && !more}
        />
      ) : (
        <></>
      ),
    [
      filters,
      isFirst,
      isLast,
      limit,
      more,
      offset,
      onChangePage,
      onChangeRowsPerPage,
      paginatedData.length,
      refetch,
      results.length,
    ]
  );

  const rows: Row<TeamActivityRow>[] = [
    {
      id: 'timestamp',
      numeric: false,
      label: 'Timestamp',
      sortable: false,
    },
    {
      id: 'eventType',
      numeric: false,
      label: 'Event Type',
      sortable: false,
    },
    {
      id: 'user',
      numeric: false,
      label: 'User',
      sortable: false,
    },
    {
      id: 'metadata',
      numeric: false,
      label: 'Metadata',
      sortable: false,
    },
  ];

  return (
    <div>
      <CustomTable<TeamActivityRow>
        rows={paginatedData}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={false}
            renderHeaderCell={(row) => (
              <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                {row.label}
              </TableCell>
            )}
          />
        )}
        renderTableRow={(row: TeamActivityRow) => (
          <TeamActivityTableRow key={row.timestamp} rowData={row} />
        )}
        renderPagination={<PaginatedComponent />}
      />
    </div>
  );
};

export default TeamActivityTable;
