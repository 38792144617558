import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'state/root';
import { updateUserMicroengine } from 'state/microengines/actions';
import { showNotification } from 'state/notification/actions';
import InfiniteScrollTable, {
  IColumn,
  IDataRenderer,
  IColumnSort,
  EColumnAlign,
  IRowRender,
} from 'views/components/table/InfiniteScrollTable';
import tableStyles from 'views/components/table/InfiniteScrollTable/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useUser } from 'views/components/providers/UserProvider';
import styles from 'views/styles';
import { btnMessages } from 'views/components/Button';
import ContextMenu from 'views/components/menu/ContextMenu';
import FabButton from 'views/components/Button/FabButton';
import TagStatus from 'views/components/Tag/TagStatus';
import { ITagStatusConfig } from 'views/components/Tag/TagStatus/TagStatus';
import { TagsList } from 'views/pages/MicroenginesPage/TagsList/TagsList';
import DevelopmentResults from '../../DevelopmentResults';
import { canManageMicroengine } from 'views/pages/MicroenginesPage/auth';
import {
  UserMicroengines,
  MicroengineListing,
  MicroengineValues,
  EngineStatuses,
} from 'models/Microengine';
import ToggleButton from './ToggleButton';
import styled from '@emotion/styled';

const TableWrapper = styled.div`
  & .ReactVirtualized__Grid__innerScrollContainer {
    height: auto !important;
    max-height: none !important;
  }
`;

const DevelopmentResultsContainer = styled.div`
  padding: 2rem 0;
`;

const FlexTableRow = styled(TableRow)`
  display: flex !important;
`;

interface IMyMicroenginesTable {
  microengines: UserMicroengines;
  sort?: IColumnSort<keyof MicroengineListing>;
  onSort: (orderBy: keyof MicroengineListing) => void;
  createUserMicroengine: () => void;
  updateUserMicroengine: (id: number, values: MicroengineValues) => void;
  archiveUserMicroengine: (id: number, displayName: string, accountNumber: number) => void;
  onLoadMore?: () => Promise<void>;
  onClickEngine: (engine: MicroengineListing) => void;
}

interface HeaderRow {
  type: 'header';
  title: string;
  id: string;
  developmentCommunity: string;
}

const messages = defineMessages({
  verificationStatus: {
    id: 'microengine.table.verificationStatus',
    defaultMessage:
      'PolySwarm’s support team is evaluating your request. You will be contacted via email when it is completed.',
  },
  verificationMessage: {
    id: 'microengine.table.verificationMessage',
    defaultMessage:
      'Your Verification Request has been sent to the PolySwarm Support team. You will be contacted by email within 2 business days.',
  },
});

const MyMicroenginesTable = ({
  microengines,
  sort,
  onSort,
  createUserMicroengine,
  updateUserMicroengine: updateUserMicroengineModal,
  archiveUserMicroengine,
  onLoadMore,
  onClickEngine,
}: IMyMicroenginesTable) => {
  const user = useUser();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedEngine = useSelector((state: RootState) => state.microengines.selected);
  const [open, setOpen] = useState<number | string>('');

  const _onToggleOpen = (id: number | string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const { formatMessage } = useIntl();
  const { classes } = useStyles();

  const onClickTag = (tag: string) => {
    history.replace(`${location.pathname}?tags=${tag}`);
  };

  const columns: IColumn<keyof MicroengineListing>[] = [
    {
      id: 'displayName',
      label: formatMessage({ id: 'table.row.name' }),
      sortable: true,
      width: 500,
      dataRenderer: ({ cellData, rowData }: IDataRenderer<MicroengineListing>) => (
        <span
          css={[classes.name, classes.link, rowData.selected && classes.selected]}
          data-cy={rowData.status}
          onClick={onClickEngine.bind(null, rowData)}>
          {cellData}
        </span>
      ),
    },
    {
      id: 'tags',
      label: formatMessage({ id: 'table.row.tags' }),
      width: 2000,
      dataRenderer: ({ cellData }: IDataRenderer<MicroengineListing>) => {
        return <TagsList tags={cellData} limit={7} onClick={onClickTag} />;
      },
    },
    {
      id: 'status',
      label: formatMessage({ id: 'table.row.status' }),
      dataRenderer: ({ rowData }: IDataRenderer<MicroengineListing>) => {
        const statusData = statusesConfig[rowData.status];
        if (statusData.tooltip)
          return (
            <Tooltip title={formatMessage(statusData.tooltip)} placement='top'>
              <TagStatus label={rowData.status} tagConfig={statusData} />
            </Tooltip>
          );
        return <TagStatus label={rowData.status} tagConfig={statusData} />;
      },
    },
    {
      id: 'actions',
      label: formatMessage({ id: 'table.head.actions' }),
      align: EColumnAlign.RIGHT,
      dataRenderer: ({ rowData }: IDataRenderer<MicroengineListing>) => {
        const status = rowData.status;
        const disabled = status === EngineStatuses.DISABLED;

        return canManageMicroengine(user) ? (
          <React.Fragment>
            <ContextMenu
              testId='myMicroenginesMenuBtn'
              options={[
                {
                  isShown: disabled,
                  label: formatMessage(btnMessages.edit),
                  type: 'primary',
                  onClick: () =>
                    updateUserMicroengineModal(rowData.id, {
                      engineId: rowData.engineId,
                      accountNumber: rowData.accountNumber,
                      address: rowData.address || '',
                      name: rowData.name,
                      displayName: rowData.displayName,
                      tags: rowData.tags,
                      vendorWebsite: rowData.vendorWebsite || '',
                      description: rowData.description || '',
                      engineType: rowData.engineType,
                      artifactTypes: rowData.artifactTypes,
                      mimetypes: rowData.mimetypes || [],
                      maxFileSize: rowData.maxFileSize,
                      webhookId: rowData.webhookId || null,
                    }),
                  testId: 'myMicroenginesEdit',
                },
                {
                  isShown: !disabled,
                  label: formatMessage(btnMessages.disable),
                  type: 'primary',
                  onClick: () =>
                    dispatch(
                      updateUserMicroengine(
                        rowData.id,
                        { status: EngineStatuses.DISABLED },
                        rowData.accountNumber
                      )
                    ),
                  testId: 'myMicroenginesDisable',
                },
                {
                  isShown: disabled,
                  label: formatMessage(btnMessages.test),
                  type: 'primary',
                  onClick: () =>
                    dispatch(
                      updateUserMicroengine(
                        rowData.id,
                        {
                          status: EngineStatuses.DEVELOPMENT,
                        },
                        rowData.accountNumber
                      )
                    ),
                  testId: 'myMicroenginesTest',
                },
                {
                  isShown: status === EngineStatuses.DEVELOPMENT,
                  label: formatMessage(btnMessages.verify),
                  type: 'primary',
                  onClick: () => {
                    dispatch(
                      updateUserMicroengine(
                        rowData.id,
                        {
                          status: EngineStatuses.VERIFICATION,
                        },
                        rowData.accountNumber
                      )
                    );
                    dispatch(
                      showNotification({
                        status: 'success',
                        delay: 10000,
                        message: statusesConfig.verification.notification,
                      })
                    );
                  },
                  testId: 'myMicroenginesVerify',
                },
                {
                  label: formatMessage(btnMessages.delete),
                  type: 'danger',
                  onClick: () => {
                    return archiveUserMicroengine(rowData.id, rowData.displayName, rowData.accountNumber);
                  },
                  testId: 'myMicroenginesDelete',
                },
              ]}
            />
            <ToggleButton open={open === rowData.id} onClick={() => _onToggleOpen(rowData.id)} />
          </React.Fragment>
        ) : null;
      },
    },
  ];

  const rowStyle = ({ index }: { index: number }) => ({});

  const rowRenderer = <T extends HeaderRow>({
    key,
    columns,
    index,
    onRowClick,
    rowData,
  }: IRowRender<T>) => {
    if (rowData.type === 'header') {
      return (
        <TableRow key={key} hover>
          <TableCell component='div' variant='head' style={tableStyles.cell}>
            {rowData.title}
          </TableCell>
        </TableRow>
      );
    }

    const _onClick = (event: any) => {
      if (onRowClick) {
        onRowClick({ event, index, rowData });
      }
    };

    return (
      <React.Fragment>
        <FlexTableRow key={key} onClick={_onClick} hover>
          {columns}
        </FlexTableRow>
        {open === rowData.id && (
          <DevelopmentResultsContainer>
            <DevelopmentResults engine={rowData.id} community={rowData.developmentCommunity} />
          </DevelopmentResultsContainer>
        )}
      </React.Fragment>
    );
  };

  return (
    <TableWrapper>
      <InfiniteScrollTable<MicroengineListing & HeaderRow>
        columns={columns}
        data={microengines.results.map((microengine) => ({
          ...microengine,
          selected: selectedEngine && selectedEngine.id === microengine.id,
        }))}
        sort={sort}
        onSort={onSort}
        loadMoreTreshold={5}
        onLoadMore={onLoadMore}
        hasMore={microengines.next_page}
        rowStyle={rowStyle}
        rowRenderer={rowRenderer}
        button={
          canManageMicroengine(user) ? (
            <FabButton icon='plus' onClick={createUserMicroengine} testId='addMicroengineBtn' />
          ) : undefined
        }
      />
    </TableWrapper>
  );
};

const statusesConfig: ITagStatusConfig = {
  disabled: { background: styles.color.medGrey, font: styles.color.white },
  development: { background: styles.color.medGrey, font: styles.color.medGrey, bordered: true },
  verification: {
    background: styles.color.lightPurple,
    font: styles.color.medGrey,
    tooltip: messages.verificationStatus,
    notification: messages.verificationMessage,
  },
  verified: { background: styles.color.purple, font: styles.color.white },
  failed: { background: styles.color.darkRed, font: styles.color.white },
};

const useStyles = makeStyles({
  base: {
    name: {
      fontWeight: styles.font.weight.bold,
      color: styles.color.black,
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
    communities: {
      color: styles.color.purple,
      fontWeight: styles.font.weight.medium,
      paddingLeft: '2rem',
    },
    label: {
      fontSize: '13px',
      textTransform: 'capitalize',
      backgroundColor: '#E0E0E0',
      borderRadius: '2rem',
      padding: '0.8rem 1.5rem',
    },
  },
  light: {
    status: {
      color: `${styles.color.xLightGrey} !important`,
      backgroundColor: styles.color.offWhite,
    },
    link: {
      color: styles.color.black,
      '&[disabled]': {
        color: styles.color.xLightGrey,
      },
      '&:hover:not([disabled])': {
        color: styles.color.purple,
      },
    },
    selected: {
      color: styles.color.purple,
    },
  },
  dark: {
    status: {
      color: `${styles.color.xLightGrey} !important`,
      backgroundColor: styles.color.offWhite,
    },
    link: {
      color: styles.color.white,
      '&[disabled]': {
        color: styles.color.lightGrey,
      },
      '&:hover:not([disabled])': {
        color: styles.color.lightBlue,
      },
    },
    selected: {
      color: styles.color.lightBlue,
    },
  },
});

export default MyMicroenginesTable;
