import React, { useEffect, useState, useRef } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { onEnterKeyDown } from 'views/components/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import styles from 'views/styles';
import styled from '@emotion/styled';
import getUrlType from 'utils/isUrl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Tooltip } from '@material-ui/core';
import Icon from 'views/components/Icon';

type hashInputText = { [T: string]: string };

export interface IScanSearchInput {
  onlyUrl?: boolean;
  onlyHash?: boolean;
  disabled?: boolean;
  initialValue?: string;
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  onCancel?: () => void;
  messages?: hashInputText;
  onFileSelect?: (files: File) => void;
}

const messages = {
  input: 'Scan URL or QR code or Search for file hash. ',
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  margin-top: 2rem;
`;

const InputWrapper = styled.div`
  display: inline-flex;
  border: 3px solid ${styles.color.purple};
  border-radius: 50px;
  padding: 10px;
  flex: 1 1 auto;
  position: relative;
`;

const Input = styled.input`
  line-height: 3.2rem;
  font-size: 1.8rem;
  width: 100%;
  outline: none;
  margin-left: 18px;
  margin-right: 18px;

  &::placeholder {
    color: ${styles.color.greyishPurple};
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  color: ${styles.color.purple};
  font-size: 2rem;
  cursor: pointer;
  margin: 4px 8px;
`;

const sha256Regex = new RegExp(/^[a-f0-9]{64}$/i);

const ScanSearchInput = ({
  onlyHash = false,
  onlyUrl = false,
  disabled,
  initialValue,
  onSubmit,
  onChange,
  messages: messagesProp,
  onFileSelect,
}: IScanSearchInput) => {
  const { classes } = useStyles();
  const [value, setValue] = useState(initialValue || '');

  const inputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    onChange && onChange(e.currentTarget.value);
  };

  const _handleSearch = () => {
    if (!value) {
      inputRef.current && inputRef.current.focus();
    } else if (onSubmit) {
      onSubmit(value);
    }
  };

  const msg = { ...messages, ...(messagesProp ?? {}) };

  const isValidSha256 = onlyHash && sha256Regex.test(value);

  const isValidURL = onlyUrl && !!getUrlType(value);

  const errorMessage = (() => {
    if (onlyHash && !!value && !isValidSha256) {
      return 'Invalid Hash';
    }

    if (onlyUrl && !isEmpty(value?.trim()) && !isValidURL) {
      return 'Invalid URL. Make sure the protocol "https://" or "http://" is set.';
    }

    return undefined;
  })();

  return (
    <div>
      <Wrapper>
        <InputWrapper css={classes.inputWrapper}>
          <Input
            data-testid='scanSearchInput'
            placeholder={msg.input}
            onKeyDown={onEnterKeyDown(_handleSearch)}
            onChange={_handleChange}
            value={value}
            ref={inputRef}
            disabled={disabled}
            css={classes.input}
          />
          <input
            ref={fileInputRef}
            type='file'
            accept='.jpg,.jpeg,.png,.gif'
            style={{ display: 'none' }}
            onChange={(e) =>
              onFileSelect && e.target.files?.[0] && onFileSelect(e.target.files?.[0])
            }
          />
          {!(onlyHash || onlyUrl) ? (
            <Tooltip
              placement='top'
              title='Scan QR code image file. Only QR Codes with URLs are supported.'
            >
              <button
                css={classes.qrcodeIcon}
                onClick={() => {
                  fileInputRef.current && fileInputRef.current.click();
                }}
                data-cy='scanSearchSubmit'
              >
                <Icon name='qr-code' title='QR Code' />
              </button>
            </Tooltip>
          ) : null}
          {!onChange && (
            <SearchIcon
              data-testid='scanSearchBtn'
              icon={faSearch}
              css={classes.searchIcon}
              onClick={_handleSearch}
            />
          )}
        </InputWrapper>
      </Wrapper>
      {(onlyHash || onlyUrl) && (
        <p css={classes.errorMessage} className={'p h-mt-tiny'}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    errorMessage: {
      color: styles.color.lightRed,
    },
    inputWrapper: {
      borderColor: styles.color.purple,
    },
    searchIcon: {
      color: styles.color.purple,
    },
    qrcodeIcon: {
      color: styles.color.purple,
      cursor: 'pointer',
      fontSize: '0.8rem',
    },
    input: {
      color: styles.color.black,
      '::placeholder': {
        color: styles.color.lightGrey,
      },
    },
  },
  light: {
    inputWrapper: {
      borderColor: styles.color.purple,
    },
    searchIcon: {
      color: styles.color.purple,
    },
    input: {
      color: styles.color.black,
      '::placeholder': {
        color: styles.color.lightGrey,
      },
    },
  },
  dark: {
    inputWrapper: {
      borderColor: styles.color.lightBlue,
    },
    searchIcon: {
      color: styles.color.lightBlue,
    },
    input: {
      color: styles.color.white,
      '::placeholder': {
        color: styles.color.xxLightGrey,
      },
    },
  },
});
export default ScanSearchInput;
