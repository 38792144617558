import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-clockface' viewBox='0 0 17 15'>
      <path
        d='M15.1646 7.5C15.1646 11.1517 12.0913 14.1612 8.2384 14.1612C4.38547 14.1612 1.3122 11.1517 1.3122 7.5C1.3122 3.84835 4.38547 0.838816 8.2384 0.838816C12.0913 0.838816 15.1646 3.84835 15.1646 7.5Z'
        fill='transparent'
        strokeWidth='1.67763'
      />
      <path
        d='M8.23785 3.74951V8.2232L11.1327 9.90083'
        fill='transparent'
        strokeLinecap='round'
        strokeWidth='1.67763'
      />
    </symbol>

    <Global
      styles={css`
        .icon-clockface {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
