import { MicroenginesState } from '.';
import { MicroenginesAction, MicroenginesActionName } from './types';
import { createInitialPageTimeSeries, buildPageTimeData } from 'utils/timeSeries';
import { PublicMicroengineListing, MicroengineListing } from 'models/Microengine';

export const initialAllMicroengines = createInitialPageTimeSeries<PublicMicroengineListing>({
  orderBy: 'name',
  results: [],
});

export const initialUserMicroengines = createInitialPageTimeSeries<MicroengineListing>({
  orderBy: 'id',
  results: [],
});

export const initialState: MicroenginesState = {
  all: initialAllMicroengines,
  user: initialUserMicroengines,
  single: null,
  selected: null,
};

const microengines = (
  state: MicroenginesState = initialState,
  action: MicroenginesAction
): MicroenginesState => {
  switch (action.type) {
    case MicroenginesActionName.SELECT_MICROENGINE: {
      return {
        ...state,
        selected: action.microengine,
      };
    }

    case MicroenginesActionName.REFRESH_ALL_MICROENGINES: {
      if (!action.result) {
        return state;
      }
      const all = buildPageTimeData(action.result, action.result.results);
      return { ...state, all };
    }

    case MicroenginesActionName.GET_ALL_MICROENGINES: {
      if (!action.result) {
        return state;
      }
      const result = buildPageTimeData(action.result, action.result.results);
      return {
        ...state,
        all: {
          ...result,
          results: [
            ...state.all.results.filter(
              ({ id: currentId }) => !result.results.map(({ id }) => id).includes(currentId)
            ),
            ...result.results,
          ],
        },
      };
    }

    case MicroenginesActionName.REFRESH_USER_MICROENGINES: {
      if (!action.result) {
        return state;
      }
      const user = buildPageTimeData(action.result, action.result.results);
      return { ...state, user };
    }

    case MicroenginesActionName.GET_USER_MICROENGINES: {
      if (!action.result) {
        return state;
      }
      const result = buildPageTimeData(action.result, action.result.results);
      return {
        ...state,
        user: {
          ...result,
          results: [...state.user.results, ...result.results],
        },
      };
    }

    case MicroenginesActionName.REFRESH_MICROENGINE: /* fallthrough */
    case MicroenginesActionName.GET_MICROENGINE: {
      return {
        ...state,
        single: action.result ? action.result : null,
      };
    }

    default: {
      return state;
    }
  }
};

export default microengines;
