import React, { useRef, useContext, useState, useMemo, useEffect } from 'react';
import { css, jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { saveAs } from 'file-saver';
import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Tree from 'rc-tree';
import api from 'services/api';
import Icon from 'views/components/Icon';
import {
  Sandbox,
  SandboxReport,
  SandboxArtifact,
  SandboxInstanceStatus,
  SandboxArtifactTypes,
} from 'models/Sandbox';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { showNotification } from 'state/notification/actions';
import { GlobalDownloadProgressContext } from 'views/components/providers/GlobalDownloadProvider';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import { downloadContent, zipArchive } from 'utils/files';
import styles from 'views/styles';
import 'rc-tree/assets/index.css';

interface IArtifactDownloadTab {
  sandboxedArtifactId: string;
  sha256: string;
  status: SandboxInstanceStatus;
  data: Sandbox<SandboxReport>['sandboxArtifacts'];
}

interface DataNode {
  title?: React.ReactNode;
  key: string | number;
  children?: DataNode[];
}

const Container = styled.div`
  margin: 32px 24px;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 18px;
`;

const TreeContainer = styled.div`
  margin-bottom: 200px;
`;

const treeStyling = css`
  div.rc-tree-list > div > div > div {
    gap: 14px;
  }
  div.rc-tree-list > div > div > div > div > span.rc-tree-checkbox {
    display: none;
  }
  div.rc-tree-list
    > div
    > div
    > div
    > div
    > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open {
    display: inline-flex;
    gap: 14px;
  }
  div.rc-tree-list
    > div
    > div
    > div
    > div
    > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-normal {
    display: inline-flex;
    gap: 14px;
  }
  div.rc-tree-list
    > div
    > div
    > div
    > div.rc-tree-treenode.rc-tree-treenode-switcher-close
    > span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-close {
    display: inline-flex;
    gap: 14px;
  }
`;
const ReportItem = ({
  onDownload,
  name,
  instanceId,
}: {
  onDownload: (event: React.MouseEvent<HTMLDivElement>) => void;
  name: string;
  instanceId: string;
}) => (
  <div style={{ display: 'flex', gap: '1rem' }}>
    <span>{`${instanceId} - ${formatFilename(name)}`}</span>
    <div onClick={onDownload} style={{ color: styles.color.purple, fontSize: '0.8rem' }}>
      <Icon name='download' className='icon-download' />
    </div>
  </div>
);

const DroppedHeader = ({ name }: { name: string }) => (
  <div style={{ display: 'flex', gap: '1rem' }}>
    <span>{name}</span>
    <Tooltip title='Executables & PDFs only available for download.' placement='top'>
      <span>
        <Icon style={{ fontSize: '0.5rem' }} name='info' title='dropped info' />
      </span>
    </Tooltip>
  </div>
);

const formatFilename = (rawFilename: string) => rawFilename.split('_').join(' ');

const REPORT_TYPES = ['raw_report', 'report'];

const onDownload = ({
  downloadCount,
  selectArtifactIds,
  selectedArtifactIds,
  previousDownloadList,
  dispatchDownloadProgress,
  reduxDispatch: dispatch,
  isZipped,
  sha256,
  sandboxedArtifactId,
  artifacts,
}: {
  downloadCount: { current: number };
  selectArtifactIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedArtifactIds: string[];
  previousDownloadList: { current: string[] };
  dispatchDownloadProgress: React.Dispatch<unknown>;
  reduxDispatch: React.Dispatch<unknown>;
  isZipped: boolean;
  sha256: string;
  sandboxedArtifactId: string;
  artifacts: Sandbox<SandboxReport>['sandboxArtifacts'];
}) => {
  downloadCount.current += 1;

  if (!Boolean(previousDownloadList.current.length)) {
    previousDownloadList.current = selectedArtifactIds;
  }
  selectArtifactIds(() => []);

  const filename = isZipped
    ? `${sha256}_sandbox_[${downloadCount.current - 1}].zip`
    : `${previousDownloadList.current[0]}`;

  dispatchDownloadProgress({
    type: 'add',
    filename,
  });

  window.setTimeout(async () => {
    dispatchDownloadProgress({
      type: 'progress',
      filename,
    });

    let url, artifact;

    try {
      const downloadArtifactFunctionArray = previousDownloadList.current.map(
        (id: string) => async () => {
          let file;
          if (id === sha256) {
            file = { name: sha256, type: 'fileSandboxed' };

            url = await api
              .downloadSandboxArtifact(sandboxedArtifactId)
              .then((res) => res.data.url)
              .catch((error) => error);
          } else {
            file = artifacts.find((item: SandboxArtifact) => item.instanceId === id) ?? {
              name: 'unknown',
              type: 'unknown',
            };

            url = await api
              .downloadSandboxArtifact(id)
              .then((res) => res.data.url)
              .catch((error) => error);
          }

          if (typeof url === 'object' && url?.toJSON()?.name === 'Error') {
            // @ts-ignore
            throw new Error(url, { cause: `${id}_${file.name}` });
          }

          try {
            artifact = await downloadContent(url);
          } catch (e) {
            // @ts-ignore
            throw new Error(e, { cause: `${id}_${file.name}` });
          }

          let filename;
          if (REPORT_TYPES.includes(file.type)) {
            filename = `${id}_${file.name}`;
          } else if (file.type === 'fileSandboxed') {
            filename = file.name;
          } else {
            filename = `${file.instanceId}_${file.name}`;
          }

          return { path: `${filename}`, buffer: artifact };
        }
      );

      if (isZipped) {
        const archive = await zipArchive(`${filename}`, downloadArtifactFunctionArray);
        archive.finalize();
      } else {
        const [file] = await Promise.all(downloadArtifactFunctionArray.map((f) => f()));
        // @ts-ignore
        saveAs(new Blob([file.buffer]), `${file.path}`);
      }

      dispatchDownloadProgress({ type: 'completed', filename });

      if (isZipped) {
        dispatch(
          // @ts-ignore
          showNotification({
            status: 'info',
            message:
              'Downloading potentially malicious files is risky. To lessen your risk, we’ve enclosed this artifact in an AES encrypted .zip file with password "infected".',
            delay: 45000,
            isDismissible: true,
          })
        );
      } else {
        dispatch(
          // @ts-ignore
          showNotification({
            status: 'info',
            message: 'Downloading potentially malicious files is risky. Please be cautious.',
            delay: 45000,
            isDismissible: true,
          })
        );
      }
    } catch (error: any) {
      dispatchDownloadProgress({ type: 'error', filename });

      // @ts-ignore
      const message = (
        <div>
          <div>{(error as { [key: string]: string }).message} - file instance:</div>
          <div>{(error as { [key: string]: string }).cause}</div>
        </div>
      );

      dispatch(
        // @ts-ignore
        showNotification({
          status: 'failure',
          delay: 10000,
          message,
        })
      );
    }
  }, 4000);
};

const ArtifactDownloadTab = ({
  sandboxedArtifactId,
  sha256,
  data: artifacts,
  status,
}: IArtifactDownloadTab) => {
  const { dispatch: dispatchDownloadProgress } = useContext(GlobalDownloadProgressContext);
  const [selectedArtifactIds, selectArtifactIds] = useState([] as string[]);
  const [unselectedArtifactIds, unselectArtifactIds] = useState([] as string[]);
  const [expandedKeys, setExpandedKeys] = useState([] as string[]);
  const [isZipped, setZip] = useState(true);
  const dispatch = useDispatch();
  const downloadCount = useRef(0);
  const previousDownloadList = useRef([] as string[]);

  const groupedData: { [key: string]: typeof artifacts } = useMemo(
    () => groupBy(artifacts, 'type'),
    [artifacts]
  );
  const artifactTypes = useMemo(
    () =>
      Object.keys(groupedData as { [key: string]: (typeof artifacts)[0][] }).concat(
        'JSON Report',
        'Sandbox Artifact'
      ),
    [groupedData]
  );

  const allArtifactInstanceIds = useMemo(
    () => [sha256].concat(artifacts?.map((item) => item.instanceId)).filter(Boolean),
    [artifacts, sha256]
  );

  useEffect(() => setExpandedKeys(artifactTypes), [artifactTypes]);

  const treeData = useMemo(() => {
    const reducedData = reduce(
      groupedData,
      (acc, val, key) => {
        if (REPORT_TYPES.includes(key)) {
          const jsonReportFound = acc.find((item) => item.key === 'JSON Report');

          if (jsonReportFound) {
            return acc.map((item) => {
              if (item.key === 'JSON Report') {
                return {
                  ...item,
                  children: (item.children ?? []).concat(
                    val.map((item) => ({
                      key: `${item.instanceId}`,
                      title: (
                        <ReportItem
                          onDownload={(e) => {
                            e.stopPropagation();
                            onDownload({
                              downloadCount,
                              selectArtifactIds,
                              selectedArtifactIds: [item.instanceId],
                              previousDownloadList,
                              dispatchDownloadProgress,
                              reduxDispatch: dispatch,
                              isZipped: false,
                              sha256,
                              sandboxedArtifactId,
                              artifacts,
                            });
                            previousDownloadList.current = [item.instanceId];
                          }}
                          name={item.name}
                          instanceId={item.instanceId}
                        />
                      ),
                    }))
                  ),
                };
              }

              return item;
            });
          }

          return acc.concat({
            key: 'JSON Report',
            title: 'JSON Report',
            children: ([] as DataNode[]).concat(
              val.map((item) => ({
                key: `${item.instanceId}`,
                title: (
                  <ReportItem
                    onDownload={(e) => {
                      e.stopPropagation();
                      onDownload({
                        downloadCount,
                        selectArtifactIds,
                        selectedArtifactIds: [item.instanceId],
                        previousDownloadList,
                        dispatchDownloadProgress,
                        reduxDispatch: dispatch,
                        isZipped: false,
                        sha256,
                        sandboxedArtifactId,
                        artifacts,
                      });
                      previousDownloadList.current = [item.instanceId];
                    }}
                    name={item.name}
                    instanceId={item.instanceId}
                  />
                ),
              }))
            ),
          });
        }

        if (SandboxArtifactTypes.DROPPED_FILE === key) {
          return acc.concat({
            key,
            title: <DroppedHeader name={capitalize(formatFilename(key))} />,
            children: val.map((item) => ({
              key: `${item.instanceId}`,
              title: `${item.instanceId} - ${item.name}`,
            })),
          });
        }

        return acc.concat({
          key,
          title: capitalize(formatFilename(key)),
          children: val.map((item) => ({
            key: `${item.instanceId}`,
            title: `${item.instanceId} - ${item.name}`,
          })),
        });
      },
      [] as DataNode[]
    );

    return [
      {
        key: 'Sandbox Artifact',
        title: 'Sandbox Artifact',
        children: [
          {
            key: sha256,
            title: sha256,
          },
        ],
      } as { [key: string]: any },
    ].concat(reducedData);
  }, [dispatch, dispatchDownloadProgress, artifacts, sandboxedArtifactId, groupedData, sha256]);

  const onSelectAll = () => {
    previousDownloadList.current = [];
    selectArtifactIds(allArtifactInstanceIds);
  };

  const onDeselectAll = () => {
    previousDownloadList.current = [];
    selectArtifactIds([]);
  };

  const droppedFilesInstanceIds = artifacts
    .filter((item) => (item.type as SandboxArtifactTypes) === SandboxArtifactTypes.DROPPED_FILE)
    .map((item) => item.instanceId);

  const onCheck = (selectedData: string[], selectedObject: unknown) => {
    const unchecked = allArtifactInstanceIds.filter((item) => selectedData.includes(item));
    unselectArtifactIds(unchecked);
    const dataFiltered = (selectedData as string[]).filter((item) => !artifactTypes.includes(item));

    const hasDroppedFile = dataFiltered.some((item) => droppedFilesInstanceIds.includes(item));

    if (Boolean(previousDownloadList.current.length)) {
      previousDownloadList.current = [];
    }
    selectArtifactIds(dataFiltered);

    if (hasDroppedFile) {
      setZip(true);
    }

    if (!isZipped && hasDroppedFile) {
      setZip(true);
    }

    if (!isZipped && dataFiltered.length > 1) {
      setZip(true);
    }
  };

  if (
    !!treeData &&
    [SandboxInstanceStatus.PENDING, SandboxInstanceStatus.STARTED].includes(status)
  ) {
    return <PanelLoader />;
  }

  const onExpandAll = () => {
    setExpandedKeys(artifactTypes);
  };

  const onCollapseAll = () => {
    setExpandedKeys([]);
  };

  const onExpand = (keys: string[]) => {
    setExpandedKeys(keys);
  };

  const extraTreeProps = Object.assign({
    expandedKeys,
    checkedKeys: {
      checked: selectedArtifactIds,
      unchecked: unselectedArtifactIds,
    },
  });

  const hasDroppedFileSelected = selectedArtifactIds.some((instanceId) =>
    droppedFilesInstanceIds.includes(instanceId)
  );

  return (
    <Container>
      {!!treeData.length ? (
        <>
          <Header>
            <ActionsContainer>
              <Button onClick={onSelectAll} size='small' color='primary'>
                Select All
              </Button>
              <Button onClick={onDeselectAll} size='small' color='primary'>
                Deselect All
              </Button>
            </ActionsContainer>
            <ActionsContainer>
              <Button onClick={onExpandAll} size='small' color='primary'>
                Expand All
              </Button>
              <Button onClick={onCollapseAll} size='small' color='primary'>
                Collapse All
              </Button>
            </ActionsContainer>
          </Header>
          <TreeContainer>
            <Tree
              css={treeStyling}
              onExpand={onExpand}
              checkable
              selectable={false}
              onCheck={onCheck}
              treeData={treeData}
              icon={(obj) => {
                if (obj.checked) {
                  return <Icon name='checkbox-checked' />;
                }

                if (obj.halfChecked && artifactTypes.includes((obj?.data?.key ?? '') as string)) {
                  return <Icon name='checkbox-minus-checked' />;
                }

                return <Icon name='checkbox-empty' />;
              }}
              switcherIcon={(obj) => {
                if (!obj.isLeaf) {
                  return obj.expanded ? <Icon name='toggle-close' /> : <Icon name='toggle-open' />;
                }
                return null;
              }}
              {...extraTreeProps}
            />
          </TreeContainer>
          <hr />
          <Footer>
            <div style={{ display: 'flex', gap: 8 }}>
              <div style={{ marginRight: 8 }}>
                <Checkbox
                  disabled={selectedArtifactIds.length > 1 || hasDroppedFileSelected}
                  color='primary'
                  size='small'
                  onChange={() => setZip(!isZipped)}
                  checked={selectedArtifactIds.length > 1 || isZipped || hasDroppedFileSelected}
                  inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                />{' '}
                Zip
              </div>
              <div style={{ fontSize: 7, alignSelf: 'center' }}>
                <Tooltip
                  placement='top'
                  title='This download will be automatically zipped if more than one file is selected.'
                >
                  <span>
                    <Icon name='info' />
                  </span>
                </Tooltip>
              </div>
            </div>
          </Footer>
          <ButtonContainer>
            <Button
              disabled={selectedArtifactIds.length === 0}
              onClick={() =>
                onDownload({
                  downloadCount,
                  selectArtifactIds,
                  selectedArtifactIds,
                  previousDownloadList,
                  dispatchDownloadProgress,
                  reduxDispatch: dispatch,
                  isZipped,
                  sha256,
                  sandboxedArtifactId,
                  artifacts,
                })
              }
              size='medium'
              variant='contained'
              color='primary'
            >
              Download
            </Button>
          </ButtonContainer>
        </>
      ) : (
        <div>No Artifacts</div>
      )}
    </Container>
  );
};
export default ArtifactDownloadTab;
