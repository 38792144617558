// @ts-nocheck
import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import JSONViewer from 'searchable-react-json-view';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import styled from '@emotion/styled';

interface IJsonViewer {
  data: any;
  collapsed: number | boolean;
  highlightSearch?: string;
  collapseStringsAfterLength: number;
  maxHeight?: string;
}

const JSONContainer = styled.div<{ isDarkTheme: boolean; maxHeight: string }>`
  background-color: ${({ isDarkTheme }) =>
    isDarkTheme ? styles.color.xxDarkPurple : styles.color.xLightPurple};
  padding: 3rem;
  border-radius: 1.5rem;
  max-height: ${({ maxHeight }) => maxHeight || '70vh'};
  overflow: scroll;

  & .react-json-view {
    background-color: transparent !important;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
`;

class PSJsonViewer extends JSONViewer {
  componentDidUpdate(prevProps: any) {
    if (prevProps.collapseStringsAfterLength !== this.props.collapseStringsAfterLength) {
      this.rjvId = Date.now().toString();
      this.setState({
        test: Date.now().toString(),
      });
    }
  }
}

export const JsonViewer = ({
  data,
  collapsed,
  highlightSearch = '',
  collapseStringsAfterLength,
  maxHeight,
}: IJsonViewer) => {
  const { theme } = useStyles();
  const isDarkTheme = theme === 'dark';
  return (
    <JSONContainer className='ps-json-view' maxHeight={maxHeight} isDarkTheme={isDarkTheme}>
      <PSJsonViewer
        src={data}
        name={false}
        collapsed={collapsed}
        enableClipboard={false}
        displayDataTypes={false}
        displayObjectSize={false}
        collapseStringsAfterLength={collapseStringsAfterLength}
        theme={isDarkTheme ? 'monokai' : 'rjv-default'}
        highlightSearch={highlightSearch || ''}
        highlightSearchColor={isDarkTheme ? styles.color.blue : styles.color.lightPurple}
      />
    </JSONContainer>
  );
};

const useStyles = makeStyles({
  base: {
    container: {},
  },
  light: {},
  dark: {},
});
