import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { closeModal } from 'state/modal/actions';
import { formSchema, formMessages } from 'utils/schema/formSchema';
import { openModal } from 'state/modal/actions';
import TextField from '@material-ui/core/TextField';
import styles from 'views/styles';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import config from 'config';

export interface ConfigureWithdrawalModalParams {
  address: string;
  limit: string;
  onSuccess: (params: { limit: string | null; address: string | null }) => void;
}

const messages = defineMessages({
  addressPlaceholder: {
    id: 'rewardsTab.configureWithdrawal.modal.addressPlaceholder',
    defaultMessage: 'Withdrawal Address',
  },
  limitPlaceholder: {
    id: 'rewardsTab.configureWithdrawalLimit.modal.limitPlaceholder',
    defaultMessage: 'Daily Withdrawal Limit amount in NCT',
  },
  button: {
    id: 'rewardsTab.configureWithdrawal.modal.button',
    defaultMessage: 'Save',
  },
});

const ConfigureWithdrawalAddress = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading] = useState(false);
  const [error] = useState<string | null | undefined>(null);

  const { address: currentAddress, limit: currentLimit, onSuccess } = useSelector(
    ({ modal }: { modal: ModalState<ConfigureWithdrawalModalParams> }) => modal.params
  );
  const [address, setAddress] = useState<string | null>(currentAddress);
  const [addressError, setAddressError] = useState<string | null>(null);
  const [limit, setLimit] = useState<string | null>(currentLimit);
  const { ethAddress } = formSchema(intl);
  const disabled = !!addressError || !address?.length || !limit?.length;

  useEffect(() => {
    async function validate() {
      const isValid = await ethAddress.isValid(address);
      if (isValid) {
        setAddressError(null);
      } else {
        setAddressError(intl.formatMessage(formMessages.invalidEthAddress));
      }
    }
    if (address && address.length > 0) {
      validate();
    } else {
      setAddressError(null);
    }
  }, [address, ethAddress, intl]);

  return (
    <ConfirmModal
      testId='configureWithdrawalModal'
      isLoading={loading}
      css={ownStyle}
      fullWidth={true}
      maxWidth='md'
      maxContentWidth='70rem'
      content={
        <div css={containerStyle}>
          <TextField
            className='field'
            onChange={(e) => setAddress(e.target.value)}
            fullWidth={true}
            defaultValue={currentAddress}
            error={!!addressError}
            helperText={addressError || 'e.g. 0xc0ffee254729296a45a3885639AC7E10F9d54979'}
            label={intl.formatMessage(messages.addressPlaceholder)}
          />
          <TextField
            className='field'
            onChange={(e) => setLimit(e.target.value)}
            fullWidth={true}
            defaultValue={currentLimit}
            label={intl.formatMessage(messages.limitPlaceholder)}
          />
          <span className='error'>{error}</span>
        </div>
      }
      submitBtnText={intl.formatMessage(messages.button)}
      disabled={disabled}
      buttonsDirection='vertical'
      onSubmit={() => onSuccess({ limit, address })}
      onCancel={() => dispatch(closeModal())}
      timeout={parseInt(config.tokenExpirationSec, 10)}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
      }}
    />
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md} 0;
  width: 70rem;
  margin: 0 auto;
`;

const containerStyle = css`
  & .field {
    margin-bottom: 3rem;
  }

  & .error {
    display: block;
    font-size: 1.5rem;
    color: ${styles.color.red};
  }
`;

export default ConfigureWithdrawalAddress;
