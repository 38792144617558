export const excerpt = (input: string, length = 40) =>
  input.length > length ? `${input.substring(0, length).trim()}…` : input;

export const toCamelCase = (name: string) =>
  name
    .split(/[\W_]/)
    .map((slug, index) =>
      index === 0 ? slug.toLowerCase() : slug.charAt(0).toUpperCase() + slug.slice(1)
    )
    .join('');

export const camelCaseToWords = (camelCaseString: string) => {
  const words = camelCaseString.replace(/([A-Z])/g, ' $1');
  const capitalizedWords = words.charAt(0).toUpperCase() + words.slice(1);
  return capitalizedWords.trim();
};
