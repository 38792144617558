import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-download' viewBox='0 0 32 32'>
      <path d='M17.284 10.878h-2.667v6.667h-2.667l4 4 4-4h-2.667v-6.667zM29.471 15.051c0.121-0.534 0.183-1.079 0.187-1.627-0.015-4.356-3.55-7.88-7.907-7.88-0.913 0.003-1.817 0.175-2.667 0.507-0.351-0.275-0.721-0.524-1.107-0.747-4.67-2.688-10.634-1.089-13.333 3.573-1 1.679-1.465 3.623-1.333 5.573-2.595 1.512-3.858 4.572-3.086 7.474s3.39 4.93 6.393 4.952h18.667c2.812-0.015 5.312-1.791 6.25-4.442s0.113-5.605-2.063-7.385v0zM25.284 24.211h-18.667c-1.909-0.047-3.518-1.438-3.842-3.319s0.726-3.73 2.509-4.414l1.040-0.387-0.2-1.093c-0.479-2.716 0.656-5.464 2.913-7.050s5.227-1.722 7.62-0.35c0.485 0.265 0.933 0.592 1.333 0.973l0.68 0.627 0.84-0.413c0.692-0.363 1.459-0.559 2.24-0.573 1.69-0.002 3.274 0.821 4.244 2.205s1.202 3.154 0.623 4.742l-0.373 1.053 0.96 0.547c1.265 0.692 2.060 2.011 2.080 3.453 0 2.209-1.791 4-4 4z' />
    </symbol>

    <Global
      styles={css`
        .icon-download {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
