// import React, { Fragment, useState } from 'react';
import React, { Fragment } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { TableCell } from '@material-ui/core';
import styled from '@emotion/styled';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { TeamActivityRow } from './types';
import { closeModal, openModal } from 'state/modal/actions';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import styles from 'views/styles';

interface ITeamActivityTableRow {
  rowData: TeamActivityRow;
  blur?: boolean;
}

const HoverableTableRow = styled(BaseHoverableTableRow)<{ blur?: boolean }>`
  filter: ${({ blur }) => (blur ? 'blur(4px)' : 'none')};
  user-select: ${({ blur }) => (blur ? 'none' : 'auto')};
  cursor: ${({ blur }) => (blur ? 'default' : 'pointer')};
`;

const TeamActivityTableRow = ({ rowData, blur = false }: ITeamActivityTableRow) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const timestamp = formatDate(parseDate(rowData?.timestamp), DATE_TIME_FORMAT);

  const viewMetadata = () =>
    dispatch(
      openModal('METADATA_MODAL', {
        onSubmit: () => {
          dispatch(closeModal());
        },
        jsonMetadata: rowData?.metadata,
        eventType: rowData?.eventType,
        timestamp,
      })
    );

  return (
    <Fragment>
      <HoverableTableRow
        blur={blur}
        renderCells={() => (
          <Fragment>
            <TableCell>{timestamp}</TableCell>
            <TableCell>{rowData?.eventType}</TableCell>
            <TableCell>{rowData?.user}</TableCell>
            <TableCell>
              <div onClick={blur ? undefined : viewMetadata} css={classes.metadata}>
                {rowData?.metadata.substring(0, 25) + '...}'}
              </div>
            </TableCell>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    metadata: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      textDecoration: 'underline',
    },
  },
  light: {
    metadata: {
      color: styles.color.purple,
    },
  },
  dark: {
    metadata: {
      color: styles.color.lightBlue,
    },
  },
});

export default TeamActivityTableRow;
