import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { btnMessages } from 'views/components/Button';
import { ProgressButton } from 'views/components/ProgressButton';

interface Props {
  className?: string;
  disabled?: boolean;
  submitBtnText: string;
  direction?: 'horizontal' | 'vertical';
  submitBtnColor?: ButtonProps['color'];
  submitBtnVariant?: ButtonProps['variant'];
  cancelBtnText?: string;
  cancelBtnColor?: ButtonProps['color'];
  cancelBtnVariant?: ButtonProps['variant'];
  onSubmit?: () => void;
  onReset?: () => void;
  onCancel?: () => void;
  timeout?: number;
  onTimeout?: () => void;
}

const FormButtonBar = ({
  className,
  disabled,
  submitBtnText,
  direction = 'horizontal',
  submitBtnColor,
  submitBtnVariant,
  cancelBtnText,
  cancelBtnColor,
  cancelBtnVariant,
  onSubmit,
  onReset,
  onCancel,
  timeout,
  onTimeout,
}: Props) => {
  return (
    <div className={className} css={direction === 'vertical' ? verticalStyle : horizontalStyle}>
      {timeout && onTimeout ? (
        <ProgressButton time={timeout} onTimeEnd={onTimeout} onClick={onSubmit}>
          {submitBtnText}
        </ProgressButton>
      ) : (
        <Button
          type={onSubmit ? 'button' : 'submit'}
          color={submitBtnColor || 'primary'}
          variant={submitBtnVariant || 'contained'}
          disabled={disabled}
          onClick={onSubmit}
          data-testid='submitBtn'
        >
          {submitBtnText}
        </Button>
      )}
      {onReset && (
        <Button color={'primary'} variant={'outlined'} onClick={onReset} data-testid='resetBtn'>
          <FormattedMessage {...btnMessages.reset} />
        </Button>
      )}
      {onCancel && (
        <Button
          color={cancelBtnColor || 'primary'}
          variant={cancelBtnVariant || 'outlined'}
          onClick={onCancel}
          data-testid='cancelBtn'
        >
          {cancelBtnText || <FormattedMessage {...btnMessages.cancel} />}
        </Button>
      )}
    </div>
  );
};

const horizontalStyle = css`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  grid-column-gap: 1fr;
  max-width: 60rem;
  margin: 0 auto;
  button {
    margin-left: 1rem;
    font-size: 1.7rem !important;
  }
`;

const verticalStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60rem;
  margin: 0 auto;
  button {
    margin-top: 1rem;
    min-width: 0 !important;
    width: 70%;
    font-size: 1.7rem !important;
  }
`;

export default FormButtonBar;
