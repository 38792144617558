import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { RootState } from 'state/root';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys } from 'utils/error';
import { AccountActionName } from 'state/account/types';
import { TeamActionName } from 'state/team/types';
import { leaveTeam } from 'state/team/actions';
import { closeModal, openModal } from 'state/modal/actions';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import Modal from 'views/components/Modal';
import { useUser } from 'views/components/providers/UserProvider';
import { SetupMfaPanel } from 'views/components/SetupMfaPanel/SetupMfaPanel';
import { hasMadeTeamMFARecently, loginWriteTeams } from 'state/auth/actions';

const messages = defineMessages({
  heading: {
    id: 'teams.modal.leaveTeamHeading',
    defaultMessage: 'Leave Team',
  },
  text: {
    id: 'teams.modal.leaveTeamText',
    defaultMessage: 'Are you sure you want to leave the team "{teamName}"?',
  },
  button: {
    id: 'teams.modal.leaveTeamBtn',
    defaultMessage: 'Leave Team',
  },
  onlyAdmin: {
    id: 'teams.modal.onlyAdminError',
    defaultMessage:
      'You are the only admin of this team. Please assign the admin role to someone else before leaving this team.',
  },
});

const errorKeys: ErrorKeys = {
  validation_error: messages.onlyAdmin.id,
};

const LeaveTeamModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const teamName = user?.context?.team?.name!;
  const { requests } = useSelector((state: RootState) => state);

  const isLoading = loadingSelector(requests, [
    TeamActionName.LEAVE_TEAM,
    AccountActionName.GET_ACCOUNT_CONTEXT,
    AccountActionName.SWITCH_ACCOUNT,
  ]);

  const error = errorSelector(requests, [
    TeamActionName.LEAVE_TEAM,
    AccountActionName.GET_ACCOUNT_CONTEXT,
    AccountActionName.SWITCH_ACCOUNT,
  ]);

  const _closeModal = () => dispatch(closeModal());

  if (!user.mfaEnabled)
    return (
      <Modal>
        <SetupMfaPanel onSetup={_closeModal} onCancel={_closeModal} />
      </Modal>
    );

  if (!hasMadeTeamMFARecently()) {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({
              modal: 'LEAVE_TEAM',
              location: location.pathname,
            })
          );
        },
      })
    );
  }

  return (
    <ConfirmModal
      testId='leaveTeamModal'
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text, { teamName })}
      submitBtnText={intl.formatMessage(messages.button)}
      onSubmit={() => dispatch(leaveTeam())}
      onCancel={_closeModal}
    />
  );
};

export default LeaveTeamModal;
