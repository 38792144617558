import { PageTimeSeries } from './TimeSeries';
import {
  PublicMicroengineListingRes,
  MicroengineListingRes,
  DevelopmentResultRes,
} from 'services/api/schema/microengines';

export interface MicroengineListing extends MicroengineListingRes {}

export type UserMicroengines = PageTimeSeries<MicroengineListing>;

export interface PublicMicroengineListing extends PublicMicroengineListingRes {}

export type AllMicroengines = PageTimeSeries<PublicMicroengineListing>;

export interface DevelopmentResult extends DevelopmentResultRes {}

export enum EMicroengineType {
  microengine = 'microengine',
  arbiter = 'arbiter',
}

export enum EngineStatuses {
  DISABLED = 'disabled',
  DEVELOPMENT = 'development',
  VERIFICATION = 'verification',
  VERIFIED = 'verified',
  FAILED = 'failed',
}

export interface MicroengineValues {
  engineId: string;
  name: string;
  displayName: string;
  address: string;
  description: string;
  vendorWebsite: string;
  tags: string[];
  engineType: keyof typeof EMicroengineType | null;
  artifactTypes: string[];
  accountNumber?: number;
  webhookId: string | null;
  mimetypes: string[];
  maxFileSize?: number | null;
  sizeType?: string | null;
  developmentCommunity?: string;
}

export interface UpdateMicroengineValues {
  engineId?: string;
  name?: string;
  address?: string;
  description?: string;
  vendorWebsite?: string;
  tags?: string[];
  engineType?: keyof typeof EMicroengineType | null;
  artifactTypes?: string[];
  accountNumber?: number;
  status?: EngineStatuses;
  webhookId?: string | null;
  mimetypes?: string[];
  maxFileSize?: number | null;
  sizeType?: string | null;
  developmentCommunity?: string;
}

export interface IClaimUserMicroengineValues {
  signature: string;
}
