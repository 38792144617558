import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import Logo from 'views/components/layout/Header/Logo';

interface Props {
  className?: string;
}

const SimpleHeader = ({ className }: Props) => (
  <header className={className} css={style.root}>
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <div css={style.content}>
            <Logo />
          </div>
        </div>
      </div>
    </div>
  </header>
);

const style = {
  root: css`
    padding: 1.8rem 0;
  `,
  content: css`
    display: flex;
    align-items: center;
  `,
};

export default SimpleHeader;
