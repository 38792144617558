import styled from '@emotion/styled';
import styles from 'views/styles';

export const ContainerSearch = styled.div<{ isDarkTheme: boolean }>`
  align-items: end;
  display: grid;
  grid-template-columns: 1fr auto 1fr auto auto;
  grid-column-gap: 1rem;

  & .icon-search {
    font-size: 0.8rem !important;
    padding-top: 0.8rem !important;
    cursor: pointer;
  }
  & .active {
    color: ${({ isDarkTheme }) => (isDarkTheme ? styles.color.lightBlue : styles.color.purple)};
  }
  & .icon-close {
    font-size: 0.5rem !important;
    cursor: pointer;
  }
`;

export const Item = styled.span`
  cursor: ${({ onClick }) => onClick && 'pointer'};
  display: inline-block;
  padding: 1.2rem 1rem;
  user-select: none;

  & .icon-arrow {
    font-size: 0.6rem !important;
    padding-top: 0.5rem !important;
    cursor: pointer;
  }

  & .arrow-up {
    transform: rotate(180deg);
    margin-top: 0.5rem;
    margin-right: -1.5rem;
  }

  & .icon-copy {
    width: 1.6em !important;
    height: 1.2em !important;
  }

  & .icon-download {
    font-size: 0.8rem;
    margin-top: -0.2rem;
  }

  span {
    font-size: 1em !important;
    font-weight: 500;
  }
`;

export const ItemContainer = styled.div<{ isDarkTheme: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 1rem;

  & span {
    color: ${({ isDarkTheme }) => (isDarkTheme ? styles.color.lightBlue : styles.color.purple)};
  }
`;
