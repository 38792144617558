export type HashRow = {
  hash: string;
};

export type HashResult = HashRow[];

export enum SearchEnum {
  Hash = 'Hash',
  IOC = 'IOC',
}

export enum IocEnum {
  IP = 'IP',
  URL = 'URL',
  MITRE_TTP = 'MITRE TTP',
  Imphash = 'Imphash',
}

export type SearchType = keyof typeof SearchEnum;
export type IocType = keyof typeof IocEnum;
export type IOCResult =
  | Record<string, string | string[]>
  | Array<{ title?: string; component: 'list' | 'table' | 'divider'; data: Record<string, any> }>;
