import React, { useState, Fragment } from 'react'; // eslint-disable-line
import { defineMessages, useIntl } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import { Hunt } from 'models/Ruleset';
import { Page } from 'models/Page';
import { EScanStatus } from 'models/Submission';
import { Row } from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import CustomTable from 'views/components/table/CustomTable';
import {
  CustomTablePagination,
  EPageDirection,
} from 'views/components/table/CustomTablePagination';
import { HistoricalInfoModalParams } from 'views/pages/HuntPage/modals/HistoricalInfoModal';
import { toggleHuntsType } from 'views/pages/HuntPage/types';
import HistoricalHuntRow from './HistoricalHuntRow';

type AnyHunt = Hunt & { active?: boolean };

interface IHistoricalHuntTable {
  hunts: Page<AnyHunt>;
  viewResults: (id: string) => void;
  viewRuleset: (id: string) => void;
  cancelHunt: (id: string) => void;
  deleteHunt: (id: string) => void;
  showHistoricalHuntInfo: (historicalParams: HistoricalInfoModalParams) => void;
  selectedHunts: string[];
  toggleHunts: toggleHuntsType;
  handleChangePage: (page: EPageDirection) => void;
  handleChangeRowsPerPage: (numRows: number) => void;
  isFirst: boolean;
}

const messages = defineMessages({
  results: {
    id: 'historicalHuntTable.row.results',
    defaultMessage: 'Results',
  },
  rulesetName: {
    id: 'historicalHuntTable.row.rulesetName',
    defaultMessage: 'Ruleset Name',
  },
  historicalHuntName: {
    id: 'historicalHuntTable.row.historicalHuntName',
    defaultMessage: 'Historical Hunt Name',
  },
  matches: {
    id: 'historicalHuntTable.row.matches',
    defaultMessage: 'Matches',
  },
  status: {
    id: 'historicalHuntTable.row.status',
    defaultMessage: 'Status',
  },
  created: {
    id: 'historicalHuntTable.row.created',
    defaultMessage: 'Created',
  },
  running: {
    id: 'historicalHuntTable.status.running',
    defaultMessage: 'Running',
  },
  success: {
    id: 'historicalHuntTable.status.success',
    defaultMessage: 'Success',
  },
  pending: {
    id: 'historicalHuntTable.status.pending',
    defaultMessage: 'Pending',
  },
  failed: {
    id: 'historicalHuntTable.status.failed',
    defaultMessage: 'Failed',
  },
  inactive: {
    id: 'historicalHuntTable.status.inactive',
    defaultMessage: 'Inactive',
  },
  unnamed: {
    id: 'historicalHuntTable.status.unnamed',
    defaultMessage: 'Unnamed',
  },
});

export const getHuntingStatusMessage = (status: EScanStatus, formatMessage: Function) => {
  switch (status) {
    case EScanStatus.RUNNING:
      return formatMessage(messages.running);
    case EScanStatus.SUCCESS:
      return formatMessage(messages.success);
    case EScanStatus.PENDING:
      return formatMessage(messages.pending);
    case EScanStatus.FAILED:
      return formatMessage(messages.failed);
    default:
      return formatMessage(messages.inactive);
  }
};

const HistoricalHuntTable = ({
  hunts,
  viewResults,
  viewRuleset,
  cancelHunt,
  deleteHunt,
  showHistoricalHuntInfo,
  toggleHunts,
  selectedHunts,
  handleChangePage,
  handleChangeRowsPerPage,
  isFirst,
}: IHistoricalHuntTable) => {
  const { formatMessage } = useIntl();
  const [isToggleOn, setToggleOn] = useState<boolean>(false);

  const finishedHunts = hunts.results.filter(
    (hunt) => hunt.status !== 'PENDING' && hunt.status !== 'RUNNING'
  );

  const rows: Row<AnyHunt>[] = [
    {
      id: 'id',
      numeric: false,
      label: formatMessage(messages.status),
      sortable: false,
    },
    {
      id: 'status',
      numeric: false,
      label: formatMessage(messages.status),
      sortable: false,
    },
    {
      id: 'rule_name',
      numeric: false,
      label: formatMessage(messages.historicalHuntName),
      sortable: false,
    },
    {
      id: 'detections',
      numeric: false,
      label: formatMessage(messages.matches),
      sortable: false,
    },
    {
      id: 'created',
      numeric: false,
      label: formatMessage(messages.created),
      sortable: false,
    },
  ];

  return (
    <Fragment>
      <CustomTable<AnyHunt>
        rows={hunts.results}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            actionAlign='left'
            renderHeaderCell={(row) => {
              if (row.id === 'id')
                return (
                  <TableCell>
                    <Checkbox
                      color='primary'
                      disabled={finishedHunts.length === 0}
                      checked={isToggleOn}
                      onChange={(value) => {
                        if (value.target.checked) {
                          setToggleOn(true);
                          toggleHunts(
                            value.target.checked,
                            finishedHunts.map((result) => result.id)
                          );
                        } else {
                          setToggleOn(false);
                          toggleHunts(value.target.checked, []);
                        }
                      }}
                    />
                  </TableCell>
                );
              return (
                <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                  {formatMessage({ id: row.label })}
                </TableCell>
              );
            }}
          />
        )}
        renderTableRow={(row: AnyHunt) => (
          <HistoricalHuntRow
            key={row.id}
            rowData={row}
            viewResults={viewResults}
            viewRuleset={viewRuleset}
            cancelHunt={cancelHunt}
            deleteHunt={deleteHunt}
            showHistoricalHuntInfo={showHistoricalHuntInfo}
            selectedHunts={selectedHunts}
            toggleHunts={toggleHunts}
          />
        )}
        renderPagination={
          <CustomTablePagination
            isFirst={isFirst}
            isLast={!hunts.has_more}
            rowsPerPage={hunts.limit}
            rowsPerPageOptions={[5, 25, 50, 75]}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
      />
    </Fragment>
  );
};

export default HistoricalHuntTable;
