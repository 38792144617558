import React, { ComponentType } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import buttonStyle from 'views/components/Button/Button.styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

export interface SharedButtonProps {
  className?: string;
  variant?: keyof typeof buttonStyle;
  disabled?: boolean;
  dataCy?: string;
}

const makeButton = <P extends {}>(WrappedComponent: ComponentType<P>) => (
  props: P & SharedButtonProps
) => {
  const { className, variant, disabled, dataCy, ...rest } = props;
  const { classes } = useStyles(variant);
  return (
    <WrappedComponent
      className={className}
      css={classes.root}
      disabled={disabled}
      data-cy={dataCy}
      onClick={(e: React.MouseEvent) => props.disabled && e.preventDefault()}
      {...(rest as P)}
    />
  );
};

const useStyles = makeStyles(
  (variant: SharedButtonProps['variant']) => buttonStyle[variant || 'base']
);

export default makeButton;
