import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-warning' viewBox='0 0 32 32'>
      <path
        fill='transparent'
        d='M30.222 16c0 7.855-6.367 14.222-14.222 14.222s-14.222-6.367-14.222-14.222c0-7.855 6.367-14.222 14.222-14.222s14.222 6.367 14.222 14.222z'
      />
      <path d='M16 32c-8.837 0-16-7.163-16-16s7.163-16 16-16c8.837 0 16 7.163 16 16s-7.163 16-16 16zM16 28.444c6.873 0 12.444-5.572 12.444-12.444s-5.572-12.444-12.444-12.444c-6.873 0-12.444 5.572-12.444 12.444s5.572 12.444 12.444 12.444z' />
      <path d='M15.040 17.778h2.702l0.639-6.948c0.090-0.978-0.63-1.843-1.607-1.933-0.054-0.005-0.108-0.007-0.163-0.007h-0.44c-0.982 0-1.778 0.796-1.778 1.778 0 0.054 0.002 0.109 0.007 0.163l0.639 6.948z' />
      <path d='M16.391 19.556c-0.982 0-1.778 0.796-1.778 1.778s0.796 1.778 1.778 1.778c0.982 0 1.778-0.796 1.778-1.778s-0.796-1.778-1.778-1.778z' />
    </symbol>

    <Global
      styles={css`
        .icon-warning {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
