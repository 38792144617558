import React, { Fragment } from 'react';
import * as yup from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useIntl, IntlShape } from 'react-intl';
import { formSchema, formMessages } from 'utils/schema/formSchema';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import FormInput from 'views/components/form/FormInput';
import FormButtonBar from 'views/components/form/CustomForm/FormButtonBar';
import CustomForm from 'views/components/form/CustomForm';
import { IInviteTeamMemberValues } from 'models/Team';

interface IInviteTeamMemberForm {
  className?: string;
  heading: string;
  isLoading: boolean;
  errorMessage?: string | null;
  onSubmit: (
    values: IInviteTeamMemberValues,
    actions: FormikHelpers<IInviteTeamMemberValues>
  ) => void;
  onCancel: () => void;
}

const initialValues: IInviteTeamMemberValues = {
  name: '',
  email: '',
};

const getValidationSchema = (intl: IntlShape) => {
  const schema = formSchema(intl);
  const { fullName, email } = schema;
  return yup.object().shape({
    name: fullName.required(
      intl.formatMessage(formMessages.required, { label: intl.formatMessage(formMessages.name) })
    ),
    email: email.required(
      intl.formatMessage(formMessages.required, { label: intl.formatMessage(formMessages.email) })
    ),
  });
};

const InviteTeamMemberForm = ({
  className,
  heading,
  isLoading,
  errorMessage,
  onSubmit,
  onCancel,
}: IInviteTeamMemberForm) => {
  const intl = useIntl();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(intl)}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<IInviteTeamMemberValues>) => (
        <CustomForm
          testId='inviteTeamMemberForm'
          className={className}
          isLoading={isLoading}
          heading={heading}
          renderFields={() => (
            <Fragment>
              <FormInput
                className='h-mb-sm'
                name='name'
                label={intl.formatMessage(formMessages.name)}
                errorOnTouched={true}
                autoFocus
              />
              <FormInput
                className='h-mb-sm'
                name='email'
                label={intl.formatMessage(formMessages.email)}
                errorOnTouched={true}
              />
              <p className='p1 h-mb-xs' style={{ color: styles.color.red }}>
                {errorMessage}
              </p>
            </Fragment>
          )}
          renderButtonBar={() => (
            <FormButtonBar
              disabled={!formikProps.values.name}
              submitBtnText={intl.formatMessage(btnMessages.invite)}
              onCancel={() => {
                formikProps.resetForm();
                onCancel();
              }}
            />
          )}
        />
      )}
    </Formik>
  );
};

export default InviteTeamMemberForm;
