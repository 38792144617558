import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { ModalAction } from 'state/modal/types';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import styled from '@emotion/styled';
import styles from 'views/styles';
import { Wallets } from 'models/Wallets';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import ContextMenu from 'views/components/menu/ContextMenu';
import { defineMessages, useIntl } from 'react-intl';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { ConfigureWithdrawalModalParams } from './modals/ConfigureWithdrawal';
import { WalletsType } from './types';

export interface IWalletsActions {
  requestMFA: (modalName: string, data?: any) => ModalAction;
  configureWithdrawal: (params: Partial<ConfigureWithdrawalModalParams>) => ModalAction;
}

export interface IWalletsTableRow {
  type: WalletsType;
  rowData: Wallets;
  actions: IWalletsActions | null;
  onClick: (id: number) => void;
}

const messages = defineMessages({
  pending: {
    id: 'walletsTab.tableRow.pending',
    defaultMessage: 'Pending',
  },
  depositAddressPlaceholder: {
    id: 'walletsTab.tableRow.depositAddressPlaceholder',
    defaultMessage: 'Pending Engine Verification',
  },
  configureWithdrawals: {
    id: 'walletsTab.tableRow.configureWithdrawal',
    defaultMessage: 'Configure Withdrawals',
  },
  showTransactions: {
    id: 'walletsTab.tableRow.showTransactions',
    defaultMessage: 'Show Transactions',
  },
  transactions: {
    id: 'walletsTab.tableRow.transactions',
    defaultMessage: 'Transactions',
  },
  noAssignedValue: {
    id: 'walletsTab.tableRow.noAssignedValue',
    defaultMessage: 'Not Set',
  },
  noDeposit: {
    id: 'walletsTab.tableRow.noDeposit',
    defaultMessage: 'Cannot deposit into a rewards wallet',
  },
  configureWithdrawalAddress: {
    id: 'walletsTab.tableRow.configureWithdrawalAddress',
    defaultMessage: 'Withdrawal address needs to be configured per wallet',
  },
});

const HoverableTableRow = styled(BaseHoverableTableRow)`
  cursor: pointer;
`;

const Strong = styled.span`
  font-weight: bold;
  margin-right: 1rem;
`;

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const TooltipValue = ({ title, message }: { title: string; message: string }) => {
  const { classes } = useStyles();
  return (
    <div style={{ display: 'flex' }}>
      <span>{message}</span>
      <Tooltip placement='top' disableFocusListener title={<span>{title}</span>} interactive>
        <div css={classes.featureIcon}>
          <Icon css={classes.helpIcon} name='info' />
        </div>
      </Tooltip>
    </div>
  );
};

const WalletsTableRow = ({ type, rowData, actions, onClick }: IWalletsTableRow) => {
  const intl = useIntl();
  const { classes } = useStyles();

  return (
    <Fragment>
      <HoverableTableRow
        renderCells={() => (
          <Fragment>
            <TableCell onClick={() => (type === 'reward' ? onClick(rowData.id) : null)}>
              <div css={classes.container}>
                <Strong>{rowData.engineName}</Strong>
              </div>
            </TableCell>
            <TableCell>
              <Tooltip title={rowData.fullBalance || ''} placement='top'>
                <span css={classes.highlight}>{rowData.truncatedBalance}</span>
              </Tooltip>
            </TableCell>
            <TableCell>
              {type === 'reward' ? (
                <TooltipValue
                  title={intl.formatMessage(messages.noDeposit)}
                  message={intl.formatMessage(messages.noAssignedValue)}
                />
              ) : (
                rowData.deposit_address || (
                  <TooltipValue
                    title={intl.formatMessage(messages.depositAddressPlaceholder)}
                    message={intl.formatMessage(messages.pending)}
                  />
                )
              )}
            </TableCell>
            <TableCell>
              {rowData.withdrawal_address ? (
                <Tooltip title={rowData.withdrawal_address} placement='top-start'>
                  <span>
                    {`${rowData.withdrawal_address.substring(0, 10)}…`}{' '}
                    <CopyButton text={rowData.withdrawal_address} />
                  </span>
                </Tooltip>
              ) : (
                <TooltipValue
                  title={intl.formatMessage(messages.configureWithdrawalAddress)}
                  message={intl.formatMessage(messages.noAssignedValue)}
                />
              )}
            </TableCell>
            <TableCell>
              <Tooltip title={rowData.fullLimit || ''} placement='top'>
                <span>{rowData.truncatedLimit}</span>
              </Tooltip>
            </TableCell>
            {actions && (
              <TableCell>
                {type === 'reward' && (
                  <ContextMenu
                    options={[
                      {
                        label: intl.formatMessage(messages.configureWithdrawals),
                        onClick: () => {
                          actions.requestMFA('CONFIGURE_WITHDRAWAL', {
                            address: rowData.withdrawal_address,
                            limit: rowData.fullLimit || '',
                          });
                        },
                      },
                      {
                        label: intl.formatMessage(messages.showTransactions),
                        onClick: () => onClick(rowData.id),
                      },
                    ]}
                  />
                )}
              </TableCell>
            )}
          </Fragment>
        )}
      />
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    infoIcon: {
      fontSize: '0.5rem',
      lineHeight: 2,
      verticalAlign: 'middle',
      marginRight: styles.spacing.tiny,
      position: 'relative',
      top: -1,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    featureIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    helpIcon: {
      cursor: 'help',
      fontSize: '0.8rem',
      paddingLeft: '1rem',
      color: styles.color.xLightGrey,
    },
    highlight: {
      fontWeight: 'bold',
    },
  },
  light: {
    infoIcon: {
      color: styles.color.purple,
    },
    highlight: {
      color: styles.color.purple,
    },
  },
  dark: {
    infoIcon: {
      color: styles.color.lightBlue,
    },
    highlight: {
      color: styles.color.lightBlue,
    },
  },
});

export default WalletsTableRow;
