import React from 'react'; // eslint-disable-line
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from 'state/modal/actions';
import { ModalState } from 'state/modal';
import ScannerDetailsModal, { ScannerDetailsModalParams } from '.';

const ScannerDetailsModalContainer = () => {
  const dispatch = useDispatch();
  const params = useSelector(
    ({ modal }: { modal: ModalState<ScannerDetailsModalParams> }) => modal.params
  );
  return <ScannerDetailsModal {...params} onClick={() => dispatch(closeModal())} />;
};

export default ScannerDetailsModalContainer;
