import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-history' viewBox='0 0 26 30'>
      <path
        d='M19.1934 28.1899H1.74509C1.33358 28.1899 0.999992 27.8563 0.999992 27.4448V1.74495C0.999992 1.33345 1.33358 0.999855 1.74509 0.999855H24.1337C24.5452 0.999855 24.8788 1.33345 24.8788 1.74495V22.8586L19.1934 28.1899Z'
        fill='transparent'
        strokeWidth={1.9}
      />
      <path
        d='M24.8787 22.8628H19.1932L19.1932 28.1942L24.8787 22.8628Z'
        fill='transparent'
        strokeWidth={1.9}
        strokeLinejoin='round'
      />
      <path
        d='M19.8897 13.8799C19.8897 17.2507 16.957 20.0857 13.2087 20.0857C9.46048 20.0857 6.52776 17.2507 6.52776 13.8799C6.52776 10.5092 9.46048 7.67412 13.2087 7.67412C16.957 7.67412 19.8897 10.5092 19.8897 13.8799Z'
        fill='transparent'
        strokeWidth={1.9}
      />
      <path
        d='M13 11V15.5518L15.8449 17.2588'
        fill='transparent'
        strokeWidth={1.9}
        strokeLinecap='round'
      />
    </symbol>

    <Global
      styles={css`
        .icon-history {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
