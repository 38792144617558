import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-stop' viewBox='0 0 32 32'>
      <path d='M25.922 9.333c0 0.803-0.651 1.455-1.455 1.455s-1.455-0.651-1.455-1.455c0-0.803 0.651-1.455 1.455-1.455s1.455 0.651 1.455 1.455z'></path>
      <path d='M22.043 6.424c0 0.803-0.651 1.455-1.455 1.455s-1.455-0.651-1.455-1.455c0-0.803 0.651-1.455 1.455-1.455s1.455 0.651 1.455 1.455z'></path>
      <path d='M18.164 5.455c0 0.803-0.651 1.455-1.455 1.455s-1.455-0.651-1.455-1.455c0-0.803 0.651-1.455 1.455-1.455s1.455 0.651 1.455 1.455z'></path>
      <path d='M14.286 7.394c0 0.803-0.651 1.455-1.455 1.455s-1.455-0.651-1.455-1.455c0-0.803 0.651-1.455 1.455-1.455s1.455 0.651 1.455 1.455z'></path>
      <path d='M23.013 9.333v6.303c0 0.268-0.217 0.485-0.485 0.485s-0.485-0.217-0.485-0.485v-9.212h-2.909v8.242c0 0.268-0.217 0.485-0.485 0.485s-0.485-0.217-0.485-0.485v-9.212h-2.909v9.212c0 0.268-0.217 0.485-0.485 0.485s-0.485-0.217-0.485-0.485v-7.273h-2.909v12.121l-1.964-2.687c-0.582-0.897-1.717-1.198-2.549-0.684-0.829 0.526-1.035 1.672-0.461 2.566 0 0 3.167 4.793 4.517 6.845s3.537 3.657 7.626 3.657c6.77 0 7.377-5.229 7.377-6.788s0-13.091 0-13.091h-2.909z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-stop {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
