import React, { useEffect } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PanelContent from 'views/components/layout/PanelContent';
import PanelLoader from 'views/components/Loader/PanelLoader';
import MySandboxingTable from './MySandboxingTable';
import warningImg from 'assets/images/warning.svg';
import useHasFeature from 'hooks/useHasFeature';
import ErrorPageContent from 'views/components/error/ErrorPageContent';
import { camelCaseToWords } from 'utils/text';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { useAuth } from 'views/components/providers/AuthProvider';
import Icon from 'views/components/Icon/Icon';
import { FilterProps } from '../../../SandboxFilter';

type Filter = {
  key: string;
  value: string;
};
interface IMySandboxingTableContainer {
  paginationProps: { [key: string]: any };
  filters: Filter[];
  removeFilter: (key: keyof FilterProps) => void;
  toggleFilters: () => void;
  [key: string]: any;
}

const messages = defineMessages({
  warningText: {
    id: 'MySandboxingTab.warningText',
    defaultMessage: 'Account must have 2FA enabled to proceed',
  },
  setup2fa: {
    id: 'MySandboxingTab.setup2fa',
    defaultMessage: 'Setup 2FA',
  },
});

const MySandboxingTableContainer = ({
  filters,
  removeFilter,
  toggleFilters,
  useMySandboxingProps,
  paginationProps,
}: IMySandboxingTableContainer) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const { hasPermission, loading: loadingPermission } = useHasFeature('sandbox_search');
  const { data, error, more, refetch, refetchItem, setAccess, loading } = useMySandboxingProps;

  const accessForbidden = !isAuthenticated
    ? 'notLoggedIn'
    : !hasPermission
    ? 'notAllowed'
    : undefined;

  useEffect(() => {
    if (isAuthenticated && hasPermission) {
      setAccess(true);
    }
  }, [setAccess, isAuthenticated, hasPermission]);

  if (error) {
    return (
      <ErrorPageContent className='h-mt-lg h-mb-lg' heading='Error' text={error}></ErrorPageContent>
    );
  }
  if (loading || loadingPermission) return <PanelLoader />;

  return data ? (
    <div css={classes.container}>
      {!accessForbidden && (
        <div css={classes.tags}>
          {filters
            .filter((item) => item.key !== 'last_updated' && item.key !== 'Last Updated')
            .filter((item) => Boolean(item.value))
            .map((item) => (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  toggleFilters();
                }}
                css={classes.tag}
                key={item.key}
              >
                <span>
                  {camelCaseToWords(item.key)}: {item.value}
                </span>
                <Icon
                  name='close'
                  css={classes.iconRemove}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFilter(item.key as keyof FilterProps);
                  }}
                />
              </div>
            ))}
        </div>
      )}
      <MySandboxingTable
        results={data}
        filters={filters}
        more={more}
        refetch={refetch}
        refetchItem={refetchItem}
        accessForbidden={accessForbidden}
        paginationProps={paginationProps}
      />
    </div>
  ) : (
    <PanelContent
      heading={''}
      image={{
        src: warningImg,
        alt: intl.formatMessage(messages.warningText),
      }}
      text={intl.formatMessage(messages.warningText)}
      customButtons={[
        <Button
          component='button'
          color='primary'
          variant='contained'
          onClick={() => history.replace('/account/advanced')}
        >
          {intl.formatMessage(messages.setup2fa)}
        </Button>,
      ]}
    />
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      paddingRight: '3rem',
      position: 'relative',
      margin: '1.5rem 0',
    },
    tags: {
      display: 'flex',
      gap: '1rem',
      marginBottom: '1rem',
      padding: '0 3rem',
      position: 'absolute',
    },
    tag: {
      background: '#D9D9D9',
      color: styles.color.black,
      padding: '0.4rem 1.8rem',
      borderRadius: '2rem',
      fontSize: '1.4rem',
      fontWeight: 600,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
    },
    iconRemove: {
      width: '1rem !important',
      height: '1rem !important',
      cursor: 'pointer',
    },
  },
  light: {},
  dark: {},
});

export default MySandboxingTableContainer;
