import React, { Fragment } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import PageLayout from 'views/components/layout/PageLayout';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

import MarketplaceStatsPanel from './MarketplaceStatsPanel';

const messages = defineMessages({
  title: {
    id: 'marketplacestatspage.title',
    defaultMessage: 'Marketplace Stats',
  },
});

const MarketplaceStatsPage = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <ScrollToTopOnMount />
        <PageLayout>
          <MarketplaceStatsPanel />
        </PageLayout>
      </BaseLayout>
    </Fragment>
  );
};

export default MarketplaceStatsPage;
