import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { DivHTMLProps } from 'views/components/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

const Card = React.forwardRef<any, DivHTMLProps>((props, ref) => {
  const { classes } = useStyles();
  return <div ref={ref} css={classes.root} {...props} />;
});

const useStyles = makeStyles({
  base: {
    root: {
      boxShadow: styles.shadow.default,
      borderRadius: styles.border.radius,
    },
  },
  light: {
    root: {
      background: styles.color.white,
    },
  },
  dark: {
    root: {
      background: styles.color.darkOffBlack,
    },
  },
});

export default Card;
