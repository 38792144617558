import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { messages as errorMessages } from 'views/components/error/ErrorPageContent';
import { btnMessages } from 'views/components/Button';
import api from 'services/api';
import BaseLayout from 'views/components/layout/BaseLayout';
import ErrorPageContent from 'views/components/error/ErrorPageContent';
import { ButtonLink } from 'views/components/Button';
import { useDispatch } from 'react-redux';
import { openModal } from 'state/modal/actions';
import AuthLoader from 'views/components/auth/AuthLoader';
import SimpleLayout from 'views/components/layout/SimpleLayout';
import CenteredContent from 'views/components/layout/CenteredContent';

type ViewState = 'LOADING' | 'ERROR' | 'REDIRECT';

const messages = defineMessages({
  loadingHeading: {
    id: 'emailVerificationPage.loading.heading',
    defaultMessage: 'Loading',
  },
  loadingText: {
    id: 'emailVerificationPage.loading.text',
    defaultMessage: 'Please wait a moment…',
  },
  errorText: {
    id: 'emailVerificationPage.error.text',
    defaultMessage: 'Request failed. Please try again or contact support for assistance.',
  },
});

export const EmailVerificationPage = () => {
  const [viewState, setViewState] = useState<ViewState>('LOADING');

  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { token } = qs.parse(location.search);

  const _verifyPricingFormEmail = async () => {
    if (!token || typeof token !== 'string') {
      setViewState('REDIRECT');
    } else {
      try {
        await api.verifyPricingFormEmail(token);
        dispatch(openModal('EMAIL_VERIFICATION_SUCCESS'));
        history.replace('/');
      } catch (error) {
        setViewState('ERROR');
      }
    }
  };

  useEffect(() => {
    _verifyPricingFormEmail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (viewState === 'ERROR') {
    return (
      <BaseLayout>
        <div className='container h-mt-xl h-mb-xxl'>
          <div className='row'>
            <div className='col-12'>
              <ErrorPageContent
                heading={intl.formatMessage(errorMessages.heading500)}
                text={intl.formatMessage(messages.errorText)}>
                <ButtonLink variant='large' to='/' replace={true}>
                  {intl.formatMessage(btnMessages.backToDash)}
                </ButtonLink>
              </ErrorPageContent>
            </div>
          </div>
        </div>
      </BaseLayout>
    );
  }

  if (viewState === 'LOADING') {
    return (
      <SimpleLayout>
        <CenteredContent>
          <AuthLoader
            style={{ marginTop: '-5rem' }}
            heading={intl.formatMessage(messages.loadingHeading)}
            text={intl.formatMessage(messages.loadingText)}
          />
        </CenteredContent>
      </SimpleLayout>
    );
  }

  // REDIRECT
  return <Redirect to='/' />;
};
