import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import ability from 'config/ability';
import { btnMessages } from 'views/components/Button';
import { combinedUserActions, useUser } from 'views/components/providers/UserProvider';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';
import PanelContent from 'views/components/layout/PanelContent';
import AppLoader from 'views/components/request/AppLoader';
import { toggleHuntsType } from 'views/pages/HuntPage/types';
import LiveHuntTab from './LiveHuntTab';

interface ILiveHuntTabContainer {
  showActive: boolean;
  toggleHunts: toggleHuntsType;
  selectedHunts: string[];
}

const messages = defineMessages({
  heading: {
    id: 'liveHuntTab.loggedOut.heading',
    defaultMessage: 'Live Hunting',
  },
});

const LiveHuntTabContainer = ({
  showActive,
  selectedHunts,
  toggleHunts,
}: ILiveHuntTabContainer) => {
  const user = useUser();
  const intl = useIntl();
  const history = useHistory();

  return (
    <AppLoader loadingActions={combinedUserActions} onReload={user.refreshUser}>
      {() =>
        ability.cannot('live_hunt', 'Artifact') ? (
          <PanelContent
            imageComponent={<RulesetImg />}
            heading={intl.formatMessage(messages.heading)}
            text={intl.formatMessage({ id: 'rulesetsTab.loggedOut.text' })}
            buttons={[
              {
                text: intl.formatMessage(btnMessages.upgrade),
                onClick: () => history.push('/pricing'),
              },
            ]}
          />
        ) : (
          <LiveHuntTab
            showActive={showActive}
            selectedHunts={selectedHunts}
            toggleHunts={toggleHunts}
          />
        )
      }
    </AppLoader>
  );
};

export default LiveHuntTabContainer;
