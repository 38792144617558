import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';
import styles from 'views/styles';

export default () => (
  <Fragment>
    <symbol id='icon-gear' viewBox='0 0 32 32'>
      <path d='M10.48 15.961c0 2.798 2.268 5.067 5.067 5.067s5.067-2.268 5.067-5.067c0-2.798-2.268-5.067-5.067-5.067s-5.067 2.268-5.067 5.067zM15.547 18.627c-1.473 0-2.667-1.194-2.667-2.667s1.194-2.667 2.667-2.667c1.473 0 2.667 1.194 2.667 2.667s-1.194 2.667-2.667 2.667zM26.6 14.841l2.476-2.089c0.414-0.349 0.575-0.914 0.409-1.429l-0.005-0.015c-0.321-0.948-0.732-1.863-1.227-2.733-0.504-0.866-1.094-1.679-1.76-2.427v0c-0.369-0.409-0.953-0.549-1.467-0.35l-2.973 1.15-1.96-1.12-0.517-3.101c-0.090-0.542-0.504-0.974-1.042-1.086l-0.001-0c-1.97-0.41-4.003-0.41-5.973 0l-0.001 0c-0.538 0.112-0.952 0.544-1.042 1.086l-0.517 3.101-1.96 1.12-2.978-1.11c-0.512-0.191-1.090-0.050-1.456 0.356l-0.006 0.007c-0.666 0.748-1.256 1.561-1.76 2.427-0.495 0.87-0.906 1.785-1.227 2.733l-0.005 0.015c-0.166 0.515-0.005 1.080 0.409 1.429l2.476 2.089v2.24l-2.476 2.089c-0.414 0.349-0.575 0.914-0.409 1.429l0.005 0.015c0.321 0.948 0.732 1.863 1.227 2.733 0.504 0.866 1.094 1.679 1.76 2.427l0.006 0.007c0.366 0.406 0.943 0.547 1.456 0.356l2.978-1.11 1.96 1.12 0.517 3.101c0.090 0.542 0.504 0.974 1.042 1.086l0.001 0c1.97 0.41 4.003 0.41 5.973 0l0.001-0c0.538-0.112 0.952-0.544 1.042-1.086l0.517-3.101 1.96-1.12 2.978 1.11c0.512 0.191 1.090 0.050 1.456-0.356l0.006-0.007c0.666-0.748 1.256-1.561 1.76-2.427 0.495-0.87 0.906-1.785 1.227-2.733l0.005-0.015c0.166-0.515 0.005-1.080-0.409-1.429l-2.476-2.089v-2.293zM26.6 20.561c-0.199 0.482-0.43 0.95-0.693 1.4-0.255 0.463-0.54 0.908-0.853 1.333l-3.28-1.227-4.093 2.347-0.573 3.44c-1.035 0.142-2.085 0.142-3.12 0l-0.573-3.44-4.12-2.387-3.28 1.227c-0.313-0.425-0.599-0.871-0.853-1.333-0.263-0.45-0.495-0.918-0.693-1.4l2.667-2.213v-4.733l-2.667-2.213c0.199-0.482 0.43-0.95 0.693-1.4 0.255-0.463 0.54-0.908 0.853-1.333l3.28 1.227 4.12-2.347 0.573-3.44c1.035-0.142 2.085-0.142 3.12 0l0.573 3.44 4.12 2.387 3.28-1.227c0.313 0.425 0.599 0.871 0.853 1.333 0.263 0.45 0.495 0.918 0.693 1.4l-2.667 2.213v4.773l2.64 2.173z' />
    </symbol>

    <Global
      styles={css`
        .icon-gear {
          width: ${styles.icon.width};
          height: ${styles.icon.height};
        }
      `}
    />
  </Fragment>
);
