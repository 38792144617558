import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import DateFnsUtils from '@date-io/date-fns';
import { startOfHour } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker as DateTimePickerComponent,
} from '@material-ui/pickers';

interface IDateTimePicker {
  label: string;
  value: Date | null;
  disabled?: boolean;
  minDate?: Date;
  onChange: (date: Date | null) => void;
}

export const DateTimePicker = ({ label, value, disabled, minDate, onChange }: IDateTimePicker) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePickerComponent
        views={['date', 'hours']}
        openTo={'date'}
        css={classes.dateTimePicker}
        disableToolbar
        disabled={disabled}
        margin='normal'
        ampm={false}
        helperText={'MM/DD/YYYY UTC'}
        id='date-time-picker'
        label={label}
        inputVariant='outlined'
        value={value}
        minDate={minDate || tomorrow}
        onChange={(e) => {
          const hourlyDate = startOfHour(e as Date);
          onChange(hourlyDate);
        }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  base: {
    dateTimePicker: { width: '100%' },
  },
  light: {},
  dark: {},
});
