import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-reload' viewBox='0 0 28 32'>
      <path d='M5.589 17.487c-0.316-0.901-0.451-1.893-0.451-2.93 0-5.003 4.101-9.059 9.059-9.059s9.059 4.101 9.059 9.059-4.101 9.059-9.059 9.059v-1.803c0-0.721-0.586-1.262-1.262-1.262-0.225 0-0.496 0.090-0.676 0.18l-6.265 3.966c-0.586 0.361-0.766 1.172-0.406 1.758 0.090 0.18 0.225 0.316 0.406 0.406l6.265 3.921c0.586 0.361 1.397 0.18 1.758-0.406 0.135-0.18 0.18-0.451 0.18-0.676v-1.803c7.346 0 13.341-5.994 13.341-13.341 0-7.301-5.994-13.296-13.341-13.296s-13.341 5.949-13.341 13.296c0 1.487 0.27 2.93 0.721 4.327 0.406 1.172 1.848 1.938 2.93 1.307 1.217-0.766 1.397-1.758 1.082-2.704z' />
    </symbol>

    <Global
      styles={css`
        .icon-reload {
          width: 2.8em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
