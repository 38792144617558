export default {
  T1548: 'Abuse Elevation Control Mechanism',
  'T1548.002': 'Abuse Elevation Control Mechanism: Bypass User Account Control',
  'T1548.004': 'Abuse Elevation Control Mechanism: Elevated Execution with Prompt',
  'T1548.001': 'Abuse Elevation Control Mechanism: Setuid and Setgid',
  'T1548.003': 'Abuse Elevation Control Mechanism: Sudo and Sudo Caching',
  T1134: 'Access Token Manipulation',
  'T1134.002': 'Access Token Manipulation: Create Process with Token',
  'T1134.003': 'Access Token Manipulation: Make and Impersonate Token',
  'T1134.004': 'Access Token Manipulation: Parent PID Spoofing',
  'T1134.005': 'Access Token Manipulation: SID-History Injection',
  'T1134.001': 'Access Token Manipulation: Token Impersonation/Theft',
  T1531: 'Account Access Removal',
  T1087: 'Account Discovery',
  'T1087.004': 'Account Discovery: Cloud Account',
  'T1087.002': 'Account Discovery: Domain Account',
  'T1087.003': 'Account Discovery: Email Account',
  'T1087.001': 'Account Discovery: Local Account',
  T1098: 'Account Manipulation',
  'T1098.001': 'Account Manipulation: Additional Cloud Credentials',
  'T1098.003': 'Account Manipulation: Additional Cloud Roles',
  'T1098.002': 'Account Manipulation: Additional Email Delegate Permissions',
  'T1098.005': 'Account Manipulation: Device Registration',
  'T1098.004': 'Account Manipulation: SSH Authorized Keys',
  T1583: 'Acquire Infrastructure',
  'T1583.005': 'Acquire Infrastructure: Botnet',
  'T1583.002': 'Acquire Infrastructure: DNS Server',
  'T1583.001': 'Acquire Infrastructure: Domains',
  'T1583.004': 'Acquire Infrastructure: Server',
  'T1583.003': 'Acquire Infrastructure: Virtual Private Server',
  'T1583.006': 'Acquire Infrastructure: Web Services',
  T1595: 'Active Scanning',
  'T1595.001': 'Active Scanning: Scanning IP Blocks',
  'T1595.002': 'Active Scanning: Vulnerability Scanning',
  'T1595.003': 'Active Scanning: Wordlist Scanning',
  T1557: 'Adversary-in-the-Middle',
  'T1557.002': 'Adversary-in-the-Middle: ARP Cache Poisoning',
  'T1557.003': 'Adversary-in-the-Middle: DHCP Spoofing',
  'T1557.001': 'Adversary-in-the-Middle: LLMNR/NBT-NS Poisoning and SMB Relay',
  T1071: 'Application Layer Protocol',
  'T1071.004': 'Application Layer Protocol: DNS',
  'T1071.002': 'Application Layer Protocol: File Transfer Protocols',
  'T1071.003': 'Application Layer Protocol: Mail Protocols',
  'T1071.001': 'Application Layer Protocol: Web Protocols',
  T1010: 'Application Window Discovery',
  T1560: 'Archive Collected Data',
  T1022: 'Archive Collected Data',
  'T1560.003': 'Archive Collected Data: Archive via Custom Method',
  'T1560.002': 'Archive Collected Data: Archive via Library',
  'T1560.001': 'Archive Collected Data: Archive via Utility',
  T1123: 'Audio Capture',
  T1119: 'Automated Collection',
  T1020: 'Automated Exfiltration',
  'T1020.001': 'Automated Exfiltration: Traffic Duplication',
  T1197: 'BITS Jobs',
  T1547: 'Boot or Logon Autostart Execution',
  'T1547.014': 'Boot or Logon Autostart Execution: Active Setup',
  'T1547.002': 'Boot or Logon Autostart Execution: Authentication Package',
  'T1547.006': 'Boot or Logon Autostart Execution: Kernel Modules and Extensions',
  'T1547.008': 'Boot or Logon Autostart Execution: LSASS Driver',
  'T1547.015': 'Boot or Logon Autostart Execution: Login Items',
  'T1547.010': 'Boot or Logon Autostart Execution: Port Monitors',
  'T1547.012': 'Boot or Logon Autostart Execution: Print Processors',
  'T1547.007': 'Boot or Logon Autostart Execution: Re-opened Applications',
  'T1547.001': 'Boot or Logon Autostart Execution: Registry Run Keys / Startup Folder',
  T1060: 'Boot or Logon Autostart Execution: Registry Run Keys / Startup Folder',
  'T1547.005': 'Boot or Logon Autostart Execution: Security Support Provider',
  'T1547.009': 'Boot or Logon Autostart Execution: Shortcut Modification',
  'T1547.003': 'Boot or Logon Autostart Execution: Time Providers',
  'T1547.004': 'Boot or Logon Autostart Execution: Winlogon Helper DLL',
  'T1547.013': 'Boot or Logon Autostart Execution: XDG Autostart Entries',
  T1037: 'Boot or Logon Initialization Scripts',
  'T1037.002': 'Boot or Logon Initialization Scripts: Login Hook',
  'T1037.001': 'Boot or Logon Initialization Scripts: Logon Script (Windows)',
  'T1037.003': 'Boot or Logon Initialization Scripts: Network Logon Script',
  'T1037.004': 'Boot or Logon Initialization Scripts: RC Scripts',
  'T1037.005': 'Boot or Logon Initialization Scripts: Startup Items',
  T1217: 'Browser Bookmark Discovery',
  T1176: 'Browser Extensions',
  T1185: 'Browser Session Hijacking',
  T1110: 'Brute Force',
  'T1110.004': 'Brute Force: Credential Stuffing',
  'T1110.002': 'Brute Force: Password Cracking',
  'T1110.001': 'Brute Force: Password Guessing',
  'T1110.003': 'Brute Force: Password Spraying',
  T1612: 'Build Image on Host',
  T1115: 'Clipboard Data',
  T1580: 'Cloud Infrastructure Discovery',
  T1538: 'Cloud Service Dashboard',
  T1526: 'Cloud Service Discovery',
  T1619: 'Cloud Storage Object Discovery',
  T1059: 'Command and Scripting Interpreter',
  'T1059.002': 'Command and Scripting Interpreter: AppleScript',
  'T1059.007': 'Command and Scripting Interpreter: JavaScript',
  'T1059.008': 'Command and Scripting Interpreter: Network Device CLI',
  'T1059.001': 'Command and Scripting Interpreter: PowerShell',
  'T1059.006': 'Command and Scripting Interpreter: Python',
  'T1059.004': 'Command and Scripting Interpreter: Unix Shell',
  'T1059.005': 'Command and Scripting Interpreter: Visual Basic',
  'T1059.003': 'Command and Scripting Interpreter: Windows Command Shell',
  T1092: 'Communication Through Removable Media',
  T1586: 'Compromise Accounts',
  'T1586.002': 'Compromise Accounts: Email Accounts',
  'T1586.001': 'Compromise Accounts: Social Media Accounts',
  T1554: 'Compromise Client Software Binary',
  T1584: 'Compromise Infrastructure',
  'T1584.005': 'Compromise Infrastructure: Botnet',
  'T1584.002': 'Compromise Infrastructure: DNS Server',
  'T1584.001': 'Compromise Infrastructure: Domains',
  'T1584.004': 'Compromise Infrastructure: Server',
  'T1584.003': 'Compromise Infrastructure: Virtual Private Server',
  'T1584.006': 'Compromise Infrastructure: Web Services',
  T1609: 'Container Administration Command',
  T1613: 'Container and Resource Discovery',
  T1136: 'Create Account',
  'T1136.003': 'Create Account: Cloud Account',
  'T1136.002': 'Create Account: Domain Account',
  'T1136.001': 'Create Account: Local Account',
  T1543: 'Create or Modify System Process',
  'T1543.001': 'Create or Modify System Process: Launch Agent',
  'T1543.004': 'Create or Modify System Process: Launch Daemon',
  'T1543.002': 'Create or Modify System Process: Systemd Service',
  'T1543.003': 'Create or Modify System Process: Windows Service',
  T1555: 'Credentials from Password Stores',
  'T1555.003': 'Credentials from Password Stores: Credentials from Web Browsers',
  'T1555.001': 'Credentials from Password Stores: Keychain',
  'T1555.005': 'Credentials from Password Stores: Password Managers',
  'T1555.002': 'Credentials from Password Stores: Securityd Memory',
  'T1555.004': 'Credentials from Password Stores: Windows Credential Manager',
  T1485: 'Data Destruction',
  T1132: 'Data Encoding',
  'T1132.002': 'Data Encoding: Non-Standard Encoding',
  'T1132.001': 'Data Encoding: Standard Encoding',
  T1486: 'Data Encrypted for Impact',
  T1565: 'Data Manipulation',
  'T1565.003': 'Data Manipulation: Runtime Data Manipulation',
  'T1565.001': 'Data Manipulation: Stored Data Manipulation',
  'T1565.002': 'Data Manipulation: Transmitted Data Manipulation',
  T1001: 'Data Obfuscation',
  'T1001.001': 'Data Obfuscation: Junk Data',
  'T1001.003': 'Data Obfuscation: Protocol Impersonation',
  'T1001.002': 'Data Obfuscation: Steganography',
  T1074: 'Data Staged',
  'T1074.001': 'Data Staged: Local Data Staging',
  'T1074.002': 'Data Staged: Remote Data Staging',
  T1030: 'Data Transfer Size Limits',
  T1530: 'Data from Cloud Storage Object',
  T1602: 'Data from Configuration Repository',
  'T1602.002': 'Data from Configuration Repository: Network Device Configuration Dump',
  'T1602.001': 'Data from Configuration Repository: SNMP (MIB Dump)',
  T1213: 'Data from Information Repositories',
  'T1213.003': 'Data from Information Repositories: Code Repositories',
  'T1213.001': 'Data from Information Repositories: Confluence',
  'T1213.002': 'Data from Information Repositories: Sharepoint',
  T1005: 'Data from Local System',
  T1039: 'Data from Network Shared Drive',
  T1025: 'Data from Removable Media',
  T1622: 'Debugger Evasion',
  T1491: 'Defacement',
  'T1491.002': 'Defacement: External Defacement',
  'T1491.001': 'Defacement: Internal Defacement',
  T1140: 'Deobfuscate/Decode Files or Information',
  T1610: 'Deploy Container',
  T1587: 'Develop Capabilities',
  'T1587.002': 'Develop Capabilities: Code Signing Certificates',
  'T1587.003': 'Develop Capabilities: Digital Certificates',
  'T1587.004': 'Develop Capabilities: Exploits',
  'T1587.001': 'Develop Capabilities: Malware',
  T1006: 'Direct Volume Access',
  T1561: 'Disk Wipe',
  'T1561.001': 'Disk Wipe: Disk Content Wipe',
  'T1561.002': 'Disk Wipe: Disk Structure Wipe',
  T1484: 'Domain Policy Modification',
  'T1484.002': 'Domain Policy Modification: Domain Trust Modification',
  'T1484.001': 'Domain Policy Modification: Group Policy Modification',
  T1482: 'Domain Trust Discovery',
  T1189: 'Drive-by Compromise',
  T1568: 'Dynamic Resolution',
  'T1568.003': 'Dynamic Resolution: DNS Calculation',
  'T1568.002': 'Dynamic Resolution: Domain Generation Algorithms',
  'T1568.001': 'Dynamic Resolution: Fast Flux DNS',
  T1114: 'Email Collection',
  'T1114.003': 'Email Collection: Email Forwarding Rule',
  'T1114.001': 'Email Collection: Local Email Collection',
  'T1114.002': 'Email Collection: Remote Email Collection',
  T1573: 'Encrypted Channel',
  'T1573.002': 'Encrypted Channel: Asymmetric Cryptography',
  'T1573.001': 'Encrypted Channel: Symmetric Cryptography',
  T1499: 'Endpoint Denial of Service',
  'T1499.003': 'Endpoint Denial of Service: Application Exhaustion Flood',
  'T1499.004': 'Endpoint Denial of Service: Application or System Exploitation',
  'T1499.001': 'Endpoint Denial of Service: OS Exhaustion Flood',
  'T1499.002': 'Endpoint Denial of Service: Service Exhaustion Flood',
  T1611: 'Escape to Host',
  T1585: 'Establish Accounts',
  'T1585.002': 'Establish Accounts: Email Accounts',
  'T1585.001': 'Establish Accounts: Social Media Accounts',
  T1546: 'Event Triggered Execution',
  'T1546.008': 'Event Triggered Execution: Accessibility Features',
  'T1546.009': 'Event Triggered Execution: AppCert DLLs',
  'T1546.010': 'Event Triggered Execution: AppInit DLLs',
  'T1546.011': 'Event Triggered Execution: Application Shimming',
  'T1546.001': 'Event Triggered Execution: Change Default File Association',
  'T1546.015': 'Event Triggered Execution: Component Object Model Hijacking',
  'T1546.014': 'Event Triggered Execution: Emond',
  'T1546.012': 'Event Triggered Execution: Image File Execution Options Injection',
  'T1546.006': 'Event Triggered Execution: LC_LOAD_DYLIB Addition',
  'T1546.007': 'Event Triggered Execution: Netsh Helper DLL',
  'T1546.013': 'Event Triggered Execution: PowerShell Profile',
  'T1546.002': 'Event Triggered Execution: Screensaver',
  'T1546.005': 'Event Triggered Execution: Trap',
  'T1546.004': 'Event Triggered Execution: Unix Shell Configuration Modification',
  'T1546.003': 'Event Triggered Execution: Windows Management Instrumentation Event Subscription',
  T1480: 'Execution Guardrails',
  'T1480.001': 'Execution Guardrails: Environmental Keying',
  T1048: 'Exfiltration Over Alternative Protocol',
  'T1048.002':
    'Exfiltration Over Alternative Protocol: Exfiltration Over Asymmetric Encrypted Non-C2 Protocol',
  'T1048.001':
    'Exfiltration Over Alternative Protocol: Exfiltration Over Symmetric Encrypted Non-C2 Protocol',
  'T1048.003':
    'Exfiltration Over Alternative Protocol: Exfiltration Over Unencrypted Non-C2 Protocol',
  T1041: 'Exfiltration Over C2 Channel',
  T1011: 'Exfiltration Over Other Network Medium',
  'T1011.001': 'Exfiltration Over Other Network Medium: Exfiltration Over Bluetooth',
  T1052: 'Exfiltration Over Physical Medium',
  'T1052.001': 'Exfiltration Over Physical Medium: Exfiltration over USB',
  T1567: 'Exfiltration Over Web Service',
  'T1567.002': 'Exfiltration Over Web Service: Exfiltration to Cloud Storage',
  'T1567.001': 'Exfiltration Over Web Service: Exfiltration to Code Repository',
  T1190: 'Exploit Public-Facing Application',
  T1203: 'Exploitation for Client Execution',
  T1212: 'Exploitation for Credential Access',
  T1211: 'Exploitation for Defense Evasion',
  T1068: 'Exploitation for Privilege Escalation',
  T1210: 'Exploitation of Remote Services',
  T1133: 'External Remote Services',
  T1008: 'Fallback Channels',
  T1083: 'File and Directory Discovery',
  T1222: 'File and Directory Permissions Modification',
  'T1222.002':
    'File and Directory Permissions Modification: Linux and Mac File and Directory Permissions Modification',
  'T1222.001':
    'File and Directory Permissions Modification: Windows File and Directory Permissions Modification',
  T1495: 'Firmware Corruption',
  T1187: 'Forced Authentication',
  T1606: 'Forge Web Credentials',
  'T1606.002': 'Forge Web Credentials: SAML Tokens',
  'T1606.001': 'Forge Web Credentials: Web Cookies',
  T1592: 'Gather Victim Host Information',
  'T1592.004': 'Gather Victim Host Information: Client Configurations',
  'T1592.003': 'Gather Victim Host Information: Firmware',
  'T1592.001': 'Gather Victim Host Information: Hardware',
  'T1592.002': 'Gather Victim Host Information: Software',
  T1589: 'Gather Victim Identity Information',
  'T1589.001': 'Gather Victim Identity Information: Credentials',
  'T1589.002': 'Gather Victim Identity Information: Email Addresses',
  'T1589.003': 'Gather Victim Identity Information: Employee Names',
  T1590: 'Gather Victim Network Information',
  'T1590.002': 'Gather Victim Network Information: DNS',
  'T1590.001': 'Gather Victim Network Information: Domain Properties',
  'T1590.005': 'Gather Victim Network Information: IP Addresses',
  'T1590.006': 'Gather Victim Network Information: Network Security Appliances',
  'T1590.004': 'Gather Victim Network Information: Network Topology',
  'T1590.003': 'Gather Victim Network Information: Network Trust Dependencies',
  T1591: 'Gather Victim Org Information',
  'T1591.002': 'Gather Victim Org Information: Business Relationships',
  'T1591.001': 'Gather Victim Org Information: Determine Physical Locations',
  'T1591.003': 'Gather Victim Org Information: Identify Business Tempo',
  'T1591.004': 'Gather Victim Org Information: Identify Roles',
  T1615: 'Group Policy Discovery',
  T1200: 'Hardware Additions',
  T1564: 'Hide Artifacts',
  'T1564.008': 'Hide Artifacts: Email Hiding Rules',
  'T1564.005': 'Hide Artifacts: Hidden File System',
  'T1564.001': 'Hide Artifacts: Hidden Files and Directories',
  'T1564.002': 'Hide Artifacts: Hidden Users',
  'T1564.003': 'Hide Artifacts: Hidden Window',
  'T1564.004': 'Hide Artifacts: NTFS File Attributes',
  'T1564.010': 'Hide Artifacts: Process Argument Spoofing',
  'T1564.009': 'Hide Artifacts: Resource Forking',
  'T1564.006': 'Hide Artifacts: Run Virtual Instance',
  'T1564.007': 'Hide Artifacts: VBA Stomping',
  T1574: 'Hijack Execution Flow',
  'T1574.012': 'Hijack Execution Flow: COR_PROFILER',
  'T1574.001': 'Hijack Execution Flow: DLL Search Order Hijacking',
  'T1574.002': 'Hijack Execution Flow: DLL Side-Loading',
  'T1574.004': 'Hijack Execution Flow: Dylib Hijacking',
  'T1574.006': 'Hijack Execution Flow: Dynamic Linker Hijacking',
  'T1574.005': 'Hijack Execution Flow: Executable Installer File Permissions Weakness',
  'T1574.013': 'Hijack Execution Flow: KernelCallbackTable',
  'T1574.007': 'Hijack Execution Flow: Path Interception by PATH Environment Variable',
  'T1574.008': 'Hijack Execution Flow: Path Interception by Search Order Hijacking',
  'T1574.009': 'Hijack Execution Flow: Path Interception by Unquoted Path',
  'T1574.010': 'Hijack Execution Flow: Services File Permissions Weakness',
  'T1574.011': 'Hijack Execution Flow: Services Registry Permissions Weakness',
  T1562: 'Impair Defenses',
  'T1562.008': 'Impair Defenses: Disable Cloud Logs',
  'T1562.002': 'Impair Defenses: Disable Windows Event Logging',
  'T1562.007': 'Impair Defenses: Disable or Modify Cloud Firewall',
  'T1562.004': 'Impair Defenses: Disable or Modify System Firewall',
  'T1562.001': 'Impair Defenses: Disable or Modify Tools',
  'T1562.010': 'Impair Defenses: Downgrade Attack',
  'T1562.003': 'Impair Defenses: Impair Command History Logging',
  'T1562.006': 'Impair Defenses: Indicator Blocking',
  'T1562.009': 'Impair Defenses: Safe Mode Boot',
  T1525: 'Implant Internal Image',
  T1070: 'Indicator Removal on Host',
  'T1070.003': 'Indicator Removal on Host: Clear Command History',
  'T1070.002': 'Indicator Removal on Host: Clear Linux or Mac System Logs',
  'T1070.001': 'Indicator Removal on Host: Clear Windows Event Logs',
  'T1070.004': 'Indicator Removal on Host: File Deletion',
  'T1070.005': 'Indicator Removal on Host: Network Share Connection Removal',
  'T1070.006': 'Indicator Removal on Host: Timestomp',
  T1202: 'Indirect Command Execution',
  T1105: 'Ingress Tool Transfer',
  T1490: 'Inhibit System Recovery',
  T1056: 'Input Capture',
  'T1056.004': 'Input Capture: Credential API Hooking',
  'T1056.002': 'Input Capture: GUI Input Capture',
  'T1056.001': 'Input Capture: Keylogging',
  'T1056.003': 'Input Capture: Web Portal Capture',
  T1559: 'Inter-Process Communication',
  'T1559.001': 'Inter-Process Communication: Component Object Model',
  'T1559.002': 'Inter-Process Communication: Dynamic Data Exchange',
  'T1559.003': 'Inter-Process Communication: XPC Services',
  T1534: 'Internal Spearphishing',
  T1570: 'Lateral Tool Transfer',
  T1036: 'Masquerading',
  'T1036.007': 'Masquerading: Double File Extension',
  'T1036.001': 'Masquerading: Invalid Code Signature',
  'T1036.004': 'Masquerading: Masquerade Task or Service',
  'T1036.005': 'Masquerading: Match Legitimate Name or Location',
  'T1036.003': 'Masquerading: Rename System Utilities',
  'T1036.002': 'Masquerading: Right-to-Left Override',
  'T1036.006': 'Masquerading: Space after Filename',
  T1556: 'Modify Authentication Process',
  'T1556.001': 'Modify Authentication Process: Domain Controller Authentication',
  'T1556.004': 'Modify Authentication Process: Network Device Authentication',
  'T1556.002': 'Modify Authentication Process: Password Filter DLL',
  'T1556.003': 'Modify Authentication Process: Pluggable Authentication Modules',
  'T1556.005': 'Modify Authentication Process: Reversible Encryption',
  T1578: 'Modify Cloud Compute Infrastructure',
  'T1578.002': 'Modify Cloud Compute Infrastructure: Create Cloud Instance',
  'T1578.001': 'Modify Cloud Compute Infrastructure: Create Snapshot',
  'T1578.003': 'Modify Cloud Compute Infrastructure: Delete Cloud Instance',
  'T1578.004': 'Modify Cloud Compute Infrastructure: Revert Cloud Instance',
  T1112: 'Modify Registry',
  T1601: 'Modify System Image',
  'T1601.002': 'Modify System Image: Downgrade System Image',
  'T1601.001': 'Modify System Image: Patch System Image',
  T1111: 'Multi-Factor Authentication Interception',
  T1621: 'Multi-Factor Authentication Request Generation',
  T1104: 'Multi-Stage Channels',
  T1106: 'Native API',
  T1599: 'Network Boundary Bridging',
  'T1599.001': 'Network Boundary Bridging: Network Address Translation Traversal',
  T1498: 'Network Denial of Service',
  'T1498.001': 'Network Denial of Service: Direct Network Flood',
  'T1498.002': 'Network Denial of Service: Reflection Amplification',
  T1046: 'Network Service Discovery',
  T1135: 'Network Share Discovery',
  T1040: 'Network Sniffing',
  T1095: 'Non-Application Layer Protocol',
  T1571: 'Non-Standard Port',
  T1003: 'OS Credential Dumping',
  'T1003.008': 'OS Credential Dumping: /etc/passwd and /etc/shadow',
  'T1003.005': 'OS Credential Dumping: Cached Domain Credentials',
  'T1003.006': 'OS Credential Dumping: DCSync',
  'T1003.004': 'OS Credential Dumping: LSA Secrets',
  'T1003.001': 'OS Credential Dumping: LSASS Memory',
  'T1003.003': 'OS Credential Dumping: NTDS',
  'T1003.007': 'OS Credential Dumping: Proc Filesystem',
  'T1003.002': 'OS Credential Dumping: Security Account Manager',
  T1027: 'Obfuscated Files or Information',
  'T1027.001': 'Obfuscated Files or Information: Binary Padding',
  'T1027.004': 'Obfuscated Files or Information: Compile After Delivery',
  'T1027.006': 'Obfuscated Files or Information: HTML Smuggling',
  'T1027.005': 'Obfuscated Files or Information: Indicator Removal from Tools',
  'T1027.002': 'Obfuscated Files or Information: Software Packing',
  'T1027.003': 'Obfuscated Files or Information: Steganography',
  T1588: 'Obtain Capabilities',
  'T1588.003': 'Obtain Capabilities: Code Signing Certificates',
  'T1588.004': 'Obtain Capabilities: Digital Certificates',
  'T1588.005': 'Obtain Capabilities: Exploits',
  'T1588.001': 'Obtain Capabilities: Malware',
  'T1588.002': 'Obtain Capabilities: Tool',
  'T1588.006': 'Obtain Capabilities: Vulnerabilities',
  T1137: 'Office Application Startup',
  'T1137.006': 'Office Application Startup: Add-ins',
  'T1137.001': 'Office Application Startup: Office Template Macros',
  'T1137.002': 'Office Application Startup: Office Test',
  'T1137.003': 'Office Application Startup: Outlook Forms',
  'T1137.004': 'Office Application Startup: Outlook Home Page',
  'T1137.005': 'Office Application Startup: Outlook Rules',
  T1201: 'Password Policy Discovery',
  T1120: 'Peripheral Device Discovery',
  T1069: 'Permission Groups Discovery',
  'T1069.003': 'Permission Groups Discovery: Cloud Groups',
  'T1069.002': 'Permission Groups Discovery: Domain Groups',
  'T1069.001': 'Permission Groups Discovery: Local Groups',
  T1566: 'Phishing',
  T1598: 'Phishing for Information',
  'T1598.002': 'Phishing for Information: Spearphishing Attachment',
  'T1598.003': 'Phishing for Information: Spearphishing Link',
  'T1598.001': 'Phishing for Information: Spearphishing Service',
  'T1566.001': 'Phishing: Spearphishing Attachment',
  'T1566.002': 'Phishing: Spearphishing Link',
  'T1566.003': 'Phishing: Spearphishing via Service',
  T1647: 'Plist File Modification',
  T1542: 'Pre-OS Boot',
  'T1542.003': 'Pre-OS Boot: Bootkit',
  'T1542.002': 'Pre-OS Boot: Component Firmware',
  'T1542.004': 'Pre-OS Boot: ROMMONkit',
  'T1542.001': 'Pre-OS Boot: System Firmware',
  'T1542.005': 'Pre-OS Boot: TFTP Boot',
  T1057: 'Process Discovery',
  T1055: 'Process Injection',
  'T1055.004': 'Process Injection: Asynchronous Procedure Call',
  'T1055.001': 'Process Injection: Dynamic-link Library Injection',
  'T1055.011': 'Process Injection: Extra Window Memory Injection',
  'T1055.015': 'Process Injection: ListPlanting',
  'T1055.002': 'Process Injection: Portable Executable Injection',
  'T1055.009': 'Process Injection: Proc Memory',
  'T1055.013': 'Process Injection: Process Doppelgänging',
  'T1055.012': 'Process Injection: Process Hollowing',
  'T1055.008': 'Process Injection: Ptrace System Calls',
  'T1055.003': 'Process Injection: Thread Execution Hijacking',
  'T1055.005': 'Process Injection: Thread Local Storage',
  'T1055.014': 'Process Injection: VDSO Hijacking',
  T1572: 'Protocol Tunneling',
  T1090: 'Proxy',
  'T1090.004': 'Proxy: Domain Fronting',
  'T1090.002': 'Proxy: External Proxy',
  'T1090.001': 'Proxy: Internal Proxy',
  'T1090.003': 'Proxy: Multi-hop Proxy',
  T1012: 'Query Registry',
  T1620: 'Reflective Code Loading',
  T1219: 'Remote Access Software',
  T1563: 'Remote Service Session Hijacking',
  'T1563.002': 'Remote Service Session Hijacking: RDP Hijacking',
  'T1563.001': 'Remote Service Session Hijacking: SSH Hijacking',
  T1021: 'Remote Services',
  'T1021.003': 'Remote Services: Distributed Component Object Model',
  'T1021.001': 'Remote Services: Remote Desktop Protocol',
  'T1021.002': 'Remote Services: SMB/Windows Admin Shares',
  'T1021.004': 'Remote Services: SSH',
  'T1021.005': 'Remote Services: VNC',
  'T1021.006': 'Remote Services: Windows Remote Management',
  T1018: 'Remote System Discovery',
  T1091: 'Replication Through Removable Media',
  T1496: 'Resource Hijacking',
  T1207: 'Rogue Domain Controller',
  T1014: 'Rootkit',
  T1053: 'Scheduled Task/Job',
  'T1053.002': 'Scheduled Task/Job: At',
  'T1053.007': 'Scheduled Task/Job: Container Orchestration Job',
  'T1053.003': 'Scheduled Task/Job: Cron',
  'T1053.005': 'Scheduled Task/Job: Scheduled Task',
  'T1053.006': 'Scheduled Task/Job: Systemd Timers',
  T1029: 'Scheduled Transfer',
  T1113: 'Screen Capture',
  T1597: 'Search Closed Sources',
  'T1597.002': 'Search Closed Sources: Purchase Technical Data',
  'T1597.001': 'Search Closed Sources: Threat Intel Vendors',
  T1596: 'Search Open Technical Databases',
  'T1596.004': 'Search Open Technical Databases: CDNs',
  'T1596.001': 'Search Open Technical Databases: DNS/Passive DNS',
  'T1596.003': 'Search Open Technical Databases: Digital Certificates',
  'T1596.005': 'Search Open Technical Databases: Scan Databases',
  'T1596.002': 'Search Open Technical Databases: WHOIS',
  T1593: 'Search Open Websites/Domains',
  'T1593.002': 'Search Open Websites/Domains: Search Engines',
  'T1593.001': 'Search Open Websites/Domains: Social Media',
  T1594: 'Search Victim-Owned Websites',
  T1505: 'Server Software Component',
  'T1505.004': 'Server Software Component: IIS Components',
  'T1505.001': 'Server Software Component: SQL Stored Procedures',
  'T1505.005': 'Server Software Component: Terminal Services DLL',
  'T1505.002': 'Server Software Component: Transport Agent',
  'T1505.003': 'Server Software Component: Web Shell',
  T1489: 'Service Stop',
  T1129: 'Shared Modules',
  T1072: 'Software Deployment Tools',
  T1518: 'Software Discovery',
  'T1518.001': 'Software Discovery: Security Software Discovery',
  T1608: 'Stage Capabilities',
  'T1608.004': 'Stage Capabilities: Drive-by Target',
  'T1608.003': 'Stage Capabilities: Install Digital Certificate',
  'T1608.005': 'Stage Capabilities: Link Target',
  'T1608.001': 'Stage Capabilities: Upload Malware',
  'T1608.002': 'Stage Capabilities: Upload Tool',
  T1528: 'Steal Application Access Token',
  T1539: 'Steal Web Session Cookie',
  T1558: 'Steal or Forge Kerberos Tickets',
  'T1558.004': 'Steal or Forge Kerberos Tickets: AS-REP Roasting',
  'T1558.001': 'Steal or Forge Kerberos Tickets: Golden Ticket',
  'T1558.003': 'Steal or Forge Kerberos Tickets: Kerberoasting',
  'T1558.002': 'Steal or Forge Kerberos Tickets: Silver Ticket',
  T1553: 'Subvert Trust Controls',
  'T1553.002': 'Subvert Trust Controls: Code Signing',
  'T1553.006': 'Subvert Trust Controls: Code Signing Policy Modification',
  'T1553.001': 'Subvert Trust Controls: Gatekeeper Bypass',
  'T1553.004': 'Subvert Trust Controls: Install Root Certificate',
  'T1553.005': 'Subvert Trust Controls: Mark-of-the-Web Bypass',
  'T1553.003': 'Subvert Trust Controls: SIP and Trust Provider Hijacking',
  T1195: 'Supply Chain Compromise',
  'T1195.003': 'Supply Chain Compromise: Compromise Hardware Supply Chain',
  'T1195.001': 'Supply Chain Compromise: Compromise Software Dependencies and Development Tools',
  'T1195.002': 'Supply Chain Compromise: Compromise Software Supply Chain',
  T1218: 'System Binary Proxy Execution',
  'T1218.003': 'System Binary Proxy Execution: CMSTP',
  'T1218.001': 'System Binary Proxy Execution: Compiled HTML File',
  'T1218.002': 'System Binary Proxy Execution: Control Panel',
  'T1218.004': 'System Binary Proxy Execution: InstallUtil',
  'T1218.014': 'System Binary Proxy Execution: MMC',
  'T1218.013': 'System Binary Proxy Execution: Mavinject',
  'T1218.005': 'System Binary Proxy Execution: Mshta',
  'T1218.007': 'System Binary Proxy Execution: Msiexec',
  'T1218.008': 'System Binary Proxy Execution: Odbcconf',
  'T1218.009': 'System Binary Proxy Execution: Regsvcs/Regasm',
  'T1218.010': 'System Binary Proxy Execution: Regsvr32',
  'T1218.011': 'System Binary Proxy Execution: Rundll32',
  'T1218.012': 'System Binary Proxy Execution: Verclsid',
  T1082: 'System Information Discovery',
  T1614: 'System Location Discovery',
  'T1614.001': 'System Location Discovery: System Language Discovery',
  T1016: 'System Network Configuration Discovery',
  'T1016.001': 'System Network Configuration Discovery: Internet Connection Discovery',
  T1049: 'System Network Connections Discovery',
  T1033: 'System Owner/User Discovery',
  T1216: 'System Script Proxy Execution',
  'T1216.001': 'System Script Proxy Execution: PubPrn',
  T1007: 'System Service Discovery',
  T1569: 'System Services',
  'T1569.001': 'System Services: Launchctl',
  'T1569.002': 'System Services: Service Execution',
  T1529: 'System Shutdown/Reboot',
  T1124: 'System Time Discovery',
  T1080: 'Taint Shared Content',
  T1221: 'Template Injection',
  T1205: 'Traffic Signaling',
  'T1205.001': 'Traffic Signaling: Port Knocking',
  T1537: 'Transfer Data to Cloud Account',
  T1127: 'Trusted Developer Utilities Proxy Execution',
  'T1127.001': 'Trusted Developer Utilities Proxy Execution: MSBuild',
  T1199: 'Trusted Relationship',
  T1552: 'Unsecured Credentials',
  'T1552.003': 'Unsecured Credentials: Bash History',
  'T1552.005': 'Unsecured Credentials: Cloud Instance Metadata API',
  'T1552.007': 'Unsecured Credentials: Container API',
  'T1552.001': 'Unsecured Credentials: Credentials In Files',
  'T1552.002': 'Unsecured Credentials: Credentials in Registry',
  'T1552.006': 'Unsecured Credentials: Group Policy Preferences',
  'T1552.004': 'Unsecured Credentials: Private Keys',
  T1535: 'Unused/Unsupported Cloud Regions',
  T1550: 'Use Alternate Authentication Material',
  'T1550.001': 'Use Alternate Authentication Material: Application Access Token',
  'T1550.002': 'Use Alternate Authentication Material: Pass the Hash',
  'T1550.003': 'Use Alternate Authentication Material: Pass the Ticket',
  'T1550.004': 'Use Alternate Authentication Material: Web Session Cookie',
  T1204: 'User Execution',
  'T1204.002': 'User Execution: Malicious File',
  'T1204.003': 'User Execution: Malicious Image',
  'T1204.001': 'User Execution: Malicious Link',
  T1078: 'Valid Accounts',
  'T1078.004': 'Valid Accounts: Cloud Accounts',
  'T1078.001': 'Valid Accounts: Default Accounts',
  'T1078.002': 'Valid Accounts: Domain Accounts',
  'T1078.003': 'Valid Accounts: Local Accounts',
  T1125: 'Video Capture',
  T1497: 'Virtualization/Sandbox Evasion',
  'T1497.001': 'Virtualization/Sandbox Evasion: System Checks',
  'T1497.003': 'Virtualization/Sandbox Evasion: Time Based Evasion',
  'T1497.002': 'Virtualization/Sandbox Evasion: User Activity Based Checks',
  T1600: 'Weaken Encryption',
  'T1600.002': 'Weaken Encryption: Disable Crypto Hardware',
  'T1600.001': 'Weaken Encryption: Reduce Key Space',
  T1102: 'Web Service',
  'T1102.002': 'Web Service: Bidirectional Communication',
  'T1102.001': 'Web Service: Dead Drop Resolver',
  'T1102.003': 'Web Service: One-Way Communication',
  T1047: 'Windows Management Instrumentation',
  T1220: 'XSL Script Processing',
};
