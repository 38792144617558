import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Switch from '@material-ui/core/Switch';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

const StyledSwitch = styled(Switch)`
  margin-left: 5px;
  margin-right: 5px;
`;

export interface IDiscoverSwitchProps {
  value: string;
  onChange: (value: string) => void;
}

export const DiscoverSwitch = ({ value, onChange }: IDiscoverSwitchProps) => {
  const { classes } = useStyles();

  const _onChange = (e: any) => {
    onChange(value === 'emergingThreats' ? 'marketplaceStats' : 'emergingThreats');
  };

  return (
    <div>
      <span
        css={[classes.label, value === 'emergingThreats' ? classes.active : null]}
        onClick={_onChange}>
        <FormattedMessage id='discover.switch.emergingThreats' defaultMessage='Emerging Threats' />
      </span>
      <StyledSwitch color='primary' checked={value === 'marketplaceStats'} onChange={_onChange} />
      <span
        css={[classes.label, value === 'marketplaceStats' ? classes.active : null]}
        onClick={_onChange}>
        <FormattedMessage
          id='discover.switch.marketplaceStats'
          defaultMessage='Marketplace Stats'
        />
      </span>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    label: {
      cursor: 'pointer',
      fontSize: '1.5rem',
    },
    active: {
      fontWeight: 'bold',
    },
  },
  light: {
    label: {
      color: styles.color.lightGrey,
    },
    active: {
      color: styles.color.purple,
    },
  },
  dark: {
    label: {
      color: styles.color.xLightGrey,
    },
    active: {
      color: styles.color.lightBlue,
    },
  },
});
