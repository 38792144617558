import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import styles from 'views/styles';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

const messages = defineMessages({
  button: {
    id: 'rewardsTab.sessionExpired.modal.button',
    defaultMessage: 'Back to dashboard',
  },
  heading: {
    id: 'rewardsTab.sessionExpired.modal.heading',
    defaultMessage: 'Session Expired',
  },
});

const SessionExpired = () => {
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const _closeModal = () => dispatch(closeModal());

  return (
    <ConfirmModal
      css={ownStyle}
      isLoading={false}
      content={<p>{intl.formatMessage(messages.heading)}</p>}
      submitBtnText={intl.formatMessage(messages.button)}
      submitBtnVariant='outlined'
      disabled={false}
      onSubmit={_closeModal}
    />
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;

  p {
    font-size: 2.5rem;
    font-weight: 500;
    color: ${styles.color.darkRed};
  }
`;

export default SessionExpired;
