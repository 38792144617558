import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styles from 'views/styles';
import { UserProps } from 'views/components/providers/UserProvider';
import Card from 'views/components/layout/Card';
import { UserAvatar } from 'views/components/Avatar';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface Props extends UserProps {
  className?: string;
}

const AccountSidebar = ({ className, user }: Props) => {
  const { classes } = useStyles();
  const { name, email, firstName, lastName, accountNumber } = user;

  return (
    <Card className={className} css={classes.root}>
      <div css={classes.avatarWrap}>
        {name && (
          <UserAvatar css={classes.avatar} name={name} accountNumber={`${accountNumber}`} />
        )}
      </div>
      <div css={classes.infoWrap}>
        <p className='h4 h-mb-tiny' data-cy='profileUsername'>
          {name}
        </p>
        <p data-cy='profileName'>{`${firstName} ${lastName}`}</p>
        <p>{email}</p>
        <p>#{accountNumber}</p>
      </div>
    </Card>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      padding: `0 ${styles.spacing.sm}`,
      textAlign: 'center',
      wordBreak: 'break-all',
    },
    avatarWrap: {
      padding: `${styles.spacing.sm} 0`,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      fontSize: '3.5rem',
    },
    avatar: {
      margin: '0 auto',
    },
    infoWrap: {
      padding: `${styles.spacing.sm} 0`,
    },
  },
  light: {
    avatarWrap: {
      borderBottomColor: styles.border.color.grey,
    },
  },
  dark: {
    avatarWrap: {
      borderBottomColor: styles.border.color.medDarkPurple,
    },
  },
});

export default AccountSidebar;
