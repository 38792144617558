import React from 'react'; // eslint-disable-line
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Formik, FormikProps } from 'formik';
import Button from '@material-ui/core/Button';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Modal from 'views/components/Modal';
import FormSelect from 'views/components/form/FormSelect';

export interface SetGroundTruthModalParams {
  setOption: (option: string) => Promise<void>;
}

interface ISetGroundTruthFormValues {
  option: string;
}

const ContentWrapper = styled.div`
  width: 548px;
  padding: ${styles.spacing.md};
`;

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  *:first-of-type {
    margin-right: 5px !important;
  }

  *:last-of-type {
    margin-left: 5px !important;
  }
`;

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

const SetGroundTruthModalModal = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { setOption } = useSelector(
    ({ modal }: { modal: ModalState<SetGroundTruthModalParams> }) => modal.params
  );
  const closeThisModal = () => dispatch(closeModal());

  return (
    <Modal>
      <ContentWrapper>
        <div css={classes.header}>
          <FormattedMessage
            id='microengines.setExpectedVerdict.header'
            defaultMessage='Set Expected Verdict'
          />
        </div>
        <Formik
          onSubmit={(values: ISetGroundTruthFormValues) => {
            if (values.option) {
              setOption(values.option);
              closeThisModal();
            }
          }}
          initialValues={{ option: '' }}>
          {(formik: FormikProps<ISetGroundTruthFormValues>) => (
            <form onSubmit={formik.handleSubmit}>
              <FormSelect
                name='option'
                label='Expected Verdict'
                options={[
                  { value: 'malicious', label: 'malicious' },
                  { value: 'benign', label: 'benign' },
                  { value: 'suspicious', label: 'suspicious' },
                  { value: 'unknown', label: 'unknown' },
                ]}
              />

              <Buttons>
                <StyledButton
                  data-cy='submitBtn'
                  className='h-mt-xs'
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!(formik.dirty && formik.isValid)}>
                  <FormattedMessage {...btnMessages.save} />
                </StyledButton>
                <StyledButton
                  data-cy='cancelBtn'
                  className='h-mt-xs'
                  color='primary'
                  variant='outlined'
                  onClick={closeThisModal}>
                  <FormattedMessage {...btnMessages.cancel} />
                </StyledButton>
              </Buttons>
            </form>
          )}
        </Formik>
      </ContentWrapper>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    header: {
      textAlign: 'left',
      fontSize: '2.6rem',
      fontWeight: 'bold',
      paddingBottom: styles.spacing.xs,
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
  },
});

export default SetGroundTruthModalModal;
