import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';
import styles from 'views/styles';

export default () => (
  <Fragment>
    <symbol id='icon-plus-alt' viewBox='0 0 32 32'>
      <path d='M16 2.667c-7.364 0-13.333 5.97-13.333 13.333s5.97 13.333 13.333 13.333c7.364 0 13.333-5.97 13.333-13.333 0-3.536-1.405-6.928-3.905-9.428s-5.892-3.905-9.428-3.905zM16 26.667c-5.891 0-10.667-4.776-10.667-10.667s4.776-10.667 10.667-10.667c5.891 0 10.667 4.776 10.667 10.667 0 2.829-1.124 5.542-3.124 7.542s-4.713 3.124-7.542 3.124zM17.067 10.667h-2.133v4.267h-4.267v2.133h4.267v4.267h2.133v-4.267h4.267v-2.133h-4.267v-4.267z' />
    </symbol>

    <Global
      styles={css`
        .icon-plus-alt {
          width: ${styles.icon.width};
          height: ${styles.icon.height};
        }
      `}
    />
  </Fragment>
);
