import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useUser } from 'views/components/providers/UserProvider';
import { useTheme } from 'views/components/providers/ThemeProvider';
import { Variant, variants } from 'views/components/ThemePreview';
import { showNotification } from 'state/notification/actions';
import { RootState } from 'state/root';
import { getTeamAccount, getTeamByAccount } from 'state/team/actions';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';

import pattern from 'assets/images/pattern.svg';
import api from 'services/api';

const applyTheme = (variant: Partial<Variant>, theme: 'light' | 'dark') => {
  document.documentElement.style.setProperty(
    '--community-background',
    variants[theme === 'light' ? variant : (`${variant}-dark` as Partial<Variant>)].background
  );
  document.documentElement.style.setProperty('--community-background-image', `url(${pattern})`);
};

export default function usePrivateCommunityTheme(isPrivate: boolean = false) {
  const theme = useTheme();
  const user = useUser();
  const ctx = contextAccount(store);
  const { single: team } = useSelector(({ team }: RootState) => team);
  const [variant, setVariant] = useState<Partial<Variant>>();
  const dispatch = useDispatch();
  const isManualPrivate = !!isPrivate;
  const isPrivateCommunity = user.context?.isPrivate;
  const context = ctx?.context,
    accountNumber = ctx?.accountNumber,
    teamAccountNumber = team?.accountNumber;

  useEffect(() => {
    async function getTeamData() {
      if (context === 'team' && String(accountNumber) !== String(teamAccountNumber)) {
        await dispatch(getTeamByAccount(`${accountNumber}`));
        dispatch(getTeamAccount(`${accountNumber}`));
      }
    }
    getTeamData();
  }, [context, accountNumber, teamAccountNumber]); // eslint-disable-line

  useEffect(() => {
    async function getTeamTheme() {
      const { data } = await api.getTeamByAccount(teamAccountNumber!);
      const teamPrivateCommunityTheme = data.theme ? (data.theme as Partial<Variant>) : 'salmon';
      setVariant(teamPrivateCommunityTheme);
      isPrivateCommunity && applyTheme(teamPrivateCommunityTheme, theme);
    }

    if (teamAccountNumber && (isPrivateCommunity || isManualPrivate)) {
      getTeamTheme();
    }
  }, [isManualPrivate, teamAccountNumber, theme, isPrivateCommunity]);

  return {
    variant: variant || team?.theme || 'default',
    theme: theme,
    setVariant: async (variant: Partial<Variant>) => {
      const teamAccountNumber = team?.accountNumber;
      if (teamAccountNumber && team?.name) {
        try {
          await api.setTeamTheme(teamAccountNumber, variant);
          setVariant(variant);
          applyTheme(variant, theme);
          dispatch(
            showNotification({
              status: 'success',
              message: `Private mode theme has been updated for all ${team.name} members`,
              delay: 1000 * 10,
            })
          );
        } catch (e) {
          dispatch(
            showNotification({
              status: 'failure',
              message: 'Failed to update theme. Please try again later.',
              delay: 1000 * 10,
            })
          );
        }
      }
    },
  };
}
