import { AccountPlan } from 'models/Account';

const COMMON = {
  name: 'Custom',
  isPrivate: false,
  features: {
    apiRequestLimit: {
      value: true,
    },
    monthlyApiLimit: {
      value: true,
    },
    downloads: {
      value: true,
    },
    hashSearches: {
      value: true,
    },
    historicalHunts: {
      value: true,
    },
    hunting: {
      value: true,
    },
    huntingYaraRules: {
      value: true,
    },
    liveHunts: {
      value: true,
    },
    metadataSearches: {
      value: true,
    },
    additionalFeatures: {
      value: true,
    },
    hasSupport: {
      value: true,
    },
    privateComm: {
      value: true,
    },
    baracuda: {
      value: true,
    },
    createTeams: {
      value: true,
    },
    integrationsOk: {
      value: true,
    },
    price: {
      value: false,
    },
    scans: {
      value: true,
    },
    sandboxing: {
      value: true,
    },
    sandboxRequest: {
      value: true,
    },
    sandboxSearch: {
      value: true,
    },
    hasStream: {
      value: true,
    },
    hasStreamAccess: {
      value: true,
    },
    taxii: {
      value: true,
    },
  },
};

export const CUSTOM_MONTHLY_PLAN: AccountPlan = {
  ...COMMON,
  interval: 'year',
  payment_plan_id: '-1',
};
export const CUSTOM_YEARL_PLAN: AccountPlan = {
  ...COMMON,
  interval: 'month',
  payment_plan_id: '-2',
};

export const CUSTOM_PLANS = [CUSTOM_MONTHLY_PLAN, CUSTOM_YEARL_PLAN];
