import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-microengine' viewBox='0 0 32 32'>
      <path d='M19.733 4.933c-0.4-0.533-1.333-0.667-1.867-0.133l-13.333 10.933c-0.4 0.133-0.533 0.667-0.533 1.067 1.2-1.2 2.8-1.6 4.533-1.067l10.933-8.933c0.667-0.533 0.667-1.333 0.267-1.867z' />
      <path d='M8 21.6c-0.133 1.6-1.467 0.667-2.8 0.667l0.133 0.667c0.133 0.533 0.4 0.8 0.933 0.933l17.333 5.067c0.133 0 0.267 0 0.4 0 0.267 0 0.4 0 0.667-0.133l0.4-0.133c-0.533-0.667-1.733-1.467-1.867-2.533l-15.2-4.533z' />
      <path d='M28 8c-0.8 0-1.333 0.533-1.333 1.333v13.333c1.067 1.067 1.333 2.267 1.333 4.267l0.667-0.4c0.4-0.267 0.667-0.667 0.667-1.2v-16c0-0.8-0.533-1.333-1.333-1.333z' />
      <path d='M22.667 16c-3.733 0-6.667-2.933-6.667-6.667s2.933-6.667 6.667-6.667c3.467 0 6.267 2.533 6.667 6v0c0 0.267 0 0.4 0 0.667 0 3.733-2.933 6.667-6.667 6.667zM22.667 5.333c-2.267 0-4 1.733-4 4s1.733 4 4 4 4-1.733 4-4c0-0.133 0-0.267 0-0.4-0.267-2-1.867-3.6-4-3.6z' />
      <path d='M7.333 24c-2.533 0-4.667-2.133-4.667-4.667 0-1.467 0.667-2.933 1.867-3.733v0c0.8-0.667 1.867-0.933 2.8-0.933 2.533 0 4.667 2.133 4.667 4.667s-2.133 4.667-4.667 4.667zM6.133 17.733c-0.533 0.4-0.8 0.933-0.8 1.6 0 1.067 0.933 2 2 2s2-0.933 2-2-0.933-2-2-2c-0.4 0-0.8 0.133-1.2 0.4v0z' />
      <path d='M25.333 29.333c-2.267 0-4-1.733-4-4s1.733-4 4-4 4 1.733 4 4-1.733 4-4 4zM25.333 24c-0.8 0-1.333 0.533-1.333 1.333s0.533 1.333 1.333 1.333 1.333-0.533 1.333-1.333-0.533-1.333-1.333-1.333z' />
      <path d='M24 9.333c0 0.736-0.597 1.333-1.333 1.333s-1.333-0.597-1.333-1.333c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333z' />
    </symbol>

    <Global
      styles={css`
        .icon-microengine {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
