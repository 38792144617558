import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Payout from './Payout';

interface IBids {
  className?: string;
  items: IBidItem[];
}

interface IBidItem {
  result?: string;
  bid?: number;
  showSign?: boolean;
}

const Bids = ({ className, items }: IBids) => {
  const { classes } = useStyles();
  return (
    <div className={className} css={classes.bids}>
      {items.map(({ result, bid, showSign }) => (
        <Bid key={`bid-${bid}`} result={result} bid={bid} showSign={showSign} />
      ))}
    </div>
  );
};

const Bid = ({ result, bid, showSign }: IBidItem) => {
  const { classes } = useStyles();
  return (
    <div className='bid' css={classes.bid}>
      <div className='result' css={classes.result}>
        <span>{result}</span>
      </div>
      <Payout bid={bid} showSign={showSign} />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    bids: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '34rem',
    },
    bid: {
      display: 'flex',
    },
    result: {
      width: 40,
      height: 40,
      lineHeight: '40px',
      borderWidth: 2,
      borderStyle: 'solid',
      fontWeight: styles.font.weight.bold,
      marginRight: '1rem',
      span: {
        position: 'relative',
      },
    },
  },
  light: {
    result: {
      color: styles.color.purple,
      borderColor: styles.color.black,
    },
  },
  dark: {
    result: {
      color: styles.color.lightBlue,
      borderColor: styles.color.white,
    },
  },
});

export default Bids;
