import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-edit' viewBox='0 0 32 32'>
      <path d='M26.86 6.674l-1.534-1.534c-1.522-1.52-3.988-1.52-5.51 0l-15.816 15.829v7.031h7.031l15.829-15.816c1.52-1.522 1.52-3.988 0-5.51zM9.965 25.401h-3.366v-3.366l11.423-11.423 3.366 3.366-11.423 11.423zM25.014 10.339l-1.793 1.793-3.353-3.353 1.793-1.793c0.507-0.504 1.326-0.504 1.832 0l1.521 1.521c0.243 0.245 0.379 0.577 0.377 0.923-0.001 0.341-0.137 0.668-0.377 0.91v0z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-edit {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
