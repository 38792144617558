import React, { Fragment } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import PageLayout from 'views/components/layout/PageLayout';

import SilobreakerPanel from './SilobreakerPanel';

const messages = defineMessages({
  title: {
    id: 'silobreakerpage.title',
    defaultMessage: 'Silobreaker',
  },
});

const SilobreakerPage = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <PageLayout>
          <SilobreakerPanel />
        </PageLayout>
      </BaseLayout>
    </Fragment>
  );
};

export default SilobreakerPage;
