import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-ping' viewBox='0 0 32 32'>
      <path
        d='M9.292 5.206c0 2.29-4.146 8.46-4.146 8.46S1 7.497 1 5.207a4.146 4.146 0 1 1 8.292 0Z'
        strokeWidth={1.133}
        strokeMiterlimit={10}
      />
    </symbol>

    <Global
      styles={css`
        .icon-ping {
          width: 4.5rem;
          height: 4.5rem;
        }
      `}
    />
  </Fragment>
);
