import React from 'react'; // eslint-disable-line
import { jsx, ObjectInterpolation } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { truncateValue } from 'utils/conversions/conversions';

interface IStatusColor {
  className?: string;
  classNumber?: ObjectInterpolation<any>;
  status: string;
  progress?: number | null;
}

export const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case 'completed':
    case 'complete':
      return styles.color.medGreen;
    case 'running':
    case 'pending':
      return styles.color.yellow;
    case 'failed':
    case 'canceled':
      return styles.color.red;
    default:
      return styles.color.xLightGrey;
  }
};

export const StatusColor = ({ className, classNumber, status, progress }: IStatusColor) => {
  const { classes } = useStyles(status);
  return (
    <div className={className}>
      <p css={[classes.num, classNumber]}>
        {progress && `${truncateValue(progress, 2)}% `}
        <span css={classes.status}>{status?.toLowerCase().replace('_', ' ')}</span>
      </p>
    </div>
  );
};

const useStyles = makeStyles((status: string) => {
  const color = getStatusColor(status);
  return {
    base: {
      num: {
        color,
        textTransform: 'capitalize',
      },
      status: {
        fontFamily: styles.font.family.main,
      },
    },
  };
});
