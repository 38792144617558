import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField/TextField';

interface SelectProps {
  options: string[];
  value: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (value: any) => void;
  onBlur?: (value: any) => void;
  otherProps?: any;
}

const Select: React.FC<SelectProps> = React.forwardRef(
  ({ options, value, placeholder = '', disabled, onChange, ...otherProps }, ref) => {
    return (
      <Autocomplete
        {...otherProps}
        options={options}
        value={value}
        onChange={(event, newValue) => onChange(newValue || '')}
        openOnFocus={true}
        disabled={disabled}
        disableCloseOnSelect={false}
        disableClearable={true}
        filterOptions={() => options}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            placeholder={placeholder}
            onKeyDown={(e) => e.preventDefault()}
            variant='outlined'
            InputLabelProps={{ shrink: false }}
            size='small'
          />
        )}
      />
    );
  }
);

export default Select;
