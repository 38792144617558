import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { defineMessages, useIntl } from 'react-intl';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from 'views/styles';
import styled from '@emotion/styled';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface IViewMoreProps {
  onClick: () => void;
}

const Wrapper = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
`;

const ViewMoreIcon = styled(FontAwesomeIcon)`
  color: ${styles.color.purple};
  font-size: 1.2em;
  margin-left: 0.5rem;
`;

const messages = defineMessages({
  viewMore: {
    id: 'viewMore.viewMore',
    defaultMessage: 'View More',
  },
});

const ViewMore = ({ onClick }: IViewMoreProps) => {
  const intl = useIntl();
  const { classes } = useStyles();
  return (
    <Wrapper data-cy='malwareViewMore' onClick={onClick} css={classes.wrapper}>
      {intl.formatMessage(messages.viewMore)}
      <ViewMoreIcon icon={faChevronDown} css={classes.icon} />
    </Wrapper>
  );
};
const useStyles = makeStyles({
  base: {
    wrapper: {
      color: styles.color.purple,
    },
    icon: {
      color: styles.color.purple,
    },
  },
  light: {
    wrapper: {
      color: styles.color.purple,
    },
    icon: {
      color: styles.color.purple,
    },
  },
  dark: {
    wrapper: {
      color: styles.color.xLightGrey,
    },
    icon: {
      color: styles.color.xLightGrey,
    },
  },
});

export default ViewMore;
