import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Button from '@material-ui/core/Button';

interface IProgressButton {
  time?: number;
  onTimeEnd: () => void;
  onClick?: () => void;
  children: React.ReactNode;
}

export const ProgressButton = ({ time = 10, onTimeEnd, onClick, children }: IProgressButton) => {
  const { classes } = useStyles();
  const [currentTime, setCurrentTime] = React.useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentTime < time) {
        setCurrentTime((prevTime) => {
          return prevTime < time ? prevTime + 1 : prevTime;
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentTime === time) {
      onTimeEnd();
    }
  }, [currentTime, onTimeEnd, time]);

  return (
    <Button css={classes.button} onClick={onClick} color='primary' variant='contained'>
      <div css={[classes.progress, { width: `${(currentTime * 100) / time}%` }]}></div>
      <div css={classes.text}>
        {children} ({time - currentTime})
      </div>
    </Button>
  );
};

const useStyles = makeStyles({
  base: {
    button: {
      position: 'relative',
      overflow: 'hidden',
    },
    text: {
      position: 'relative',
    },
    progress: {
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'white',
      opacity: 0.5,
      transition: 'width 0.3s ease-in-out',
    },
  },
  light: {},
  dark: {},
});
