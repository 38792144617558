import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { RootState } from 'state/root';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys } from 'utils/error';
import { TeamActionName } from 'state/team/types';
import { AccountActionName } from 'state/account/types';
import { archiveTeam } from 'state/team/actions';
import { closeModal, openModal } from 'state/modal/actions';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import ConfirmForm from 'views/components/form/ConfirmForm';
import Modal from 'views/components/Modal';
import { useUser } from 'views/components/providers/UserProvider';
import { SetupMfaPanel } from 'views/components/SetupMfaPanel/SetupMfaPanel';
import { hasMadeTeamMFARecently, loginWriteTeams } from 'state/auth/actions';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';

const messages = defineMessages({
  heading: {
    id: 'teams.modal.archiveHeading',
    defaultMessage: 'Are you sure you want to delete "{teamName}"?',
  },
  text: {
    id: 'teams.modal.archiveText',
    defaultMessage:
      'This action cannot be undone. All your data referring to this team will be lost. Please type in the team name to confirm your action.',
  },
});

const errorKeys: ErrorKeys = {};

const ArchiveTeamModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();

  const { team, requests } = useSelector((state: RootState) => state);
  const teamName = team?.single?.name!;
  const teamAccountNumber =
    team?.single?.accountNumber?.toString() ?? contextAccount(store)?.accountNumber!;

  const isLoading = loadingSelector(requests, [
    TeamActionName.ARCHIVE_TEAM,
    AccountActionName.GET_ACCOUNT_CONTEXT,
    AccountActionName.SWITCH_ACCOUNT,
  ]);

  const error = errorSelector(requests, [
    TeamActionName.ARCHIVE_TEAM,
    AccountActionName.GET_ACCOUNT_CONTEXT,
    AccountActionName.SWITCH_ACCOUNT,
  ]);

  const _closeModal = () => dispatch(closeModal());

  if (!user.mfaEnabled)
    return (
      <Modal>
        <SetupMfaPanel onSetup={_closeModal} onCancel={_closeModal} />
      </Modal>
    );

  if (!hasMadeTeamMFARecently()) {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({
              modal: 'ARCHIVE_TEAM',
              location: location.pathname,
              teamAccountNumber,
              teamName,
            })
          );
        },
      })
    );
  }

  return (
    <Modal>
      <ConfirmForm
        testId='archiveTeamModal'
        css={ownStyle}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        name={teamName}
        label={intl.formatMessage({ id: 'teams.form.name.label' })}
        heading={intl.formatMessage(messages.heading, { teamName })}
        text={intl.formatMessage(messages.text, { teamName })}
        buttonText={intl.formatMessage(btnMessages.delete)}
        buttonColor='secondary'
        onSubmit={() => dispatch(archiveTeam(teamAccountNumber, user.accountNumber))}
        onCancel={_closeModal}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default ArchiveTeamModal;
