import { useSelector } from 'react-redux';
import { RootState } from 'state/root';
import { AccountFeature } from 'models/Account';

export default function useTeamHasFeature(feature: string) {
  const { hasFeature } = useSelector<RootState, { hasFeature: boolean }>(({ team, account }) => {
    // Get features for either team or user depending on context
    let features: AccountFeature[] = [];
    if (account.context && account.context.team && team.account) {
      features = team.account.features;
    }

    const filteredFeature = features.find((f) => f.tag === feature);

    return {
      hasFeature: filteredFeature?.value || (filteredFeature?.remainingUses || 0) > 0,
    };
  });

  return { hasFeature, hasPermission: hasFeature };
}
