import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Tooltip } from '@material-ui/core';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import styles from 'views/styles';

interface Props {
  className?: string;
  filename: string;
  filesize: string;
}

const SidebarFileDetails = ({ className, filename, filesize }: Props) => {
  const { classes } = useStyles();
  return (
    <div className={className}>
      <div css={classes.content}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <Tooltip title={filename ?? undefined} placement='top'>
            <div css={classes.ellipticalLabelContainer}>
              <p css={classes.fileName} data-cy='fileName'>
                {filename}
              </p>
            </div>
          </Tooltip>
          {!!filename && typeof filename === 'string' && (
            <CopyTextButton css={classes.copyBtn} text={filename} />
          )}
        </div>
        <p css={classes.fileSize} data-cy='fileSize'>
          {filesize}
        </p>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    content: {
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      textAlign: 'center',
    },
    fileName: {
      lineHeight: 1.4,
      fontWeight: styles.font.weight.bold,
      marginBottom: '0.2rem',
    },
    fileSize: {
      fontSize: styles.font.size.p1,
    },
    ellipticalLabelContainer: {
      maxHeight: '12em',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '> p': {
        '-webkit-line-clamp': '8',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
    },
    copyBtn: {
      fontSize: '0.4rem',
      marginLeft: '0.6rem',
      verticalAlign: 'middle',
    },
  },
  light: {
    fileName: {
      color: styles.color.black,
    },
    fileSize: {
      color: styles.color.medGrey,
    },
  },
  dark: {
    fileName: {
      color: styles.color.white,
    },
    fileSize: {
      color: styles.color.xLightGrey,
    },
  },
});
export default SidebarFileDetails;
