import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { closeModal } from 'state/modal/actions';
import StatusModal from 'views/components/Modal/StatusModal';
import { ModalState } from 'state/modal';

export interface ArchiveTeamCompleteModalParams {
  teamName: string;
}

const messages = defineMessages({
  heading: {
    id: 'teams.modal.archiveCompleteHeading',
    defaultMessage: 'Team Deleted',
  },
  text: {
    id: 'teams.modal.archiveCompleteText',
    defaultMessage: 'The team named "{teamName}" has been deleted by the administrator.',
  },
  buttonText: {
    id: 'teams.modal.archiveCompleteButtonText',
    defaultMessage: 'OK',
  },
});

const ArchiveTeamCompleteModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { teamName } = useSelector(
    ({ modal }: { modal: ModalState<ArchiveTeamCompleteModalParams> }) => modal.params
  );

  return (
    <StatusModal
      state='info'
      value='!'
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text, { teamName })}
      buttonText={intl.formatMessage(messages.buttonText)}
      onClick={() => dispatch(closeModal())}
    />
  );
};

export default ArchiveTeamCompleteModal;
