import React from 'react';
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormikProps } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { formMessages } from 'utils/schema/formSchema';
import { btnMessages } from 'views/components/Button';
import Card from 'views/components/layout/Card';
import FormInput from 'views/components/form/FormInput';
import styles from 'views/styles';
import styled from '@emotion/styled';
import UsernameField from './UsernameField';
import { IAccountSignupValues } from 'models/Account';

interface IAccountSignupFormContentProps {
  formik: FormikProps<IAccountSignupValues>;
  errorMessage?: string | null;
}

const Wrapper = styled(Card)`
  position: relative;
  padding: ${styles.spacing.md};
  width: 500px;
  margin: 0 auto;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const ErrorMessage = styled.p`
  color: ${styles.color.red};
`;

const Title = styled.h1`
  text-align: center;
  margin-top: ${styles.spacing.sm};
  display: inline-block;
  width: auto;
  margin: 0 auto;
  border-bottom: 1px solid #6d3aec;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
`;

const Header = styled.h2`
  text-align: center;
`;

const FieldsWrapper = styled.div`
  margin-top: ${styles.spacing.xs};
`;

const AccountSignupFormContent = ({
  formik,
  errorMessage,
}: IAccountSignupFormContentProps) => {
  const intl = useIntl();

  return (
    <form onSubmit={formik.handleSubmit} data-cy='accountSignupForm'>
      <Wrapper>
        <Title
          className='h2 h-mb-tiny'
          css={{
            marginBottom: `${styles.spacing.xs} !important`,
            fontSize: styles.font.size.h3,
            color: styles.color.purple,
          }}
        >
          <FormattedMessage id='signup.title' defaultMessage='PolySwarm Signup' />
        </Title>

        <Header
          className='h2 h-mb-tiny'
          css={{
            fontSize: styles.font.size.h5,
            fontWeight: 600,
          }}
        >
          <FormattedMessage
            id='signup.firstStepHeader'
            defaultMessage='Basic contact information'
          />
        </Header>
        <FieldsWrapper>
          <UsernameField name='username' label={intl.formatMessage(formMessages.username)} />
          <FormInput name='firstName' label={intl.formatMessage(formMessages.firstName)} />
          <FormInput name='lastName' label={intl.formatMessage(formMessages.lastName)} />
          <FormInput name='jobTitle' label={intl.formatMessage(formMessages.jobTitle)} />
          <FormInput name='company' label={intl.formatMessage(formMessages.company)} />
        </FieldsWrapper>
        {errorMessage && <ErrorMessage className='p1 h-mt-xxs'>{errorMessage}</ErrorMessage>}

        <SubmitButton
          data-cy='signupSubmitBtn'
          className='h-mt-xs'
          color='primary'
          variant='contained'
          type='submit'
          disabled={!formik.isValid}
        >
          <FormattedMessage {...btnMessages.submit} />
        </SubmitButton>
      </Wrapper>
    </form>
  );
};

export default AccountSignupFormContent;
