import React, { Fragment, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import Menu from 'material-ui-popup-state/HoverMenu';
import MenuItem from '@material-ui/core/MenuItem';

interface ITagsTooltipProps {
  label: string;
  tags: string[];
  onClick?: (tag: string) => void;
}

const TagsTooltip = ({ label, tags, onClick }: ITagsTooltipProps) => {
  const { classes } = useStyles();
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  return (
    <React.Fragment>
      <span css={classes.label} {...bindHover(popupState)}>
        {label}
      </span>

      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        {tags.map((tag) => (
          <MenuItem
            onClick={() => {
              popupState.close();
              onClick && onClick(tag);
            }}>
            {tag}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    label: {
      color: styles.color.purple,
      fontWeight: styles.font.weight.medium,
      fontSize: '1.3rem',
      paddingLeft: '1rem',
      cursor: 'help',
    },
  },
  light: {
    label: {
      color: styles.color.purple,
    },
  },
  dark: {
    label: {
      color: styles.color.lightBlue,
    },
  },
});

export default TagsTooltip;
