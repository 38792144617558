import { Action } from 'redux';
import { AsyncAction } from 'state/types/actions';
import { TeamInvitationRes } from 'services/api/schema/team';
import { InvitationRes } from 'services/api/schema/invitations';

export enum InvitationActionName {
  GET_INVITATION = 'GET_INVITATION',
  HANDLE_INVITATION = 'HANDLE_INVITATION',
  SET_INVITE_TOKEN = 'SET_INVITE_TOKEN',
  REMOVE_INVITE_TOKEN = 'REMOVE_INVITE_TOKEN',
}

export interface GetInvitationAction
  extends AsyncAction<InvitationActionName.GET_INVITATION, TeamInvitationRes> {}

export interface HandleInvitationAction
  extends AsyncAction<InvitationActionName.HANDLE_INVITATION, InvitationRes> {}

export interface SetInviteTokenAction extends Action<InvitationActionName.SET_INVITE_TOKEN> {
  inviteToken: string;
}

export interface RemoveInviteTokenAction extends Action<InvitationActionName.REMOVE_INVITE_TOKEN> {}

export type InvitationAction =
  | GetInvitationAction
  | HandleInvitationAction
  | SetInviteTokenAction
  | RemoveInviteTokenAction;
