import { isNil } from 'lodash';
import { HuntingState } from '.';
import { HuntingAction, HuntingActionName } from './types';
import { createInitialPagination, buildPaginatedData } from 'utils/pagination';
import sortHuntsByRuleName from 'state/utils/sortHuntsByRuleName';
import { NormalizedArtifactMetadata, ArtifactMetadata } from 'models/Submission';
import { AccountActionName } from 'state/account/types';
import { ArtifactRes } from 'services/api/schema/submission';
import { RulesetRes, HuntRes, LiveHuntRes } from 'services/api/schema/hunting';

export const initialRulesets = createInitialPagination<RulesetRes>({
  orderBy: 'created',
  results: [],
});

export const initialLiveHunts = createInitialPagination<LiveHuntRes>({
  orderBy: 'created',
  results: [],
});

export const initialHistoricalHunts = createInitialPagination<HuntRes>({
  orderBy: 'created',
  results: [],
});

export const initialMetadataSearchResults = createInitialPagination<ArtifactRes>({
  orderBy: 'sha256',
  results: [],
});

export const initialState: HuntingState = {
  rulesets: initialRulesets,
  liveHunts: initialLiveHunts,
  liveHuntResults: null,
  historicalHunts: initialHistoricalHunts,
  historicalHuntResults: null,
  metadataSearchTerm: '',
  metadataSearchPage: initialMetadataSearchResults,
  metadataSearchResults: [],
};

const microengines = (state: HuntingState = initialState, action: HuntingAction): HuntingState => {
  switch (action.type) {
    case HuntingActionName.REFRESH_ALL_RULESETS:
      if (!action.result) {
        return state;
      }
      const rulesets = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        rulesets,
      };

    case HuntingActionName.GET_ALL_RULESETS: {
      if (!action.result) {
        return state;
      }
      const rulesets = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        rulesets: {
          ...rulesets,
          results: [...state.rulesets.results, ...rulesets.results],
        },
      };
    }

    case HuntingActionName.REFRESH_ALL_LIVE_HUNTS: {
      if (!action.result) {
        return state;
      }
      const liveHunts = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        liveHunts,
      };
    }

    case HuntingActionName.GET_ALL_LIVE_HUNTS: {
      if (!action.result) {
        return state;
      }
      const liveHunts = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        liveHunts: {
          ...liveHunts,
          results: [...state.liveHunts.results, ...liveHunts.results],
        },
      };
    }

    case HuntingActionName.GET_LIVE_HUNT_RESULTS: {
      return {
        ...state,
        liveHuntResults: action.result ? sortHuntsByRuleName(action.result) : state.liveHuntResults,
      };
    }

    case HuntingActionName.REFRESH_ALL_HISTORICAL_HUNTS: {
      if (!action.result) {
        return state;
      }
      const historicalHunts = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        historicalHunts,
      };
    }

    case HuntingActionName.GET_ALL_HISTORICAL_HUNTS: {
      if (!action.result) {
        return state;
      }
      const historicalHunts = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        historicalHunts: {
          ...historicalHunts,
          results: [...state.historicalHunts.results, ...historicalHunts.results],
        },
      };
    }

    case HuntingActionName.GET_HISTORICAL_HUNT_RESULTS: {
      if (!action.result) {
        return state;
      }
      const historicalHuntResults = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        historicalHuntResults: historicalHuntResults,
      };
    }

    case HuntingActionName.SET_METADATA_SEARCH_TERM: {
      return { ...state, metadataSearchTerm: action.term };
    }

    case HuntingActionName.GET_MORE_METADATA_SEARCH_RESULTS: /* fallthrough */
    case HuntingActionName.GET_METADATA_SEARCH_RESULTS: {
      if (!action.result) {
        return state;
      }
      const metadataSearchPage = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        metadataSearchResults: getArtifactMetaData(action.result.results),
        metadataSearchPage,
      };
    }

    case HuntingActionName.RESET_METADATA_SEARCH: {
      return { ...state, metadataSearchTerm: '', metadataSearchPage: initialMetadataSearchResults };
    }

    case HuntingActionName.RUN_LIVE_HUNT: {
      return { ...state, liveHunts: initialLiveHunts };
    }

    case HuntingActionName.RUN_HISTORICAL_HUNT: {
      return { ...state, historicalHunts: initialHistoricalHunts };
    }

    // Invalidate cache when switcing account
    case AccountActionName.SWITCH_ACCOUNT: {
      return {
        ...state,
        rulesets: initialRulesets,
        liveHunts: initialLiveHunts,
        liveHuntResults: null,
        historicalHunts: initialHistoricalHunts,
        historicalHuntResults: null,
      };
    }

    default: {
      return state;
    }
  }
};

/**
 * Sort the ArtifactMetadata object
 *
 * Objects in JS have a predictable order, therefore, we can simply delete the
 * property and move it to the end of the object to get the order we want.
 */
const sortArtifactMetadata = (metadata: ArtifactMetadata) => {
  if (metadata.hasOwnProperty('lief') && !isNil(metadata.lief)) {
    if (metadata.lief.hasOwnProperty('exported_functions')) {
      const expfn = metadata.lief.exported_functions;
      delete metadata.lief.exported_functions;
      metadata.lief.exported_functions = expfn;
    }
    if (metadata.lief.hasOwnProperty('imported_functions')) {
      const impfn = metadata.lief.imported_functions;
      delete metadata.lief.imported_functions;
      metadata.lief.imported_functions = impfn;
    }
  }
  return metadata;
};

/**
 * Normalize metadata and perform sorting
 */
export const normalizeMetadata = (metadata: ArtifactRes['metadata']) => {
  return sortArtifactMetadata(
    metadata.reduce((result, item) => {
      return { ...result, [item.tool]: item.tool_metadata };
    }, {} as ArtifactMetadata)
  );
};

export const normalizeArtifactMetadata = (data: ArtifactRes) => {
  const {
    filename,
    metadata,
    first_seen,
    last_seen,
    last_scanned,
    latest_scan,
    mimetype,
    extended_type,
    created,
    md5,
    sha1,
    sha256,
    detections,
    polyscore,
    polyunite_malware_family,
    latest_scan_polyscore,
    type,
    id,
  } = data;

  const normalizedMetadata = normalizeMetadata(metadata);
  const result: NormalizedArtifactMetadata = {
    attributes: {
      names: [filename],
      type,
      first_seen,
      last_seen,
      last_scanned,
      mimetype,
      extended_type,
      md5,
      sha1,
      sha256,
    },
    id,
    metadata: normalizedMetadata,
    sha256,
    submitted: created,
    last_seen: last_seen as string,
    latest_scan,
    detections,
    polyscore,
    polyunite_malware_family,
    latest_scan_polyscore,
    file_size: normalizedMetadata.exiftool ? (normalizedMetadata.exiftool.file_size as string) : '',
    file_type: normalizedMetadata.exiftool ? (normalizedMetadata.exiftool.file_type as string) : '',
  };

  // const malicious = assertions.dete .filter((a: any) => a.verdict === true).length;
  // const total = assertions.filter((a: any) => a.verdict !== null).length;
  // if (total > result.detections.total) {
  //   result.detections = { malicious, total };
  // }

  return result;
};

/**
 * Normalize artifact metadata
 */
export const getArtifactMetaData = (
  artifactMetadata: ArtifactRes[]
): NormalizedArtifactMetadata[] => artifactMetadata.map(normalizeArtifactMetadata);

export default microengines;
