import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { closeModal } from 'state/modal/actions';
import styles from 'views/styles';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

const messages = defineMessages({
  heading: {
    id: 'rewardsTab.configureWithdrawals.modal.heading',
    defaultMessage: 'Redeem NCT Rewards',
  },
  text: {
    id: 'rewardsTab.configureWithdrawals.modal.text',
    defaultMessage: 'Withdrawal Address Not Configured',
  },
  button: {
    id: 'rewardsTab.configureWithdrawals.modal.button',
    defaultMessage: 'Configure a wallet',
  },
});

const ConfigureWithdrawalsAlert = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <ConfirmModal
      testId='configureWithdrawalsAlertModal'
      isLoading={false}
      css={ownStyle}
      fullWidth={true}
      content={<span css={red}>{intl.formatMessage(messages.text)}</span>}
      heading={intl.formatMessage(messages.heading)}
      submitBtnText={intl.formatMessage(messages.button)}
      disabled={false}
      buttonsDirection='vertical'
      onSubmit={() => {
        history.replace('/account/wallets');
        dispatch(closeModal());
      }}
      onCancel={() => dispatch(closeModal())}
    />
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

const red = css`
  color: ${styles.color.red};
`;

export default ConfigureWithdrawalsAlert;
