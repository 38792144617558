import Big from 'big.js';
import { TimeSeriesDataRes } from 'services/api/schema/timeSeries';

export type GainsObject = { loss: boolean; value: number };

const calculateGains = (data: TimeSeriesDataRes[]): GainsObject | null => {
  if (!data.length) return null;
  const original = new Big(data[0].value);
  const current = new Big(data[data.length - 1].value);
  const diff = current.minus(original);
  const loss = diff.lt(0);
  const percentage = diff.abs().div(original.lt(current) ? current : original);
  return { loss, value: parseFloat(percentage.toString()) };
};

export default calculateGains;
