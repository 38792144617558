import React, { useMemo, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import has from 'lodash/has';
import xor from 'lodash/xor';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import { contextAccount } from 'state/auth/selectors';
import Modal from 'views/components/Modal';
import { store } from 'state/store';
import useFormBuilder, { IManual, FormReturn } from 'components/FormBuilder';
import Button from '@material-ui/core/Button/Button';
import { useUser } from 'views/components/providers/UserProvider';
import api from 'services/api';

export type Fields = {
  accountNumber: string;
  emails: string[];
  scope: string[];
  tags: string[];
  isPrivate: boolean;
  expire: boolean;
  date: string;
};
type ModalProps = {
  onSubmit: (values: Fields) => void;
  filters: Fields;
  hasPrivateCommunity: boolean;
};

const AddLimitedAccessModal = () => {
  const ctx = contextAccount(store);
  const teamAccountNumber = ctx?.accountNumber!;
  const { id: userId } = useUser();
  const { classes } = useStyles();
  const _closeModal = () => dispatch(closeModal());
  const dispatch = useDispatch<Dispatch>();
  const { filters, onSubmit, hasPrivateCommunity } = useSelector(
    ({ modal }: { modal: ModalState<ModalProps> }) => modal.params
  );

  const formBuilderConfig = useMemo(
    () => [
      {
        elementType: 'input',
        hideName: true,
        defaultValue: '',
        id: 'accountNumber',
        placeholder: 'Enter external team account number *',
      },
      {
        elementType: 'inputMultiple',
        id: 'emails',
        hideName: true,
        placeholder: 'Enter external member email address(es) *',
        defaultValue: [],
        rules: {
          validate: {
            maxEmails: (value: string[]) => {
              return value.length <= 20 || 'Maximum 20 emails.';
            },
            isEmail: (value: string[]) => {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

              return value.every((email) => emailRegex.test(email)) || 'Enter emails only.';
            },
          },
        },
      },
      {
        elementType: 'selectTag',
        id: 'scope',
        name: 'Scope *',
        defaultValue: [],
        placeholder: 'Select which scopes to include',
        defaultOptions: ['manual_analysis'],
        bold: false,
        hideName: true,
      },
      {
        elementType: 'inputMultiple',
        hideName: true,
        id: 'tags',
        placeholder: 'Custom tags (Optional)',
        defaultValue: [],
        rules: {
          validate: {
            maxTags: (value: string[]) => {
              return value.length <= 20 || 'Maximum 20 tags.';
            },
            noUnderscoreFirst: (value: string[]) => {
              const regex = /^[^_].*$/;

              return (
                value.every((tag) => regex.test(tag)) || 'No underscore as the first character.'
              );
            },
            isValid: (value: string[]) => {
              const tagRegex = /^[\w .\-:]{1,39}$/;

              return (
                value.every((tag) => tagRegex.test(tag)) ||
                'Please only use letters, numbers, colon, space, period, underscore and hyphen.'
              );
            },
          },
        },
      },
      {
        elementType: 'switch',
        name: 'Is Private',
        id: 'isPrivate',
        defaultValue: hasPrivateCommunity,
        show: hasPrivateCommunity,
        defaultOptions: ['Public', 'Private'],
        bold: false,
      },
      {
        name: 'Expiration Date',
        hideName: true,
        combine: [
          {
            elementType: { name: 'input', type: 'date' },
            placeholder: 'Expiration Date *',
            id: 'date',
            defaultValue: '',
          },
          { elementType: 'checkbox', name: 'Never Expire', id: 'expire', defaultValue: false },
        ],
      },
    ],
    [hasPrivateCommunity]
  );

  const { FormComponent } = useFormBuilder(formBuilderConfig as IManual[]);

  const validate = async (
    values: {
      emails: string[];
      tags: string[];
      isPrivate: boolean;
      accountNumber: string;
      scope: string[];
      expire: boolean;
      date: string;
    },
    setError: FormReturn['setError']
  ) => {
    let validationResponse;

    try {
      const data = {
        type: 'portal-external',
        teamAccountNumber: `${teamAccountNumber}`,
        emails: values.emails,
        community: values.isPrivate ? 'private' : 'public',
        tags: [`team:${values.accountNumber}`].concat(values.tags),
        scope: { feature_tags: values.scope },
        expiration: values?.expire ? '' : new Date(values.date).toISOString(),
      };

      validationResponse = await api.limitedAccessValidation(userId as number, data);

      if (validationResponse && validationResponse.status === 204) {
        return true;
      }
    } catch (e: unknown) {
      if ((e as AxiosError)?.name === 'AxiosError') {
        const eData = (e as AxiosError)?.response?.data as { message: string };

        setError('requestError', {
          type: 'request',
          message: typeof eData?.message === 'string' ? eData.message : 'Something went wrong.',
        });

        return false;
      }
    }

    setError('unknownError', {
      type: 'unknown',
      message: 'Something went wrong.',
    });

    return false;
  };

  return (
    <Modal fullWidth={false} maxWidth='lg'>
      <div css={classes.container}>
        <h2 css={classes.title}>Add user with limited access this account</h2>
        <FormComponent
          customClass={classes.form}
          filters={filters}
          footerRender={({
            handleSubmit,
            dirtyFields,
            formState,
            clearErrors,
            setError,
            getValues,
          }) => {
            const { errors } = formState;
            const errorState = errors['requestError'] ?? errors['unknownError'] ?? {};

            return (
              <div style={{ width: '100%' }}>
                <div style={{ paddingBottom: '1rem' }}>
                  <div style={{ color: 'red' }}>{errorState.message}</div>
                </div>
                <div css={classes.mfaMessage}>
                  <p>
                    Pressing "Submit" you'll be redirected to the PolySwarm authO login page to
                    enter your two factor authentication for confirmation.
                  </p>
                </div>
                <div css={classes.buttonsContainer}>
                  <Button
                    onClick={() => {
                      _closeModal();
                    }}
                    variant='outlined'
                    color='primary'
                    css={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      formState.isSubmitting ||
                      !(
                        ['accountNumber', 'scope', 'emails'].every((item) =>
                          has(dirtyFields, item)
                        ) && !isEmpty(xor([has(dirtyFields, 'expire')], [has(dirtyFields, 'date')]))
                      )
                    }
                    type='submit'
                    variant='contained'
                    color='primary'
                    css={classes.button}
                    onClick={async (e) => {
                      clearErrors(['requestError', 'unknownError']);
                      const values = getValues();
                      const isBackendValid = await validate(values, setError);

                      if (isBackendValid) {
                        handleSubmit(onSubmit)(e);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            );
          }}
        />
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: 'max-content',
      padding: '3rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      width: '65rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.3rem',
      textAlign: 'center',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
    },
    mfaMessage: {
      paddingBottom: '2rem',
      textAlign: 'center',
      fontSize: '1.4rem',
    },
    button: {
      padding: '0.7rem 1rem !important',
    },
  },
  light: {},
  dark: {},
});
export default AddLimitedAccessModal;
