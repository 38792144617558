// @ts-nocheck
import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Button from '@material-ui/core/Button/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import useFormBuilder from 'components/FormBuilder';

export type FilterProps = {
  teamName: string;
  teamAccountNumber: string;
};

interface ILimitedAccessFilter {
  onFilterClose: () => void;
  filters?: Partial<FilterProps> | {};
  handleApply: (filters: Partial<FilterProps>) => void;
}

const messages = {
  teamName: 'Team Name',
  teamAccountNumber: 'Team Account Number',
};

const filterDefaultValues: FilterProps = {
  teamName: '',
  teamAccountNumber: '',
};

const isThereAnyFilter = (filters: FilterProps, defaultValues: Partial<FilterProps>) => {
  const defaults = { ...filterDefaultValues, ...defaultValues };
  return Object.entries(filters).some(
    ([key, value]) => value !== defaults[key as keyof FilterProps]
  );
};

export const TeamLimitedAccessFilter = ({
  filters,
  handleApply,
  onFilterClose,
}: ILimitedAccessFilter) => {
  const { classes } = useStyles();

  const { FormComponent } = useFormBuilder([
    {
      elementType: 'input',
      name: messages.teamName,
      id: 'teamName',
      defaultValue: '',
    },
    {
      elementType: 'input',
      name: messages.teamAccountNumber,
      id: 'teamAccountNumber',
      defaultValue: '',
    },
  ]);

  return (
    <FormComponent
      filters={filters}
      onSubmit={(data) => {
        handleApply(data);
        onFilterClose();
      }}
      footerRender={({ dirtyFields }) => {
        return (
          <div css={classes.buttonsContainer}>
            <Button
              onClick={() => {
                handleApply({});
              }}
              size='small'
              variant='text'
              color='primary'
              css={classes.button}
            >
              Reset
            </Button>
            <Button
              type='submit'
              size='small'
              variant='contained'
              color='primary'
              disabled={!isThereAnyFilter(dirtyFields)}
              css={classes.button}
            >
              Search
            </Button>
          </div>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  base: {
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
    },
    button: {
      padding: '0.7rem 2rem !important',
    },
  },
});
