import React from 'react'; // eslint-disable-line
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import styles from 'views/styles';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import Modal from 'views/components/Modal';
import Close from '@material-ui/icons/Close';

interface Data {
  key: string;
  value: string | null;
}

export interface InfoModalParams {
  title: string;
  data: Data[];
}

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const InfoModal = () => {
  const dispatch = useDispatch();

  const _closeModal = () => dispatch(closeModal());
  const { title, data } = useSelector(
    ({ modal }: { modal: ModalState<InfoModalParams> }) => modal.params
  );

  return (
    <Modal maxWidth='xl'>
      <Close css={closeIcon} onClick={_closeModal} />
      <div css={container}>
        <h3 className='h3'>{title}</h3>
        <div css={table}>
          {data.length &&
            data.map(
              ({ key, value }) =>
                value && (
                  <div css={row}>
                    <span css={label}>{key}</span>
                    <div>
                      <span>{value}</span>
                      <CopyButton text={value} />
                    </div>
                  </div>
                )
            )}
        </div>
      </div>
    </Modal>
  );
};

const container = css`
  width: 60rem;
  padding: ${styles.spacing.lg};
`;
const closeIcon = css`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`;
const table = css`
  width: 85%;
  margin-top: ${styles.spacing.md};
`;
const row = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1rem;
`;
const label = css`
  font-weight: bold;
`;

export default InfoModal;
