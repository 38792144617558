import React, { createContext, useContext, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import config from 'config';

export type AnalyticsEventType = {
  options: UaEventOptions | string;
  params?: any;
};

declare interface IAnalytics {
  event: (options: UaEventOptions | string, params?: any) => void;
  sendPageView: (pageLocation: string) => void;
}

export const AnalyticsContext = createContext<IAnalytics>({
  event: () => null,
  sendPageView: () => null,
});

export const useAnalytics = () => useContext(AnalyticsContext);

/**
 * The Analytics component
 *
 * Initializes ReactGA and tracks page visits on route change
 */
const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  const sendPageView = useCallback((pageLocation: string) => {
    ReactGA.send({ hitType: 'pageview', page: pageLocation });
  }, []);

  const sendEvent = useCallback((options: UaEventOptions | string, params?: any) => {
    ReactGA.event(options, params);
  }, []);

  useEffect(() => {
    ReactGA.initialize(config.gaID, {
      testMode: process.env.NODE_ENV === 'test',
      gaOptions: {
        cookieFlags: 'SameSite=None;Secure'
      },
    });
  }, []);

  useEffect(() => {
    if (location.pathname === '/callback') {
      ReactGA.send({ referrer: config.url });
    }
    sendPageView(location.pathname);
  }, [sendPageView, location.pathname]);

  return (
    <AnalyticsContext.Provider value={{ event: sendEvent, sendPageView: sendPageView }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
