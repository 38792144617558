import React, { Fragment, useState, useEffect } from 'react';
import { useIntl, IntlShape } from 'react-intl';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { CancelToken, AxiosPromise } from 'axios';
import { isEmpty } from 'lodash';
import { formSchema } from 'utils/schema/formSchema';
import { formMessages } from 'utils/schema/formSchema';
import { RulesetValues } from 'models/Ruleset';
import { RulesetValidationResponse } from 'views/components/form/CodeEditor';
import styles from 'views/styles';
import FormInput from 'views/components/form/FormInput';
import CustomForm from 'views/components/form/CustomForm';
import FormButtonBar from 'views/components/form/CustomForm/FormButtonBar';
import DropTextArea from 'views/components/form/DropTextArea';

interface IRulesetsForm {
  className?: string;
  initialValues?: Partial<RulesetValues>;
  heading: string;
  buttonText: string;
  isLoading: boolean;
  errorMessage?: string | null;
  onValidateRules: (
    value: string,
    cancelToken?: CancelToken
  ) => AxiosPromise<RulesetValidationResponse>;
  onSubmit: (values: RulesetValues, actions: FormikHelpers<RulesetValues>) => void;
  onCancel: () => void;
}

const getInitialValues = (values?: Partial<RulesetValues>): RulesetValues => ({
  name: values && values.name ? values.name : '',
  yara: values && values.yara ? values.yara : '',
  description: values && values.description ? values.description : '',
});

const getValidationSchema = (intl: IntlShape) => {
  const schema = formSchema(intl);
  const { name, yara, description } = schema;
  return yup.object().shape({
    name: name.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.name),
      })
    ),
    yara: yara.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.yara),
      })
    ),
    description,
  });
};

const RulesetsForm = ({
  className,
  initialValues,
  heading,
  buttonText,
  isLoading,
  errorMessage,
  onValidateRules,
  onSubmit,
  onCancel,
}: IRulesetsForm) => {
  const [isValidRules, setIsValidRules] = useState(false);

  const intl = useIntl();

  const _initialValidation = async () => {
    if (initialValues && initialValues.yara) {
      setIsValidRules(true);
    }
  };

  const _checkRuleValidation = (result: RulesetValidationResponse) =>
    setIsValidRules(!result.errors);

  const _resetRuleValidation = () => setIsValidRules(false);

  useEffect(() => {
    _initialValidation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={getInitialValues(initialValues)}
      validationSchema={getValidationSchema(intl)}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<RulesetValues>) => (
        <CustomForm
          testId='rulesetsForm'
          className={className}
          isLoading={isLoading}
          heading={heading}
          renderFields={() => (
            <Fragment>
              <FormInput
                className='h-mb-sm'
                autoFocus
                name='name'
                label={intl.formatMessage(formMessages.name)}
                errorOnTouched
              />

              <FormInput
                className='h-mb-sm'
                name='description'
                label={intl.formatMessage(formMessages.description)}
                optionalText={intl.formatMessage(formMessages.optional)}
              />
              <DropTextArea
                className='h-mb-sm'
                name='yara'
                label={intl.formatMessage(formMessages.yara)}
                placeholder={intl.formatMessage(formMessages.yaraPlaceholder)}
                isInitialValid={isValidRules}
                onValidate={onValidateRules}
                onAfterValidate={_checkRuleValidation}
                onClick={_resetRuleValidation}
                onChange={_resetRuleValidation}
              />
              <p className='p1 h-mb-xs' style={{ color: styles.color.red }}>
                {errorMessage}
              </p>
            </Fragment>
          )}
          renderButtonBar={() => (
            <FormButtonBar
              disabled={
                !formikProps.values.name?.trim() ||
                !formikProps.values.yara?.trim() ||
                !isValidRules ||
                !isEmpty(formikProps.errors)
              }
              submitBtnText={buttonText}
              onCancel={() => {
                formikProps.resetForm();
                onCancel();
              }}
            />
          )}
        />
      )}
    </Formik>
  );
};

export default RulesetsForm;
