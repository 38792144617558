import { IntlShape, MessageDescriptor } from 'react-intl';

// react-intl helper types
export type IntlMessages = { [key: string]: MessageDescriptor };
export type InjectedIntlProps = { intl: IntlShape };

/**
 * Format an object's key as a message.
 * Return they key if it's not found.
 */
export const formatMessageByKey = (intl: IntlShape, key: string, messages: IntlMessages) => {
  if (messages[key]) {
    return intl.formatMessage(messages[key]);
  }
  return key;
};
