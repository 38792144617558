import { PlansState } from '.';
import { PlansAction, PlansActionName } from './types';

export const initialState: PlansState = {
  selected: 'Enterprise',
  interval: 'month',
};

const plans = (state: PlansState = initialState, action: PlansAction) => {
  switch (action.type) {
    case PlansActionName.SELECT_PLAN: {
      return {
        ...state,
        selected: action.plan,
      };
    }
    case PlansActionName.SWITCH_INTERVAL: {
      return {
        ...state,
        interval: action.interval,
      };
    }
    default: {
      return state;
    }
  }
};

export default plans;
