import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import ItemLoader from '../../Loader/ItemLoader';

interface ILoadingNotice {
  text: string;
}

const LoadingNotice = ({ text }: ILoadingNotice) => {
  const { classes } = useStyles();
  return (
    <div css={classes.root}>
      <p css={classes.text}>{text}</p>
      <ItemLoader css={classes.loader} />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      borderRadius: styles.border.radius,
      paddingRight: '2rem',
      height: '6.4rem',
    },
    text: {
      padding: '2rem 0 2rem 2rem',
      marginRight: '2rem',
    },
    loader: {
      fontSize: '0.8rem !important',
    },
  },
  light: {
    root: {
      backgroundColor: styles.color.xLightPurple,
    },
  },
  dark: {
    root: {
      backgroundColor: styles.color.darkXBlack,
    },
  },
});

export default LoadingNotice;
