import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-sun' viewBox='0 0 32 32'>
      <path d='M4.413 4.413c0.481-0.481 1.26-0.481 1.741 0l1.741 1.741c0.481 0.481 0.481 1.26 0 1.741s-1.26 0.481-1.741 0l-1.741-1.741c-0.481-0.481-0.481-1.26 0-1.741z'></path>
      <path d='M16 0c0.68 0 1.231 0.551 1.231 1.231v2.462c0 0.68-0.551 1.231-1.231 1.231s-1.231-0.551-1.231-1.231v-2.462c0-0.68 0.551-1.231 1.231-1.231z'></path>
      <path d='M16 27.077c0.68 0 1.231 0.551 1.231 1.231v2.462c0 0.68-0.551 1.231-1.231 1.231s-1.231-0.551-1.231-1.231v-2.462c0-0.68 0.551-1.231 1.231-1.231z'></path>
      <path d='M24.106 24.106c0.481-0.481 1.26-0.481 1.741 0l1.741 1.741c0.481 0.481 0.481 1.26 0 1.741s-1.26 0.481-1.741 0l-1.741-1.741c-0.481-0.481-0.481-1.26 0-1.741z'></path>
      <path d='M27.077 16c0-0.68 0.551-1.231 1.231-1.231h2.462c0.68 0 1.231 0.551 1.231 1.231s-0.551 1.231-1.231 1.231h-2.462c-0.68 0-1.231-0.551-1.231-1.231z'></path>
      <path d='M0 16c0-0.68 0.551-1.231 1.231-1.231h2.462c0.68 0 1.231 0.551 1.231 1.231s-0.551 1.231-1.231 1.231h-2.462c-0.68 0-1.231-0.551-1.231-1.231z'></path>
      <path d='M4.413 27.587c-0.481-0.481-0.481-1.26 0-1.741l1.741-1.741c0.481-0.481 1.26-0.481 1.741 0s0.481 1.26 0 1.741l-1.741 1.741c-0.481 0.481-1.26 0.481-1.741 0z'></path>
      <path d='M24.106 7.894c-0.481-0.481-0.481-1.26 0-1.741l1.741-1.741c0.481-0.481 1.26-0.481 1.741 0s0.481 1.26 0 1.741l-1.741 1.741c-0.481 0.481-1.26 0.481-1.741 0z'></path>
      <path d='M16 6.667c5.155 0 9.333 4.179 9.333 9.333s-4.179 9.333-9.333 9.333c-5.155 0-9.333-4.179-9.333-9.333s4.179-9.333 9.333-9.333zM16 10c-3.314 0-6 2.686-6 6s2.686 6 6 6c3.314 0 6-2.686 6-6s-2.686-6-6-6z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-sun {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
