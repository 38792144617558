const color = {
  isPolySwarm: true,
  // primary
  white: '#FFFFFF',
  offWhite: '#F8F7FC',
  black: '#3F3F3F',
  darkXBlack: '#1C1A30',
  darkBlack: '#221F3A',
  darkOffBlack: '#2D2948',

  // grey
  ulGrey: '#FAFAFD',
  xxxLightGrey: '#EEEEF1',
  xxLightGrey: '#E3E3ED',
  xLightGrey: '#9192A4',
  medLightGrey: '#6C6C75',
  lightGrey: '#656573',
  grey: '#5F5F61',
  medGrey: '#414141',
  darkGrey: '#46425E',

  // purple
  greyishPurple: '#DBD9F3',
  ulPurple: '#F7F2FC',
  xxxLightPurple: '#F0E9F7',
  xxLightPurple: '#FBF9FE',
  xLightPurple: '#F8F5FE',
  lightPurple: '#DBCDF8',
  medLightPurple: '#B69CF5',
  purple: '#6D3AEC',
  medPurple: '#6033D3',
  darkPurple: '#512AB2',
  medDarkPurple: '#55516A',
  xDarkPurple: '#423E5A',
  xxDarkPurple: '#353154',
  xxxDarkPurple: '#1A013B',

  // green
  green: '#7BFA7F',
  medGreen: '#64CC67',

  // red
  lightRed: '#FF5C6F',
  red: '#FF455B',
  medRed: '#F8001D',
  darkRed: '#E8001B',
  xDarkRed: '#D32F42',

  // blue
  xxLightBlue: '#00C7F8',
  xLightBlue: '#00B8E6',
  lightBlue: '#00ADD8',
  medLightBlue: '#018AAC',
  blue: '#7897FF',

  // yellow
  yellow: '#FFAA00',
  darkYellow: '#996600',
};

export default color;
