import React, { useEffect } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { Dispatch } from 'state/types/thunk';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import {
  RequestError,
  ErrorKeys,
  translateError,
  getErrorMessage,
  isErrorOfType,
} from 'utils/error';
import { UserActionName } from 'state/user/types';
import { InvitationActionName } from 'state/invitation/types';
import { getInvitation, handleInvitation } from 'state/invitation/actions';
import { removeInviteToken } from 'state/invitation/actions';
import { closeModal } from 'state/modal/actions';
import { btnMessages } from 'views/components/Button';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import StatusModal from 'views/components/Modal/StatusModal';
import { joinTeam } from 'state/team/actions';
import { InvitationRequestState } from 'models/Invitation';

export interface TeamMemberInvitationModalParams {
  errorModal?: RequestError;
  invitationRequestState?: InvitationRequestState;
}

interface StateProps {
  inviteToken: string;
  inviter: string | null;
  team: string | null;
}

const messages = defineMessages({
  heading: {
    id: 'team.invitations.modal.heading',
    defaultMessage: 'New team invitation',
  },
  text: {
    id: 'team.invitations.modal.text',
    defaultMessage:
      'You have been invited by {inviter} to join the team "{team}". Would you like to accept?',
  },
  failureHeading: {
    id: 'team.invitations.modal.failure.heading',
    defaultMessage: 'Invitation error',
  },
  failureText: {
    id: 'team.invitations.modal.failure.text',
    defaultMessage:
      'An error occurred while attempting to accept this invitation. Please try accepting the invitation again.',
  },
  acceptedHeading: {
    id: 'team.invitations.modal.accepted.heading',
    defaultMessage: 'Invitation accepted',
  },
  acceptedText: {
    id: 'team.invitations.modal.accepted.text',
    defaultMessage: 'This invitation has already been accepted.',
  },
  declinedHeading: {
    id: 'team.invitations.modal.declined.heading',
    defaultMessage: 'Invitation declined',
  },
  declinedText: {
    id: 'team.invitations.modal.declined.text',
    defaultMessage:
      'This invitation has already been declined. Please ask the team administrator to send another invitation.',
  },
  deletedHeading: {
    id: 'team.invitations.modal.deleted.heading',
    defaultMessage: 'Team deleted',
  },
  deletedText: {
    id: 'team.invitations.modal.deleted.text',
    defaultMessage: 'This team has been deleted. Contact the team administrator for more details.',
  },
  notFoundHeading: {
    id: 'team.invitations.modal.notFound.heading',
    defaultMessage: 'Invitation not found',
  },
  notFoundText: {
    id: 'team.invitations.modal.notFound.text',
    defaultMessage:
      'Invitation could not be found. Please contact the team administrator for more details.',
  },
  expiredHeading: {
    id: 'team.invitations.modal.expired.heading',
    defaultMessage: 'Invitation expired',
  },
  expiredText: {
    id: 'team.invitations.modal.expired.text',
    defaultMessage: 'Invitation has expired. Please contact the team administrator.',
  },
});

const errorKeys: ErrorKeys = {};

const TeamMemberInvitationModal = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const { requests } = useSelector((state: RootState) => state);

  const isLoading = loadingSelector(requests, [
    InvitationActionName.HANDLE_INVITATION,
    UserActionName.GET_USER,
  ]);

  const error = errorSelector(requests, [
    InvitationActionName.HANDLE_INVITATION,
    UserActionName.GET_USER,
  ]);

  const { errorModal, invitationRequestState } = useSelector(
    ({ modal }: { modal: ModalState<TeamMemberInvitationModalParams> }) => modal.params
  );

  const { inviteToken, inviter, team } = useSelector(
    ({ auth, invitation }: RootState): StateProps => ({
      inviteToken: auth.inviteToken!,
      inviter: invitation && invitation.details.inviter,
      team: invitation && invitation.details.team!,
    })
  );

  const _handleAccept = async () => {
    dispatch(joinTeam(inviteToken, team!));
  };

  const _handleDecline = async () => {
    try {
      await dispatch(handleInvitation(inviteToken, 'decline'));
      dispatch(removeInviteToken());
    } catch (error) {
      console.warn(error);
    }
    dispatch(closeModal());
  };

  const _handleReset = () => {
    dispatch(removeInviteToken());
    dispatch(closeModal());
    history.push('/');
  };

  useEffect(() => {
    dispatch(getInvitation(inviteToken));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Invitation not found
  if (errorModal && isErrorOfType('not_found_error', errorModal)) {
    return (
      <StatusModal
        state='reload'
        heading={intl.formatMessage(messages.notFoundHeading)}
        text={intl.formatMessage(messages.notFoundText)}
        buttonText={intl.formatMessage(btnMessages.backToDash)}
        onClick={_handleReset}
        dataCy='teamInvitationNotFound'
      />
    );
  }

  // Team has been deleted
  if (error && getErrorMessage(error).includes('deleted')) {
    return (
      <StatusModal
        state='reload'
        heading={intl.formatMessage(messages.deletedHeading)}
        text={intl.formatMessage(messages.deletedText)}
        buttonText={intl.formatMessage(btnMessages.backToDash)}
        onClick={_handleReset}
        dataCy='teamInvitationDeleted'
      />
    );
  }

  // Already a member
  if ((error && isErrorOfType('already_member', error)) || invitationRequestState === 'accepted') {
    return (
      <StatusModal
        state='ok'
        heading={intl.formatMessage(messages.acceptedHeading)}
        text={intl.formatMessage(messages.acceptedText)}
        buttonText={intl.formatMessage(btnMessages.backToDash)}
        onClick={_handleReset}
        dataCy='teamInvitationAccepted'
      />
    );
  }

  // Closed invitation
  if (
    (error && isErrorOfType('closed_invitation', error)) ||
    invitationRequestState === 'declined'
  ) {
    return (
      <StatusModal
        state='reload'
        heading={intl.formatMessage(messages.declinedHeading)}
        text={intl.formatMessage(messages.declinedText)}
        buttonText={intl.formatMessage(btnMessages.backToDash)}
        onClick={_handleReset}
        dataCy='teamInvitationDeclined'
      />
    );
  }

  // Expired invitation
  if (error && isErrorOfType('expired_invitation', error)) {
    return (
      <StatusModal
        state='reload'
        heading={intl.formatMessage(messages.expiredHeading)}
        text={intl.formatMessage(messages.expiredText)}
        buttonText={intl.formatMessage(btnMessages.backToDash)}
        onClick={_handleReset}
        dataCy='teamInvitationExpired'
      />
    );
  }

  // Request error
  if (errorModal) {
    return (
      <StatusModal
        state='reload'
        heading={intl.formatMessage(messages.failureHeading)}
        text={intl.formatMessage(messages.failureText)}
        buttonText={intl.formatMessage(btnMessages.backToDash)}
        onClick={_handleReset}
        dataCy='teamInvitationError'
      />
    );
  }

  return (
    <ConfirmModal
      css={ownStyle}
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text, { inviter, team })}
      submitBtnText={intl.formatMessage(btnMessages.accept)}
      cancelBtnText={intl.formatMessage(btnMessages.decline)}
      cancelBtnColor='secondary'
      onSubmit={_handleAccept}
      onCancel={_handleDecline}
      testId='teamInvitationModal'
    />
  );
};

const ownStyle = css`
  width: 500px;
`;

export default TeamMemberInvitationModal;
