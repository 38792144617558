import React, { useState, Fragment } from 'react'; // eslint-disable-line
import { defineMessages, useIntl } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import { Hunt } from 'models/Ruleset';
import { Page } from 'models/Page';
import { EScanStatus } from 'models/Submission';
import { Row } from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import CustomTable from 'views/components/table/CustomTable';
import { toggleHuntsType } from 'views/pages/HuntPage/types';
import {
  CustomTablePagination,
  EPageDirection,
} from 'views/components/table/CustomTablePagination';
import HuntingRow from './HuntingRow';
import { generalMessages } from '../../../../../index';

type AnyHunt = Hunt & { active?: boolean };

interface IHuntingTable {
  hunts: Page<AnyHunt>;
  viewRuleset: (id: string) => void;
  deleteHunt: (id: string) => void;
  downloadArtifact: (hash: string, url: string) => void;
  showHuntingInfo: (liveHuntId: string, rulesetId: string) => void;
  modalTitle: string;
  selectedHunts: string[];
  toggleHunts: toggleHuntsType;
  handleChangePage: (page: EPageDirection) => void;
  handleChangeRowsPerPage: (numRows: number) => void;
  isFirst: boolean;
}

const messages = defineMessages({
  results: {
    id: 'table.row.results',
    defaultMessage: 'Results',
  },
  ruleName: {
    id: 'table.row.ruleName',
    defaultMessage: 'Rule Name',
  },
  status: {
    id: 'table.row.status',
    defaultMessage: 'Status',
  },
  sha256: {
    id: 'table.row.sha256',
    defaultMessage: 'SHA256',
  },
  polyscore: {
    id: 'table.row.polyscore',
    defaultMessage: 'PolyScore™',
  },
  matchedOn: {
    id: 'table.row.matchedOn',
    defaultMessage: 'Matched On',
  },
  running: {
    id: 'general.status.running',
    defaultMessage: 'Running',
  },
  success: {
    id: 'general.status.success',
    defaultMessage: 'Success',
  },
  pending: {
    id: 'general.status.pending',
    defaultMessage: 'Pending',
  },
  failed: {
    id: 'general.status.failed',
    defaultMessage: 'Failed',
  },
  inactive: {
    id: 'general.status.inactive',
    defaultMessage: 'Inactive',
  },
  unnamed: {
    id: 'general.status.unnamed',
    defaultMessage: 'Unnamed',
  },
});

export const getHuntingStatusMessage = (status: EScanStatus, formatMessage: Function) => {
  switch (status) {
    case EScanStatus.RUNNING:
      return formatMessage(messages.running);
    case EScanStatus.SUCCESS:
      return formatMessage(messages.success);
    case EScanStatus.PENDING:
      return formatMessage(messages.pending);
    case EScanStatus.FAILED:
      return formatMessage(messages.failed);
    default:
      return formatMessage(messages.inactive);
  }
};

const HuntingTable = ({
  hunts,
  viewRuleset,
  deleteHunt,
  downloadArtifact,
  showHuntingInfo,
  modalTitle,
  selectedHunts,
  toggleHunts,
  handleChangePage,
  handleChangeRowsPerPage,
  isFirst,
}: IHuntingTable) => {
  const { formatMessage } = useIntl();
  const [isToggleOn, setToggleOn] = useState<boolean>(false);

  const rows: Row<AnyHunt>[] = [
    {
      id: 'id',
      numeric: false,
      label: formatMessage(messages.status),
      sortable: false,
    },
    {
      id: 'community',
      numeric: false,
      label: 'Community',
      sortable: false,
    },
    {
      id: 'rule_name',
      numeric: false,
      label: formatMessage(messages.ruleName),
      sortable: false,
    },
    { id: 'sha256', numeric: false, label: formatMessage(messages.sha256), sortable: false },
    {
      id: 'malware_family',
      numeric: false,
      label: formatMessage(generalMessages.malwareFamily),
      sortable: false,
    },
    {
      id: 'polyscore',
      numeric: false,
      label: formatMessage(messages.polyscore),
      sortable: false,
    },
    {
      id: 'detections',
      numeric: false,
      label: formatMessage(generalMessages.detections),
      sortable: false,
    },
    { id: 'created', numeric: false, label: formatMessage(messages.matchedOn), sortable: false },
  ];

  return (
    <Fragment>
      <CustomTable<AnyHunt>
        rows={hunts.results}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            actionAlign='left'
            showActions={true}
            renderHeaderCell={(row) => {
              if (row.id === 'id')
                return (
                  <TableCell>
                    <Checkbox
                      color='primary'
                      checked={isToggleOn}
                      onChange={(value) => {
                        if (value.target.checked) {
                          setToggleOn(true);
                          toggleHunts(
                            value.target.checked,
                            hunts.results.map((result) => result.id)
                          );
                        } else {
                          setToggleOn(false);
                          toggleHunts(value.target.checked, []);
                        }
                      }}
                    />
                  </TableCell>
                );
              return (
                <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                  {formatMessage({ id: row.label })}
                </TableCell>
              );
            }}
          />
        )}
        renderTableRow={(row: AnyHunt) => (
          <HuntingRow
            key={row.id}
            rowData={row}
            viewRuleset={viewRuleset}
            downloadArtifact={downloadArtifact}
            deleteHunt={deleteHunt}
            showHuntingInfo={showHuntingInfo}
            modalTitle={modalTitle}
            selectedHunts={selectedHunts}
            toggleHunts={toggleHunts}
          />
        )}
        renderPagination={
          <CustomTablePagination
            isFirst={isFirst}
            isLast={!hunts.has_more}
            rowsPerPage={hunts.limit}
            rowsPerPageOptions={[5, 25, 50, 75]}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
      />
    </Fragment>
  );
};

export default HuntingTable;
