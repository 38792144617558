import React, { Fragment } from 'react'; // eslint-disable-line
import { defineMessages, useIntl } from 'react-intl';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styles from 'views/styles';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { FileUpload } from './FileUpload';
import styled from '@emotion/styled';
import useIsPrivateContext from 'hooks/useIsPrivateContext';

const Wrapper = styled.div`
  margin-top: ${styles.spacing.lg};
  margin-bottom: ${styles.spacing.lg};
`;

const messages = defineMessages({
  title: {
    id: 'scanPage.title',
    defaultMessage: 'Scan',
  },
});

const ScanPage = () => {
  const intl = useIntl();
  const isPrivateContext = useIsPrivateContext();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout variant={isPrivateContext ? undefined : 'transparent'}>
        <Wrapper>
          <FileUpload showTerms={true} />
        </Wrapper>
      </BaseLayout>
    </Fragment>
  );
};

export default ScanPage;
