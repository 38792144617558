import React, { useState, FC, useContext, useEffect } from 'react';
// import { history } from 'state/store';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { getErrorMessage } from 'utils/error';
import PivotingWhiteList, { whiteListIds, WhiteListIds } from './PivotingWhiteList';
import { useIntl } from 'react-intl';
import { useUser } from 'views/components/providers/UserProvider';
import clickLink from 'utils/clickLink';
import { daysFromNowDate, toISODate } from 'utils/date/date';
import { formatMessageByKey } from 'utils/messageUtils';
import metadataMessages from 'views/components/Accordion/AccordionFileDetails/metadataMessages';
import api from 'services/api/api';
import { storeAccountKey, getAccountStoredKey } from 'utils/storage/storage';
import { toBytes } from 'utils/formatBytes/formatBytes';

type filterType = { [key: string]: string[] };
type operatorType = { [key: string]: string };
enum DateOptions {
  TODAY = 'TODAY',
  DAYS = 'DAYS',
}
type optionDate = 'TODAY' | 'DAYS' | keyof typeof DateOptions;
type OptionalValue = number;
type PivotParams = {
  id?: string;
  tab?: string;
  section: string;
  item?: string;
};
export interface FilterProps {
  active: boolean;
  error: string | null;
  loading: boolean;
  filter: filterType;
  operator: operatorType;
  startDate: { option: optionDate; value: OptionalValue };
  endDate: { option: optionDate; value: OptionalValue };
  startDateGetter?: (accountNubmer: number | string) => {
    option: optionDate;
    value: OptionalValue;
  };
  endDateGetter?: (accountNumber: number | string) => { option: optionDate; value: OptionalValue };
  setStartDate: (option: optionDate, value: OptionalValue) => void;
  setEndDate: (option: optionDate, value: OptionalValue) => void;
  isPivotable: (params: PivotParams) => boolean;
  onSearchFilter: () => void;
  setArtifactId: (artifactId: string) => void;
  onToggleActive: (artifactId: string) => void;
  onSelectFilter: (key: string, value: string, operator?: string) => void;
  onSetActualKeyFilter: (key: string, value: string) => void;
  onClearFilter: () => void;
}

const getArtifactActive = (accountNumber: number | string, artifactId: string) =>
  getAccountStoredKey(accountNumber, `pivotingFilterActive-${artifactId}`);
const getArtifactFilter = (accountNumber: number | string, artifactId: string) =>
  getAccountStoredKey(accountNumber, `pivotingFilterData-${artifactId}`);
const getFilterActualKey = (accountNumber: number | string, artifactId: string) =>
  getAccountStoredKey(accountNumber, `pivotingFilterActualKey-${artifactId}`);
const storagedStartDate: FilterProps['startDateGetter'] = (accountNumber: number | string) =>
  getAccountStoredKey(accountNumber, 'pivotingFilterStartDate');
const storagedEndDate: FilterProps['endDateGetter'] = (accountNumber: number | string) =>
  getAccountStoredKey(accountNumber, 'pivotingFilterEndDate');

const initialFilterValues: FilterProps = {
  active: false,
  error: null,
  loading: false,
  filter: {},
  operator: {},
  startDate: { option: 'DAYS', value: 30 },
  endDate: { option: 'TODAY', value: 0 },
  startDateGetter: (accountNumber) => ({
    option: storagedStartDate(accountNumber)?.option ?? 'DAYS',
    value: storagedStartDate(accountNumber)?.value ?? 30,
  }),
  endDateGetter: (accountNumber) => ({
    option: storagedEndDate(accountNumber)?.option ?? 'TODAY',
    value: storagedEndDate(accountNumber)?.value ?? 0,
  }),
  setStartDate: () => {},
  setEndDate: () => {},
  isPivotable: () => false,
  onSearchFilter: () => {},
  setArtifactId: () => {},
  onToggleActive: () => {},
  onSelectFilter: () => {},
  onSetActualKeyFilter: () => {},
  onClearFilter: () => {},
};

const tabs = {
  details: 'File Details',
  sandbox: 'Sandbox',
  network: 'Network',
  json: 'JSON',
  detections: 'Detections',
  'file-data': 'File Data',
  'extracted-config': 'Extracted Config',
  analysis: 'Analysis',
} as { [key: string]: string };

const transforms = {
  'dropped_files.size': (value: string[]) => {
    return value.map((item) => toBytes(item));
  },
} as { [key: string]: (value: string[]) => any };

export const PivotingContext = React.createContext<FilterProps>(initialFilterValues);

export const usePivoting = () => useContext(PivotingContext);

const PivotingProvider: FC = ({ children }) => {
  const [artifactId, setArtifactId] = useState<string>();
  const [active, setActive] = useState<boolean>(initialFilterValues.active);
  const [startDate, _setStartDate] = useState<{ option: optionDate; value: OptionalValue }>({
    option: 'DAYS',
    value: 30,
  });
  const [endDate, _setEndDate] = useState<{ option: optionDate; value: OptionalValue }>({
    option: 'TODAY',
    value: 0,
  });
  const [filter, setFilter] = useState<filterType>(initialFilterValues.filter);
  const [operator, setOperator] = useState<operatorType>(initialFilterValues.operator);
  const [filterActualKey, setFilterActualKey] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<string | null>(initialFilterValues.error);
  const [loading, setLoading] = useState<boolean>(initialFilterValues.loading);
  const { accountNumber } = useUser();
  const location = useLocation();
  const intl = useIntl();

  useEffect(() => {
    if (!!accountNumber) {
      const start = initialFilterValues?.startDateGetter?.(accountNumber) ?? {
        option: 'DAYS',
        value: 30,
      };
      const end = initialFilterValues?.endDateGetter?.(accountNumber) ?? {
        option: 'TODAY',
        value: 0,
      };

      _setStartDate(start);
      _setEndDate(end);
    }
  }, [accountNumber]);

  const onSelectFilter = (key: string, value: string, oper = ':') => {
    if (key in filter) {
      if (value === '') {
        delete filter[key];
      } else if (filter[key].includes(value)) {
        filter[key] = filter[key].filter((item) => item !== value);
        if (filter[key].length === 0) {
          delete filter[key];
        }
      } else {
        filter[key] = [...filter[key], value];
      }
    } else {
      filter[key] = [value];
    }
    setFilter({ ...filter });
    setOperator({ ...operator, [key]: oper });
    storeAccountKey(accountNumber, `pivotingFilterData-${artifactId}`, filter);
  };

  const onSetActualKeyFilter = (key: string, value: string) => {
    setFilterActualKey({ ...filterActualKey, [key]: value });
    storeAccountKey(accountNumber, `pivotingFilterActualKey-${artifactId}`, {
      ...filterActualKey,
      [key]: value,
    });
  };

  const isPivotable = (params: PivotParams) => {
    if (params.id && whiteListIds.includes(params.id.toLocaleLowerCase() as WhiteListIds)) {
      return true;
    }

    const locationTab = location.pathname.split('/').slice(-1)[0];
    const defaultTab = tabs[locationTab] || '';
    const { tab = defaultTab, section, item } = params;

    const isTabInWhiteList = PivotingWhiteList[tab];
    const isSectionInWhiteList = isTabInWhiteList && isTabInWhiteList[section];

    if (!item) {
      return !isEmpty(isSectionInWhiteList) && active;
    } else {
      return (
        isSectionInWhiteList &&
        isSectionInWhiteList // @ts-ignore
          .map((item) => formatMessageByKey(intl, item, metadataMessages).toUpperCase())
          .includes(formatMessageByKey(intl, item, metadataMessages).toUpperCase()) &&
        active
      );
    }
  };

  const onClearFilter = () => {
    setFilter({});
    storeAccountKey(accountNumber, `pivotingFilterData-${artifactId}`, {});
  };

  const onToggleActive = (artifactId: string) => {
    /* if (!active) {
      if (!['details', 'network', 'sandbox'].some((path) => location.pathname.endsWith(path))) {
        if (location.pathname.endsWith('json')) {
          // history.replace({ pathname: 'details' });
        } else {
          // history.replace(`${location.pathname}/details`);
        }
      }
    }*/
    setArtifactId(artifactId);
    setActive(!active);
    storeAccountKey(accountNumber, `pivotingFilterActive-${artifactId}`, !active);
  };

  const setStartDate: FilterProps['setStartDate'] = (option, value) => {
    _setStartDate({ option, value });
  };
  const setEndDate: FilterProps['setEndDate'] = (option, value) => {
    _setEndDate({ option, value });
  };

  const onSearchFilter = async () => {
    // Build query object and translate it to the metadata search string equivalent
    const query: { [key: string]: string[] } = {};
    for (const [key, val] of Object.entries(filter)) {
      const [section, attribute] = key.split(' | ', 2);
      let keyName: string;
      if (!attribute) {
        keyName = section;
      } else {
        if (section !== 'Artifact Attributes') {
          const prefix = section.toLowerCase().replace(' ', '_');
          keyName = `${prefix}.${attribute}`;
        } else {
          keyName = attribute;
        }
      }
      query[filterActualKey[key] || keyName] = transforms[keyName] ? transforms[keyName](val) : val;
    }
    // Add to the query object the 'artifact.created' range
    const today = toISODate(new Date());
    let createdFrom;
    let createdTo;
    if (startDate.option === 'TODAY') {
      createdFrom = today;
    } else if (startDate.option === 'DAYS' && startDate.value) {
      createdFrom = toISODate(daysFromNowDate(-startDate.value));
    } else {
      throw new Error(`Expected startDate value, got ${JSON.stringify(startDate)}`);
    }
    if (endDate.option === 'TODAY') {
      createdTo = today;
    } else if (endDate.option === 'DAYS' && endDate.value) {
      createdTo = toISODate(daysFromNowDate(-endDate.value));
    }
    query['artifact.created'] = [`[${createdFrom} TO ${createdTo}]`];
    setLoading(true);
    try {
      setError(null);
      const res = await api.parseMetadataSearch(query); // Get the metadata query string
      const { term } = res.data;
      const searchParams = new URLSearchParams({
        term,
        limit: '25',
        pivoting: 'true',
      });
      clickLink(`/search/metadata?${searchParams}`, true);
    } catch (e) {
      setError(`Pivot failed: ${getErrorMessage(e as Error)}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (artifactId) {
      setActive(getArtifactActive(accountNumber, artifactId));
      setFilter(getArtifactFilter(accountNumber, artifactId) || {});
      setFilterActualKey(getFilterActualKey(accountNumber, artifactId) || {});
    }
  }, [accountNumber, artifactId]);

  return (
    <PivotingContext.Provider
      value={{
        active,
        error,
        loading,
        filter,
        operator,
        isPivotable,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        onSearchFilter,
        onSelectFilter,
        onClearFilter,
        setArtifactId: (artifactId: string) => setArtifactId(artifactId),
        onToggleActive: (artifactId: string) => onToggleActive(artifactId),
        onSetActualKeyFilter,
      }}
    >
      {children}
    </PivotingContext.Provider>
  );
};
export default PivotingProvider;
