import { defineMessages } from 'react-intl';

export const generalMessages = defineMessages({
  true: {
    id: 'general.true',
    defaultMessage: 'True',
  },
  false: {
    id: 'general.false',
    defaultMessage: 'False',
  },
  noData: {
    id: 'general.noData',
    defaultMessage: 'No data found.',
  },
  detections: {
    id: 'general.detections',
    defaultMessage: 'Detections',
  },
  scanDate: {
    id: 'general.scanDate',
    defaultMessage: 'Scan Date',
  },
  malwareFamily: {
    id: 'general.malwareFamily',
    defaultMessage: 'Malware Family',
  },
  newApiKey: {
    id: 'account.switch.newApiKey',
    defaultMessage: 'A new API key was created for this account.',
  },
});
