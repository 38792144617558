import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import warningImg from 'assets/images/warning.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PanelContent from 'views/components/layout/PanelContent';
import { switchAndRefreshAccount } from 'state/account/actions';
import { useUser } from 'views/components/providers/UserProvider';
import { btnMessages } from 'views/components/Button';

const messages = defineMessages({
  text: {
    id: 'setup2fa.text',
    defaultMessage: 'To proceed, you must enable 2FA on your User Account',
  },
  setupButton: {
    id: 'setup2fa.setupButton',
    defaultMessage: 'Setup 2FA',
  },
});

interface SetupMfaPanelProps {
  onCancel?: () => void;
  onSetup?: () => void;
}

export const SetupMfaPanel = ({ onCancel, onSetup }: SetupMfaPanelProps) => {
  const intl = useIntl();
  const user = useUser();
  const dispatch = useDispatch();
  const history = useHistory();

  const _onSetup2fa = () => {
    if (user.context?.team) {
      dispatch(switchAndRefreshAccount(user.accountNumber, false, '/account/advanced'));
    } else {
      history.replace('/account/advanced');
    }
    if (onSetup) {
      onSetup();
    }
  };

  const buttons: any[] = [
    {
      text: intl.formatMessage(messages.setupButton),
      onClick: _onSetup2fa,
    },
  ];

  if (onCancel) {
    buttons.push({
      text: intl.formatMessage(btnMessages.cancel),
      variant: 'outlined',
      onClick: onCancel,
    });
  }

  return (
    <PanelContent
      heading={''}
      image={{ src: warningImg, alt: intl.formatMessage(messages.text) }}
      text={intl.formatMessage(messages.text)}
      buttons={buttons}
    />
  );
};
