import React from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys } from 'utils/error';
import { closeModal } from 'state/modal/actions';
import { deleteHunt, deleteHuntResult } from 'state/hunting/actions';
import { ScanType } from 'models/Ruleset';
import { HuntingActionName } from 'state/hunting/types';
import { btnMessages } from 'views/components/Button';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

export interface DeleteHuntModalParams {
  id: string;
  scanType: ScanType;
  all?: boolean;
}

const messages = defineMessages({
  deleteLiveHeading: {
    id: 'rulesets.modal.deleteLiveHeading',
    defaultMessage: 'Delete Live Hunt',
  },
  deleteLiveText: {
    id: 'rulesets.modal.deleteLiveText',
    defaultMessage: 'Are you sure you want to delete this Live Hunt Match?',
  },
  deleteHistHeading: {
    id: 'rulesets.modal.deleteHistHeading',
    defaultMessage: 'Delete Historical Hunt',
  },
  confirmDeletion: {
    id: 'rulesets.modal.confirmDeletion',
    defaultMessage: 'Confirm Deletion',
  },
  deleteHistText: {
    id: 'rulesets.modal.deleteHistText',
    defaultMessage: 'Are you sure you want to delete this historical hunt?',
  },
  deleteHistResultText: {
    id: 'rulesets.modal.deleteHistResultText',
    defaultMessage: 'Are you sure you want to delete this Historical Hunt Match?',
  },
});

const errorKeys: ErrorKeys = {};

const DeleteHuntModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = qs.parse(location.search);
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [HuntingActionName.DELETE_HUNT]);
  const error = errorSelector(requests, [HuntingActionName.DELETE_HUNT]);

  const { id, scanType, all } = useSelector(
    ({ modal }: { modal: ModalState<DeleteHuntModalParams> }) => modal.params
  );
  const isHistoricalResult = scanType === 'historical' && params['hunt_id'];
  const text = intl.formatMessage(
    scanType === 'live'
      ? messages.deleteLiveText
      : isHistoricalResult
      ? messages.deleteHistResultText
      : messages.deleteHistText
  );
  const heading = intl.formatMessage(messages.confirmDeletion);

  return (
    <ConfirmModal
      testId='deleteHuntModal'
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={heading}
      text={text}
      submitBtnText={intl.formatMessage(btnMessages.delete)}
      submitBtnColor='secondary'
      onSubmit={() =>
        dispatch(
          isHistoricalResult
            ? deleteHuntResult(scanType, id, params['hunt_id'] as string, params)
            : deleteHunt(scanType, id, all, params)
        )
      }
      onCancel={() => dispatch(closeModal())}
    />
  );
};

export default DeleteHuntModal;
