import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from '@emotion/styled';
import styles from 'views/styles';
// import { TinyLogo } from './Logo';
import Filters, { IFilter } from './Filters';
import MalwareTableRow from './MalwareTableRow';
import ViewMore from './ViewMore';
import { DiscoverSwitch } from './DiscoverSwitch';
import { ThreatArtifact } from 'models/Tag';
import Loader from 'views/components/Loader';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { PolyScoreTooltip } from 'views/pages/ScanPage/ScanResultsPage/ScanResultsSidebar';
import MarketplaceStatsPanel from 'views/pages/MarketplaceStatsPage/MarketplaceStatsPanel';

export interface IMalwareTableProps {
  data: ThreatArtifact[];
  filters: IFilter[];
  filter: string | null;
  isLoading?: boolean;
  hasMore?: boolean;
  onChangeFilter: (arg0: string) => void;
  onViewMore?: () => void;
}

enum Tabs {
  emergingThreats = 'emergingThreats',
  marketplaceStats = 'marketplaceStats',
}

const Wrapper = styled.div``;

const Header = styled.h3`
  font-weight: 600;
  font-size: ${styles.font.size.h3};
  line-height: 1.2;
  color: #3f3f3f;
  padding-bottom: 1rem;
  text-align: center;
  font-weight: bold;
`;

const Footer = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
  flex-direction: row;
  display: flex;
`;

const Left = styled.div`
  flex: 1 1 auto;
`;

const Right = styled.div`
  flex: 1 1 auto;
  text-align: right;
`;

// const Hint = styled.div`
//   font-size: 0.9rem;
// `;

const StyledPolyScoreTooltip = styled(PolyScoreTooltip)`
  font-size: 0.45rem;
  color: ${styles.color.xLightGrey};
`;

const StyledTable = styled(Table)`
  border-collapse: collapse;

  & th,
  & td {
    border-bottom: 1px solid ${styles.color.greyishPurple};
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1.5;
    padding-left: ${styles.spacing.xs};
    &:first-of-type {
      padding-left: 0px;
      width: 1%;
      white-space: nowrap;
    }
    &:nth-of-type(2) {
      width: 8%;
      min-width: 100px;
    }
    &:nth-of-type(3) {
      width: 10%;
      padding-right: 2rem;
      min-width: 100px;
      white-space: nowrap;
    }
  }
`;

const StyledLoader = styled(Loader)`
  margin: 0 auto;
  text-align: center;
  font-size: 0.75rem;
`;

const PolyScoreHeading = styled.span`
  margin-right: ${styles.spacing.tiny};
`;

const MalwareTable = ({
  data,
  filters,
  filter,
  isLoading,
  hasMore,
  onChangeFilter,
  onViewMore,
}: IMalwareTableProps) => {
  const [tab, setTab] = useState<string>(Tabs.emergingThreats);
  const { classes } = useStyles();

  if (isLoading && data.length === 0) {
    return <StyledLoader />;
  }

  return (
    <Wrapper>
      <div css={classes.headerContainer}>
        <div></div>
        <Header css={classes.header}>
          <FormattedMessage id='scanPage.mawlaretable.title' defaultMessage='Discover' />
        </Header>
        <DiscoverSwitch value={tab} onChange={setTab} />
      </div>
      {tab === Tabs.emergingThreats ? (
        <React.Fragment>
          <Filters filters={filters} value={filter} onChange={onChangeFilter} />
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    id='scanPage.malwaretable.header.hash'
                    defaultMessage='Scan Results'
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id='scanPage.malwaretable.header.firstSeen'
                    defaultMessage='First Seen in PolySwarm'
                  />
                </TableCell>
                <TableCell>
                  <PolyScoreHeading>PolyScore™</PolyScoreHeading>
                  <StyledPolyScoreTooltip />
                </TableCell>
                <TableCell>
                  <FormattedMessage id='scanPage.malwaretable.header.tags' defaultMessage='Tags' />
                </TableCell>
                <TableCell align='right'>
                  <FormattedMessage id='scanPage.malwaretable.header.copy' defaultMessage='Copy' />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row) => <MalwareTableRow key={row.sha256} data={row} filter={filter} />)}
            </TableBody>
          </StyledTable>
          <Footer>
            <Left>
              {/* <Hint css={classes.hint}>
          <TinyLogo />
          <FormattedMessage
            id='scanPage.malwaretable.firstSeenHint'
            defaultMessage='Malware first seen on PolySwarm'
          />
        </Hint> */}
            </Left>
            <Right>{hasMore && onViewMore && <ViewMore onClick={onViewMore} />}</Right>
          </Footer>
        </React.Fragment>
      ) : (
        <MarketplaceStatsPanel />
      )}
    </Wrapper>
  );
};

const useStyles = makeStyles({
  base: {
    header: {
      color: styles.color.black,
      padding: 0,
    },
    hint: {
      color: styles.color.lightGrey,
    },
    headerContainer: {
      display: 'grid',
      gridColumnGap: '2rem',
      gridTemplateColumns: '32rem auto 32rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '2rem',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
    hint: {
      color: styles.color.lightGrey,
    },
  },
  dark: {
    header: {
      color: styles.color.xxLightGrey,
    },
    hint: {
      color: styles.color.xxLightGrey,
    },
  },
});

export default MalwareTable;
