import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl, defineMessages } from 'react-intl';
import Button from '@material-ui/core/Button';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import CopyTextArea from 'views/components/CopyText/CopyTextArea';
import CustomForm from 'views/components/form/CustomForm';
import { Formik } from 'formik';

interface IViewRulesetForm {
  className?: string;
  value: string | null;
  isLoading: boolean;
  errorMessage?: string;
  onClose: () => void;
}

const messages = defineMessages({
  heading: {
    id: 'ruleset.view',
    defaultMessage: 'View Ruleset',
  },
});

const ViewRulesetForm = ({
  className,
  value,
  isLoading,
  errorMessage,
  onClose,
}: IViewRulesetForm) => {
  const intl = useIntl();
  return (
    <Formik onSubmit={() => {}} initialValues={{}}>
      <CustomForm
        testId='viewRulesetsForm'
        className={className}
        isLoading={isLoading}
        heading={intl.formatMessage(messages.heading)}
        headingCentered={true}
        renderFields={() => (
          <div className='h-mt-xxs'>
            <CopyTextArea value={value || ''} alignNotification='left' height='40vh' />
            <p className='p1 h-mt-xxs' style={{ color: styles.color.red }}>
              {errorMessage}
            </p>
          </div>
        )}
        renderButtonBar={() => (
          <div className='h-mt-sm' css={style.buttons}>
            <Button
              style={{ fontSize: '1.8rem' }}
              css={style.button}
              color='primary'
              variant='contained'
              onClick={onClose}
            >
              {intl.formatMessage(btnMessages.close)}
            </Button>
          </div>
        )}
      />
    </Formik>
  );
};

const style = {
  text: css`
    text-align: center;
  `,
  buttons: css`
    text-align: center;
  `,
  button: css`
    width: calc(40%);
    max-width: 22rem;
    font-size: ${styles.font.size.h6};
  `,
};

export default ViewRulesetForm;
