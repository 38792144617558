import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-dropdown' viewBox='0 0 15 9'>
      <path strokeWidth={2} strokeLinecap='round' d='M1 1h13M2.429 4.5h10.142M3.857 8h7.286' />
    </symbol>

    <Global
      styles={css`
        .icon-dropdown {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
