import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import qs from 'query-string';
import { pick } from 'lodash';
import { Auth0DecodedHash, Auth0UserProfile } from 'auth0-js';
import { useIntl, defineMessages } from 'react-intl';
import { EMAIL_WHITELIST } from 'utils/constants';
import auth from 'services/auth';
import api from 'services/api';
import { RootState } from 'state/root';
import { UserRes } from 'services/api/schema/user';
import { isErrorOfType, translateError, ErrorKeys, errorMessages } from 'utils/error';
import { getAccountContext } from 'state/account/actions';
import { setUser } from 'state/user/actions';
import { setInviteToken, handleTeamInvitation } from 'state/invitation/actions';
import { Dispatch } from 'state/types/thunk';
import { login, setSession, sendVerificationEmail, getMFAData } from 'state/auth/actions';
import AccountSignupForm from './AccountSignupForm';
import SimpleLayout from 'views/components/layout/SimpleLayout';
import CenteredContent from 'views/components/layout/CenteredContent';
import VerifyEmail from './VerifyEmail';
import LoginError from './LoginError';
import SEO from 'views/components/layout/SEO';
import { IAccountSignupValues } from 'models/Account';
import AuthLoader from 'views/components/auth/AuthLoader';
import { PRICING_SIDEBAR_STORAGE_KEY } from '../PricingPage/PricingSidebar/ContactDialog/ContactSteps';
import { getFromSessionStorage } from 'utils/storage/storage';
import { ContextAccount, getLastContextAccount } from 'state/auth/selectors';

interface StateProps {
  lastLocation?: string;
  inviteToken?: string;
}

const errorKeys: ErrorKeys = {
  username_exists: errorMessages.username.id,
};

const messages = defineMessages({
  loginError: {
    id: 'authCallback.login.error',
    defaultMessage: 'Login Error',
  },
  loginHeading: {
    id: 'authCallback.login.heading',
    defaultMessage: 'Logging in',
  },
  loginText: {
    id: 'authCallback.login.text',
    defaultMessage: 'Please wait while we log you in…',
  },
  verifyEmai: {
    id: 'authCallback.verifyEmai',
    defaultMessage: 'Verify Email',
  },
  accountSetup: {
    id: 'authCallback.accountSetup',
    defaultMessage: 'Account Setup',
  },
});

export const AuthCallbackPage = () => {
  const [decodedHash, setDecodedHash] = useState<Auth0DecodedHash | null>(null);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [isSignupLoading, setIsSignupLoading] = useState(false);
  const [signupError, setSignupError] = useState<string | null | undefined>(null);
  const [loginError, setLoginError] = useState(false);
  const [userInfo, setUserInfo] = useState<Auth0UserProfile | null>(null);
  const [userData, setUserData] = useState<IAccountSignupValues | null>(null);

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch<Dispatch>();

  const { lastLocation, inviteToken } = useSelector<RootState, StateProps>(({ auth }) => ({
    lastLocation: auth.lastLocation,
    inviteToken: auth.inviteToken,
  }));

  const { event: gaEvent } = useAnalytics();

  /**
   * Set the session and the user.
   * Redirect back to stored location whe complete.
   */
  const _finishLogin = async (decodedHash: Auth0DecodedHash, user: UserRes) => {
    dispatch(setSession(decodedHash));
    const lastContextAccount = getLastContextAccount(user.accountNumber);
    let ctx: ContextAccount | undefined;
    if (lastContextAccount) {
      // Hack to remember last session when the user comes back
      // from the same device / browser
      const context = getFromSessionStorage('persist:account')?.context?.context;
      if (
        !context ||
        context === 'null' ||
        (context === 'user' && lastContextAccount.context !== 'user')
      ) {
        ctx = {
          accountNumber: lastContextAccount.accountNumber,
          context: lastContextAccount.context,
          isPrivate: lastContextAccount.isPrivate,
        };
      }
    }
    await dispatch(getAccountContext(ctx));
    dispatch(setUser(user));

    const mfaData = getMFAData();

    if (typeof inviteToken === 'string') {
      await dispatch(handleTeamInvitation());
    } else if (mfaData) {
      const { location } = mfaData;
      history.replace(location || '/');
    } else {
      history.replace(lastLocation && lastLocation !== location.pathname ? lastLocation : '/');
    }
  };

  /**
   * Attempt login. If user not found, attempt signup.
   */
  const _startLogin = (decodedHash: Auth0DecodedHash) => {
    api
      .login(decodedHash.accessToken!)
      .then(({ data }) => _finishLogin(decodedHash, data))
      .catch((error) => {
        if (isErrorOfType('user_not_found', error)) {
          setDecodedHash(decodedHash);
          setShowSignupForm(true);
        } else {
          setLoginError(true);
        }
      });
  };

  /**
   * Check to make sure email is verified before attempting login.
   */
  const _checkEmailVerification = (decodedHash: Auth0DecodedHash) => {
    if (
      !decodedHash.idTokenPayload.email_verified ||
      ((window as any).Cypress && (window as any).emailVerificationState)
    ) {
      setDecodedHash(decodedHash);
      setShowEmailVerification(true);
    } else {
      _startLogin(decodedHash);
    }
  };

  /**
   * Start the authentication process.
   * Redirect users that visit callback page directly.
   */
  const _handleAuth = () => {
    const { inviteToken } = qs.parse(location.search);

    if (typeof inviteToken === 'string') {
      dispatch(setInviteToken(inviteToken));
    }

    auth
      .parseHash()
      .then((decodedHash) => {
        _checkEmailVerification(decodedHash);
        return decodedHash;
      })
      .then((decodedHash: Auth0DecodedHash) => {
        if (decodedHash && decodedHash.accessToken) {
          return auth.getUserInfo(decodedHash.accessToken);
        }
      })
      .then((userInfo) => {
        if (userInfo) {
          setUserInfo(userInfo);
        }
      })
      .catch((error) => {
        const isLogout = error.message === 'auth_tokens_not_found';
        if (!isLogout) {
          console.warn('Error handling Auth', error);
        }
        if (typeof inviteToken === 'string') {
          dispatch(handleTeamInvitation());
        } else {
          history.replace(lastLocation && lastLocation !== location.pathname ? lastLocation : '/');
        }
      });
  };

  const _resendVerificationEmail = () => dispatch(sendVerificationEmail(decodedHash!.accessToken!));

  /**
   * Submit signup form and attempt to finish login.
   */
  const _handleSignup = (userData: IAccountSignupValues) => {
    if (!decodedHash || !decodedHash.accessToken || !userData) {
      return;
    }

    setIsSignupLoading(true);
    setSignupError(null);
    setUserData(userData);
    api
      .signUp(decodedHash.accessToken, userData)
      .then(({ data }) => {
        _finishLogin(decodedHash, data);

        if (EMAIL_WHITELIST.every((email) => !data.email.includes(email))) {
          gaEvent({
            category: 'Account',
            action: 'New Account Created',
          });
        }
      })
      .catch((error) => {
        setIsSignupLoading(false);
        setShowSignupForm(true);
        setSignupError(translateError(intl, errorKeys, error));
      });
  };

  useEffect(() => {
    const { emailVerified } = qs.parse(location.search);
    if (emailVerified) {
      auth
        .checkSession()
        .then((decodedHash) => _checkEmailVerification(decodedHash))
        .catch(() => dispatch(login()));
    } else {
      _handleAuth();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _getComponentsToRender = () => {
    if (loginError) {
      return (
        <Fragment>
          <SEO title={intl.formatMessage(messages.loginError)} />
          <LoginError onRetry={() => dispatch(login())} />
        </Fragment>
      );
    }

    if (showEmailVerification) {
      return (
        <Fragment>
          <SEO title={intl.formatMessage(messages.verifyEmai)} />
          <VerifyEmail
            style={{ marginTop: '-5rem' }}
            email={decodedHash!.idTokenPayload.email}
            onResend={_resendVerificationEmail}
          />
        </Fragment>
      );
    }

    if (showSignupForm) {
      let initialValues = {};

      const savedUserDataRaw = localStorage.getItem(PRICING_SIDEBAR_STORAGE_KEY);
      if (savedUserDataRaw) {
        const savedUserData = JSON.parse(savedUserDataRaw);
        initialValues = Object.assign(
          { options: [] },
          pick(savedUserData, ['firstName', 'lastName', 'jobTitle', 'company'])
        );
        localStorage.removeItem(PRICING_SIDEBAR_STORAGE_KEY);
      } else if (userInfo) {
        let firstName = '';
        let lastName = '';

        if (userInfo.name.indexOf(' ') !== -1) {
          firstName = userInfo.name.substring(0, userInfo.name.lastIndexOf(' '));
          lastName = userInfo.name.substring(userInfo.name.lastIndexOf(' ') + 1);
        } else {
          firstName = userInfo.name;
        }

        initialValues = Object.assign(
          {
            username: userInfo.nickname,
            firstName: userInfo.given_name || firstName,
            lastName: userInfo.family_name || lastName,
            options: [],
          },
          userData || {}
        );
      }

      return (
        <Fragment>
          <SEO title={intl.formatMessage(messages.accountSetup)} />
          <AccountSignupForm
            style={{ marginTop: '-5rem' }}
            errorMessage={signupError}
            onSubmit={_handleSignup}
            initialValues={initialValues}
            isSignupLoading={isSignupLoading}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <SEO title={intl.formatMessage({ id: 'general.loading' })} />
        <CenteredContent>
          <AuthLoader
            style={{ marginTop: '-5rem' }}
            heading={intl.formatMessage(messages.loginHeading)}
            text={intl.formatMessage(messages.loginText)}
          />
        </CenteredContent>
      </Fragment>
    );
  };

  return (
    <SimpleLayout withHeader={false}>
      <CenteredContent>{_getComponentsToRender()}</CenteredContent>
    </SimpleLayout>
  );
};
