import React from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import ability from 'config/ability';
import { btnMessages } from 'views/components/Button';
import { combinedUserActions } from 'views/components/providers/UserProvider';
import { useAuth } from 'views/components/providers/AuthProvider';
import { useUser } from 'views/components/providers/UserProvider';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';
import PanelContent from 'views/components/layout/PanelContent';
import MetadataSearchTab from './MetadataSearchTab';
import Placeholder from 'views/components/request/Placeholder/PlaceholderContainer';

const messages = defineMessages({
  heading: {
    id: 'metadataSearch.heading',
    defaultMessage: 'Search artifact metadata',
  },
  text: {
    id: 'metadataSearch.loggedOut.text',
    defaultMessage: 'Log in or create an account to begin searching today!',
  },
});

const MetadataSearchTabContainer = () => {
  const intl = useIntl();
  const location = useLocation();
  const { refreshUser } = useUser();
  const { isAuthenticated, login } = useAuth();
  const { term } = qs.parse(location.search);

  if (!isAuthenticated && term) {
    login(location.pathname + location.search);
  }

  return (
    <Placeholder loadingActions={combinedUserActions} onReload={refreshUser}>
      {ability.cannot('metadata_search', 'Artifact') ? (
        <PanelContent
          imageComponent={<RulesetImg />}
          heading={intl.formatMessage(messages.heading)}
          text={intl.formatMessage(messages.text)}
          buttons={[
            {
              text: intl.formatMessage(btnMessages.login),
              onClick: () => login(location.pathname + location.search),
            },
          ]}
        />
      ) : (
        <MetadataSearchTab />
      )}
    </Placeholder>
  );
};

export default MetadataSearchTabContainer;
