import { Action } from 'redux';

export enum PlansActionName {
  SELECT_PLAN = 'SELECT_PLAN',
  SWITCH_INTERVAL = 'SWITCH_INTERVAL',
}

export interface SelectPlanAction extends Action<PlansActionName.SELECT_PLAN> {
  plan: string | null;
}

export interface SwitchIntervalAction extends Action<PlansActionName.SWITCH_INTERVAL> {
  interval: string;
}

export type PlansAction = SelectPlanAction | SwitchIntervalAction;
