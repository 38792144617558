import { ReactNode } from 'react';
import { Action } from 'redux';
import { MessageDescriptor } from 'react-intl';

export enum NotificationActionName {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
}

export type ShowNotificationParams = {
  status: 'success' | 'failure' | 'info';
  message: MessageDescriptor | ReactNode;
  isDismissible?: boolean;
  delay?: number;
};

export interface ShowNotificationAction extends Action<NotificationActionName.SHOW_NOTIFICATION> {
  params: ShowNotificationParams;
}

export interface HideNotificationAction extends Action<NotificationActionName.HIDE_NOTIFICATION> {}

export type NotificationAction = ShowNotificationAction | HideNotificationAction;
