import * as yup from 'yup';
import { IntlShape } from 'react-intl';
import { formMessages } from '.';
import { isAddress } from 'web3-validator';

export const formSchema = (intl: IntlShape) => ({
  username: yup
    .string()
    .label(intl.formatMessage(formMessages.username))
    .min(3, intl.formatMessage(formMessages.usernameMin))
    .max(15, intl.formatMessage(formMessages.usernameMax))
    .matches(/^[a-zA-Z0-9_\-\\+]+$/, {
      message: intl.formatMessage(formMessages.usernameMatch),
    }),

  name: yup.string().label(intl.formatMessage(formMessages.name)),

  fullName: yup
    .string()
    .label(intl.formatMessage(formMessages.fullName))
    .matches(/^[a-zA-Z0-9_\-\\+\s+]+$/, {
      message: intl.formatMessage(formMessages.fullNameMatch),
    }),

  firstName: yup
    .string()
    .min(1, intl.formatMessage(formMessages.firstNameMin))
    .label(intl.formatMessage(formMessages.firstName)),

  lastName: yup
    .string()
    .min(1, intl.formatMessage(formMessages.lastNameMin))
    .label(intl.formatMessage(formMessages.lastName)),

  ruleName: yup.string().label(intl.formatMessage(formMessages.name)),

  malwareFamilyName: yup.string().label(intl.formatMessage(formMessages.name)),

  jobTitle: yup.string(),

  email: yup.string().email().label(intl.formatMessage(formMessages.email)),

  confirmEmail: yup
    .string()
    .email()
    .label(intl.formatMessage(formMessages.confirmEmail))
    .required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.confirmEmail),
      })
    ),

  phone: yup.string().label(intl.formatMessage(formMessages.phone)),

  company: yup.string().label(intl.formatMessage(formMessages.company)),

  yara: yup.string().label(intl.formatMessage(formMessages.yara)),

  confirm: (name: string, label: string) =>
    yup
      .string()
      .label(label)
      .required(intl.formatMessage(formMessages.required, { label }))
      .test(
        'text-matches',
        intl.formatMessage(formMessages.confirmMatch, { name }),
        (value: string) => {
          return value === name;
        }
      ),

  ethAddress: yup
    .string()
    .label(intl.formatMessage(formMessages.ethAddress))
    .test('valid-address', intl.formatMessage(formMessages.invalidEthAddress), isAddress),

  description: yup.string().label(intl.formatMessage(formMessages.description)),

  tags: yup.array().of(yup.string()).label(intl.formatMessage(formMessages.tags)),

  artifactTypes: yup.array().of(yup.string()).label(intl.formatMessage(formMessages.artifactTypes)),

  polyscoreLower: yup.number().min(0).max(1).label(intl.formatMessage(formMessages.polyscoreLower)),

  polyscoreUpper: yup.number().min(0).max(1).label(intl.formatMessage(formMessages.polyscoreUpper)),
});
