import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface IContactDialogTriggerProps {
  onClick: (e: any) => void;
}

export const ContactDialogTrigger = ({ onClick }: IContactDialogTriggerProps) => {
  const { classes } = useStyles();
  return (
    <div css={classes.root}>
      <h2 css={classes.header}>
        <FormattedMessage id='contactDialogTrigger.heading' defaultMessage='Questions?' />
      </h2>
      <Button css={classes.contactBtn} color='primary' variant='outlined' onClick={onClick}>
        <FormattedMessage id='contactDialogTrigger.btn' defaultMessage='Contact us' />
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      textAlign: 'center',
      marginTop: styles.spacing.sm,
    },
    header: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    subHeader: {
      fontSize: '1.8rem',
      marginTop: styles.spacing.tiny,
    },
    contactBtn: {
      padding: '0.3em 1.2em !important',
      fontSize: '1.6rem !important',
      marginTop: `${styles.spacing.xxs} !important`,
    },
  },
  light: {
    header: {
      color: styles.color.lightGrey,
    },
    subHeader: {
      color: styles.color.xLightGrey,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
    subHeader: {
      color: styles.color.xLightGrey,
    },
  },
});
