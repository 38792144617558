import React, { Fragment, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import { MenuOption } from './UserMenu';
import UserMenuItem from './UserMenuItem';

type Props = MenuOption;

const UserSubmenu = ({ id, items, onClick, ...rest }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const _handleOpen = (e: React.MouseEvent<any>) => setAnchorEl(e.currentTarget);

  const _handleClose = () => setAnchorEl(null);

  const _handleClick = (onItemClick?: () => void) => {
    onItemClick && onItemClick();
    onClick && onClick();
    _handleClose();
  };

  return (
    <Fragment>
      <UserMenuItem
        {...rest}
        aria-controls={`user-menu-${id}`}
        aria-haspopup='true'
        onClick={_handleOpen}
      />
      <Menu
        id={`user-menu-${id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={_handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {items &&
          items.map((item) => (
            <UserMenuItem key={item.id} {...item} onClick={() => _handleClick(item.onClick)} />
          ))}
      </Menu>
    </Fragment>
  );
};

export default UserSubmenu;
