import React, { FC } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import gearsImg from '../shared/gears.svg';
import arrowImg from '../shared/arrow.svg';

const Engines = ({ className, arrow }: { className?: string; arrow?: boolean }) => {
  const { classes } = useStyles();
  return (
    <div className={className} css={classes.engines}>
      <Engine arrow={arrow}>
        <FormattedMessage id='competeSection.engineA' defaultMessage='Engine A' />
      </Engine>
      <Engine arrow={arrow}>
        <FormattedMessage id='competeSection.engineB' defaultMessage='Engine B' />
      </Engine>
      <Engine arrow={arrow}>
        <FormattedMessage id='competeSection.engineC' defaultMessage='Engine C' />
      </Engine>
      <Engine arrow={arrow}>
        <FormattedMessage id='competeSection.engineD' defaultMessage='Engine D' />
      </Engine>
    </div>
  );
};

const Engine: FC<{ arrow?: boolean }> = ({ arrow, children }) => {
  const { classes } = useStyles();
  return (
    <div className='engine' css={classes.engine}>
      {arrow && <img className='arrow' css={classes.engineArrow} src={arrowImg} alt='arrow' />}
      <img src={gearsImg} alt='engine' />
      <p>{children}</p>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    engines: {
      position: 'relative',
      width: '34rem',
      display: 'flex',
      justifyContent: 'space-between',
    },
    engine: {
      position: 'relative',
      '&:last-child': {
        marginRight: 0,
      },
      p: {
        fontSize: styles.font.size.p1,
        fontWeight: styles.font.weight.bold,
        textTransform: 'uppercase',
      },
    },
    engineArrow: {
      position: 'absolute',
      bottom: '-2rem',
      left: '50%',
      transform: 'translateX(-50%) rotate(-90deg)',
    },
  },
  light: {
    engine: {
      p: {
        color: styles.color.purple,
      },
    },
  },
  dark: {
    engine: {
      p: {
        color: styles.color.lightBlue,
      },
    },
  },
});

export default Engines;
