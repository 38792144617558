import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { useIntl, defineMessages } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

const messages = defineMessages({
  title: {
    id: 'engineAgreementPage.title',
    defaultMessage: 'Engine Agreement',
  },
});

const Wrapper = styled(Card)`
  padding: calc(30px * 1.5);
  margin-top: 3.2rem !important;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;
    margin-left: 5rem;

    li {
      padding: 0.5rem 0 0.5rem 0;
      font-size: 1.8rem;
    }
  }
`;

const Header = styled.h1`
  font-size: ${styles.font.size.h3};
`;

const SubHeader = styled.div`
  font-size: ${styles.font.size.h5};
`;

const Quote: React.FC = ({ children }) => <em>&ldquo;{children}&rdquo;</em>;

const StrongQuote: React.FC = ({ children }) => (
  <strong>
    <Quote>{children}</Quote>
  </strong>
);

const Strong: React.FC = ({ children }) => (
  <strong>
    <strong>{children}</strong>
  </strong>
);

const Link = ({ href, text }: { href: string; text?: string }) => (
  <a className='a' href={href} target='_blank' rel='noopener noreferrer'>
    {' '}
    {text || href}
  </a>
);

const Address = styled.li`
  padding: 2rem 0;
  list-style: none !important;

  li {
    padding: 0;
  }
`;

const LetterList = styled.ol`
  margin-left: 1.5rem;
  list-style: none !important;
  counter-reset: list !important;

  li {
    padding-top: 1rem;
  }

  li:before {
    content: '(' counter(list, lower-alpha) ') ';
    counter-increment: list;
  }
`;

const EngineAgreementPageContent = () => {
  const { classes } = useStyles();

  return (
    <Wrapper className='container h-mt-lg h-mb-lg'>
      <ScrollToTopOnMount />
      <Typography paragraph>
        <Header css={classes.header}>MARKETPLACE ENGINE/ARBITER AGREEMENT</Header>
        <SubHeader css={classes.subheader}>Last Updated: August 03, 2021</SubHeader>
      </Typography>
      <Typography paragraph>
        This Marketplace Engine/Arbiter Agreement (this <StrongQuote>Agreement</StrongQuote>) is
        entered into by and between Swarm Technologies Inc. (<StrongQuote>PolySwarm</StrongQuote>)
        and you. References herein to <StrongQuote>you</StrongQuote> or{' '}
        <StrongQuote>Security Expert</StrongQuote> are referring to you in your capacity as an
        individual that develops and operates an Engine (as defined below), unless you are a
        representative of a team, company or other organization that develops and operates an
        Engine, in which case references to <StrongQuote>you</StrongQuote> or{' '}
        <StrongQuote>Security Expert</StrongQuote> refer to such team, company or other
        organization, and you represent and warrant that you have the authority to bind such team,
        company or other organization to the terms of this Agreement. This Agreement applies to your
        submission of an Engine to, and your participation in, the Marketplace. If you do not agree
        to the terms of this Agreement, or if you are not authorized to accept this Agreement on
        behalf of your team, organization or entity (if applicable), do not submit an Engine to or
        otherwise participate in the Marketplace.
      </Typography>
      <Typography paragraph>
        <Strong>1. Definitions</Strong>
      </Typography>
      <Typography paragraph>
        1.1 The following terms, when used in this Agreement will have the following meanings:
      </Typography>
      <Typography paragraph>
        <StrongQuote>Arbiter</StrongQuote> means an Engine that aids in making ground truth
        determinations for purposes of determining rewards to be issued to Security Experts that
        competed in the applicable Bounty (<StrongQuote>Rewards</StrongQuote>).
      </Typography>
      <Typography paragraph>
        <StrongQuote>Bounty</StrongQuote> means a bounty that is issued through the Marketplace in
        connection with a Submitted Artifact that is submitted to the PolySwarm Platform by a
        PolySwarm Platform user.
      </Typography>
      <Typography paragraph>
        <StrongQuote>Confidential Information</StrongQuote> means any information or data disclosed
        by either party that is marked or otherwise designated as confidential or proprietary or
        that should otherwise be reasonably understood to be confidential in light of the nature of
        the information and the circumstances surrounding disclosure. However, “Confidential
        Information” will not include any information which (a) is in the public domain through no
        fault of the receiving party; (b) was properly known to the receiving party, without
        restriction, prior to disclosure by the disclosing party; (c) was properly disclosed to the
        receiving party, without restriction, by another person with the legal authority to do so;
        or (d) is independently developed by the receiving party without the use of or reference to
        the disclosing party’s Confidential Information.
      </Typography>
      <Typography paragraph>
        <StrongQuote>Engine</StrongQuote> means a security software application submitted by a
        Security Expert to PolySwarm or otherwise used by a Security Expert in connection with the
        Marketplace to scan Submitted Artifacts, either in the role of an ‘Engine’ or ‘Arbiter’, as
        described in more detail in the Participation Mechanics.
      </Typography>
      <Typography paragraph>
        <StrongQuote>Marketplace</StrongQuote> means that portion of the PolySwarm Platform pursuant
        to which Security Experts compete for Rewards in connection with Bounties.
      </Typography>
      <Typography paragraph>
        <StrongQuote>Participation Mechanics</StrongQuote> means the processes governing your
        participation in and PolySwarm’s operation of the Marketplace, including the processes for
        (a) Security Experts to use their Engines to scan Submitted Artifacts (or to pass on
        scanning Submitted Artifacts) and make determinations based on such scans (
        <StrongQuote>Assertions</StrongQuote>) (it being understood that, in certain circumstances,
        Security Experts may also participate in the Marketplace without making an Assertion); (b)
        determining which Submitted Artifacts are scanned by which Engines; (c) determining and
        issuing Rewards; (d) the making of ground truth determinations for purposes of determining
        Rewards and the issuance of such Rewards to the applicable Security Experts; and (e)
        determining how Engines are promoted to become Arbiters in the Marketplace and the Rewards
        to be issued to Arbiters, in each case as such processes may be updated from time to time by
        PolySwarm. The current Participation Mechanics are located at
        <Link href='https://polyswarm.network/how-it-works' />
      </Typography>
      <Typography paragraph>
        <StrongQuote>PolySwarm Platform</StrongQuote> means the decentralized threat detection
        marketplace made available by PolySwarm, and all related documentation, software and
        intellectual property provided by PolySwarm in relation thereto.
      </Typography>
      <Typography paragraph>
        <StrongQuote>Public Community</StrongQuote> means a community in the PolySwarm Platform
        threat detection marketplace that is open to all participants.
      </Typography>
      <Typography paragraph>
        <StrongQuote>Submitted Artifact</StrongQuote> means a potentially malicious file, hash or
        URL submitted to the PolySwarm Platform by a PolySwarm Platform user.
      </Typography>
      <Typography paragraph>
        <StrongQuote>Your Engine</StrongQuote> means the Engine(s) that you submit to PolySwarm or
        otherwise use in connection with the Marketplace to scan Submitted Artifacts.
      </Typography>
      <Typography paragraph>
        <StrongQuote>Your Rewards</StrongQuote> means the compensation offered by PolySwarm to you
        (if any) in return for accurate assertions or ground truth determinations in connection with
        the Marketplace, as mutually agreed upon by you and PolySwarm pursuant to an enrollment form
        (including any online form) or similar document referencing this Agreement.
      </Typography>

      <Typography paragraph>
        <Strong>2. Your Engine and Participation in the Marketplace</Strong>
      </Typography>
      <Typography paragraph>
        2.1 <u>Your Engine.</u>
        <LetterList>
          <li>
            As a condition of Your Engine being permitted to participate in the Marketplace,
            PolySwarm in its discretion must approve Your Engine and, without limiting the
            foregoing, you agree to meet any reasonable standards for Engines that PolySwarm may
            require from time to time. You will be responsible for hosting and operating Your
            Engine, except that if the parties mutually agree, Your Engine may be hosted and
            operated by PolySwarm, in which case you will provide PolySwarm with a copy of Your
            Engine in order to permit PolySwarm to host and operate Your Engine in connection with
            the Marketplace.
          </li>
          <li>
            You hereby grant to PolySwarm a non-exclusive right and license to use Your Engine in
            connection with the Marketplace (including the right to host and operate Your Engine if
            provided to PolySwarm for such purpose). PolySwarm may exercise the foregoing rights
            through contractors providing services to PolySwarm, solely in their capacities as
            service providers to PolySwarm.
          </li>
          <li>
            PolySwarm will not decompile, reverse engineer, disassemble or otherwise attempt to
            derive the source code for Your Engine, except as permitted by law. PolySwarm will not
            remove, obscure, or alter any copyright notice, trademark, or other proprietary rights
            notices affixed to or contained within Your Engine.
          </li>
        </LetterList>
      </Typography>
      <Typography paragraph>
        2.2 <u>Participation in the Marketplace.</u>
        <LetterList>
          <li>
            You acknowledge and agree that your participation in the Marketplace, and PolySwarm’s
            obligations in relation to the operation of the Marketplace, is subject to the
            Participation Mechanics. You agree that PolySwarm is solely responsible (in conjunction
            with the assistance of Arbiters, if applicable) for making ground truth determinations
            and determining Rewards to be issued to Security Experts. You hereby waive any right to
            challenge, dispute or otherwise make any claim against PolySwarm in relation to any
            ground truth determinations or Reward determinations made by PolySwarm.
          </li>
          <li>
            For any Submitted Artifact to which you get access through the Marketplace, you agree
            that such Submitted Artifact and the results of any scans related thereto are the
            Confidential Information of PolySwarm and you may only use the same internally for the
            purposes of scanning Submitted Artifacts as part of your participation in the
            Marketplace and/or improving Your Engine. However, if a Submitted Artifact is submitted
            in a Public Community, you may additionally use information regarding such Submitted
            Artifact and the results of any scans related thereto to generate aggregated and
            anonymized information (e.g., industry trends) that you may share with third parties,
            provided that you must, prior to sharing such information, share such information with
            PolySwarm and credit PolySwarm as the source of such information in accordance with
            Section 2.3. Any information from confidential or proprietary sources or vendors in
            Security Expert reports may be deleted from reports to be shared with PolySwarm.
          </li>
          <li>
            You agree that PolySwarm has the full, unencumbered right, without any obligation to
            compensate or reimburse you, to freely use, make derivative works of and disclose the
            results of any scans conducted by any Security Experts (including you).
          </li>
          <li>
            To be eligible to receive a Reward, you must provide PolySwarm with accurate, complete,
            and up-to-date information about you, as reasonably requested by PolySwarm. You are
            solely responsible for paying any and all taxes related to Rewards issued to you.
          </li>
        </LetterList>
      </Typography>
      <Typography paragraph>
        2.3 <u>Trademarks.</u> To the extent Your Engine is approved for participation in the
        Marketplace by PolySwarm and active on the Marketplace, each party (the
        <StrongQuote>Grantor</StrongQuote>) hereby grants to the other party (the
        <StrongQuote>Grantee</StrongQuote>) a non-exclusive, non-transferable (except as permitted
        in Section 8.2), non-sublicensable right and license to use Grantor’s trademarks (
        <StrongQuote>Trademarks</StrongQuote>), solely for the purpose of (a) in the case of you as
        the Grantor, marketing and publicizing the PolySwarm Platform and the functionality of Your
        Engine that has been approved and is active on the Marketplace or (b) in the case of
        PolySwarm as the Grantor, indicating that Your Engine is approved and active on the
        Marketplace. Any goodwill resulting from the use of the Grantor’s Trademarks shall inure
        solely to the benefit of Grantor. Grantee shall use the Grantor’s Trademarks in accordance
        with the Grantor’s guidelines as may be provided by Grantor from time to time. The Grantee
        shall remedy any deficiencies in its use of the Grantor’s Trademarks, as determined by the
        Grantor in its sole discretion, upon notification by the Grantor and in the manner requested
        by the Grantor.
      </Typography>
      <Typography paragraph>
        2.4 <u>Customer Discount.</u> If you are not already a paying customer of the PolySwarm
        Platform, if you become a paying customer of the PolySwarm Platform during the term of this
        Agreement, PolySwarm may agree to extend to you a discount to PolySwarm’s then-standard
        subscription fees.
      </Typography>

      <Typography paragraph>
        <Strong>3. Proprietary Rights and Confidentiality.</Strong>
      </Typography>
      <Typography paragraph>
        3.1 <u>PolySwarm’s Ownership Rights.</u> As between the parties, PolySwarm exclusively owns
        all right, title and interest in and to the PolySwarm Platform. As between the parties,
        Security Expert exclusively owns all right, title and interest in and to Your Engine. Except
        for the express rights granted hereunder, PolySwarm reserves all rights, title and interests
        in and to the PolySwarm Platform and PolySwarm’s Confidential Information, and Security
        Expert reserves all rights, title and interests in and to Your Engine and Security Expert’s
        Confidential Information.
      </Typography>
      <Typography paragraph>
        3.2 <u>Confidentiality.</u> Each party agrees that it will use the Confidential Information
        of the other party solely in accordance with the provisions of this Agreement and it will
        not disclose, or permit to be disclosed, the same directly or indirectly, to any third party
        without the other party’s prior written consent, except as otherwise permitted hereunder.
        However, either party may disclose Confidential Information to its employees, officers,
        directors, attorneys, auditors, financial advisors and other representatives who have a need
        to know and are legally bound to keep such information confidential by confidentiality
        obligations consistent with those of this Agreement; and as required by law (in which case
        the receiving party will provide the disclosing party with prior written notification
        thereof, will provide the disclosing party with the opportunity to contest such disclosure,
        and will use its reasonable efforts to minimize such disclosure to the extent permitted by
        applicable law. Neither party will disclose the terms of this Agreement to any third party,
        except that either party may confidentially disclose such terms to actual or potential
        lenders, investors or acquirers. Each party agrees to exercise due care in protecting the
        Confidential Information from unauthorized use and disclosure. In the event of actual or
        threatened breach of the provisions of this Section, the non-breaching party will be
        entitled to seek immediate injunctive and other equitable relief, without waiving any other
        rights or remedies available to it. Each party will promptly notify the other in writing if
        it becomes aware of any violations of the confidentiality obligations set forth in this
        Agreement.
      </Typography>

      <Typography paragraph>
        <Strong>4. Warranties and Disclaimers</Strong>
      </Typography>
      <Typography paragraph>
        4.1 <u>Your Warranties.</u> You represent and warrant that (a) you have full power and
        authority to use and/or provide for use Your Engine without violating any other agreement;
        (b) Your Engine and its use as contemplated herein will not violate any third party rights
        (including intellectual property rights and rights of privacy or publicity) or any
        applicable laws or regulations; (c) all information you provide to PolySwarm is and will be
        true, accurate, and complete; (d) you will not interfere with PolySwarm’s business
        practices, including in relation to the Marketplace or PolySwarm Platform; and (e) Your
        Engine will not contain or transmit any viruses or other malicious code.
      </Typography>
      <Typography paragraph>
        4.2 <u>PolySwarm Platform.</u> PolySwarm represents and warrants that (a) it has full power
        and authority to use and/or provide for use the PolySwarm Platform without violating any
        other agreement; (b) the PolySwarm Platform and its use as contemplated herein will not
        violate any third party rights (including intellectual property rights and rights of privacy
        or publicity) or any applicable laws or regulations; and (c) the PolySwarm Platform
        (excluding Submitted Artifacts and Engines that may interact with the PolySwarm Platform)
        will not contain or transmit any viruses or other malicious code.
      </Typography>
      <Typography paragraph>
        4.3 <u>DISCLAIMER.</u> EXCEPT AS EXPRESSLY SET FORTH HEREIN, EACH PARTY DISCLAIMS ANY AND
        ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, TITLE,
        NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. POLYSWARM DOES NOT PROVIDE ANY
        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, TITLE,
        NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE, IN RELATION TO SUBMITTED ARTIFACTS,
        AND YOU AGREE THAT YOU ARE SCANNING SUBMITTED ARTIFACTS SOLELY AT YOUR OWN RISK.
      </Typography>

      <Typography paragraph>
        <Strong>5. Indemnification</Strong>
      </Typography>
      <Typography paragraph>
        Each party will defend the other party against any claim, demand, suit, or proceeding
        (“Claim”) made or brought against the indemnified party by a third party resulting from a
        breach of the indemnifying party’s representations or warranties hereunder, provided that
        (a) the indemnified party will promptly notify the indemnifying party of such Claim; (b) the
        indemnifying party will have the sole and exclusive authority to defend and/or settle any
        such Claim (provided that the indemnifying party may not settle any Claim without the
        indemnified party’s prior written consent, which will not be unreasonably withheld, unless
        it unconditionally releases the indemnified party of all related liability); and (c) the
        indemnified party reasonably cooperates with The indemnifying party in connection therewith.
        In the case of Claims in which a third party is alleging infringement or misappropriation of
        its intellectual property rights, the indemnifying party will have no liability or
        obligation with respect to any Claim to the extent such Claim is caused in whole or in part
        by (A) use of the PolySwarm Platform (in the case of PolySwarm as the indemnifying party) or
        use of Your Engine (in the case of you as the indemnifying party) not in accordance with
        this Agreement; or (B) the combination of the PolySwarm Platform (in the case of PolySwarm
        as the indemnifying party) or use of Your Engine (in the case of you as the indemnifying
        party) with third party applications, portions of applications, product(s) where the Claim
        would not have arisen but for such combination.
      </Typography>

      <Typography paragraph>
        <Strong>6. Limitation of Liability</Strong>
      </Typography>
      <Typography paragraph>
        UNDER NO LEGAL THEORY, WHETHER IN TORT, CONTRACT, OR OTHERWISE, WILL EITHER PARTY BE LIABLE
        FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY
        CHARACTER, INCLUDING DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS, LOST SALES OR BUSINESS,
        WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, LOST CONTENT OR DATA, OR FOR ANY AND ALL
        OTHER DAMAGES OR LOSSES, EVEN IF A REPRESENTATIVE OF SUCH PARTY HAS BEEN ADVISED, KNEW OR
        SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES; OR (B) ANY DIRECT DAMAGES, COSTS, OR
        LIABILITIES IN EXCESS OF THE GREATER OF (A) AMOUNTS OF CASH REWARDS PAID TO SECURITY EXPERT
        DURING THE TWELVE (12) MONTHS PRECEDING THE INCIDENT OR CLAIM OR (B) $1000.
      </Typography>

      <Typography paragraph>
        <Strong>7. Termination</Strong>
      </Typography>
      <Typography paragraph>
        Either party may terminate this Agreement at any time upon notice to the other party. Upon
        termination of this Agreement, all rights and obligations will immediately terminate except
        that any terms or conditions that by their nature should survive such termination will
        survive, including terms and conditions relating to confidentiality, disclaimers,
        indemnification, limitations of liability and termination and the general provisions below.
      </Typography>

      <Typography paragraph>
        <Strong>8. General</Strong>
      </Typography>
      <Typography paragraph>
        8.1 <u>Export Compliance.</u> Each party will comply with the export laws and regulations of
        the United States, European Union and other applicable jurisdictions in providing and using
        the PolySwarm Platform.
      </Typography>
      <Typography paragraph>
        8.2 <u>Assignment; Delegation.</u> Neither party hereto may assign or otherwise transfer
        this Agreement, in whole or in part, without the other party’s prior written consent, except
        that either party may assign this Agreement without consent to a successor to all or
        substantially all of its assets or business related to this Agreement. Any attempted
        assignment, delegation, or transfer by either party in violation hereof will be null and
        void. Subject to the foregoing, this Agreement will be binding on the parties and their
        successors and assigns.
      </Typography>
      <Typography paragraph>
        8.3 <u>Amendment; Waiver.</u> PolySwarm reserves the right in its sole discretion and at any
        time and for any reason to modify the terms of this Agreement. If we do this, we will post
        the changes on this page and will indicate at the top of this page the date this Agreement
        was last revised. You may read a current, effective copy of these Terms of Service by
        visiting <Link href='https://polyswarm.network/engine-agreement' />. We will also notify you
        of any material changes, either through the Marketplace user interface, a pop-up notice,
        email, or through other reasonable means. Your continued use of the Marketplace after the
        date any such changes become effective constitutes your acceptance of the new terms of this
        Agreement. You should periodically visit this page to review the current terms so you are
        aware of any revisions. If you do not agree to abide by these or any future terms of this
        Agreement, do not submit an Engine to or otherwise participate in the Marketplace. No waiver
        by either party of any breach or default hereunder shall be deemed to be a waiver of any
        preceding or subsequent breach or default. Any such waiver will be only to the specific
        provisions and under the specific circumstances for which it was given, and will not apply
        with respect to any repeated or continued violation of the same provision or any other
        provision. Failure or delay by either party to enforce any provision of this Agreement will
        not be deemed a waiver of future enforcement of that or any other provision.
      </Typography>
      <Typography paragraph>
        8.4 <u>Relationship.</u> Nothing contained herein will in any way constitute any
        association, partnership, agency, employment or joint venture between the parties hereto, or
        be construed to evidence the intention of the parties to establish any such relationship.
        Neither party will have the authority to obligate or bind the other in any manner, and
        nothing herein contained will give rise or is intended to give rise to any rights of any
        kind to any third parties.
      </Typography>
      <Typography paragraph>
        8.5 <u>Unenforceability.</u> If a court of competent jurisdiction determines that any
        provision of this Agreement is invalid, illegal, or otherwise unenforceable, such provision
        will be enforced as nearly as possible in accordance with the stated intention of the
        parties, while the remainder of this Agreement will remain in full force and effect and bind
        the parties according to its terms.
      </Typography>
      <Typography paragraph>
        8.6 <u>Governing Law; Venue.</u> This Agreement will be governed by the laws of the State of
        California, exclusive of its rules governing choice of law and conflict of laws. This
        Agreement will not be governed by the United Nations Convention on Contracts for the
        International Sale of Goods. All disputes arising out of the Agreement will be subject to
        the exclusive jurisdiction and venue of the state and federal courts of San Francisco,
        California, and the parties hereby consent to the personal jurisdiction of these courts.
      </Typography>
      <Typography paragraph>
        8.7 <u>Notices.</u> Any notice required or permitted to be given hereunder to PolySwarm will
        be given in writing by personal delivery, certified mail, return receipt requested, or by
        overnight delivery to the address set forth below.
        <Address>
          <li>Swarm Technologies Inc.</li>
          <li>310 AVENIDA DE DIEGO #103</li>
          <li>SAN JUAN, PR 00909 </li>
          <li>Attn: Legal</li>
        </Address>
        Notices to Security Expert may be sent to the email or mailing address listed on Security
        Expert’s applicable enrollment form.
      </Typography>
      <Typography paragraph>
        8.8 <u>Entire Agreement.</u> This Agreement comprises the entire agreement between Security
        Expert and PolySwarm with respect to its subject matter, and supersedes all prior and
        contemporaneous proposals, statements, sales materials or presentations and agreements (oral
        and written). Any acceptance by Security Expert of online terms of service covering the same
        subject matter of this Agreement will be superseded by this Agreement. No oral or written
        information or advice given by PolySwarm, its agents or employees will create a warranty or
        in any way increase the scope of the warranties in this Agreement.
      </Typography>
      <Typography paragraph>
        8.9 <u>Force Majeure.</u> Neither Party will be deemed in breach hereunder for any
        cessation, interruption or delay in the performance of its obligations due to causes beyond
        its reasonable control (<StrongQuote>Force Majeure Event</StrongQuote>), including
        earthquake, flood, or other natural disaster, act of God, labor controversy, civil
        disturbance, terrorism, war (whether or not officially declared), cyber attacks (e.g.,
        denial of service attacks), or the inability to obtain sufficient supplies, transportation,
        or other essential commodity or service required in the conduct of its business, or any
        change in or the adoption of any law, regulation, judgment or decree.
      </Typography>
      <Typography paragraph>
        8.10 <u>Interpretation.</u> For purposes hereof, <Quote>including</Quote> means{' '}
        <Quote>including without limitation</Quote>.
      </Typography>
    </Wrapper>
  );
};

const EngineAgreementPage = () => {
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <EngineAgreementPageContent />
      </BaseLayout>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {
    header: {
      color: styles.color.purple,
    },
    subheader: {
      color: styles.color.lightGrey,
    },
  },
  dark: {
    header: {
      color: styles.color.xLightGrey,
    },
    subheader: {
      color: styles.color.xLightGrey,
    },
  },
});

export default EngineAgreementPage;
