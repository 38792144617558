import { CSSProperties, ReactElement, ReactNode } from 'react';
import {
  TableHeaderProps,
  RowMouseEventHandlerParams,
  TableCellProps,
  Table,
  CellMeasurerCache,
  Index,
} from 'react-virtualized';

export interface IAutoSizer {
  width: number;
  height: number;
}

export enum EColumnAlign {
  LEFT,
  RIGHT,
}

export enum ESortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IColumn<T> {
  id: T | 'actions';
  label: string;
  numeric?: boolean;
  sortable?: boolean;
  width?: number;
  align?: EColumnAlign;
  dataRenderer?: Function;
}

export interface ITableRow {
  index: number;
}

export interface IColumnSort<T> {
  orderBy: T;
  direction: ESortDirection;
}

export interface IInfiniteScrollTable<T> {
  columns: IColumn<keyof T>[];
  data: any[];
  loading?: boolean;
  rowHeight?: number | ((arg0: Index) => number);
  rowStyle?: ({ index }: { index: number }) => Object | Object;
  headerHeight?: number;
  hasMore?: boolean;
  onLoadMore?: () => Promise<void>;
  loadMoreTreshold?: number;
  onRowClick?: (info: RowMouseEventHandlerParams) => void;
  onSort?: Function;
  sort?: IColumnSort<keyof T>;
  button?: ReactElement;
  disableHeader?: boolean;
  rowRenderer?: (arg0: IRowRender<T>) => ReactNode;
  deferredMeasurementCache?: CellMeasurerCache;
  registerRef?: (arg0: Table) => void;
}

export interface IOnRowClick<T> {
  event: any;
  index: number;
  rowData: T;
}

export interface IRowRender<T> {
  key: string;
  columns: IColumn<keyof T>[];
  index: number;
  onRowClick?: (args: IOnRowClick<T>) => void;
  rowData: T;
  style: CSSProperties;
  parent?: any;
}

export interface ICellRenderer<T> extends TableCellProps {
  column?: IColumn<T>;
}

export interface IRenderHeader<T> extends TableHeaderProps {
  columnIndex: number;
  column?: IColumn<T>;
  sort?: IColumnSort<T>;
  onSort?: Function;
}

export interface IDataRenderer<T = any, C = any> {
  cellData: C;
  rowData: T;
}
