import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'state/root';
import InfiniteScrollTable, {
  IColumn,
  IColumnSort,
  IDataRenderer,
} from 'views/components/table/InfiniteScrollTable';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { TagsList } from 'views/pages/MicroenginesPage/TagsList/TagsList';
import { AllMicroengines, PublicMicroengineListing } from 'models/Microengine';

interface IAllMicroenginesTable {
  microengines: AllMicroengines;
  sort?: IColumnSort<keyof PublicMicroengineListing>;
  onSort: (orderBy: keyof PublicMicroengineListing) => void;
  onLoadMore?: () => Promise<void>;
  onClickEngine: (engine: PublicMicroengineListing) => void;
}

const messages = defineMessages({
  name: {
    id: 'table.row.name',
    defaultMessage: 'Name',
  },
  tags: {
    id: 'table.row.tags',
    defaultMessage: 'Tags',
  },
});

const AllMicroenginesTable = ({
  microengines,
  sort,
  onSort,
  onLoadMore,
  onClickEngine,
}: IAllMicroenginesTable) => {
  const history = useHistory();
  const location = useLocation();
  const selectedEngine = useSelector((state: RootState) => state.microengines.selected);

  const { formatMessage } = useIntl();
  const { classes } = useStyles();

  const onClickTag = (tag: string) => {
    history.replace(`${location.pathname}?tags=${tag}`);
  };

  const columns: IColumn<keyof PublicMicroengineListing>[] = [
    {
      id: 'displayName',
      label: formatMessage(messages.name),
      sortable: true,
      dataRenderer: ({ cellData, rowData }: IDataRenderer<PublicMicroengineListing>) => (
        <span
          css={[classes.name, classes.link, rowData.selected && classes.selected]}
          onClick={onClickEngine.bind(null, rowData)}>
          {cellData}
        </span>
      ),
    },
    {
      id: 'tags',
      label: formatMessage(messages.tags),
      width: 2000,
      dataRenderer: ({ cellData }: IDataRenderer<PublicMicroengineListing>) => {
        return <TagsList tags={cellData} limit={7} onClick={onClickTag} />;
      },
    },
  ];

  return (
    <InfiniteScrollTable<PublicMicroengineListing>
      columns={columns}
      data={microengines.results.map((microengine) => ({
        ...microengine,
        selected: selectedEngine && selectedEngine.id === microengine.id,
      }))}
      sort={sort}
      onSort={onSort}
      onLoadMore={onLoadMore}
      loadMoreTreshold={5}
      hasMore={microengines.next_page}
    />
  );
};

const useStyles = makeStyles({
  base: {
    name: {
      fontWeight: styles.font.weight.bold,
      textDecoration: 'none',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
    communities: {
      color: styles.color.purple,
      fontWeight: styles.font.weight.medium,
      paddingLeft: '2rem',
    },
    moreTagsLabel: {
      color: styles.color.purple,
      fontWeight: styles.font.weight.medium,
      fontSize: '1.2rem',
      paddingLeft: '1rem',
      cursor: 'help',
    },
  },
  light: {
    link: {
      color: styles.color.black,
      '&[disabled]': {
        color: styles.color.xLightGrey,
      },
      '&:hover:not([disabled])': {
        color: styles.color.purple,
      },
    },
    moreTagsLabel: {
      color: styles.color.purple,
    },
    selected: {
      color: styles.color.purple,
    },
  },
  dark: {
    link: {
      color: styles.color.white,
      '&[disabled]': {
        color: styles.color.lightGrey,
      },
      '&:hover:not([disabled])': {
        color: styles.color.lightBlue,
      },
    },
    moreTagsLabel: {
      color: styles.color.lightBlue,
    },
    selected: {
      color: styles.color.lightBlue,
    },
  },
});

export default AllMicroenginesTable;
