import { Action } from 'redux';
import { Auth0DecodedHash } from 'auth0-js';
import { AsyncAction } from 'state/types/actions';

export enum AuthActionName {
  LOGIN = 'LOGIN',
  SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL',
  SET_SESSION = 'SET_SESSION',
  SCHEDULE_RENEWAL = 'SCHEDULE_RENEWAL',
  LOGOUT = 'LOGOUT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  ACCESS_DENIED_ERROR = 'ACCESS_DENIED_ERROR',
}

export interface LoginAction extends Action<AuthActionName.LOGIN> {
  lastLocation?: string;
}

export interface SendVerificationEmailAction
  extends AsyncAction<AuthActionName.SEND_VERIFICATION_EMAIL, {}> {}

export interface SetSessionAction extends Action<AuthActionName.SET_SESSION> {
  expiresAt: number;
  accessToken: string;
  idTokenPayload: Auth0DecodedHash['idTokenPayload'];
  scope: string;
}

export interface ScheduleRenewalAction extends Action<AuthActionName.SCHEDULE_RENEWAL> {}

export interface LogoutAction extends Action<AuthActionName.LOGOUT> {
  lastLocation?: string;
}

export interface ChangePasswordAction extends AsyncAction<AuthActionName.CHANGE_PASSWORD, string> {}

export interface AccessDeniedErrorAction extends Action<AuthActionName.ACCESS_DENIED_ERROR> {}

export type AuthAction =
  | LoginAction
  | SendVerificationEmailAction
  | SetSessionAction
  | ScheduleRenewalAction
  | LogoutAction
  | ChangePasswordAction
  | AccessDeniedErrorAction;
