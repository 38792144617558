import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { openModal } from 'state/modal/actions';
import { useIntl } from 'react-intl';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { DATE_TIME_FORMAT } from 'utils/constants';
import InfiniteScrollTable, {
  IColumn,
  IDataRenderer,
  IColumnSort,
  EColumnAlign,
  IRowRender,
} from 'views/components/table/InfiniteScrollTable';
import tableStyles from 'views/components/table/InfiniteScrollTable/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { btnMessages } from 'views/components/Button';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import ContextMenu from 'views/components/menu/ContextMenu';
import { DevelopmentResult } from 'models/Microengine';
import { useSetGroundTruth } from '../../hooks/useSetGroundTruth';

interface IDevelopmentResultsTable {
  developmentResults: any;
  sort?: IColumnSort<keyof DevelopmentResult>;
  onLoadMore?: () => Promise<void>;
  refresh: () => void;
}

interface SetGroundTruthModalParams {
  setOption: (option: string) => Promise<void>;
}

interface HeaderRow {
  type: 'header';
  title: string;
}

const CopyButton = styled(CopyTextButton)`
  font-size: 0.5rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const FlexTableRow = styled(TableRow)`
  display: flex !important;
`;

const DevelopmentResultsTable = ({
  developmentResults,
  sort,
  onLoadMore,
  refresh,
}: IDevelopmentResultsTable) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { setGroundTruth } = useSetGroundTruth(refresh);

  const columns: IColumn<keyof DevelopmentResult>[] = [
    {
      id: 'sha256',
      label: 'sha256',
      sortable: true,
      width: 1600,
      dataRenderer: ({ cellData }: IDataRenderer<DevelopmentResult>) => (
        <React.Fragment>
          <span>{cellData.substring(0, 30)}</span>
          <CopyButton text={cellData} />
        </React.Fragment>
      ),
    },
    {
      id: 'verdict',
      label: 'Verdict',
      sortable: true,
      dataRenderer: ({ cellData }: IDataRenderer<DevelopmentResult>) => <span>{cellData}</span>,
    },
    {
      id: 'expected_verdict',
      label: 'Expected Verdict',
      sortable: true,
      width: 400,
      dataRenderer: ({ cellData }: IDataRenderer<DevelopmentResult>) => <span>{cellData}</span>,
    },
    {
      id: 'status',
      label: 'Status',
      sortable: true,
      width: 400,
      dataRenderer: ({ cellData }: IDataRenderer<DevelopmentResult>) => <span>{cellData}</span>,
    },
    {
      id: 'created_at',
      label: 'Timestamp',
      sortable: true,
      dataRenderer: ({ cellData }: IDataRenderer<DevelopmentResult>) =>
        formatDate(parseDate(cellData), DATE_TIME_FORMAT),
    },
    {
      id: 'actions',
      label: formatMessage({ id: 'table.head.actions' }),
      align: EColumnAlign.RIGHT,
      dataRenderer: ({ rowData }: IDataRenderer<DevelopmentResult>) => {
        return (
          <ContextMenu
            testId='myDevelopmentCommunityMenuBtn'
            options={[
              {
                label: formatMessage(btnMessages.setGroundTruth),
                type: 'primary',
                onClick: () => {
                  dispatch(
                    openModal<SetGroundTruthModalParams>('SET_GROUND_TRUTH_MODAL', {
                      setOption: (option: string) =>
                        setGroundTruth(rowData.community, rowData.instance_id, option),
                    })
                  );
                },
                testId: 'myDevelopmentCommunitySetGroundTruth',
              },
            ]}
          />
        );
      },
    },
  ];

  const rowStyle = () => ({});

  const rowRenderer = <T extends HeaderRow>({
    key,
    columns,
    index,
    onRowClick,
    rowData,
  }: IRowRender<T>) => {
    if (rowData.type === 'header') {
      return (
        <TableRow key={key} hover>
          <TableCell component='div' variant='head' style={tableStyles.cell}>
            {rowData.title}
          </TableCell>
        </TableRow>
      );
    }

    const _onClick = (event: any) => {
      if (onRowClick) {
        onRowClick({ event, index, rowData });
      }
    };

    return (
      <React.Fragment>
        <FlexTableRow key={key} onClick={_onClick} hover>
          {columns}
        </FlexTableRow>
      </React.Fragment>
    );
  };

  return (
    <InfiniteScrollTable<DevelopmentResult & HeaderRow>
      columns={columns}
      data={developmentResults}
      sort={sort}
      loadMoreTreshold={5}
      onLoadMore={onLoadMore}
      hasMore={false}
      rowStyle={rowStyle}
      rowRenderer={rowRenderer}
    />
  );
};

export default DevelopmentResultsTable;
