import React from 'react';
import { Field, FieldProps } from 'formik';
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import styles from 'views/styles';
import CheckboxField from '../CheckboxField';

type CheckboxOption = {
  value: any;
  label: string;
};

interface IFormCheckboxArrayField {
  className?: string;
  name: string;
  label?: string;
  errorMessage?: string;
  options: CheckboxOption[];
  disabled?: boolean;
  onChange?: (event: any) => void;
}

const FormCheckboxArrayField = ({
  className,
  name,
  label,
  options,
  errorMessage,
  disabled,
  onChange,
}: IFormCheckboxArrayField) => {
  const muiClasses = useMuiStyles();
  return (
    <div className={className}>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <FormControl error={!!errorMessage}>
            {label && <FormLabel className={muiClasses.label}>{label}</FormLabel>}

            <div className='h-mt-tiny'>
              <FormGroup>
                {options.map((item, idx) => (
                  <CheckboxField
                    key={idx}
                    disabled={disabled}
                    className={muiClasses.checkbox}
                    label={item.label}
                    checked={(field.value || []).includes(item.value)}
                    name={`${name}.${idx}`}
                    value={item.value}
                    onChange={(event) => {
                      const target = event.currentTarget;
                      const value = target.value;
                      const valueArray = field.value || [];

                      if (target.checked) {
                        valueArray.push(value);
                      } else {
                        valueArray.splice(valueArray.indexOf(value), 1);
                      }

                      form.setFieldValue(field.name, valueArray);
                      form.setFieldTouched(field.name, true);

                      if (onChange) {
                        onChange(event);
                      }
                    }}
                  />
                ))}
              </FormGroup>
              {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </div>
          </FormControl>
        )}
      </Field>
    </div>
  );
};

const useMuiStyles = makeMuiStyles({
  label: {
    fontSize: styles.font.size.p1,
  },
  checkbox: {
    margin: '0.4rem 0',
  },
});

export default FormCheckboxArrayField;
