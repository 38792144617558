import { useState, useEffect } from 'react';
import { Analytics, HistoricalStats } from 'models/Analytycs';
import api from 'services/api';

interface AnalyticsData<T> {
  main_net: T;
  test_net: T;
}

export const useAnalyticsStats = () => {
  const [latest, setLatest] = useState<AnalyticsData<Analytics> | null>(null);
  const [historical, setHistorical] = useState<AnalyticsData<HistoricalStats[]> | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);

  const refetch = () => setLoadMore(!loadMore);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      try {
        const latestData = await api.getLatestAnalyticsData();
        const historicalData = await api.getHistoricalAnalyticsData();

        if (active) {
          setLatest(latestData.data);
          setHistorical(historicalData.data);
          setError(false);
          setLoading(false);
        }
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadMore]);

  return { error, loading, latest, historical, refetch };
};
