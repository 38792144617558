import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

interface Props<T> {
  className?: string;
  rows: T[];
  showActions?: boolean;
  actionAlign?: 'right' | 'left' | 'center';
  mustRenderHeaderCell?: (data: T) => boolean;
  renderHeaderCell: (data: T) => JSX.Element;
}

const CustomTableHead = <T extends {}>({
  className,
  rows,
  showActions,
  actionAlign = 'right',
  mustRenderHeaderCell,
  renderHeaderCell,
}: Props<T>) => {
  const mustRender = mustRenderHeaderCell ?? (() => true);
  return (
    <TableHead className={className}>
      <TableRow>
        {rows.filter(mustRender).map(renderHeaderCell)}
        {showActions && (
          <TableCell key='cell-actions' align={actionAlign}>
            <FormattedMessage id='table.head.actions' defaultMessage='Actions' />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
