import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-open-view' viewBox='0 0 16 16'>
      <path d='M13.3333 13.3333H1.66667V1.66667H7.5V0H1.66667C0.741667 0 0 0.75 0 1.66667V13.3333C0 14.25 0.741667 15 1.66667 15H13.3333C14.25 15 15 14.25 15 13.3333V7.5H13.3333V13.3333ZM9.16667 0V1.66667H12.1583L3.96667 9.85833L5.14167 11.0333L13.3333 2.84167V5.83333H15V0H9.16667Z' />
    </symbol>

    <Global
      styles={css`
        .icon-open-view {
          width: 3em;
          height: 3em;
        }
      `}
    />
  </Fragment>
);
