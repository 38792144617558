import { Auth0DecodedHash } from 'auth0-js';
import { defineMessages } from 'react-intl';
import auth from 'services/auth';
import api from 'services/api';
import config from 'config';
import request from 'state/utils/request';
import { Thunk } from 'state/types/thunk';
import { AuthActionName } from './types';
import { notExpired } from 'utils/date/date';

const messages = defineMessages({
  emailSuccess: {
    id: 'signup.verifyEmail.resend.success',
    defaultMessage: 'Verification email sent',
  },
  emailFailure: {
    id: 'signup.verifyEmail.resend.failure',
    defaultMessage: 'Error sending account verification email. Please try again.',
  },
  passwordSuccess: {
    id: 'account.changePassword.success',
    defaultMessage: 'Change password email sent. Please check your email inbox.',
  },
  passwordFailure: {
    id: 'account.changePassword.failure',
    defaultMessage: 'Error sending change password email. Please try again.',
  },
});

const MFAkey = '2FA';
const MFALastAction = '2FALastAction';
const setMFAData = (data: any) => {
  const created = new Date().getTime();
  localStorage.setItem(MFAkey, JSON.stringify({ created, data }));
  if (data.modal) {
    // This item is not deleted when MFAkey is deleted
    localStorage.setItem(MFALastAction, JSON.stringify({ created, action: data.modal }));
  }
};
export const getMFAData = (): any | null => {
  const mfaData = localStorage.getItem(MFAkey);
  if (mfaData) {
    const { data, created } = JSON.parse(mfaData);
    if (notExpired(new Date(created), parseInt(config.tokenExpirationSec, 10) * 1000)) {
      return data;
    } else {
      removeMFAData();
    }
  }
  return null;
};

export const getMFALastAction = (): { action: string; created: number } | null => {
  const lastAction = localStorage.getItem(MFALastAction);
  if (lastAction) {
    return JSON.parse(lastAction);
  }
  return null;
};

const TEAM_ACTIONS = [
  'CREATE_TEAM',
  'UPDATE_TEAM',
  'ARCHIVE_TEAM',
  'LEAVE_TEAM',
  'ARCHIVE_TEAM_COMPLETE',
  'INVITE_TEAM_MEMBER',
  'UPDATE_TEAM_MEMBER',
  'ARCHIVE_TEAM_MEMBER',
  'FORCE_MFA',
  'CHANGE_QUOTA',
];

export const hasMadeTeamMFARecently = (): boolean => {
  const lastAction = getMFALastAction();
  if (lastAction && TEAM_ACTIONS.indexOf(lastAction.action) >= 0) {
    return notExpired(
      new Date(lastAction.created),
      parseInt(config.otherTokenExpirationSec, 10) * 1000
    );
  }
  return false;
};

export const hasMadeScopedMFARecently = (): boolean => {
  const lastAction = getMFALastAction();
  if (lastAction) {
    const expirationSec =
      TEAM_ACTIONS.indexOf(lastAction.action) >= 0
        ? config.otherTokenExpirationSec
        : config.tokenExpirationSec;
    return notExpired(new Date(lastAction.created), parseInt(expirationSec, 10) * 1000);
  }
  return false;
};

export const removeMFAData = () => localStorage.removeItem(MFAkey);
export const removeMFALastAction = () => localStorage.removeItem(MFALastAction);

const NONCE_KEY = 'NONCE'; // used as a withdrawal nonce
const WALLET_NONCE_KEY = 'WALLET_NONCE'; // used in the token "https://portal-api/wallet" claim
export const setWalletNonce = (walletNonce: string, nonce?: string) => {
  sessionStorage.setItem(WALLET_NONCE_KEY, walletNonce);
  if (nonce) {
    sessionStorage.setItem(NONCE_KEY, nonce);
  }
};
export const getWalletNonce = () => sessionStorage.getItem(WALLET_NONCE_KEY);
export const getNonce = () => sessionStorage.getItem(NONCE_KEY);
export const removeWalletNonce = () => {
  sessionStorage.removeItem(WALLET_NONCE_KEY);
  sessionStorage.removeItem(NONCE_KEY);
};

/**
 * Trigger auth0 login, store location, and return to callback page.
 */
export const login = (lastLocation?: string, email?: string): Thunk => (dispatch) => {
  dispatch({ type: AuthActionName.LOGIN, lastLocation });
  auth.login(email);
};

export const loginRemoveMfa = (data?: any, email?: string): Thunk => (dispatch) => {
  setMFAData(data);
  auth.loginRemoveMfa(email);
};

export const loginWriteMfa = (data?: any, email?: string): Thunk => (dispatch) => {
  setMFAData(data);
  auth.loginWriteMfa(email);
};

export const loginWriteApiKey = (data?: any, email?: string): Thunk => (dispatch) => {
  setMFAData(data);
  auth.loginWriteApiKey(email);
};

export const loginWriteTeams = (data?: any, email?: string): Thunk => (dispatch) => {
  setMFAData(data);
  auth.loginWriteTeams(email);
};

export const loginWalletUpdate = (data?: any, email?: string): Thunk => (dispatch) => {
  setMFAData(data);
  const { address, limit, accountNumber } = data;
  auth.loginWalletUpdate(address, limit, accountNumber, email);
};

export const loginWalletWithdrawal = (data?: any, email?: string): Thunk => (dispatch) => {
  setMFAData(data);
  const { amount, fee, accountNumber } = data;
  auth.loginWalletWithdrawal(amount, fee, accountNumber, email);
};

/**
 * Trigger auth0 logout and clear tokens and scheduled renewals.
 * Redirects back to stored location whe complete.
 */
export const logout = (lastLocation?: string): Thunk => (dispatch) => {
  clearTimeout(renewalTimer);
  dispatch({ type: AuthActionName.LOGOUT, lastLocation });
  auth.logout();
};

export const sendVerificationEmail = (accessToken: string) =>
  request(
    () => api.sendVerificationEmail(accessToken).then((res) => res.data),
    { type: AuthActionName.SEND_VERIFICATION_EMAIL },
    { success: messages.emailSuccess, failure: messages.emailFailure }
  );

/**
 * Store access token and expiry time in persisted state.
 * Automatically schedules access token renewal.
 */
export const setSession = (decodedHash: Auth0DecodedHash): Thunk => (dispatch) => {
  const { expiresIn, accessToken, idTokenPayload, scope } = decodedHash;
  const expiresAt = expiresIn! * 1000 + new Date().getTime();
  dispatch(scheduleRenewal(expiresAt));
  dispatch({
    type: AuthActionName.SET_SESSION,
    accessToken: accessToken!,
    idTokenPayload,
    expiresAt,
    scope,
  });
};

/**
 * Schedule access token to renew at expiry time.
 */
let renewalTimer: number;
export const scheduleRenewal = (expiresAt: number): Thunk => (dispatch) => {
  const timeout = expiresAt - Date.now();
  if (timeout > 0) {
    renewalTimer = window.setTimeout(() => dispatch(renewSession()), timeout);
  }
  dispatch({ type: AuthActionName.SCHEDULE_RENEWAL });
};

/**
 * Retrieve new access token and set the session.
 */
export const renewSession = (): Thunk<Promise<void>> => async (dispatch) => {
  try {
    const decodedHash = await auth.checkSession();
    dispatch(setSession(decodedHash));
  } catch (error) {
    console.error('ERROR renewSession', error);
    // dispatch(logout());
  }
};

export const changePassword = (email: string, withNotify?: boolean) =>
  request(
    () => auth.changePassword(email).then((res) => res),
    { type: AuthActionName.CHANGE_PASSWORD },
    withNotify && {
      success: messages.passwordSuccess,
      failure: messages.passwordFailure,
    }
  );
