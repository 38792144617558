import Big, { BigSource } from 'big.js';
import { v5 as uuidv5 } from 'uuid';

import config from '../../config';

export const nctToEthDenominator = (nct: BigSource): string =>
  new Big(nct).times(new Big(10).pow(-18)).toFixed();

const NCT_TO_WEI = new Big(10).pow(18);

export const toNCT = (value: BigSource): Big => new Big(value).div(NCT_TO_WEI);
export const toWEI = (value: BigSource): Big => new Big(value).mul(NCT_TO_WEI);

/**
 * Generate a hashed version of the address + limit, used in
 * wallet write operations as a "claim" in the tokens acquired for that purpose.
 */
export const generateWalletNonce = (
  accountNumber: number,
  withdrawalAddress?: string | null,
  limit?: string | null
) => {
  const address = withdrawalAddress ? withdrawalAddress : 'x0';
  limit = limit ? toWEI(limit).toFixed() : '0';
  const text = `${accountNumber}+${address}+${limit}--${config.walletClaimSalt}`;
  return uuidv5(text, uuidv5.URL);
};

/**
 * Generate a hashed version of the nonce, used in
 * wallet withdrawal operations as a "claim" in the tokens acquired for that purpose.
 */
export const generateWithdrawalNonce = (
  accountNumber: number,
  amount: number | string,
  fee: number | string,
  nonce: number | string
) => {
  const text = `${accountNumber}+${amount}-${fee}-${nonce}--${config.walletClaimSalt}`;
  return uuidv5(text, uuidv5.URL);
};
