import type { CustomPages } from 'tenants/config';
import LandingPage from './custom-pages/Landing';

const customPages: CustomPages = {
  landing: LandingPage,
  terms: 'https://www.accenture.com/us-en/support/security/security-legal-terms',
  privacy: 'https://www.accenture.com/us-en/support/security/security-legal-terms',
};

export default customPages;
