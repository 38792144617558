import { useState, useEffect } from 'react';
import api from 'services/api';
import { getBalanceValues } from 'utils/balance';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { ITransactions, WalletsType } from '../types';
import Big from 'big.js';

type OptDate = Date | null;

const getDate = (date: Date) => date.toISOString().split('T')[0];

export default function useTransactions({ id, type }: { id: number; type: WalletsType }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<ITransactions[] | null>(null);
  const [dates, setDates] = useState<{ start?: OptDate; end?: OptDate }>({});

  const setTransactionDates = (start: OptDate, end: OptDate) => {
    setDates({ start, end });
  };

  async function fetchTransactions() {
    try {
      setError(false);
      setLoading(true);
      const params = dates ? dates : {};
      if (type === 'engine') {
        // TODO: get engine transactions
        // const response = await api.getEngineTransactions({id, ...params });
        setTransactions([]);
      } else {
        const response = await api.getRewardsTransactions({
          start: params.start ? getDate(params.start) : undefined,
          end: params.end ? getDate(params.end) : undefined,
        });
        setTransactions(
          response.data.map((transaction) => {
            const type = transaction.type || 'Withdrawal';
            const factor = type === 'Withdrawal' ? Big(-1) : Big(1);
            const [balance, fullBalance] = getBalanceValues(factor.mul(transaction.amount));
            const [fee, fullFee] = getBalanceValues(factor.mul(transaction.fee));
            const [total, fullTotal] = getBalanceValues(
              factor.mul(Big(transaction.fee).add(transaction.amount))
            );
            return {
              id: transaction.transaction_hash || '',
              amount: balance,
              fullAmount: fullBalance,
              total,
              fullTotal,
              fee: fee ? fee : '0',
              fullFee,
              status: transaction.status,
              type: transaction.type || 'Withdrawal',
              timestamp: formatDate(parseDate(transaction.created_at), DATE_TIME_FORMAT),
            };
          })
        );
      }
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  }

  useEffect(() => {
    if (Object.keys(dates).length) {
      fetchTransactions();
    }
  }, [dates]); // eslint-disable-line react-hooks/exhaustive-deps

  return { transactions, fetchTransactions, setTransactionDates, loading, error };
}
