import React from 'react';
import { without, indexOf } from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ScanResultsButton from './ScanResultsButton';
import { SmallLogo } from './Logo';
import Tag from './Tag';
import CopyButton from './CopyButton';
import { ThreatArtifact } from 'models/Tag';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import { PolyScoreTableBar } from 'views/components/PolyScore/PolyScoreTableBar';

const FIRST_SEEN_TAG = 'first_seen';

interface IMalwareTableRowProps {
  data: ThreatArtifact;
  filter: string | null;
}

const MalwareTableRow = ({ data, filter }: IMalwareTableRowProps) => {
  const intl = useIntl();
  const tags = without(data.tags, FIRST_SEEN_TAG);
  const isFirstSeen = indexOf(data.tags, FIRST_SEEN_TAG) !== -1;
  const polyscore = data.latest_instance.polyscore
    ? parseFloat(data.latest_instance.polyscore)
    : null;

  return (
    <TableRow>
      <TableCell>
        <ScanResultsButton
          hash={data.sha256}
          analyticsEvent={{
            category: 'Emerging Threats',
            action: 'View Scan Results click-Widget',
            label: filter || 'none',
          }}
        />
      </TableCell>
      <TableCell>
        {isFirstSeen && (
          <Tooltip
            placement='top'
            title={intl.formatMessage({
              id: 'scanPage.malwaretable.firstSeenHint',
              defaultMessage: 'This artifact was seen by PolySwarm before other vendors',
            })}
          >
            <span>
              <SmallLogo />
            </span>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <PolyScoreTableBar polyscore={polyscore} />
      </TableCell>
      <TableCell>
        {tags.map((tag, idx) => (
          <Tag key={idx}>{tag}</Tag>
        ))}
      </TableCell>
      <TableCell align='right'>
        <CopyButton
          hash={data.sha256}
          analyticsEvent={{
            category: 'Emerging Threats',
            action: 'Copy Click-Widget',
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default MalwareTableRow;
