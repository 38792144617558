import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { Redirect } from 'react-router-dom';
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { errorMessages } from 'utils/error';
import styles from 'views/styles';
import Progress from '../Progress';
import FileDetails from './FileDetails';
import FileIcon from './FileIcon';
import { parseScanPageURL } from 'views/url';

interface Props {
  isLoading: boolean;
  isModal?: boolean;
  fileUploaded: boolean;
  errorMessage?: string | null;
  percentage: number;
  filename: string;
  filesize: string;
  uuid: string | null | undefined;
  submissionId?: string;
  renderFailureBtn: () => JSX.Element;
  onCancel: () => void;
}

const FileUploadingState = (props: Props) => {
  const { event: gaEvent } = useAnalytics();
  const { isLoading, fileUploaded, errorMessage, uuid, submissionId } = props;
  const isError = !!errorMessage;

  if (isLoading) {
    return <FileLoadingState {...props} />;
  } else if (!!!isError && !!!uuid) {
    return <FileWaitingScanResultsState {...props} />;
  } else if (!!!isError && fileUploaded) {
    gaEvent({
      category: 'Scan',
      action: 'File Upload',
    });
    if (props.isModal) {
      return null;
    }
    return (
      <Redirect
        push
        to={{
          pathname: parseScanPageURL(uuid!, { type: 'file' }),
          state: { search: true, submissionId },
        }}
      />
    );
  }
  if (!fileUploaded && !!isError) {
    return <FileErrorState {...props} />;
  }
  return <></>;
};

const FileLoadingState = ({ percentage, filename, filesize, onCancel }: Props) => (
  <div css={style.root} data-cy='fileUploading'>
    <h2 className='h2 h-mb-tiny'>
      <FormattedMessage id='fileupload.uploading.heading' defaultMessage='Uploading file…' />
    </h2>
    <Progress className='h-mt-sm h-mb-xxs' percentage={percentage} />
    <FileDetails filename={filename} filesize={filesize} onCancel={onCancel} />
  </div>
);

const FileWaitingScanResultsState = ({ percentage, filename, filesize, onCancel }: Props) => (
  <div css={style.root} data-cy='fileWaitingScanResults'>
    <h2 className='h2 h-mb-tiny'>
      <FormattedMessage
        id='fileupload.waitingScanResults.heading'
        defaultMessage='Waiting for scan results…'
      />
    </h2>
    <Progress className='h-mt-sm h-mb-xxs' percentage={percentage} />
    <FileDetails filename={filename} filesize={filesize} onCancel={onCancel} />
  </div>
);

const FileErrorState = ({
  errorMessage,
  filename,
  filesize,
  renderFailureBtn,
  onCancel,
}: Props) => {
  const intl = useIntl();
  return (
    <div css={style.root} data-cy='uploadError'>
      <h2 className='h2 h-mb-tiny' css={style.error}>
        <FormattedMessage id='fileupload.failed.heading' defaultMessage='Error uploading file' />
      </h2>
      <p className='p h-mb-tiny'>{errorMessage || intl.formatMessage(errorMessages.server)}</p>
      <FileIcon className='h-mt-sm h-mb-xxs' error={true} />
      {filename && filesize && (
        <FileDetails filename={filename} filesize={filesize} onCancel={onCancel} />
      )}
      {renderFailureBtn()}
    </div>
  );
};

const style = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    text-align: center;
  `,
  error: css`
    color: ${styles.color.red};
  `,
};

export default FileUploadingState;
