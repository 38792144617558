import React, { useEffect, Fragment, useState, useCallback, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { RootState } from 'state/root';
import languages from 'locale/localeData/data.json';
import Snackbar from 'views/components/Snackbar';
import { changeLanguage } from 'state/settings/actions';
import { DEFAULT_LANG, LANGS, LocaleState } from 'utils/constants';

export const locales = Object.keys(LANGS) as LocaleState[];

// TODO: Move snackbar state to redux?
let isLangNotifyDismissed = false;

interface ILocaleProviderState {
  selectedLanguage: LocaleState;
  isNativeLanguage: boolean;
  text: string;
  buttonText: string;
}

/**
 * The LocaleProvider component
 *
 * Provides multi-lingual support for the application
 */
const LocaleProvider: FC = ({ children }) => {
  const { locale } = useSelector((state: RootState) => state.settings);

  const [state, setState] = useState<ILocaleProviderState>({
    selectedLanguage: locale || DEFAULT_LANG,
    isNativeLanguage: true,
    text: '',
    buttonText: '',
  });

  const dispatch = useDispatch();

  const { selectedLanguage, isNativeLanguage, text, buttonText } = state;

  /**
   * Check the browser's default language and suggest that language to the user
   */
  const _checkBrowserLang = useCallback(() => {
    const currentLang = locale.substring(0, 2);
    const browserLang = navigator.language.substring(0, 2);

    if (currentLang !== browserLang) {
      // Find exact match
      if (Object.keys(LANGS).includes(navigator.language)) {
        const { text, buttonText } = LANGS[navigator.language as LocaleState];
        setState({
          selectedLanguage: navigator.language as LocaleState,
          isNativeLanguage: false,
          text,
          buttonText,
        });
      } else {
        // Find first closest match
        Object.keys(LANGS).every((lang) => {
          if (lang.substring(0, 2) === browserLang) {
            const { text, buttonText } = LANGS[lang as LocaleState];
            setState({
              selectedLanguage: lang as LocaleState,
              isNativeLanguage: false,
              text,
              buttonText,
            });
            return false;
          }
          return true;
        });
      }
    }
  }, [locale]);

  const _handleSnackbarClose = () => {
    isLangNotifyDismissed = true;
  };

  const _changeLanguage = (locale: LocaleState) => {
    dispatch(changeLanguage(locale));
    _handleSnackbarClose();
  };

  useEffect(() => {
    _checkBrowserLang();
  }, [_checkBrowserLang]);

  return (
    <IntlProvider locale={locale} messages={languages[locale]}>
      <Fragment>
        {!isNativeLanguage && !isLangNotifyDismissed && (
          <Snackbar
            id='locale-snackbar'
            openOnMount={true}
            renderActionBtn={() => (
              <button onClick={() => _changeLanguage(selectedLanguage)}>{buttonText}</button>
            )}
            onClose={_handleSnackbarClose}
          >
            {text}
          </Snackbar>
        )}
        {children}
      </Fragment>
    </IntlProvider>
  );
};

export default LocaleProvider;
