import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import * as yup from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { IntlShape, useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { formSchema } from 'utils/schema/formSchema';
import { formMessages } from 'utils/schema/formSchema';
import { btnMessages } from 'views/components/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import styled from '@emotion/styled';
import FormInput from 'views/components/form/FormInput';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Loader from 'views/components/Loader';
import CheckboxArrayField, {
  CheckboxOption,
} from 'views/pages/AuthCallbackPage/AccountSignupForm/CheckboxArrayField';

export interface IContactFormValues {
  userId?: string;
  teamId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  plan?: string;
  phone?: string;
  contactMethod?: string;
  jobTitle?: string;
  company?: string;
  options?: CheckboxOption[];
}

interface ISidebarPricingFormProps {
  loading: boolean;
  initialValues?: IContactFormValues;
  onSubmit: (values: IContactFormValues, actions: FormikHelpers<IContactFormValues>) => void;
  onClose: () => void;
  options?: CheckboxOption[];
  withOptions?: boolean;
  withMessage?: boolean;
}

interface IValidationSchemaProps {
  intl: IntlShape;
  withOptions?: boolean;
  withMessage?: boolean;
}

const messages = defineMessages({
  optionsLabel: {
    id: 'pricingpage.form.options.label',
    defaultMessage: 'How can we help you?',
  },
  optionsRequired: {
    id: 'pricingpage.form.options.required',
    defaultMessage: 'Select an option options above',
  },
  messageRequired: {
    id: 'pricingpage.form.message.required',
    defaultMessage: 'Tell us more about your use case',
  },
});

const FieldsWrapper = styled.div``;

const StyledCheckboxArrayField = styled(CheckboxArrayField)`
  margin-top: ${styles.spacing.sm};

  & .MuiCheckbox-root {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  & .MuiFormLabel-root {
    font-size: 1.6rem !important;
    font-weight: normal;
    padding-bottom: 1.5rem;
  }

  & .MuiTypography-root {
    font-size: 1.5rem !important;
  }

  & .MuiFormControlLabel-root {
    padding-bottom: 4px;
    font-size: 1.6rem;
  }
`;

const StyledFormInput = styled(FormInput)`
  margin-top: 2px;

  & .MuiInputLabel-formControl {
    font-size: 1.6rem;
  }
  & .MuiInputBase-root {
    padding-bottom: 8px !important;
  }
  & .MuiInputBase-input {
    font-size: 1.6rem;
  }
`;

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  *:first-of-type {
    margin-right: 5px !important;
  }

  *:last-of-type {
    margin-left: 5px !important;
  }
`;

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

const StyledLoader = styled(Loader)`
  font-size: 0.6rem;
  margin: 0 auto 0 auto;
  text-align: center;
  padding: ${styles.spacing.xs};
`;

const getValidationSchema = ({ intl, withMessage, withOptions }: IValidationSchemaProps) => {
  const schema = formSchema(intl);
  const { firstName, lastName, jobTitle, company, email } = schema;

  let shape = yup.object().shape({
    firstName: firstName.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.firstName),
      })
    ),
    lastName: lastName.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.lastName),
      })
    ),
    jobTitle: jobTitle.notRequired(),
    email: email.required(
      intl.formatMessage(formMessages.required, {
        label: intl.formatMessage(formMessages.email),
      })
    ),
    company,
  });

  if (withOptions) {
    shape = shape.concat(
      yup.object().shape({
        options: yup
          .array()
          .of(yup.string())
          .required(intl.formatMessage(messages.optionsRequired)),
      })
    );
  }

  if (withMessage) {
    shape = shape.concat(
      yup.object().shape({
        message: yup.string().required(intl.formatMessage(messages.messageRequired)),
      })
    );
  }

  return shape;
};

const ContactForm = ({
  loading,
  initialValues,
  onSubmit,
  onClose,
  options,
  withOptions,
  withMessage,
}: ISidebarPricingFormProps) => {
  const { classes } = useStyles();
  const intl = useIntl();

  if (loading) {
    return <StyledLoader />;
  }

  return (
    <Formik
      // See: https://github.com/jaredpalmer/formik/issues/811#issuecomment-505495937
      key={JSON.stringify(initialValues)}
      initialValues={initialValues || {}}
      validationSchema={getValidationSchema({ intl, withOptions, withMessage })}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount>
      {(formik: FormikProps<IContactFormValues>) => {
        return (
          <form onSubmit={formik.handleSubmit} data-cy='pricingSidebarForm'>
            <div css={classes.subheader}>
              <FormattedMessage id='pricingpage.sidebar.subheader' defaultMessage='Questions?' />
            </div>
            <h2 className='h4' css={classes.header}>
              <FormattedMessage id='pricingpage.sidebar.header' defaultMessage='Contact Us' />
            </h2>

            <FieldsWrapper>
              <StyledFormInput
                name='firstName'
                required
                label={intl.formatMessage(formMessages.firstName)}
                errorOnTouched
              />
              <StyledFormInput
                name='lastName'
                required
                label={intl.formatMessage(formMessages.lastName)}
                errorOnTouched
              />
              <StyledFormInput
                name='jobTitle'
                label={intl.formatMessage(formMessages.jobTitle)}
                errorOnTouched
              />
              <StyledFormInput
                name='company'
                label={intl.formatMessage(formMessages.company)}
                errorOnTouched
              />
              <StyledFormInput
                name='email'
                required
                label={intl.formatMessage(formMessages.email)}
                errorOnTouched
              />
              <FormControl margin='none' fullWidth>
                {withOptions && options && (
                  <StyledCheckboxArrayField
                    required={withOptions}
                    css={classes.checkboxLabel}
                    name='options'
                    margin='dense'
                    label={intl.formatMessage(messages.optionsLabel)}
                    options={options}
                    errorOnTouched
                  />
                )}
                {withMessage && (
                  <StyledFormInput
                    required={withMessage}
                    name='message'
                    label='Tell us more about your use case'
                    rows={5}
                    multiline
                    errorOnTouched
                  />
                )}
              </FormControl>
              <Buttons>
                <StyledButton
                  data-cy='submitBtn'
                  className='h-mt-xs'
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!formik.isValid || formik.isSubmitting}>
                  <FormattedMessage {...btnMessages.submit} />
                </StyledButton>
                <StyledButton
                  data-cy='cancelBtn'
                  className='h-mt-xs'
                  color='primary'
                  variant='outlined'
                  onClick={onClose}>
                  <FormattedMessage {...btnMessages.cancel} />
                </StyledButton>
              </Buttons>
            </FieldsWrapper>
          </form>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles({
  base: {
    subheader: {
      textAlign: 'left',
      fontSize: '1.6rem',
      color: styles.color.xLightGrey,
    },
    header: {
      textAlign: 'left',
      fontSize: '2.6rem',
      fontWeight: 'bold',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
    checkboxLabel: {
      '& .MuiFormLabel-root': {
        color: styles.color.black,
      },
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
    checkboxLabel: {
      '& .MuiFormLabel-root': {
        color: styles.color.white,
      },
    },
  },
});

export default ContactForm;
