import { Action } from 'redux';
import { AsyncAction } from 'state/types/actions';
import { SwitchAccountAction } from 'state/account/types';
import {
  RulesetsRes,
  RulesetRes,
  HuntRes,
  HuntsRes,
  HuntResultsRes,
  LiveHuntRes,
  LiveHuntsRes,
} from 'services/api/schema/hunting';
import { MetadataSearchResultsRes } from 'services/api/schema/submission';

export enum HuntingActionName {
  GET_RULESET = 'GET_RULESET',
  GET_ALL_RULESETS = 'GET_ALL_RULESETS',
  REFRESH_ALL_RULESETS = 'REFRESH_ALL_RULESETS',
  CREATE_RULESET = 'CREATE_RULESET',
  UPDATE_RULESET = 'UPDATE_RULESET',
  DELETE_RULESET = 'DELETE_RULESET',
  RUN_LIVE_HUNT = 'RUN_LIVE_HUNT',
  RUN_HISTORICAL_HUNT = 'RUN_HISTORICAL_HUNT',
  GET_ALL_LIVE_HUNTS = 'GET_ALL_LIVE_HUNTS',
  REFRESH_ALL_LIVE_HUNTS = 'REFRESH_ALL_LIVE_HUNTS',
  GET_LIVE_HUNT_RESULTS = 'GET_LIVE_HUNT_RESULTS',
  CANCEL_LIVE_HUNT = 'CANCEL_LIVE_HUNT',
  CANCEL_HISTORICAL_HUNT = 'CANCEL_HISTORICAL_HUNT',
  DELETE_HUNT = 'DELETE_HUNT',
  INFO_MODAL = 'INFO_MODAL',
  HISTORICAL_INFO_MODAL = 'HISTORICAL_INFO_MODAL',
  FILTER_MODAL = 'FILTER_MODAL',
  GET_ALL_HISTORICAL_HUNTS = 'GET_ALL_HISTORICAL_HUNTS',
  REFRESH_ALL_HISTORICAL_HUNTS = 'REFRESH_ALL_HISTORICAL_HUNTS',
  GET_HISTORICAL_HUNT_RESULTS = 'GET_HISTORICAL_HUNT_RESULTS',
  SET_METADATA_SEARCH_TERM = 'SET_METADATA_SEARCH_TERM',
  GET_METADATA_SEARCH_RESULTS = 'GET_METADATA_SEARCH_RESULTS',
  GET_MORE_METADATA_SEARCH_RESULTS = 'GET_MORE_METADATA_SEARCH_RESULTS',
  RESET_METADATA_SEARCH = 'RESET_METADATA_SEARCH',
}

export interface GetRulesetAction extends AsyncAction<HuntingActionName.GET_RULESET, RulesetsRes> {}

export interface GetAllRulesetsAction
  extends AsyncAction<HuntingActionName.GET_ALL_RULESETS, RulesetsRes> {}

export interface RefreshAllRulesetsAction
  extends AsyncAction<HuntingActionName.REFRESH_ALL_RULESETS, RulesetsRes> {}

export interface CreateRuelsetAction
  extends AsyncAction<HuntingActionName.CREATE_RULESET, RulesetRes> {}

export interface UpdateRuelsetAction
  extends AsyncAction<HuntingActionName.UPDATE_RULESET, RulesetRes> {}

export interface DeleteRuelsetAction
  extends AsyncAction<HuntingActionName.DELETE_RULESET, RulesetRes> {}

export interface RunLiveHuntAction
  extends AsyncAction<HuntingActionName.RUN_LIVE_HUNT, LiveHuntRes> {}

export interface GetAllLiveHuntsAction
  extends AsyncAction<HuntingActionName.GET_ALL_LIVE_HUNTS, LiveHuntsRes> {}

export interface RefreshAllLiveHuntsAction
  extends AsyncAction<HuntingActionName.REFRESH_ALL_LIVE_HUNTS, LiveHuntsRes> {}

export interface GetLiveHuntResultsAction
  extends AsyncAction<HuntingActionName.GET_LIVE_HUNT_RESULTS, HuntResultsRes> {}

export interface CancelLiveHuntAction
  extends AsyncAction<HuntingActionName.CANCEL_LIVE_HUNT, LiveHuntRes> {}

export interface CancelHistoricalHuntAction
  extends AsyncAction<HuntingActionName.CANCEL_HISTORICAL_HUNT, HuntResultsRes> {}

export interface DeleteHuntAction extends AsyncAction<HuntingActionName.DELETE_HUNT, {}> {}

export interface InfoAction extends AsyncAction<HuntingActionName.INFO_MODAL, {}> {}

export interface HistoricalInfoAction
  extends AsyncAction<HuntingActionName.HISTORICAL_INFO_MODAL, {}> {}

export interface FilterAction extends AsyncAction<HuntingActionName.FILTER_MODAL, {}> {}

export interface RunHistoricalHuntAction
  extends AsyncAction<HuntingActionName.RUN_HISTORICAL_HUNT, HuntRes> {}

export interface GetAllHistoricalHuntsAction
  extends AsyncAction<HuntingActionName.GET_ALL_HISTORICAL_HUNTS, HuntsRes> {}

export interface RefreshAllHistoricalHuntsAction
  extends AsyncAction<HuntingActionName.REFRESH_ALL_HISTORICAL_HUNTS, HuntsRes> {}

export interface GetHistoricalHuntResultsAction
  extends AsyncAction<HuntingActionName.GET_HISTORICAL_HUNT_RESULTS, HuntsRes> {}

export interface SetMetadataSearchTermAction
  extends Action<HuntingActionName.SET_METADATA_SEARCH_TERM> {
  term: string;
}

export interface GetMetadataSearchResultsAction
  extends AsyncAction<HuntingActionName.GET_METADATA_SEARCH_RESULTS, MetadataSearchResultsRes> {}

export interface GetMoreMetadataSearchResultsAction
  extends AsyncAction<
    HuntingActionName.GET_MORE_METADATA_SEARCH_RESULTS,
    MetadataSearchResultsRes
  > {}

export interface ResetMetadataSearchAction
  extends Action<HuntingActionName.RESET_METADATA_SEARCH> {}

export type HuntingAction =
  | GetRulesetAction
  | GetAllRulesetsAction
  | RefreshAllRulesetsAction
  | CreateRuelsetAction
  | UpdateRuelsetAction
  | DeleteRuelsetAction
  | RunLiveHuntAction
  | GetAllLiveHuntsAction
  | RefreshAllLiveHuntsAction
  | GetLiveHuntResultsAction
  | CancelLiveHuntAction
  | CancelHistoricalHuntAction
  | DeleteHuntAction
  | InfoAction
  | HistoricalInfoAction
  | FilterAction
  | RunHistoricalHuntAction
  | GetAllHistoricalHuntsAction
  | RefreshAllHistoricalHuntsAction
  | GetHistoricalHuntResultsAction
  | SetMetadataSearchTermAction
  | GetMetadataSearchResultsAction
  | GetMoreMetadataSearchResultsAction
  | ResetMetadataSearchAction
  | SwitchAccountAction;
