import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-question' viewBox='0 0 23 32'>
      <path d='M8.326 31.48v-5.298h5.296v5.298z' />
      <path d='M8.266 22.912c0-1.803 0.212-3.275 0.636-4.415s1.18-2.174 2.267-3.102c1.047-0.875 1.823-1.661 2.327-2.357s0.756-1.448 0.756-2.257c0-2.068-0.955-3.102-2.864-3.102-0.835 0-1.538 0.272-2.108 0.815s-0.868 1.366-0.895 2.466h-6.224c0.026-1.869 0.441-3.424 1.243-4.663s1.889-2.158 3.261-2.754c1.372-0.597 2.946-0.895 4.723-0.895 1.896 0 3.52 0.302 4.872 0.905s2.39 1.491 3.112 2.665c0.723 1.173 1.084 2.602 1.084 4.286 0 1.021-0.186 1.939-0.557 2.754s-0.845 1.541-1.422 2.178c-0.577 0.636-1.395 1.438-2.456 2.406-0.080 0.093-0.212 0.219-0.398 0.378-0.729 0.65-1.236 1.326-1.521 2.028s-0.441 1.591-0.467 2.665h-5.369z' />
    </symbol>

    <Global
      styles={css`
        .icon-question {
          width: 2.3em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
