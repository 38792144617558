import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import numeral from 'numeral';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface IAccountLevelProgress {
  percentage: number;
}

export const AccountLevelProgress = ({ percentage }: IAccountLevelProgress) => {
  const { classes } = useStyles();
  return (
    <div css={classes.root}>
      <div style={{ width: numeral(percentage).format('0%') }} css={classes.bar} />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      position: 'relative',
      width: '100%',
      height: '1.2rem',
      backgroundColor: styles.color.xxLightGrey,
      borderRadius: styles.border.radiusXl,
      overflow: 'hidden',
    },
    bar: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: styles.color.green,
      height: '100%',
    },
  },
});
