import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { SmallCopyButton } from 'views/components/CopyText/CopyTextButton';
import styles from 'views/styles';

interface ICode {
  children: string;
}

export const Code = ({ children }: ICode) => {
  const { classes } = useStyles();
  return (
    <pre>
      <code css={classes.code}>{children}</code>
    </pre>
  );
};

export const CopyCode = ({ children }: ICode) => {
  const { classes } = useStyles();
  return (
    <div css={classes.container}>
      <Code>{children}</Code>
      <SmallCopyButton text={children} />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    code: {
      fontFamily: 'monospace',
      fontSize: '1.3rem',
      margin: '0.5rem 0',
      padding: '0.5rem 1rem',
      borderRadius: '0.5rem',
    },
  },
  light: {
    code: {
      color: styles.color.purple,
    },
  },
  dark: {
    code: {
      color: styles.color.blue,
    },
  },
});
