import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys } from 'utils/error';
import { MicroenginesActionName } from 'state/microengines/types';
import { archiveUserMicroengine } from 'state/microengines/actions';
import { PageTimeQuery } from 'models/TimeSeries';
import { MicroengineListing } from 'models/Microengine';
import { closeModal } from 'state/modal/actions';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import ConfirmForm from 'views/components/form/ConfirmForm';
import Modal from 'views/components/Modal';

export interface ArchiveMicroengineModalParams {
  id: number;
  displayName: string;
  accountNumber: number;
  queryParams?: PageTimeQuery<MicroengineListing>;
}

const messages = defineMessages({
  name: {
    id: 'microengines.archive.name',
    defaultMessage: 'Engine name',
  },
  heading: {
    id: 'microengines.archive.heading',
    defaultMessage: 'Are you sure want to delete "{displayName}"?',
  },
  text: {
    id: 'microengines.archive.text',
    defaultMessage:
      'This action cannot be undone. All your data related to this engine will be lost. Please type in the engine name to confirm your action.',
  },
});

const errorKeys: ErrorKeys = {};

const ArchiveMicroengineModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [MicroenginesActionName.ARCHIVE_USER_MICROENGINE]);
  const error = errorSelector(requests, [MicroenginesActionName.ARCHIVE_USER_MICROENGINE]);

  const { id, displayName, accountNumber, queryParams } = useSelector(
    ({ modal }: { modal: ModalState<ArchiveMicroengineModalParams> }) => modal.params
  );

  return (
    <Modal>
      <ConfirmForm
        testId='archiveMicroengineForm'
        css={ownStyle}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        name={displayName}
        label={intl.formatMessage(messages.name)}
        heading={intl.formatMessage(messages.heading, { displayName })}
        text={intl.formatMessage(messages.text)}
        buttonText={intl.formatMessage(btnMessages.delete)}
        buttonColor='secondary'
        onSubmit={() => dispatch(archiveUserMicroengine(id, accountNumber, queryParams))}
        onCancel={() => dispatch(closeModal())}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default ArchiveMicroengineModal;
