import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import Modal from 'views/components/Modal';
import useFormBuilder from 'components/FormBuilder';
import Button from '@material-ui/core/Button/Button';

export type Fields = {
  team: string;
  team_account_number: string;
  is_private: boolean;
};

type ModalProps = {
  fields: Fields;
  email: string;
  onSubmit: (values: Fields) => void;
};

const EditApiKeyTagsModal = () => {
  const { classes } = useStyles();
  const _closeModal = () => dispatch(closeModal());
  const dispatch = useDispatch<Dispatch>();
  const { fields, onSubmit, email } = useSelector(
    ({ modal }: { modal: ModalState<ModalProps> }) => modal.params
  );

  const { FormComponent } = useFormBuilder([
    {
      elementType: 'selectTag',
      id: 'tags',
      name: 'Tags',
      placeholder: 'Select existing tags',
      defaultOptions: ['Tag 1', 'Tag 2', 'Tag 3'],
      bold: false,
      hideName: true,
    },
    {
      elementType: 'inputTag',
      hideName: true,
      name: 'Custom Tags (Optional)',
      id: 'custom_tags',
      placeholder: 'Add custom tags',
    },
  ]);

  return (
    <Modal fullWidth={false} maxWidth='lg'>
      <div css={classes.container}>
        <h2 css={classes.title}>Edit tags for {email}</h2>
        <FormComponent
          customClass={classes.form}
          filters={fields}
          onSubmit={(values) => {
            onSubmit(values);
            _closeModal();
          }}
          footerRender={() => {
            return (
              <div css={classes.buttonsContainer}>
                <Button
                  onClick={() => {
                    _closeModal();
                  }}
                  variant='outlined'
                  color='primary'
                  css={classes.button}
                >
                  Cancel
                </Button>
                <Button type='submit' variant='contained' color='primary' css={classes.button}>
                  Save
                </Button>
              </div>
            );
          }}
        />
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: 'max-content',
      padding: '4rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.8rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      width: '65rem',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '1rem',
    },
    button: {},
  },
  light: {},
  dark: {},
});

export default EditApiKeyTagsModal;
