import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-info' viewBox='0 0 32 32'>
      <path d='M14.397 14.376h3.195v9.624h-3.195v-9.624zM17.592 7.945l0.011 3.252h-3.206l0.011-3.252h3.184z' />
      <path d='M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16 0-4.243-1.686-8.313-4.686-11.314s-7.070-4.686-11.314-4.686zM16 2.24c3.649 0 7.149 1.45 9.73 4.030s4.030 6.080 4.030 9.73c0 7.599-6.161 13.76-13.76 13.76s-13.76-6.161-13.76-13.76c0-7.599 6.161-13.76 13.76-13.76z' />
    </symbol>

    <Global
      styles={css`
        .icon-info {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
