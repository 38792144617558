import React, { useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import Button from '@material-ui/core/Button';
import { useIntl, defineMessages } from 'react-intl';
import styled from '@emotion/styled';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { ConfirmCheckbox } from './ConfirmCheckbox';
import PanelContent from 'views/components/layout/PanelContent';
import tokenHand from 'assets/images/token-hand.svg';
import styles from 'views/styles';

const messages = defineMessages({
  heading: {
    id: 'rewardsTab.welcome.heading',
    defaultMessage: 'Earn Nectar (NCT tokens) browsing the internet',
  },
  howTo: {
    id: 'rewardsTab.welcome.howTo',
    defaultMessage: 'How to get started',
  },
  joinButton: {
    id: 'rewardsTab.welcome.joinButton',
    defaultMessage: 'Join NectarNet',
  },
});

export interface WelcomePros {
  telemetryIngest: boolean;
  onAgree: () => void;
}

const Bold = styled.span`
  font-weight: bold;
  font-size: 2rem;
`;

export const Welcome: React.FC<WelcomePros> = ({ telemetryIngest, onAgree }) => {
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const intl = useIntl();
  const { classes } = useStyles();

  let customButtons;
  let extraContent;
  if (telemetryIngest) {
    customButtons = [
      <Button
        disabled={!agreementAccepted}
        component='button'
        color='primary'
        variant='contained'
        onClick={onAgree}>
        {intl.formatMessage(messages.joinButton)}
      </Button>,
    ];
    extraContent = <ConfirmCheckbox value={agreementAccepted} onChange={setAgreementAccepted} />;
  }

  const pStyle = { paddingBottom: '1.2rem' }; // TODO use CSS instead of inline text
  const body = (
    <div style={{ maxWidth: '70rem', margin: '1.8rem auto' }}>
      <p className='p' style={pStyle}>
        <Bold>Get NectarNet</Bold>
      </p>
      <p className='p' style={pStyle}>
        Everyday internet users with the NectarNet browser extension are rewarded with PolySwarm NCT
        tokens for their data.
      </p>
      <p className='p' style={pStyle}>
        <a
          css={classes.link}
          href='https://docs.polyswarm.io/consumers/rewards#general'
          target='_blank'
          rel='noopener noreferrer'>
          {intl.formatMessage(messages.howTo)}
        </a>
      </p>
    </div>
  );

  return (
    <div>
      <PanelContent
        image={{
          src: tokenHand,
          alt: 'NectarNet',
        }}
        heading={intl.formatMessage(messages.heading)}
        body={body}
        extraContent={extraContent}
        customButtons={customButtons}
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    link: {
      fontSize: '1.8rem',
    },
  },
  light: {
    link: {
      color: styles.color.purple,
    },
  },
  dark: {
    link: {
      color: styles.color.blue,
    },
  },
});
