import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { PageQuery } from 'models/Page';
import { Ruleset } from 'models/Ruleset';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys, errorMessages } from 'utils/error';
import { closeModal } from 'state/modal/actions';
import { cancelLiveHunt } from 'state/hunting/actions';
import { HuntingActionName } from 'state/hunting/types';
import { btnMessages } from 'views/components/Button';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

export interface CancelLiveHuntModalParams {
  id: string;
  params?: PageQuery<Ruleset>;
}

const messages = defineMessages({
  heading: {
    id: 'rulesets.modal.cancelLiveHuntHeading',
    defaultMessage: 'Stop Live Hunt',
  },
  text: {
    id: 'rulesets.modal.cancelLiveHuntText',
    defaultMessage: 'Are you sure you want to stop this live hunt?',
  },
});

const errorKeys: ErrorKeys = {
  rate_limit_exceeded: errorMessages.rateLimit.id,
};

const CancelLiveHuntModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [HuntingActionName.CANCEL_LIVE_HUNT]);
  const error = errorSelector(requests, [HuntingActionName.CANCEL_LIVE_HUNT]);

  const { id, params } = useSelector(
    ({ modal }: { modal: ModalState<CancelLiveHuntModalParams> }) => modal.params
  );

  return (
    <ConfirmModal
      testId='cancelLiveHuntModal'
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text)}
      submitBtnText={intl.formatMessage(btnMessages.stopHunt)}
      onSubmit={() => dispatch(cancelLiveHunt(id, params))}
      onCancel={() => dispatch(closeModal())}
    />
  );
};

export default CancelLiveHuntModal;
