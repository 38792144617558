import {
  AccountRes,
  AccountContextRes,
  AccountFeatureRes,
  PaymentMethodRes,
  SubscriptionTransactionRes,
} from 'services/api/schema/account';

export interface AccountFeature extends AccountFeatureRes {}

export interface Account extends AccountRes {}

export interface AccountContext extends AccountContextRes {}

export interface PaymentMethod extends PaymentMethodRes {}

export interface SubscriptionTransaction extends SubscriptionTransactionRes {}

export enum EAccountFeatures {
  'apiRequestLimit',
  'monthlyApiLimit',
  'scans',
  'hashSearches',
  'metadataSearches',
  'downloads',
  'sandboxing',
  'sandboxRequest',
  'sandboxSearch',
  'hunting',
  'historicalHunts',
  'liveHunts',
  'huntingYaraRules',
  'price',
  'hasStream',
  'hasStreamAccess',
  'taxii',
  'additionalFeatures',
  'hasSupport',
  'privateComm',
  'createTeams',
  'integrationsOk',
}

/**
 * An enumerated list of account plan names where the key is camelCase.
 *
 * NOTE: If a plan name changes, this needs to be updated.
 */
export enum EAccountPlans {
  'anonymous' = 'Anonymous',
  'community' = 'Community',
  'basic' = 'Basic',
  'enterprise' = 'Enterprise',
  'enterprisePremium' = 'Enterprise Premium',
}

export type AccountFeatureSlug = keyof typeof EAccountFeatures;

export type AccountFeatureValue = {
  value: number | boolean | string;
  units?: string;
};

export type AccountFeatures = { [T in AccountFeatureSlug]: AccountFeatureValue };

export interface AccountPlan {
  name: string;
  isPrivate: boolean;
  features: AccountFeatures;
  payment_plan_id: string;
  interval: string;
  startDate?: string | null;
  endDate?: string | null;
  isCurrent?: boolean;
  isPending?: boolean;
}

export interface AccountUsage {
  feature: AccountFeatureSlug;
  tag?: string;
  baseQuota: number;
  numUses: number;
  overage: number;
  remainingUses: number | string;
  totalAvail: number | string;
  value: boolean;
}

export interface SignupValues {
  userId?: string;
  teamId?: string;
  firstName: string;
  lastName: string;
  plan: string;
  email: string;
  phone?: string;
  company?: string;
  contactMethod?: string;
}

/**
 * An enumerated list of account feature tags.
 *
 * NOTE: Access control is checked with this, see ability.ts
 */
export enum EAccountFeatureTag {
  'daily_api_limit',
  'historical_hunt',
  'is_private',
  'max_artifact_size',
  'scan',
  'hash_search',
  'metadata_search',
  'download',
  'has_notifications',
  'live_hunt',
  'tags_view',
  'private_communities',
  'public_communities',
  'private_comm',
  'public_comm',
  'price',
  'hunting_yara_rules',
  'tags_user',
  'metadata_rerun',
  'all_scans',
  'tags_admin',
  'create_teams',
  'has_stream_access',
  'has_support',
  'barracuda',
  'sandbox_request',
  'sandbox_search',
  'ioc_user',
}

export type AccountFeatureTag = keyof typeof EAccountFeatureTag;

export enum EPaymentInterval {
  month = 'month',
  year = 'year',
}

export type PaymentInterval = keyof typeof EPaymentInterval;

export interface IPaymentPlans {
  currentPlan: AccountPlan;
  pendingPlan?: AccountPlan;
}

export interface IAccountSignupValues {
  username?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  company?: string;
  options?: string[];
}
