import { sortBy, concat, filter } from 'lodash';
import { AccountPlan, EAccountPlans, Account, IPaymentPlans } from 'models/Account';
import { AuthAction, AuthActionName } from 'state/auth/types';
import { AccountAction, AccountActionName } from './types';
import { AccountState } from '.';
import { AccountPlansRes, AccountPlanRes } from 'services/api/schema/account';
import { CUSTOM_PLANS } from 'views/pages/PricingPage/PricingPanel/PricingComparePlans/plans';

export const initialState: AccountState = {
  plans: [],
  context: null,
  paymentMethods: [],
  paymentPlans: null,
  subscriptionTransactions: null,
};

const account = (
  state: AccountState = initialState,
  action: AccountAction | AuthAction
): AccountState => {
  switch (action.type) {
    case AccountActionName.GET_ACCOUNT_PLANS: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        plans: concat(transformPlanData(action.result), CUSTOM_PLANS),
      };
    }

    case AccountActionName.SWITCH_ACCOUNT:
    case AccountActionName.GET_ACCOUNT_CONTEXT: {
      if (!action.result) {
        return state;
      }
      let isPrivate;
      if (action.result.context === 'user') {
        isPrivate = false;
      } else if (action.result.isPrivate !== null && action.result.isPrivate !== undefined) {
        isPrivate = action.result.isPrivate;
      } else {
        isPrivate = state.context?.isPrivate === true;
      }
      return {
        ...state,
        plans: [],
        context: {
          ...action.result,
          isPrivate,
        },
      };
    }

    case AccountActionName.GET_PAYMENT_METHODS: {
      if (!action.result) {
        return state;
      }

      return {
        ...state,
        paymentMethods: action.result,
      };
    }

    case AccountActionName.ATTACH_PAYMENT_METHOD: {
      if (!action.result) {
        return state;
      }

      return {
        ...state,
        paymentMethods: [...state.paymentMethods, action.result],
      };
    }

    case AccountActionName.DETACH_PAYMENT_METHOD: {
      if (!action.id) {
        return state;
      }

      return {
        ...state,
        paymentMethods: filter(state.paymentMethods, (i) => i.id !== action.id),
      };
    }

    case AccountActionName.SET_DEFAULT_PAYMENT_METHOD: {
      if (!action.id) {
        return state;
      }

      return {
        ...state,
        paymentMethods: state.paymentMethods.map((item) => ({
          ...item,
          isDefault: item.id === action.id,
        })),
      };
    }

    case AccountActionName.GET_SUBSCRIPTION_TRANSACTIONS: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        subscriptionTransactions: action.result,
      };
    }

    case AccountActionName.GET_PAYMENT_PLANS: {
      return {
        ...state,
        paymentPlans: action.paymentPlans,
      };
    }

    case AuthActionName.LOGOUT: {
      return {
        ...initialState,
        context: {
          ...state.context!,
        },
      };
    }

    default: {
      return state;
    }
  }
};

const isEnterprisePlan = (plan: string) => {
  return [EAccountPlans.enterprise, EAccountPlans.enterprisePremium].includes(
    plan as EAccountPlans
  );
};

export const transformPlanData = (plans: AccountPlansRes): AccountPlan[] => {
  if (!plans) {
    return [];
  }

  const getFeatureValueByTag = (plan: AccountPlanRes, tag: string) => {
    const feature = plan.features.find((item) => item.tag === tag);

    if (feature) {
      return feature.value;
    }

    return false;
  };

  return sortBy(
    plans.map((plan) => ({
      name: plan.name,
      isPrivate: plan.isPrivate,
      features: {
        apiRequestLimit: { value: plan.dailyApiLimit, units: 'day' },
        scans: { value: plan.scansPerMonth, units: 'month' },
        downloads: { value: plan.downloadsPerMonth, units: 'month' },
        hashSearches: { value: plan.hashSearchPerMonth, units: 'month' },
        metadataSearches: { value: plan.metadataSearchPerMonth, units: 'month' },
        monthlyApiLimit: { value: plan.monthlyApiLimit!, units: 'month' },
        liveHunts: {
          value: plan.name === EAccountPlans.community ? false : plan.liveHuntPerMonth,
          units: 'active',
        },
        historicalHunts: {
          value: plan.name === EAccountPlans.community ? false : plan.historicalHuntPerMonth,
          units: 'month',
        },
        huntingYaraRules: {
          value: getFeatureValueByTag(plan, 'hunting_yara_rules') ? -1 : false,
        },
        sandboxing: {
          value: isEnterprisePlan(plan.name),
        },
        sandboxRequest: {
          value: isEnterprisePlan(plan.name),
        },
        sandboxSearch: {
          value: isEnterprisePlan(plan.name),
        },
        hunting: { value: false },
        hasStream: { value: false },
        hasStreamAccess: {
          value: getFeatureValueByTag(plan, 'has_stream_access'),
        },
        taxii: { value: getFeatureValueByTag(plan, 'taxii') },
        additionalFeatures: { value: false },
        hasSupport: { value: getFeatureValueByTag(plan, 'has_support') },
        privateComm: { value: getFeatureValueByTag(plan, 'private_comm') },
        createTeams: { value: getFeatureValueByTag(plan, 'create_teams') },
        integrationsOk: {
          value:
            [EAccountPlans.enterprise, EAccountPlans.enterprisePremium].includes(
              plan.name as any
            ) || 'feature.limited',
        },
        price: { value: plan.price, units: 'usd' },
      },
      payment_plan_id: plan.payment_plan_id,
      interval: plan.interval,
    })),
    [(plan) => plan.features.scans.value]
  );
};

export const transformPaymentPlans = (account: Account, plans: AccountPlan[]): IPaymentPlans => {
  // Current Plan
  let currentPlan;
  if (account.paymentPlan) {
    const currentPlanData = plans.find((plan) => plan.name === account.paymentPlan!.name)!;
    currentPlan = {
      ...currentPlanData,
      isCurrent: true,
      startDate: account.paymentPlan.startDate,
      endDate: account.paymentPlan.endDate,
    };
  } else {
    const currentPlanData = plans.find((plan) => plan.name === account.plan.name)!;
    currentPlan = {
      ...currentPlanData,
      isCurrent: true,
      startDate: account.planPeriodStart,
      endDate: account.planPeriodEnd,
    };
  }

  // Pending Plan
  let pendingPlan;
  if (account.pendingPaymentPlan) {
    const pendingPlanData = plans.find((plan) => plan.name === account.pendingPaymentPlan!.name)!;
    pendingPlan = {
      ...pendingPlanData,
      isPending: true,
      startDate: account.pendingPaymentPlan.startDate,
      endDate: account.pendingPaymentPlan.endDate,
    };
  }

  return { currentPlan, pendingPlan };
};

export default account;
