import styled from '@emotion/styled';

interface Props {
  apiUrl: string;
}

const AvatarBackground = styled('div')<Props>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 60%;
  background-image: ${({ apiUrl }) => `url("${apiUrl}")`};
  background-size: 115%;
  background-position-x: 50%;
  background-position-y: 100%;
  background-repeat: no-repeat;

  a {
    position: absolute;
    top: 0;
  }
`;

export default AvatarBackground;
