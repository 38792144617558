import React, { useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { closeModal } from 'state/modal/actions';
import styles from 'views/styles';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { useWithdrawalFee } from '../hooks/useWithdrawalFee';
import { toNCT, toWEI } from 'utils/nct';
import Big from 'big.js';

export interface RedeemTransaction {
  mfa?: boolean;
  amount: string | number;
  fee: string | number;
  total: string | number;
  feeTimestamp?: string;
  feeSign?: string;
}

export interface RedeemRewardsParams {
  address: string;
  balance: string;
  limit: string;
  withdrawalsLimitMin: number;
  onSubmit: ({ amount, fee, total, feeTimestamp, feeSign }: RedeemTransaction) => void;
}

const messages = defineMessages({
  heading: {
    id: 'rewardsTab.redeemRewards.modal.heading',
    defaultMessage: 'Redeem NCT Rewards',
  },
  withdrawTo: {
    id: 'rewardsTab.redeemRewards.modal.withdrawTo',
    defaultMessage: 'Withdraw to',
  },
  redeemOf: {
    id: 'rewardsTab.redeemRewards.modal.reedeemOf',
    defaultMessage: 'Redeem Balance of',
  },
  placeholder: {
    id: 'rewardsTab.redeemRewards.modal.placeholder',
    defaultMessage: 'Amount to redeem',
  },
  errorHigherThanBalance: {
    id: 'rewardsTab.redeemRewards.modal.errorHigherThanBalance',
    defaultMessage: 'Amount to redeem cannot be higher than the current balance',
  },
  errorHigherThanLimit: {
    id: 'rewardsTab.redeemRewards.modal.errorHigherThanLimit',
    defaultMessage: 'Amount to redeem cannot be higher than the current wallet limit',
  },
  errorLessThanZero: {
    id: 'rewardsTab.redeemRewards.modal.errorLessThanZero',
    defaultMessage: 'Amount is less than zero',
  },
  errorLessThanMinLimit: {
    id: 'rewardsTab.redeemRewards.modal.errorLessThanMinLimit',
    defaultMessage: 'Amount is less than minimum allowed ({withdrawalsLimitMin})',
  },
  errorTooLow: {
    id: 'rewardsTab.redeemRewards.modal.errorTooLow',
    defaultMessage: 'Amount to redeem too low, less than estimated fee ({fee} NCT)',
  },
  button: {
    id: 'rewardsTab.redeemRewards.modal.button',
    defaultMessage: 'Preview',
  },
  feeError: {
    id: 'rewardsTab.redeemRewards.modal.fee.error',
    defaultMessage: 'Unable to estimate fee',
  },
});

const RedeemRewards = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState<string>('');

  const {
    balance: currentBalance,
    withdrawalsLimitMin,
    limit,
    address,
    onSubmit,
  } = useSelector(({ modal }: { modal: ModalState<RedeemRewardsParams> }) => modal.params);
  const weiAmount = toWEI(amount || 0);
  const {
    fee: withdrawalFeeStr,
    timestamp: feeTimestamp,
    sign: feeSign,
    error: feeError,
  } = useWithdrawalFee(weiAmount.toFixed());
  const withdrawalFee = toNCT(withdrawalFeeStr || 0);
  let amountToWithdraw = Big(0);
  let error = false;
  let disabled = true;
  const balance = Big(currentBalance);
  let errorMessage;
  if (amount !== '') {
    amountToWithdraw = Big(amount).minus(withdrawalFee);
    const errorAmountExceedBalance = Big(amount).gt(balance);
    const errorToLow = Big(amount).gt(0) && amountToWithdraw.lte(0);
    const errorExceedLimit = Big(amount).gt(limit);
    const errorLessThanZero = Big(amount).lt(0);
    const errorLessThanMinLimit = Big(amount).lt(withdrawalsLimitMin);
    const errors = [
      { error: errorAmountExceedBalance, message: messages.errorHigherThanBalance },
      { error: errorToLow, message: messages.errorTooLow },
      { error: errorExceedLimit, message: messages.errorHigherThanLimit },
      { error: errorLessThanZero, message: messages.errorLessThanZero },
      { error: errorLessThanMinLimit, message: messages.errorLessThanMinLimit },
    ];
    error = errors.map(({ error }) => error).some(Boolean);
    errorMessage = errors.find(({ error }) => error)?.message;
    disabled = balance.eq(0) || Big(amount).eq(0) || error;
  }
  if (feeError) {
    errorMessage = messages.feeError;
  }
  return (
    <ConfirmModal
      testId='redeemRewardsModal'
      isLoading={false}
      css={ownStyle}
      fullWidth={true}
      content={
        <div css={containerStyle}>
          <label>
            <Checkbox
              id='check-current-balance'
              color='primary'
              value={false}
              checked={amount !== '' && Big(amount).eq(currentBalance)}
              onChange={(e) => {
                if (e.target.checked) {
                  setAmount(currentBalance);
                } else {
                  setAmount('');
                }
              }}
            />
            {intl.formatMessage(messages.redeemOf)} {currentBalance}
          </label>
          <TextField
            type='number'
            className='field'
            onChange={(e) => setAmount(e.target.value)}
            fullWidth={true}
            value={amount}
            error={error || feeError}
            helperText={
              (error || feeError) && errorMessage
                ? intl.formatMessage(errorMessage, {
                    fee: withdrawalFee.toFixed(),
                    withdrawalsLimitMin,
                  })
                : ''
            }
            label={intl.formatMessage(messages.placeholder)}
          />
          <span className='withdraw-to'>
            {intl.formatMessage(messages.withdrawTo)} {address}
          </span>
        </div>
      }
      heading={intl.formatMessage(messages.heading)}
      submitBtnText={intl.formatMessage(messages.button)}
      disabled={disabled}
      buttonsDirection='vertical'
      onSubmit={() => {
        onSubmit({
          amount: amountToWithdraw.toFixed(),
          fee: withdrawalFee.toFixed(),
          total: amount,
          feeTimestamp: feeTimestamp,
          feeSign: feeSign,
        });
      }}
      onCancel={() => dispatch(closeModal())}
    />
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

const containerStyle = css`
  text-align: left;
  padding-top: 1rem;

  & label {
    cursor: pointer;
  }

  & .MuiCheckbox-root {
    margin-left: -10px !important;
  }

  & .field {
    margin-top: 1rem;
  }

  & .withdraw-to {
    font-size: 1.1rem;
    color: ${styles.color.xLightGrey};
  }
`;

export default RedeemRewards;
