import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-help' viewBox='0 0 32 32'>
      <path d='M16 2.667c-7.364 0-13.333 5.97-13.333 13.333s5.97 13.333 13.333 13.333c7.364 0 13.333-5.97 13.333-13.333 0-3.536-1.405-6.928-3.905-9.428s-5.892-3.905-9.428-3.905zM16 26.667c-5.891 0-10.667-4.776-10.667-10.667s4.776-10.667 10.667-10.667c5.891 0 10.667 4.776 10.667 10.667 0 2.829-1.124 5.542-3.124 7.542s-4.713 3.124-7.542 3.124zM16.253 9.333h-0.253c-2.209 0-4 1.791-4 4 0 0.334 0.435 1.126 1.333 1.126s1.333-0.716 1.333-1.126c0-0.736 0.597-1.333 1.333-1.333h0.253c0.302 0.003 0.589 0.131 0.794 0.353s0.308 0.519 0.286 0.821c0.002 0.412-0.231 0.79-0.6 0.973-1.275 0.625-2.079 1.927-2.067 3.347 0-0.107 0 0.2 0 0.92 0 0.253 0.388 1.048 1.333 1.048s1.333-0.795 1.333-1.048c0-0.237 0-0.544 0-0.92-0.002-0.412 0.231-0.79 0.6-0.973 1.559-0.793 2.375-2.548 1.977-4.252s-1.908-2.915-3.657-2.935zM16 20.409c-0.847 0-1.533 0.686-1.533 1.533s0.687 1.533 1.533 1.533c0.847 0 1.533-0.686 1.533-1.533 0.004-0.408-0.157-0.8-0.445-1.088s-0.68-0.449-1.088-0.445z' />
    </symbol>

    <Global
      styles={css`
        .icon-help {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
