import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { useUser } from 'views/components/providers/UserProvider';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys } from 'utils/error';
import { UserActionName } from 'state/user/types';
import { archiveUser } from 'state/user/actions';
import { closeModal } from 'state/modal/actions';
import { formMessages } from 'utils/schema/formSchema';
import styles from 'views/styles';
import ConfirmForm from 'views/components/form/ConfirmForm';
import Modal from 'views/components/Modal';

const messages = defineMessages({
  heading: {
    id: 'account.advanced.archive.modal.heading',
    defaultMessage: 'To permanently delete your account, enter your email address below.',
  },
  text: {
    id: 'account.advanced.archive.modal.text',
    defaultMessage:
      'Keep in mind that you will not be able to reactivate your account or retrieve any of the content or information you have in your account.',
  },
  button: {
    id: 'account.advanced.archive.modal.button',
    defaultMessage: 'Delete account',
  },
});

const errorKeys: ErrorKeys = {};

const ArchiveUserModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useUser();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [UserActionName.ARCHIVE_USER]);
  const error = errorSelector(requests, [UserActionName.ARCHIVE_USER]);

  return (
    <Modal>
      <ConfirmForm
        testId='archiveUserModal'
        css={ownStyle}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        name={user.email}
        label={intl.formatMessage(formMessages.email)}
        heading={intl.formatMessage(messages.heading)}
        text={intl.formatMessage(messages.text)}
        buttonText={intl.formatMessage(messages.button)}
        buttonColor='secondary'
        onSubmit={() => dispatch(archiveUser())}
        onCancel={() => dispatch(closeModal())}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default ArchiveUserModal;
