import React, { useMemo, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageQuery } from 'models/Page';
import { RootState } from 'state/root';
import { SUPPLIER_DOCS_URL } from 'utils/constants';
import { MicroengineListing, MicroengineValues } from 'models/Microengine';
import {
  getUserMicroengines as getUserMicroenginesAction,
  selectMicroengine,
} from 'state/microengines/actions';
import { extractPageQueryParams } from 'utils/pagination';
import { MicroenginesActionName } from 'state/microengines/types';
import { openModal } from 'state/modal/actions';
import { IColumnSort, ESortDirection } from 'views/components/table/InfiniteScrollTable';
import { ArchiveMicroengineModalParams } from 'views/pages/MicroenginesPage/modals/ArchiveMicroengineModal';
import { CreateMicroengineModalParams } from 'views/pages/MicroenginesPage/modals/CreateMicroengineModal';
import { UpdateMicroengineModalParams } from 'views/pages/MicroenginesPage/modals/UpdateMicroengineModal';
import { useUser } from 'views/components/providers/UserProvider';
import { canManageMicroengine } from 'views/pages/MicroenginesPage/auth';
import microengineImg from 'assets/images/microengine.svg';
import { btnMessages } from 'views/components/Button';
import PanelContent from 'views/components/layout/PanelContent';
import AppLoader from 'views/components/request/AppLoader';
import MyMicroenginesTable from './MyMicroenginesTable';
import { useMimetypes } from '../hooks/useMimetypes';
import { useArtifactTypes } from '../hooks/useArtifactTypes';

const messages = defineMessages({
  microengine: {
    id: 'myMicroenginesTab.microengine',
    defaultMessage: 'engine',
  },
  microengineHeading: {
    id: 'myMicroenginesTab.microengineHeading',
    defaultMessage: 'Add your engines',
  },
  microengineText: {
    id: 'myMicroenginesTab.microengineText',
    defaultMessage:
      'Add your first engine by clicking below. For information on building your first engine, check out the docs.',
  },
  claimMicroengine: {
    id: 'myMicroenginesTab.claimMicroengine',
    defaultMessage: 'Add engine',
  },
});

const MyMicroenginesTab = () => {
  const user = useUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { mimetypes } = useMimetypes();
  const { artifactTypes } = useArtifactTypes();
  const microengines = useSelector((state: RootState) => state.microengines.user);

  const [sort, setSort] = useState<IColumnSort<keyof MicroengineListing>>({
    orderBy: 'name',
    direction: ESortDirection.ASC,
  });

  // Get list of provied tags
  const tags = useMemo(() => {
    const searchQuery = new URLSearchParams(location.search);
    return searchQuery.has('tags') && searchQuery.get('tags') !== ''
      ? searchQuery.get('tags')!.split(',')
      : [];
  }, [location.search]);

  // Call API for list of user microengines
  const _getUserMicroengines = (refresh: boolean, query?: PageQuery<MicroengineListing>) => {
    const params = Object.assign({ tags }, extractPageQueryParams(microengines), query);
    dispatch(getUserMicroenginesAction(params, refresh));
  };

  // Apply sorting
  const _handleSort = ({ orderBy, direction }: any) => {
    setSort({ orderBy, direction });
    _getUserMicroengines(true, {
      orderBy,
      direction,
    });
  };

  const formData = {
    artifactTypes,
    mimetypes,
  };

  useEffect(() => {
    _getUserMicroengines(true);
  }, []); // eslint-disable-line

  useEffect(() => {
    _getUserMicroengines(true);
  }, [tags]); // eslint-disable-line

  const _onLoadMore = () => {
    const { offset, limit } = microengines;

    _getUserMicroengines(false, {
      offset: offset ? Number(offset) + limit : limit,
    });

    return Promise.resolve();
  };

  const _createUserMicroengine = () => {
    dispatch(
      openModal<CreateMicroengineModalParams>('CREATE_USER_MICROENGINE', {
        refresh: true,
        queryParams: sort,
        formData,
      })
    );
  };

  // const _claimUserMicroengine = (id: number, name: string, accountNumber: number) => {
  //   const params = extractPageQueryParams(microengines);
  //   dispatch(claimUserMicroengineAction(id, name, accountNumber, params));
  // };

  const _updateUserMicroengine = (id: number, values: MicroengineValues) => {
    const queryParams = Object.assign({}, extractPageQueryParams(microengines), {});
    dispatch(
      openModal<UpdateMicroengineModalParams>('UPDATE_USER_MICROENGINE', {
        id,
        values,
        queryParams,
        formData,
      })
    );
  };

  const _archiveUserMicroengine = (id: number, displayName: string, accountNumber: number) => {
    const queryParams = Object.assign({}, extractPageQueryParams(microengines), {});
    dispatch(
      openModal<ArchiveMicroengineModalParams>('ARCHIVE_USER_MICROENGINE', {
        id,
        displayName,
        accountNumber,
        queryParams,
      })
    );
  };

  const _onClickEngine = (engine: MicroengineListing) => {
    dispatch(selectMicroengine(engine));
  };

  return (
    <AppLoader
      loadingActions={[MicroenginesActionName.REFRESH_USER_MICROENGINES]}
      onReload={() => _getUserMicroengines(true)}
    >
      {() => {
        if (!microengines.results) {
          return null;
        } else if (!microengines.results.length && canManageMicroengine(user)) {
          return (
            <PanelContent
              image={{ src: microengineImg, alt: intl.formatMessage(messages.microengine) }}
              heading={intl.formatMessage(messages.microengineHeading)}
              text={intl.formatMessage(messages.microengineText)}
              buttons={[
                {
                  text: intl.formatMessage(messages.claimMicroengine),
                  onClick: _createUserMicroengine,
                  testId: 'addMicroengineBtn',
                },
                {
                  text: intl.formatMessage(btnMessages.readDocs),
                  href: `${SUPPLIER_DOCS_URL}/create-your-participant`,
                  variant: 'outlined',
                },
              ]}
            />
          );
        }
        return (
          <MyMicroenginesTable
            microengines={microengines}
            sort={sort}
            onSort={_handleSort}
            createUserMicroengine={_createUserMicroengine}
            updateUserMicroengine={_updateUserMicroengine}
            archiveUserMicroengine={_archiveUserMicroengine}
            onLoadMore={_onLoadMore}
            onClickEngine={_onClickEngine}
          />
        );
      }}
    </AppLoader>
  );
};

export default MyMicroenginesTab;
