import React, { useState, createContext, Fragment } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';

const messages = defineMessages({
  title: {
    id: 'sandbox.title',
    defaultMessage: 'Sandbox',
  },
});

export const SandboxDownloadContext = createContext<{
  items: string[];
  addItems: (items: string[]) => void;
}>({
  items: [],
  addItems: (items: string[]) => null,
});

const SandboxPage = ({ children }: { children: React.ReactNode }) => {
  const [items, addItems] = useState<string[]>([]);
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <SandboxDownloadContext.Provider value={{ items, addItems }}>
        <BaseLayout>{children}</BaseLayout>
      </SandboxDownloadContext.Provider>
    </Fragment>
  );
};

export default SandboxPage;
