import React, { useRef, useEffect, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import qs from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { getAllHistoricalHunts, getHistoricalHuntResults } from 'state/hunting/actions';
import { downloadArtifactUrl } from 'state/submission/actions';
import { extractPageQueryParams } from 'utils/pagination';
import { RootState } from 'state/root';
import { Hunt } from 'models/Ruleset';
import { PageQuery } from 'models/Page';
import { HuntingActionName } from 'state/hunting/types';
import { openModal } from 'state/modal/actions';
import { CancelHistoricalHuntModalParams } from 'views/pages/HuntPage/modals/CancelHistoricalHuntModal';
import { DeleteHuntModalParams } from 'views/pages/HuntPage/modals/DeleteHuntModal';
import { ViewRulesetModalParams } from 'views/pages/HuntPage/modals/ViewRulesetModal';
import { HistoricalInfoModalParams } from 'views/pages/HuntPage/modals/HistoricalInfoModal';
import { EPageDirection } from 'views/components/table/CustomTablePagination';
import HistoricalHuntTable from './HistoricalHuntTable';
import HuntingTable from '../HuntingTable';
import PanelContent from 'views/components/layout/PanelContent';
import AppLoader from 'views/components/request/AppLoader';
import { toggleHuntsType } from 'views/pages/HuntPage/types';
import { InfoModalParams } from 'views/pages/HuntPage/modals/InfoModal';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';

interface IHistoricalHuntTab {
  selectedHunts: string[];
  toggleHunts: toggleHuntsType;
}

const messages = {
  heading: 'Choose a YARA ruleset',
  text: "Choose the YARA ruleset you'd like to use for your Historical Hunt by clicking below. For information on searching and YARA rules, check out the docs.",
  chooseRuleset: 'Choose ruleset',
  resultInfo: 'Result Info',
};

const HistoricalHuntTab = ({ toggleHunts, selectedHunts }: IHistoricalHuntTab) => {
  const _offsetsHistorical = useRef<string[]>([]);
  const _offsetsResults = useRef<string[]>([]);
  const _currentOffsetHistorical = useRef<string | undefined>();
  const _currentOffsetResults = useRef<string | undefined>();

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { hunt_id } = qs.parse(location.search);

  const { historicalHunts, historicalHuntResults } = useSelector(
    (state: RootState) => state.hunting
  );

  const _getAllHistoricalHunts = (refresh: boolean, query?: PageQuery<Hunt>) => {
    const params = Object.assign({}, extractPageQueryParams(historicalHunts), query);
    dispatch(getAllHistoricalHunts(refresh, params));
  };

  const _viewRuleset = (id: string) =>
    dispatch(openModal<ViewRulesetModalParams>('VIEW_RULESET', { id, scanType: 'historical' }));

  const _viewResults = (id: string) => {
    history.replace(`${match.url}?${qs.stringify({ hunt_id: id })}`);
  };

  const _showHuntingInfo = (liveHuntId: string, liveHuntResultId: string) =>
    dispatch(
      openModal<InfoModalParams>('INFO_MODAL', {
        title: messages.resultInfo,
        data: [
          { key: 'Live Hunt ID', value: liveHuntId },
          { key: 'Historical Hunt Result ID', value: liveHuntResultId },
        ],
      })
    );

  const _showHistoricalHuntInfo = ({ rulesets, totalMatches, data }: HistoricalInfoModalParams) =>
    dispatch(
      openModal<HistoricalInfoModalParams>('HISTORICAL_INFO_MODAL', {
        rulesets,
        totalMatches,
        data,
      })
    );

  const _deleteHunt = (id: string) =>
    dispatch(openModal<DeleteHuntModalParams>('DELETE_HUNT', { id, scanType: 'historical' }));

  const _cancelHunt = (id: string) => {
    const params = qs.parse(location.search);
    dispatch(
      openModal<CancelHistoricalHuntModalParams>('CANCEL_HISTORICAL_HUNT', {
        id,
        params,
      })
    );
  };

  const _downloadArtifact = (hash: string, url: string) => dispatch(downloadArtifactUrl(hash, url));

  const _updateQueryParams = (newParams: PageQuery<Hunt>) => {
    const params = qs.parse(location.search);
    history.replace(`${match.url}?${qs.stringify({ ...params, ...newParams })}`);
  };

  const _handleChangePage =
    (
      _currentOffset: React.MutableRefObject<string | undefined>,
      _offsets: React.MutableRefObject<string[]>
    ) =>
    (direction: EPageDirection) => {
      let offset;

      if (direction === EPageDirection.PREV) {
        _currentOffset.current = _offsets.current.pop();
        offset = _currentOffset.current;
      } else if (direction === EPageDirection.NEXT) {
        if (_currentOffset.current) {
          _offsets.current.push(_currentOffset.current);
        }
        _currentOffset.current = String(
          hunt_id ? historicalHuntResults?.offset : historicalHunts.offset
        );
        offset = _currentOffset.current;
      } else if (direction === EPageDirection.FIRST) {
        _currentOffset.current = undefined;
        _offsets.current = [];
      }

      _updateQueryParams({ offset });
    };

  const _handleChangeRowsPerPage =
    (
      _currentOffset: React.MutableRefObject<string | undefined>,
      _offsets: React.MutableRefObject<string[]>
    ) =>
    (numRows: number) => {
      _currentOffset.current = undefined;
      _offsets.current = [];
      _updateQueryParams({ limit: numRows, offset: undefined });
    };

  useEffect(() => {
    const params = qs.parse(location.search);
    if (params.hunt_id) {
      dispatch(getHistoricalHuntResults(String(params.hunt_id), { limit: 25, ...params }));
    } else {
      _getAllHistoricalHunts(true, params);
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppLoader
      loadingActions={[HuntingActionName.REFRESH_ALL_HISTORICAL_HUNTS]}
      onReload={() => _getAllHistoricalHunts(true)}
    >
      {() => {
        if (!historicalHunts.results) {
          return null;
        }
        if (hunt_id && historicalHuntResults?.results) {
          return (
            <HuntingTable
              hunts={historicalHuntResults}
              viewRuleset={_viewRuleset}
              deleteHunt={_deleteHunt}
              downloadArtifact={_downloadArtifact}
              showHuntingInfo={_showHuntingInfo}
              modalTitle={messages.resultInfo}
              selectedHunts={selectedHunts}
              toggleHunts={toggleHunts}
              handleChangePage={_handleChangePage(_currentOffsetResults, _offsetsResults)}
              handleChangeRowsPerPage={_handleChangeRowsPerPage(
                _currentOffsetResults,
                _offsetsResults
              )}
              isFirst={!_currentOffsetResults.current}
            />
          );
        }
        return !historicalHunts.results.length ? (
          <PanelContent
            testId='historicalHuntEmpty'
            imageComponent={<RulesetImg />}
            heading={'No Results'}
          />
        ) : (
          <Fragment>
            <HistoricalHuntTable
              hunts={historicalHunts}
              cancelHunt={_cancelHunt}
              viewResults={_viewResults}
              viewRuleset={_viewRuleset}
              deleteHunt={_deleteHunt}
              showHistoricalHuntInfo={_showHistoricalHuntInfo}
              toggleHunts={toggleHunts}
              selectedHunts={selectedHunts}
              handleChangePage={_handleChangePage(_currentOffsetHistorical, _offsetsHistorical)}
              handleChangeRowsPerPage={_handleChangeRowsPerPage(
                _currentOffsetHistorical,
                _offsetsHistorical
              )}
              isFirst={!_currentOffsetHistorical.current}
            />
          </Fragment>
        );
      }}
    </AppLoader>
  );
};

export default HistoricalHuntTab;
