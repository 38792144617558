import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import SidebarDefault from 'views/components/layout/Sidebar/SidebarDefault';

const messages = defineMessages({
  live: {
    id: 'huntSidebar.live',
    defaultMessage:
      'Live Hunt will find artifacts submitted to the PolySwarm marketplace based on your active ruleset and return them here.',
  },
  historical: {
    id: 'huntSidebar.historical',
    defaultMessage:
      'Historical Hunt will find artifacts matching your ruleset that have been submitted to the PolySwarm marketplace in the past.',
  },
  rulesetsHeading: {
    id: 'rulesets.sidebar.heading',
    defaultMessage: 'Rulesets for Live and Historical Hunting',
  },
  rulesetsText: {
    id: 'rulesets.sidebar.text',
    defaultMessage:
      'Upload one or more rulesets to go Hunting. We offer Live Hunting, which will use your ruleset to search through artifacts as they are submitted. And Historical Hunting, which will use your ruleset to search through previously submitted artifacts.',
  },
  liveHeading: {
    id: 'live.sidebar.heading',
    defaultMessage: 'Live Hunting',
  },
  liveText: {
    id: 'live.sidebar.text',
    defaultMessage:
      'Live Hunting will match your active YARA ruleset against new artifacts as they are submitted. Matching artifacts will be listed here for you to view and download.',
  },
  historicalHeading: {
    id: 'historical.sidebar.heading',
    defaultMessage: 'Historical Hunting',
  },
  historicalText: {
    id: 'historical.sidebar.text',
    defaultMessage:
      'Historical Hunting will match your YARA ruleset against previously submitted artifacts. Matching artifacts will be listed here for you to view and download.',
  },
});

const HuntSidebar = () => {
  const intl = useIntl();
  const location = useLocation();

  if (location.pathname === '/hunt/rulesets') {
    return (
      <SidebarDefault
        heading={intl.formatMessage(messages.rulesetsHeading)}
        text={intl.formatMessage(messages.rulesetsText)}
        isLoginRequired={true}
      />
    );
  }

  if (location.pathname === '/hunt/live') {
    return (
      <SidebarDefault
        heading={intl.formatMessage(messages.liveHeading)}
        text={intl.formatMessage(messages.liveText)}
        isLoginRequired={true}
      />
    );
  }

  if (location.pathname === '/hunt/historical') {
    return (
      <SidebarDefault
        heading={intl.formatMessage(messages.historicalHeading)}
        text={intl.formatMessage(messages.historicalText)}
        isLoginRequired={true}
      />
    );
  }

  // If route not found
  return null;
};

export default HuntSidebar;
