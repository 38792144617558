import { AsyncAction } from 'state/types/actions';
import { Action } from 'redux';
import {
  AllMicroenginesRes,
  MicroengineListingRes,
  MicroengineClaimRes,
  MicroengineRes,
  UserMicroenginesRes,
} from 'services/api/schema/microengines';
import { PublicMicroengineListing } from 'models/Microengine';

export enum MicroenginesActionName {
  SELECT_MICROENGINE = 'SELECT_MICROENGINE',
  GET_ALL_MICROENGINES = 'GET_ALL_MICROENGINES',
  REFRESH_ALL_MICROENGINES = 'REFRESH_ALL_MICROENGINES',
  GET_ALL_VERIFIED_MICROENGINES = 'GET_ALL_VERIFIED_MICROENGINES',
  GET_MICROENGINE = 'GET_MICROENGINE',
  REFRESH_MICROENGINE = 'REFRESH_MICROENGINE',
  GET_USER_MICROENGINES = 'GET_USER_MICROENGINES',
  REFRESH_USER_MICROENGINES = 'REFRESH_USER_MICROENGINES',
  CREATE_USER_MICROENGINE = 'CREATE_USER_MICROENGINE',
  CLAIM_USER_MICROENGINE = 'CLAIM_USER_MICROENGINE',
  VERIFY_USER_MICROENGINE = 'VERIFY_USER_MICROENGINE',
  UPDATE_USER_MICROENGINE = 'UPDATE_USER_MICROENGINE',
  ARCHIVE_USER_MICROENGINE = 'ARCHIVE_USER_MICROENGINE',
}

export interface SelectMicroengineAction extends Action<MicroenginesActionName.SELECT_MICROENGINE> {
  microengine: MicroengineListingRes | null;
}

export interface GetAllMicroenginesAction
  extends AsyncAction<MicroenginesActionName.GET_ALL_MICROENGINES, AllMicroenginesRes> {}

export interface RefreshAllMicroenginesAction
  extends AsyncAction<MicroenginesActionName.REFRESH_ALL_MICROENGINES, AllMicroenginesRes> {}

export interface GetAllVerifiedMicroenginesAction
  extends AsyncAction<
    MicroenginesActionName.GET_ALL_VERIFIED_MICROENGINES,
    PublicMicroengineListing[]
  > {}

export interface GetMicroengineAction
  extends AsyncAction<MicroenginesActionName.GET_MICROENGINE, MicroengineListingRes> {}

export interface RefreshMicroengineAction
  extends AsyncAction<MicroenginesActionName.REFRESH_MICROENGINE, MicroengineListingRes> {}

export interface GetUserMicroenginesAction
  extends AsyncAction<MicroenginesActionName.GET_USER_MICROENGINES, UserMicroenginesRes> {}

export interface RefreshUserMicroenginesAction
  extends AsyncAction<MicroenginesActionName.REFRESH_USER_MICROENGINES, UserMicroenginesRes> {}

export interface CreateUserMicroengineAction
  extends AsyncAction<MicroenginesActionName.CREATE_USER_MICROENGINE, MicroengineRes> {}

export interface ClaimUserMicroengineAction
  extends AsyncAction<MicroenginesActionName.CLAIM_USER_MICROENGINE, MicroengineClaimRes> {}

export interface VerifyUserMicroengineAction
  extends AsyncAction<MicroenginesActionName.VERIFY_USER_MICROENGINE, MicroengineRes> {}

export interface UpdateUserMicroengineAction
  extends AsyncAction<MicroenginesActionName.UPDATE_USER_MICROENGINE, MicroengineRes> {}

export interface ArchiveUserMicroengineAction
  extends AsyncAction<MicroenginesActionName.ARCHIVE_USER_MICROENGINE, MicroengineRes> {}

export type MicroenginesAction =
  | SelectMicroengineAction
  | GetAllMicroenginesAction
  | RefreshAllMicroenginesAction
  | GetAllVerifiedMicroenginesAction
  | GetMicroengineAction
  | RefreshMicroengineAction
  | GetUserMicroenginesAction
  | RefreshUserMicroenginesAction
  | CreateUserMicroengineAction
  | ClaimUserMicroengineAction
  | VerifyUserMicroengineAction
  | UpdateUserMicroengineAction
  | ArchiveUserMicroengineAction;
