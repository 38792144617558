import Big, { BigSource } from 'big.js';

/**
 * Round float between 0 and 1 to two decimal places.
 */
export const roundFloat = (num: number) =>
  Math.round((Math.min(Math.max(num, 0), 1) + Number.EPSILON) * 100) / 100;

/**
 * Truncate float between 0 and 1 to two decimal places.
 */
export const truncateFloat = (num: number) => {
  let output;
  const n = Math.min(Math.max(num, 0), 1);
  const values = n.toString().split('.');
  if (values.length === 1) {
    output = values[0];
  } else {
    output = `${values[0]}.${values[1].substring(0, 2)}`;
  }
  return parseFloat(output);
};

/**
 * Convert float between 0 and 1 to percentage as integer.
 */
export const floatToPercentageInt = (num: number) =>
  Math.round(Math.min(Math.max(num, 0), 1) * 100);

/**
 * Convert float between 0 and 1 to percentage string.
 */
export const floatToPercentage = (num: number) => `${floatToPercentageInt(num)}%`;

export const toFixed = (num: BigSource, fixed: number): string => {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  const match = Big(num).toFixed().match(re);
  return match ? match[0] : '';
};

/**
 * Truncate number to n decimal places.
 */
export const truncateValue = (value: BigSource, decimalPlaces: number) => {
  const val = Big(value).toFixed();
  const [, decimals] = val.split('.');
  if (decimals && decimals.length > decimalPlaces) {
    return toFixed(value, decimalPlaces);
  }
  return val;
};

/**
 * Formats a number with the following logic:
 * - If the number is less than 1,000, returns the number as is.
 * - Greater than or equal to 1,000 and less than 1,000,000, returns the number divided by 1,000 with a 'K' suffix.
 * - Than 1,000,000,000, returns the number divided by 1,000,000 with an 'M' suffix.
 */
export const formatNumber = (num: number) => {
  if (num === undefined || num === null) return ''; // Prevents NaN from being returned in border cases.
  const suffixes = ['', 'K', 'M'];
  const absNum = Math.abs(num);
  const logBase = Math.min(Math.floor(Math.log10(Math.max(absNum, 1)) / 3), suffixes.length - 1);
  const divisor = 1000 ** logBase;
  const formatted = (absNum / divisor).toFixed(1);
  const formattedWithSuffix = formatted.endsWith('.0')
    ? formatted.slice(0, -2) + suffixes[logBase]
    : formatted + suffixes[logBase];

  return num < 0 ? `-${formattedWithSuffix}` : formattedWithSuffix;
};
