// @ts-nocheck
import React, { useState, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import styled from '@emotion/styled';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { NormalizedSearchResult, ESubmissionResultType } from 'models/Submission';
import styles from 'views/styles';
import { useIntl, defineMessages } from 'react-intl';
import { useAuth } from 'views/components/providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { btnMessages } from 'views/components/Button';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import ToggleButton from '../../MetadataSearchTab/ToggleButton';
import ArtifactDetails from '../../MetadataSearchTab/ArtifactDetails';
import { ErrorKeys, translateError } from 'utils/error';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import ActionBtn from 'views/components/table/ActionBtn';
import { PolyScore } from 'views/components/PolyScore';
import Tag from 'views/components/Tag';
import Icon from 'views/components/Icon';
import { getNetworkData } from 'views/pages/SandboxPage/NetworkTab/mapper';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { parseScanPageURL } from 'views/url';
import { useDispatch } from 'react-redux';
import { openModal } from 'state/modal/actions';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';

interface IHashSearchTableRow {
  rowData: NormalizedSearchResult;
  viewScanResult: (hash: string) => void;
  downloadArtifact: (hash: string) => void;
}

const messages = defineMessages({
  notFound: {
    id: 'hashSearchTableRow.notFound',
    defaultMessage: 'Not Found',
  },
  error: {
    id: 'hashSearchTableRow.error',
    defaultMessage: 'Request Error',
  },
  invalidHash: {
    id: 'hashSearchTableRow.invalidHash',
    defaultMessage: 'Invalid Hash',
  },
});

const HoverableTableRow = styled(BaseHoverableTableRow)`
  cursor: pointer;
`;

const Detections = styled.div<{ malicious?: boolean }>`
  color: ${({ malicious }) => (malicious ? styles.color.red : styles.color.medGreen)};
  font-weight: bold;
`;

const HashValue = styled.div`
  font-family: ${styles.font.family.mono};
  font-size: 1.3rem;
  white-space: nowrap;
  text-align: left;
  line-height: 1.5;
`;

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const ErrorMessage = styled.span`
  color: ${styles.color.xLightGrey};
`;

const ScanButton = styled(Button)`
  font-size: 1.4rem !important;
  min-width: 6em !important;
  padding: 0.5rem 1.4rem !important;
`;

const Actions = styled.div`
  white-space: nowrap;
`;

const Icons = styled.div<{ isDarkTheme: boolean }>`
  display: flex;
  align-items: center;

  & .icon {
    cursor: default;
    font-size: 0.6rem;
    color: ${({ isDarkTheme }) =>
      isDarkTheme ? styles.color.xDarkPurple : styles.color.xLightGrey};
  }

  & .icon-link {
    margin-right: -0.5rem;
    margin-top: 2.3rem;
  }

  & .icon-ping {
    fill: none;
    margin-left: 0.8rem;
    margin-top: 2.3rem;
  }

  & .active {
    color: ${({ isDarkTheme }) => (isDarkTheme ? styles.color.lightBlue : styles.color.purple)};
  }
`;

const errorKeys: ErrorKeys = {
  not_found_error: messages.notFound.id,
  invalid_hash: messages.invalidHash.id,
};

const DetectionsValue = ({
  rowData,
  viewScanResult,
}: {
  rowData: NormalizedSearchResult;
  viewScanResult: IHashSearchTableRow['viewScanResult'];
}) => {
  const intl = useIntl();

  if (rowData.error) {
    return <ErrorMessage>{translateError(intl, errorKeys, rowData.error)}</ErrorMessage>;
  }

  if (rowData.detections && rowData.detections.total) {
    return (
      <Detections
        malicious={rowData.detections.malicious !== 0}
        onClick={(e) => {
          e.preventDefault();
          viewScanResult(rowData.sha256);
        }}
      >
        {`${rowData.detections.malicious} / ${rowData.detections.total}`}
      </Detections>
    );
  }

  return (
    <ScanButton color='primary' variant='contained' onClick={() => viewScanResult(rowData.sha256)}>
      {intl.formatMessage(btnMessages.scanNow)}
    </ScanButton>
  );
};

const HashSearchTableRow = ({ rowData, viewScanResult, downloadArtifact }: IHashSearchTableRow) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isAuthenticated, login } = useAuth();
  const { classes, theme } = useStyles();
  const isDarkTheme = theme === 'dark';
  const networkData = getNetworkData(
    0,
    0,
    rowData?.metadata?.cape_sandbox_v2?.network as any,
    rowData?.metadata?.triage_sandbox_v0 as any
  );
  const isCommunityPlan = useIsCommunityPlan();

  const hasUrls = networkData.urls.length > 0;
  const hasDomains = networkData.domains.length > 0;
  const hasIps = networkData.ips.length > 0;

  const [open, setOpen] = useState(false);

  const reportTitle = isCommunityPlan
    ? 'Generate Report is not available for Community Plan users'
    : 'Generate Report';

  const _onToggleOpen = () => {
    setOpen(!open);
  };

  const _openScanResults = () => {
    if (!rowData.error) {
      viewScanResult(rowData.sha256);
    }
  };

  return (
    <Fragment>
      <HoverableTableRow
        dataCy='hashSearchTableRow'
        renderCells={() => (
          <Fragment>
            <TableCell>
              <HashValue data-testid='hashSearchResultLink'>
                {rowData.hash}
                <CopyButton text={rowData.hash} />
              </HashValue>
              {rowData.attributes?.mimetype && <Tag inline>{rowData.attributes.mimetype}</Tag>}
            </TableCell>
            <TableCell>
              <PolyScore
                classNumber={classes.polyscore}
                polyscore={rowData.polyscore}
                bar={false}
                iconSize='small'
              />
            </TableCell>
            <TableCell onClick={_openScanResults}>
              {rowData.submitted
                ? formatDate(parseDate(rowData.submitted), DATE_TIME_FORMAT)
                : intl.formatMessage({ id: 'placeholder.none' })}
            </TableCell>
            <TableCell onClick={_openScanResults}>
              <DetectionsValue rowData={rowData} viewScanResult={viewScanResult} />
            </TableCell>
            <TableCell align='right'>
              <Icons isDarkTheme={isDarkTheme}>
                <Icon name='link' className={`icon icon-link ${hasUrls ? 'active' : ''}`} />
                <Icon name='globe' className={`icon ${hasDomains ? 'active' : ''}`} />
                <Icon name='ping' className={`icon icon-ping ${hasIps ? 'active' : ''}`} />
              </Icons>
            </TableCell>
            <TableCell align='right'>
              {!rowData.error && (
                <Actions>
                  <ToggleButton open={open} onClick={_onToggleOpen} />
                  <ActionBtn
                    name='open-view'
                    onClick={() => {
                      window.open(
                        parseScanPageURL(rowData.sha256, {
                          type: rowData.attributes.type.toLowerCase() as keyof ESubmissionResultType as Lowercase<ESubmissionResultType>,
                          instanceId: rowData.artifact_id,
                        }),
                        '_blank'
                      );
                    }}
                  />
                  <ActionBtn
                    disabled={isCommunityPlan}
                    style={{ marginLeft: 8 }}
                    name='generate-report'
                    title={reportTitle}
                    aria-label={reportTitle}
                    onClick={() => {
                      if (!isAuthenticated) {
                        login(location.pathname);
                      } else {
                        dispatch(
                          openModal('GENERATE_REPORT', {
                            type: 'scan',
                            id: rowData.artifact_id,
                          })
                        );
                      }
                    }}
                  />
                  <ActionBtn
                    name='download'
                    style={{ marginLeft: 8 }}
                    aria-label={intl.formatMessage(btnMessages.download)}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      if (!isAuthenticated) {
                        login(location.pathname);
                      } else {
                        downloadArtifact(rowData.sha256);
                      }

                      return false;
                    }}
                    data-testid='hashSearchDownloadBtn'
                  />
                </Actions>
              )}
            </TableCell>
          </Fragment>
        )}
      />
      {open && (
        <TableCell colSpan={4}>
          <ArtifactDetails details={rowData} />
        </TableCell>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    polyscore: {
      fontSize: '1.6rem',
      textAlign: 'left',
    },
    polyscoreContainer: {
      marginTop: '-4rem',
    },
  },
  light: {},
  dark: {},
});
export default HashSearchTableRow;
