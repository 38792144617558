import React from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { IFilterTagsButtonProps } from './interfaces';
import Badge from '@material-ui/core/Badge';

const Wrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 25px;
`;

const StyledButton = styled(Button)`
  font-size: 1.1rem !important;
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 1.4rem;
    right: 1.4rem;
    font-size: 1rem;
    padding: 0 5px;
    min-width: 1.2rem;
    height: 1.4rem;
  }
`;

const FilterTagsButton = ({ onClick }: IFilterTagsButtonProps) => {
  const query = new URLSearchParams(useLocation().search);
  const tags = query.has('tags') && query.get('tags') !== '' ? query.get('tags')!.split(',') : [];

  return (
    <Wrapper>
      <StyledBadge badgeContent={tags.length} color='error'>
        <StyledButton variant='outlined' color='primary' size='small' onClick={onClick}>
          Filter
        </StyledButton>
      </StyledBadge>
    </Wrapper>
  );
};

export default FilterTagsButton;
