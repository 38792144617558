import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import Loader from 'views/components/Loader';
import { Form } from 'formik';

interface Props {
  className?: string;
  isLoading: boolean;
  heading?: string;
  headingCentered?: boolean;
  text?: string;
  testId?: string;
  renderInfoText?: () => JSX.Element;
  renderFields: () => JSX.Element;
  renderButtonBar: () => JSX.Element;
}

/**
 * Custom dialog form that can be used to render form fields and a button bar with common styling.
 */
const CustomForm = ({
  className,
  isLoading,
  heading,
  headingCentered,
  text,
  testId,
  renderInfoText,
  renderFields,
  renderButtonBar,
}: Props) => {
  const { classes } = useStyles({ isLoading, headingCentered });
  return (
    <div className={className} css={classes.root} data-testid={testId}>
      {isLoading && <Loader css={classes.loader} testId='form-loader' />}
      <div css={classes.form}>
        <Form noValidate autoComplete='off'>
          {heading && (
            <h4 className='h4 h-mb-tiny' css={classes.heading}>
              {heading}
            </h4>
          )}
          {text && <p className='p1 h-mb-xs'>{text}</p>}
          {renderInfoText && (
            <p className='p1 h-mb-xs'>
              <Icon css={classes.infoIcon} name='info' />
              {renderInfoText()}
            </p>
          )}
          {renderFields()}
          {renderButtonBar()}
        </Form>
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  ({ isLoading, headingCentered }: { isLoading: boolean; headingCentered?: boolean }) => ({
    base: {
      root: {
        position: 'relative',
      },
      heading: {
        textAlign: headingCentered ? 'center' : 'left',
      },
      loader: {
        position: 'absolute',
        fontSize: '0.6rem !important',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      infoIcon: {
        fontSize: '0.5rem',
        lineHeight: 2,
        verticalAlign: 'middle',
        marginRight: styles.spacing.tiny,
        position: 'relative',
        top: -1,
      },
      form: {
        visibility: isLoading ? 'hidden' : 'visible',
      },
    },
    light: {
      infoIcon: {
        color: styles.color.purple,
      },
    },
    dark: {
      infoIcon: {
        color: styles.color.lightBlue,
      },
    },
  })
);

export default CustomForm;
