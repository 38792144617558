import React from 'react';
import microengineImg from 'assets/images/microengine.svg';
import MicroengineDetailSidebar from './MicroengineDetailSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { selectMicroengine } from 'state/microengines/actions';
import SidebarDefault from 'views/components/layout/Sidebar/SidebarDefault';

const messages = defineMessages({
  heading: {
    id: 'microengine.sidebar.heading',
    defaultMessage: 'Engine Details',
  },
  text: {
    id: 'microengine.sidebar.text',
    defaultMessage: 'Select an Engine’s name to view its details',
  },
});

const MicroenginesSidebar = () => {
  const selectedEngine = useSelector((state: RootState) => state.microengines.selected);
  const dispatch = useDispatch();
  const intl = useIntl();

  const _onClose = () => {
    dispatch(selectMicroengine(null));
  };

  if (selectedEngine) {
    return <MicroengineDetailSidebar microengine={selectedEngine} onClose={_onClose} />;
  }

  return (
    <SidebarDefault
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text)}
      isLoginRequired={true}
      icon={microengineImg}
    />
  );
};

export default MicroenginesSidebar;
