import React, { useState } from 'react'; // eslint-disable-line
import { jsx, keyframes } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import HowItWorksSection from '../';
import computerImg from './computer.svg';
import swarmImg from '../shared/swarm.svg';
import fileImg from '../shared/file.svg';
import arrowImg from '../shared/arrow.svg';

const SubmitSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { classes } = useStyles(isVisible);

  const _handleVisibilityChange = (isVisible: boolean) => setIsVisible(isVisible);

  return (
    <HowItWorksSection onVisibilityChange={_handleVisibilityChange}>
      <p css={classes.text}>
        <FormattedMessage
          id='howItWorks.sections.submit.text'
          defaultMessage='Users submit artifacts to PolySwarm’s network via API or web UI.'
        />
      </p>
      <div css={classes.inner}>
        <p css={classes.submit}>Submission</p>
        <div css={classes.arrowWrap}>
          <div css={classes.arrow}>
            <img css={classes.arrowHead} src={arrowImg} alt='arrow' />
          </div>
          <img css={classes.file} src={fileImg} alt='file' />
        </div>
        <div css={classes.computerWrap}>
          <img src={computerImg} alt='computer' />
          <div css={classes.computerScreen} />
          <img css={classes.swarm} src={swarmImg} alt='swarm' />
        </div>
      </div>
    </HowItWorksSection>
  );
};

const fileMove = keyframes`
  0% {
    opacity: 0;
    left: 2rem;
  }
  20% {
    opacity: 1;
    left: 2rem;
  }
  30% {
    opacity: 1;
    left: 2rem;
  }
  100% {
    opacity: 1;
    left: calc(100% + 5rem);
  }
`;

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

const fadeInGrow = keyframes`
  33.33% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  66.67% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const useStyles = makeStyles((isVisible: boolean) => ({
  base: {
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
    },
    text: {
      position: 'absolute',
      top: styles.spacing.lg,
      left: styles.spacing.lg,
      maxWidth: '28rem',
      zIndex: 1,
    },
    submit: {
      fontSize: styles.font.size.h6,
      fontWeight: styles.font.weight.black,
      textTransform: 'uppercase',
    },
    arrowWrap: {
      width: '100%',
      paddingLeft: '3rem',
      position: 'relative',
    },
    file: {
      position: 'absolute',
      top: '50%',
      left: '2rem',
      transform: 'translateY(-50%)',
      opacity: 0,
      animation: isVisible ? `${fileMove} 2s ease-in-out 0.2s forwards` : 'none',
    },
    arrow: {
      width: '100%',
      height: '0.2rem',
      backgroundColor: styles.color.xLightGrey,
      position: 'relative',
    },
    arrowHead: {
      position: 'absolute',
      top: '0.1rem',
      transform: 'translateY(-50%)',
      right: '-0.2rem',
      width: '1.4rem',
    },
    computerWrap: {
      position: 'relative',
      zIndex: 1,
    },
    computerScreen: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 200,
      height: 150,
      transform: 'translate(-50%, -50%)',
      backgroundColor: styles.color.white,
      opacity: 0,
      animation: isVisible ? `${fadeIn} 0.8s ease-in-out 1.8s forwards` : 'none',
    },
    swarm: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 165,
      transform: 'translate(-50%, -50%) scale(0.9)',
      opacity: 0,
      animation: isVisible ? `${fadeInGrow} 4s ease-in-out 2s forwards` : 'none',
    },
  },
  light: {
    submit: {
      color: styles.color.purple,
    },
  },
  dark: {
    submit: {
      color: styles.color.lightBlue,
    },
  },
}));

export default SubmitSection;
