import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Icon from 'views/components/Icon';

const Stat = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
`;

type TopListProps = {
  title: string;
  list?: {
    name: string;
    variation: number;
  }[];
};

const TopList = ({ title, list = [] }: TopListProps) => {
  const { classes } = useStyles();

  return (
    <Stat>
      <div css={classes.title}>{title}</div>
      <ul css={classes.list}>
        {list.map((item, i) => (
          <li key={`${item.name} ${i}`} css={classes.listItem}>
            <Icon
              css={[classes.arrow, item.variation > 0 ? classes.arrowUp : classes.arrowDown]}
              name='arrow-solid'
            />{' '}
            {item.name}
          </li>
        ))}
      </ul>
    </Stat>
  );
};

const useStyles = makeStyles({
  base: {
    title: {
      fontFamily: styles.font.family.main,
      fontWeight: styles.font.weight.bold,
      whiteSpace: 'nowrap',
      fontSize: '2rem',
    },
    list: {
      paddingLeft: '0.5rem',
    },
    listItem: {
      paddingTop: '1.5rem',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    },
    arrow: {
      fontSize: '0.27rem',
      marginRight: '2rem',
    },
    arrowUp: {
      color: styles.color.medGreen,
      transform: 'rotate(180deg)',
    },
    arrowDown: {
      color: styles.color.medRed,
    },
  },
  light: {
    value: {
      color: styles.color.purple,
    },
    title: {
      color: styles.color.black,
    },
  },
  dark: {
    value: {
      color: styles.color.lightBlue,
    },
    title: {
      color: styles.color.white,
    },
  },
});

export default TopList;
