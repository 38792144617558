import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl, defineMessages } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Card from 'views/components/layout/Card';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { PolyScore } from 'views/components/PolyScore';
import { SmallCopyButton } from 'views/components/CopyText/CopyTextButton';
import Icon from 'views/components/Icon';
import Loader from 'views/components/Loader';
import ContactSalesForFeature from 'views/components/ContactSalesForFeature';
import styles from 'views/styles';
import usePagination from 'hooks/usePagination';
import { ListPagination, EPageDirection } from './ListPagination';

import useScanHistory, { ScanHistory as ScanHistoryType } from './hooks/useScanHistory';
import useHasFeature from 'hooks/useHasFeature';

interface Props {
  sticky?: boolean;
  sha256: string;
  activeInstanceId: string;
  setActiveInstanceId: (id: string) => void;
}

const messages = defineMessages({
  contactSalesTitle: {
    id: 'scanPage.scanHistory.contactSales.title',
    defaultMessage: 'There are {availableResults}+ results available',
  },
  contactSalesDescription: {
    id: 'scanPage.scanHistory.contactSales.description',
    defaultMessage:
      'To see the remaining results contact sales to add the scan history feature to your plan.',
  },
});

const ListItem = ({
  item,
  activeInstanceId,
  setActiveInstanceId,
  isLatestInstance = false,
  includesBorder = false,
}: {
  item: ScanHistoryType;
  activeInstanceId: string;
  setActiveInstanceId: (id: string) => void;
  isLatestInstance?: boolean;
  includesBorder?: boolean;
}) => {
  const { classes, theme } = useStyles();
  const activeColor = theme === 'dark' ? styles.color.darkBlack : styles.color.xxxLightGrey;

  const timestamp = formatDate(parseDate(item.timestamp), DATE_TIME_FORMAT);
  return (
    <div
      css={classes.listContainer}
      style={{
        borderBottom: includesBorder ? `1px solid ${activeColor}` : 'none',
        background: item.id === activeInstanceId ? activeColor : 'inherit',
      }}
      onClick={() => setActiveInstanceId(item.id)}
    >
      <div css={classes.itemList}>
        {timestamp}
        {isLatestInstance && <Icon css={classes.iconStar} name='star' />}
        {item.hasBeenSandboxed && <Icon css={classes.iconShovel} name='shovel' />}
      </div>
      <div css={classes.polyscoreContainer}>
        <PolyScore
          classNumber={classes.polyscore}
          polyscore={item.polyscore}
          bar={false}
          iconSize='tiny'
        />
      </div>
      <div>
        {`${item.id.substring(0, 10)}...`}
        <SmallCopyButton text={item.id} />
      </div>
      <div css={classes.textEllipsis}>{item.malwareFamily}</div>
    </div>
  );
};

function ScanHistory({ sticky = false, sha256, activeInstanceId, setActiveInstanceId }: Props) {
  const intl = useIntl();
  const { classes } = useStyles();
  const { hasFeature } = useHasFeature('all_scans');
  const { data, more, availableResults, fetchNextPage, loading, error } = useScanHistory({
    hash: sha256,
    shouldFetch: true,
  });
  const [firstItem] = data;
  const { isFirst, isLast, offset, limit, onChangePage, onChangeRowsPerPage } = usePagination({
    defaultTotal: data.length,
    defaultLimit: 5,
    defaultOffset: 0,
  });
  const paginatedData = data.slice(offset, offset + limit);

  return (
    <Card
      css={classes.cardContainer}
      style={{
        flexGrow: sticky ? 1 : 0,
        overflow: sticky ? 'scroll' : 'hidden',
      }}
    >
      <div css={classes.titleContainer}>
        <span css={classes.title}>Scan History</span>
      </div>
      {error ? (
        <div css={classes.error}>{error}</div>
      ) : (
        <>
          <div style={{ overflow: 'scroll' }}>
            {loading && (
              <div css={classes.centered}>
                <Loader css={classes.loader} />
              </div>
            )}
            {!hasFeature && !loading && data.length && (
              <ListItem
                key={firstItem.id}
                item={firstItem}
                activeInstanceId={activeInstanceId}
                isLatestInstance={true}
                setActiveInstanceId={setActiveInstanceId}
              />
            )}
            {hasFeature &&
              !loading &&
              paginatedData.map((item, i) => (
                <ListItem
                  key={item.id}
                  item={item}
                  activeInstanceId={activeInstanceId}
                  isLatestInstance={item.id === firstItem.id}
                  setActiveInstanceId={setActiveInstanceId}
                  includesBorder={i !== paginatedData.length - 1}
                />
              ))}
          </div>
          <div css={classes.paginationContainer}>
            <ListPagination
              isFirst={isFirst}
              isLast={(isLast && !more) || !hasFeature}
              offset={offset}
              total={hasFeature ? data.length : availableResults}
              rowsPerPage={hasFeature ? limit : 1}
              hasMore={more}
              onChangePage={(direction) => {
                if (direction === EPageDirection.NEXT && isLast && more) {
                  fetchNextPage();
                }
                onChangePage(direction);
              }}
            />
            {hasFeature && !sticky && (
              <button type='button' onClick={() => onChangeRowsPerPage(limit === 5 ? 50 : 5)}>
                <Icon
                  css={{
                    ...classes.iconArrow,
                    transform: limit === 5 ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                  name='arrow'
                />
              </button>
            )}
          </div>
        </>
      )}
      {!hasFeature && (
        <ContactSalesForFeature feature='scan-history'>
          <div css={classes.contactSalesContainer}>
            <Icon css={classes.iconHistory} name='history' />
            <div>
              <span css={classes.contantTitle}>
                {intl.formatMessage(messages.contactSalesTitle, { availableResults })}
              </span>
              <p>{intl.formatMessage(messages.contactSalesDescription)}</p>
            </div>
          </div>
        </ContactSalesForFeature>
      )}
    </Card>
  );
}

const useStyles = makeStyles({
  base: {
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '800px',
    },
    titleContainer: {
      padding: '2rem 2rem 1.5rem 2rem',
    },
    title: {
      fontWeight: 'bold',
    },
    listContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 12rem',
      gridTemplateRows: 'auto auto',
      gridColumnGap: '1rem',
      gridRowGap: '1.5rem',
      alignItems: 'center',
      padding: '1.2rem 2rem',
      cursor: 'pointer',
    },
    itemList: {
      fontWeight: 'bold',
      display: 'flex',
      gap: '1rem',
      alignItems: 'self-start',
    },
    iconStar: {
      fontSize: '0.6rem !important',
    },
    paginationContainer: {
      padding: '1.5rem 2rem 2rem 2rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    iconArrow: {
      fontSize: '0.4rem !important',
    },
    iconShovel: {
      fontSize: '0.8rem !important',
    },
    polyscoreContainer: {
      textAlign: 'right',
    },
    polyscore: {
      fontSize: '1.6rem',
      textAlign: 'right',
    },
    loader: {
      fontSize: '0.35em !important',
      display: 'inline-flex',
      marginLeft: '1rem',
    },
    centered: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '9rem 0',
    },
    contactSalesContainer: {
      display: 'flex',
      gap: '3rem',
      marginBottom: '2rem',
      marginTop: '-2rem',
    },
    iconHistory: {
      fontSize: '4.5rem !important',
      height: '1.4em !important',
    },
    contantTitle: {
      fontWeight: 'bold',
    },
    textEllipsis: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'right',
    },
    error: {
      padding: '2rem',
      textAlign: 'center',
      color: styles.color.red,
    },
  },
  light: {
    title: {
      color: styles.color.purple,
    },
    iconStar: {
      color: styles.color.purple,
    },
    iconArrow: {
      color: styles.color.xLightGrey,
    },
    iconShovel: {
      color: styles.color.purple,
    },
    iconHistory: {
      color: styles.color.purple,
    },
  },
  dark: {
    title: {
      color: styles.color.lightBlue,
    },
    iconStar: {
      color: styles.color.lightBlue,
    },
    iconArrow: {
      color: styles.color.xLightGrey,
    },
    iconShovel: {
      color: styles.color.lightBlue,
    },
    iconHistory: {
      color: styles.color.lightBlue,
    },
  },
});
export default ScanHistory;
