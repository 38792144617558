import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import Button from '@material-ui/core/Button';
import Modal from 'views/components/Modal';
import StatusIcon, { IStatusIcon } from 'views/components/StatusIcon';

interface Props {
  state: IStatusIcon['status'];
  value?: number | string | null;
  heading: string;
  text: string;
  buttonText: string;
  onClick: () => void;
  dataCy?: string;
}

const StatusModal = ({ state, value, heading, text, buttonText, onClick, dataCy }: Props) => (
  <Modal>
    <div css={ownStyle} data-cy={dataCy}>
      <StatusIcon status={state} value={value} />
      <h3 className='h3 h-mb-tiny'>{heading}</h3>
      <p className='p'>{text}</p>
      <Button
        className='h-mt-xs'
        color='primary'
        variant='contained'
        onClick={onClick}
        data-cy='statusModalBtn'
      >
        {buttonText}
      </Button>
    </div>
  </Modal>
);

const ownStyle = css`
  text-align: center;
  padding: ${styles.spacing.md};
  max-width: 54rem;
  margin: 0 auto;
`;

export default StatusModal;
