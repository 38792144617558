import { Action } from 'redux';
import { ModalName } from 'views/components/Modal/ModalConductor';

export enum ModalActionName {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export interface OpenModalAction<T = {}> extends Action<ModalActionName.OPEN_MODAL> {
  name: ModalName;
  params: T;
}

export interface CloseModalAction extends Action<ModalActionName.CLOSE_MODAL> {}

export type ModalAction = OpenModalAction | CloseModalAction;
