import { useState } from 'react';
import api from 'services/api';

export const useDevelopmentCommunity = () => {
  const [developmentCommunity, setDevelopmentCommunity] = useState<any>({});

  const getDevelopmentCommunity = async (communityName: string) => {
    const response = await api.getDevelopmentCommunity(communityName);
    setDevelopmentCommunity(response.data);
  };

  return { getDevelopmentCommunity, developmentCommunity };
};
