import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { getPolyScoreResult, getPolyScoreColor } from '..';
import styles from 'views/styles';
import { floatToPercentage } from 'utils/conversions/conversions';
import { getPolyScoreWidth } from '../PolyScore';

interface IPolyScoreBar {
  className?: string;
  polyscore: number | null;
  color?: boolean;
  size?: 'small' | 'large';
}

export const PolyScoreBar = ({ className, polyscore, color, size = 'small' }: IPolyScoreBar) => {
  const { classes } = useStyles({ polyscore, color, size });
  const result = getPolyScoreResult(polyscore);
  return (
    <div className={className} css={classes.root} data-testid={`polyscore-${result}`}>
      <div css={classes.bar} />
    </div>
  );
};

const useStyles = makeStyles(({ polyscore, color, size }: IPolyScoreBar) => {
  const backgroundColor = color ? getPolyScoreColor(polyscore) : undefined;
  let width = polyscore ? floatToPercentage(polyscore) : '0%';
  width = size === 'small' ? getPolyScoreWidth(polyscore) : width;
  return {
    base: {
      root: {
        position: 'relative',
        width: '100%',
        height: '0.8rem',
        display: 'flex',
        justifyContent: 'space-between',
      },
      bar: {
        width,
        height: '100%',
      },
    },
    light: {
      root: {
        backgroundColor: styles.color.xxLightGrey,
      },
      bar: {
        backgroundColor: backgroundColor || styles.color.purple,
      },
    },
    dark: {
      root: {
        backgroundColor: styles.color.lightGrey,
      },
      bar: {
        backgroundColor: backgroundColor || styles.color.lightBlue,
      },
    },
  };
});
