import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-assertions' viewBox='0 0 32 32'>
      <path d='M18.667 25.333c0 0-0.133 0 0 0-0.667 0-1.067-0.4-1.2-0.8l-6.667-15.467-2.267 6.8c-0.533 1.733-2 2.8-3.733 2.8h-2.133c-0.8 0-1.333-0.533-1.333-1.333s0.533-1.333 1.333-1.333h2.133c0.533 0 1.067-0.4 1.2-0.933l3.333-10.133c0.267-0.533 0.667-0.933 1.333-0.933 0.533 0 1.067 0.267 1.333 0.8l7.067 16.4 3.2-4.8c0.8-1.067 2-1.733 3.333-1.733h3.733c0.8 0 1.333 0.533 1.333 1.333s-0.533 1.333-1.333 1.333h-3.867c-0.4 0-0.8 0.267-1.067 0.533l-4.533 6.933c-0.4 0.267-0.8 0.533-1.2 0.533z' />
    </symbol>

    <Global
      styles={css`
        .icon-assertions {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
