import { Action } from 'redux';
import { LocaleState } from 'utils/constants';
import { ThemeState } from 'models/Theme';

export enum SettingsActionName {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  CHANGE_THEME = 'CHANGE_THEME',
}

export interface ChangLanguageAction extends Action<SettingsActionName.CHANGE_LANGUAGE> {
  locale: LocaleState;
}

export interface ChangeThemeAction extends Action<SettingsActionName.CHANGE_THEME> {
  theme: ThemeState;
}

export type SettingsAction = ChangLanguageAction | ChangeThemeAction;
