import api from 'services/api';
import request from 'state/utils/request';
import { ETagsActionName } from './types';

/**
 * Request emerging threats
 */
export const getEmergingThreats = (params: { threats?: number; families?: number }) =>
  request(() => api.getEmergingThreats(params).then((res) => res.data), {
    type: ETagsActionName.GET_EMERGING_THREATS,
  });
