import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import { useIntl, defineMessages } from 'react-intl';
import { translateError, ErrorKeys } from 'utils/error';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { HuntingActionName } from 'state/hunting/types';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import { createRuleset, updateRuleset } from 'state/hunting/actions';
import api from 'services/api';
import { CancelToken } from 'axios';
import { RulesetValues, Ruleset } from 'models/Ruleset';
import { PageQuery } from 'models/Page';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import Modal from 'views/components/Modal';
import RulesetsForm from '../forms/RulesetsForm';

export interface RulesetModalParams {
  id?: string;
  values?: RulesetValues;
  refresh?: boolean;
  queryParams: PageQuery<Ruleset>;
}

const messages = defineMessages({
  createHeading: {
    id: 'rulesets.modal.createHeading',
    defaultMessage: 'Create Ruleset',
  },
  updateHeading: {
    id: 'rulesets.modal.updateHeading',
    defaultMessage: 'Modify Ruleset',
  },
});

const errorKeys: ErrorKeys = {};

/**
 * The CreateRulesetModal component
 */
const CreateRulesetModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { requests } = useSelector((state: RootState) => state);

  const { event: gaEvent } = useAnalytics();

  const isLoading = loadingSelector(requests, [
    HuntingActionName.CREATE_RULESET,
    HuntingActionName.UPDATE_RULESET,
  ]);

  const error = errorSelector(requests, [
    HuntingActionName.CREATE_RULESET,
    HuntingActionName.UPDATE_RULESET,
  ]);

  const { id, values, refresh, queryParams } = useSelector(
    ({ modal }: { modal: ModalState<RulesetModalParams> }) => modal.params
  );

  const _updateRuleset = (id: string, values: RulesetValues, queryParams: PageQuery<Ruleset>) =>
    dispatch(updateRuleset(id, values, queryParams));

  const _createRuleset = (
    values: RulesetValues,
    queryParams: PageQuery<Ruleset>,
    refresh?: boolean
  ) => {
    dispatch(createRuleset(values, queryParams, refresh));
    gaEvent({
      category: 'Hunting',
      action: 'Ruleset Added',
    });
  };

  const _handleValidateRules = (value: string, cancelToken?: CancelToken) => {
    const file = new File([value], 'rules.yar');
    return api.validateRuleset(file, cancelToken);
  };

  return (
    <Modal fullWidth={true} maxWidth='xl'>
      <RulesetsForm
        initialValues={values}
        css={ownStyle}
        heading={intl.formatMessage(id ? messages.updateHeading : messages.createHeading)}
        buttonText={intl.formatMessage(btnMessages.save)}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        onValidateRules={_handleValidateRules}
        onSubmit={(values) =>
          id
            ? _updateRuleset(id, values, queryParams)
            : _createRuleset(values, queryParams, refresh)
        }
        onCancel={() => dispatch(closeModal())}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
`;

export default CreateRulesetModal;
