import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import HowItWorksTabPanel from './HowItWorksTabPanel';

interface IHowItWorksIntro {
  heading: string;
  text: string;
}

const HowItWorksIntro = ({ heading, text }: IHowItWorksIntro) => {
  const { classes } = useStyles();
  return (
    <div css={classes.root}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div css={classes.introWrap}>
              <div css={classes.intro}>
                <h2 css={classes.heading} className='h3'>
                  {heading}
                </h2>
                <p className='p'>{text}</p>
              </div>
              <HowItWorksTabPanel />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    introWrap: {
      padding: `${styles.spacing.xl} 0`,
      maxWidth: '120rem',
      margin: '0 auto',
    },
    intro: {
      textAlign: 'center',
      maxWidth: '64.8rem',
      margin: `0 auto ${styles.spacing.md}`,
    },
  },
  light: {
    root: {
      backgroundColor: styles.color.white,
    },
    heading: {
      color: styles.color.purple,
    },
  },
  dark: {
    root: {
      backgroundColor: styles.color.darkOffBlack,
    },
    heading: {
      color: styles.color.lightBlue,
    },
  },
});

export default HowItWorksIntro;
