import React, { useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useIntl } from 'react-intl';
import { btnMessages } from 'views/components/Button';
import { ICON_REGISTRY } from 'views/components/Icon/IconBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ActionBtn from 'views/components/table/ActionBtn';
import styles from 'views/styles';

interface IContextMenu {
  className?: string;
  style?: React.CSSProperties;
  icon?: keyof typeof ICON_REGISTRY;
  label?: string;
  options: ContextMenuOption[];
  testId?: string;
}

interface ContextMenuOption {
  isShown?: boolean;
  label: string;
  disabled?: boolean;
  type?: 'primary' | 'danger';
  onClick: () => void;
  testId?: string;
}

const ContextMenu = ({ className, style, icon, label, options, testId }: IContextMenu) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();

  const open = Boolean(anchorEl);

  const _handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

  const _handleClose = () => setAnchorEl(null);

  const _handleMenuItemClick = (option: ContextMenuOption) => {
    option.onClick();
    _handleClose();
  };

  return (
    <span className={className} style={style}>
      <ActionBtn
        name={icon || 'more'}
        aria-label={label || intl.formatMessage(btnMessages.more)}
        aria-owns={open ? 'context-menu' : undefined}
        aria-haspopup='true'
        onClick={_handleClick}
        data-testid={testId}
      />
      <Menu
        id='context-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={_handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 'auto',
            maxWidth: 300,
          },
        }}>
        {options.map(
          (option) =>
            option.isShown !== false && (
              <MenuItem
                disabled={option.disabled}
                key={option.label}
                css={[
                  ownStyle.base,
                  option.type && option.type !== 'primary' && ownStyle[option.type],
                ]}
                onClick={() => (option.disabled ? null : _handleMenuItemClick(option))}
                data-testid={option.testId}>
                <Typography variant='inherit' noWrap>
                  {option.label}
                </Typography>
              </MenuItem>
            )
        )}
      </Menu>
    </span>
  );
};

const ownStyle = {
  base: css`
    font-size: 1.5rem !important;
  `,
  danger: css`
    color: ${styles.color.red} !important;
  `,
};

export default ContextMenu;
