import { createInitialPagination, buildPaginatedData } from 'utils/pagination';
import uniqBy from 'lodash/uniqBy';
import { ApiKeyData } from 'models/ApiKey';
import { ApiKeysAction, ApiKeysActionName } from './types';
import { ApiKeysState } from '.';

export const initialApiKeyPagination = createInitialPagination<ApiKeyData>({
  direction: 'desc',
});

export const initialState: ApiKeysState = initialApiKeyPagination;

const apiKey = (state: ApiKeysState = initialState, action: ApiKeysAction): ApiKeysState => {
  switch (action.type) {
    case ApiKeysActionName.REFRESH_API_KEYS: {
      return action.result ? buildPaginatedData(action.result, action.result.results) : state;
    }

    case ApiKeysActionName.GET_API_KEYS: {
      if (!action.result) {
        return state;
      } else {
        const result = buildPaginatedData(action.result, action.result.results);
        const stateResults = state.results ?? [];
        return {
          ...result,
          results: uniqBy([...stateResults, ...result.results], 'id'),
        };
      }
    }

    case ApiKeysActionName.CLEAR_API_KEYS_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default apiKey;
