import React, { useState, FC } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from '../Icon';

interface IAccordion {
  className?: string;
  title: string;
  helpText?: string;
  openByDefault?: boolean;
  dataCy?: string;
  onOpen?: () => void;
}

const Accordion: FC<IAccordion> = ({
  className,
  title,
  helpText,
  openByDefault,
  dataCy,
  children,
  onOpen,
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault || false);

  const { classes } = useStyles(isOpen);

  const _toggleOpen = () => {
    !isOpen && onOpen && onOpen();
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <div
      id={title.toLowerCase().split(' ').join('_')}
      className={className}
      css={classes.root}
      data-cy={dataCy}>
      <div css={classes.headingWrap}>
        <div css={classes.heading}>
          <h3 onClick={_toggleOpen}>{title}</h3>
          {helpText && (
            <Tooltip title={helpText} placement='top'>
              <div css={classes.info} tabIndex={0}>
                <Icon name='info' />
              </div>
            </Tooltip>
          )}
        </div>
        <button css={classes.button} onClick={_toggleOpen} data-cy='accordionToggle'>
          <Icon name='arrow' title='toggle' />
        </button>
      </div>
      <div css={classes.drawer}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles((isOpen: boolean) => ({
  base: {
    root: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: styles.border.radius,
      padding: styles.spacing.xs,
    },
    headingWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: styles.font.weight.bold,
    },
    info: {
      cursor: 'help',
      fontSize: '0.5rem',
      marginLeft: styles.spacing.tiny,
    },
    button: {
      cursor: 'pointer',
      fontSize: '0.32rem',
      padding: '0.5rem',
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
    },
    drawer: {
      display: isOpen ? 'block' : 'none',
      paddingTop: styles.spacing.xs,
    },
  },
  light: {
    root: {
      borderColor: styles.border.color.grey,
      backgroundColor: styles.color.white,
    },
    heading: {
      color: styles.color.black,
    },
    info: {
      color: styles.color.xLightGrey,
    },
    button: {
      color: styles.color.xLightGrey,
      '&:hover': {
        color: styles.color.purple,
      },
    },
  },
  dark: {
    root: {
      borderColor: styles.border.color.darkPurple,
      backgroundColor: styles.color.darkXBlack,
    },
    heading: {
      color: styles.color.white,
    },
    info: {
      color: styles.color.xLightGrey,
    },
    button: {
      color: styles.color.xLightGrey,
      '&:hover': {
        color: styles.color.lightBlue,
      },
    },
  },
}));

export default Accordion;
