import React, { FC, ReactNode } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl } from 'react-intl';
import { errorMessages, ErrorKeys, getErrorType, RequestError, translateError } from 'utils/error';
import { Filter } from 'views/components/utils';
import ErrorPageContent, { messages } from 'views/components/error/ErrorPageContent';
import Button from '@material-ui/core/Button';
import PanelLoader from 'views/components/Loader/PanelLoader';

export interface ISharedPlacholderProps {
  children?: ReactNode;
  loaderScale?: number;
  errorScale?: number;
  renderError?: ErrorRenderer;
  renderLoader?: () => JSX.Element;
  onReload: () => void;
}

interface IPlaceholder extends ISharedPlacholderProps {
  isLoading: boolean;
  error?: RequestError;
}

type ErrorRenderer = { [key: string]: JSX.Element };

const errorKeys: ErrorKeys = {
  rate_limit_exceeded: errorMessages.rateLimit.id,
};

const Placeholder: FC<IPlaceholder> = (props) => {
  const { isLoading, error, loaderScale = 0.75, errorScale = 1, renderLoader, children } = props;
  return (
    <div css={style.root}>
      {error && <PlaceholderError {...props} error={error} errorScale={errorScale} />}
      <div css={style.loader(isLoading)}>
        {renderLoader ? renderLoader() : <PanelLoader css={style.loaderDefault(loaderScale)} />}
      </div>
      <div css={style.content(isLoading, !!error)}>
        {typeof children === 'function' ? children() : children}
      </div>
    </div>
  );
};

interface IPlaceholderErrorProps extends IPlaceholder {
  error: RequestError;
  errorScale: number;
}

const PlaceholderError = ({ error, errorScale, renderError, onReload }: IPlaceholderErrorProps) => {
  const intl = useIntl();
  const errorType = error && getErrorType(error);

  if (errorType && renderError && Object.keys(renderError).includes(errorType)) {
    return <Filter currentState={errorType} states={renderError} />;
  }

  return (
    <ErrorPageContent
      css={style.error(errorScale)}
      heading={intl.formatMessage(messages.headingReload)}
      text={translateError(intl, errorKeys, error) || intl.formatMessage(messages.textReload)}
    >
      <Button color='primary' variant='contained' onClick={onReload}>
        {intl.formatMessage(messages.buttonReload)}
      </Button>
    </ErrorPageContent>
  );
};

const style = {
  root: css`
    position: relative;
  `,
  loader: (isLoading: boolean) => css`
    display: ${isLoading ? 'block' : 'none'};
  `,
  loaderDefault: (loaderScale: number) => css`
    font-size: ${loaderScale}rem;
  `,
  error: (errorScale: number) => css`
    transform: scale(${errorScale});
  `,
  content: (isLoading: boolean, error: boolean) => css`
    display: ${error || isLoading ? 'none' : 'block'};
  `,
};

export default Placeholder;
