import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import ability from 'config/ability';
import { btnMessages } from 'views/components/Button';
import { combinedUserActions } from 'views/components/providers/UserProvider';
import { ReactComponent as RulesetImg } from 'assets/images/ruleset.svg';
import PanelContent from 'views/components/layout/PanelContent';
import RulesetsTab from './RulesetsTab';
import { useUser } from 'views/components/providers/UserProvider';
import AppLoader from 'views/components/request/AppLoader';

const messages = defineMessages({
  text: {
    id: 'rulesetsTab.loggedOut.text',
    defaultMessage: 'Upgrade to an Enterprise plan today to begin hunting!',
  },
});

const RulesetsTabContainer = () => {
  const intl = useIntl();
  const history = useHistory();
  const { refreshUser } = useUser();
  return (
    <AppLoader loadingActions={combinedUserActions} onReload={refreshUser}>
      {() =>
        ability.can('live_hunt', 'Artifact') || ability.can('historical_hunt', 'Artifact') ? (
          <RulesetsTab />
        ) : (
          <PanelContent
            imageComponent={<RulesetImg />}
            heading={intl.formatMessage({ id: 'rulesetsTab.rulesetHeading' })}
            text={intl.formatMessage(messages.text)}
            buttons={[
              {
                text: intl.formatMessage(btnMessages.upgrade),
                onClick: () => history.push('/pricing'),
                testId: 'rulesetUpgradeBtn',
              },
            ]}
          />
        )
      }
    </AppLoader>
  );
};

export default RulesetsTabContainer;
