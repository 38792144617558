import React, { FC, Fragment, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { ObjectInterpolation } from '@emotion/core';
import semiCircleImg from './semi-circle.svg';
import semiCircleImgDark from './semi-circle-dark.svg';
import styles from 'views/styles';
import Header from 'views/components/layout/Header/HeaderContainer';
import Footer from 'views/components/layout/Footer/FooterContainer';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import usePrivateCommunityTheme from 'hooks/usePrivateCommunityTheme';

interface IBaseLayout {
  variant?: 'transparent';
}

const BaseLayout: FC<IBaseLayout> = ({ variant, children }) => {
  const { classes } = useStyles({ variant });
  usePrivateCommunityTheme();

  return (
    <div css={classes.root}>
      <div css={classes.content}>
        <div css={classes.inner}>
          <Header css={classes.header} />
          {children}
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

const transparentAfter = (imgUrl: string): ObjectInterpolation<any> => ({
  content: '""',
  display: 'block',
  background: `url(${imgUrl}) no-repeat center right`,
  backgroundSize: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '50%',
  height: '100%',
  zIndex: -1,
  [`@media (max-width: ${styles.breakpoint.lg})`]: {
    width: 585,
  },
});

const transparentInner = (): ObjectInterpolation<any> => ({
  maxWidth: '128rem',
  margin: '0 auto',
  [`@media (min-width: ${styles.breakpoint.xl})`]: {
    maxWidth: '160rem',
  },
});

const useStyles = makeStyles(({ variant }: IBaseLayout) => ({
  base: {
    root: {
      [`@media (max-width: ${styles.breakpoint.xl})`]: {
        minWidth: styles.breakpoint.windowMin,
      },
    },
    content: {
      position: 'relative',
      minHeight: '100vh',
      overflowY: 'hidden',
      paddingBottom: '8rem',
      backgroundImage: `var(--community-background-image, none)`,
      transition: 'background-color 0.3s ease-in-out',
    },
    inner: variant === 'transparent' ? transparentInner() : {},
    footer: {
      marginTop: '-8rem',
    },
  },
  light: {
    content: {
      backgroundColor:
        variant === 'transparent'
          ? 'var(--community-background, transparent)'
          : `var(--community-background, ${styles.color.offWhite})`,
      '&:after': variant === 'transparent' && transparentAfter(semiCircleImg),
    },
    header: {
      backgroundColor: variant === 'transparent' ? 'transparent' : styles.color.white,
    },
  },
  dark: {
    content: {
      backgroundColor:
        variant === 'transparent'
          ? 'var(--community-background, transparent)'
          : `var(--community-background, ${styles.color.darkBlack})`,
      '&:after': variant === 'transparent' && transparentAfter(semiCircleImgDark),
    },
    header: {
      backgroundColor: variant === 'transparent' ? 'transparent' : styles.color.darkXBlack,
    },
  },
}));

export default BaseLayout;
