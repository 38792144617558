const shortenFilename = (filename: string) => {
  if (filename.length <= 25) return filename;

  if (filename.indexOf('.') === -1) {
    const firstHalf = filename.substr(0, 10);
    const secondHalf = filename.substr(-10);
    return `${firstHalf}...${secondHalf}`;
  }

  const fileArr = filename.split('.');
  let fileExt = fileArr.splice(-1)[0];
  const file = fileArr.join('.');

  if (fileExt.length > 5) {
    fileExt = `${fileExt.substr(0, 5)}...`;
  }

  if (file.length <= 20) {
    return `${file}.${fileExt}`;
  }

  const firstHalf = file.substr(0, 10);
  const secondHalf = file.substr(-10);
  return `${firstHalf}...${secondHalf}.${fileExt}`;
};

export default shortenFilename;
