import React, { useCallback, useMemo, Fragment, useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import BaseLayout from 'views/components/layout/BaseLayout';
import useIsPrivateContext from 'hooks/useIsPrivateContext';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import { format } from 'date-fns';
import has from 'lodash/has';
import kebabCase from 'lodash/kebabCase';
import api from 'services/api';
import { ManualAnalysis as IManualAnalysis } from 'models/Submission';
import SEO from 'views/components/layout/SEO';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Panel from 'views/components/layout/Panel';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'views/components/Icon';
import styles from 'views/styles';
import ScanSearchInput from 'views/components/FileUpload/ScanSearchInput';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { FileState, FileUpload } from 'views/components/FileUpload';
import PrivacyTerms from 'views/components/FileUpload/PrivacyTerms';
import useFormBuilder, { ELEMENT_TYPE } from 'components/FormBuilder';
import useNotification from 'hooks/useNotification';
import { DateTimePicker } from './DateTimePicker';
import { getErrorMessage } from 'utils/error';
import { useUser } from 'views/components/providers/UserProvider';

const fileUploadMessages = {
  hashText: {
    input: 'Enter a Hash',
  },
  urlText: {
    input: 'Enter a URL',
  },
};

const ManualAnalysisTabs = ({
  setTabId,
  tabId,
  setFile,
  setHashURLValue,
  modalShareData,
}: {
  setTabId: (id: string) => void;
  tabId: string;
  setFile: (file: FileState | null) => void;
  setHashURLValue: (value: { [K: string]: string }) => void;
  modalShareData: boolean;
}) => {
  return (
    <>
      <Tabs centered indicatorColor='primary' value={tabId} onChange={(_, id) => setTabId(id)}>
        <Tab
          value='file'
          label='File'
          onClick={() => {
            setFile(null);
          }}
        />
        <Tab value='hash' label='Hash' />
        <Tab value='url' label='URL' />
      </Tabs>
      <Panel>
        {tabId === 'file' && (
          <FileUpload
            hasHeading={false}
            isScan={false}
            isModal={true}
            onFileInput={(file) => {
              setFile(file);
            }}
            modalShareData={modalShareData}
            messages={fileUploadMessages}
          />
        )}
        {(tabId === 'hash' || tabId === 'url') && (
          <ScanSearchInput
            key={tabId}
            onlyHash={tabId === 'hash'}
            onlyUrl={tabId === 'url'}
            messages={tabId === 'hash' ? fileUploadMessages.hashText : fileUploadMessages.urlText}
            onChange={(inputValue) => {
              setHashURLValue({ type: tabId, value: inputValue });
            }}
          />
        )}
      </Panel>
    </>
  );
};

const FORMAT_RESPONSE_OPTIONS = ['Report', 'Short Blurb', 'Presentation', 'Verbal Form'];
const PRIMARY_AUDIENCE_OPTIONS = ['Executives', 'Incident Responders', 'SOC Analyst'];
const PURPOSE_OF_ANALYSIS_OPTIONS = ['Incident Response', 'General Inquiry', 'Unknown File'];

type hashURLTypes = 'url' | 'file' | 'hash' | 'qr';

const defaultValues = {
  format: '',
  audience: '',
  purpose: '',
  description: null,
};

type ManualBuilderProps = {
  format: string;
  audience: string;
  purpose: string;
  description: string;
};

const isInputFilled = (inputs: ManualBuilderProps) => {
  const defaults = { ...defaultValues };
  return Object.keys(defaults).some((key) => !has(inputs, key));
};

const uniqueUploadDoneID = () => uniqueId('fileUpload_');

function ManualAnalysis() {
  const { classes } = useStyles();
  const { manualAnalysisContextId: contextId } = useUser();
  const [tabId, setTabId] = useState('file');
  const [hashURLValue, setHashURLValue] = React.useState<{
    type?: hashURLTypes;
    value?: string;
  }>({});
  const [file, setFile] = useState<FileState | null>(null);
  const [selectedTier, setSelectedTier] = useState<'tier-3' | 'tier-4'>('tier-3');
  const [dateTime, setDateTime] = useState<Date | null>(null);
  const [uploadDoneID, setUploadDoneID] = useState<string>(() => uniqueUploadDoneID());
  const isPrivateContext = useIsPrivateContext();
  const notification = useNotification();

  const configForm = useMemo(
    () => [
      {
        elementType: ELEMENT_TYPE.select,
        id: 'format',
        placeholder: 'Select the format of response*',
        defaultValue: '',
        defaultOptions: FORMAT_RESPONSE_OPTIONS,
      },
      {
        elementType: ELEMENT_TYPE.select,
        id: 'audience',
        placeholder: 'Select primary audience*',
        defaultValue: '',
        defaultOptions: PRIMARY_AUDIENCE_OPTIONS,
      },
      {
        elementType: ELEMENT_TYPE.select,
        id: 'purpose',
        placeholder: 'Select purpose of analysis*',
        defaultValue: '',
        defaultOptions: PURPOSE_OF_ANALYSIS_OPTIONS,
      },
      {
        elementType: ELEMENT_TYPE.textarea,
        id: 'description',
        placeholder: 'Describe your manual analysis requirements*',
        defaultValue: '',
        maxChars: 500,
        rules: {
          pattern: /^[A-Za-z0-9.,_()\[\]\-\s;?:"'!\n]+$/, // eslint-disable-line
        },
      },
    ],
    []
  );

  const { FormComponent } = useFormBuilder(configForm);

  const onHandleSumbit = async (
    data: { format: string; audience: string; purpose: string; description: string },
    clearForm: () => void
  ) => {
    let uploaded;

    try {
      if (!!file && !!file?.file) {
        uploaded = await api.store3Steps(file.file, file.zipConfig, file.isQRCode, contextId);
      } else if (!hashURLValue.value) {
        throw new Error('No file uploaded');
      } else if (hashURLValue.type === 'url') {
        uploaded = (await api.storeUrl(hashURLValue.value, contextId))?.data;
      } else if (hashURLValue.type !== 'hash') {
        throw new Error('No file uploaded');
      }

      const utcDateTime = format(dateTime as Date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

      await api.manualAnalysis(
        Object.assign(
          {},
          {
            tier: selectedTier,
            responseTime: utcDateTime,
            format: kebabCase(data.format?.toLowerCase()),
            audience: data.audience?.toLowerCase(),
            purpose: data.purpose?.toLowerCase(),
            description: data.description,
          },
          !!uploaded && { instanceId: uploaded.result.id },
          hashURLValue.type === 'hash' && { sha256: hashURLValue.value }
        ) as IManualAnalysis,
        contextId
      );

      notification.success(
        'Your manual analysis request has been submitted. The analyst will be in touch soon.'
      );

      clearForm();
      setFile(null);
      setTabId('file');
      setDateTime(null);
      setHashURLValue({});
      setUploadDoneID(uniqueUploadDoneID());
    } catch (e: unknown) {
      const errorMessage = getErrorMessage(e as Error);
      notification.failure(errorMessage);
    }
  };

  return (
    <Fragment>
      <SEO title='Manual Analysis' />
      <BaseLayout variant={isPrivateContext ? undefined : 'transparent'}>
        <div css={classes.container}>
          <h2 className='h2'>Manual Analysis</h2>
          <div css={classes.manualInput}>
            <ManualAnalysisTabs
              key={uploadDoneID}
              setTabId={setTabId}
              tabId={tabId}
              setFile={setFile}
              setHashURLValue={setHashURLValue}
              modalShareData={false}
            />
          </div>
          <FormComponent
            headerRender={() => {
              return (
                <>
                  <div
                    style={{
                      alignSelf: 'start',
                    }}
                  >
                    <h6 className='h6'>Select Manual Analysis Tier</h6>
                    <div style={{ alignItems: 'center', display: 'flex', width: 'fit-content' }}>
                      <Radio
                        checked={selectedTier === 'tier-3'}
                        color='primary'
                        id='tier3'
                        onClick={() => setSelectedTier('tier-3')}
                      />
                      <label htmlFor='tier3'>Tier 3</label>
                      <Tooltip title={'Human Verified - 24hrs response'} placement='top'>
                        <div css={classes.info} tabIndex={0}>
                          <Icon name='info' />
                        </div>
                      </Tooltip>
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', width: 'fit-content' }}>
                      <Radio
                        checked={selectedTier === 'tier-4'}
                        color='primary'
                        id='tier4'
                        onClick={() => setSelectedTier('tier-4')}
                      />
                      <label htmlFor='tier4'>Tier 4</label>
                      <Tooltip title={'Detailed Analysis - 72hrs response'} placement='top'>
                        <div css={classes.info} tabIndex={0}>
                          <Icon name='info' />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <DateTimePicker
                    label='Response Time*'
                    value={dateTime}
                    onChange={(value) => {
                      if (!!value) {
                        setDateTime(value);
                      }
                    }}
                  />
                </>
              );
            }}
            isFormInclusive
            onSubmit={(
              data: { format: string; audience: string; purpose: string; description: string },
              clearForm: () => void
            ) => onHandleSumbit(data, clearForm)}
            footerRender={({ dirtyFields, formState }) => {
              return (
                <div css={classes.footerContainer}>
                  <Button
                    disabled={
                      formState.isSubmitting ||
                      (isEmpty(file) && isEmpty(hashURLValue.value)) ||
                      isEmpty(dateTime?.toString()) ||
                      isInputFilled(dirtyFields)
                    }
                    css={classes.button}
                    type='submit'
                    color='primary'
                    variant='contained'
                  >
                    {formState.isSubmitting ? <CircularProgress color='inherit' /> : 'Submit'}
                  </Button>
                  <PrivacyTerms />
                </div>
              );
            }}
          />
        </div>
      </BaseLayout>
    </Fragment>
  );
}

const useStyles = makeStyles({
  base: {
    container: {
      margin: '24px auto',
      minWidth: 'max-content',
      width: '50%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '3rem',
      padding: '4rem 0',
    },
    textarea: {
      width: '100%',
      padding: '2rem 1rem',
      borderRadius: '1rem',
      border: `1px solid ${styles.color.xxLightGrey}`,
      color: styles.color.black,
      background: styles.color.xxLightPurple,
      minHeight: '10rem',
      maxHeight: '10rem',
      resize: 'none',
      overflowY: 'auto',
    },
    textareaContainer: {
      position: 'relative',
    },
    textareaChars: {
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      fontSize: '1.2rem',
      color: styles.color.xLightGrey,
    },
    button: {
      width: 'max-content',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '3rem',
    },
    info: {
      cursor: 'help',
      fontSize: '0.5rem',
      marginLeft: styles.spacing.tiny,
    },
    manualInput: {
      padding: '0 48px',
      paddingBottom: 24,
    },
  },
  light: {
    manualInput: {
      background: styles.color.white,
      border: `1px solid ${styles.color.xxLightGrey}`,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    info: {
      color: styles.color.xLightGrey,
    },
  },
  dark: {
    manualInput: {
      background: styles.color.xxDarkPurple,
      border: `1px solid ${styles.color.xDarkPurple}`,
      boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.15)',
    },
    info: {
      color: styles.color.xLightGrey,
    },
  },
});
export default ManualAnalysis;
