import React, { Fragment, useState, useEffect, FC, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { debounce } from 'lodash';
import { isAuthenticated as isAuth, isPasswordUser as isPass } from 'state/auth/selectors';
import {
  hasMadeScopedMFARecently,
  login,
  logout,
  removeMFAData,
  removeMFALastAction,
  renewSession,
} from 'state/auth/actions';
import { handleTeamInvitation } from 'state/invitation/actions';
import SEO from 'views/components/layout/SEO';
import AuthLoader from 'views/components/auth/AuthLoader';
import SimpleLayout from 'views/components/layout/SimpleLayout';
import CenteredContent from 'views/components/layout/CenteredContent';

export interface AuthProps {
  isAuthenticated: boolean;
  isPasswordUser: boolean;
  login: (lastLocation?: string) => void;
  logout: (lastLocation?: string) => void;
}

interface StateProps {
  isLoggedIn: boolean;
  isAuthenticated: boolean;
  isPasswordUser: boolean;
  inviteToken?: string;
}

const messages = defineMessages({
  loading: {
    id: 'general.loading',
    defaultMessage: 'Loading…',
  },
  loginHeading: {
    id: 'authProvider.login.heading',
    defaultMessage: 'One moment',
  },
  loginText: {
    id: 'authProvider.login.text',
    defaultMessage: 'Redirecting you to the login page…',
  },
  logoutHeading: {
    id: 'authProvider.logout.heading',
    defaultMessage: 'Logging out',
  },
  logoutText: {
    id: 'authProvider.logout.text',
    defaultMessage: 'Please wait while we log you out…',
  },
});

const initialAuthValues: AuthProps = {
  isAuthenticated: false,
  isPasswordUser: false,
  login: () => {},
  logout: () => {},
};

/**
 * App-level auth context
 */
export const AuthContext = React.createContext<AuthProps>(initialAuthValues);

/**
 * Auth context hook.
 */
export const useAuth = () => useContext(AuthContext);

/**
 * The AuthProvider component
 *
 * Provides authorization context to child components
 */
const AuthProvider: FC = ({ children }) => {
  const [state, setState] = useState({
    isLoggingIn: false,
    isLoggingOut: false,
  });

  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isLoggedIn, isAuthenticated, isPasswordUser, inviteToken } = useSelector<
    RootState,
    StateProps
  >(({ auth }: RootState) => ({
    isLoggedIn: auth.isLoggedIn,
    isAuthenticated: isAuth(auth),
    isPasswordUser: isPass(auth),
    inviteToken: auth.inviteToken,
  }));

  const { isLoggingIn, isLoggingOut } = state;

  const _login = (lastLocation?: string) => {
    setState({ ...state, isLoggingIn: true });
    removeMFAData();
    removeMFALastAction();
    dispatch(login(lastLocation));
  };

  const _logout = (lastLocation?: string) => {
    setState({ ...state, isLoggingOut: true });
    removeMFAData();
    removeMFALastAction();
    dispatch(logout(lastLocation));
  };

  const _checkSession = () => {
    if (isLoggedIn) {
      if (isAuthenticated) {
        dispatch(renewSession());
      } else {
        _logout(location.pathname);
      }
    }
  };

  const _checkInvitation = () => {
    if ((isAuthenticated && typeof inviteToken === 'string') || (window as any).invitationState) {
      dispatch(handleTeamInvitation(location.pathname));
    }
  };

  // Only runs on mount since values come from persisted state
  useEffect(() => {
    if (!hasMadeScopedMFARecently()) {
      // If MFA was made recently, there is no need to check the session
      _checkSession();
    }
    _checkInvitation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reload = debounce(() => {
    const _path = '/';
    if (window.location.href !== _path) {
      window.location.href = _path;
    }
  }, 1000);

  const handleStorageEvent = (event: any) => {
    if (event.oldValue === event.newValue) {
      return;
    }

    if (['persist:auth', 'persist:account'].indexOf(event.key) === -1) {
      return;
    }

    const oldValue = JSON.parse(event.oldValue);
    const newValue = JSON.parse(event.newValue);

    if (event.key === 'persist:auth') {
      if (oldValue.isLoggedIn === newValue.isLoggedIn) {
        return;
      }
    }

    if (event.key === 'persist:account') {
      const oldContext = JSON.parse(oldValue.context);
      const newContext = JSON.parse(newValue.context);
      if (oldContext?.accountNumber === newContext?.accountNumber) {
        return;
      }
    }

    reload();
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isPasswordUser,
        login: _login,
        logout: _logout,
      }}>
      {isLoggingIn || isLoggingOut ? (
        <Fragment>
          <SEO title={intl.formatMessage(messages.loading)} />
          <SimpleLayout withHeader={false}>
            <CenteredContent>
              <AuthLoader
                dataCy='authLoader'
                style={{ marginTop: '-5rem' }}
                heading={
                  isLoggingIn
                    ? intl.formatMessage(messages.loginHeading)
                    : intl.formatMessage(messages.logoutHeading)
                }
                text={
                  isLoggingIn
                    ? intl.formatMessage(messages.loginText)
                    : intl.formatMessage(messages.logoutText)
                }
              />
            </CenteredContent>
          </SimpleLayout>
        </Fragment>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
