import archiver, { Archiver } from 'archiver';
// @ts-ignore
import archiverZipEncryptable from 'archiver-zip-encrypted';
import concatStream from 'concat-stream';
import { saveAs } from 'file-saver';
import axios from 'axios';
import toBuffer from 'blob-to-buffer';

archiver.registerFormat('files-encrypted', archiverZipEncryptable);

/**
 * Creates an encrypted zip file with the content yielded by the functions
 * passed. The password of the zip is 'infected'.
 * @param zipName the name of the zip file created
 * @param filesFunc array of async functions, each one has to return a promise
 *        that contains inside the name of the file, and a buffer with the file content.
 * @return the `Archiver` object returned has a method `finalize()`
 *         that has to be called to trigger the creation of the zip file, that will
 *         be downloaded by the browser after call it.
 */
export const zipArchive = async (
  zipName: string,
  filesFunc: (() => Promise<{ path: string; buffer: Buffer }>)[]
): Promise<Archiver> => {
  // @ts-ignore
  const archive = archiver('files-encrypted', {
    zlib: { level: 3 },
    encryptionMethod: 'aes256',
    password: 'infected',
  });

  archive.pipe(
    concatStream({ encoding: 'buffer' }, (buffer: any) => {
      saveAs(new Blob([buffer]), zipName);
    })
  );

  while (filesFunc.length) {
    // 5 at a time
    const files = await Promise.all(filesFunc.splice(0, 5).map((f) => f()));
    for (const file of files) {
      archive.append(file.buffer, { name: file.path });
    }
  }
  return archive;
};

/**
 * Download the content from the URL passed and store it
 * into the buffer returned.
 */
export const downloadContent = async (url: string): Promise<Buffer> => {
  const res = await axios.get(url, { responseType: 'blob' });
  return new Promise((resolve, reject) => {
    toBuffer(res.data, (err: any, buffer: any) => {
      // Blob -> Buffer
      if (err) {
        return reject(err);
      }
      return resolve(buffer);
    });
  });
};

export const fileToBinary = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const binaryData = reader.result;
      resolve(binaryData as ArrayBuffer);
    };
    reader.onerror = () => {
      reject(new Error('Unable to read the file as binary data'));
    };
    reader.readAsArrayBuffer(file);
  });
};

/**
 * The function `_checkIsZipFile` uses FileReader to check if a given file is a ZIP file by comparing
 * its signature.
 * @param {File} file - The `file` parameter in the `_checkIsZipFile` function is of type `File`, which
 * represents a file from the file system.
 * @returns The `_checkIsZipFile` function returns a Promise that resolves to a boolean value indicating
 * whether the provided file is a ZIP file or not.
 */
export function _checkIsZipFile(file: File): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const buffer = new Uint8Array(e.target!.result as ArrayBuffer);
      const signature = [0x50, 0x4b, 0x03, 0x04];

      for (let i = 0; i < signature.length; i++) {
        if (buffer[i] !== signature[i]) {
          resolve(false);
          return;
        }
      }

      resolve(true);
    };

    reader.onerror = function () {
      reject(new Error('Failed to read file'));
    };

    reader.readAsArrayBuffer(file.slice(0, 4));
  });
}

export async function checkIsZipFile(file: File) {
  const isZipFile = await _checkIsZipFile(file);

  return isZipFile && file.name.toLowerCase().endsWith('.zip');
}
