import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import styles from 'views/styles';
import SearchField from 'views/components/form/SearchField';
import SearchTextArea from 'views/components/form/SearchTextArea';

interface IHashSearchField {
  label: string;
  textAreaLabel?: string;
  isLoading: boolean;
  initialValue?: string;
  isInitialMulti?: boolean;
  onSubmit: (value: string, multi?: boolean) => void;
}

const messages = defineMessages({
  multi: {
    id: 'hashSearchField.multi',
    defaultMessage: 'Search Multiple',
  },
});

const HashSearchField = ({
  label,
  textAreaLabel,
  isLoading,
  initialValue,
  isInitialMulti,
  onSubmit,
}: IHashSearchField) => {
  const [isMulti, setIsMulti] = useState(false);

  const intl = useIntl();

  const { classes } = useStyles();

  const _handleToggleMulti = () => setIsMulti((isMulti) => !isMulti);

  useEffect(() => {
    if (isInitialMulti) {
      setIsMulti(isInitialMulti);
    }
  }, [isInitialMulti]);

  return (
    <div css={classes.root}>
      <FormControlLabel
        css={classes.switch}
        labelPlacement='start'
        control={
          <Switch
            color='primary'
            onChange={_handleToggleMulti}
            checked={isMulti}
            data-cy='hashSearchSwitch'
          />
        }
        label={intl.formatMessage(messages.multi)}
      />
      {isMulti ? (
        <SearchTextArea
          dataCy='hashSearchTextArea'
          label={textAreaLabel || label}
          monospace={true}
          initialValue={initialValue}
          isInitialOpen={isInitialMulti ? false : true}
          isLoading={isLoading}
          onSubmit={(value) => onSubmit(value, true)}
        />
      ) : (
        <SearchField
          dataCy='hashSearchField'
          label={label}
          initialValue={initialValue}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      position: 'relative',
      paddingTop: styles.spacing.xs,
    },
    switch: {
      position: 'absolute',
      transform: 'scale(0.75)',
      transformOrigin: 'center right',
      top: 0,
      right: 0,
    },
  },
});

export default HashSearchField;
