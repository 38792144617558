import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { Redirect } from 'react-router-dom';
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import styles from 'views/styles';
import Loader from 'views/components/Loader';
import ScanSearchInput from 'views/components/FileUpload/ScanSearchInput';
import styled from '@emotion/styled';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { parseScanPageURL } from 'views/url';

interface Props {
  isLoading: boolean;
  fileUploaded: boolean;
  loadingHeading: string;
  errorHeading: string;
  errorMessage?: string;
  errorType?: 'error' | 'primary';
  uuid: string | null;
  submissionId?: string;
  query: string;
  type?: string;
  renderFailureBtn: () => JSX.Element;
  onCancel: () => void;
}

const Heading = styled.h2<{ error?: boolean }>`
  text-align: center;
  font-weight: 600;
  font-size: 5rem;
  line-height: 1.2;
  padding-bottom: 1rem;
`;

const StyledLoader = styled(Loader)`
  font-size: 1rem;
  padding: 3rem;
`;
const ScanSearchingState = (props: Props) => {
  const { event: gaEvent } = useAnalytics();
  const { isLoading, fileUploaded, errorMessage, uuid, submissionId, type } = props;

  if (isLoading) {
    return <LoadingState {...props} />;
  } else if (!errorMessage && !uuid) {
    return <LoadingState {...props} loadingHeading='Waiting for scan results...' />;
  } else if (!errorMessage && fileUploaded && uuid) {
    gaEvent({
      category: 'Search',
      action: 'Hash Search',
    });

    if (type === 'url') {
      return (
        <Redirect
          push
          to={{
            pathname: parseScanPageURL(uuid, { type: 'url' }),
            state: { search: true, submissionId },
          }}
        />
      );
    } else {
      return (
        <Redirect
          push
          to={{ pathname: parseScanPageURL(uuid), state: { search: true, submissionId } }}
        />
      );
    }
  }

  return <ErrorState {...props} />;
};

const LoadingState = ({ loadingHeading, query, onCancel }: Props) => (
  <div css={style.root} data-cy='scanSearchingLoading'>
    <Heading>{loadingHeading}</Heading>
    <StyledLoader />
    <ScanSearchInput disabled={true} initialValue={query} onCancel={onCancel} />
  </div>
);

const ErrorState = ({
  errorHeading,
  errorMessage,
  errorType = 'error',
  query,
  renderFailureBtn,
}: Props) => {
  const { classes } = useStyles(errorType === 'error');

  return (
    <div css={style.root} data-cy='scanSearchingError'>
      <Heading css={classes.heading}>{errorHeading}</Heading>
      <p className='p h-mb-sm'>{errorMessage}</p>
      <ScanSearchInput disabled={true} initialValue={query} />
      {renderFailureBtn()}
    </div>
  );
};

const style = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    text-align: center;
  `,
};

const useStyles = makeStyles((error: boolean) => ({
  base: {
    heading: {
      color: error ? styles.color.red : styles.color.grey,
    },
  },
  light: {
    heading: {
      color: error ? styles.color.red : styles.color.grey,
    },
  },
  dark: {
    heading: {
      color: error ? styles.color.red : styles.color.white,
    },
  },
}));

export default ScanSearchingState;
