import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import styled from '@emotion/styled';
import { DATE_TIME_FORMAT } from 'utils/constants';
import { NormalizedArtifactMetadata } from 'models/Submission';
import styles from 'views/styles';
import { useIntl } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import ActionBtn from 'views/components/table/ActionBtn';
import { btnMessages } from 'views/components/Button';
import BaseHoverableTableRow from 'views/components/table/HoverableTableRow';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { PolyScore } from 'views/components/PolyScore';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { parseScanPageURL } from 'views/url';
import { useDispatch } from 'react-redux';
import { openModal } from 'state/modal/actions';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';

interface IMetadataSearchTableRow {
  rowData: NormalizedArtifactMetadata;
  viewScanResult?: (hash: string) => void;
  downloadArtifact: (hash: string) => void;
  columns: string[];
}

const HoverableTableRow = styled(BaseHoverableTableRow)`
  cursor: pointer;
`;

const HashValue = styled.div`
  font-family: ${styles.font.family.mono};
  font-size: 1.3rem;
  white-space: nowrap;
  text-align: left;
  line-height: 1.5;
`;

const CopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const Actions = styled.div`
  white-space: nowrap;
`;

const MetadataSearchTableRow = ({
  rowData,
  downloadArtifact,
  columns,
}: IMetadataSearchTableRow) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const isCommunityPlan = useIsCommunityPlan();

  const reportTitle = isCommunityPlan
    ? 'Generate Report is not available for Community Plan users'
    : 'Generate Report';

  const { filesize, filetype } = rowData.metadata?.exiftool || {};

  const columnsComponents = {
    sha256: (
      <TableCell>
        <HashValue data-testid='metadataSearchResultLink'>
          {`${rowData.sha256.substring(0, 20)}…`}
          <CopyButton text={rowData.sha256} />
        </HashValue>
      </TableCell>
    ),
    malware_family: (
      <TableCell>
        {rowData.polyunite_malware_family
          ? rowData.polyunite_malware_family
          : intl.formatMessage({ id: 'placeholder.none' })}
      </TableCell>
    ),
    submitted: (
      <TableCell>
        {rowData.submitted
          ? formatDate(parseDate(rowData.submitted), DATE_TIME_FORMAT)
          : intl.formatMessage({ id: 'placeholder.none' })}
      </TableCell>
    ),
    last_seen: (
      <TableCell>
        {rowData.last_seen
          ? formatDate(parseDate(rowData.last_seen), DATE_TIME_FORMAT)
          : intl.formatMessage({ id: 'placeholder.none' })}
      </TableCell>
    ),
    polyscore: (
      <TableCell>
        <PolyScore
          iconSize='small'
          classNumber={classes.polyscore}
          polyscore={rowData.latest_scan_polyscore}
          bar={false}
        />
      </TableCell>
    ),
    detections: rowData.detections?.total ? (
      <TableCell>
        {rowData.detections.malicious}/{rowData.detections.total}
      </TableCell>
    ) : (
      <TableCell>{intl.formatMessage({ id: 'placeholder.none' })}</TableCell>
    ),
    file_size: <TableCell>{filesize}</TableCell>,
    file_type: <TableCell>{filetype}</TableCell>,
  } as { [key: string]: React.ReactNode };

  return (
    <Fragment>
      <HoverableTableRow
        dataCy='metadataSearchTableRow'
        renderCells={() => (
          <Fragment>
            {columns.map((column, index) => (
              <Fragment key={index}>{columnsComponents[column]}</Fragment>
            ))}
            <TableCell align='right'>
              <Actions>
                <ActionBtn
                  name='open-view'
                  aria-label={intl.formatMessage(btnMessages.info)}
                  onClick={() =>
                    window.open(
                      parseScanPageURL(rowData.sha256, {
                        type: 'file',
                        instanceId: rowData.latest_scan.artifact_instance_id,
                      }),
                      '_blank'
                    )
                  }
                />
                <ActionBtn
                  disabled={isCommunityPlan}
                  style={{ marginLeft: 12 }}
                  name='generate-report'
                  title={reportTitle}
                  aria-label={reportTitle}
                  onClick={() => {
                    dispatch(
                      openModal('GENERATE_REPORT', {
                        type: 'scan',
                        id: rowData.latest_scan.artifact_instance_id,
                      })
                    );
                  }}
                />
                <ActionBtn
                  name='download'
                  style={{ marginLeft: 12 }}
                  title={intl.formatMessage(btnMessages.download)}
                  aria-label={intl.formatMessage(btnMessages.download)}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    downloadArtifact(rowData.sha256);
                    return false;
                  }}
                  data-testid='metadataSearchDownloadBtn'
                />
              </Actions>
            </TableCell>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    polyscore: {
      fontSize: '1.6rem',
      textAlign: 'left',
    },
  },
  light: {},
  dark: {},
});

export default MetadataSearchTableRow;
