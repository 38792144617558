import React, { useState, useRef, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import CopyToClipboard from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import styled from '@emotion/styled';

interface ICopyTextButton {
  className?: string;
  label?: string;
  text: string;
  notification?: boolean;
  alignNotification?: 'right' | 'left';
}

const CopyTextButton = ({ notification = true, ...props }: ICopyTextButton) => {
  const { className, alignNotification } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [hasResult, setHasResult] = useState(false);

  const { classes } = useStyles({ isOpen, alignNotification });

  const _openTimeout = useRef<number>();
  const _resultTimeout = useRef<number>();

  const _clearAllTimeouts = () => {
    _openTimeout.current && clearTimeout(_openTimeout.current);
    _resultTimeout.current && clearTimeout(_resultTimeout.current);
  };

  useEffect(() => _clearAllTimeouts, []);

  const _onCopy = (_: string, hasResult: boolean) => {
    _clearAllTimeouts();
    setIsOpen(true);
    setHasResult(hasResult);
    _openTimeout.current = window.setTimeout(() => setIsOpen(false), 1200);
    _resultTimeout.current = window.setTimeout(() => setHasResult(false), 1500);
  };

  return (
    <div className={className} css={classes.root}>
      <CopyButton {...props} isOpen={isOpen} onCopy={_onCopy} />
      {notification && <CopyNotification {...props} isOpen={isOpen} hasResult={hasResult} />}
    </div>
  );
};

const CopyButton = ({
  isOpen,
  alignNotification,
  label,
  text,
  onCopy,
}: ICopyTextButton & { isOpen: boolean; onCopy: (_: string, hasResult: boolean) => void }) => {
  const { classes } = useStyles({ isOpen, alignNotification });
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <div css={classes.content}>
        <Icon css={classes.icon} name='copy' title='Copy' />
        {label && <span css={classes.label}>{label}</span>}
      </div>
    </CopyToClipboard>
  );
};

const CopyNotification = ({
  isOpen,
  hasResult,
  alignNotification,
}: ICopyTextButton & { isOpen: boolean; hasResult: boolean }) => {
  const { classes } = useStyles({ isOpen, alignNotification });
  return (
    <div css={classes.notification}>
      {hasResult ? (
        <FormattedMessage id='common.copy.copied' defaultMessage='Copied' />
      ) : (
        <FormattedMessage id='common.copy.copyerror' defaultMessage='Could not copy' />
      )}
    </div>
  );
};

export const SmallCopyButton = styled(CopyTextButton)`
  font-size: 0.4rem;
  margin-left: 0.6rem;
  vertical-align: middle;
`;

const useStyles = makeStyles(
  ({ isOpen, alignNotification }: { isOpen: boolean; alignNotification?: 'right' | 'left' }) => ({
    base: {
      root: {
        cursor: 'pointer',
        position: 'relative',
        display: 'inline-block',
        whiteSpace: 'nowrap',
      },
      icon: {
        fontSize: 'inherit',
        verticalAlign: 'middle',
      },
      label: {
        fontWeight: styles.font.weight.medium,
        marginLeft: '0.5rem',
        fontSize: '2.4em',
        verticalAlign: 'middle',
      },
      notification: {
        pointerEvents: 'none',
        opacity: isOpen ? 1 : 0,
        position: 'absolute',
        top: '95%',
        left: alignNotification === 'left' ? 'auto' : '1.2rem',
        right: alignNotification === 'left' ? '1.2rem' : 'auto',
        background: styles.color.grey,
        color: styles.color.white,
        borderRadius: styles.border.radius,
        padding: '0.9rem 1.1rem',
        fontSize: '1.1rem',
        fontWeight: styles.font.weight.medium,
        lineHeight: '1.1rem',
        letterSpacing: 1,
        transition: `opacity ${styles.easing.time} ${styles.easing.main}`,
        zIndex: 999,
      },
    },
    light: {
      content: {
        color: styles.color.purple,
      },
      notification: {
        background: styles.color.grey,
        color: styles.color.white,
      },
    },
    dark: {
      content: {
        color: styles.color.lightBlue,
      },
      notification: {
        background: styles.color.xxxLightGrey,
        color: styles.color.black,
      },
    },
  })
);

export default CopyTextButton;
