import MUITab from '@material-ui/core/Tab';
import { Route as RRoute, Redirect } from 'react-router-dom';
import customPages from 'tenants/custom-pages';
import tenant from 'tenants';
import { Pages } from './config';
import { useUser } from 'views/components/providers/UserProvider';

export const Tab = ({
  show,
  ...props
}: React.ComponentProps<typeof MUITab> & { show?: boolean }) => {
  if (show === false) return <span />;
  return <MUITab {...props} />;
};

export const Route = ({
  show,
  fallbackUrl = '/404',
  ...props
}: React.ComponentProps<typeof RRoute> & { show?: boolean; fallbackUrl?: string }) => {
  const user = useUser();
  const { component, path } = props;
  const page = typeof path === 'string' ? path.split('/')[1] : '';
  const shouldRedirectToLogin =
    user.id === -1 &&
    tenant.isLogingRequired &&
    !tenant.publicPages?.includes(page as Pages) &&
    (tenant.enabledPages === 'all' || tenant.enabledPages?.includes(page as Pages)) &&
    !tenant.disabledPages?.includes(page as Pages);

  const finalComponent =
    customPages && customPages[page as Pages] ? customPages[page as Pages] : component;

  if (show === false) return <Redirect to={shouldRedirectToLogin ? '/login' : fallbackUrl} />;
  if (typeof finalComponent === 'string') {
    window.location.href = finalComponent;
    return null;
  }

  return <RRoute {...props} path={path} component={finalComponent} />;
};
