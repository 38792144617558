import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  heading429: {
    id: 'page.error.429.heading',
    defaultMessage: 'Usage limit is reached',
  },
  heading408: {
    id: 'page.error.408.heading',
    defaultMessage: 'Execution time exceeded.',
  },
  headingReload: {
    id: 'page.error.reload.heading',
    defaultMessage: 'Page failed to load',
  },
  textReload: {
    id: 'page.error.reload.text',
    defaultMessage: 'Something went wrong. Try to load the page again.',
  },
  somethingWrong: {
    id: 'page.error.somethingWrong.text',
    defaultMessage: 'Something went wrong. Please try again.',
  },
  buttonReload: {
    id: 'page.error.reload.button',
    defaultMessage: 'Try again',
  },
  heading404: {
    id: 'page.error.404.heading',
    defaultMessage: 'Page not found',
  },
  text404: {
    id: 'page.error.404.text',
    defaultMessage:
      "We can't find the URL you're looking for. Let's try going back to the homepage.",
  },
  button404: {
    id: 'page.error.404.button',
    defaultMessage: 'Go to the homepage',
  },
  heading500: {
    id: 'page.error.500.heading',
    defaultMessage: 'Internal Server Error',
  },
  text500: {
    id: 'page.error.500.text',
    defaultMessage:
      'An error has occurred and we’re working to fix the problem. We’ll be up and running shortly. If you need immediate help – contact support.',
  },
  button500: {
    id: 'page.error.500.button',
    defaultMessage: 'Go to the homepage',
  },
});
