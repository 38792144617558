import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import { useUser } from 'views/components/providers/UserProvider';
import Card from 'views/components/layout/Card';
import { TeamAvatar } from 'views/components/Avatar';

interface Props {
  className?: string;
  heading: string;
  text?: string | null;
  teamName: string;
  accountNumber: string;
  renderContextMenu?: () => JSX.Element;
  children: React.ReactNode;
}

const AvatarSidebar = ({
  className,
  heading,
  text,
  teamName,
  accountNumber,
  renderContextMenu,
  children,
}: Props) => {
  const user = useUser();

  const isPrivateCommunity = user.context?.isPrivate;

  return (
    <Card className={className} css={style.card}>
      <TeamAvatar
        isPrivate={!!isPrivateCommunity}
        accountNumber={accountNumber}
        teamName={teamName}
      />
      <div css={style.cardInner}>
        <div css={style.titleWrap}>
          <h4 className='h4' data-cy='avatarSidebarHeading'>
            {heading}
          </h4>
          {typeof renderContextMenu === 'function' && renderContextMenu()}
        </div>
        <p className='h-mt-tiny h-mb-xs p1' data-cy='avatarSidebarText'>
          {text}
        </p>
        {children}
      </div>
    </Card>
  );
};

const style = {
  card: css`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 350px;
    padding: ${styles.spacing.sm} 0;
  `,
  cardInner: css`
    margin-top: 8%;
    flex: 1 0 auto;
    padding: 0 ${styles.spacing.xs} ${styles.spacing.sm};
    h3 {
      font-size: 2.4rem;
    }
  `,
  titleWrap: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
};

export default AvatarSidebar;
