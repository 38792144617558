import React, { ReactNode, FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootState, RootAction } from 'state/root';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, errorMessages, ErrorKeys, getErrorType } from 'utils/error';
import Button from '@material-ui/core/Button';
import ErrorPageContent, { messages } from 'views/components/error/ErrorPageContent';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { Filter } from '../utils';

type ErrorRenderer = { [key: string]: JSX.Element };

interface IAppLoader {
  loadingActions: RootAction['type'][];
  errorActions?: RootAction['type'][];
  children?: ReactNode;
  loader?: ReactNode;
  renderError?: ErrorRenderer;
  onReload?: () => void;
}

const errorKeys: ErrorKeys = {
  rate_limit_exceeded: errorMessages.rateLimit.id,
};

const AppLoader: FC<IAppLoader> = ({
  loader,
  renderError,
  loadingActions,
  errorActions,
  onReload,
  children,
}) => {
  const intl = useIntl();

  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, loadingActions);
  const error = errorSelector(requests, errorActions || loadingActions);
  const errorType = error && getErrorType(error);

  if (errorType && renderError && Object.keys(renderError).includes(errorType)) {
    return <Filter currentState={errorType} states={renderError} />;
  }

  if (error && onReload) {
    return (
      <ErrorPageContent
        className='h-mt-lg h-mb-md'
        heading={intl.formatMessage(messages.headingReload)}
        text={translateError(intl, errorKeys, error) || intl.formatMessage(messages.textReload)}>
        <Button
          style={{ fontSize: '2.4rem' }}
          color='primary'
          variant='contained'
          onClick={onReload}>
          <FormattedMessage {...messages.buttonReload} />
        </Button>
      </ErrorPageContent>
    );
  }

  if (loader !== null && isLoading) {
    if (loader) {
      return typeof loader === 'function' ? loader() : loader;
    } else {
      return <PanelLoader />;
    }
  }

  return typeof children === 'function' ? children() : children;
};

export default AppLoader;
