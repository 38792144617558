import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-list' viewBox='0 0 32 32'>
      <path d='M25.333 6.667v18.667h-18.667v-18.667h18.667zM26.8 4h-21.6c-0.667 0-1.2 0.533-1.2 1.2v21.6c0 0.533 0.533 1.2 1.2 1.2h21.6c0.533 0 1.2-0.667 1.2-1.2v-21.6c0-0.667-0.667-1.2-1.2-1.2zM14.667 9.333h8v2.667h-8v-2.667zM14.667 14.667h8v2.667h-8v-2.667zM14.667 20h8v2.667h-8zM9.333 9.333h2.667v2.667h-2.667zM9.333 14.667h2.667v2.667h-2.667zM9.333 20h2.667v2.667h-2.667z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-list {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
