import React, { useCallback, useEffect } from 'react'; // eslint-disable-line
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Dispatch } from 'state/types/thunk';

import SimpleLayout from 'views/components/layout/SimpleLayout';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';
import { RootState } from 'state/root';
import { getSelf, getUserAccount } from 'state/user/actions';
import { getAccountContext } from 'state/account/actions';
import qs from 'query-string';

export const SwitchAccountPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const ctx = contextAccount(store);
  const user = useSelector((store: RootState) => store.user);
  const location = useLocation();

  const _refreshUser = useCallback(async () => {
    await dispatch(getSelf());
    await dispatch(getUserAccount());
    await dispatch(getAccountContext(ctx));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _refreshUser()
      .then(() => {
        const { next } = qs.parse(location.search);
        const nextPath = next ? next : '/';
        history.replace(nextPath);
      });
  }, [user.profile, _refreshUser, history, location]);

  return <SimpleLayout withHeader={false}></SimpleLayout>;
};
