import React, { Fragment } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl, defineMessages } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';
import PricingPlans from './PricingPlans/PricingPlans';

const messages = defineMessages({
  title: {
    id: 'pricingPage.title',
    defaultMessage: 'Pricing',
  },
});

const PricingPage = () => {
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <ScrollToTopOnMount />
        <PricingPlans />
      </BaseLayout>
    </Fragment>
  );
};

export default PricingPage;
