import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import MalwareTable from './MalwareTable';
import { getEmergingThreats } from 'state/tags/actions';
import { RootState } from 'state/root';
import { Threat, ThreatArtifact } from 'models/Tag';
import { ETagsActionName } from 'state/tags/types';
import { loadingSelector } from 'state/requests/selectors';
import { IFilter } from './Filters';

const THREATS_BATCH_SIZE = 10;

interface IMalwareTableContainerState {
  threats?: Threat[];
  isLoading: boolean;
}

const MalwareTableContainer = () => {
  const { event: gaEvent } = useAnalytics();
  const [filter, setFilter] = useState<string>('');
  const [limit, setLimit] = useState<number>(THREATS_BATCH_SIZE);

  const dispatch = useDispatch();

  const { threats, isLoading } = useSelector<RootState, IMalwareTableContainerState>(
    ({ requests, tags }) => ({
      threats: tags.threats ? tags.threats.result : [],
      isLoading: loadingSelector(requests, [ETagsActionName.GET_EMERGING_THREATS]),
    })
  );

  let artifacts: ThreatArtifact[] = [];
  if (threats) {
    const threat = threats.find((item) => item.family_name === filter);
    if (threat) {
      artifacts = threat.artifacts;
    }
  }

  const filters: IFilter[] = useMemo(() => {
    if (!threats) {
      return [];
    }

    return threats.map((item) => ({
      value: item.family_name,
      label: item.family_name,
    }));
  }, [threats]);

  useEffect(() => {
    dispatch(getEmergingThreats({ families: 6, threats: 10 }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (filters.length) {
      setFilter(filters[0].value);
    }
  }, [filters]);

  // Save selected filter value
  const onChangeFilter = (value: string) => {
    setFilter(value);
    setLimit(THREATS_BATCH_SIZE);
    gaEvent({
      category: 'Emerging Threats',
      action: 'Emerging threat selection-Widget',
    });
  };

  // Show next batch of results if there are more results
  // available then we shown
  const hasMore = artifacts && artifacts.length > limit;
  const onViewMore = () => {
    if (hasMore) {
      const nextBatchSize =
        artifacts.length - limit > THREATS_BATCH_SIZE
          ? THREATS_BATCH_SIZE
          : artifacts.length - limit;
      setLimit(limit + nextBatchSize);
    }
  };

  return (
    <MalwareTable
      data={(artifacts || []).slice(0, limit)}
      filters={filters}
      filter={filter}
      isLoading={isLoading}
      hasMore={hasMore}
      onChangeFilter={onChangeFilter}
      onViewMore={onViewMore}
    />
  );
};

export default MalwareTableContainer;
