import React, { useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { ModalState } from 'state/modal';
import { openModal } from 'state/modal/actions';
import Divider from '@material-ui/core/Divider';
import styles from 'views/styles';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import { toFixed } from 'utils/conversions/conversions';

export interface RedeemPreviewModalParams {
  mfa?: boolean;
  amount: number;
  fee: string;
  total: string;
  address: string;
  onSuccess: () => void;
}

const messages = defineMessages({
  heading: {
    id: 'rewardsTab.redeemPreview.modal.heading',
    defaultMessage: 'Redemption Preview',
  },
  button: {
    id: 'rewardsTab.redeemPreview.modal.button',
    defaultMessage: 'Confirm',
  },
  sendTo: {
    id: 'rewardsTab.redeemPreview.modal.sendTo',
    defaultMessage: 'Send to',
  },
  amountToRedeem: {
    id: 'rewardsTab.redeemPreview.modal.amountToRedeem',
    defaultMessage: 'Amount to redeem',
  },
  transactionFee: {
    id: 'rewardsTab.redeemPreview.modal.transactionFee',
    defaultMessage: 'Transaction fee',
  },
  totalSend: {
    id: 'rewardsTab.redeemPreview.modal.totalSend',
    defaultMessage: 'Total to send',
  },
});

const RedeemPreview = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { theme } = useStyles();
  const [loading, setLoading] = useState(false);
  const [error] = useState('');

  const { mfa, amount, fee, total, address, onSuccess } = useSelector(
    ({ modal }: { modal: ModalState<RedeemPreviewModalParams> }) => modal.params
  );

  return (
    <ConfirmModal
      testId='redeemPreviewModal'
      isLoading={loading}
      css={ownStyle}
      fullWidth={true}
      heading={intl.formatMessage(messages.heading)}
      content={
        <div css={containerStyle}>
          <div css={rowStyle}>
            <span className='label'>{intl.formatMessage(messages.amountToRedeem)}</span>
            <span className='amount'>
              {toFixed(total, 10)}
              <span className='nct'>NCT</span>
            </span>
          </div>
          <div css={rowStyle}>
            <span className='label'>{intl.formatMessage(messages.transactionFee)}</span>
            <span className='fee-sign'>-</span>
            <span className='amount'>
              {toFixed(fee, 10)}
              <span className='nct'>NCT</span>
            </span>
          </div>
          <Divider />
          <div css={rowStyle} className={`total total-${theme}`}>
            <span className='label'>{intl.formatMessage(messages.totalSend)}</span>
            <span className='amount'>
              {amount}
              <span className='nct'>NCT</span>
            </span>
          </div>
          <span className='sent-to'>
            {intl.formatMessage(messages.sendTo)} {address}
          </span>
          <span className='error'>{error}</span>
        </div>
      }
      submitBtnText={intl.formatMessage(messages.button)}
      disabled={loading}
      buttonsDirection='vertical'
      onSubmit={() => {
        setLoading(true);
        onSuccess();
      }}
      onCancel={() => dispatch(closeModal())}
      timeout={mfa ? 60 : 0}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
      }}
    />
  );
};

const useStyles = makeStyles();

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

const containerStyle = css`
  width: 100%;
  margin: 0 auto;
  font-size: 1.9rem;

  & .sent-to {
    font-size: 1.1rem;
    color: ${styles.color.grey};
  }

  & .error {
    display: block;
    font-size: 1.5rem;
    color: ${styles.color.red};
  }
`;

const rowStyle = css`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  & .label {
    width: 45%;
    text-align: right;
  }
  & .nct {
    font-size: 1.2rem;
    margin-left: 0.2rem;
  }
  & .fee-sign {
    flex-grow: 1;
    text-align: right;
  }
  & .amount {
    width: 45%;
    text-align: right;
  }

  &.total {
    font-weight: bold;
  }
  &.total-light {
    color: ${styles.color.purple};
  }
  &.total-dark {
    color: ${styles.color.blue};
  }
`;

export default RedeemPreview;
