import React, { useRef, useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { random } from 'lodash';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useIntl } from 'react-intl';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import Icon from 'views/components/Icon';

interface ISearchTextArea {
  label: string;
  monospace?: boolean;
  initialValue?: string;
  isLoading: boolean;
  isInitialOpen?: boolean;
  dataCy?: string;
  onSubmit: (value: string) => void;
}

/**
 * The SearchTextArea Component
 */
const SearchTextArea = ({
  label,
  monospace,
  initialValue,
  isLoading,
  isInitialOpen,
  dataCy,
  onSubmit,
}: ISearchTextArea) => {
  const [value, setValue] = useState(initialValue || '');
  const [isOpen, setIsOpen] = useState(isInitialOpen !== undefined ? isInitialOpen : true);

  const intl = useIntl();

  const { classes } = useStyles({ value: !!value, monospace, isOpen, isLoading });

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const id = `search-text-area-${random(1, 255)}`;

  useEffect(() => {
    initialValue && setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (isOpen) inputRef.current && inputRef.current.focus();
  }, [isOpen]);

  const _handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setValue(e.currentTarget.value);

  const _openDrawer = () => setIsOpen(true);

  const _handleSearch = (e: any) => {
    e.preventDefault();
    if (!value) {
      setIsOpen(true);
      inputRef.current && inputRef.current.focus();
    } else if (onSubmit) {
      setIsOpen(false);
      onSubmit(value);
    }
  };

  return (
    <form onSubmit={_handleSearch} data-cy={dataCy}>
      <div css={classes.formInner}>
        <div css={classes.labelWrap}>
          <label css={classes.label} htmlFor={id} onClick={_openDrawer}>
            {value ? `${value.substring(0, 16)}…` : label}
          </label>
          <button
            css={classes.searchBtn}
            type='button'
            onClick={_handleSearch}
            disabled={isLoading}>
            <Icon name='search' title={intl.formatMessage(btnMessages.search)} />
          </button>
        </div>
        <textarea
          ref={inputRef}
          id={id}
          rows={6}
          css={classes.textarea}
          autoFocus={true}
          value={value}
          onChange={_handleChange}></textarea>
      </div>
    </form>
  );
};

const useStyles = makeStyles(
  ({
    value,
    monospace,
    isOpen,
    isLoading,
  }: {
    value: boolean;
    monospace?: boolean;
    isOpen: boolean;
    isLoading: boolean;
  }) => ({
    base: {
      formInner: {
        paddingTop: 16,
      },
      labelWrap: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderBottomStyle: isLoading ? 'dotted' : 'solid',
        borderBottomWidth: isOpen && !isLoading ? 0 : 1,
        padding: '4px 0',
        '&:after': {
          transition:
            isOpen || isLoading ? 'none' : 'height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          content: "''",
          display: 'block',
          height: 0,
          width: '100%',
          position: 'absolute',
          bottom: -1,
          left: 0,
        },
        '&:hover:after': {
          height: isOpen || isLoading ? 0 : 2,
        },
        [`@media (min-width: ${styles.breakpoint.lg})`]: {
          padding: '0.4rem 0 0.3rem',
        },
        [`@media (min-width: ${styles.breakpoint.xxl})`]: {
          padding: '0.3rem 0 0.2rem',
        },
      },
      label: {
        height: '1.1875em',
        fontFamily: value && monospace ? styles.font.family.mono : styles.font.family.main,
        fontSize: value && monospace ? styles.font.size.p : styles.font.size.input,
        width: '100%',
        position: 'relative',
        top: 2,
      },
      textarea: {
        fontFamily: monospace ? styles.font.family.mono : styles.font.family.main,
        fontSize: monospace ? styles.font.size.p1 : styles.font.size.input,
        color: styles.color.black,
        padding: monospace ? '0.4rem' : '1rem',
        lineHeight: monospace ? 2 : 1.5,
        display: !isOpen || isLoading ? 'none' : 'inline',
        width: '100%',
        resize: 'vertical',
        borderWidth: 2,
        borderStyle: 'solid',
        borderBottomLeftRadius: styles.border.radius,
        borderBottomRightRadius: styles.border.radius,
        outline: 0,
      },
      searchBtn: {
        cursor: isLoading ? 'not-allowed' : 'pointer',
        fontSize: '0.8rem',
      },
    },
    light: {
      labelWrap: {
        borderBottomColor: 'rgba(0,0,0,0.42)',
        '&:after': {
          backgroundColor: styles.color.black,
        },
      },
      label: {
        color: styles.color.xLightGrey,
      },
      textarea: {
        background: styles.color.xxLightPurple,
        color: styles.color.black,
        borderColor: styles.color.purple,
      },
      searchBtn: {
        color: isLoading ? styles.color.xLightGrey : styles.color.purple,
      },
    },
    dark: {
      labelWrap: {
        borderBottomColor: 'rgba(255,255,255,0.7)',
        '&:after': {
          backgroundColor: styles.color.xLightGrey,
        },
      },
      label: {
        color: styles.color.xLightGrey,
      },
      textarea: {
        background: styles.color.darkOffBlack,
        color: styles.color.xxLightGrey,
        borderColor: styles.color.lightBlue,
      },
      searchBtn: {
        color: isLoading ? styles.color.xLightGrey : styles.color.lightBlue,
      },
    },
  })
);

export default SearchTextArea;
