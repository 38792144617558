import React, { FC } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styles from 'views/styles';
import SimpleHeader from './SimpleHeader';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface ISimpleLayout {
  withHeader?: boolean; // defaults to true
}

const SimpleLayout: FC<ISimpleLayout> = ({ children, withHeader }) => {
  const { classes } = useStyles();
  return (
    <div css={classes.root}>
      <div css={classes.content}>
        {withHeader !== false && <SimpleHeader css={classes.header} />}
        {children}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      [`@media (max-width: ${styles.breakpoint.xl})`]: {
        minWidth: styles.breakpoint.windowMin,
      },
    },
    header: {
      position: 'absolute',
      width: '100%',
    },
  },
  light: {
    content: {
      backgroundColor: styles.color.offWhite,
    },
  },
  dark: {
    content: {
      backgroundColor: styles.color.darkBlack,
    },
  },
});

export default SimpleLayout;
