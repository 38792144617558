import { InvitationState } from '.';
import { InvitationAction, InvitationActionName } from './types';

export const initialState: InvitationState = null;

const invitations = (
  state: InvitationState = initialState,
  action: InvitationAction
): InvitationState => {
  switch (action.type) {
    case InvitationActionName.GET_INVITATION: {
      return action.result || state;
    }

    case InvitationActionName.HANDLE_INVITATION: {
      return action.result ? initialState : state;
    }

    case InvitationActionName.REMOVE_INVITE_TOKEN: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default invitations;
