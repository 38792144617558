import React from 'react'; // eslint-disable-line
import { jsx, keyframes } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface Props {
  className?: string;
  height?: number | string;
  width?: number | string;
}

export const Skeleton = ({ className, height = 20, width = '100%' }: Props) => {
  const { classes } = useStyles();
  return <span className={className} css={classes.root} style={{ height, width }} />;
};

export const skeletonBgShine = keyframes`
  to { background-position: 120% 0; }
`;

export const skeletonGradientBg = (r: number, g: number, b: number) =>
  `linear-gradient(
    100deg,
    rgba(${r}, ${g}, ${b}, 0),
    rgba(${r}, ${g}, ${b}, 0.6) 50%,
    rgba(${r}, ${g}, ${b}, 0) 80%
  )`;

const useStyles = makeStyles({
  base: {
    root: {
      display: 'block',
      borderRadius: styles.border.radius,
      '&:after': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundSize: '50px 200px',
        backgroundPosition: '-20% 0',
        backgroundRepeat: 'repeat-y',
        animation: `${skeletonBgShine} 1.25s infinite`,
      },
    },
  },
  light: {
    root: {
      backgroundColor: styles.color.xLightPurple,
      '&:after': {
        backgroundImage: skeletonGradientBg(255, 255, 255),
      },
    },
  },
  dark: {
    root: {
      backgroundColor: styles.color.xxDarkPurple,
      '&:after': {
        backgroundImage: skeletonGradientBg(78, 70, 124),
      },
    },
  },
});

export default Skeleton;
