import { useUser } from 'views/components/providers/UserProvider';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';
import { EAccountPlans } from 'models/Account';

export default function useIsCommunityPlan() {
  const user = useUser();
  const ctx = contextAccount(store);
  if (ctx?.context === 'team') {
    return user.context?.planName === EAccountPlans.community;
  } else if (ctx?.context === 'user') {
    return user.accountPlan === EAccountPlans.community;
  }
  return false;
}
