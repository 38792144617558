import React from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { IFilterEnginesButtonProps } from './interfaces';
import Badge from '@material-ui/core/Badge';

const Wrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 25px;
`;

const StyledButton = styled(Button)`
  font-size: 1.1rem !important;
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 1.4rem;
    right: 1.4rem;
    font-size: 1rem;
    padding: 0 5px;
    min-width: 1.2rem;
    height: 1.4rem;
  }
`;

const FilterEnginesButton = ({ onClick }: IFilterEnginesButtonProps) => {
  const query = new URLSearchParams(useLocation().search);
  const engines =
    query.has('engine') && query.get('engine') !== '' ? query.get('engine')!.split(',') : [];

  return (
    <Wrapper>
      <StyledBadge badgeContent={engines.length} color='error'>
        <StyledButton variant='outlined' color='primary' size='small' onClick={onClick}>
          Filter
        </StyledButton>
      </StyledBadge>
    </Wrapper>
  );
};

export default FilterEnginesButton;
