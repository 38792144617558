import { applyMiddleware, compose, createStore } from 'redux';
import { createMemoryHistory, createBrowserHistory, History } from 'history';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { rootReducer } from 'state/root';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export let history: History;
if (process.env.NODE_ENV === 'test') {
  history = createMemoryHistory();
} else {
  history = createBrowserHistory();
}

export const reducers = rootReducer(history);

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export const persistor = persistStore(store);
