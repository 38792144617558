import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { IUpdateTeamMemberValues, TeamMember } from 'models/Team';
import { PageAttributes } from 'models/Page';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys } from 'utils/error';
import { TeamActionName } from 'state/team/types';
import { updateTeamMember } from 'state/team/actions';
import { closeModal, openModal } from 'state/modal/actions';
import styles from 'views/styles';
import Modal from 'views/components/Modal';
import UpdateTeamMemberForm from '../forms/UpdateTeamMemberForm';
import { useUser } from 'views/components/providers/UserProvider';
import { SetupMfaPanel } from 'views/components/SetupMfaPanel/SetupMfaPanel';
import { hasMadeTeamMFARecently, loginWriteTeams } from 'state/auth/actions';
import { useLocation } from 'react-router-dom';

export interface UpdateTeamMemberModalParams {
  teamAccountNumber: number | string;
  accountNumber: number;
  name: string;
  values: IUpdateTeamMemberValues;
  queryParams?: PageAttributes<TeamMember>;
}

const messages = defineMessages({
  heading: {
    id: 'teams.modal.editMemberHeading',
    defaultMessage: 'Team roles for {name}',
  },
});

const errorKeys: ErrorKeys = {};

const UpdateTeamMemberModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [TeamActionName.UPDATE_TEAM_MEMBER]);
  const error = errorSelector(requests, [TeamActionName.UPDATE_TEAM_MEMBER]);

  const { teamAccountNumber, accountNumber, name, values, queryParams } = useSelector(
    ({ modal }: { modal: ModalState<UpdateTeamMemberModalParams> }) => modal.params
  );

  const _closeModal = () => dispatch(closeModal());

  if (!user.mfaEnabled)
    return (
      <Modal>
        <SetupMfaPanel onSetup={_closeModal} onCancel={_closeModal} />
      </Modal>
    );

  if (!hasMadeTeamMFARecently()) {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({
              modal: 'UPDATE_TEAM_MEMBER',
              location: location.pathname,
              teamAccountNumber,
              accountNumber,
              name,
              values,
            })
          );
        },
      })
    );
  }

  return (
    <Modal>
      <UpdateTeamMemberForm
        css={ownStyle}
        initialValues={values}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        heading={intl.formatMessage(messages.heading, { name })}
        onSubmit={(memberValues) =>
          dispatch(updateTeamMember(accountNumber, memberValues, queryParams))
        }
        onCancel={() => dispatch(closeModal())}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default UpdateTeamMemberModal;
