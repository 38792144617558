import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface IConfirmCheckboxProps {
  value: boolean;
  onChange: (e: any) => void;
}

const Label = styled(FormControlLabel)`
  margin-left: 0px !important;
  margin-bottom: 3rem;

  & .MuiCheckbox-root {
    padding: 0px;
    margin-top: 0px;
    margin-right: 8px;
  }
  & .MuiTypography-root {
    font-weight: bold;
    font-size: 1.2rem;
  }
`;

export const ConfirmCheckbox = ({ value, onChange }: IConfirmCheckboxProps) => {
  const { classes } = useStyles();

  return (
    <div>
      <Label
        css={classes.label}
        control={
          <Checkbox
            color='primary'
            value={value}
            checked={value === true}
            onChange={() => {
              onChange(!value);
            }}
          />
        }
        label={
          <span data-testid='rewards-terms-label'>
            <FormattedMessage
              id='rewardsPanel.terms'
              defaultMessage='I accept the {terms} and {privacy}'
              values={{
                privacy: (
                  <a
                    css={classes.link}
                    href='https://polyswarm.io/privacy'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <FormattedMessage
                      id='rewardsPanel.privacy.text'
                      defaultMessage='Privacy Policy'
                    />
                  </a>
                ),
                terms: (
                  <Link to='/terms' css={classes.link}>
                    <FormattedMessage
                      id='rewardsPanel.privacy.terms'
                      defaultMessage='Terms of Service'
                    />
                  </Link>
                ),
              }}
            />
          </span>
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {
    link: {
      color: `${styles.color.purple} !important`,
    },
    label: {
      color: styles.color.black,
    },
  },
  dark: {
    link: {
      color: `${styles.color.blue} !important`,
    },
    label: {
      color: styles.color.white,
    },
  },
});
