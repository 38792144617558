import React from 'react';
import styled from '@emotion/styled';
import { TableCell } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import { Sandboxing } from 'models/Sandboxing';
import { Row } from 'views/components/table/CustomTable';
import { TablePagination, EPageDirection } from 'views/components/table/TablePagination';
import usePagination from 'hooks/usePagination';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import EmptySandboxing, { ForbiddenOptions, emptyRowData } from '../../EmptySandboxing';
import AllSandboxingTableRow from './AllSandboxingTableRow';

interface IAllSandboxingTableProps {
  results: Sandboxing[];
  more: boolean;
  refetch: () => void;
  refetchItem: (sha256: string, id: string) => void;
  accessForbidden?: ForbiddenOptions;
}

const messages = {
  sandboxedOn: 'Sandboxed On',
  sandboxTarget: 'Target',
  sandboxType: 'Type',
  sandboxProvider: 'Sandbox Provider',
  status: 'Status',
};

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AllSandboxingTable = ({
  results,
  more,
  refetch,
  refetchItem,
  accessForbidden,
}: IAllSandboxingTableProps) => {
  const intl = useIntl();
  const { isFirst, isLast, offset, limit, onChangePage, onChangeRowsPerPage } = usePagination({
    defaultTotal: results.length,
    defaultOffset: 0,
    defaultLimit: 50,
  });
  const paginatedData = results.slice(offset, offset + limit);

  const rows: Row<Sandboxing>[] = [
    {
      id: 'sandboxOn',
      numeric: false,
      label: messages.sandboxedOn,
      sortable: false,
    },
    {
      id: 'target',
      numeric: false,
      label: messages.sandboxTarget,
      sortable: false,
    },
    {
      id: 'type',
      numeric: false,
      label: messages.sandboxType,
      sortable: false,
    },
    {
      id: 'sandboxProvider',
      numeric: false,
      label: messages.sandboxProvider,
      sortable: false,
    },
    {
      id: 'status',
      numeric: false,
      label: messages.status,
      sortable: false,
    },
  ];

  return (
    <div>
      {!accessForbidden ? (
        <Toolbar>
          <Button
            component='span'
            color='primary'
            size='small'
            variant='text'
            onClick={() => refetch()}
          >
            Reload
          </Button>
        </Toolbar>
      ) : null}
      <CustomTable<Sandboxing>
        rows={accessForbidden ? emptyRowData : paginatedData}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            renderHeaderCell={(row) => (
              <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                {intl.formatMessage({ id: row.label })}
              </TableCell>
            )}
          />
        )}
        renderTableRow={(row: Sandboxing) => (
          <AllSandboxingTableRow
            refetch={refetchItem}
            blur={!!accessForbidden}
            key={row.sha256}
            rowData={row}
          />
        )}
        renderPagination={
          paginatedData.length ? (
            <TablePagination
              rowsPerPage={limit}
              offset={offset}
              total={results.length}
              rowsPerPageOptions={[5, 25, 50, 100, 200]}
              onChangePage={(direction) => {
                if (direction === EPageDirection.NEXT && isLast && more) {
                  refetch();
                }
                onChangePage(direction);
              }}
              onChangeRowsPerPage={onChangeRowsPerPage}
              isFirst={isFirst}
              isLast={isLast && !more}
              hasMore={more}
            />
          ) : undefined
        }
      />
      {accessForbidden && <EmptySandboxing type='mySandboxing' forbidden={accessForbidden} />}
    </div>
  );
};
export default AllSandboxingTable;
