import { useEffect, useState } from 'react';
import { useUser } from 'views/components/providers/UserProvider';
import { DevelopmentResult } from 'models/Microengine';
import api from 'services/api';

export const useDevelopmentResults = (engine: string | null = null) => {
  const user = useUser();
  const [developmentResults, setDevelopmentResults] = useState<DevelopmentResult[]>([]);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const accountNumber = user.context?.accountNumber || user.accountNumber;
  const refetch = () => setLoadMore(true);

  useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const response = await api.getUserDevelopmentResults(accountNumber, engine);
      if (active) {
        setDevelopmentResults(response.data);
        setLoadMore(false);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [accountNumber, loadMore, engine]);

  return {
    loading,
    refetch,
    developmentResults,
  };
};
