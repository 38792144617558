import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterEnginesButton from './FilterEnginesButton';
import styles from 'views/styles';
import styled from '@emotion/styled';
import { useDevelopmentResultsEngines } from './hooks';

const Wrapper = styled.div`
  padding: ${styles.spacing.xs};
`;

const FilterEnginesDialog = () => {
  const { engines } = useDevelopmentResultsEngines();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const engine = query.has('engine') && query.get('engine') !== '' ? query.get('engine') : null;

  const onChange = (value: string | null) => {
    if (value) {
      history.replace(`${location.pathname}?engine=${value}`);
    } else {
      history.replace(`${location.pathname}`);
    }
  };

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'enginesDialog',
  });

  return (
    <div>
      <FilterEnginesButton {...bindTrigger(popupState)} />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Wrapper>
          <Autocomplete
            value={engines.find((eng) => eng.id === engine)}
            options={engines}
            getOptionLabel={(option) => option.name}
            onChange={(_event, newValue) => {
              onChange(newValue ? newValue.id : null);
            }}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='Engines' />}
          />
        </Wrapper>
      </Popover>
    </div>
  );
};

export default FilterEnginesDialog;
