import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  optional: {
    id: 'formSchema.optional',
    defaultMessage: '(optional)',
  },
  required: {
    id: 'formSchema.required',
    defaultMessage: '{label} is required',
  },
  account: {
    id: 'formSchema.account.label',
    defaultMessage: 'Account',
  },
  name: {
    id: 'formSchema.name.label',
    defaultMessage: 'Name',
  },
  nameMatch: {
    id: 'formSchema.name.match',
    defaultMessage: 'Name can only contain letters, numbers and underscores',
  },
  allowedChars: {
    id: 'formSchema.allowedChars',
    defaultMessage: 'Allowed characters are letters, numbers, underscore, dash and plus',
  },
  vendorWebsite: {
    id: 'formSchema.vendorWebsite.label',
    defaultMessage: 'Website',
  },
  firstName: {
    id: 'formSchema.firstName.label',
    defaultMessage: 'First name',
  },
  firstNameMin: {
    id: 'formSchema.firstNameMin.label',
    defaultMessage: 'First name must be more than 1 characters',
  },
  lastName: {
    id: 'formSchema.lastName.label',
    defaultMessage: 'Last name',
  },
  lastNameMin: {
    id: 'formSchema.lastNameMin.label',
    defaultMessage: 'Last name must be more than 1 characters',
  },
  jobTitle: {
    id: 'formSchema.jobTitle.label',
    defaultMessage: 'Job title',
  },
  yara: {
    id: 'formSchema.yara.label',
    defaultMessage: 'Rules',
  },
  yaraPlaceholder: {
    id: 'formSchema.yara.placeholder',
    defaultMessage: 'Drag and drop a file or paste your rules here',
  },
  ruleName: {
    id: 'formSchema.ruleName.label',
    defaultMessage: 'Rule Name',
  },
  malwareFamilyName: {
    id: 'formSchema.malwareFamilyName.label',
    defaultMessage: 'Malware Family Name',
  },
  username: {
    id: 'formSchema.username.label',
    defaultMessage: 'Username',
  },
  usernameMin: {
    id: 'formSchema.username.min',
    defaultMessage: 'Username must be more than 3 characters',
  },
  usernameMax: {
    id: 'formSchema.username.max',
    defaultMessage: 'Username can be no more than 15 characters',
  },
  usernameMatch: {
    id: 'formSchema.username.match',
    defaultMessage: 'Username can only contain letters, numbers and underscores',
  },
  fullName: {
    id: 'formSchema.fullName.label',
    defaultMessage: 'Name',
  },
  fullNameMatch: {
    id: 'formSchema.fullName.match',
    defaultMessage: 'Name can only contain letters, numbers and underscores',
  },
  email: {
    id: 'formSchema.email.label',
    defaultMessage: 'Email address',
  },
  confirmEmail: {
    id: 'formSchema.confirmEmail.label',
    defaultMessage: 'Confirm email address',
  },
  confirmEmailMatch: {
    id: 'formSchema.confirmEmail.match',
    defaultMessage: 'Email addresses must match',
  },
  phone: {
    id: 'formSchema.phone.label',
    defaultMessage: 'Phone number',
  },
  contactMethod: {
    id: 'formSchema.contactMethod.label',
    defaultMessage: 'Preferred contact method',
  },
  company: {
    id: 'formSchema.company.label',
    defaultMessage: 'Company',
  },
  confirmMatch: {
    id: 'formSchema.confirm.match',
    defaultMessage: 'Must match {name}',
  },
  ethAddress: {
    id: 'formSchema.ethAddress.label',
    defaultMessage: 'Ethereum address',
  },
  invalidEthAddress: {
    id: 'formSchema.ethAddress.match',
    defaultMessage: 'Ethereum address failed validation. Re-enter your Ethereum address.',
  },
  description: {
    id: 'formSchema.description.label',
    defaultMessage: 'Description',
  },
  tags: {
    id: 'formSchema.tags.label',
    defaultMessage: 'Tags',
  },
  role: {
    id: 'formSchema.role.label',
    defaultMessage: 'Role (optional, defaults to "Team Member")',
  },
  artifactTypes: {
    id: 'formSchema.artifactTypes.label',
    defaultMessage: 'Select one or more artifact type that your engine can process',
  },
  webhookInfo: {
    id: 'formSchema.webhook.info',
    defaultMessage: 'Manage webhooks in your Account Settings',
  },
  createNewWebhook: {
    id: 'formSchema.webhook.newWebhook',
    defaultMessage: 'Create Webhook',
  },
  mimetypesInfo: {
    id: 'formSchema.mimetypes.info',
    defaultMessage:
      'Your engine will only receive bounties having files matching your selected list of Mimetypes. Select all Mimetypes that your engine can process.',
  },
  polyscoreLower: {
    id: 'formSchema.polyscore.min',
    defaultMessage: 'Min',
  },
  polyscoreUpper: {
    id: 'formSchema.polyscore.max',
    defaultMessage: 'Max',
  },
});
