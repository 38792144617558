import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-star' viewBox='0 0 15 15'>
      <path d='M9.452 5.007L7.922.344a.5.5 0 0 0-.95 0L5.444 5.007H.5a.5.5 0 0 0-.292.906l3.995 2.872-1.531 4.652a.5.5 0 0 0 .767.563l4.009-2.882L11.457 14a.5.5 0 0 0 .766-.563l-1.531-4.652 3.995-2.872a.5.5 0 0 0-.292-.906H9.452z' />
    </symbol>

    <Global
      styles={css`
        .icon-star {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
