import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import styled from '@emotion/styled';
import { Formik, FormikProps } from 'formik';
import { Dispatch } from 'state/types/thunk';
import { showNotification } from 'state/notification/actions';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import styles from 'views/styles';
import { btnMessages } from 'views/components/Button';
import FormInput from 'views/components/form/FormInput';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { createWebhook } from 'state/webhooks/actions';
import {
  formatErrorMessage,
  getErrorData,
  getErrorFields,
  getErrorMessage,
  getErrorMessages,
} from 'utils/error';
import { errorSelector } from 'state/requests/selectors';
import { WebhooksActionName } from 'state/webhooks/types';
import { RootState } from '../../../../../state/root';

interface IWebhookFormValues {
  url: string;
  requestsPerDay: number;
}

interface ICreateWebhookForm {
  closeForm: () => void;
}

interface NewWebhook {
  url: string;
  requestsPerDay: number;
}

const messages = defineMessages({
  url: {
    id: 'account.webhooks.form.url',
    defaultMessage: 'Webhook url',
  },
  requestsPerDay: {
    id: 'account.webhooks.form.requestsPerDay',
    defaultMessage: 'Rate limit (messages per day)',
  },
  webhookCreated: {
    id: 'account.webhooks.form.created',
    defaultMessage:
      'Webhook created. Go to your Webhook Settings to configure and verify it. (Settings → Webhook).',
  },
});

const Buttons = styled.div`
  margin-top: 0px !important;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  *:first-of-type {
    margin-right: 5px !important;
  }

  *:last-of-type {
    margin-left: 5px !important;
  }
`;

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

const WebhookForm = ({ closeForm }: ICreateWebhookForm) => {
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const { requests } = useSelector((state: RootState) => state);

  const error = errorSelector(requests, [WebhooksActionName.CREATE_WEBHOOK]);
  const errorMessage = error && getErrorMessage(error);
  const errorData = error && getErrorData(error);
  const errorFields = getErrorFields(errorData);
  const errorMessages = getErrorMessages(errorData, errorMessage);

  const { classes } = useStyles();

  const _createWebhook = async (values: NewWebhook) => {
    await dispatch(createWebhook(values));
    closeForm();
  };

  const _onSubmit = (values: IWebhookFormValues) => {
    _createWebhook(values);
    dispatch(
      showNotification({
        status: 'success',
        delay: 10000,
        message: intl.formatMessage(messages.webhookCreated),
      })
    );
  };

  return (
    <div css={classes.wrapper}>
      <div css={classes.header}>
        <FormattedMessage id='account.webhooks.form.headerForm' defaultMessage='Create webhook' />
      </div>

      <Formik onSubmit={_onSubmit} initialValues={{ url: '', requestsPerDay: 100 }}>
        {(formik: FormikProps<IWebhookFormValues>) => (
          <form onSubmit={formik.handleSubmit}>
            <FormInput
              className='h-mb-xs'
              name='url'
              label={intl.formatMessage(messages.url)}
              errorOnTouched
              error={errorFields.includes('url')}
              helperText={formatErrorMessage(errorMessages['url'])}
            />
            <FormInput
              className='h-mb-xs'
              name='requestsPerDay'
              label={intl.formatMessage(messages.requestsPerDay)}
              errorOnTouched
              error={errorFields.includes('requestsPerDay')}
              helperText={formatErrorMessage(errorMessages['requestsPerDay'])}
            />
            <Buttons>
              <StyledButton
                data-cy='submitBtn'
                className='h-mt-xs'
                color='primary'
                variant='contained'
                type='button'
                size='small'
                onClick={() => formik.submitForm()}
                disabled={!(formik.dirty && formik.isValid)}>
                <FormattedMessage {...btnMessages.save} />
              </StyledButton>
              <StyledButton
                data-cy='cancelBtn'
                className='h-mt-xs'
                color='primary'
                variant='outlined'
                size='small'
                onClick={closeForm}>
                <FormattedMessage {...btnMessages.cancel} />
              </StyledButton>
            </Buttons>
          </form>
        )}
      </Formik>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    header: {
      textAlign: 'left',
      fontSize: '2.0rem',
      fontWeight: 'bold',
    },
    wrapper: {
      width: '100%',
      padding: styles.spacing.xs,
      marginBottom: styles.spacing.xs,
      borderRadius: '2rem',
      marginTop: '2rem',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
    wrapper: {
      background: styles.color.ulGrey,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
    wrapper: {
      background: styles.color.darkOffBlack,
    },
  },
});

export default WebhookForm;
