import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { resetRequest } from 'state/requests/actions';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys, getErrorData } from 'utils/error';
import { MicroenginesActionName } from 'state/microengines/types';
import { updateUserMicroengine } from 'state/microengines/actions';
import { closeModal } from 'state/modal/actions';
import { PageTimeQuery } from 'models/TimeSeries';
import { MicroengineListing, MicroengineValues } from 'models/Microengine';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import Modal from 'views/components/Modal';
import MicroengineForm from '../forms/MicroengineForm';
import { CheckboxOption } from 'views/pages/AuthCallbackPage/AccountSignupForm/CheckboxArrayField';

interface FormData {
  mimetypes: string[];
  artifactTypes: CheckboxOption[];
}

export interface UpdateMicroengineModalParams {
  id: number;
  values: Partial<MicroengineValues>;
  queryParams?: PageTimeQuery<MicroengineListing>;
  formData: FormData;
}

const messages = defineMessages({
  heading: {
    id: 'microengines.update.heading',
    defaultMessage: 'Edit engine',
  },
});

const errorKeys: ErrorKeys = {};

const UpdateMicroengineModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [MicroenginesActionName.UPDATE_USER_MICROENGINE]);
  const error = errorSelector(requests, [MicroenginesActionName.UPDATE_USER_MICROENGINE]);

  const { id, values, queryParams, formData } = useSelector(
    ({ modal }: { modal: ModalState<UpdateMicroengineModalParams> }) => modal.params
  );

  return (
    <Modal>
      <MicroengineForm
        css={ownStyle}
        initialValues={values}
        formData={formData}
        heading={intl.formatMessage(messages.heading)}
        buttonText={intl.formatMessage(btnMessages.save)}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        errorData={error && getErrorData(error)}
        onSubmit={({ accountNumber, ...values }) => {
          return dispatch(updateUserMicroengine(id, values, accountNumber!, queryParams));
        }}
        onCancel={() => {
          dispatch(closeModal());
          dispatch(resetRequest(MicroenginesActionName.UPDATE_USER_MICROENGINE));
        }}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default UpdateMicroengineModal;
