import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import styles from 'views/styles';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import StatusIcon, { IStatusIcon } from 'views/components/StatusIcon';

const SessionExpired = () => {
  const dispatch = useDispatch<Dispatch>();
  const _closeModal = () => dispatch(closeModal());

  const {
    content,
    btnText,
    status,
    statusMessage,
    loading,
    submitBtnVariant = 'outlined',
    layout = 'row',
  } = useSelector(
    ({
      modal,
    }: {
      modal: ModalState<{
        content: React.ReactNode;
        btnText: string;
        loading?: boolean;
        status?: IStatusIcon['status'];
        statusMessage?: string;
        layout?: 'column' | 'row';
        submitBtnVariant?: 'outlined' | 'contained';
      }>;
    }) => modal.params
  );

  return (
    <ConfirmModal
      css={ownStyle}
      isLoading={loading || false}
      content={
        <div css={layout === 'column' ? columnStyle : {}}>
          {status && <StatusIcon status={status} value={statusMessage} />}
          <div>{content}</div>
        </div>
      }
      submitBtnText={btnText}
      submitBtnVariant={submitBtnVariant}
      disabled={false}
      onSubmit={_closeModal}
    />
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;

  p {
    font-size: 2.5rem;
    font-weight: 500;
    color: ${styles.color.darkRed};
  }
`;

const columnStyle = css`
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 2.2rem;
  margin-bottom: 4rem;
`;

export default SessionExpired;
