import { NotificationState } from '.';
import { NotificationActionName, NotificationAction } from './types';

export const initialState: NotificationState = {
  show: false,
  status: null,
  message: null,
  delay: 2000,
};

const notification = (
  state: NotificationState = initialState,
  action: NotificationAction
): NotificationState => {
  switch (action.type) {
    case NotificationActionName.SHOW_NOTIFICATION: {
      return { show: true, ...action.params };
    }

    case NotificationActionName.HIDE_NOTIFICATION: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default notification;
