import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import Icon from 'views/components/Icon';
import useIsPrivateContext from 'hooks/useIsPrivateContext';
import Button from '@material-ui/core/Button';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

const NotLoggedInPage = () => {
  const isPrivateContext = useIsPrivateContext();
  const { classes } = useStyles();

  return (
    <Fragment>
      <SEO />
      <BaseLayout variant={isPrivateContext ? undefined : 'transparent'}>
        <div css={classes.container}>
          <h2 className='h2 h-mb-xxs'>Scan a file for threats</h2>

          <p className='p'>Log in to upload a file or scan a URL</p>

          <div>
            <Icon name='drag' css={classes.icon} />
          </div>

          <Button
            css={classes.button}
            color='primary'
            variant='contained'
            onClick={() => window.location.replace('/login')}
          >
            Log in / Sign up
          </Button>
        </div>
      </BaseLayout>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      margin: '0 auto',
      width: 'max-content',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '3rem',
      padding: '4rem 0',
    },
    icon: {
      fontSize: '8rem',
    },
    button: {
      fontSize: '2rem',
    },
  },
  light: {
    icon: {
      color: styles.color.xxLightGrey,
    },
  },
});

export default NotLoggedInPage;
