import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-share' viewBox='0 0 32 32'>
      <path d='M24 22.667c-0.987 0.006-1.936 0.376-2.667 1.040l-10.667-5.773c0.091-0.395 0.091-0.805 0-1.2l10.667-5.773c1.408 1.26 3.506 1.362 5.030 0.246s2.059-3.147 1.284-4.87c-0.776-1.723-2.652-2.667-4.498-2.266s-3.159 2.041-3.149 3.93c0.002 0.206 0.024 0.412 0.067 0.613l-10.733 5.773c-0.724-0.675-1.677-1.052-2.667-1.053-2.209 0-4 1.791-4 4s1.791 4 4 4c0.99-0.002 1.943-0.378 2.667-1.053l10.667 5.773c-0.020 0.204-0.020 0.409 0 0.613 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.209-1.791-4-4-4zM24 6.4c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6c-0.884 0-1.6-0.716-1.6-1.6s0.716-1.6 1.6-1.6zM6.667 18.933c-0.884 0-1.6-0.716-1.6-1.6s0.716-1.6 1.6-1.6c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6zM24 28.267c-0.647 0-1.231-0.39-1.478-0.988s-0.111-1.286 0.347-1.744c0.458-0.458 1.146-0.595 1.744-0.347s0.988 0.831 0.988 1.478c0 0.884-0.716 1.6-1.6 1.6z' />
    </symbol>

    <Global
      styles={css`
        .icon-share {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
