import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import SEO from 'views/components/layout/SEO';
import Button from '@material-ui/core/Button';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Logo from 'views/components/layout/Header/Logo';
import Icon from 'views/components/Icon';
import Footer from 'views/components/layout/Footer/FooterContainer';

const region = (process.env.REACT_APP_REGION as 'us' | 'eu') || 'us';
const euSite = (process.env.REACT_APP_EU_SITE as string) || '/';
const usSite = (process.env.REACT_APP_US_SITE as string) || '/';

const addSlash = (url: string) => (url.endsWith('/') ? url : `${url}/`);
const loginUrls = {
  eu: region === 'eu' ? '/login' : `${addSlash(euSite)}region`,
  us: region === 'us' ? '/login' : `${addSlash(usSite)}region`,
};

const goToRegionLogin = (region: 'eu' | 'us') => {
  return window.location.replace(loginUrls[region]);
};

const Landing = () => {
  const { classes } = useStyles();

  return (
    <Fragment>
      <SEO />
      <div css={classes.page}>
        <header css={classes.root}>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div css={classes.content}>
                  <Logo />
                </div>
              </div>
            </div>
          </div>
        </header>
        <div css={classes.container}>
          <h2 className='h2 h-mb-xxs'>Malware Analysis Service</h2>

          <div css={classes.buttonContainer}>
            <h3 className='h4 h-mb-xxs'>Select Regional Tenant (EU / US)</h3>

            <Button
              css={classes.button}
              color='primary'
              variant='contained'
              onClick={() => goToRegionLogin('eu')}
            >
              {region === 'eu' && <Icon name='check-alt' css={classes.checkIcon} />}
              EU Client Login
            </Button>
            <Button
              css={classes.button}
              color='primary'
              variant='contained'
              onClick={() => goToRegionLogin('us')}
            >
              {region === 'us' && <Icon name='check-alt' css={classes.checkIcon} />}
              US / Global Client Login
            </Button>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    page: {
      display: 'flex',
      flexDirection: 'column',
      height: '100dvh',
    },
    container: {
      margin: '3rem auto',
      width: 'max-content',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '7rem',
      padding: '4rem 0',
      flexGrow: 1,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    icon: {
      fontSize: '8rem',
    },
    button: {
      fontSize: '2rem',
    },
    root: {
      padding: '1.8rem 0',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    checkIcon: {
      fontSize: '0.5rem',
      color: styles.color.green,
      marginRight: '1.5rem',
    },
  },
  light: {
    icon: {
      color: styles.color.xxLightGrey,
    },
  },
});

export default Landing;
