import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import styles from 'views/styles';
import FileIcon from 'views/components/FileUpload/FileIcon';

const UploadUnavailableState = () => (
  <div css={style.root} data-cy='fileUploadUnavailable'>
    <h2 className='h2 h-mb-tiny' css={style.heading}>
      <FormattedMessage id='fileupload.unavailable.heading' defaultMessage='Under maintenance' />
    </h2>
    <p className='p h-mb-tiny'>
      <FormattedMessage
        id='fileupload.unavailable.text'
        defaultMessage='PolySwarm is undergoing scheduled maintenance.'
      />
      <br />
      <FormattedMessage
        id='fileupload.unavailable.subtext'
        defaultMessage='Sorry for the inconvenience.'
      />
    </p>
    <FileIcon className='h-mt-sm h-mb-xxs' error={true} />
    <Button
      style={{ fontSize: '2.4rem' }}
      className='h-mt-sm'
      color='primary'
      variant='contained'
      disabled>
      <FormattedMessage id='fileupload.uploaded.button' defaultMessage='Scan file' />
    </Button>
  </div>
);

const style = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    text-align: center;
  `,
  heading: css`
    color: ${styles.color.red};
  `,
};

export default UploadUnavailableState;
