import React, { useContext } from 'react';
import { Global } from '@emotion/core';
import { ThemeContext } from 'views/components/providers/ThemeProvider';
import baseStyles from 'views/styles/base';

const GlobalStyles = () => {
  const theme = useContext(ThemeContext);
  return <Global styles={baseStyles(theme)} />;
};

export default GlobalStyles;
