import { defineMessages } from 'react-intl';

export const errorMessages = defineMessages({
  server: {
    id: 'errors.server',
    defaultMessage: 'Request failed. Please try again.',
  },
  username: {
    id: 'errors.username',
    defaultMessage: 'Username already in use. Try a different username.',
  },
  teamNameExists: {
    id: 'teams.modal.error.nameExists',
    defaultMessage: 'Team name already in use. Try a different team name.',
  },
  invitationExists: {
    id: 'teams.invite.exists',
    defaultMessage: 'User with specified email is already invited.',
  },
  teamMemberExists: {
    id: 'teams.member.exists',
    defaultMessage: 'User with specified email is already in your team.',
  },
  teamForceMfa: {
    id: 'teams.forceMfa',
    defaultMessage:
      'Failure to switch to Team Account context. You must enable 2FA on your User Account before you can access this Team Account.',
  },
  teamAdvanced: {
    id: 'teams.advanced',
    defaultMessage: 'You must enable 2FA on your User Account before you can access this section.',
  },
  mfaRequired: {
    id: 'errors.mfaRequired',
    defaultMessage: 'You must enable 2FA on your User Account before executing this action.',
  },
  noneFound: {
    id: 'errors.noneFound',
    defaultMessage: 'None found',
  },
  invalidRules: {
    id: 'errors.invalidRules',
    defaultMessage: 'Invalid YARA rules',
  },
  microengineExists: {
    id: 'microengines.exists',
    defaultMessage:
      'Engine with this Ethereum address has already been claimed. Contact support if you need assistance.',
  },
  invalidSignature: {
    id: 'microengines.invalidSignature',
    defaultMessage: 'Invalid signature',
  },
  emptyFile: {
    id: 'scan.file.empty',
    defaultMessage: 'The file you selected has no content. Please select a file that is not empty.',
  },
  fileTooLarge: {
    id: 'scan.file.fileTooLarge',
    defaultMessage:
      'File size exceeds upload limit on your plan. Upgrade your plan to upload larger files.',
  },
  rateLimit: {
    id: 'errors.upload.rateLimit',
    defaultMessage:
      'Daily API Request Limit or Feature Usage Limit reached. Check your account plan usage status to see your current available usage.',
  },
  livehuntRateLimit: {
    id: 'errors.upload.livehuntRateLimit',
    defaultMessage:
      'Your plan does not support running additional live hunts, please stop one of your running live hunts before starting a new one.',
  },
  rescanFile: {
    id: 'errors.scan.file.rescan',
    defaultMessage: 'Error rescanning the file. Please try again.',
  },
  rescanUrl: {
    id: 'errors.scan.url.rescan',
    defaultMessage: 'Error rescanning the URL. Please try again.',
  },
  invalidUrl: {
    id: 'errors.scan.url.invalid',
    defaultMessage: 'Invalid URL. Please try again.',
  },
  download: {
    id: 'errors.scan.download',
    defaultMessage: 'Error downloading the artifact. Please try again.',
  },
  downloadUsageExhausted: {
    id: 'errors.scan.downloadUsageExhausted',
    defaultMessage:
      'Unable to download this artifact. You have 0 remaining downloads in your subscription. You can either upgrade your plan to one having more download usage or you can wait for your plan to renew usage in the next billing cycle.',
  },
  downloadForbidden: {
    id: 'errors.scan.downloadForbidden',
    defaultMessage:
      'Monthly downloads allowance is not available in your subscription. You can upgrade your plan to have file downloads enabled.',
  },
  switchAccountFailure: {
    id: 'account.switch.switchAccountFailure',
    defaultMessage: 'Error switching accounts. Please try again.',
  },
  noApiKeys: {
    id: 'account.switch.noApiKeys',
    defaultMessage: 'No API keys found for this account.',
  },
});
