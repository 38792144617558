import { Page, PageAttributes } from 'models/Page';
import { pick } from 'lodash';

export const DEFAULT_LIMIT = 25;

const initialParams = {
  direction: 'asc',
  orderBy: 'createdAt',
  limit: DEFAULT_LIMIT,
};

const initialValues = {
  results: null,
  total: 0,
};

const pageAttributes = [
  'page',
  'direction',
  'orderBy',
  'limit',
  'offset',
  'next_page',
  'has_more',
  'more',
  'rule_name',
];

export const initialPagination = Object.assign({}, initialParams, initialValues);

export const createInitialPagination = <T extends {}>(params?: Partial<Page<T>>): Page<T> =>
  Object.assign({}, initialPagination, params);

export const extractPageQueryParams = <T extends {}>(data: Page<T>) =>
  pick<Page<T>>(data, Object.keys(initialParams)) as PageAttributes<T>;

export const extractUrlQueryParams = <T extends {}>(query: any): Partial<PageAttributes<T>> =>
  pageAttributes.reduce((acc, key) => {
    if (typeof query[key] === 'string') {
      let value: string | string[] | number | boolean | null | undefined =
        key === 'page' || key === 'limit' ? Number(query[key]) : query[key];
      value = value === 'true' ? true : value;
      value = value === 'false' ? false : value;
      return Object.assign({}, acc, { [key]: value });
    }
    return acc;
  }, {});

/**
 * Extract the pagination data out of an api response, type it, and return a new object.
 */
export const buildPaginatedData = <T>(res: any, results: T[]): Page<T> => {
  if (!res.results) {
    throw new Error(`Response does not include the results param.`);
  }
  const data = pageAttributes.reduce((acc: any, key) => {
    if (res[key] !== undefined) {
      acc[key] = res[key];
    }
    return acc;
  }, {}) as Page<T>;
  data.results = results;
  data.total = res.total;
  return data;
};
