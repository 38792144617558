import React, { useState } from 'react'; // eslint-disable-line
import { jsx, keyframes } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import HowItWorksSection from '../';
import swarmImg from '../shared/swarm.svg';

const GroundTruthSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { classes } = useStyles(isVisible);

  const _handleVisibilityChange = (isVisible: boolean) => setIsVisible(isVisible);

  return (
    <HowItWorksSection onVisibilityChange={_handleVisibilityChange}>
      <div css={classes.root}>
        <div css={classes.left}>
          <h3 className='h3' css={classes.heading}>
            <FormattedMessage id='groundTruthSection.heading' defaultMessage='Ground Truth' />
          </h3>
          <p className='p' css={classes.text}>
            <FormattedMessage
              id='groundTruthSection.text'
              defaultMessage='In two weeks, the PolySwarm protocol converges on industry consensus.'
            />
          </p>
        </div>
        <div css={classes.right}>
          <img css={classes.swarm} src={swarmImg} alt='swarm' />
        </div>
      </div>
    </HowItWorksSection>
  );
};

const pulse = keyframes`
  33.33% {
    transform: scale(1);
  }
  66.67% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

const useStyles = makeStyles((isVisible: boolean) => ({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    left: {
      width: '50%',
    },
    text: {
      maxWidth: '34rem',
    },
    right: {
      width: '50%',
      textAlign: 'center',
    },
    swarm: {
      width: '100%',
      maxWidth: '26rem',
      transform: 'scale(0.9)',
      animation: isVisible ? `${pulse} 6s ease-in-out forwards` : 'none',
    },
  },
  light: {
    heading: {
      color: styles.color.purple,
    },
  },
  dark: {
    heading: {
      color: styles.color.lightBlue,
    },
  },
}));

export default GroundTruthSection;
