import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-wordmark' viewBox='0 0 188 32'>
      <path d='M71.65 9.058c-3.954 0-7.262 3.056-7.262 7.247 0 4.17 3.308 7.206 7.262 7.206 3.933 0 7.261-3.014 7.261-7.206s-3.328-7.247-7.261-7.247zM71.65 21.363c-2.764 0-4.881-2.23-4.881-5.058s2.118-5.079 4.881-5.079c2.784 0 4.861 2.251 4.861 5.079s-2.078 5.058-4.861 5.058z' />
      <path d='M89.966 21.259h-5.85v-11.087c0-0.62-0.484-1.115-1.129-1.115-0.625 0-1.13 0.495-1.13 1.115v12.016c0 0.619 0.504 1.115 1.13 1.115h6.979c0.545 0 0.968-0.454 0.968-1.032 0-0.537-0.424-1.012-0.968-1.012z' />
      <path d='M102.14 9.058c-0.626 0-1.129 0.495-1.129 1.115v2.177c0 1.197-0.282 2.147-0.847 2.808-0.645 0.764-1.593 1.115-2.784 1.115-1.17 0-2.118-0.351-2.763-1.115-0.565-0.661-0.847-1.61-0.847-2.808v-2.177c0-0.62-0.504-1.115-1.129-1.115s-1.13 0.495-1.13 1.115v2.508c0 1.61 0.424 2.973 1.271 3.943 0.811 0.947 2.005 1.539 3.479 1.737v4.034c0 0.619 0.504 1.115 1.129 1.115 0.605 0 1.129-0.496 1.129-1.115v-4.036c1.48-0.199 2.671-0.791 3.48-1.736 0.848-0.97 1.271-2.333 1.271-3.943v-2.508c0.001-0.62-0.504-1.115-1.129-1.115z' />
      <path d='M114.808 16.119c-1.231-0.784-2.663-1.074-3.954-1.528-1.109-0.392-1.876-0.888-1.876-1.796 0-1.136 0.908-1.652 2.562-1.652 1.714 0 2.401 0.805 3.348 0.805 0.605 0 1.069-0.475 1.069-1.074 0-1.548-3.429-1.817-4.296-1.817-3.288 0-5.063 1.548-5.063 3.819 0 1.61 0.806 2.581 1.896 3.2 1.251 0.702 2.662 0.97 3.792 1.424 1.009 0.413 1.735 0.95 1.735 1.899 0 1.115-0.767 2.065-2.663 2.065-2.077 0-2.824-1.177-3.731-1.177-0.585 0-1.089 0.475-1.089 1.115 0 1.342 2.885 2.106 4.861 2.106 2.924 0 5.083-1.569 5.083-4.294 0-1.528-0.726-2.498-1.674-3.097z' />
      <path d='M136.643 9.058c-0.626 0-1.13 0.495-1.13 1.115v8.010c0 1.094-0.282 1.838-0.726 2.333-0.584 0.619-1.392 0.847-2.219 0.847-0.848 0-1.533-0.268-1.997-0.744-0.584-0.598-0.928-1.569-0.928-2.808v-7.639c0-0.62-0.504-1.115-1.13-1.115s-1.13 0.495-1.13 1.115v7.639c0 1.239-0.363 2.209-0.948 2.808-0.464 0.475-1.129 0.744-1.997 0.744-0.807 0-1.634-0.227-2.198-0.847-0.463-0.495-0.746-1.239-0.746-2.333v-8.010c0-0.62-0.504-1.115-1.13-1.115s-1.129 0.495-1.129 1.115v8.031c0 1.755 0.504 3.014 1.311 3.84 0.988 1.053 2.441 1.445 3.873 1.445 0.786 0 1.634-0.165 2.38-0.557 0.666-0.392 1.271-0.97 1.714-1.837 0.424 0.867 1.029 1.445 1.694 1.837 0.746 0.392 1.594 0.557 2.381 0.557 1.452 0 2.884-0.392 3.873-1.445 0.807-0.826 1.311-2.085 1.311-3.84v-8.031c-0-0.62-0.505-1.115-1.129-1.115z' />
      <path d='M186.733 10.503c-0.988-1.053-2.44-1.445-3.873-1.445-0.786 0-1.634 0.165-2.38 0.557-0.665 0.392-1.271 0.97-1.714 1.837-0.424-0.867-1.029-1.445-1.694-1.837-0.746-0.392-1.593-0.557-2.38-0.557-1.452 0-2.884 0.392-3.873 1.445-0.807 0.826-1.311 2.085-1.311 3.84v8.031c0 0.62 0.504 1.115 1.13 1.115s1.129-0.495 1.129-1.115v-8.011c0-1.094 0.282-1.837 0.726-2.333 0.585-0.619 1.392-0.847 2.219-0.847 0.848 0 1.533 0.268 1.997 0.743 0.584 0.599 0.928 1.569 0.928 2.808v7.639c0 0.62 0.505 1.115 1.13 1.115s1.13-0.495 1.13-1.115v-7.639c0-1.239 0.363-2.209 0.948-2.808 0.464-0.475 1.129-0.743 1.997-0.743 0.807 0 1.634 0.227 2.199 0.847 0.463 0.495 0.746 1.239 0.746 2.333v8.011c0 0.62 0.504 1.115 1.129 1.115s1.13-0.495 1.13-1.115v-8.031c0-1.755-0.505-3.014-1.312-3.84z' />
      <path d='M165.978 14.031c0.001-1.63-0.625-2.911-1.654-3.758-0.968-0.785-2.057-1.012-3.227-1.012l-4.679-0.001c-0.625 0-1.13 0.496-1.13 1.136l-0.002 12.015c0 0.62 0.504 1.115 1.13 1.115 0.605 0 1.129-0.495 1.129-1.114l0.002-11.128 3.248 0.001c0.726 0 1.331 0.146 1.875 0.517 0.626 0.434 1.009 1.177 1.008 2.229s-0.424 1.817-1.089 2.271c-0.429 0.292-1.018 0.459-1.686 0.459-1.316 0-2.054 0.842-2.054 2.037 0 0.904 0.484 1.649 1.678 2.355l3.755 2.218c0.178 0.105 0.372 0.155 0.564 0.155 0.391 0 0.771-0.207 0.98-0.578 0.312-0.553 0.126-1.261-0.414-1.58l-4.349-2.57c1.105-0.007 2.19-0.198 3.119-0.927 1.109-0.826 1.795-2.209 1.795-3.84z' />
      <path d='M150.155 10.461c-0.887-0.846-2.118-1.404-3.712-1.404s-2.824 0.557-3.711 1.404c-1.271 1.156-1.856 2.891-1.856 4.542v7.391c0 0.619 0.484 1.115 1.13 1.115 0.605 0 1.13-0.496 1.13-1.115v-3.289h6.616v3.289c0 0.619 0.504 1.115 1.129 1.115s1.13-0.496 1.13-1.115v-7.391c-0-1.652-0.565-3.386-1.856-4.542zM149.751 16.793h-6.616v-1.686c0-1.218 0.343-2.23 0.968-2.932 0.585-0.619 1.392-0.971 2.34-0.971s1.775 0.351 2.361 0.971c0.605 0.702 0.948 1.714 0.948 2.932v1.686z' />
      <path d='M57.925 9.289h-5.229c-0.624 0-1.129 0.518-1.129 1.156v11.895c0 0.639 0.506 1.156 1.129 1.156s1.13-0.518 1.13-1.156v-3.235h4.099c2.644 0 4.795-2.202 4.795-4.908s-2.151-4.908-4.795-4.908zM57.925 16.793h-4.099v-5.192h4.099c1.398 0 2.536 1.164 2.536 2.596s-1.137 2.596-2.536 2.596z' />
      <path d='M3.624 8.457h16.707c2.5 0 4.534 2.018 4.534 4.497s-2.034 4.497-4.534 4.497h-9.040c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28h9.039c3.924 0 7.116-3.166 7.116-7.058s-3.192-7.058-7.116-7.058h-16.706c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28z' />
      <path d='M20.331 0h-6.479c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28h6.479c5.778 0 10.479 4.662 10.479 10.394s-4.701 10.393-10.479 10.393h-16.46c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28h16.459c7.202 0 13.060-5.811 13.060-12.954s-5.858-12.954-13.060-12.954z' />
      <path d='M12.846 12.954c0-0.706-0.579-1.28-1.291-1.28h-3.146c-0.712 0-1.291 0.574-1.291 1.28 0 0.709 0.571 1.28 1.043 1.28h3.146c0.906 0 1.539-0.527 1.539-1.28z' />
      <path d='M8.76 29.243h-7.469c-0.712 0-1.291 0.574-1.291 1.28s0.579 1.28 1.291 1.28h7.469c0.712 0 1.291-0.574 1.291-1.28s-0.579-1.28-1.291-1.28z' />
    </symbol>

    <Global
      styles={css`
        .icon-wordmark {
          width: 18.8em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
