import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface IConfirmCheckboxProps {
  value: boolean;
  onChange: (e: any) => void;
}

const Wrapper = styled.div`
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const Label = styled(FormControlLabel)`
  color: ${styles.color.xLightGrey};
  margin-left: 0px !important;

  & .MuiCheckbox-root {
    padding: 0px;
    margin-top: 0px;
    margin-right: 8px;
  }
  & .MuiTypography-root {
    font-size: 1.2rem;
  }

  a {
    color: ${styles.color.lightGrey};
  }
`;

export const ConfirmCheckbox = ({ value, onChange }: IConfirmCheckboxProps) => {
  const { classes } = useStyles();

  return (
    <Wrapper>
      <Label
        control={
          <Checkbox
            color='primary'
            value={value}
            checked={value === true}
            onChange={() => {
              onChange(!value);
            }}
          />
        }
        label={
          <span data-testid='terms-label'>
            <FormattedMessage
              id='microengineFormPanel.engineAgreement'
              defaultMessage='I agree to the terms and conditions of the {engineAgreement}.'
              values={{
                engineAgreement: (
                  <Link to='/engine-agreement' target='_blank' css={classes.link}>
                    <FormattedMessage
                      id='microengineForm.engineAgreement.terms'
                      defaultMessage='Engine Agreement'
                    />
                  </Link>
                ),
              }}
            />
          </span>
        }
      />
    </Wrapper>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {
    link: {
      color: `${styles.color.lightGrey} !important`,
    },
  },
  dark: {
    link: {
      color: `${styles.color.xxLightGrey} !important`,
    },
  },
});
