import axios from 'axios';
import { uniqBy } from 'lodash';
import { Artifact, NormalizedSearchResult, Assertion, SubmissionResult } from 'models/Submission';
import { MicroenginesAction, MicroenginesActionName } from 'state/microengines/types';
import { SubmissionAction, SubmissionActionName } from './types';
import { SubmissionState } from '.';
import { normalizeArtifactMetadata, normalizeMetadata } from 'state/hunting/reducers';
import { PublicMicroengineListing } from 'models/Microengine';

const emptyAssertion: Assertion = {
  author: '',
  author_name: '',
  bid: '',
  mask: true,
  metadata: {
    malware_family: '',
    scanner: {
      environment: {
        architecture: '',
        operating_system: '',
      },
      signatures_version: '',
      vendor_version: '',
      version: '',
    },
  },
  verdict: null,
};

export const initialState: SubmissionState = {
  emptyAssertions: [],
  uuid: null,
  fileSizeLimit: null,
  scanResults: null,
  hashSearchResults: {},
  fileDetails: null,
  isRescanning: false,
  loadingExtra: false,
  cancelFileUpload: null,
};

const submission = (
  state: SubmissionState = initialState,
  action: SubmissionAction | MicroenginesAction
): SubmissionState => {
  switch (action.type) {
    case SubmissionActionName.GET_FILE_SIZE_LIMIT: {
      return { ...initialState, fileSizeLimit: action.result || state.fileSizeLimit };
    }

    case SubmissionActionName.START_FILE_UPLOAD: {
      return { ...initialState, cancelFileUpload: action.cancel };
    }

    case SubmissionActionName.SUBMIT_FILE: {
      // Ignore action if the request was canceled
      if (action.error instanceof axios.Cancel) {
        return state;
      }

      if (!action.result) {
        return state;
      }

      return {
        ...state,
        uuid: action.result.result.sha256,
        scanResults: action.result,
      };
    }

    case SubmissionActionName.SUBMIT_URL: /* fallthrough */
    case SubmissionActionName.GET_SUBMISSION_UUID: {
      if (!action.result) {
        return initialState;
      }
      return {
        ...initialState,
        uuid: action.result.result.sha256,
        scanResults: action.result,
        fileDetails: normalizeArtifactMetadata(action.result.result) || state.fileDetails,
      };
    }

    case SubmissionActionName.CANCEL_FILE_UPLOAD: {
      return initialState;
    }

    case MicroenginesActionName.GET_ALL_VERIFIED_MICROENGINES: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        emptyAssertions: createEmptyAssertions(action.result),
      };
    }

    case SubmissionActionName.GET_SUBMISSION: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        uuid: action.result.result.sha256,
        scanResults: mergeAssertions(state.emptyAssertions, action.result),
        fileDetails: normalizeArtifactMetadata(action.result.result) || state.fileDetails,
        isRescanning: false,
        loadingExtra: false,
      };
    }

    case SubmissionActionName.SET_LOADING_STATE: {
      return {
        ...state,
        loadingExtra: true,
      };
    }

    case SubmissionActionName.GET_HASH_SEARCH_RESULTS: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        hashSearchResults: Object.assign({}, state.hashSearchResults, {
          [action.page]: action.result,
        }),
      };
    }

    case SubmissionActionName.REFRESH_HASH_SEARCH_RESULTS: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        hashSearchResults: {
          [action.page]: action.result,
        },
      };
    }

    case SubmissionActionName.CLEAR_HASH_SEARCH_RESULTS: {
      return {
        ...state,
        hashSearchResults: {},
      };
    }

    case SubmissionActionName.GET_FILE_DETAILS: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        uuid: action.result.result.sha256,
        scanResults: action.result,
        fileDetails: normalizeArtifactMetadata(action.result.result),
      };
    }

    case SubmissionActionName.RESCAN_FILE: {
      return { ...state, isRescanning: true };
    }

    case SubmissionActionName.RESCAN_FILE_FAILURE: {
      return { ...state, isRescanning: false };
    }

    default: {
      return state;
    }
  }
};

export default submission;

/**
 * Generate empty assertions to populate the UI more quickly.
 */
const createEmptyAssertions = (microengines: PublicMicroengineListing[]): Assertion[] => {
  return microengines.map((m) => ({ ...emptyAssertion, author: m.engineId, author_name: m.name }));
};

/**
 * Replace empty assertions with real results.
 */
const mergeAssertions = (
  emptyAssertions: Assertion[],
  scanResult: SubmissionResult
): SubmissionResult => {
  const assertions = uniqBy([...scanResult.result.assertions, ...emptyAssertions], 'author_name');
  return {
    ...scanResult,
    result: {
      ...scanResult.result,
      assertions: assertions,
    },
  };
};

export const transformHashSearch = (
  hash: string,
  {
    assertions,
    sha256,
    filename,
    metadata,
    artifact_id,
    created,
    first_seen,
    last_seen,
    last_scanned,
    mimetype,
    extended_type,
    md5,
    sha1,
    polyscore,
    type,
  }: Artifact
): NormalizedSearchResult => {
  const total = assertions.filter((a) => a.verdict !== null).length;
  const malicious = assertions.filter((a) => a.verdict === true).length;
  const normalizedMetadata = normalizeMetadata(metadata);
  return {
    hash,
    sha256,
    artifact_id,
    attributes: {
      names: [filename],
      first_seen,
      last_seen,
      last_scanned,
      mimetype,
      extended_type,
      md5,
      sha1,
      sha256,
      type,
    },
    polyscore,
    metadata: normalizedMetadata,
    submitted: created,
    detections: {
      malicious,
      total,
    },
  };
};
