import React, { useState } from 'react'; // eslint-disable-line
import { jsx, keyframes } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import HowItWorksSection from '../HowItWorksSection';
import { Escrow, Engines, Bids } from '../HowItWorksSubsections';
import computerResultsImg from './computer-results.svg';
import arrowImg from '../shared/arrow.svg';

const VerdictSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { classes } = useStyles(isVisible);

  const _handleVisibilityChange = (isVisible: boolean) => setIsVisible(isVisible);

  return (
    <HowItWorksSection onVisibilityChange={_handleVisibilityChange}>
      <div css={classes.root}>
        <div css={classes.left}>
          <p css={classes.text}>
            <FormattedMessage
              id='verdictSection.p1'
              defaultMessage='Crowdsourced intelligence (engine determinations) and a final score (PolyScore) are sent back to the User.'
            />
          </p>
          <p css={classes.text}>
            <FormattedMessage
              id='verdictSection.p2'
              defaultMessage='The money from the bounty and the assertions becomes the reward, which is securely escrowed in an Ethereum smart contract.'
            />
          </p>
          <Escrow css={classes.escrow} />
        </div>
        <div css={classes.right}>
          <Engines css={classes.engines} />
          <Bids
            items={[{ result: 'M', bid: 5 }, { result: 'B', bid: 3 }, { result: 'B', bid: 2 }, {}]}
            css={classes.bids}
          />
          <div css={classes.computerWrap}>
            <img css={classes.computerArrow} src={arrowImg} alt='arrow' />
            <img css={classes.computer} src={computerResultsImg} alt='computer' />
          </div>
        </div>
      </div>
    </HowItWorksSection>
  );
};

const fadeInUp = keyframes`
  to {
    opacity: 1;
    top: 0;
  }
`;

const fadeOutDown = keyframes`
  to {
    opacity: 0;
    top: 10px;
  }
`;

const fadeZoom = keyframes`
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const show = keyframes`
  to {
    opacity: 1;
  }
`;

const useStyles = makeStyles((isVisible: boolean) => ({
  base: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      maxWidth: '42rem',
      marginBottom: styles.spacing.xs,
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    left: {
      width: '50%',
      position: 'relative',
    },
    escrow: {
      marginTop: styles.spacing.sm,
      '.assertions': {
        opacity: 0,
        animation: isVisible ? `${show} 0.2s ease-in-out 1.4s forwards` : 'none',
        '.payout': {
          opacity: 0,
          top: 10,
          '&:nth-of-type(1)': {
            animation: isVisible ? `${fadeInUp} 0.2s ease-in-out 1.6s forwards` : 'none',
          },
          '&:nth-of-type(2)': {
            animation: isVisible ? `${fadeInUp} 0.2s ease-in-out 1.8s forwards` : 'none',
          },
          '&:nth-of-type(3)': {
            animation: isVisible ? `${fadeInUp} 0.2s ease-in-out 2s forwards` : 'none',
          },
        },
      },
    },
    computerWrap: {
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        height: '3rem',
        width: 2,
        backgroundColor: styles.color.xLightGrey,
        opacity: 0,
        animation: isVisible ? `${show} 0.4s ease-in-out 3s forwards` : 'none',
      },
    },
    computerArrow: {
      position: 'absolute',
      top: '3rem',
      left: '50%',
      transform: 'translateX(-50%) rotate(90deg)',
      opacity: 0,
      animation: isVisible ? `${show} 0.4s ease-in-out 3s forwards` : 'none',
    },
    computer: {
      maxWidth: '26rem',
      marginTop: styles.spacing.md,
      opacity: 0,
      transform: 'scale(0.9)',
      animation: isVisible ? `${fadeZoom} 0.6s ease-in-out 2.3s forwards` : 'none',
    },
    right: {
      width: '50%',
      position: 'relative',
      textAlign: 'center',
    },
    engines: {
      margin: '0 auto',
      '.engine': {
        '&:nth-of-type(4)': {
          opacity: 0.5,
        },
      },
    },
    bids: {
      position: 'relative',
      margin: '3.7rem auto 0',
      [`@media (min-width: ${styles.breakpoint.xxl})`]: {
        margin: '4rem auto 0',
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: '-1.1rem',
        left: '50%',
        height: 2,
        width: '26.8rem',
        transform: 'translateX(-55%)',
        backgroundColor: styles.color.xLightGrey,
        opacity: 0,
        animation: isVisible ? `${show} 0.4s ease-in-out 3s forwards` : 'none',
        [`@media (min-width: ${styles.breakpoint.lg})`]: {
          bottom: '-1rem',
          width: '27.5rem',
        },
        [`@media (min-width: ${styles.breakpoint.xxl})`]: {
          bottom: '-1.1rem',
          width: '28.5rem',
          transform: 'translateX(-54%)',
        },
      },
      '.bid': {
        '.result': {
          position: 'relative',
          '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: '-3.2rem',
            left: '50%',
            height: '3rem',
            width: 2,
            transform: 'translateX(-50%)',
            backgroundColor: styles.color.xLightGrey,
            opacity: 0,
            animation: isVisible ? `${show} 0.4s ease-in-out 3s forwards` : 'none',
          },
        },
        '.payout': {
          top: 0,
        },
        '&:nth-of-type(1)': {
          '.payout': {
            animation: isVisible ? `${fadeOutDown} 0.4s ease-in-out 0.7s forwards` : 'none',
          },
        },
        '&:nth-of-type(2)': {
          '.payout': {
            animation: isVisible ? `${fadeOutDown} 0.4s ease-in-out 0.9s forwards` : 'none',
          },
        },
        '&:nth-of-type(3)': {
          '.payout': {
            animation: isVisible ? `${fadeOutDown} 0.4s ease-in-out 1.1s forwards` : 'none',
          },
        },
        '&:nth-of-type(4)': {
          opacity: 0.5,
        },
      },
    },
  },
}));

export default VerdictSection;
