import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { TeamMember } from 'models/Team';
import { PageAttributes } from 'models/Page';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys } from 'utils/error';
import { TeamActionName } from 'state/team/types';
import { archiveTeamMember } from 'state/team/actions';
import { closeModal, openModal } from 'state/modal/actions';
import { btnMessages } from 'views/components/Button';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import Modal from 'views/components/Modal';
import { useUser } from 'views/components/providers/UserProvider';
import { SetupMfaPanel } from 'views/components/SetupMfaPanel/SetupMfaPanel';
import { hasMadeTeamMFARecently, loginWriteTeams } from 'state/auth/actions';

export interface ArchiveTeamMemberModalParams {
  teamAccountNumber: number | string;
  accountNumber: number;
  teamMemberName: string;
  queryParams?: PageAttributes<TeamMember>;
}

const messages = defineMessages({
  heading: {
    id: 'teams.modal.archiveMemberHeading',
    defaultMessage: 'Remove team member',
  },
  text: {
    id: 'teams.modal.archiveMemberText',
    defaultMessage: 'Are you sure you want to remove the team member "{teamMemberName}"?',
  },
});

const errorKeys: ErrorKeys = {};

const ArchiveTeamMemberModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useUser();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [TeamActionName.ARCHIVE_TEAM_MEMBER]);
  const error = errorSelector(requests, [TeamActionName.ARCHIVE_TEAM_MEMBER]);

  const { accountNumber, teamMemberName, queryParams } = useSelector(
    ({ modal }: { modal: ModalState<ArchiveTeamMemberModalParams> }) => modal.params
  );

  const _closeModal = () => dispatch(closeModal());

  if (!user.mfaEnabled)
    return (
      <Modal>
        <SetupMfaPanel onSetup={_closeModal} onCancel={_closeModal} />
      </Modal>
    );

  if (!hasMadeTeamMFARecently()) {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({
              modal: 'ARCHIVE_TEAM_MEMBER',
              location: location.pathname,
              accountNumber,
              teamMemberName,
            })
          );
        },
      })
    );
  }

  return (
    <ConfirmModal
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text, { teamMemberName })}
      submitBtnColor='secondary'
      submitBtnText={intl.formatMessage(btnMessages.delete)}
      onSubmit={() => dispatch(archiveTeamMember(accountNumber, queryParams))}
      onCancel={_closeModal}
    />
  );
};

export default ArchiveTeamMemberModal;
