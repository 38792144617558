import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { translateError, ErrorKeys } from 'utils/error';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { ModalState } from 'state/modal';
import { ApiKeyForm } from 'models/ApiKey';
import { ILimitedAccessMemberForm } from 'models/Team';
import { closeModal, openModal } from 'state/modal/actions';
import { RootState } from 'state/root';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import { createApiKey, getApiKeys } from 'state/apiKeys/actions';
import { ApiKeysActionName } from 'state/apiKeys/types';
import config from 'config';

interface ConfirmApiKeyCreationParams {
  apiKeyData: Partial<ApiKeyForm>;
}

interface ConfirmLimitedAccessCreationParams
  extends Omit<ConfirmApiKeyCreationParams, 'apiKeyData'> {
  limitedAccessForm: ILimitedAccessMemberForm;
  apiKeyData?: never;
}

type ConfirmParams = ConfirmApiKeyCreationParams | ConfirmLimitedAccessCreationParams;

const messages = {
  textPersonalKey: 'Confirm creation of a personal API key',
  textTeamKey: 'Confirm creation of a team API key',
  textLimitedAccessKey: (emails: string) => (
    <p>
      Confirm that you wish to grant user(s) <span style={{ fontWeight: 'bold' }}>{emails}</span>{' '}
      limited access to your account.
    </p>
  ),
  mfaSuccess: 'API Key created',
  mfaFailure: 'Error creating API Key. Please try again.',
};

const errorKeys: ErrorKeys = {};

const ConfirmApiKeyCreation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [ApiKeysActionName.CREATE_API_KEY]);
  const error = errorSelector(requests, [ApiKeysActionName.CREATE_API_KEY]);

  const confirmState = useSelector(
    ({ modal }: { modal: ModalState<ConfirmParams> }) => modal.params
  );

  const paramsState =
    'apiKeyData' in confirmState
      ? (confirmState as ConfirmApiKeyCreationParams).apiKeyData
      : (confirmState as ConfirmLimitedAccessCreationParams).limitedAccessForm;

  let text: string | React.ReactNode = '';

  if ('apiKeyData' in confirmState) {
    text = confirmState.apiKeyData?.teamAccountNumber
      ? messages.textTeamKey
      : messages.textPersonalKey;
  } else {
    text = messages.textLimitedAccessKey(
      (paramsState as ILimitedAccessMemberForm).emails.join(', ')
    );
  }

  return (
    <ConfirmModal
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      text={text}
      submitBtnText={intl.formatMessage({ id: 'button.confirm' })}
      onSubmit={async () => {
        if ('apiKeyData' in confirmState) {
          const state = paramsState as Partial<ApiKeyForm>;
          await dispatch(createApiKey(state));
          dispatch(getApiKeys(undefined, true));
        } else {
          const state = paramsState as ILimitedAccessMemberForm;
          await dispatch(createApiKey(state));
          dispatch(getApiKeys({ types: 'portal-external' }, true));
        }

        dispatch(closeModal());
      }}
      onCancel={() => dispatch(closeModal())}
      timeout={parseInt(config.tokenExpirationSec, 10)}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
      }}
    />
  );
};
export default ConfirmApiKeyCreation;
