import React, { useEffect } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl } from 'react-intl';
import { uniq } from 'lodash';
import styles from 'views/styles';
import { Sandbox, SandboxReport, SandboxProviders, SandboxInstanceStatus } from 'models/Sandbox';
import PanelLoader from 'views/components/Loader/PanelLoader';
import AccordionData from 'views/components/Accordion/AccordionData';
import { ActiveSections, Section } from 'views/components/ActiveSections';
import ErrorPageContent, {
  messages as errorMessages,
} from 'views/components/error/ErrorPageContent';
import { RequestError } from 'utils/error';

interface INetworkTabProps {
  error: RequestError;
  status: SandboxInstanceStatus;
  provider: SandboxProviders;
  data: ReturnType<typeof getNetworkData>['data'];
}

const filterPresentValues = (data: Record<string, any> | undefined) => {
  if (!data) return undefined;
  const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      const filteredValues = value.filter(Boolean);
      if (filteredValues.length) {
        acc[key] = filteredValues;
      }
    } else if (value) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);

  return filteredData;
};

const checkForPresentValues = (data: Record<string, any> | undefined): boolean => {
  if (!data) return false;
  if (Array.isArray(data)) {
    if (data.length === 0) return false;
    return data
      .map((item) => {
        if (typeof item === 'object' && 'data' in item) {
          return checkForPresentValues(item.data);
        }
        return checkForPresentValues(item);
      })
      .some(Boolean);
  } else if (typeof data === 'object') {
    return Object.values(data).some((value) => {
      if (typeof value === 'object' && 'value' in value) {
        return checkForPresentValues(value.value);
      }
      return checkForPresentValues(value);
    });
  }
  return true;
};

const mapAndFlatten = (data: any) => (mapper: (item: any) => any) => {
  if (!data) return undefined;
  if (Array.isArray(data) && data.length === 0) return undefined;
  return data.map(mapper).filter(Boolean).flat(2);
};

const toArray = (...args: any[]) => {
  if (args.length === 0) return undefined;
  if (args.every((item) => item === undefined)) return undefined;
  return uniq(
    args
      .map((item) => item || [])
      .flat(2)
      .filter(Boolean)
  );
};

export const getNetworkData = (
  networkData: Sandbox<SandboxReport>['report']['network'],
  providerKey: SandboxProviders
) => {
  const extracted = networkData?.extracted || [];
  const jarm = networkData?.jarm || { hosts: [] };
  const suricataAlerts = networkData?.suricata_alerts || [];
  const mapExtracted = mapAndFlatten(extracted);

  const data = {
    IOCs: filterPresentValues({
      [providerKey === SandboxProviders.CAPE ? 'network.hosts.ip' : 'targets.iocs.ips']: {
        label: 'IPs',
        value: toArray(
          networkData?.network?.hosts?.map((host: { ip: string }) => host.ip),
          networkData?.network?.domains?.map((domain: { ip: string }) => domain.ip),
          networkData?.targets?.map((target: { iocs: { ips: string } }) => target?.iocs?.ips)
        ),
      },
      'targets.iocs.domains': {
        label: 'Domains',
        value: toArray(
          networkData?.targets?.map(
            (target: { iocs: { domains: string } }) => target?.iocs?.domains
          )
        ),
      },
      'targets.iocs.urls': {
        label: 'URLs',
        value: toArray(
          networkData?.targets?.map((target: { iocs: { urls: string } }) => target?.iocs?.urls)
        ),
      },
      'network.dead_hosts': { label: 'Dead Hosts', value: networkData?.network?.dead_hosts },
    }),
    C2: filterPresentValues({
      'extracted.config.c2': {
        label: 'Config C2',
        value: toArray(
          mapExtracted((item) => {
            return item?.config?.c2;
          }),
          networkData?.extracted_c2_ips
        ),
      },
    }),
    Backup: filterPresentValues({
      'extracted.config.attr.backup_connection_host': {
        label: 'Backup Host',
        value: mapExtracted((item) => {
          return item?.config?.attr?.backup_connection_host;
        }),
      },
      'extracted.config.attr.backup_dns_server': {
        label: 'Backup DNS',
        value: mapExtracted((item) => {
          return item?.config?.attr?.backup_dns_server;
        }),
      },
    }),
    DNS: filterPresentValues({
      'extracted.config.attr.dns_servers': {
        label: 'DNS Servers',
        value: mapExtracted((item) => {
          return item?.config?.attr?.dns_servers;
        }),
      },
    }),
    SMTP: filterPresentValues({
      'network.smtp.dst': {
        label: 'SMTP dst',
        value: networkData?.network?.smtp?.map((smtp: { dst: string }) => smtp.dst),
      },
      'network.smtp.raw': {
        label: 'SMTP raw',
        value: networkData?.network?.smtp?.map((smtp: { raw: string }) => smtp.raw),
      },
    }),
    Domain: filterPresentValues({
      'network.domains.domain': {
        label: 'Domains',
        value: toArray(
          networkData?.network?.dns?.map((dns: { request: string }) => dns.request),
          networkData?.network?.domains?.map((domain: { domain: string }) => domain.domain)
        ),
      },
    }),
    JARM: [
      {
        id: 'jarm.hosts',
        component: 'table' as const,
        data: jarm?.hosts,
      },
    ],
    SuricataAlerts: {
      'suricata_alerts.category': {
        label: 'Category',
        value: suricataAlerts.map((alert: { category: string }) => alert.category),
      },
      'suricata_alerts.dstip': {
        label: 'Dstip',
        value: suricataAlerts.map((alert: { dstip: string }) => alert.dstip),
      },
      'suricata_alerts.dstport': {
        label: 'Dstport',
        value: suricataAlerts.map((alert: { dstport: string }) => String(alert.dstport)),
      },
      'suricata_alerts.protocol': {
        label: 'Protocol',
        value: suricataAlerts.map((alert: { protocol: string }) => alert.protocol),
      },
      'suricata_alerts.signature': {
        label: 'Signature',
        value: suricataAlerts.map((alert: { signature: string }) => alert.signature),
      },
    },
  };

  return { data, hasPresentValues: checkForPresentValues(data) };
};

const NetworkTab = ({ error, status, provider, data }: INetworkTabProps) => {
  const intl = useIntl();
  const providerKey = provider === 'cape' ? 'cape_sandbox_v2' : 'triage_sandbox_v0';

  if (error) {
    return (
      <ErrorPageContent
        className='h-mt-lg h-mb-lg'
        heading={intl.formatMessage(errorMessages.headingReload)}
        text={intl.formatMessage(errorMessages.textReload)}
      ></ErrorPageContent>
    );
  }

  if ([SandboxInstanceStatus.PENDING, SandboxInstanceStatus.STARTED].includes(status)) {
    return <PanelLoader />;
  }

  return (
    <div css={style.root} data-cy='scanFileNetwork'>
      {checkForPresentValues(data) ? (
        <>
          <ActiveSections
            sections={
              [
                {
                  label: 'N',
                  title: 'Network Details',
                  active: checkForPresentValues(data.IOCs),
                },
                provider === SandboxProviders.TRIAGE && {
                  label: 'C',
                  title: 'Config C2',
                  active: checkForPresentValues(data.C2),
                },
                provider === SandboxProviders.TRIAGE && {
                  label: 'B',
                  title: 'Backup',
                  active: checkForPresentValues(data.Backup),
                },
                provider === SandboxProviders.TRIAGE && {
                  label: 'D',
                  title: 'DNS',
                  active: checkForPresentValues(data.DNS),
                },
                {
                  label: 'S',
                  title: 'SMTP',
                  active: checkForPresentValues(data.SMTP),
                },
                {
                  label: 'D',
                  title: 'Domains',
                  active: checkForPresentValues(data.Domain),
                },
                {
                  label: 'J',
                  title: 'JARM Signatures',
                  active: checkForPresentValues(data.JARM),
                },
                {
                  label: 'S',
                  title: 'Suricata Alerts',
                  active: checkForPresentValues(data.SuricataAlerts),
                },
              ].filter(Boolean) as Section[]
            }
          />
          {checkForPresentValues(data.IOCs) && (
            <AccordionData
              id={providerKey}
              css={style.item}
              title='Network Details'
              data={data.IOCs}
              columns={2}
            />
          )}
          {checkForPresentValues(data.C2) && (
            <AccordionData
              css={style.item}
              id={providerKey}
              title='C2'
              data={filterPresentValues(data.C2)}
              columns={2}
            />
          )}
          {checkForPresentValues(data.Backup) && (
            <AccordionData
              css={style.item}
              id={providerKey}
              title='Backup'
              data={filterPresentValues(data.Backup)}
              columns={2}
            />
          )}
          {checkForPresentValues(data.DNS) && (
            <AccordionData
              css={style.item}
              id={providerKey}
              title='DNS'
              data={filterPresentValues(data.DNS)}
              columns={2}
            />
          )}
          {checkForPresentValues(data.SMTP) && (
            <AccordionData
              css={style.item}
              id={providerKey}
              title='SMTP'
              data={filterPresentValues(data.SMTP)}
              columns={2}
            />
          )}
          {checkForPresentValues(data.Domain) && (
            <AccordionData
              css={style.item}
              id={providerKey}
              title='Domain'
              data={filterPresentValues(data.Domain)}
              columns={2}
            />
          )}
          {checkForPresentValues(data.JARM) ? (
            <AccordionData
              key='accordion-data-jarm'
              css={style.item}
              title='JARM Signatures'
              data={data.JARM}
              columns={2}
            />
          ) : null}
          {checkForPresentValues(data.SuricataAlerts) ? (
            <AccordionData
              key='accordion-data-suricata-alerts'
              css={style.item}
              id={providerKey}
              title='Suricata Alerts'
              data={data.SuricataAlerts}
              columns={2}
            />
          ) : null}
        </>
      ) : (
        <div>No Network Data</div>
      )}
    </div>
  );
};

const style = {
  root: css`
    margin: calc(${styles.spacing.grid} / 2) ${styles.spacing.grid};
  `,
  item: css`
    margin-bottom: ${styles.spacing.grid};
    &:last-child {
      margin-bottom: 0;
    }
  `,
};
export default NetworkTab;
