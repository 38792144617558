import React from 'react'; // eslint-disable-line
import { DroppedFiles } from 'models/Sandbox';
import formatBytes from 'utils/formatBytes';

export const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};

export const mapDroppedFiles = (droppedData: DroppedFiles[]) => {
  if (!droppedData) return [];
  const data = droppedData.map((dropped: DroppedFiles) => {
    return removeEmpty({
      name: dropped.name,
      guest_paths: dropped.guest_paths,
      size: formatBytes(Number(dropped.size)),
      type: dropped.type,
      sha256: dropped.sha256,
      md5: dropped.md5,
      sha1: dropped.sha1,
      sha3_384: dropped.sha3_384,
      ssdeep: dropped.ssdeep,
      tlsh: dropped.tlsh,
    });
  });
  return data;
};
