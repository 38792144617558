import { defineMessages } from 'react-intl';

const metadataMessages = defineMessages({
  names: {
    id: 'fileDetails.names',
    defaultMessage: 'Names',
  },
  first_seen: {
    id: 'fileDetails.firstSeen',
    defaultMessage: 'First Seen',
  },
  last_scanned: {
    id: 'fileDetails.lastScanned',
    defaultMessage: 'Last Scanned',
  },
  mimetype: {
    id: 'fileDetails.mimetype',
    defaultMessage: 'MIME Type',
  },
  extended_type: {
    id: 'fileDetails.extendedType',
    defaultMessage: 'Extended Type',
  },
  md5: {
    id: 'fileDetails.md5',
    defaultMessage: 'MD5',
  },
  hash: {
    id: 'fileDetails.hash',
    defaultMessage: 'Hash',
  },
  sha1: {
    id: 'fileDetails.sha1',
    defaultMessage: 'SHA-1',
  },
  sha256: {
    id: 'fileDetails.sha256',
    defaultMessage: 'SHA-256',
  },
  ssdeep: {
    id: 'fileDetails.ssdeep',
    defaultMessage: 'ssdeep',
  },
  tlsh: {
    id: 'fileDetails.tlsh',
    defaultMessage: 'TLSH',
  },
  lief: {
    id: 'fileDetails.lief',
    defaultMessage: 'Lief',
  },
  strings: {
    id: 'fileDetails.strings',
    defaultMessage: 'Strings',
  },
  exiftool: {
    id: 'fileDetails.exiftool',
    defaultMessage: 'Exiftool',
  },
  MachineType: {
    id: 'fileDetails.machineType',
    defaultMessage: 'Machine Type',
  },
  TimeStamp: {
    id: 'fileDetails.timeStamp',
    defaultMessage: 'Time Stamp',
  },
  EntryPoint: {
    id: 'fileDetails.entryPoint',
    defaultMessage: 'Entry Point',
  },
  FileType: {
    id: 'fileDetails.fileType',
    defaultMessage: 'File Type',
  },
  FileTypeExtension: {
    id: 'fileDetails.fileTypeExtension',
    defaultMessage: 'File Type Extension',
  },
  FileSize: {
    id: 'fileDetails.fileSize',
    defaultMessage: 'File Size',
  },
  MIMEType: {
    id: 'fileDetails.mimeType',
    defaultMessage: 'MIME Type',
  },
  domains: {
    id: 'fileDetails.domains',
    defaultMessage: 'Domain-like Strings',
  },
  ipv4: {
    id: 'fileDetails.ipv4',
    defaultMessage: 'IPV4-like Strings',
  },
  ipv6: {
    id: 'fileDetails.ipv6',
    defaultMessage: 'IPV6-like Strings',
  },
  urls: {
    id: 'fileDetails.urls',
    defaultMessage: 'URL-like Strings',
  },
  is_pie: {
    id: 'fileDetails.isPie',
    defaultMessage: 'Position-independent Executable',
  },
  libraries: {
    id: 'fileDetails.libraries',
    defaultMessage: 'Import Libraries',
  },
  imported_functions: {
    id: 'fileDetails.importedFunctions',
    defaultMessage: 'Imported Functions',
  },
  exported_functions: {
    id: 'fileDetails.exportedFunctions',
    defaultMessage: 'External Functions',
  },
  has_nx: {
    id: 'fileDetails.hasNx',
    defaultMessage: 'DEP',
  },
  force_integrity: {
    id: 'fileDetails.forceIntegrity',
    defaultMessage: 'Force Integrity',
  },
  force_no_isolation: {
    id: 'fileDetails.forceNoIsolation',
    defaultMessage: 'Force No Isolation',
  },
  has_export_table: {
    id: 'fileDetails.hasExportTable',
    defaultMessage: 'Has Export Table?',
  },
  has_import_table: {
    id: 'fileDetails.hasImportTable',
    defaultMessage: 'Has Import Table',
  },
  high_entropy_aslr: {
    id: 'fileDetails.highEntropyAslr',
    defaultMessage: 'High Entropy ASLR',
  },
  is_dll: {
    id: 'fileDetails.isDll',
    defaultMessage: 'Is DLL',
  },
  is_exe: {
    id: 'fileDetails.isExe',
    defaultMessage: 'Is EXE',
  },
  is_driver: {
    id: 'fileDetails.isDriver',
    defaultMessage: 'Is Driver',
  },
  is_suspicious: {
    id: 'fileDetails.isSuspicious',
    defaultMessage: 'Is Suspicious',
  },
  uses_aslr: {
    id: 'fileDetails.sesAslr',
    defaultMessage: 'Uses ASLR',
  },
  uses_cfg: {
    id: 'fileDetails.usesCfg',
    defaultMessage: 'Uses CFG',
  },
  uses_dep: {
    id: 'fileDetails.usesDep',
    defaultMessage: 'Uses DEP',
  },
  uses_seh: {
    id: 'fileDetails.usesSeh',
    defaultMessage: 'Uses SEH',
  },
  is_probably_packed: {
    id: 'fileDetails.isProbablyPacked',
    defaultMessage: 'Is probably packed',
  },
  compile_date: {
    id: 'fileDetails.compileDate',
    defaultMessage: 'Compile Date',
  },
  is_valid: {
    id: 'fileDetails.isValid',
    defaultMessage: 'Valid NT Header',
  },
  verify_checksum: {
    id: 'fileDetails.verifyChecksum',
    defaultMessage: 'Verify Checksum',
  },
  pefile: {
    id: 'fileDetails.pefile',
    defaultMessage: 'PEFile',
  },
  Author: {
    id: 'fileDetails.author',
    defaultMessage: 'Author',
  },
  CreateDate: {
    id: 'fileDetails.createDate',
    defaultMessage: 'Created',
  },
  ModifyDate: {
    id: 'fileDetails.modifyDate',
    defaultMessage: 'Modified',
  },
  CodePage: {
    id: 'fileDetails.codePage',
    defaultMessage: 'Code Page',
  },
  OriginalFileName: {
    id: 'fileDetails.originalFileName',
    defaultMessage: 'Original File Name',
  },
  imphash: {
    id: 'fileDetails.imphash',
    defaultMessage: 'Import Hash',
  },
});

export default metadataMessages;
