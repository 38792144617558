import React, { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import PageSidebarLayout from 'views/components/layout/PageSidebarLayout';
import SearchSidebar from './SearchSidebar';
import SearchPanel from './SearchPanel';

const messages = defineMessages({
  title: {
    id: 'searchPage.title',
    defaultMessage: 'Search',
  },
});

const SearchPage = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <PageSidebarLayout
          renderSidebar={() => <SearchSidebar />}
          renderPanel={() => <SearchPanel />}
        />
      </BaseLayout>
    </Fragment>
  );
};

export default SearchPage;
