import React, { Fragment, forwardRef } from 'react'; // eslint-disable-line
import { jsx, ObjectInterpolation } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { MenuOption } from './ConfigMenu';

type Props = Partial<MenuOption>;

const UserMenuItem = forwardRef<any, Props>((props, ref) => {
  const { href, onClick, spacer, dataCy } = props;
  const { classes } = useStyles(spacer);
  const muiClasses = useMuiStyles();

  return (
    <Fragment>
      {href ? (
        <MenuItem
          data-cy={dataCy}
          component='a'
          ref={ref}
          href={href}
          target='_blank'
          rel='noopener noreferrer'
          css={classes.menuItem}
          classes={{ root: muiClasses.item }}
        >
          <ConfigMenuItemInner {...props} />
        </MenuItem>
      ) : (
        <MenuItem
          data-cy={dataCy}
          ref={ref}
          css={classes.menuItem}
          classes={{ root: muiClasses.item }}
          onClick={onClick}
        >
          <ConfigMenuItemInner {...props} />
        </MenuItem>
      )}
    </Fragment>
  );
});

const ConfigMenuItemInner = ({ iconName, label, spacer }: Props) => {
  const { classes } = useStyles(spacer);
  return (
    <Fragment>
      <div css={classes.iconWrap}>{iconName && <Icon css={classes.icon} name={iconName} />}</div>
      {label}
    </Fragment>
  );
};

const spacerStyles = (color: string): ObjectInterpolation<any> => ({
  marginBottom: '1.6rem !important',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-0.8rem',
    left: 0,
    display: 'block',
    height: 1,
    width: '100%',
    backgroundColor: color,
  },
});

const useStyles = makeStyles((spacer?: boolean) => ({
  base: {
    iconWrap: {
      display: 'flex',
      marginRight: '1.2rem',
    },
    icon: {
      width: 24,
      height: '3.2em',
    },
    avatar: {
      height: '2.5em',
      width: '2.5em',
      img: {
        left: '0.1em',
      },
    },
  },
  light: {
    iconWrap: {
      color: styles.color.purple,
    },
    menuItem: spacer ? spacerStyles(styles.color.xxxLightGrey) : {},
  },
  dark: {
    iconWrap: {
      color: styles.color.lightBlue,
    },
    menuItem: spacer ? spacerStyles(styles.border.color.darkPurple) : {},
  },
}));

const useMuiStyles = makeMuiStyles({
  item: {
    fontSize: styles.font.size.p1,
    transition: `color ${styles.easing.time} ${styles.easing.main}`,
    overflow: 'visible',
  },
});

export default UserMenuItem;
