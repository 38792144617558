// @ts-nocheck
import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Filter } from 'models/Ruleset';
import styles from 'views/styles';
import { btnMessages } from 'views/components/Button';
import FilterForm from '../forms/FilterForm';

const removeEmptyValues = (obj: any) =>
  Object.fromEntries(
    Object.entries(obj)
      .map(([k, v]) => {
        return [k, k === 'community' ? v?.toLowerCase() : v];
      })
      .filter(([_, v]) => v !== '')
  );

const FilterModal = ({ onClose }: { onClose: () => void }) => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const params = qs.parse(location.search);

  const _onSubmit = (values: Filter) => {
    const newParams = { ...params, ...values };

    history.replace(`${location.pathname}?${qs.stringify(removeEmptyValues(newParams))}`);
  };

  return (
    <FilterForm
      onFilterClose={onClose}
      initialValues={params}
      css={ownStyle}
      heading={'Filter'}
      buttonText={intl.formatMessage(btnMessages.save)}
      isLoading={false}
      errorMessage={null}
      onSubmit={_onSubmit}
    />
  );
};

const ownStyle = css`
  padding: ${styles.spacing.lg};
`;
export default FilterModal;
