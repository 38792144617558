import React, { FC, Fragment, ReactNode } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import StatusIcon from 'views/components/StatusIcon';
import { Filter } from 'views/components/utils';

interface Props {
  className?: string;
  status?: '404' | '500' | 'warning' | 'default';
  heading: string;
  text?: string | ReactNode;
}

const ErrorPageContent: FC<Props> = ({ className, status, heading, text, children }) => (
  <div className={className} css={style.root} data-cy='errorPageContent'>
    <Filter
      currentState={status || 'default'}
      states={{
        default: <DefaultStatus heading={heading} text={text} />,
        warning: <WarningStatus heading={heading} text={text} />,
        '404': <PageNotFoundStatus heading={heading} text={text} />,
        '500': <ServerErrorStatus heading={heading} text={text} />,
      }}
    />
    {children && (
      <div className='h-mt-sm' css={style.buttonWrap}>
        {children}
      </div>
    )}
  </div>
);

const DefaultStatus = ({ heading, text }: Props) => (
  <Fragment>
    <StatusIcon status='reload' />
    <h1 className='h2 h-mt-sm h-mb-xxs'>{heading}</h1>
    {text && <p className='p'>{text}</p>}
  </Fragment>
);

const PageNotFoundStatus = ({ heading, text }: Props) => (
  <Fragment>
    <StatusIcon status='error' value={404} />
    <h1 className='h2 h-mt-sm h-mb-xxs'>{heading}</h1>
    {text && <p className='p'>{text}</p>}
  </Fragment>
);

export const ServerErrorStatus = ({ heading, text }: Props) => (
  <Fragment>
    <StatusIcon status='error' value={500} />
    <h1 className='h2 h-mt-sm h-mb-xxs'>{heading}</h1>
    {text && <p className='p'>{text}</p>}
  </Fragment>
);

export const WarningStatus = ({ heading, text }: Props) => (
  <Fragment>
    <StatusIcon status='warning' />
    <h1 css={style.h1}>{heading}</h1>
    {text && <p css={style.p}>{text}</p>}
  </Fragment>
);

const style = {
  root: css`
    text-align: center;
    max-width: 60rem;
    margin: 0 auto;
  `,
  buttonWrap: css`
    button {
      font-size: 2rem;
    }
  `,
  h1: css`
    font-weight: 600;
    font-size: 2.8rem;
    margin-bottom: 1.5rem;
  `,
  p: css`
    text-align: left;
    font-size: 1.5rem;
  `,
};

export default ErrorPageContent;
