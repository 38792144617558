import tenant from 'tenants';
import type { Pages } from 'tenants/config';
import { useUser } from 'views/components/providers/UserProvider';

export function useIsPageEnabled() {
  const user = useUser();

  const isEnabled = (page: Pages) => {
    if (user.id === -1 && tenant.isLogingRequired) {
      return tenant.publicPages?.includes(page);
    }
    if (tenant.enabledPages === 'all') {
      if (tenant.disabledPages?.includes(page)) {
        return false;
      }
      return true;
    }
    return tenant.enabledPages.includes(page);
  };

  const shouldBeOnNavbar = (page: Pages) => {
    if (tenant.navbarPages) {
      return tenant.navbarPages.includes(page);
    }
    return true;
  };

  return {
    isEnabled,
    shouldBeOnNavbar,
  };
}
