import React from 'react'; // eslint-disable-line
import { jsx, keyframes } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface ILoader {
  className?: string;
  testId?: string;
}

const Loader = ({ className, testId }: ILoader) => {
  const { classes } = useStyles();
  return (
    <div className={className} css={classes.root} data-testid={testId || 'loader'}>
      <svg css={classes.svg} viewBox='0 0 132 132' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <circle
            css={classes.circle}
            strokeWidth='12'
            opacity='0.200000003'
            cx='66'
            cy='66'
            r='60'
          />
          <path
            css={classes.line}
            strokeWidth='12'
            strokeLinecap='round'
            strokeDasharray='80,9999'
            d='M66,126 C99.137085,126 126,99.137085 126,66 C126,32.862915 99.137085,6 66,6 C32.862915,6 6,32.862915 6,66 C6,99.137085 32.862915,126 66,126 Z'
          />
        </g>
      </svg>
    </div>
  );
};

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

const useStyles = makeStyles({
  base: {
    root: {
      fontSize: '1.2rem',
    },
    svg: {
      width: '13.2em',
      height: '13.2em',
    },
    line: {
      transformOrigin: 'center',
      animation: `${spin} 0.8s linear infinite`,
    },
  },
  light: {
    circle: {
      stroke: styles.color.lightPurple,
    },
    line: {
      stroke: styles.color.purple,
    },
  },
  dark: {
    circle: {
      stroke: styles.color.lightPurple,
    },
    line: {
      stroke: styles.color.lightBlue,
    },
  },
});

export default Loader;
