export interface ITransactions {
  id: string;
  amount: string;
  fullAmount: string;
  status: string;
  type: string;
  timestamp: string;
  fee: string;
  fullFee: string;
  total: string;
  fullTotal: string;
}
export enum WalletsTypeEnum {
  engine = 'engine',
  reward = 'reward',
}
export type WalletsType = keyof typeof WalletsTypeEnum;

export type Order = 'asc' | 'desc';
