import { json2csv } from 'json-2-csv';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';

const json2csvCallback = (fileName: string) =>
  function (err: Error | undefined, csv: string | undefined) {
    if (err) throw err;
    if (csv) {
      const [oldHeader, ...content] = csv.split('\n');
      const header = oldHeader
        .split(',')
        .map((column: string) => column.replace('.', '_'))
        .join(',');
      const csvContent = [header, ...content].join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `${format(new Date(), 'yyyyddMM-hhmm')}-${fileName}`);
    }
  };

export const toCSV = (json: any, fileName: string, columns: string[]) => {
  json2csv(json, json2csvCallback(fileName), {
    keys: columns,
    emptyFieldValue: '',
  });
};
