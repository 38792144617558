import React, { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import HowItWorksContent from './HowItWorksContent';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

const messages = defineMessages({
  title: {
    id: 'howItWorksPage.title',
    defaultMessage: 'How It Works',
  },
});

const HowItWorksPage = () => {
  const intl = useIntl();
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <HowItWorksContent />
      </BaseLayout>
    </Fragment>
  );
};

export default HowItWorksPage;
