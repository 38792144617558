export const getFromStorage = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : undefined;
};

export const setToStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = (key: string) => {
  const value = sessionStorage.getItem(key);
  return value ? JSON.parse(value) : undefined;
};

export const setToSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getAccountStoredKey = <T = any>(
  accountNumber: number | string,
  key: string,
  defaultValue: any = null
): T => {
  let val = JSON.parse(localStorage.getItem(`${key}-${accountNumber}`) || 'null');
  if (val === null) {
    // Old versions of portal only stored one history for all users so let's check
    try {
      val = JSON.parse(localStorage.getItem(key) || 'null');
    } catch (e) {
      // Some old versions of portal stored strings instead of objects so are not parseable
      val = localStorage.getItem(key);
    }
    if (val !== null) {
      // Migrate old format
      storeAccountKey(accountNumber, key, val);
      localStorage.removeItem(key);
    } else {
      val = defaultValue;
    }
  }
  return val;
};

export const storeAccountKey = (accountNumber: number | string, key: string, value: any) => {
  localStorage.setItem(`${key}-${accountNumber}`, JSON.stringify(value));
};

export const removeAccountKey = (accountNumber: number | string, key: string) => {
  localStorage.removeItem(`${key}-${accountNumber}`);
};
