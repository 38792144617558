import React, { useState, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { object as yupObject, date as yupDate, string as yupString } from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import { Formik, FormikProps } from 'formik';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { showNotification } from 'state/notification/actions';
import { errorMessages } from 'utils/error';
import { toISODate } from 'utils/date/date';
import { Integration } from 'models/Integration';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { btnMessages } from 'views/components/Button';
import FabButton from 'views/components/Button/FabButton';
import { useUser } from 'views/components/providers/UserProvider';
import FormInput from 'views/components/form/FormInput';
import { DatePicker } from '../ApiKeyTable/DatePicker';

interface IIntegrationFormValues {
  name: string;
  expiration: Date | null;
  neverExpires: boolean;
}

interface IIntegrationDialog {
  onIntegrationCreate: (apiKeyData: Partial<Integration>) => void;
  btnType?: 'icon' | 'button';
}

const messages = defineMessages({
  title: {
    id: 'team.integrations.type.label',
    defaultMessage: 'Create a new Integration',
  },
  name: {
    id: 'team.integrations.name.label',
    defaultMessage: 'Integration name',
  },
  apiKey: {
    id: 'team.integrations.apiKey.label',
    defaultMessage: 'API Key will be automatically generated',
  },
  mfaMessage: {
    id: 'team.integrations.account.mfaMessage',
    defaultMessage:
      'Pressing "Save" you\'ll be redirected to the PolySwarm authO login page to enter your two factor authentication ' +
      'to confirm the new integration.',
  },
});

const ContentWrapper = styled.div`
  width: 65rem;
  padding: ${styles.spacing.md};
`;

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  *:first-of-type {
    margin-right: 5px !important;
  }

  *:last-of-type {
    margin-left: 5px !important;
  }
`;

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

const schema = yupObject({
  expiration: yupDate().min(new Date()).max(new Date('9999-12-12')).nullable(),
  name: yupString().required(),
});

export const CreateIntegrationDialog = ({
  onIntegrationCreate,
  btnType = 'icon',
}: IIntegrationDialog) => {
  const intl = useIntl();
  const user = useUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const _onClick = () => {
    if (user.mfaEnabled) {
      setOpen(true);
    } else {
      dispatch(
        showNotification({
          status: 'failure',
          message: errorMessages.mfaRequired,
          delay: 1000 * 10,
        })
      );
      history.replace('/account/advanced');
    }
  };

  const _onClose = () => {
    setOpen(false);
  };

  const _onSubmit = (values: IIntegrationFormValues) => {
    const { neverExpires, ...formValues } = values;
    const data = {
      ...formValues,
      expiration: neverExpires ? null : toISODate(formValues.expiration || new Date()),
    };
    onIntegrationCreate(data);
  };

  return (
    <Fragment>
      <Tooltip title={intl.formatMessage(messages.title)} placement='top'>
        <div>
          {btnType === 'icon' ? (
            <FabButton icon='plus' onClick={_onClick} />
          ) : (
            <Button
              data-cy='createIntegrationBtn'
              variant='contained'
              color='primary'
              onClick={_onClick}
            >
              <FormattedMessage {...messages.title} />
            </Button>
          )}
        </div>
      </Tooltip>
      <Dialog open={open} onClose={_onClose} maxWidth='md'>
        <ContentWrapper>
          <div css={classes.header}>{intl.formatMessage(messages.title)}</div>
          <Formik
            validationSchema={schema}
            onSubmit={_onSubmit}
            initialValues={{ name: '', expiration: null, neverExpires: false }}
          >
            {(formik: FormikProps<IIntegrationFormValues>) => (
              <form onSubmit={formik.handleSubmit}>
                <FormInput
                  name='name'
                  placeholder={intl.formatMessage(messages.name)}
                  label={intl.formatMessage(messages.name)}
                />
                <div css={classes.expiresContainer}>
                  <DatePicker
                    label='Expiration Date'
                    disabled={formik.values.neverExpires}
                    value={formik.values.expiration}
                    onChange={(value) => {
                      formik.setFieldValue('expiration', value ?? null);
                    }}
                  />
                  <div css={classes.expiresCheckbox}>
                    <Checkbox
                      disabled={false}
                      color='primary'
                      checked={formik.values.neverExpires}
                      onChange={(value) => {
                        formik.setFieldValue('neverExpires', value.target.checked);
                      }}
                    />
                    <span css={classes.neverExpiresLabel}>Never Expire</span>
                  </div>
                </div>
                <p css={classes.mfaMessage}>{intl.formatMessage(messages.apiKey)}</p>
                {user.mfaEnabled && (
                  <p css={classes.mfaMessage}>{intl.formatMessage(messages.mfaMessage)}</p>
                )}
                <Buttons>
                  <StyledButton
                    data-cy='submitBtn'
                    className='h-mt-xs'
                    color='primary'
                    variant='contained'
                    type='submit'
                    disabled={
                      !(
                        formik.dirty &&
                        formik.isValid &&
                        formik.values.name &&
                        (formik.values.expiration || formik.values.neverExpires)
                      )
                    }
                  >
                    <FormattedMessage {...btnMessages.save} />
                  </StyledButton>
                  <StyledButton
                    data-cy='cancelBtn'
                    className='h-mt-xs'
                    color='primary'
                    variant='outlined'
                    onClick={_onClose}
                  >
                    <FormattedMessage {...btnMessages.cancel} />
                  </StyledButton>
                </Buttons>
              </form>
            )}
          </Formik>
        </ContentWrapper>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    expiresContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '3rem 0px 0px',
    },
    header: {
      textAlign: 'left',
      fontSize: '2.6rem',
      fontWeight: 'bold',
      paddingBottom: styles.spacing.xs,
    },
    mfaMessage: {
      textAlign: 'center',
      paddingTop: styles.spacing.sm,
    },
    neverExpiresLabel: {
      fontWeight: 'bold',
    },
    expiresCheckbox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 'fit-content',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
  },
});
