import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-globe' viewBox='0 0 32 32'>
      <path d='M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16 0-4.243-1.686-8.313-4.686-11.314s-7.070-4.686-11.314-4.686zM27.072 9.6h-4.464c-0.303-1.858-0.841-3.67-1.6-5.392 2.544 1.106 4.668 2.995 6.064 5.392zM28.8 16c-0.007 1.080-0.147 2.154-0.416 3.2h-5.344c0.080-1.056 0.128-2.128 0.128-3.2s0-2.144-0.128-3.2h5.344c0.269 1.046 0.409 2.12 0.416 3.2zM16 28.8c-0.944 0-2.512-2.224-3.36-6.4h6.72c-0.848 4.176-2.416 6.4-3.36 6.4zM12.176 19.2c-0.096-0.992-0.144-2.064-0.144-3.2s0-2.208 0.144-3.2h7.648c0.096 0.992 0.144 2.064 0.144 3.2s0 2.208-0.144 3.2h-7.648zM3.2 16c0.007-1.080 0.147-2.154 0.416-3.2h5.344c0 1.056-0.128 2.128-0.128 3.2s0 2.144 0.128 3.2h-5.344c-0.269-1.046-0.409-2.12-0.416-3.2zM16 3.2c0.944 0 2.512 2.224 3.36 6.4h-6.72c0.848-4.176 2.416-6.4 3.36-6.4zM11.008 4.208c-0.765 1.722-1.308 3.534-1.616 5.392h-4.464c1.4-2.4 3.53-4.289 6.080-5.392zM4.928 22.4h4.464c0.303 1.858 0.841 3.669 1.6 5.392-2.544-1.106-4.668-2.995-6.064-5.392zM20.928 27.792c0.766-1.72 1.304-3.533 1.6-5.392h4.464c-1.379 2.387-3.48 4.275-6 5.392h-0.064z' />
    </symbol>

    <Global
      styles={css`
        .icon-globe {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
