import React, { useState, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import styled from '@emotion/styled';
import { Formik, FormikProps } from 'formik';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { btnMessages } from 'views/components/Button';
import FormInput from 'views/components/form/FormInput';
import { errorSelector } from 'state/requests/selectors';
import {
  formatErrorMessage,
  getErrorData,
  getErrorFields,
  getErrorMessage,
  getErrorMessages,
} from 'utils/error';
import { RootState } from 'state/root';
import { WebhooksActionName } from 'state/webhooks/types';

interface IWebhookFormValues {
  url: string;
  requestsPerDay: number;
}

interface ICreateWebhookDialog {
  children: any;
  createWebhook: (values: IWebhookFormValues) => void;
}

const messages = defineMessages({
  url: {
    id: 'account.webhooks.createWebhook.url',
    defaultMessage: 'Webhook url',
  },
  requestsPerDay: {
    id: 'account.webhooks.createWebhook.requestsPerDay',
    defaultMessage: 'Rate limit (messages per day)',
  },
});

const ContentWrapper = styled.div`
  width: 548px;
  padding: ${styles.spacing.md};
`;

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  *:first-of-type {
    margin-right: 5px !important;
  }

  *:last-of-type {
    margin-left: 5px !important;
  }
`;

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

export const CreateWebhookDialog = ({ createWebhook, children }: ICreateWebhookDialog) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);

  const error = errorSelector(requests, [WebhooksActionName.CREATE_WEBHOOK]);
  const errorMessage = error && getErrorMessage(error);
  const errorData = error && getErrorData(error);
  const errorFields = getErrorFields(errorData);
  const errorMessages = getErrorMessages(errorData, errorMessage);

  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const _onClick = () => {
    setOpen(true);
  };

  const _onClose = () => {
    setOpen(false);
  };

  const _onSubmit = (values: IWebhookFormValues) => {
    return dispatch(createWebhook(values));
  };

  return (
    <Fragment>
      {children(_onClick)}
      <Dialog open={open} onClose={_onClose}>
        <ContentWrapper>
          <div css={classes.header}>
            <FormattedMessage
              id='account.webhooks.createWebhook.header'
              defaultMessage='Create webhook'
            />
          </div>

          <Formik onSubmit={_onSubmit} initialValues={{ url: '', requestsPerDay: 100 }}>
            {(formik: FormikProps<IWebhookFormValues>) => (
              <form onSubmit={formik.handleSubmit}>
                <FormInput
                  className='h-mb-sm'
                  name='url'
                  label={intl.formatMessage(messages.url)}
                  errorOnTouched
                  error={errorFields.includes('url')}
                  helperText={formatErrorMessage(errorMessages['url'])}
                />
                <FormInput
                  className='h-mb-sm'
                  name='requestsPerDay'
                  label={intl.formatMessage(messages.requestsPerDay)}
                  errorOnTouched
                  error={errorFields.includes('requestsPerDay')}
                  helperText={formatErrorMessage(errorMessages['requestsPerDay'])}
                />
                <Buttons>
                  <StyledButton
                    data-cy='submitBtn'
                    className='h-mt-xs'
                    color='primary'
                    variant='contained'
                    type='submit'
                    disabled={!(formik.dirty && formik.isValid)}>
                    <FormattedMessage {...btnMessages.save} />
                  </StyledButton>
                  <StyledButton
                    data-cy='cancelBtn'
                    className='h-mt-xs'
                    color='primary'
                    variant='outlined'
                    onClick={_onClose}>
                    <FormattedMessage {...btnMessages.cancel} />
                  </StyledButton>
                </Buttons>
              </form>
            )}
          </Formik>
        </ContentWrapper>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    header: {
      textAlign: 'left',
      fontSize: '2.6rem',
      fontWeight: 'bold',
      paddingBottom: styles.spacing.xs,
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
  },
});
