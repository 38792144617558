import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-rescan' viewBox='0 0 32 32'>
      <path d='M24.48 7.509c-4.685-4.679-12.275-4.679-16.96 0l-0.853 0.893-2.667-2.667v7.587h7.547l-3.040-3.040 0.893-0.893c3.070-3.039 7.824-3.574 11.493-1.294s5.293 6.78 3.927 10.878c-1.366 4.098-5.366 6.723-9.669 6.346s-7.785-3.658-8.418-7.931h-2.667c0.288 2.708 1.503 5.233 3.44 7.147 4.702 4.687 12.313 4.675 17-0.027s4.675-12.313-0.027-17z' />
    </symbol>

    <Global
      styles={css`
        .icon-rescan {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
