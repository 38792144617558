import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import { useIntl, defineMessages } from 'react-intl';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys, errorMessages } from 'utils/error';
import { closeModal } from 'state/modal/actions';
import { HuntingActionName } from 'state/hunting/types';
import { runLiveHunt } from 'state/hunting/actions';
import { btnMessages } from 'views/components/Button';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

export interface RunLiveHuntModalParams {
  id: string;
  name: string;
}

const messages = defineMessages({
  heading: {
    id: 'rulesets.modal.liveHuntHeading',
    defaultMessage: 'Run Live Hunt',
  },
  text: {
    id: 'rulesets.modal.liveHuntText',
    defaultMessage: 'Ready to run a live hunt with this ruleset. Would you like to continue?',
  },
});

const errorKeys: ErrorKeys = {
  rate_limit_exceeded: errorMessages.livehuntRateLimit.id,
};

/**
 * The RunLiveHuntModal component
 */
const RunLiveHuntModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [HuntingActionName.RUN_LIVE_HUNT]);
  const error = errorSelector(requests, [HuntingActionName.RUN_LIVE_HUNT]);
  const { event: gaEvent } = useAnalytics();

  const { id, name } = useSelector(
    ({ modal }: { modal: ModalState<RunLiveHuntModalParams> }) => modal.params
  );

  const _runLiveHunt = (id: string) => {
    dispatch(runLiveHunt(id, name));
    gaEvent({
      category: 'Hunting',
      action: 'Live Hunt Started',
    });
  };

  return (
    <ConfirmModal
      testId='runLiveHuntModal'
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text)}
      submitBtnText={intl.formatMessage(btnMessages.run)}
      onSubmit={() => _runLiveHunt(id)}
      onCancel={() => dispatch(closeModal())}
    />
  );
};

export default RunLiveHuntModal;
