import { AsyncAction } from 'state/types/actions';
import { WebhooksRes, WebhookRes, WebhookAttemptRes } from 'services/api/schema/webhooks';

export enum WebhooksActionName {
  GET_WEBHOOKS = 'GET_WEBHOOKS',
  REFRESH_WEBHOOKS = 'REFRESH_WEBHOOKS',
  CREATE_WEBHOOK = 'CREATE_WEBHOOK',
  TEST_WEBHOOK = 'TEST_WEBHOOK',
  ARCHIVE_WEBHOOK = 'ARCHIVE_WEBHOOK',
}

export interface GetWebhooksAction
  extends AsyncAction<WebhooksActionName.GET_WEBHOOKS, WebhooksRes> {}

export interface RefreshWebhooksAction
  extends AsyncAction<WebhooksActionName.REFRESH_WEBHOOKS, WebhooksRes> {}

export interface CreateWebhookAction
  extends AsyncAction<WebhooksActionName.CREATE_WEBHOOK, WebhookRes> {}

export interface TestWebhookAction
  extends AsyncAction<WebhooksActionName.TEST_WEBHOOK, WebhookAttemptRes> {}

export interface ArchiveWebhookAction
  extends AsyncAction<WebhooksActionName.ARCHIVE_WEBHOOK, WebhookRes> {}

export type WebhooksAction =
  | GetWebhooksAction
  | RefreshWebhooksAction
  | CreateWebhookAction
  | TestWebhookAction
  | ArchiveWebhookAction;
