import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from 'state/root';
import { useUser } from 'views/components/providers/UserProvider';
import { useAuth } from 'views/components/providers/AuthProvider';
import { changePassword } from 'state/auth/actions';
import { translateError, ErrorKeys, errorMessages } from 'utils/error';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { UserActionName } from 'state/user/types';
import { updateUser } from 'state/user/actions';
import ProfileForm, { IProfileFormValues } from './ProfileForm';

const errorKeys: ErrorKeys = {
  username_exists: errorMessages.username.id,
};

const ProfileTab = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { username, firstName, lastName, email, company } = useUser();
  const { isPasswordUser } = useAuth();

  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [UserActionName.UPDATE_USER]);
  const error = errorSelector(requests, [UserActionName.UPDATE_USER]);

  const _handlePasswordChange = (email: string) => dispatch(changePassword(email, true));

  const _handleSubmit = (userValues: IProfileFormValues) => {
    const { username, firstName, lastName, company } = userValues; // cannot update email
    dispatch(updateUser({ username, firstName, lastName, company }, true));
  };

  return (
    <div css={style.root}>
      <div css={style.form}>
        <h4 className='h4 h-mb-xs'>{intl.formatMessage({ id: 'account.profile' })}</h4>
        <ProfileForm
          initialValues={{ username, firstName, lastName, email, company }}
          isLoading={isLoading}
          errorMessage={translateError(intl, errorKeys, error)}
          showChangePasswordLink={isPasswordUser}
          onPasswordChange={_handlePasswordChange}
          onSubmit={_handleSubmit}
        />
      </div>
    </div>
  );
};

const style = {
  root: css`
    display: flex;
    justify-content: center;
    padding: 30px 2.4rem;
  `,
  form: css`
    width: 66.6%;
  `,
};

export default ProfileTab;
