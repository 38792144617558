import { FC } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { ButtonHTMLProps } from 'views/components/Button';
import { ICON_REGISTRY } from 'views/components/Icon/IconBase';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';

interface IActionBtn extends ButtonHTMLProps {
  name: keyof typeof ICON_REGISTRY;
  isShown?: boolean;
}

const ActionBtn: FC<IActionBtn> = ({ name, isShown, ...rest }) => {
  const { classes } = useStyles(name);
  if (isShown === false) return null;
  const { title, ...otherBtnProps } = rest;
  return (
    <button css={classes.root} {...otherBtnProps}>
      <Icon name={name} />
    </button>
  );
};

const useStyles = makeStyles((name: IActionBtn['name']) => {
  const color = (color: string) =>
    name === 'delete' || name === 'remove' ? styles.color.red : color;

  return {
    base: {
      root: {
        cursor: 'pointer',
        fontSize: '0.7rem',
        marginLeft: '0.6rem',
        outline: 'none',
        '&:disabled': {
          color: styles.color.xLightGrey,
          cursor: 'not-allowed',
        },
      },
    },
    light: {
      root: {
        color: color(styles.color.purple),
      },
    },
    dark: {
      root: {
        color: color(styles.color.lightBlue),
      },
    },
  };
});

export default ActionBtn;
