import request from 'state/utils/request';
import api from 'services/api';
import { HuntingActionName, ResetMetadataSearchAction } from './types';
import { Artifact } from 'models/Submission';
import { RulesetValues, Ruleset, ScanType, Hunt } from 'models/Ruleset';
import { getAccountContext } from 'state/account/actions';
import { Thunk } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { history } from 'state/store';
import { PageQuery } from 'models/Page';
import { MetadataSearchResultsRes } from 'services/api/schema/submission';

export const getRuleset = (rulesetId: string) =>
  request(() => api.getRuleset(rulesetId).then((res) => res.data), {
    type: HuntingActionName.GET_RULESET,
  });

export const getAllRulesets = (params?: PageQuery<Ruleset>, refresh?: boolean) =>
  request(
    () => api.getAllRulesets(params).then((res) => res.data),
    refresh
      ? { type: HuntingActionName.REFRESH_ALL_RULESETS }
      : { type: HuntingActionName.GET_ALL_RULESETS }
  );

export const createRuleset = (
  values: RulesetValues,
  params?: PageQuery<Ruleset>,
  refresh?: boolean
): Thunk<Promise<void>> => async (dispatch) => {
  await dispatch(
    request(() => api.createRuleset(values).then((res) => res.data), {
      type: HuntingActionName.CREATE_RULESET,
    })
  );
  dispatch(closeModal());
  dispatch(getAllRulesets(params, refresh));
};

export const updateRuleset = (
  id: string,
  values: RulesetValues,
  params?: PageQuery<Ruleset>
): Thunk<Promise<void>> => async (dispatch) => {
  await dispatch(
    request(() => api.updateRuleset(id, values).then((res) => res.data), {
      type: HuntingActionName.UPDATE_RULESET,
    })
  );
  dispatch(closeModal());
  dispatch(getAllRulesets(params, true));
};

export const deleteRuleset = (
  id: string,
  params?: PageQuery<Ruleset>
): Thunk<Promise<void>> => async (dispatch) => {
  await dispatch(
    request(() => api.deleteRuleset(id).then((res) => res.data), {
      type: HuntingActionName.DELETE_RULESET,
    })
  );
  dispatch(closeModal());
  dispatch(getAllRulesets(params, true));
};

export const runLiveHunt = (id: string, ruleName: string): Thunk<Promise<void>> => async (
  dispatch
) => {
  await dispatch(
    request(() => api.runLiveHunt(id, ruleName).then((res) => res.data), {
      type: HuntingActionName.RUN_LIVE_HUNT,
    })
  );
  dispatch(getAccountContext());
  dispatch(closeModal());
  history.push('/hunt/live');
};

export const getAllLiveHunts = (all = true, refresh?: boolean, params?: PageQuery<Hunt>) =>
  request(
    () => api.getAllLiveHunts(all, params).then((res) => res.data),
    refresh
      ? { type: HuntingActionName.REFRESH_ALL_LIVE_HUNTS }
      : { type: HuntingActionName.GET_ALL_LIVE_HUNTS }
  );

export const getLiveHuntResults = (huntId = 'active') =>
  request(() => api.getHuntResults('live', huntId, { limit: 100 }).then((res) => res.data), {
    type: HuntingActionName.GET_LIVE_HUNT_RESULTS,
  });

export const cancelLiveHunt = (
  id: string,
  params?: PageQuery<Ruleset>
): Thunk<Promise<void>> => async (dispatch) => {
  await dispatch(
    request(() => api.cancelLiveHunt(id).then((res) => res.data), {
      type: HuntingActionName.CANCEL_LIVE_HUNT,
    })
  );
  dispatch(getAccountContext());
  dispatch(closeModal());
  dispatch(getAllRulesets(params, true));
};

export const cancelHistoricalHunt = (
  id: string,
  params?: PageQuery<Hunt>
): Thunk<Promise<void>> => async (dispatch) => {
  await dispatch(
    request(() => api.cancelHunt('historical', id).then((res) => res.data), {
      type: HuntingActionName.CANCEL_HISTORICAL_HUNT,
    })
  );
  dispatch(closeModal());
  dispatch(getAllHistoricalHunts(true, params));
};

export const deleteHunt = (
  scanType: ScanType,
  id: string,
  all = true,
  params?: any
): Thunk<Promise<void>> => async (dispatch) => {
  await dispatch(
    request(() => api.deleteHunt(id, scanType).then((res) => res.data), {
      type: HuntingActionName.DELETE_HUNT,
    })
  );

  dispatch(closeModal());

  if (scanType === 'live') {
    dispatch(getAllLiveHunts(all, true, params));
  } else {
    dispatch(getAllHistoricalHunts(true, params));
  }
};

export const deleteHuntResult = (
  scanType: ScanType,
  id: string,
  huntId: string,
  params?: any
): Thunk<Promise<void>> => async (dispatch) => {
  await dispatch(
    request(() => api.deleteHuntResult(id, scanType).then((res) => res.data), {
      type: HuntingActionName.DELETE_HUNT,
    })
  );

  dispatch(closeModal());

  dispatch(getHistoricalHuntResults(huntId, params));
};

export const runHistoricalHunt = (id: string, ruleName: string): Thunk<Promise<void>> => async (
  dispatch
) => {
  await dispatch(
    request(() => api.runHistoricalHunt(id, ruleName).then((res) => res.data), {
      type: HuntingActionName.RUN_HISTORICAL_HUNT,
    })
  );
  dispatch(closeModal());
  history.push('/hunt/historical');
};

export const getAllHistoricalHunts = (refresh?: boolean, params?: PageQuery<Hunt>) =>
  request(
    () => api.getAllHistoricalHunts(params).then((res) => res.data),
    refresh
      ? { type: HuntingActionName.REFRESH_ALL_HISTORICAL_HUNTS }
      : { type: HuntingActionName.GET_ALL_HISTORICAL_HUNTS }
  );

export const getHistoricalHuntResults = (huntId = 'active', params?: PageQuery<Hunt>) =>
  request(() => api.getHuntResults('historical', huntId, params).then((res) => res.data), {
    type: HuntingActionName.GET_HISTORICAL_HUNT_RESULTS,
  });

export const getMetadataSearchResults = (
  term: string,
  params?: PageQuery<Artifact>,
  loadMore?: boolean
): Thunk<Promise<MetadataSearchResultsRes>> => (dispatch) => {
  dispatch({ type: HuntingActionName.SET_METADATA_SEARCH_TERM, term });
  return dispatch(
    request(() => api.getMetadataSearchResults(term, params).then((res) => res.data), {
      type: loadMore
        ? HuntingActionName.GET_MORE_METADATA_SEARCH_RESULTS
        : HuntingActionName.GET_METADATA_SEARCH_RESULTS,
    })
  );
};

export const resetMetadataSearch = (): ResetMetadataSearchAction => ({
  type: HuntingActionName.RESET_METADATA_SEARCH,
});
