import React, { useEffect, useCallback } from 'react';
import { Switch, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useAuth } from 'views/components/providers/AuthProvider';
import ProtectedRoute from 'views/components/auth/ProtectedRoute';
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import joinUrl from 'utils/joinUrl';
import { selectMicroengine } from 'state/microengines/actions';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import AllMicroenginesTab from './MicroenginesTabs/AllMicroenginesTab';
import MyMicroenginesTab from './MicroenginesTabs/MyMicroenginesTab';
import FilterTagsDialog from './FilterTags/FilterTagsDialog';
import FilterEnginesDialog from './FilterEngines/FilterEnginesDialog';
import { Route, Tab } from 'tenants/components';
import { useIsTabEnabled } from 'hooks/useIsTabEnabled';

const messages = defineMessages({
  myEngines: {
    id: 'microengines.user',
    defaultMessage: 'My Engines',
  },
  allEngines: {
    id: 'microengines.all',
    defaultMessage: 'All Engines',
  },
  results: {
    id: 'microengines.results',
    defaultMessage: 'Development Results',
  },
});

const MicroenginesPanel = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const muiClasses = useMuiStyles();
  const { isAuthenticated } = useAuth();
  const tab = useIsTabEnabled();

  const urls =
    tab.isEnabled('engines', 'all') || tab.isEnabled('engines', 'allEngines')
      ? {
          allEngines: match.url,
          myEngines: joinUrl(match.url, 'my-engines'),
          results: joinUrl(match.url, 'development-results'),
        }
      : {
          myEngines: match.url,
          allEngines: joinUrl(match.url, 'all-engines'),
          results: joinUrl(match.url, 'development-results'),
        };

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) => history.push(pathname);

  const _location = location.pathname.substring(
    0,
    location.pathname.indexOf('/details') !== -1
      ? location.pathname.indexOf('/details')
      : location.pathname.length + 1
  );

  const resetSelectedEngine = useCallback(() => {
    dispatch(selectMicroengine(null));
  }, [dispatch]);

  useEffect(() => {
    return resetSelectedEngine;
  }, [resetSelectedEngine]);

  return (
    <Card>
      <Tabs indicatorColor='primary' value={_location} onChange={_handleChange}>
        <Tab
          value={urls.allEngines}
          label={intl.formatMessage(messages.allEngines)}
          data-cy='allMicroenginesTab'
          onClick={resetSelectedEngine}
          show={tab.isEnabled('engines', 'allEngines')}
        />
        <Tab
          className={!isAuthenticated ? muiClasses.hidden : ''}
          value={urls.myEngines}
          label={intl.formatMessage(messages.myEngines)}
          data-cy='myMicroenginesTab'
          onClick={resetSelectedEngine}
          show={tab.isEnabled('engines', 'myEngines')}
        />
      </Tabs>
      <Switch>
        <Route
          exact
          path={urls.allEngines}
          show={tab.isEnabled('engines', 'allEngines')}
          component={FilterTagsDialog}
        />
        <Route
          exact
          path={`${urls.allEngines}/details/:address`}
          show={tab.isEnabled('engines', 'allEngines')}
          component={FilterTagsDialog}
        />
        <ProtectedRoute exact path={urls.myEngines} component={FilterTagsDialog} />
        <ProtectedRoute exact path={urls.results} component={FilterEnginesDialog} />
        <ProtectedRoute
          exact
          path={`${urls.myEngines}/details/:address`}
          component={FilterTagsDialog}
        />
      </Switch>
      <Panel>
        <Switch>
          <Route exact path={urls.allEngines} component={AllMicroenginesTab} />
          <Route
            exact
            path={`${urls.allEngines}/details/:address`}
            component={AllMicroenginesTab}
          />
          <ProtectedRoute exact path={urls.myEngines} component={MyMicroenginesTab} />
          <ProtectedRoute
            exact
            path={`${urls.myEngines}/details/:address`}
            component={MyMicroenginesTab}
          />
          <Redirect to='/404' />
        </Switch>
      </Panel>
    </Card>
  );
};

const useMuiStyles = makeMuiStyles({
  hidden: {
    display: 'none',
  },
});

export default MicroenginesPanel;
