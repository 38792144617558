import React, { useCallback, useRef } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Dropzone from 'react-dropzone';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import FileSelect from 'views/components/Button/FileSelect';
import styled from '@emotion/styled';
import fileUploadImg from 'assets/images/file-upload.svg';
import fileUploadDarkImg from 'assets/images/file-upload-dark.svg';
import shortenFilename from 'utils/shortenFilename';
import formatBytes from 'utils/formatBytes';

const Hint = styled.div`
  font-weight: regular;
`;

const SelectFileButton = styled.span`
  cursor: pointer;
  font-weight: bold;
`;

const StyledFileSelect = styled(FileSelect)`
  margin-left: 2rem;
`;

type Props = {
  btnText?: string;
  hintText?: string;
  accept?: string;
  onFileSelect: (files: { file: File | null; filename: string; filesize: string }) => void;
};

export function UploadFile({
  onFileSelect,
  btnText = 'Select file',
  hintText = 'or drag and drop',
  accept = '*/*',
}: Props) {
  return (
    <Dropzone
      accept={accept}
      onDrop={(file) => {
        if (!file.length) {
          return;
        }
        onFileSelect({
          file: file[0],
          filename: shortenFilename(file[0].name),
          filesize: formatBytes(file[0].size),
        });
      }}
    >
      {({ getRootProps, isDragActive }) => (
        <div
          {...getRootProps({
            onClick: (e) => e.stopPropagation(),
            tabIndex: -1,
          })}
        >
          <Container
            accept={accept}
            btnText={btnText}
            hintText={hintText}
            isDragActive={isDragActive}
            onFileSelect={onFileSelect}
          />
        </div>
      )}
    </Dropzone>
  );
}

function Container({
  isDragActive,
  onFileSelect,
  btnText,
  hintText,
  accept,
}: Props & { isDragActive: boolean }) {
  const { theme, classes } = useStyles(isDragActive);
  const inputRef = useRef<HTMLLabelElement>(null);
  const _clickFileInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  return (
    <div css={classes.dropZone} data-cy='scanFileState'>
      <div onClick={_clickFileInput}>
        <img
          src={theme === 'dark' ? fileUploadDarkImg : fileUploadImg}
          alt='file upload'
          css={classes.dragIcon}
        />
      </div>
      <StyledFileSelect
        id='upload-image'
        ref={inputRef}
        accept={accept}
        onSelection={(files) =>
          onFileSelect({
            file: files[0],
            filename: shortenFilename(files[0].name),
            filesize: formatBytes(files[0].size),
          })
        }
      >
        <Hint css={classes.hint}>
          <SelectFileButton css={classes.selectFileButton}>{btnText}</SelectFileButton>
          <span>&nbsp;</span>
          {hintText}
        </Hint>
      </StyledFileSelect>
    </div>
  );
}

const useStyles = makeStyles((isDragActive?: boolean) => ({
  base: {
    dropZone: {
      alignItems: 'center',
      zIndex: 1,
      textAlign: 'center',
      borderWidth: 3,
      borderStyle: 'dashed',
      borderRadius: styles.border.radius,
      borderColor: styles.color.greyishPurple,
      pointerEvents: isDragActive ? 'none' : 'auto',
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      padding: '3rem 0 3rem 0',
    },
    dragIcon: {
      cursor: 'pointer',
      width: '6rem',
      transition: isDragActive ? 'none' : `color ${styles.easing.time} ${styles.easing.main}`,
      color: styles.color.purple,
    },
    selectFileButton: {
      color: styles.color.purple,
    },
    header: {
      color: styles.color.black,
    },
    hint: {
      color: styles.color.lightGrey,
    },
    error: {
      color: styles.color.red,
      textAlign: 'center',
      marginTop: '1rem',
    },
  },
  light: {
    dropZone: {
      borderColor: isDragActive ? styles.color.purple : styles.color.greyishPurple,
      backgroundColor: isDragActive ? 'rgba(109, 58, 236, 0.07)' : 'transparent',
    },
    selectFileButton: {
      '&:hover': {
        color: styles.color.xLightGrey,
      },
    },
    dragIcon: {
      '&:hover': {
        color: styles.color.xLightGrey,
      },
    },
    hint: {
      color: styles.color.lightGrey,
    },
  },
  dark: {
    dropZone: {
      borderColor: isDragActive ? styles.color.xxLightGrey : styles.color.greyishPurple,
      backgroundColor: isDragActive ? 'rgba(172, 172, 187, 0.07)' : 'transparent',
    },
    selectFileButton: {
      color: styles.color.lightBlue,
      '&:hover': {
        color: styles.color.xLightGrey,
      },
    },
    dragIcon: {
      '&:hover': {
        color: styles.color.xLightGrey,
      },
    },
    hint: {
      color: styles.color.xxxLightGrey,
    },
  },
}));
