import React, { useState, ComponentType } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { ActionItemProps } from './SidebarActions';

interface Props {
  url: string;
  actionItemProps: ActionItemProps;
  ChildComponent: ComponentType<any>;
}

const ICON_SIZE = 32;

const SidebarActionTooltip = ({ url, actionItemProps, ChildComponent }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const _toggleTooltip = () => setIsOpen((isOpenState) => !isOpenState);

  const _closeTooltip = () => setIsOpen(false);

  return (
    <ClickAwayListener onClickAway={_closeTooltip}>
      <Tooltip
        open={isOpen}
        placement='top'
        interactive
        title={
          <div css={style.buttons} data-cy='scanShareTooltip'>
            <FacebookShareButton url={url}>
              <div title='Facebook'>
                <FacebookIcon size={ICON_SIZE} round={true} />
              </div>
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <div title='Twitter'>
                <TwitterIcon size={ICON_SIZE} round={true} />
              </div>
            </TwitterShareButton>
            <RedditShareButton url={url}>
              <div title='Reddit'>
                <RedditIcon size={ICON_SIZE} round={true} />
              </div>
            </RedditShareButton>
            <TelegramShareButton url={url}>
              <div title='Telegram'>
                <TelegramIcon size={ICON_SIZE} round={true} />
              </div>
            </TelegramShareButton>
            <CopyButton className='social-copy-btn' css={style.copy} url={url} size={ICON_SIZE} />
          </div>
        }
      >
        <ChildComponent {...actionItemProps} onClick={_toggleTooltip} />
      </Tooltip>
    </ClickAwayListener>
  );
};

const CopyButton = ({
  className,
  size,
  url,
}: {
  className?: string;
  size: number;
  url: string;
}) => (
  <CopyToClipboard text={url}>
    <div className={className} style={{ height: size, width: size }} title='Copy'>
      <Icon name='copy' />
    </div>
  </CopyToClipboard>
);

const style = {
  buttons: css`
    display: flex;
    .SocialMediaShareButton,
    .social-copy-btn {
      cursor: pointer;
      opacity: 0.8;
      outline: none;
      margin-right: 1rem;
      transition: opacity ${styles.easing.time} ${styles.easing.main};
      &:hover {
        opacity: 1;
      }
      &:focus {
        outline: 1px dotted ${styles.color.lightGrey};
      }
      &:last-child {
        margin-right: 0;
      }
    }
  `,
  copy: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${styles.color.offWhite};
    color: ${styles.color.purple};
    border-radius: 100%;
    padding: 0.6rem;
  `,
};

export default SidebarActionTooltip;
