import { toNCT } from 'utils/nct';
import { toFixed } from 'utils/conversions/conversions';
import { BigSource } from 'big.js';

/**
 * Return two balance values: A truncated one (4 decimal places) and a full one
 * 1e-7 returns ['> 0.0000', '0.0000001']
 * 1.01234568912345 returns ['1.0123', '1.01234568912345']
 */
export const getBalanceValues = (balance: BigSource, decimalPlaces = 4): [string, string] => {
  const nctBalance = toNCT(balance);
  const fullValue = nctBalance.toFixed();
  const [, decimals] = fullValue.split('.');

  let trucatedValue;
  if (decimals && decimals.length > decimalPlaces) {
    trucatedValue = toFixed(nctBalance, decimalPlaces);
  } else {
    trucatedValue = fullValue;
  }

  return [trucatedValue, fullValue];
};
