import { useEffect, useState } from 'react';
import api from 'services/api';

export interface UseWithdrawalFee {
  fee: string;
  sign?: string;
  timestamp?: string;
  error: boolean;
}

export const useWithdrawalFee = (amount: string): UseWithdrawalFee => {
  const [fee, setFee] = useState<string>('');
  const [sign, setSign] = useState<string | undefined>();
  const [timestamp, setTimestamp] = useState<string | undefined>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    async function getWithdrawalFee() {
      try {
        const response = await api.getFees(amount);
        const data = response.data;
        if (data) {
          setFee(data.fee);
          setTimestamp(data.timestamp);
          setSign(data.sign);
          setError(false);
        } else {
          setError(true);
        }
      } catch {
        setError(true);
      }
    }

    if (amount && amount !== '0' && /^([0-9]|\.)+$/.exec(amount)) {
      getWithdrawalFee();
    }
  }, [amount]);

  return { fee, timestamp, sign, error };
};
