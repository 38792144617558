import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'state/types/thunk';
import { getTeamByAccount } from 'state/team/actions';
import api from 'services/api';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';

export default function useForceTeamMfa() {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const accountNumber = contextAccount(store)?.accountNumber!;
  const forceTeamMfa = async (force: boolean) => {
    setLoading(true);
    try {
      await api.forceTeamMfa(accountNumber, force);
      await dispatch(getTeamByAccount(accountNumber));
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return { forceTeamMfa, loading, error };
}
