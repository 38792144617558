import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'state/types/thunk';
import { openModal } from 'state/modal/actions';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import useRequestFeature from './useRequestFeature';
import useIsOwnerOrAdmin from 'hooks/useIsOwnerOrAdmin';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';

import styles from 'views/styles';
import Loader from 'views/components/Loader';

interface IContactSalesForFeature {
  feature: string;
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
}

const messages = defineMessages({
  btnLabel: {
    id: 'contactSalesForFeature.btnLabel',
    defaultMessage: 'Contact Sales',
  },
  success: {
    id: 'contactSalesForFeature.success',
    defaultMessage: 'Sales has been notified and will contact you soon using your primary email.',
  },
  notAdminOrOwner: {
    id: 'contactSalesForFeature.notAdminOrOwner.message',
    defaultMessage:
      'Contact your Team Owner or Team Admin to add additional features to your plan.',
  },
});

const ContactSalesForFeature = ({ feature, size = 'small', children }: IContactSalesForFeature) => {
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const isOwnerOrAdmin = useIsOwnerOrAdmin();
  const { classes } = useStyles();
  const { requestFeature, loading } = useRequestFeature();
  const isCommunityPlan = useIsCommunityPlan();

  const handleClick = async () => {
    if (isCommunityPlan) {
      return window.open('/pricing', '_blank');
    }

    const { success, errorMessage } = await requestFeature(feature);

    if (success) {
      dispatch(
        openModal('NOTIFICATION_MODAL', {
          content: intl.formatMessage(messages.success),
          submitBtnVariant: 'contained',
          btnText: 'Ok',
          status: 'ok',
          layout: 'column',
        })
      );
    } else {
      dispatch(
        openModal('NOTIFICATION_MODAL', {
          content: errorMessage,
          submitBtnVariant: 'contained',
          btnText: 'Ok',
          status: 'error',
          layout: 'column',
        })
      );
    }
  };

  return (
    <div css={classes.container}>
      {children}
      {isOwnerOrAdmin ? (
        <Button
          css={classes.btn}
          disabled={loading}
          color='primary'
          size={size}
          variant='contained'
          onClick={handleClick}
        >
          {loading ? <Loader css={classes.loader} /> : intl.formatMessage(messages.btnLabel)}
        </Button>
      ) : (
        <Typography variant='subtitle2'>{intl.formatMessage(messages.notAdminOrOwner)}</Typography>
      )}
    </div>
  );
};

export default ContactSalesForFeature;

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: '2rem',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    btn: {
      padding: '1rem 2rem !important',
    },
    loader: {
      fontSize: '0.18rem !important',
    },
  },
  light: {
    code: {
      color: styles.color.purple,
    },
  },
  dark: {
    code: {
      color: styles.color.blue,
    },
  },
});
