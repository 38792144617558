import { useState, useEffect } from 'react';
import qs from 'query-string';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { EPageDirection } from 'views/components/table/CustomTablePagination';

type PaginationParams = {
  defaultTotal: number;
  defaultOffset: number;
  defaultLimit: number;
  syncWithQueryParams?: boolean;
};

type PaginationRes = {
  isFirst: boolean;
  isLast: boolean;
  limit: number;
  offset: number;
  setTotal: (total: number) => void;
  onChangePage: (page: EPageDirection) => void;
  onChangeRowsPerPage: (numRows: number) => void;
};

const usePagination = ({
  defaultTotal = 0,
  defaultOffset = 0,
  defaultLimit = 10,
  syncWithQueryParams = false,
}: PaginationParams): PaginationRes => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const [total, setTotal] = useState(defaultTotal);
  const [offset, setOffset] = useState(defaultOffset);
  const [limit, setLimit] = useState(defaultLimit);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (syncWithQueryParams) {
      const params = qs.parse(location.search);
      const queryOffset = Number(params.offset as string);
      const queryLimit = Number(params.limit as string);

      if (queryOffset) {
        setOffset(queryOffset);
        setCurrentPage(Math.floor(queryOffset / queryLimit) + 1);
      }

      if (queryLimit) {
        setLimit(queryLimit);
      }
    }
  }, [syncWithQueryParams, location.search]);

  useEffect(() => {
    if (syncWithQueryParams) {
      _updateQueryParams({ offset, limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, syncWithQueryParams]);

  useEffect(() => {
    setTotal(defaultTotal);
    if (currentPage > 1 && defaultTotal < limit * (currentPage - 1)) {
      setCurrentPage(1);
      setOffset(0);
    }
  }, [defaultTotal, limit, currentPage]);

  const isFirst = currentPage === 1;
  const isLast = currentPage * limit >= total;

  const onChangePage = (page: EPageDirection) => {
    switch (page) {
      case EPageDirection.PREV: {
        setCurrentPage((prev) => prev - 1);
        setOffset((prev) => prev - limit);
        break;
      }
      case EPageDirection.NEXT: {
        setCurrentPage((prev) => prev + 1);
        setOffset((prev) => prev + limit);
        break;
      }
      case EPageDirection.FIRST: {
        setCurrentPage(1);
        setOffset(0);
        break;
      }
      default:
        break;
    }
  };

  const onChangeRowsPerPage = (numRows: number) => {
    setLimit(numRows);
    const newOffset = Math.floor(offset / numRows) * numRows;
    setCurrentPage(Math.floor(newOffset / numRows) + 1);
    setOffset(newOffset);

    if (syncWithQueryParams) {
      _updateQueryParams({ offset: newOffset, limit: numRows });
    }
  };

  const _updateQueryParams = (newParams: { offset?: number; limit?: number }) => {
    const params = qs.parse(location.search);
    history.replace(`${match.url}?${qs.stringify({ ...params, ...newParams })}`);
  };

  return {
    isFirst,
    isLast,
    limit,
    offset,
    setTotal,
    onChangePage,
    onChangeRowsPerPage,
  };
};

export default usePagination;
