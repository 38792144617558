import api from 'services/api';

export const useGetRuleset = () => {
  const getRuleset = async (rulesetId: string) => {
    const response = await api.getRuleset(rulesetId);
    return response.data;
  };

  return { getRuleset };
};
