import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import { useIntl, defineMessages } from 'react-intl';
import Button from '@material-ui/core/Button';
import StatusIcon from 'views/components/StatusIcon';

interface CardErrorProps {
  size?: 'small' | 'medium' | 'large';
  error: number;
  errorMessage?: string;
  retry: () => void;
}

export const messages = defineMessages({
  errorMessage: {
    id: 'cardError.errorMessage',
    defaultMessage:
      'We are sorry to have to tell you this, but the server was unable to provide the data you requested. Please give it a moment to collect its thoughts and then try again. If this problem persists, please contact support@polyswarm.io.',
  },
});

const SIZES = {
  small: 1,
  medium: 2,
  large: 3,
};

const Container = styled.div<{ size: CardErrorProps['size'] }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .h-mb-tiny {
    font-size: ${({ size }) => 0.5 * SIZES[size || 'small']}rem !important;
  }

  & > *:not(:first-child) {
    margin-top: 1rem;
  }
`;

const CardError = ({ size = 'small', error, retry }: CardErrorProps) => {
  const intl = useIntl();
  return (
    <Container size={size}>
      <StatusIcon className='h-mb-tiny' status='error' value={error} />
      <p>{intl.formatMessage(messages.errorMessage)}</p>
      <Button size={size} color='secondary' variant='contained' onClick={retry}>
        {intl.formatMessage({ id: 'button.retry' })}
      </Button>
    </Container>
  );
};

export default CardError;
