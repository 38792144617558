import React, { ReactNode, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { sortBy, range } from 'lodash';
import { Artifact, Assertion, AssertionMetadata } from 'models/Submission';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import ScanItem, { ScanItemSkeleton } from './ScanItem';
import Accordion from 'views/components/Accordion';
import { defineMessages, useIntl } from 'react-intl';

interface IDetectionsTable {
  scanning: boolean;
  file: Artifact | null;
  onClick: (authorName: string | null, author: string, metadata: AssertionMetadata) => void;
}

const messages = defineMessages({
  notAsserting: {
    id: 'scan.results.detections.notAsserting',
    defaultMessage: '{count} engines not asserting',
  },
});

const DetectionsTable = (props: IDetectionsTable) => {
  const { scanning } = props;
  const { classes } = useStyles();

  return (
    <div css={classes.root}>
      {scanning ? (
        <ScanningState {...props} />
      ) : (
        <Fragment>
          <MaliciousResults {...props} />
          <BenignResults {...props} />
          <FailedResults {...props} />
        </Fragment>
      )}
    </div>
  );
};

const DetectionItem = ({
  file,
  verdict,
  children,
}: {
  file: Artifact | null;
  verdict?: boolean | null;
  children: (props: { assertions: Assertion[] }) => ReactNode;
}) => (
  <Fragment>
    {children({
      assertions: sortBy(file ? file.assertions : [], ['author_name']).filter(
        (a) => (verdict === undefined || a.verdict === verdict) && a.mask
      ),
    })}
  </Fragment>
);

const ScanningState = ({ file, onClick }: IDetectionsTable) => {
  const { classes } = useStyles();
  return (
    <DetectionItem file={file}>
      {({ assertions }) => {
        const numBlankItems = 28;
        return (
          <Fragment>
            {assertions.map((assertion) => (
              <ScanItem
                key={assertion.author}
                css={classes.item}
                windowClosed={false}
                failed={false}
                author={assertion.author}
                authorName={assertion.author_name}
                bid={assertion.bid}
                metadata={assertion.metadata}
                verdict={null}
                onClick={onClick}
              />
            ))}
            {range(numBlankItems - assertions.length).map((_, i) => (
              <ScanItemSkeleton key={i} css={[classes.item, classes.itemBlank]} />
            ))}
          </Fragment>
        );
      }}
    </DetectionItem>
  );
};

const MaliciousResults = ({ file, onClick }: IDetectionsTable) => {
  const { classes } = useStyles();
  return (
    <DetectionItem file={file} verdict={true}>
      {({ assertions }) =>
        assertions.map((assertion) => (
          <ScanItem
            key={assertion.author}
            css={classes.item}
            windowClosed={true}
            failed={false}
            author={assertion.author}
            authorName={assertion.author_name}
            bid={assertion.bid}
            metadata={assertion.metadata}
            verdict={true}
            onClick={onClick}
          />
        ))
      }
    </DetectionItem>
  );
};

const BenignResults = ({ file, onClick }: IDetectionsTable) => {
  const { classes } = useStyles();
  return (
    <DetectionItem file={file} verdict={false}>
      {({ assertions }) =>
        assertions.map((assertion) => (
          <ScanItem
            key={assertion.author}
            css={classes.item}
            windowClosed={true}
            failed={false}
            author={assertion.author}
            authorName={assertion.author_name}
            bid={assertion.bid}
            metadata={assertion.metadata}
            verdict={false}
            onClick={onClick}
          />
        ))
      }
    </DetectionItem>
  );
};

const FailedResults = ({ file, onClick }: IDetectionsTable) => {
  const intl = useIntl();
  const { classes } = useStyles();
  return (
    <DetectionItem file={file} verdict={null}>
      {({ assertions }) => (
        <Accordion
          css={classes.accordion}
          title={intl.formatMessage(messages.notAsserting, { count: assertions.length })}>
          <div css={classes.failedResultsContent}>
            {assertions.map((assertion) => (
              <ScanItem
                key={assertion.author}
                css={classes.item}
                windowClosed={true}
                failed={true}
                author={assertion.author}
                authorName={assertion.author_name}
                bid={assertion.bid}
                metadata={assertion.metadata}
                verdict={null}
                onClick={onClick}
              />
            ))}
          </div>
        </Accordion>
      )}
    </DetectionItem>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: `0 ${styles.spacing.grid}`,
    },
    item: {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      '&:nth-of-type(odd)': {
        marginRight: styles.spacing.grid,
      },
      '&:nth-of-type(1), &:nth-of-type(2)': {
        borderTopColor: 'transparent',
      },
    },
    itemBlank: {
      borderTopColor: 'transparent',
    },
    accordion: {
      display: 'block',
      width: '100%',
      padding: '1rem 2rem',
      marginRop: '1rem',
    },
    failedResultsContent: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  light: {
    item: {
      borderTopColor: styles.border.color.grey,
    },
  },
  dark: {
    item: {
      borderTopColor: styles.border.color.darkPurple,
    },
  },
});

export default DetectionsTable;
