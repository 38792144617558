import React, { useMemo, useEffect, useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useIntl, defineMessages } from 'react-intl';
import styles from 'views/styles';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'views/components/providers/ThemeProvider';
import TextField from '@material-ui/core/TextField';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import { JsonViewer } from 'views/components/JsonViewer';
import Icon from 'views/components/Icon';
import { ContainerSearch, Item, ItemContainer } from './components';
import {
  downloadJSON,
  getAllKeys,
  filterData,
  deletePreviousMatch,
  highlightMatch,
  getHighlighted,
  IFilter,
  IConfig,
  IMatches,
} from './utils';

interface IJSONTab {
  onRetry?: () => void;
  data: Record<string, any> | null;
}

const messages = defineMessages({
  jsonKey: {
    id: 'sandboxDetails.JSONTab.jsonKey',
    defaultMessage: 'Search JSON Results: Key',
  },
  jsonValue: {
    id: 'sandboxDetails.JSONTab.jsonValue',
    defaultMessage: 'Search JSON Results: Value',
  },
  expand: {
    id: 'sandboxDetails.JSONTab.expand',
    defaultMessage: 'Expand All',
  },
  collapse: {
    id: 'sandboxDetails.JSONTab.collapse',
    defaultMessage: 'Collapse All',
  },
});

const JSONTab = ({ data: defaultData, onRetry }: IJSONTab) => {
  const intl = useIntl();
  const theme = useTheme();
  const location = useLocation();

  const [open, setOpen] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<number | boolean>(1);
  const [currentMatch, setCurrentMatch] = useState<number | null>(null);
  const [collapseLength, setCollapsedLength] = useState<number>(80);
  const [matches, setMatches] = useState<number | null>(null);
  const [data, setData] = useState<any>(defaultData);
  const [filter, setFilter] = useState<IFilter>({});
  const [lastSearch, setLastSearch] = useState<IFilter>({});
  const [highlightSearch, setHighlightSearch] = useState<string | null>(null);
  const [config] = useState<IConfig>({ mode: 'includes' });

  const isDarkTheme = theme === 'dark';
  const isFiltered = filter.key || filter.value;
  const JSONdata = JSON.stringify(data, null, 2);

  const _handleEmptySearch = () => {
    setData(defaultData);
    setMatches(null);
    setHighlightSearch(null);
    setFilter({});
    setLastSearch({});
    setCurrentMatch(null);
  };

  const _handleSearch = (inputFilter: IFilter | null = null) => {
    if (isFiltered || inputFilter) {
      const matches = {
        matches: 0,
        matchesBoth: 0,
      };
      const filterValue = inputFilter || filter;
      const filteredData = filterData(defaultData, filterValue, matches, config);
      setLastSearch(filterValue);
      setData(filteredData);
      if (filterValue.key && filterValue.value) {
        setHighlightSearch(filterValue.key);
        setMatches(matches.matchesBoth);
      } else if (filterValue.key) {
        setHighlightSearch(filterValue.key);
        setMatches(matches.matches);
      } else if (filterValue.value) {
        setHighlightSearch(filterValue.value);
        setMatches(matches.matches);
      }
    } else {
      _handleEmptySearch();
    }
  };

  const _handleMatches = ({ nextMatch }: { nextMatch: IMatches['nextMatch'] }) => {
    deletePreviousMatch(isDarkTheme);
    highlightMatch({
      isDarkTheme,
      currentMatch,
      setCurrentMatch,
      nextMatch,
    });
  };

  const _handleEnter = (e: React.KeyboardEvent, type: 'key' | 'value') => {
    if (e.keyCode === 13) {
      setOpen(false);
      const target = e.target as HTMLInputElement;
      if (filter[type] === '') {
        _handleSearch();
      } else if (lastSearch[type] === target.value) {
        _handleMatches({
          nextMatch: (matches) =>
            currentMatch && currentMatch < matches.length ? currentMatch + 1 : 1,
        });
      } else {
        _handleSearch({ ...filter, [type]: target.value });
      }
    }
  };

  const _handleScape = (e: React.KeyboardEvent) => {
    if (e.keyCode === 27) {
      _handleEmptySearch();
    }
  };

  const _handleArrowKeys = (e: React.KeyboardEvent) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      setOpen(true);
    }
  };

  const forceUpdate = () => setCollapsedLength((prev) => (prev === 50 ? 51 : 50));

  useEffect(() => {
    if (location.hash) {
      document.querySelector(location.hash)?.scrollIntoView();
    }
    setData(defaultData);
  }, [location.hash, defaultData]);

  useEffect(() => {
    let timer: any;
    if (matches !== null && matches > 0 && currentMatch === null) {
      if (!getHighlighted().length) {
        timer = setTimeout(() => {
          deletePreviousMatch(isDarkTheme);
          highlightMatch({
            isDarkTheme,
            currentMatch,
            setCurrentMatch,
            nextMatch: (_) => 1,
            isFirst: true,
          });
        }, 200);
      }
    }
    return () => clearTimeout(timer);
  }, [matches, currentMatch, isDarkTheme]);

  const dataKeys = useMemo(
    () =>
      !defaultData
        ? getAllKeys(defaultData)
            .filter((item: string) => Number(item) !== 0 && !Number(item))
            .sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()))
        : [],
    [defaultData]
  );

  if (!data) {
    return <PanelLoader />;
  }

  return (
    <div css={style.root} data-cy='scanFileJSON'>
      <ContainerSearch isDarkTheme={isDarkTheme}>
        <Autocomplete
          inputValue={filter.key || ''}
          value={filter.key || ''}
          options={dataKeys.filter((item: string) => item.includes(filter.key || ''))}
          freeSolo
          autoSelect
          disableClearable
          open={open}
          onInputChange={(_e, value) => {
            setFilter({ ...filter, key: value });

            if (value.length === 0) {
              if (open) setOpen(false);
            } else if (value.length >= 1) {
              if (!open) setOpen(true);
            }
          }}
          onClose={() => setOpen(false)}
          onChange={(e, value) => {
            if (e.type === 'click' || e.type === 'keydown') {
              setTimeout(() => _handleSearch({ ...filter, key: value }), 100);
              setOpen(false);
            }
          }}
          onKeyDown={(e) => {
            _handleScape(e);
            _handleArrowKeys(e);
            _handleEnter(e, 'key');
          }}
          renderInput={(params) => (
            <TextField
              label={intl.formatMessage(messages.jsonKey)}
              {...{
                ...params,
                inputProps: { ...params.inputProps, 'data-testid': 'jsonKeyFilter' },
              }}
            />
          )}
        />
        <span>:</span>
        <TextField
          onKeyDown={(e) => {
            _handleScape(e);
            _handleEnter(e, 'value');
          }}
          inputProps={{ 'data-testid': 'jsonValueFilter' }}
          onChange={(e) => setFilter({ ...filter, value: e.target.value })}
          label={intl.formatMessage(messages.jsonValue)}
          fullWidth={true}
          value={filter.value || ''}
        />
        <div data-testid='jsonSearchIcon' onClick={() => _handleSearch()}>
          <Icon name='search' title='search' className={`icon-search ${isFiltered && 'active'}`} />
        </div>
        <div data-testid='jsonCloseIcon' onClick={_handleEmptySearch}>
          <Icon name='close' title='cancel' className={`icon-close ${isFiltered && 'active'}`} />
        </div>
      </ContainerSearch>
      <ItemContainer isDarkTheme={isDarkTheme}>
        {matches !== null && (
          <Item data-testid='jsonMatches'>
            {matches > 0 && `${currentMatch || 0} /`} {matches} match(es)
          </Item>
        )}
        {matches !== null && (
          <Item
            data-testid='jsonMatchesArrowUp'
            onClick={() => {
              _handleMatches({
                nextMatch: (matches) =>
                  currentMatch && currentMatch > 1 ? currentMatch - 1 : matches.length,
              });
            }}
          >
            <Icon name='arrow' className='icon-arrow arrow-up' />
          </Item>
        )}
        {matches !== null && (
          <Item
            data-testid='jsonMatchesArrowDown'
            onClick={() => {
              _handleMatches({
                nextMatch: (matches) =>
                  currentMatch && currentMatch < matches.length ? currentMatch + 1 : 1,
              });
            }}
          >
            <Icon name='arrow' className='icon-arrow' />
          </Item>
        )}
        <Item
          onClick={() => {
            forceUpdate();
            setCollapsed(false);
          }}
        >
          {intl.formatMessage(messages.expand)}
        </Item>
        <Item
          onClick={() => {
            forceUpdate();
            setCollapsed(filter.key ? true : 1);
          }}
        >
          {intl.formatMessage(messages.collapse)}
        </Item>
        <Item data-testid='jsonCopyIcon'>
          <CopyTextButton label='Copy All' text={JSONdata} alignNotification='right' />
        </Item>
        <Item onClick={() => downloadJSON({ name: 'json', json: JSONdata })}>
          <Icon name='download' className='icon-download' />
        </Item>
      </ItemContainer>
      <JsonViewer
        data={data || {}}
        collapsed={collapsed}
        highlightSearch={highlightSearch || ''}
        collapseStringsAfterLength={collapseLength}
      />
    </div>
  );
};

const style = {
  root: css`
    margin: calc(${styles.spacing.grid} / 2) ${styles.spacing.grid};
  `,
};

export default JSONTab;
