import React, { useEffect } from 'react';
import MySandboxingTableContainer from './MySandboxingTable/MySandboxingTableContainer';
import { FilterProps } from '../../SandboxFilter';

type Filter = {
  key: string;
  value: string;
};

const initialFiltersMySandboxing = {
  startDate: {
    option: 'DAYS',
    value: 30,
  },
} as Partial<FilterProps>;

function MySandboxingTab({
  setFilters,
  removeFilter,
  filters,
  toggleFilters,
  useMySandboxingProps,
  paginationProps,
}: {
  setFilters: (initialState: Partial<FilterProps>) => void;
  removeFilter: (key: keyof FilterProps) => void;
  filters: Filter[];
  toggleFilters: () => void;
  useMySandboxingProps: any;
  paginationProps: { [key: string]: any };
}) {
  useEffect(() => {
    setFilters(initialFiltersMySandboxing);
    useMySandboxingProps.refetch([], false, true);
  }, []); // eslint-disable-line

  return (
    <MySandboxingTableContainer
      filters={filters}
      removeFilter={removeFilter}
      toggleFilters={toggleFilters}
      useMySandboxingProps={useMySandboxingProps}
      paginationProps={paginationProps}
    />
  );
}

export default MySandboxingTab;
