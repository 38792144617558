import { useState, useEffect } from 'react';
import { getErrorMessage, getErrorMessageAndFieldValidations } from 'utils/error';
import api from 'services/api';
import { WalletRes } from 'services/api/schema/wallets';
import { getBalanceValues } from 'utils/balance';
import { toNCT, toWEI } from 'utils/nct';
import { useUser } from 'views/components/providers/UserProvider';
import { getNonce, removeWalletNonce } from 'state/auth/actions';
import { BigSource } from 'big.js';

export interface WithdrawalConfig {
  withdrawalsLimit: string;
  withdrawalAddress: string;
}

export interface RewardsWalletRes extends WalletRes {
  truncatedBalance: string;
  fullBalance: string;
  truncatedLimit: string;
  fullLimit: string;
}

export interface WithdrawalRes {
  success: boolean;
  data?: WalletRes;
  errorMessage?: string;
  error?: any;
}

export interface UseRewardsWallet {
  rewardsWallet: Partial<RewardsWalletRes> | null;
  loading: boolean;
  error: boolean;
  errorMessage: string | undefined;
  enrollRewards: () => Promise<void>;
  getRewardsWallet: () => Promise<void>;
  configureWithdrawal: ({
    withdrawalsLimit,
    withdrawalAddress,
  }: WithdrawalConfig) => Promise<WithdrawalRes>;
  startWithdrawal: (
    amount: BigSource,
    fee: BigSource,
    feeTimestamp?: string,
    feeSign?: string
  ) => Promise<WithdrawalRes>;
  refetch: () => void;
}

export const useRewardsWallet = (loadOnStart = true): UseRewardsWallet => {
  const user = useUser();
  const accountNumber = user.context?.accountNumber;
  const team = user.context?.team;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [reload, setReload] = useState<boolean>(false);
  const [rewardsWallet, setRewardsWallet] = useState<Partial<RewardsWalletRes> | null>(null);

  const configureWithdrawal = async ({
    withdrawalsLimit,
    withdrawalAddress,
  }: WithdrawalConfig): Promise<WithdrawalRes> => {
    try {
      const response = await api.patchRewardsWallet({
        withdrawalsLimit: toWEI(withdrawalsLimit).toFixed(),
        withdrawalAddress,
      });
      return {
        success: response.status === 200,
        data: response.data,
      };
    } catch (error: any) {
      const errorMessage = getErrorMessageAndFieldValidations(error);
      return {
        success: false,
        errorMessage: errorMessage,
        error: error,
      };
    }
  };

  const startWithdrawal = async (
    amount: BigSource,
    fee: BigSource,
    feeTimestamp?: string,
    feeSign?: string
  ): Promise<WithdrawalRes> => {
    try {
      const nonce = getNonce();
      if (!nonce) {
        throw new Error('Verification Error ‒ Make sure your browser is allowing local storage.');
      }
      removeWalletNonce();
      const response = await api.startWithdrawal(
        nonce,
        toWEI(amount).toFixed(),
        toWEI(fee).toFixed(),
        feeTimestamp,
        feeSign
      );
      if (response.status === 201) {
        setLoading(true);
        await getRewardsWallet();
        setLoading(false);
        return { success: true };
      }
      return { success: false };
    } catch (error: any) {
      setError(true);
      const errorMessage = getErrorMessageAndFieldValidations(error);
      setErrorMessage(errorMessage);
      return {
        success: false,
        error: error,
        errorMessage: errorMessage,
      };
    }
  };

  const getRewardsWallet = async (): Promise<void> => {
    const response = await api.getRewardsWallet();
    const data = response.data;
    const [truncatedBalance, fullBalance] = getBalanceValues(data.balance || 0);
    const [truncatedLimit, fullLimit] = getBalanceValues(data.withdrawals_limit || 0);
    setRewardsWallet({
      ...data,
      truncatedBalance,
      fullBalance,
      truncatedLimit,
      withdrawals_limit_min: toNCT(data.withdrawals_limit_min || 0).toNumber(),
      fullLimit,
    });
  };

  const enrollRewards = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await api.enrollRewards();
      const data = response.data;
      const [truncatedBalance, fullBalance] = getBalanceValues(0);
      const [truncatedLimit, fullLimit] = getBalanceValues(0);
      setRewardsWallet({
        ...rewardsWallet,
        truncatedBalance,
        fullBalance,
        truncatedLimit,
        fullLimit,
        tosAccepted: data.tosAccepted,
      });
      setError(false);
    } catch (error: any) {
      setError(true);
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const refetch = () => setReload(!reload);

  useEffect(() => {
    async function onload() {
      try {
        setError(false);
        setLoading(true);
        await getRewardsWallet();
        setLoading(false);
      } catch (error: any) {
        setError(true);
        setLoading(false);
        setErrorMessage(getErrorMessage(error));
      }
    }
    if (loadOnStart && !team) {
      onload();
    }
  }, [reload, accountNumber, loadOnStart, team]);

  return {
    rewardsWallet,
    loading,
    error,
    errorMessage,
    enrollRewards,
    getRewardsWallet,
    configureWithdrawal,
    startWithdrawal,
    refetch,
  };
};
