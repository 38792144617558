import React, { Fragment } from 'react';
import numeral from 'numeral';
import { ENTER_KEY_CODE, SPACE_KEY_CODE } from 'utils/constants';

/**
 * Renders state which matches currentState prop.
 *
 * Can be used to replace switch-cases in JSX where certain components are rendered based on some variable.
 */
export const Filter = <K extends string>({
  currentState,
  states,
}: {
  currentState: K;
  states: { [k in K]: JSX.Element };
}): JSX.Element => {
  return states[currentState];
};

export const onEnterKeyDown = (onEnter: () => void) => (e: React.KeyboardEvent) => {
  if (e.keyCode === ENTER_KEY_CODE) {
    e.preventDefault();
    onEnter();
  }
};

export const onEnterOrSpaceKeyDown = (onEnter: () => void) => (e: React.KeyboardEvent) => {
  if (e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACE_KEY_CODE) {
    e.preventDefault();
    onEnter();
  }
};

/**
 * Rounds NCT to 4 decimal points and adds thousands separators.
 */
export const formatNCT = (nct: string, format = '0,0.[0000]', long = false) => {
  if (long) {
    return numeral(nct).divide('10e+17').format(format, Math.floor);
  }

  return numeral(nct).format(format, Math.floor);
};

// TODO: Show full precision NCT in balloon tooltip when hovered over.
export const FormattedNCT = (props: { nct: string; format?: string; long?: boolean }) => (
  <Fragment>{formatNCT(props.nct, props.format, props.long)}</Fragment>
);

const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
/**
 * Truncates an ethereum hash to the format 0x0000…0000
 * @param address Full hash to truncate
 * @returns Truncated hash
 */
export const formatTransactionHash = (hash: string) => {
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const match = hash.match(truncateRegex);
  if (!match) return hash;
  return `${match[1]}…${match[2]}`;
};
