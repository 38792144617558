import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { ModalState } from 'state/modal';
import { closeModal, openModal } from 'state/modal/actions';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import { useArchiveIntegration } from 'views/pages/TeamDetailPage/TeamDetailPanel/TeamDetailTabs/TeamIntegrationsTab/useArchiveIntegration';
import config from 'config';

interface ConfirmIntegrationDeletionParams {
  key: string;
  reloadIntegrations: () => void;
  removeMFAData: () => void;
}

const messages = defineMessages({
  text: {
    id: 'team.deleteIntegration.confirm.text',
    defaultMessage: 'Confirm deletion of integration?',
  },
  mfaSuccess: {
    id: 'team.deleteIntegration.confirm.success',
    defaultMessage: 'Integration deleted',
  },
  mfaFailure: {
    id: 'team.deleteIntegration.confirm.failure',
    defaultMessage: 'Error deleting integration. Please try again.',
  },
});

const ConfirmIntegrationDeletion = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { archiveIntegration, loading, error } = useArchiveIntegration();

  const { key, reloadIntegrations, removeMFAData } = useSelector(
    ({ modal }: { modal: ModalState<ConfirmIntegrationDeletionParams> }) => modal.params
  );

  return (
    <ConfirmModal
      isLoading={loading}
      errorMessage={error}
      text={intl.formatMessage(messages.text)}
      submitBtnText={intl.formatMessage({ id: 'button.confirm' })}
      onSubmit={async () => {
        await archiveIntegration(key);
        reloadIntegrations();
        dispatch(closeModal());
        removeMFAData();
      }}
      onCancel={() => {
        dispatch(closeModal());
        removeMFAData();
      }}
      timeout={parseInt(config.tokenExpirationSec, 10)}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
        removeMFAData();
      }}
    />
  );
};

export default ConfirmIntegrationDeletion;
