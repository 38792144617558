import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface Props {
  className?: string;
  error?: boolean;
}

const FileIcon = ({ className, error }: Props) => {
  const { classes } = useStyles();
  return (
    <svg
      className={className}
      css={classes.root}
      viewBox='0 0 101 130'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      {error ? (
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <path
            css={classes.errorBg}
            fillRule='nonzero'
            d='M69.9244536,6.56277554e-15 L14,-1.77635684e-15 C6.2680135,-3.56013962e-16 2.60581843e-15,6.2680135 3.55271368e-15,14 L1.77635684e-15,116 C2.72325209e-15,123.731986 6.2680135,130 14,130 L87,130 C94.7319865,130 101,123.731986 101,116 L101,31.0040671 C101,27.28086 99.5169309,23.711008 96.8787098,21.0838306 L79.8031634,4.07976349 C77.1793624,1.46694586 73.6273103,-4.23291742e-15 69.9244536,-3.55271368e-15 Z'
          />
          <g
            css={classes.errorX}
            transform='translate(50.445436, 65.445436) rotate(-45.000000) translate(-50.445436, -65.445436) translate(36.445436, 51.945436)'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='9'>
            <path d='M14,27 L14,0' />
            <path d='M0.5,13.5 L27.5,13.5' />
          </g>
        </g>
      ) : (
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <path
            css={classes.okBg}
            fillRule='nonzero'
            d='M69.9244536,6.56277554e-15 L14,-1.77635684e-15 C6.2680135,-3.56013962e-16 2.60581843e-15,6.2680135 3.55271368e-15,14 L1.77635684e-15,116 C2.72325209e-15,123.731986 6.2680135,130 14,130 L87,130 C94.7319865,130 101,123.731986 101,116 L101,31.0040671 C101,27.28086 99.5169309,23.711008 96.8787098,21.0838306 L79.8031634,4.07976349 C77.1793624,1.46694586 73.6273103,-4.23291742e-15 69.9244536,-3.55271368e-15 Z'
          />
          <polyline
            css={classes.okCheck}
            strokeWidth='9'
            strokeLinecap='round'
            strokeLinejoin='round'
            transform='translate(50.500041, 62.500041) rotate(-45.000000) translate(-50.500041, -62.500041) '
            points='37.9180958 55.9900453 37.9180958 69.0100373 63.0819869 69.0100373'
          />
        </g>
      )}
    </svg>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      width: '9rem',
    },
    errorX: {
      stroke: styles.color.red,
    },
    okBg: {
      fill: styles.color.lightPurple,
      opacity: 0.25,
    },
  },
  light: {
    errorBg: {
      fill: styles.color.red,
      opacity: 0.2,
    },
    okCheck: {
      stroke: styles.color.purple,
    },
  },
  dark: {
    errorBg: {
      fill: styles.color.lightPurple,
      opacity: 0.25,
    },
    okCheck: {
      stroke: styles.color.blue,
    },
  },
});

export default FileIcon;
