import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage } from 'react-intl';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { ITabProps } from 'views/components/layout/TabPanel/TabPanel';

const SubmitIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox='0 0 38 39' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-496.000000, -673.000000)' fillRule='nonzero'>
        <g transform='translate(60.000000, 468.000000)'>
          <g transform='translate(432.000000, 202.000000)'>
            <g transform='translate(3.500000, 4.000000)'>
              <path
                className='fill stroke'
                d='M3.23558587,11.09761 C6.03252404,4.92289941 12.2326731,0.629000776 19.4319596,0.629000776 C29.2535777,0.629000776 37.2159595,8.62123461 37.2159595,18.4796754 C37.2159595,28.3397389 29.2535777,36.3303499 19.4319596,36.3303499 C12.2003386,36.3303499 5.97593859,31.9991272 3.19678442,25.7789785'
                strokeWidth='1.75999999'
                strokeLinecap='round'
                strokeLinejoin='round'></path>
              <path
                className='fill-stroke'
                d='M1.51904004,19.8001759 L26.6402899,19.8001759 C27.1263005,19.8001759 27.5202899,19.4061864 27.5202899,18.9201759 C27.5202899,18.4341654 27.1263005,18.0401759 26.6402899,18.0401759 L1.51904004,18.0401759 C1.03302946,18.0401759 0.639040047,18.4341654 0.639040047,18.9201759 C0.639040047,19.4061864 1.03302946,19.8001759 1.51904004,19.8001759 Z'></path>
              <path
                className='fill-stroke'
                d='M20.2604774,24.2724324 C19.9108121,24.6099832 19.9009914,25.1670818 20.2385422,25.516747 C20.5760929,25.8664123 21.1331916,25.876233 21.4828568,25.5386823 L28.1388568,19.1132855 C28.4972705,18.7672893 28.4972705,18.1930318 28.1388568,17.8470356 L21.4828568,11.4216388 C21.1331916,11.0840881 20.5760929,11.0939088 20.2385422,11.4435741 C19.9009914,11.7932392 19.9108121,12.3503378 20.2604774,12.6878887 L26.26063,18.4801605 L20.2604774,24.2724324 Z'></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const CompeteIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox='0 0 38 39' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g className='stroke' transform='translate(-597.000000, -673.000000)' strokeWidth='1.76'>
        <g transform='translate(60.000000, 468.000000)'>
          <g transform='translate(432.000000, 202.000000)'>
            <g transform='translate(105.500000, 4.000000)'>
              <path
                d='M7.48,16.28 C-0.125684776,16.28 0.449949322,6.0852483 0.449949322,3.96 L4.30606172,3.96'
                strokeLinecap='round'></path>
              <path
                d='M29.48,16.28 C37.039991,16.28 36.5120719,6.0852483 36.5120719,3.96 L32.6380309,3.96'
                strokeLinecap='round'></path>
              <path
                className='fill'
                d='M32.12,0.44 L4.84,0.44 C4.84,24.65132 16.9105721,17.77972 16.9105721,26.32412 C16.8170878,31.8132 14.3294534,33.38268 10.1432561,33.38268 L10.1432561,36.52 L26.8151594,36.52 L26.8151594,33.38268 C22.6305466,33.38268 19.9416833,31.8132 19.9416833,26.32412 C19.9416833,17.76988 32.12,24.55128 32.12,0.44 Z'
                fillRule='nonzero'
                strokeLinecap='round'></path>
              <polygon
                className='bg'
                fillRule='nonzero'
                points='18.48 3.96 20.68 8.43940203 25.08 8.43940203 21.5215565 11.2069621 22.88 16.28 18.48 12.9188041 14.08 16.28 15.8277239 11.2069621 11.88 8.43940203 16.28 8.43940203'></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const VerdictIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox='0 0 43 42' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g className='stroke' transform='translate(-697.000000, -669.000000)' strokeWidth='1.76'>
        <g transform='translate(60.000000, 468.000000)'>
          <g transform='translate(432.000000, 202.000000)'>
            <g transform='translate(206.500000, 0.000000)'>
              <path
                className='fill'
                d='M11.44,13.9822222 L10.1822407,12.8457023 C8.53813672,11.1913023 5.91490072,13.8225023 7.56424072,15.4857023 L15.1285167,22.7624201 C16.0414383,23.6820371 17.7387407,23.2486407 18.1083444,22.0448295 C18.4050761,21.0783646 17.864673,20.1182884 17.1294137,19.3776341 L16.7098017,18.9133341'
                fillRule='nonzero'></path>
              <path
                className='fill'
                d='M26.9855656,10.4638279 C27.1582879,10.5679441 27.2564327,10.632594 27.28,10.6577778 C27.476624,10.8678876 27.7087664,11.1561781 27.9764269,11.5226491 C29.6222762,13.1805691 32.4074344,10.711184 30.7563491,9.047984 L22.9023491,1.127984 C21.2582451,-0.526416 18.6350091,2.104784 20.2843491,3.767984 L21.1570157,4.647984 L26.9855656,10.4638279 Z'
                fillRule='nonzero'></path>
              <polygon
                className='fill'
                fillRule='nonzero'
                points='27.28 10.6577778 17.6 19.8 11.44 13.9822222 21.12 4.84'></polygon>
              <path
                className='fill'
                d='M17.6,40.04 L0,40.04 L0,37.4 C0,35.94536 0.78848,34.76 1.76,34.76 L15.84,34.76 C16.81328,34.76 17.6,35.94536 17.6,37.4 L17.6,40.04 Z'
                fillRule='nonzero'></path>
              <path
                className='fill'
                d='M15.84,34.76 L1.76,34.76 L1.76,32.12 C1.76,30.66536 2.54848,29.48 3.52,29.48 L14.08,29.48 C15.05328,29.48 15.84,30.66536 15.84,32.12 L15.84,34.76 Z'
                fillRule='nonzero'></path>
              <line x1='22.4386114' y1='15.6720509' x2='40.4073072' y2='32.8175054'></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const GroundTruthIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox='0 0 40 39' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-802.000000, -673.000000)' fillRule='nonzero'>
        <g transform='translate(60.000000, 468.000000)'>
          <g transform='translate(432.000000, 202.000000)'>
            <g transform='translate(311.500000, 4.000000)'>
              <path
                className='fill stroke'
                d='M36.96,18.48 C36.96,28.6873878 28.6841739,36.96 18.48,36.96 C8.27100522,36.96 0,28.6873878 0,18.48 C0,8.27261217 8.27100522,0 18.48,0 C28.6841739,0 36.96,8.27261217 36.96,18.48 Z'
                strokeWidth='1.76'
                strokeLinecap='round'
                strokeLinejoin='round'></path>
              <path
                className='fill-stroke'
                d='M11.1917412,19.0615023 C10.8534907,18.7125139 10.2963736,18.7038089 9.9473851,19.0420593 C9.59839663,19.3803097 9.58969167,19.9374269 9.92794202,20.2864153 L13.887942,24.3721297 C14.2254173,24.7203184 14.7809653,24.7298996 15.1302463,24.3935549 L27.0102463,12.9535549 C27.3603296,12.6164378 27.3708406,12.0593517 27.0337234,11.7092685 C26.6966062,11.3591852 26.1395202,11.3486741 25.7894369,11.6857914 L14.541267,22.5173624 L11.1917412,19.0615023 Z'></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const RewardIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox='0 0 40 39' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        transform='translate(-903.000000, -673.000000)'
        fillRule='nonzero'
        className='stroke'
        strokeWidth='1.76'>
        <g transform='translate(60.000000, 468.000000)'>
          <g transform='translate(432.000000, 202.000000)'>
            <g transform='translate(412.500000, 4.000000)'>
              <path
                className='fill'
                d='M36.96,18.48 C36.96,28.6873878 28.6841739,36.96 18.48,36.96 C8.27100522,36.96 0,28.6873878 0,18.48 C0,8.27261217 8.27100522,0 18.48,0 C28.6841739,0 36.96,8.27261217 36.96,18.48 Z'></path>
              <polygon
                className='bg'
                points='22.8795802 10.56 14.0804198 10.56 9.68 18.04 14.0804198 25.52 22.8795802 25.52 27.28 18.04'></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const SubmitTab = (props: ITabProps) => {
  const { classes } = useStyles(props);
  return (
    <div css={classes.root}>
      <SubmitIcon css={classes.icon} />
      <p css={classes.text}>
        <FormattedMessage id='howItWorks.tabPanel.submit' defaultMessage='Submit' />
      </p>
    </div>
  );
};

export const CompeteTab = (props: ITabProps) => {
  const { classes } = useStyles(props);
  return (
    <div css={classes.root}>
      <CompeteIcon css={classes.icon} />
      <p css={classes.text}>
        <FormattedMessage id='howItWorks.tabPanel.compete' defaultMessage='Compete' />
      </p>
    </div>
  );
};

export const VerdictTab = (props: ITabProps) => {
  const { classes } = useStyles(props);
  return (
    <div css={classes.root}>
      <VerdictIcon css={classes.icon} />
      <p css={classes.text}>
        <FormattedMessage id='howItWorks.tabPanel.verdict' defaultMessage='Verdict' />
      </p>
    </div>
  );
};

export const GroundTruthTab = (props: ITabProps) => {
  const { classes } = useStyles(props);
  return (
    <div css={classes.root}>
      <GroundTruthIcon css={classes.icon} />
      <p css={classes.text}>
        <FormattedMessage id='howItWorks.tabPanel.groundTruth' defaultMessage='Ground Truth' />
      </p>
    </div>
  );
};

export const RewardTab = (props: ITabProps) => {
  const { classes } = useStyles(props);
  return (
    <div css={classes.root}>
      <RewardIcon css={classes.icon} />
      <p css={classes.text}>
        <FormattedMessage id='howItWorks.tabPanel.reward' defaultMessage='Reward' />
      </p>
    </div>
  );
};

const useStyles = makeStyles(({ isActive, isHovering }: ITabProps) => ({
  base: {
    root: {
      paddingTop: '1rem',
      textAlign: 'center',
      display: 'inline-block',
      width: '11.6rem',
      position: 'relative',
      '&:after': {
        position: 'absolute',
        bottom: '-4.6rem',
        left: '50%',
        transform: 'translateX(-50%) rotate(45deg)',
        content: '""',
        display: isActive ? 'block' : 'none',
        width: '3rem',
        height: '3rem',
        borderTopWidth: 2,
        borderTopStyle: 'solid',
        borderLeftWidth: 2,
        borderLeftStyle: 'solid',
        zIndex: 1,
        [`@media (min-width: ${styles.breakpoint.xxl})`]: {
          bottom: '-4.5rem',
        },
      },
    },
    icon: {
      display: 'inline-block',
      width: '5rem',
      padding: '0.4rem',
      '.fill, .fill-stroke, .stroke': {
        transition: `fill ${styles.easing.time} ${styles.easing.main}, stroke ${styles.easing.time} ${styles.easing.main}`,
      },
    },
    text: {
      transition: `color ${styles.easing.time} ${styles.easing.main}`,
      fontWeight: styles.font.weight.bold,
    },
  },
  light: {
    root: {
      '&:after': {
        borderTopColor: styles.color.purple,
        borderLeftColor: styles.color.purple,
        backgroundColor: styles.color.white,
      },
    },
    icon: {
      '.fill': {
        fill: isActive || isHovering ? styles.color.green : styles.color.white,
      },
      '.bg': {
        fill: styles.color.white,
      },
      '.stroke': {
        stroke: styles.color.purple,
      },
      '.fill-stroke': {
        fill: styles.color.purple,
      },
    },
    text: {
      color: isActive || isHovering ? styles.color.purple : styles.color.black,
    },
  },
  dark: {
    root: {
      '&:after': {
        borderTopColor: styles.color.lightBlue,
        borderLeftColor: styles.color.lightBlue,
        backgroundColor: styles.color.darkOffBlack,
      },
    },
    icon: {
      '.fill': {
        fill: isActive || isHovering ? styles.color.darkOffBlack : styles.color.darkOffBlack,
      },
      '.bg': {
        fill: styles.color.darkOffBlack,
      },
      '.stroke': {
        stroke: isActive || isHovering ? styles.color.lightBlue : styles.color.greyishPurple,
      },
      '.fill-stroke': {
        fill: isActive || isHovering ? styles.color.lightBlue : styles.color.greyishPurple,
      },
    },
    text: {
      color: isActive || isHovering ? styles.color.lightBlue : styles.color.greyishPurple,
    },
  },
}));
