import React, { FC } from 'react';

const PageLayout: FC = ({ children }) => {
  return (
    <div className='container h-mt-sm h-mb-lg'>
      <div className='row'>
        <div className='col-12'>{children}</div>
      </div>
    </div>
  );
};

export default PageLayout;
