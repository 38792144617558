import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { useIntl, defineMessages } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

const messages = defineMessages({
  title: {
    id: 'customerTermsPage.title',
    defaultMessage: 'Terms of service',
  },
});

const Wrapper = styled(Card)`
  padding: calc(30px * 1.5);
  margin-top: 3.2rem !important;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;
    margin-left: 2rem;

    li {
      padding: 0.5rem 0 0.5rem 0;
    }
  }

  ol {
    list-style: decimal;
    counter-reset: item;

    li {
      padding: 0.5rem 0 0.5rem 0;

      & > ol {
        margin-left: 0;
      }
    }
  }
`;

const Header = styled.h1`
  font-size: ${styles.font.size.h3};
`;

const SubHeader = styled.div`
  font-size: ${styles.font.size.h5};
`;

const LetterList = styled.ol`
  margin-left: 1.5rem;
  list-style: none !important;
  counter-reset: list !important;

  li:before {
    content: '(' counter(list, lower-alpha) ') ';
    counter-increment: list;
  }
`;

const CustomerTermsPageContent = () => {
  const { classes } = useStyles();

  return (
    <Wrapper className='container h-mt-lg h-mb-lg'>
      <ScrollToTopOnMount />
      <Typography paragraph>
        <Header css={classes.header}>Minimum Customer Terms</Header>
        <SubHeader css={classes.subheader}>Last Updated: March 30, 2022</SubHeader>
      </Typography>
      <Typography paragraph>
        1. <u>Definitions</u>
        <LetterList>
          <li>
            <strong>&ldquo;Private Community&ldquo; </strong>
            means a community in PolySwarm’s threat detection marketplace set up on behalf of an
            enterprise customer that (a) selects and approves all participants that join such
            community and (b) defines the rules governing storage, sharing, and distribution of
            Submitted Artifacts in such community.
          </li>

          <li>
            <strong>&ldquo;Public Community&ldquo; </strong>
            means a community in PolySwarm’s threat detection marketplace that is open to all
            participants.
          </li>

          <li>
            <strong>&ldquo;Security Expert&ldquo; </strong>
            means an independent, crowd-sourced security expert who has developed anti-malware
            engines for the purpose of scanning Submitted Artifacts.
          </li>

          <li>
            <strong>&ldquo;Submitted Artifacts&ldquo; </strong>
            means potentially malicious files, hashes, or URLs provided by Customer.
          </li>
        </LetterList>
      </Typography>
      <Typography paragraph>
        2. <u>Customer Responsibilities.</u> Customer will (a) only use the PolySwarm Products with
        respect to the Submitted Artifacts, (b) be solely responsible for the legality of Submitted
        Artifacts, (c) use commercially reasonable efforts to prevent unauthorized access to or use
        of the PolySwarm Products and notify PolySwarm promptly of any such unauthorized access or
        use, and (d) ensure that the Submitted Artifacts do not contain any personal data or
        personally identifiable information.
      </Typography>
      <Typography paragraph>
        3. <u>Technology Protections.</u> Customer will not directly or indirectly: (a) attempt to
        probe, scan or test the vulnerability of the PolySwarm Products, breach the security or
        authentication measures of the PolySwarm Products without proper authorization or willfully
        render any part of the PolySwarm Products unusable; (b) attack, or attempt to attack, the
        PolySwarm Products using a denial-of-service attack, a distributed denial-of-service attack
        or any other attack; (c) use or access the PolySwarm Products to develop a product or
        service that is competitive with PolySwarm Products; (d) use the PolySwarm Products to
        engage in any misleading or deceptive comparisons involving the PolySwarm Products or other
        products or services; (e) submit or allow any third party under Customer’s control to submit
        Submitted Artifacts that are subject to the International Traffic in Arms Regulations
        (“ITAR”) maintained by the U.S. Department of State or do anything that would cause the
        PolySwarm Products to provide a defense service, as defined by ITAR; or (f) otherwise use
        the PolySwarm Products (i) to engage in any illegal activity, (ii) to infringe or violate
        any third party rights, or (iii) otherwise outside the scope expressly permitted in this
        Customer Agreement.
      </Typography>
      <Typography paragraph>
        4. <u>Submitted Artifacts.</u> Customer agrees that (a) all Submitted Artifacts submitted in
        Public Communities (and any related metadata and the results of any virus scans related
        thereto) are not confidential information of Customer and may be freely shared or disclosed
        by PolySwarm and the Security Experts during and after the term hereof, and (b) if any
        Submitted Artifacts are flagged as containing malicious code, such Submitted Artifacts and
        any data or information related to such malicious code may be freely shared or disclosed by
        PolySwarm and the Security Experts during and after the term hereof.
      </Typography>
      <Typography paragraph>
        5. <u>Aggregated Information.</u> PolySwarm shall have the right to aggregate, collect and
        analyze data and other information relating to the provision, use and performance of the
        PolySwarm Platform and shall be free (during and after the term hereof) to (i) use such data
        and other information to develop and improve the PolySwarm Products and other PolySwarm
        offerings, and (ii) disclose such data and other information solely in an aggregated and
        anonymized format that does not identify Customer or any individual.
      </Typography>
      <Typography paragraph>
        6. <u>DISCLAIMER.</u> THE POLYSWARM PRODUCTS ARE PROVIDED TO CUSTOMER “AS IS”. POLYSWARM
        DOES NOT PROVIDE CUSTOMER ANY INDEMNITIES, WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
        WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR
        PURPOSE, IN RELATION TO THE POLYSWARM PRODUCTS. WITHOUT LIMITING THE FOREGOING, POLYSWARM
        DOES NOT REPRESENT OR WARRANT THAT ANY OR ALL MALICIOUS FILES OR SECURITY THREATS WILL BE
        DETECTED USING THE POLYSWARM PRODUCTS. POLYSWARM WILL NOT BE RESPONSIBLE FOR ANY MISUSE OF
        THE SUBMITTED ARTIFACTS BY A SECURITY EXPERT OR OTHERWISE HAVE ANY LIABILITY ARISING FROM
        ANY ACTS OR OMISSIONS OF A SECURITY EXPERT.
      </Typography>
      <Typography paragraph>
        7. <u>Customer Remedies.</u> Customer acknowledges that it is contracting directly with
        Partner for the PolySwarm Products and any warranties to Customer are given solely by
        Partner, and not PolySwarm. Customer agrees that any recourse regarding the PolySwarm
        Products, including but not limited to, warranty claims, losses, liabilities, damages and
        expenses, will be submitted and directed to Reseller and not PolySwarm.
      </Typography>
      <Typography paragraph>
        8. <u>Third Party Beneficiary.</u> Customer agrees that PolySwarm is an express third party
        beneficiary of this Customer Agreement.
      </Typography>
    </Wrapper>
  );
};

const CustomerTermsPage = () => {
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <CustomerTermsPageContent />
      </BaseLayout>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {
    header: {
      color: styles.color.purple,
    },
    subheader: {
      color: styles.color.lightGrey,
    },
  },
  dark: {
    header: {
      color: styles.color.xLightGrey,
    },
    subheader: {
      color: styles.color.xLightGrey,
    },
  },
});

export default CustomerTermsPage;
