import { AsyncAction } from 'state/types/actions';
import { ThreatsRes } from 'services/api/schema/tags';

export enum ETagsActionName {
  GET_EMERGING_THREATS = 'GET_EMERGING_THREATS',
}

export interface IGetEmergingThreatsAction
  extends AsyncAction<ETagsActionName.GET_EMERGING_THREATS, ThreatsRes> {}

export type TagsAction = IGetEmergingThreatsAction;
