import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { Field, FieldProps } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';

interface FormSelectProps {
  className?: string;
  name?: string;
  label?: string;
  info?: string;
  placeholder?: string;
  showStatus?: boolean;
  disabled?: boolean;
  selected?: string | number | null;
  options: {
    value: string | number;
    label: string;
    disabled?: boolean;
    status?: string;
  }[];
}

const FormSelect = ({
  className,
  name,
  label,
  info,
  options,
  showStatus,
  disabled,
  selected,
  ...rest
}: FormSelectProps) => {
  const { classes } = useStyles();
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <TextField
          className={className}
          select
          disabled={disabled}
          fullWidth={true}
          value={field.value}
          InputLabelProps={info ? { style: { pointerEvents: 'auto', zIndex: 999 } } : {}}
          label={
            info ? (
              <div css={classes.containerInfo}>
                <Tooltip title={info} placement='top'>
                  <div css={classes.info}>
                    <Icon name='info' />
                  </div>
                </Tooltip>
                {label}
              </div>
            ) : (
              label
            )
          }
          onChange={(e) => form.setFieldValue(field.name, e.target.value)}
          {...rest}>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              selected={option.value === selected}
              onClickCapture={(e) => {
                if (option.disabled) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}>
              {showStatus && 'status' in option ? (
                <div css={classes.containerStatus}>
                  <span>{option.label}</span>
                  <span css={classes.small}>{option.status}</span>
                </div>
              ) : (
                option.label
              )}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Field>
  );
};

const useStyles = makeStyles({
  base: {
    containerInfo: {
      display: 'flex',
    },
    containerStatus: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    small: {
      fontSize: '1.4rem',
    },
    status: {
      fontSize: '0.5rem',
    },
    info: {
      cursor: 'help',
      fontSize: '0.5rem',
      marginRight: styles.spacing.tiny,
    },
  },
  light: {
    info: {
      color: styles.color.xLightGrey,
    },
  },
  dark: {
    info: {
      color: styles.color.xLightGrey,
    },
  },
});

export default FormSelect;
