import React, { useCallback, useEffect, useState, useMemo } from 'react';
import qs from 'query-string';
import reduce from 'lodash/reduce';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import TextField from '@material-ui/core/TextField/TextField';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { camelCaseToWords } from 'utils/text';
import AllSandboxingTableContainer from './AllSandboxingTable/AllSandboxingTableContainer';
import useHasFeature from 'hooks/useHasFeature';
import { useAuth } from 'views/components/providers/AuthProvider';
import { FilterProps } from '../../SandboxFilter';

type Filter = {
  key: string;
  value: string;
};

const messages = {
  seachByHash: 'Search by Hash',
};

const initialFiltersAllSandboxing = {
  startDate: {
    option: 'DAYS',
    value: 90,
  },
  endDate: {
    option: 'TODAY',
    value: 0,
  },
} as Partial<FilterProps>;

const transformFiltersToParams = (filters: Partial<FilterProps>) =>
  reduce(
    filters,
    (acc, val, key) => {
      if (['endDate', 'startDate'].includes(key)) {
        const reducedDates = {
          [key === 'endDate' ? 'end_value' : 'start_value']: (val as FilterProps['startDate'])
            .value,
          [key === 'endDate' ? 'end_option' : 'start_option']: (val as FilterProps['startDate'])
            .option,
        };

        return { ...omit(acc, ['endDate', 'startDate']), ...reducedDates };
      }

      return { ...acc, [key]: val };
    },
    {}
  );

const getFilterTags = (
  filters: Partial<FilterProps> | undefined,
  exclude: Array<keyof FilterProps> = []
) => {
  return Object.keys(filters || {})
    .filter((key) => !exclude.includes(key as keyof FilterProps))
    .map((key) => {
      const value = filters![key as keyof FilterProps]!;

      if (typeof value !== 'string') {
        return {
          key,
          value: value.option === 'DAYS' ? `${value.value} days` : 'Today',
        };
      }

      return {
        key,
        value,
      };
    });
};

function AllSandboxingTab({
  setFilters,
  removeFilter,
  filters,
  toggleFilters,
  useAllSandboxingProps,
}: {
  setFilters: (initialState: Partial<FilterProps>) => void;
  removeFilter: (key: keyof FilterProps) => void;
  filters: Filter[];
  toggleFilters: () => void;
  useAllSandboxingProps: any;
}) {
  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search);
  const { isAuthenticated } = useAuth();
  const { hasPermission } = useHasFeature('sandbox_search');
  const { hasPermission: hasSandboxRequestFeature } = useHasFeature('sandbox_request');
  const [hash, setHash] = useState('');

  const sha256 = !!hash ? hash : query?.sha256;

  const { classes } = useStyles();
  const accessForbidden = !isAuthenticated
    ? 'notLoggedIn'
    : !hasPermission || !hasSandboxRequestFeature
    ? 'notAllowed'
    : undefined;

  const initialParams = transformFiltersToParams(initialFiltersAllSandboxing);

  useEffect(() => {
    const params = qs.stringify({
      ...initialParams,
      ...query,
      sha256,
    });

    if (!!sha256 && isEmpty(query)) {
      history.push(`${location.pathname}?${params}`);
    }
  }, [sha256, location.pathname, query]); // eslint-disable-line

  const filtersWithHash = useMemo(
    () => (!isEmpty(filters) ? [...filters, { key: 'sha256', value: sha256 }] : []),
    [sha256, filters]
  );

  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setHash(value);
      useAllSandboxingProps.refetch(
        (!isEmpty(filters)
          ? filters
          : getFilterTags({ ...initialFiltersAllSandboxing, sha256: value })
        ).map((item) => (item.key === 'sha256' ? { key: 'sha256', value } : item)),
        false
      );
    },
    [filters, useAllSandboxingProps]
  );

  useEffect(() => {
    setFilters([] as Partial<FilterProps>);
    useAllSandboxingProps.refetch([], false, true);
  }, []); // eslint-disable-line

  return (
    <div css={classes.container}>
      <div css={classes.filters}>
        <div css={classes.inputContainer}>
          {!accessForbidden && (
            <TextField
              value={sha256}
              onChange={onTextChange}
              label={messages.seachByHash}
              disabled={!!accessForbidden}
              fullWidth
            />
          )}
          {!!sha256 && (
            <button onClick={() => setHash('')} css={classes.iconContainer}>
              <Icon css={classes.iconRemove} name='close' />
            </button>
          )}
        </div>
        {!accessForbidden && !!sha256 && (
          <div css={classes.tags}>
            {filters
              .filter((item) => item.key !== 'sha256')
              .map((filter) => (
                <div onClick={toggleFilters} css={classes.tag} key={filter.key}>
                  <span>
                    {camelCaseToWords(filter.key)}: {filter.value}
                  </span>
                  <Icon
                    name='close'
                    css={classes.iconClose}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFilter(filter.key as keyof FilterProps);
                    }}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
      <AllSandboxingTableContainer
        useAllSandboxingProps={useAllSandboxingProps}
        filters={filtersWithHash}
        accessForbidden={accessForbidden}
      />
    </div>
  );
}

const useStyles = makeStyles({
  base: {
    container: {
      paddingRight: '3rem',
    },
    filters: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column',
      paddingLeft: '3rem',
      marginBottom: '1rem',
    },
    tags: {
      display: 'flex',
      gap: '1rem',
    },
    tag: {
      background: '#D9D9D9',
      color: styles.color.black,
      padding: '0.4rem 1.8rem',
      borderRadius: '2rem',
      fontSize: '1.4rem',
      fontWeight: 600,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
    },
    tagContainer: {
      display: 'flex',
      gap: '1rem',
    },
    inputContainer: {
      position: 'relative',
    },
    iconContainer: {
      position: 'absolute',
      right: '1rem',
      bottom: '.8rem',
      cursor: 'pointer',
    },
    iconRemove: {
      width: '1.4rem !important',
      height: '1.4rem !important',
    },
    iconClose: {
      width: '1rem !important',
      height: '1rem !important',
      cursor: 'pointer',
    },
  },
  light: {
    iconRemove: {
      color: styles.color.purple,
    },
  },
  dark: {
    iconRemove: {
      color: styles.color.lightBlue,
    },
  },
});
export default AllSandboxingTab;
