import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MessageDescriptor, useIntl } from 'react-intl';
import { RootState } from 'state/root';
import { hideNotification } from 'state/notification/actions';
import Banner from '../Banner';

export const Notification = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { show, status, message, isDismissible, delay } = useSelector(
    ({ notification }: RootState) => notification
  );

  let _message = message;
  if (message && typeof message === 'object' && message.hasOwnProperty('id')) {
    _message = intl.formatMessage(message as MessageDescriptor);
  }

  const _handleDismiss = () => dispatch(hideNotification());

  useEffect(() => {
    if (delay || (show && !isDismissible)) {
      setTimeout(() => {
        dispatch(hideNotification());
      }, delay || 5000);
    }
  }, [delay, show, isDismissible, dispatch]);

  return (
    <Banner
      show={show}
      text={_message}
      level={status || 'info'}
      onDismiss={isDismissible ? () => _handleDismiss() : undefined}
    />
  );
};
