import { HashType } from 'models/Submission';

export const isMd5 = (value: string) => /^[A-Fa-f0-9]{32}$/.test(value);
export const isSha1 = (value: string) => /^[A-Fa-f0-9]{40}$/.test(value);
export const isSha256 = (value: string) => /^[A-Fa-f0-9]{64}$/.test(value);

export const isHash = (value: string) => {
  if (isMd5(value)) return true;
  if (isSha1(value)) return true;
  if (isSha256(value)) return true;
  return false;
};

export const getHashType = (value: string): HashType => {
  if (isMd5(value)) return 'md5';
  if (isSha1(value)) return 'sha1';
  if (isSha256(value)) return 'sha256';
  throw new Error('Invalid hash type');
};
