import React from 'react'; // eslint-disable-line
import { ISandboxData } from 'models/Sandbox';
import isEmpty from 'lodash/isEmpty';
import { IAccordionData } from 'views/components/Accordion/AccordionData/AccordionData';
import mitreJSONData from './mitreData';

interface ITriageData {
  extracted: {
    config: {
      c2: string[];
      attr: {
        autorun: {
          control: string;
          name: string;
        };
        autostartMethod: string;
        installPath: string;
        registryKeyname: string;
        watchdogPath: string;
        taskschedulerTaskname: string;
      };
    };
  }[];
}

export const mapMitre = (
  mitreData: ISandboxData['tool_metadata']['ttp'],
  tool_name: string = ''
) => {
  if (!mitreData || !mitreData.length) return undefined;

  return [
    {
      title: 'ttps',
      id: `${tool_name === 'cape' ? 'cape_sandbox_v2' : 'triage_sandbox_v0'}.ttp`,
      component: 'table' as const,
      data: mitreData.map((mitre) => ({
        ID: (
          <a
            className='p a'
            href={`https://attack.mitre.org/techniques/${mitre.replace('.', '/')}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {mitre}
          </a>
        ),
        Name: mitreJSONData[mitre as keyof typeof mitreJSONData] || '',
      })),
    },
  ];
};

export const mapOSAutostarts = (autostartsData: ITriageData['extracted'] = []) => {
  const extracted = autostartsData.map((data) => data.config);
  const [autostarts] = extracted
    .filter(Boolean)
    .filter((a) => a.attr?.autostartMethod)
    .map((d) => d.attr)
    .flat()
    .filter(Boolean);

  if (!autostarts) return null;

  const { autostartMethod, installPath, registryKeyname, watchdogPath, taskschedulerTaskname } =
    autostarts || {};
  return {
    'Autostart Method': autostartMethod,
    'Install Path': installPath,
    'Registry Key Name': registryKeyname,
    'Watchdog Path': watchdogPath,
    'Task Scheduler Task Name': taskschedulerTaskname,
  };
};

export const mapOSAutorun = (autorunData: ITriageData['extracted'] = []) => {
  const extracted = autorunData.map((data) => data.config);
  const autorun = extracted
    .filter(Boolean)
    .filter((a) => a.attr)
    .map((d) => d.attr.autorun)
    .flat()
    .filter(Boolean);
  if (!autorun.length) return null;
  const data = autorun
    .map(({ control, name }) => {
      return [
        {
          component: 'list' as const,
          data: {
            name: { label: 'Autorun Control', value: control },
            control: { label: 'Autorun Name', value: name },
          },
        },
        {
          component: 'divider' as const,
        },
      ];
    })
    .flat();
  return data.slice(0, -1);
};

export const mapSignatures = (signaturesData: [], signatureType: string) => {
  return signaturesData
    ?.map((sig: any, index: number) =>
      // @ts-ignore
      ([] as IAccordionData['data']).concat(
        {
          component: 'list' as const,
          title: signatureType,
          id: `${signatureType.split(' ').join('')}-${index}`,
          data: Object.assign(
            {},
            {
              name: {
                label: 'Name',
                value: sig?.name ?? '-',
              },
              label: {
                label: 'Label',
                value: sig?.label ?? '-',
              },
              score: {
                label: 'Score (10)',
                value: sig?.score ?? '-',
              },
              tags: {
                label: 'Tags',
                value: isEmpty(sig?.tags) ? '-' : sig?.tags?.join(','),
              },
              ttp: {
                label: 'TTPs',
                value: isEmpty(sig?.ttp) ? '-' : sig?.ttp?.join(','),
              },
            },
            signatureType.includes('Static') && {
              desc: { label: 'Description', value: sig?.desc ?? '-' },
            }
          ),
        },
        index < signaturesData.length - 1 ? { component: 'divider' } : <></>
      )
    )
    .flat();
};
