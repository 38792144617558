import { SandboxingDataRes, SandboxingRes } from 'services/api/schema/sandboxing';

export interface Sandboxing extends SandboxingRes {}

export interface SandboxingData extends SandboxingDataRes {}

export type Sandboxings = SandboxingData;

export type VM = {
  architecture: string;
  id: string;
  language: string;
  name: string;
  os_name: string;
  os_version: string;
  slug: string;
  supported_artifact_types: [string];
  supported_browsers: [string] | [];
};

export type Provider = {
  id: string;
  name: string;
  slug: string;
  tool: string;
  vms: Record<string, VM>;
};
export type ProviderRes = { result: Record<string, Provider>; status: string };

export type ProviderList = {
  vms: VM[];
  id: string;
  name: string;
  slug: string;
  tool: string;
}[];

export const status = {
  SUCCEEDED: 'Success',
  STARTED: 'Started',
  FAILED: 'Failed',
  PENDING: 'Pending',
  TIMEDOUT: 'Timed Out',
  DELAYED: 'Delayed',
  FAILED_REIMBURSED: 'Failed Reimbursed',
  TIMEDOUT_REIMBURSED: 'Timedout Reimbursed',
  COLLECTING_DATA: 'Collecting Data',
} as const;

export const providerList = ['Triage', 'Cape'];
export const statusList = Object.values(status);

export type StatusKeys = keyof typeof status;
export type StatusValues = (typeof status)[StatusKeys];

export const invertedStatus = Object.keys(status).reduce(
  (obj, key) => ({ ...obj, [status[key as StatusKeys]]: key }),
  {} as Record<StatusValues, StatusKeys>
);

export const filterMapper = {
  sandboxProvider: 'sandbox',
  status: 'status',
  sha256: 'sha256',
  hash: 'sha256',
};
export const filterIgnore = ['last_updated'];
