import { ModalName } from 'views/components/Modal/ModalConductor';
import { ModalActionName, CloseModalAction, OpenModalAction } from './types';

export const openModal = <T extends {}>(name: ModalName, params?: T): OpenModalAction => ({
  name,
  params: params ? params : {},
  type: ModalActionName.OPEN_MODAL,
});

export const closeModal = (): CloseModalAction => ({
  type: ModalActionName.CLOSE_MODAL,
});
