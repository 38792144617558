import { useState, useEffect } from 'react';
import api from 'services/api';

export const useOptions = () => {
  const [options, setOptions] = useState<string[]>([]);
  const loading = options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await api.getMicroengineTags();
      if (active) {
        setOptions(response.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return { loading, options };
};
