import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-more' viewBox='0 0 32 32'>
      <path d='M16.667 12c-1.105 0-2-0.895-2-2s0.895-2 2-2c1.105 0 2 0.895 2 2s-0.895 2-2 2zM16.667 18.667c-1.105 0-2-0.895-2-2s0.895-2 2-2c1.105 0 2 0.895 2 2s-0.895 2-2 2zM16.667 25.333c-1.105 0-2-0.895-2-2s0.895-2 2-2c1.105 0 2 0.895 2 2s-0.895 2-2 2z' />
    </symbol>

    <Global
      styles={css`
        .icon-more {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
