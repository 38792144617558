/**
 * Create a temp anchor tag in the DOM, then click it.
 *
 * If the link has to be opened in a new tab, it uses the
 * `window.open` function instead, so the browser clones
 * the session storage into the new window, preserving
 * the account context among other states tracked by Redux.
 */
const clickLink = (url: string, newTab?: boolean) => {
  if (newTab) {
    return window.open(url, '_blank');
  }
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default clickLink;
