import { Auth0DecodedHash, Auth0UserProfile, AuthorizeOptions, WebAuth } from 'auth0-js';
import { v4 as uuidv4 } from 'uuid';
import config from 'config';
import { setWalletNonce } from 'state/auth/actions';
import { generateWalletNonce, generateWithdrawalNonce, toWEI } from 'utils/nct';

class AuthService {
  callbackUrl = `${window.location.protocol}//${window.location.host}/callback`;

  private webAuthConfig = {
    domain: config.auth0Url.replace(/http(s?):\/\//, ''),
    clientID: config.auth0ClientId,
    redirectUri: this.callbackUrl,
    audience: config.auth0Audience,
    responseType: 'token id_token',
    scope: 'openid profile email',
  };

  private webAuth = new WebAuth(this.webAuthConfig);

  // Scopes 'withdraw:wallet' and 'write:wallet' have to be added to Auth0, at
  // "Applications" > "APIs" > * (Portal API) > "Permissions" tab.
  //
  // In order to enforce MFA when requesting these scopes, add this
  // Login Flow Action in Auth0 ("Actions" > "Flows" > ["Login"]):
  //
  // exports.onExecutePostLogin = async (event, api) => {
  //   const scopes = event?.transaction?.requested_scopes || [];
  //   if (scopes.indexOf('write:wallet')>=0 || scopes.indexOf('withdraw:wallet')>=0) {
  //     api.multifactor.enable("any");
  //     const match = /wallet\|(?<wallet>(\w|-)+)\|/.exec(event.request.query.nonce);
  //     if (match) {
  //       const walletHash = match.groups?.wallet;
  //       if (walletHash) {
  //         api.accessToken.setCustomClaim('https://portal-api/wallet', walletHash);
  //       }
  //     }
  //   }
  // };
  private webAuthWalletWithdrawal = new WebAuth({
    ...this.webAuthConfig,
    scope: this.webAuthConfig.scope + ' withdraw:wallet', // Wallet withdrawal
  });
  private webAuthWalletUpdate = new WebAuth({
    ...this.webAuthConfig,
    scope: this.webAuthConfig.scope + ' write:wallet', // Wallet update (change the withdrawal address)
  });

  // Check notes above about scopes
  private webAuthRemoveMfa = new WebAuth({
    ...this.webAuthConfig,
    scope: this.webAuthConfig.scope + ' remove:authenticators', // Disable MFA and remove authenticators
  });

  // Check notes above about scopes
  private webAuthWriteMfa = new WebAuth({
    ...this.webAuthConfig,
    scope: this.webAuthConfig.scope + ' write:authenticators', // Enable MFA and add authenticators
  });

  // Check notes above about scopes
  private webAuthWriteApiKey = new WebAuth({
    ...this.webAuthConfig,
    scope: this.webAuthConfig.scope + ' write:api_key', // Create / Delete API Keys
  });

  // Check notes above about scopes
  private webAuthWriteTeams = new WebAuth({
    ...this.webAuthConfig,
    scope: this.webAuthConfig.scope + ' write:teams', // Create / Delete API Keys
  });

  private authorize(webAuth: WebAuth, email?: string, prompt?: string, nonceMessage?: string) {
    const authOptions: AuthorizeOptions = {
      redirectUri: this.callbackUrl,
      login_hint: email,
    };
    if (prompt) {
      authOptions.prompt = prompt;
    }
    if (nonceMessage) {
      // Enforce nonce uniqueness
      authOptions.nonce = `n-${uuidv4()}-${nonceMessage}`;
    }
    webAuth.authorize(authOptions);
  }

  login(email?: string) {
    this.authorize(this.webAuth, email, 'login');
  }

  loginRemoveMfa(email?: string) {
    this.authorize(this.webAuthRemoveMfa, email);
  }

  loginWriteMfa(email?: string) {
    this.authorize(this.webAuthWriteMfa, email);
  }

  loginWriteApiKey(email?: string) {
    this.authorize(this.webAuthWriteApiKey, email);
  }

  loginWriteTeams(email?: string) {
    this.authorize(this.webAuthWriteTeams, email);
  }

  loginWalletUpdate(
    withdrawalAddress: string | null,
    limit: string | null,
    accountNumber: number,
    email?: string
  ) {
    const walletNonce = generateWalletNonce(accountNumber, withdrawalAddress, limit);
    setWalletNonce(walletNonce);
    const nonceMessage = `wallet|${walletNonce}|`;
    this.authorize(this.webAuthWalletUpdate, email, undefined, nonceMessage);
  }

  loginWalletWithdrawal(amount: string, fee: string, accountNumber: number, email?: string) {
    // Plain number nonce randomly chosen
    const nonce = (Math.floor(Math.random() * 1_000_000_000_000) + 1).toString();
    // Nonce hashed along with the op details
    const walletNonce = generateWithdrawalNonce(
      accountNumber,
      toWEI(amount).toFixed(),
      toWEI(fee).toFixed(),
      nonce
    );
    setWalletNonce(walletNonce, nonce);
    const nonceMessage = `wallet|${walletNonce}|`;
    this.authorize(this.webAuthWalletWithdrawal, email, undefined, nonceMessage);
  }

  logout() {
    this.webAuth.logout({
      returnTo: this.callbackUrl,
    });
  }

  parseHash() {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.webAuth.parseHash({}, (err, res) => {
        if (res && res.idToken && res.idTokenPayload && res.accessToken && res.expiresIn) {
          resolve(res);
        } else if (err) {
          reject(new Error(err.error));
        } else {
          reject(new Error('auth_tokens_not_found'));
        }
      });
    });
  }

  checkSession() {
    return new Promise<Auth0DecodedHash>((resolve, reject) => {
      this.webAuth.checkSession(
        { extraData: { pathname: window.location.pathname } } as any,
        (err, res) => {
          if (res && res.idToken && res.idTokenPayload && res.accessToken && res.expiresIn) {
            resolve(res);
          } else if (err) {
            reject(new Error(err.error));
          } else {
            reject(new Error('auth_tokens_not_found'));
          }
        }
      );
    });
  }

  changePassword(email: string) {
    const options = { email, connection: 'Username-Password-Authentication' };
    return new Promise<string>((resolve, reject) => {
      this.webAuth.changePassword(options, (err, res) => {
        if (err) {
          reject(new Error(err.code));
        } else {
          resolve(res);
        }
      });
    });
  }

  getUserInfo(accessToken: string): Promise<Auth0UserProfile> {
    return new Promise((resolve, reject) => {
      this.webAuth.client.userInfo(accessToken, (err, user) => {
        if (err) {
          return reject(err);
        }

        return resolve(user);
      });
    });
  }
}

export default new AuthService();
