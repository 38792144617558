import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { useIntl, defineMessages } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

const messages = defineMessages({
  title: {
    id: 'accessibilityPage.title',
    defaultMessage: 'Accessibility',
  },
});

const Wrapper = styled(Card)`
  padding: calc(30px * 1.5);
  margin-top: 3.2rem !important;

  ul {
    list-style: disc;
    margin-left: 5rem;

    li {
      padding: 0.5rem 0 0.5rem 0;
      font-size: 1.8rem;
    }
  }
`;

const Header = styled.h1`
  font-size: ${styles.font.size.h3};
`;

const Header4 = styled.h3`
  font-size: ${styles.font.size.h4};
`;

const Signature = styled.p`
  color: ${styles.color.lightGrey};
  font-size: 1.7rem;
`;

const AccessibilityPageContent = () => {
  const { classes } = useStyles();

  return (
    <Wrapper className='container h-mt-lg h-mb-lg'>
      <ScrollToTopOnMount />
      <Typography paragraph>
        <Header css={classes.header}>
          Accessibility Statement for{' '}
          <span className='basic-information website-name'>PolySwarm</span>
        </Header>
      </Typography>
      <Typography paragraph>
        <span className='basic-information organization-name'>Swarm Technologies Inc</span> is
        committed to ensuring digital accessibility for people with disabilities. We are continually
        improving the user experience for everyone, and applying the relevant accessibility
        standards.
      </Typography>
      <Typography paragraph>
        <Header4>Measures to support accessibility</Header4>
      </Typography>
      <Typography paragraph>
        <span className='basic-information organization-name'>Swarm Technologies Inc </span>
        takes the following measures to ensure accessibility of
        <span className='basic-information website-name'> PolySwarm</span>:
      </Typography>
      <Typography paragraph>
        <ul>
          <li>Assign clear accessibility targets and responsibilities.</li>
          <li>Employ formal accessibility quality assurance methods.</li>
          <li>Include people with disabilities in our design personas.</li>
          <li>Include accessibility in all new web development efforts.</li>
          <li>Update existing materials to be accessible.</li>
        </ul>
      </Typography>
      <Typography paragraph>
        <Header4>Conformance status</Header4>
      </Typography>
      <Typography paragraph>
        The{' '}
        <a className='a' href='https://www.w3.org/WAI/standards-guidelines/wcag/'>
          Web Content Accessibility Guidelines (WCAG)
        </a>{' '}
        defines requirements for designers and developers to improve accessibility for people with
        disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA.
        PolySwarm is <span data-printfilter='lowercase'>partially conformant</span> with{' '}
        <span data-negate=''>WCAG 2.1 level AA</span>. Partially conformant means that some parts of
        the content do not fully conform to the accessibility standard.
      </Typography>
      <Typography paragraph>
        <Header4>Feedback</Header4>
      </Typography>
      <Typography paragraph>
        We welcome your feedback on the accessibility of PolySwarm. Please let us know if you
        encounter accessibility barriers on PolySwarm:
      </Typography>
      <Typography paragraph>
        <ul>
          <li>
            E-mail:{' '}
            <a className='a' href='mailto:info@polyswarm.ioinfo@polyswarm.io'>
              info@polyswarm.io
            </a>
          </li>
        </ul>
      </Typography>
      <Typography paragraph>We try to respond to feedback within 5 business days.</Typography>
      <Typography paragraph>
        <Header4>Compatibility with browsers and assistive technology</Header4>
      </Typography>
      <Typography paragraph>
        PolySwarm is designed to be compatible with the following assistive technologies:
      </Typography>
      <Typography paragraph>
        <ul>
          <li>
            Chrome with screen magnifiers, screen readers, text-to-speech software, alternative
            keyboards, and alternative pointing devices on Windows, MacOS, and Linux.
          </li>
          <li>
            Firefox with screen magnifiers, screen readers, text-to-speech software, alternative
            keyboards, and alternative pointing devices on Windows, MacOS, and Linux.
          </li>
          <li>
            Safari with screen magnifiers, screen readers, text-to-speech software, alternative
            keyboards, and alternative pointing devices on MacOS.
          </li>
          <li>
            Edge with screen magnifiers, screen readers, text-to-speech software, alternative
            keyboards, and alternative pointing devices on Windows.
          </li>
        </ul>
      </Typography>
      <Typography paragraph>PolySwarm is not compatible with:</Typography>
      <Typography paragraph>
        <ul>
          <li>Browsers that do not support javascript.</li>
          <li>Browsers that do not support HTML5 standards.</li>
          <li>Browsers older than 2 major versions.</li>
        </ul>
      </Typography>
      <Typography paragraph>
        <Header4>Technical specifications</Header4>
      </Typography>
      <Typography paragraph>
        Accessibility of PolySwarm relies on the following technologies to work with the particular
        combination of web browser and any assistive technologies or plugins installed on your
        computer:
      </Typography>
      <Typography paragraph>
        <ul>
          <li>HTML</li>
          <li>WAI-ARIA</li>
          <li>CSS</li>
          <li>JavaScript</li>
        </ul>
      </Typography>
      <Typography paragraph>
        These technologies are relied upon for conformance with the accessibility standards used.
      </Typography>
      <Typography paragraph>
        <Header4>Assessment approach</Header4>
      </Typography>
      <Typography paragraph>
        Swarm Technologies Inc assessed the accessibility of PolySwarm by the following approaches:
      </Typography>
      <Typography paragraph>
        <ul>
          <li>Self-evaluation</li>
        </ul>
      </Typography>
      <Typography paragraph>
        <Header4>Formal approval of this accessibility statement</Header4>
      </Typography>
      <Typography paragraph>This Accessibility Statement is approved by:</Typography>
      <Typography className='signature' paragraph>
        <Signature>Swarm Technologies Inc</Signature>
        <Signature>Operations Department</Signature>
        <Signature>Chief Operations Officer</Signature>
      </Typography>
    </Wrapper>
  );
};

const AccessibilityPage = () => {
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <AccessibilityPageContent />
      </BaseLayout>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {
    header: {
      color: styles.color.purple,
    },
    subheader: {
      color: styles.color.lightGrey,
    },
  },
  dark: {
    header: {
      color: styles.color.xLightGrey,
    },
    subheader: {
      color: styles.color.xLightGrey,
    },
  },
});

export default AccessibilityPage;
