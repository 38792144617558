import React, { useEffect } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useSelector } from 'react-redux';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { ESubmissionResultType } from 'models/Submission';
import { RootState } from 'state/root';
import styles from 'views/styles';
import AccordionFileDetails from 'views/components/Accordion/AccordionFileDetails';
import LoadingNotice from 'views/components/Banner/LoadingNotice';
import { SubmissionActionName } from 'state/submission/types';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { ActiveSections } from 'views/components/ActiveSections';
import { errorSelector } from 'state/requests/selectors';
import ErrorPageContent, {
  messages as errorMessages,
} from 'views/components/error/ErrorPageContent';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';
import { toolWhitelist } from 'views/pages/ScanPage/config';

interface IFileDetailsTab {
  type?: keyof typeof ESubmissionResultType;
  onRetryFileDetails: () => void;
}

const messages = defineMessages({
  loading: {
    id: 'fileDetails.loading',
    defaultMessage:
      'Performing additional analysis of this artifact. Check back later for additional details.',
  },
  attributes: {
    id: 'fileDetails.attributes',
    defaultMessage: 'Artifact Attributes',
  },
});

const FileDetailsTab = ({ type, onRetryFileDetails }: IFileDetailsTab) => {
  const location = useLocation();

  const intl = useIntl();
  const { requests } = useSelector((state: RootState) => state);
  const error = errorSelector(requests, [SubmissionActionName.GET_SUBMISSION]);
  const artifactMeta = useSelector((state: RootState) => state.submission.fileDetails);
  const loading = useSelector((state: RootState) => state.submission.loadingExtra);

  useEffect(() => {
    if (location.hash) {
      document.querySelector(location.hash)?.scrollIntoView();
    }
  }, [location.hash, artifactMeta]);

  if (error) {
    return (
      <ErrorPageContent
        className='h-mt-lg h-mb-lg'
        heading={intl.formatMessage(errorMessages.headingReload)}
        text={intl.formatMessage(errorMessages.textReload)}
      >
        <Button
          style={{ fontSize: '2.4rem' }}
          color='primary'
          variant='contained'
          onClick={onRetryFileDetails}
        >
          <FormattedMessage {...errorMessages.buttonReload} />
        </Button>
      </ErrorPageContent>
    );
  }

  if (!artifactMeta || loading) {
    return <PanelLoader />;
  }

  const secSinceSubmission =
    (Date.now() - new Date(artifactMeta.attributes.first_seen).getTime()) / 1000;
  const showLoadingNotice = secSinceSubmission < 30 * 60; // 30 minutes

  return (
    <div css={style.root} data-cy='scanFileDetails'>
      <ActiveSections
        sections={[
          {
            label: 'A',
            title: 'Attributes',
            active: !!artifactMeta.attributes,
          },
          {
            label: 'S',
            title: 'Strings',
            active: !!artifactMeta?.metadata.strings,
          },
          {
            label: 'E',
            title: 'Exiftool',
            active: !!artifactMeta?.metadata.exiftool,
          },
          {
            label: 'P',
            title: 'Pefile',
            active: !!artifactMeta?.metadata.pefile,
          },
          {
            label: 'L',
            title: 'Lief',
            active: !!artifactMeta?.metadata.lief,
          },
          {
            label: 'H',
            title: 'Hash',
            active: !!artifactMeta?.metadata.hash,
          },
        ]}
      />
      <div css={style.item}>
        <AccordionFileDetails
          title={intl.formatMessage(messages.attributes)}
          data={artifactMeta.attributes as any}
          columns={2}
        />
      </div>
      <>
        {Object.keys(artifactMeta.metadata).length > 0
          ? Object.entries(artifactMeta.metadata)
              .filter(([tool]) => toolWhitelist.includes(tool))
              .map(([tool, data]) => {
                return (
                  <div key={tool} css={style.item}>
                    <AccordionFileDetails title={tool} data={data} columns={2} />
                  </div>
                );
              })
          : showLoadingNotice && <LoadingNotice text={intl.formatMessage(messages.loading)} />}
      </>
    </div>
  );
};

const style = {
  root: css`
    margin: calc(${styles.spacing.grid} / 2) ${styles.spacing.grid};
  `,
  item: css`
    margin-bottom: ${styles.spacing.grid};
    &:last-child {
      margin-bottom: 0;
    }
  `,
};
export default FileDetailsTab;
