import React, { useState, useRef } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useIntl, FormattedMessage } from 'react-intl';
import axios, { CancelToken, CancelTokenSource, AxiosPromise } from 'axios';
import { Field, FieldProps, FormikProps } from 'formik';
import Dropzone from 'react-dropzone';
import { ErrorKeys, translateError, errorMessages, isErrorOfType } from 'utils/error';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import FileSelect from 'views/components/Button/FileSelect';
import Loader from 'views/components/Loader';
import Tag from 'views/components/Tag';
import CodeEditor, { RulesetValidationResponse } from 'views/components/form/CodeEditor';

interface IDropTextArea {
  className?: string;
  name: string;
  placeholder?: string;
  label?: string;
  isInitialValid?: boolean;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  onClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  onValidate?: (
    value: string,
    cancelToken?: CancelToken
  ) => AxiosPromise<RulesetValidationResponse>;
  onAfterValidate?: (result: RulesetValidationResponse) => void;
}

const errorKeys: ErrorKeys = {};

/**
 * The DropTextArea component
 */
const DropTextArea = ({
  className,
  name,
  isInitialValid,
  label,
  onValidate,
  onAfterValidate,
  onClick,
  onFocus,
  onBlur,
  onChange,
}: IDropTextArea) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [validationResult, setValidationResult] = useState<RulesetValidationResponse | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isDragActive, setIsDragActive] = useState(false);

  const intl = useIntl();

  const { classes } = useStyles();

  const _timeoutId = useRef<number>();
  const _source = useRef<CancelTokenSource>();

  const _resetValidationState = () => {
    setErrorMessage(undefined);
    setIsValidating(false);
    setIsValid(null);
  };

  const _handleDragEnter = () => setIsDragActive(true);

  const _handleDragLeave = () => setIsDragActive(false);

  const _validate = async (value: string) => {
    if (!value.trim()) {
      setIsValid(false);
      return;
    }
    if (onValidate) {
      setIsValidating(true);
      try {
        _source.current = axios.CancelToken.source();
        const { data } = await onValidate(value, _source.current.token);
        if (_source.current) {
          setIsValid(true);
          onAfterValidate && onAfterValidate(data);
        }
      } catch (error: any) {
        if (isErrorOfType('hunting_error', error)) {
          if (_source.current) {
            setErrorMessage(intl.formatMessage(errorMessages.invalidRules));
            setValidationResult(error.response.data.data);
            setIsValid(false);
            onAfterValidate && onAfterValidate(error.response.data.data);
          }
        } else {
          setErrorMessage(translateError(intl, errorKeys, error));
          setIsValid(false);
        }
      }
      setIsValidating(false);
    }
  };

  const _readFile = (files: File[], form: FormikProps<any>) => {
    const file = files[0];
    const reader = new FileReader();
    setIsDragActive(false);
    reader.onload = (e: any) => {
      form.setFieldValue(name, e.target.result);
      _validate(e.target.result);
    };
    reader.readAsText(file);
  };

  const _handleFocus = (value: string) => {
    setIsFocused(true);
    if (_source.current) {
      _source.current.cancel();
      _source.current = undefined;
    }
    _resetValidationState();
    onFocus && onFocus(value);
  };

  const _handleBlur = (value: string) => {
    setIsFocused(false);
    if (value) {
      _timeoutId.current = window.setTimeout(() => {
        _validate(value);
        setIsEditing(false);
      }, 200);
    } else {
      setIsEditing(false);
    }
    onBlur && onBlur(value);
  };

  const _handleChange = (value: string, form: FormikProps<any>) => {
    form.setFieldValue(name, value);
    _resetValidationState();
    if (isFocused && value) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
    onChange && onChange(value);
  };

  const _handleClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    _timeoutId.current && clearTimeout(_timeoutId.current);
    _resetValidationState();
    onClick && onClick(e);
  };

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <Dropzone
          onDrop={(file) => _readFile(file, form)}
          onDragEnter={_handleDragEnter}
          onDragLeave={_handleDragLeave}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                onClick: (e) => e.stopPropagation(),
                tabIndex: -1,
              })}
              data-testid='dropTextArea'
              className={className}
              css={classes.root}
            >
              {label && <FormLabel css={classes.label}>{label}</FormLabel>}
              <CodeEditor
                {...field}
                validationResult={validationResult}
                isFocused={isDragActive}
                onFocus={_handleFocus}
                onBlur={_handleBlur}
                onChange={(value) => _handleChange(value, form)}
              />
              <input style={{ display: 'none' }} {...getInputProps()} />
              {isEditing ? (
                <div css={classes.buttonWrap}>
                  <button css={classes.button} type='button'>
                    <Tag css={classes.validate}>
                      <FormattedMessage id='dropTextArea.validate' defaultMessage='Validate' />
                    </Tag>
                  </button>
                </div>
              ) : (
                <Tooltip open={!!errorMessage} title={errorMessage || ''} placement='top'>
                  <div css={classes.buttonWrap}>
                    <FileSelect
                      id='drop-text-area-select'
                      css={classes.button}
                      tabIndex={0}
                      onSelection={(file) => _readFile(file, form)}
                      onClick={_handleClick}
                    >
                      <FileSelectIcon
                        isInitialValid={isInitialValid}
                        isValidating={isValidating}
                        isValid={isValid}
                      />
                    </FileSelect>
                  </div>
                </Tooltip>
              )}
            </div>
          )}
        </Dropzone>
      )}
    </Field>
  );
};

const FileSelectIcon = ({
  isInitialValid,
  isValidating,
  isValid,
}: {
  isInitialValid?: boolean;
  isValidating: boolean;
  isValid: boolean | null;
}) => {
  const { classes } = useIconStyles((isInitialValid && isValid === null) || isValid);

  if (isValidating) {
    return <Loader css={classes.loader} testId='fileValidationLoader' />;
  }
  if (isInitialValid && isValid === null) {
    return <Icon css={classes.result} name='benign' testId='fileIsValid' />;
  }
  if (isValid === null) {
    return <Icon css={classes.upload} name='plus-alt' />;
  }
  return (
    <Icon
      css={classes.result}
      name={isValid ? 'benign' : 'malicious'}
      testId={isValid ? 'fileIsValid' : 'fileIsInvalid'}
    />
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      position: 'relative',
      outline: 'none',
    },
    label: {
      fontSize: `${styles.font.size.p1} !important`,
      fontWeight: styles.font.weight.medium,
      marginBottom: styles.spacing.xxs,
    },
    buttonWrap: {
      position: 'absolute',
      zIndex: 1,
      bottom: '2rem',
      right: '2rem',
    },
    button: {
      cursor: 'pointer',
      fontSize: '0.65rem',
      padding: '0.1rem 0.1rem 0',
      '&:focus': {
        outline: `1px dotted ${styles.color.lightGrey}`,
      },
    },
    validate: {
      fontSize: '0.9rem',
      padding: '0.8rem',
      letterSpacing: 'normal',
    },
  },
});

const useIconStyles = makeStyles((isValid: boolean | null) => ({
  base: {
    loader: {
      fontSize: '0.15rem !important',
    },
    result: {
      color: isValid ? styles.color.medGreen : styles.color.red,
    },
  },
  light: {
    upload: {
      color: styles.color.purple,
    },
  },
  dark: {
    upload: {
      color: styles.color.lightBlue,
    },
  },
}));

export default DropTextArea;
