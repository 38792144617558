import { PricingData } from './types';

export const pricingData: PricingData = {
  plans: [
    {
      name: 'BASIC',
      features: [
        { label: '1,250/day PolySwarm Scan & Search' },
        { label: '375/month PolySwarm Intelligence' },
        { label: 'Unlimited YARA Hunting Rules' },
      ],
      comparisonSections: {
        polySwarmApi: [
          {
            label: 'PolySwarm Scan & Search',
            info: 'The maximum total daily usage for an account across all features.',
            bold: true,
            included: true,
            limit: '1,250 /day',
          },
          {
            label: 'Hash Search',
            info: 'Search for an artifact on the PolySwarm network by hash.',
            included: true,
          },
          { label: 'Perform Scan', info: 'Scan a file or URL.', included: true },
          {
            label: 'Search Sandbox Data/Results',
            info: 'Search through Sandbox related metadata on an artifact.',
            included: true,
          },
          {
            label: 'All Scan (History)',
            info: 'Access the complete history of all scans performed on your account.',
            included: false,
          },
        ],
        polySwarmIntelligence: [
          {
            label: 'PolySwarm Intelligence',
            info: 'The maximum total monthly usage for an account across all features.',
            bold: true,
            included: true,
            limit: '375 /month',
          },
          {
            label: 'Metadata Search',
            info: 'Search for an artifact on the PolySwarm network by metadata.',
            included: true,
          },
          { label: 'List/Tag/Link View', info: '', included: true },
          {
            label: 'IOC Search',
            info: 'Search for an artifact in the PolySwarm network by IOC.',
            included: true,
          },
          {
            label: 'Downloads Artifacts +Sandbox',
            info: 'Download artifacts from the PolySwarm network.',
            included: true,
          },
        ],
        sandboxing: [{ label: 'On-Demand Sandboxing', included: true, limit: '0 /month' }],
        hunting: [
          {
            label: 'Use YARA Hunting Rules',
            info: 'YARA Rules used for Live and Historical Hunting in the PolySwarm network.',
            included: true,
            limit: 'UNLIMITED',
          },
          {
            label: 'Live Hunts',
            info: 'Live Hunting will match your active YARA ruleset against new artifacts as they are submitted.',
            included: true,
            limit: '10 /month',
          },
          {
            label: 'Historical Hunts',
            info: 'Historical Hunting will match your YARA ruleset against previously submitted artifacts.',
            included: true,
            limit: '1 /month',
          },
        ],
        services: [
          {
            label: 'Team Access',
            info: 'You can create team accounts to share a plan with your colleagues. Large enterprises may opt to create separate teams for different parts of the organization. Team accounts can only purchase plans with Team Access.',
            included: false,
          },
          {
            label: 'TAXII (Includes Free)',
            info: 'Access PolySwarm threat intelligence feeds via Stix/Taxii',
            included: false,
          },
          {
            label: 'Taxi Collection - Trustgroup',
            info: '',
            included: false,
          },
          {
            label: 'Taxi Collection - Ransomware',
            info: '',
            included: false,
          },
          {
            label: 'Integrations Support',
            info: 'Individual Plans only support the Autopsy Plugin Integration. Enterprise Plans support all TIPs, Autopsy Plugin, and Automation tools.',
            included: false,
          },
          { label: 'Private Community', info: '', included: false },
          {
            label: 'Enterprise Support',
            info: 'We have your back. You can contact us via Discord, Slack, and Email to get assistance.',
            included: false,
          },
          {
            label: 'Max Artifact Size for Submissions',
            info: '',
            included: true,
            limit: '33,554,432 bytes',
          },
        ],
      },
    },
    {
      name: 'ENTERPRISE',
      features: [
        { label: '12,500/day PolySwarm Scan & Search' },
        { label: '1,250/month PolySwarm Intelligence' },
        { label: 'Unlimited YARA Hunting Rules' },
        { label: '30 Sandbox Detonations/Month' },
        { label: 'Team Access', included: true },
        { label: 'Integrations Support', included: true },
        { label: 'Enterprise Support', included: true },
      ],
      comparisonSections: {
        polySwarmApi: [
          { label: 'Daily Request Limit', included: true, limit: '12,500 /day' },
          { label: 'Hash Search', included: true },
          { label: 'Perform Scan', included: true },
          { label: 'Search Sandbox Data/Results', included: true },
          { label: 'All Scan (History)', included: true },
        ],
        polySwarmIntelligence: [
          { label: 'Advanced Searches & Downloads', included: true, limit: '1,250 /month' },
          { label: 'Metadata Search', included: true },
          { label: 'List/Tag/Link View', included: true },
          { label: 'IOC Search', included: true },
          { label: 'Downloads Artifacts +Sandbox', included: true },
        ],
        sandboxing: [{ label: 'On-Demand Sandboxing', included: true, limit: '30 /month' }],
        hunting: [
          { label: 'Use YARA Hunting Rules', included: true, limit: 'UNLIMITED' },
          { label: 'Live Hunts', included: true, limit: '25 /month' },
          { label: 'Historical Hunts', included: true, limit: '5 /month' },
        ],
        services: [
          { label: 'Team Access', included: true },
          { label: 'TAXII (Includes Free)', included: true },
          {
            label: 'Taxi Collection - Trustgroup',
            included: false,
          },
          {
            label: 'Taxi Collection - Ransomware',
            included: false,
          },
          { label: 'Integrations Support', included: true },
          { label: 'Private Community', info: '', included: false },
          { label: 'Enterprise Support', included: true },
          { label: 'Max Artifact Size for Submissions', included: true, limit: '33,554,432 bytes' },
        ],
      },
    },
    {
      name: 'ENTERPRISE PREMIUM',
      features: [
        { label: '37,500/day PolySwarm Scan & Search' },
        { label: '6,250/month PolySwarm Intelligence' },
        { label: 'Unlimited YARA Hunting Rules' },
        { label: '60 Sandbox Detonations/Month' },
        { label: 'Team Access', included: true },
        { label: 'Integrations Support', included: true },
        { label: 'Enterprise Support', included: true },
      ],
      comparisonSections: {
        polySwarmApi: [
          { label: 'Daily Request Limit', included: true, limit: '37,500 /day' },
          { label: 'Hash Search', included: true },
          { label: 'Perform Scan', included: true },
          { label: 'Search Sandbox Data/Results', included: true },
          { label: 'All Scan (History)', included: true },
        ],
        polySwarmIntelligence: [
          { label: 'Advanced Searches & Downloads', included: true, limit: '6,250 /month' },
          { label: 'Metadata Search', included: true },
          { label: 'List/Tag/Link View', included: true },
          { label: 'IOC Search', included: true },
          { label: 'Downloads Artifacts +Sandbox', included: true },
        ],
        sandboxing: [{ label: 'On-Demand Sandboxing', included: true, limit: '60 /month' }],
        hunting: [
          { label: 'Use YARA Hunting Rules', included: true, limit: 'UNLIMITED' },
          { label: 'Live Hunts', included: true, limit: '100 /month' },
          { label: 'Historical Hunts', included: true, limit: '25 /month' },
        ],
        services: [
          { label: 'Team Access', included: true },
          { label: 'TAXII (Includes Free)', included: true },
          { label: 'Taxi Collection - Trustgroup', included: false },
          { label: 'Taxi Collection - Ransomware', included: false },
          { label: 'Integrations Support', included: true },
          { label: 'Private Community', info: '', included: false },
          { label: 'Enterprise Support', included: true },
          { label: 'Max Artifact Size for Submissions', included: true, limit: '33,554,432 bytes' },
        ],
      },
    },
    {
      name: 'CUSTOM',
      features: [
        {
          label:
            'If none of our three standard plans are suitable for your requirements, we are happy to provide you with a custom plan, tailored to your exact requirements and use cases. Contact us via the link below to discuss your needs.',
        },
      ],
      comparisonSections: {
        polySwarmApi: [
          { label: 'Daily Request Limit', included: true, limit: 'CUSTOM' },
          { label: 'Hash Search', included: true },
          { label: 'Perform Scan', included: true },
          { label: 'Search Sandbox Data/Results', included: true },
          { label: 'All Scan (History)', included: true },
        ],
        polySwarmIntelligence: [
          { label: 'Advanced Searches & Downloads', included: true, limit: 'CUSTOM' },
          { label: 'Metadata Search', included: true },
          { label: 'List/Tag/Link View', included: true },
          { label: 'IOC Search', included: true },
          { label: 'Downloads Artifacts +Sandbox', included: true },
        ],
        sandboxing: [{ label: 'On-Demand Sandboxing', included: 'CUSTOM' }],
        hunting: [
          { label: 'Use YARA Hunting Rules', included: true, limit: 'CUSTOM' },
          { label: 'Live Hunts', included: true, limit: 'CUSTOM' },
          { label: 'Historical Hunts', included: true, limit: 'CUSTOM' },
        ],
        services: [
          { label: 'Team Access', included: true },
          { label: 'TAXII (Includes Free)', included: true },
          { label: 'Taxi Collection - Trustgroup', included: true },
          { label: 'Taxi Collection - Ransomware', included: true },
          { label: 'Integrations Support', included: true },
          { label: 'Private Community', info: '', included: true },
          { label: 'Enterprise Support', included: true },
          {
            label: 'Max Taxi Collection - Ransomwaresions',
            included: true,
            limit: '33,554,432 bytes',
          },
        ],
      },
    },
  ],
};
