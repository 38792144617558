import { SortedHuntingResults } from 'models/Ruleset';
import { HuntResultsRes } from 'services/api/schema/hunting';

const sortHuntsByRuleName = (hunts: HuntResultsRes) =>
  hunts.result.reduce((acc, { rule_name, artifact }) => {
    acc[rule_name] ? acc[rule_name].push(artifact) : (acc[rule_name] = [artifact]);
    return acc;
  }, {} as SortedHuntingResults);

export default sortHuntsByRuleName;
