import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import { closeModal } from 'state/modal/actions';
import styles from 'views/styles';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import successImg from 'assets/images/success.svg';

export interface ConfigureWithdrawalSuccessModalParams {
  address: string;
  limit: string;
  type: 'new' | 'updated';
}

const messages = defineMessages({
  button: {
    id: 'rewardsTab.configureWithdrawalSuccess.modal.button',
    defaultMessage: 'Back to dashboard',
  },
  limit: {
    id: 'rewardsTab.configureWithdrawalSuccess.modal.limit',
    defaultMessage: 'With a daily withdrawal limit of',
  },
  titleNew: {
    id: 'rewardsTab.configureWithdrawalSuccess.modal.titleNew',
    defaultMessage: 'New Withdrawal Address',
  },
  titleUpdated: {
    id: 'rewardsTab.configureWithdrawalSuccess.modal.titleUpdated',
    defaultMessage: 'Withdrawal configuration updated',
  },
});

const ConfigureWithdrawalSuccess = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { address, limit, type } = useSelector(
    ({ modal }: { modal: ModalState<ConfigureWithdrawalSuccessModalParams> }) => modal.params
  );

  return (
    <ConfirmModal
      testId='configureWithdrawalSuccessModal'
      isLoading={false}
      css={ownStyle}
      fullWidth={true}
      content={
        <div css={containerStyle}>
          <img src={successImg} alt='success' />
          <span className='title'>
            {type === 'new'
              ? intl.formatMessage(messages.titleNew)
              : intl.formatMessage(messages.titleUpdated)}
          </span>
          <div className='address'>
            <span>{address}</span>
          </div>
          <span>
            {intl.formatMessage(messages.limit)} {limit} NCT
          </span>
        </div>
      }
      submitBtnText={intl.formatMessage(messages.button)}
      onSubmit={() => dispatch(closeModal())}
    />
  );
};

const ownStyle = css`
  padding: ${styles.spacing.xs};
  width: 600px;
`;

const containerStyle = css`
  & .title {
    font-size: 2.3rem;
    display: block;
  }

  & .title,
  .address {
    font-weight: 700;
  }

  & .address {
    display: flex;
    justify-content: center;
  }

  & .address,
  .title,
  img {
    margin-bottom: 2rem;
  }
`;

export default ConfigureWithdrawalSuccess;
