import { defineMessages } from 'react-intl';

export const btnMessages = defineMessages({
  login: {
    id: 'button.login',
    defaultMessage: 'Log in / Sign up',
  },
  goBack: {
    id: 'button.goBack',
    defaultMessage: 'Go Back',
  },
  backToDash: {
    id: 'button.backToDash',
    defaultMessage: 'Back to dashboard',
  },
  backToAll: {
    id: 'button.backToAll',
    defaultMessage: 'Back to all',
  },
  delete: {
    id: 'button.delete',
    defaultMessage: 'Delete',
  },
  makeDefault: {
    id: 'button.makeDefault',
    defaultMessage: 'Set as Default',
  },
  confirm: {
    id: 'button.confirm',
    defaultMessage: 'Confirm',
  },
  resend: {
    id: 'button.resend',
    defaultMessage: 'Re-send',
  },
  enable: {
    id: 'button.enable',
    defaultMessage: 'Enable',
  },
  disable: {
    id: 'button.disable',
    defaultMessage: 'Disable',
  },
  test: {
    id: 'button.test',
    defaultMessage: 'Enable Development Mode',
  },
  verify: {
    id: 'button.verify',
    defaultMessage: 'Request Verification',
  },
  update: {
    id: 'button.update',
    defaultMessage: 'Update',
  },
  upgrade: {
    id: 'button.upgrade',
    defaultMessage: 'Upgrade',
  },
  retry: {
    id: 'button.retry',
    defaultMessage: 'Try again',
  },
  accept: {
    id: 'button.accept',
    defaultMessage: 'Accept',
  },
  decline: {
    id: 'button.decline',
    defaultMessage: 'Decline',
  },
  create: {
    id: 'button.create',
    defaultMessage: 'Create',
  },
  invite: {
    id: 'button.invite',
    defaultMessage: 'Invite',
  },
  edit: {
    id: 'button.edit',
    defaultMessage: 'Edit',
  },
  cancel: {
    id: 'button.cancel',
    defaultMessage: 'Cancel',
  },
  reset: {
    id: 'button.reset',
    defaultMessage: 'Reset',
  },
  stop: {
    id: 'button.stop',
    defaultMessage: 'Stop',
  },
  claim: {
    id: 'button.claim',
    defaultMessage: 'Save',
  },
  sign: {
    id: 'button.sign',
    defaultMessage: 'Sign',
  },
  save: {
    id: 'button.save',
    defaultMessage: 'Save',
  },
  signup: {
    id: 'button.signup',
    defaultMessage: 'Sign up',
  },
  rescan: {
    id: 'button.rescan',
    defaultMessage: 'Rescan',
  },
  download: {
    id: 'button.download',
    defaultMessage: 'Download',
  },
  share: {
    id: 'button.share',
    defaultMessage: 'Share',
  },
  viewEdit: {
    id: 'button.viewEdit',
    defaultMessage: 'View/Edit',
  },
  readDocs: {
    id: 'button.readDocs',
    defaultMessage: 'Read the docs',
  },
  readMore: {
    id: 'button.readMore',
    defaultMessage: 'Read More',
  },
  activate: {
    id: 'button.activate',
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: 'button.deactivate',
    defaultMessage: 'Deactivate',
  },
  liveHunt: {
    id: 'button.liveHunt',
    defaultMessage: 'Run Live Hunt',
  },
  histHunt: {
    id: 'button.histHunt',
    defaultMessage: 'Run Historical Hunt',
  },
  duplicate: {
    id: 'button.duplicate',
    defaultMessage: 'Duplicate',
  },
  run: {
    id: 'button.run',
    defaultMessage: 'Run',
  },
  close: {
    id: 'button.close',
    defaultMessage: 'Close',
  },
  stopHunt: {
    id: 'button.stopHunt',
    defaultMessage: 'Stop Hunt',
  },
  more: {
    id: 'button.more',
    defaultMessage: 'More',
  },
  search: {
    id: 'button.search',
    defaultMessage: 'Search',
  },
  toggleDropdown: {
    id: 'button.toggleDropdown',
    defaultMessage: 'Toggle Dropdown',
  },
  scanNow: {
    id: 'button.scanNow',
    defaultMessage: 'Scan Now',
  },
  viewRuleset: {
    id: 'button.viewRuleset',
    defaultMessage: 'View Ruleset',
  },
  submit: {
    id: 'button.submit',
    defaultMessage: 'Submit',
  },
  viewUsage: {
    id: 'button.viewUsage',
    defaultMessage: 'View Usage',
  },
  setGroundTruth: {
    id: 'button.setExpectedVerdict',
    defaultMessage: 'Set Expected Verdict',
  },
  viewScanMetadata: {
    id: 'button.viewScanMetadata',
    defaultMessage: 'View Scan Metadata',
  },
  info: {
    id: 'button.info',
    defaultMessage: 'Info',
  },
});
