import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-benign' viewBox='0 0 32 32'>
      <path d='M19.271 0.819l8 4.757c1.94 1.154 3.129 3.244 3.129 5.501v9.846c0 2.257-1.189 4.347-3.129 5.501l-8 4.757c-2.016 1.199-4.526 1.199-6.542 0l-8-4.757c-1.94-1.154-3.129-3.244-3.129-5.501v-9.846c0-2.257 1.189-4.347 3.129-5.501l8-4.757c2.016-1.199 4.526-1.199 6.542 0zM13.795 20.672c0.621 0.605 1.612 0.605 2.233 0l7.249-7.062c0.009-0.009 0.018-0.017 0.026-0.026 0.547-0.561 0.535-1.46-0.026-2.007-0.581-0.566-1.506-0.566-2.087 0l-5.72 5.573c-0.311 0.303-0.806 0.303-1.117 0l-2.903-2.828c-0.581-0.566-1.506-0.566-2.087 0-0.009 0.009-0.018 0.017-0.026 0.026-0.547 0.561-0.535 1.46 0.026 2.007l4.431 4.317z' />
    </symbol>

    <Global
      styles={css`
        .icon-benign {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
