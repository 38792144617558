import { createInitialPagination, buildPaginatedData } from 'utils/pagination';
import { WebhookData } from 'models/Webhook';
import { WebhooksAction, WebhooksActionName } from './types';
import { WebhooksState } from '.';

export const initialWebhookPagination = createInitialPagination<WebhookData>({
  direction: 'desc',
});

export const initialState: WebhooksState = initialWebhookPagination;

const webhook = (state: WebhooksState = initialState, action: WebhooksAction): WebhooksState => {
  switch (action.type) {
    case WebhooksActionName.REFRESH_WEBHOOKS: {
      return action.result ? buildPaginatedData(action.result, action.result.results) : state;
    }

    case WebhooksActionName.GET_WEBHOOKS: {
      if (!action.result) {
        return state;
      } else {
        const result = buildPaginatedData(action.result, action.result.results);
        const stateResults = state.results || [];

        return {
          ...result,
          results: [...stateResults, ...result.results],
        };
      }
    }

    default: {
      return state;
    }
  }
};

export default webhook;
