import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { DivHTMLProps } from 'views/components/Button';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

interface Itag extends DivHTMLProps {
  inline?: boolean;
}

const Tag = (props: Itag) => {
  const { classes } = useStyles();
  return <div css={[classes.root, props.inline ? classes.inline : null]} {...props} />;
};

const useStyles = makeStyles({
  base: {
    root: {
      textTransform: 'uppercase',
      borderRadius: styles.border.radiusXl,
      padding: '1rem 1.2rem',
      fontSize: '1.2rem',
      lineHeight: '1.2rem',
      fontWeight: styles.font.weight.bold,
      letterSpacing: 1,
    },
    inline: {
      display: 'inline-block',
      padding: '0.5rem 0.7rem',
      marginTop: '0.5rem',
      fontSize: '0.9rem',
    },
  },
  light: {
    root: {
      color: styles.color.black,
      backgroundColor: styles.color.xxLightGrey,
    },
  },
  dark: {
    root: {
      color: styles.color.xxLightGrey,
      backgroundColor: styles.color.medDarkPurple,
    },
  },
});

export default Tag;
