import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styled from '@emotion/styled';

type DivProps = React.HTMLAttributes<HTMLDivElement>;
interface IIconContainer extends DivProps {
  children: React.ReactNode;
}

const Container = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = ({ children, ...props }: IIconContainer) => (
  <Container {...props}>{children}</Container>
);

export default IconContainer;
