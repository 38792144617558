import React, { FC, useState, useEffect, Fragment, useCallback } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { Snackbar as MaterialSnackbar } from '@material-ui/core';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';

interface Props {
  id: string;
  openOnMount?: boolean;
  autoHide?: boolean;
  renderActionBtn?: () => JSX.Element;
  renderBtnContent?: () => JSX.Element;
  onClose?: () => void;
}

/**
 * The Snackbar component
 */
const Snackbar: FC<Props> = ({
  id,
  openOnMount,
  autoHide,
  renderActionBtn,
  renderBtnContent,
  onClose,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { classes } = useStyles();

  const _handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const _handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  useEffect(() => {
    if (openOnMount) {
      _handleOpen();
    }
  }, [openOnMount, _handleOpen]);

  return (
    <Fragment>
      {renderBtnContent && <button onClick={_handleOpen}>{renderBtnContent()}</button>}
      <MaterialSnackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        key={id}
        open={isOpen}
        onClose={_handleClose}
        autoHideDuration={autoHide ? 5000 : undefined}
        ContentProps={{ 'aria-describedby': id }}
        message={
          <span id={id} css={classes.text}>
            {children}
          </span>
        }
        action={
          <Fragment>
            {renderActionBtn && <div css={classes.buttons}>{renderActionBtn()}</div>}
            <div css={classes.buttons}>
              <button css={classes.btn} onClick={_handleClose}>
                <Icon css={classes.close} name='close' />
              </button>
            </div>
          </Fragment>
        }
      />
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    btn: {
      marginLeft: styles.spacing.xxs,
    },
    close: {
      fontSize: '0.4rem',
      margin: '0 0.2rem 0.2rem',
      verticalAlign: 'middle',
    },
    text: {
      fontSize: styles.font.size.p1,
      fontFamily: styles.font.family.main,
      lineHeight: 1.3,
    },
    buttons: {
      'a, button': {
        cursor: 'pointer',
        display: 'block',
        lineHeight: 1.3,
        textDecoration: 'none',
        fontSize: styles.font.size.p1,
        fontFamily: styles.font.family.main,
        fontWeight: styles.font.weight.medium,
        transition: `color ${styles.easing.time} ${styles.easing.main}`,
      },
    },
  },
  light: {
    buttons: {
      'a, button': {
        color: styles.color.white,
        '&:hover': {
          color: styles.color.green,
        },
      },
    },
  },
  dark: {
    buttons: {
      'a, button': {
        color: styles.color.black,
        '&:hover': {
          color: styles.color.lightBlue,
        },
      },
    },
  },
});

export default Snackbar;
