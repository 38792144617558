import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useAnalytics } from 'views/components/providers/AnalyticsProvider';
import FileDetails from './FileDetails';

interface Props {
  percentage?: number;
  filename: string;
  filesize: string;
  onCancel?: () => void;
}

const FileAcceptedState = ({ percentage, filename, filesize, onCancel }: Props) => {
  const { event: gaEvent } = useAnalytics();

  gaEvent({
    category: 'Sandbox',
    action: 'Sandbox Triggered',
  });

  return (
    <div css={style.root}>
      <FileDetails
        filename={filename}
        filesize={filesize}
        onCancel={!!onCancel ? onCancel : () => null}
      />
    </div>
  );
};

const style = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    text-align: center;
  `,
};

export default FileAcceptedState;
