import React from 'react';
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import styled from '@emotion/styled';
import ContactForm, { IContactFormValues } from './ContactForm';
import ContactStep from './FormStep';
import ContactConfirm from './ContactConfirmContainer';
import { CheckboxOption } from 'views/pages/AuthCallbackPage/AccountSignupForm/CheckboxArrayField';

interface IContactSteps {
  onSubmit: (values: IContactFormValues) => void;
  initialValues: IContactFormValues;
  loading: boolean;
  currentStep: number;
  onClose: () => void;
  options?: CheckboxOption[];
  withOptions?: boolean;
  withMessage?: boolean;
}

export const PRICING_SIDEBAR_STORAGE_KEY = 'PRICING_SIDEBAR_STORAGE';

const Wrapper = styled(Card)`
  width: 548px;
  position: relative;
  padding: ${styles.spacing.md};
`;

const ContactSteps = ({
  onSubmit,
  initialValues,
  loading,
  currentStep,
  onClose,
  options,
  withOptions,
  withMessage,
}: IContactSteps) => (
  <Wrapper>
    <ContactStep currentStep={currentStep} step={1}>
      <ContactForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        loading={loading}
        onClose={onClose}
        options={options}
        withOptions={withOptions}
        withMessage={withMessage}
      />
    </ContactStep>
    <ContactStep currentStep={currentStep} step={2}>
      <ContactConfirm />
    </ContactStep>
  </Wrapper>
);

export default ContactSteps;
