import { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Filter } from 'views/components/utils';
import Dropzone from 'react-dropzone';
import { RootState } from 'state/root';
import shortenFilename from 'utils/shortenFilename';
import formatBytes from 'utils/formatBytes';
import getUrlType, { UrlScanType } from 'utils/isUrl';
import { parse as parseTld } from 'tldts';
import { isHash } from 'utils/isHash';
import { RequestError, ErrorKeys, errorMessages, translateError } from 'utils/error';
import { Dispatch } from 'state/types/thunk';
import { useSystem } from 'views/components/providers/SystemProvider';
import {
  submitV2File as submitFile,
  cancelFileUpload,
  submitUrl,
  getFileSizeLimit,
  getSubmission,
} from 'state/submission/actions';
import { errorSelector, loadingSelector } from 'state/requests/selectors';
import { SubmissionActionName } from 'state/submission/types';
import { Artifact } from 'models/Submission';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import useOnSelection from 'views/pages/ScanPage/FileUpload/useOnSelection';
import { parseScanPageURL } from 'views/url';
import Button from '@material-ui/core/Button';
import ScanFileState, { IScanFileState } from './ScanFileState';
import UploadUnavailableState from './UploadUnavailableState';
import FileUploadingState from './FileUploadingState';
import FileAcceptedState from './FileAcceptedState';
import PrivacyTerms from './PrivacyTerms';
import HashSearchingState from './HashSearchingState';
import UrlSearchingState from './UrlSearchingState';
import FileShareDataModal from 'components/FileUpload/FileShareDataModal';
import { checkIsZipFile } from 'utils/files';

interface IFileUpload {
  hasHeading?: boolean;
  isScan?: boolean;
  onlyHash?: boolean;
  className?: string;
  showTerms?: boolean;
  messages?: IScanFileState['messages'];
  isModal?: boolean;
  modalShareData?: boolean;
  onFileInput?: (file: FileState) => void;
  onResolvedHashInput?: (type: 'hash', hash: string) => void;
}

interface StateProps {
  isLoading: boolean;
  reqErr?: RequestError;
  uuid: string | null;
  scanResults: Artifact | null;
  fileSizeLimit: number | null;
}

type ViewState = 'default' | 'uploading' | 'searching-hash' | 'searching-url' | 'file-accepted';
type ZipConfig = {
  isZip: boolean;
  zipPassword?: string;
};

export interface FileState {
  file: File | null;
  filename: string;
  filesize: string;
  zipConfig?: ZipConfig;
  isQRCode?: boolean;
}

const initialFileState: FileState = {
  file: null,
  filename: '',
  filesize: '',
};

const errorKeys: ErrorKeys = {
  empty_file: errorMessages.emptyFile.id,
  file_size_too_large: errorMessages.fileTooLarge.id,
  rate_limit_exceeded: errorMessages.rateLimit.id,
};
const QRCodeAllowedExtentions = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

export const FileUpload = ({
  onlyHash = false,
  className,
  showTerms,
  messages,
  isModal = false,
  modalShareData = false,
  onResolvedHashInput,
  onFileInput,
  isScan = false,
  hasHeading = true,
}: IFileUpload) => {
  const [viewState, setViewState] = useState<ViewState>('default');
  const [urlScanType, setUrlScanType] = useState<UrlScanType>('url');
  const [error, setError] = useState<Error>();
  const [fileState, setFileState] = useState<FileState>(initialFileState);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isSelectingFile, setIsSelectingFile] = useState(false);
  const [query, setQuery] = useState('');
  const [isEnteringQuery, setIsEnteringQuery] = useState(false);
  const [scanSearching, setScanSearching] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [isCancel, setCancel] = useState(false);
  const { isMaintenanceMode } = useSystem();
  const [isZipFile, setIsZipFile] = useState(false);
  const [couldBeQRCode, setCouldBeQRCode] = useState(false);
  const [loadedFile, setLoadedFile] = useState<File>();

  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const { classes } = useStyles();

  const {
    file: pendingSelectedFile,
    onSelection,
    hasShareData,
  } = useOnSelection({
    isModal,
    modalShareData: modalShareData,
  });
  const { file, filename, filesize } = fileState;

  const { isLoading, reqErr, uuid, scanResults, fileSizeLimit } = useSelector<
    RootState,
    StateProps
  >(({ requests, submission }) => ({
    isLoading: loadingSelector(requests, [
      SubmissionActionName.SUBMIT_FILE,
      SubmissionActionName.SUBMIT_URL,
      SubmissionActionName.GET_SUBMISSION_UUID,
    ]),
    reqErr: errorSelector(requests, [
      SubmissionActionName.SUBMIT_FILE,
      SubmissionActionName.SUBMIT_URL,
      SubmissionActionName.GET_SUBMISSION,
      SubmissionActionName.GET_SUBMISSION_UUID,
    ]),
    uuid: submission.uuid,
    scanResults: submission.scanResults && submission.scanResults.result,
    fileSizeLimit: submission.fileSizeLimit,
  }));

  const isFailure = !!scanResults && scanResults.bounty_state === 5;
  const scanBounty = !!scanResults ? scanResults.bounty_state : 0;

  const _resetState = () => {
    setViewState('default');
    setError(undefined);
    setFileState(initialFileState);
    setFileUploaded(false);
    setIsSelectingFile(false);
    setQuery('');
    setIsEnteringQuery(false);
    setScanSearching(false);
    setPercentage(0);
  };

  const _handleFileSelection = async (files: File[], zipConfig?: ZipConfig, isQRCode?: boolean) => {
    if (!files.length) return;

    const file = files[0];
    const filename = shortenFilename(file.name);
    const filesize = formatBytes(file.size);
    _resetState();
    setFileState({
      file,
      filename,
      filesize,
    });

    if (file.size <= 0) {
      setError(new Error('empty_file'));
    } else if (fileSizeLimit && file.size > fileSizeLimit) {
      setError(
        new Error(
          `File size exceeds upload limit on your plan (${formatBytes(
            fileSizeLimit
          )}). Upgrade your plan to upload larger files`
        )
      );
    } else {
      if (!!onFileInput) {
        setViewState('file-accepted');
        onFileInput({ file, filename, filesize, zipConfig, isQRCode });
      } else {
        setViewState('uploading');
        await _handleFileUpload(file, zipConfig, isQRCode);
        setCancel(false);
      }
    }
  };

  const _handleFileUpload = async (file: File, zipConfig?: ZipConfig, isQRCode?: boolean) => {
    const submitRes = await dispatch(submitFile(file, zipConfig, isQRCode, setPercentage));
    await dispatch(getSubmission(submitRes.result.id));
    setFileUploaded(true);
  };

  const _submissionPollingTimeout = useRef<number>();

  /**
   * Stop polling submission by id
   */
  const _stopPollingSubmission = useCallback((isCancel?: boolean, isFail?: boolean) => {
    window.clearInterval(_submissionPollingTimeout.current);
    setFileUploaded(!isFail);

    if (isCancel) {
      _submissionPollingTimeout.current = undefined;
    }
  }, []);

  /**
   * Start polling submission by id until window will be closed
   */
  const _startPollingSubmission = useCallback(() => {
    if (!!scanResults && viewState !== 'default') {
      _submissionPollingTimeout.current = window.setInterval(() => {
        dispatch(getSubmission(scanResults.id));
      }, 3000);
    }
  }, [viewState, scanResults, dispatch]);

  useEffect(() => {
    return () => {
      // stop intervals when transitions to other pages
      _stopPollingSubmission();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isFailure) {
      return _stopPollingSubmission(false, true);
    }

    if (!_submissionPollingTimeout.current && !isCancel && fileUploaded) {
      return _startPollingSubmission();
    }

    if (!!_submissionPollingTimeout.current && scanBounty === 2) {
      return _stopPollingSubmission();
    }
  }, [
    fileUploaded,
    isCancel,
    isFailure,
    scanBounty,
    _startPollingSubmission,
    _stopPollingSubmission,
  ]);

  const _handleCancel = () => {
    _resetState();
    dispatch(cancelFileUpload());
    _stopPollingSubmission(true);
    setCancel(true);
  };

  const _handleRetry = async () => {
    if (file) {
      await _handleFileUpload(file);
    } else {
      _resetState();
      setIsSelectingFile(true);
    }
    _submissionPollingTimeout.current = undefined;
  };

  const _handleScanSearch = (query: string) => {
    const trimmedQuery = query.trim();
    const urlType = getUrlType(trimmedQuery);
    const urlData = parseTld(trimmedQuery);
    setError(undefined);
    if (urlType && (urlData.isIcann || urlData.isIp)) {
      _handleSearchUrl(trimmedQuery, urlType);
    } else if (isHash(trimmedQuery)) {
      if (isModal) {
        !!onResolvedHashInput && onResolvedHashInput('hash', trimmedQuery);
      } else {
        // We already know the hash, so just redirect user to scan results page
        history.push(parseScanPageURL(trimmedQuery, { type: 'file' }));
      }
    } else {
      setError(new Error('Invalid URL. Make sure the protocol "https://" or "http://" is set.'));
    }
  };

  const _handleSearchUrl = (url: string, urlScanType: UrlScanType) => {
    setViewState('searching-url');
    setUrlScanType(urlScanType);
    setFileUploaded(false);
    setScanSearching(true);
    setQuery(url);

    dispatch(submitUrl(url))
      .then(() => {
        setFileUploaded(true);
        setScanSearching(false);
      })
      .catch(() => setScanSearching(false));
  };

  const _handleRetrySearch = () => {
    _resetState();
    setIsEnteringQuery(true);
  };

  useEffect(() => {
    dispatch(getFileSizeLimit());
  }, [dispatch]);

  return (
    <>
      <Dropzone
        onDrop={async (files) => {
          const isZip = await checkIsZipFile(files[0]);

          if (isZip) {
            setIsZipFile(true);
            setLoadedFile(files[0]);
          } else if (QRCodeAllowedExtentions.includes(files[0].type)) {
            setCouldBeQRCode(true);
            setLoadedFile(files[0]);
          } else {
            onSelection(_handleFileSelection)(files);
          }
        }}
      >
        {({ getRootProps, isDragActive }) => (
          <div
            data-testid='fileSelect'
            className={className}
            css={classes.root}
            {...getRootProps({
              onClick: (e) => e.stopPropagation(),
              tabIndex: -1,
            })}
          >
            {isMaintenanceMode ? (
              <UploadUnavailableState />
            ) : (
              <Filter
                currentState={viewState}
                states={{
                  default: (
                    <ScanFileState
                      hasHeading={hasHeading}
                      onlyHash={onlyHash}
                      isScan={isScan}
                      messages={messages}
                      isDragActive={isDragActive}
                      isInputInitiallyVisible={isEnteringQuery}
                      selectFileWhenMounted={isSelectingFile}
                      onFileSelect={async (files, checkZip = true, zipConfig, isQRCode) => {
                        if (QRCodeAllowedExtentions.includes(files[0].type) && !loadedFile) {
                          setCouldBeQRCode(true);
                          setLoadedFile(files[0]);
                        } else if (checkZip) {
                          const isZip = await checkIsZipFile(files[0]);
                          if (isZip) {
                            setIsZipFile(true);
                            setLoadedFile(files[0]);
                          } else {
                            onSelection(_handleFileSelection)(files);
                          }
                        } else {
                          if (couldBeQRCode || isZipFile) {
                            onSelection(() => {
                              _handleFileSelection(files, zipConfig, isQRCode);
                            })(files);
                          } else {
                            _handleFileSelection(files, zipConfig, isQRCode);
                          }
                        }
                      }}
                      onScanSearch={_handleScanSearch}
                      errorMessage={translateError(intl, errorKeys, error || reqErr)}
                      file={loadedFile}
                      isZipFile={isZipFile}
                      couldBeQRCode={couldBeQRCode}
                    />
                  ),
                  'file-accepted': (
                    <FileAcceptedState
                      filename={filename}
                      filesize={filesize}
                      onCancel={_resetState}
                    />
                  ),
                  uploading: (
                    <FileUploadingState
                      isLoading={isLoading}
                      isModal={isModal}
                      fileUploaded={fileUploaded}
                      errorMessage={
                        error || reqErr
                          ? translateError(intl, errorKeys, error || reqErr)
                          : isFailure
                          ? 'Fail Uploading'
                          : ''
                      }
                      percentage={percentage}
                      filename={filename}
                      filesize={filesize}
                      uuid={uuid}
                      submissionId={get(scanResults, 'id')}
                      renderFailureBtn={() => (
                        <Button
                          style={{ fontSize: '2.4rem' }}
                          className='h-mt-sm'
                          color='primary'
                          variant='contained'
                          onClick={_handleRetry}
                        >
                          {intl.formatMessage({ id: 'button.retry' })}
                        </Button>
                      )}
                      onCancel={_handleCancel}
                    />
                  ),
                  'searching-hash': (
                    <HashSearchingState
                      isLoading={isLoading || scanSearching}
                      fileUploaded={fileUploaded}
                      error={error || reqErr}
                      uuid={uuid}
                      submissionId={get(scanResults, 'id')}
                      hash={query}
                      renderFailureBtn={() => (
                        <SearchingBtnGroup
                          onRetryUpload={_handleRetry}
                          onRetrySearch={_handleRetrySearch}
                        />
                      )}
                      onCancel={_handleCancel}
                    />
                  ),
                  'searching-url': (
                    <UrlSearchingState
                      type={urlScanType}
                      isLoading={isLoading || scanSearching}
                      fileUploaded={fileUploaded}
                      error={error || reqErr}
                      uuid={uuid}
                      submissionId={get(scanResults, 'id')}
                      url={query}
                      renderFailureBtn={() => (
                        <SearchingBtnGroup
                          onRetryUpload={_handleRetry}
                          onRetrySearch={_handleRetrySearch}
                        />
                      )}
                      onCancel={_handleCancel}
                    />
                  ),
                }}
              />
            )}
            {showTerms && <PrivacyTerms css={classes.terms} />}
          </div>
        )}
      </Dropzone>
      <FileShareDataModal
        onCancel={() => {
          const input = document.getElementById('scan-file-state-select') as HTMLInputElement;
          if (input) {
            input.value = '';
          }
        }}
        onSubmit={async () => {
          if (pendingSelectedFile) {
            await _handleFileSelection(pendingSelectedFile);
          }
        }}
        open={!!pendingSelectedFile && hasShareData === null && !hasShareData}
      />
    </>
  );
};

const SearchingBtnGroup = ({
  onRetryUpload,
  onRetrySearch,
}: {
  onRetryUpload: () => void;
  onRetrySearch: () => void;
}) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Button
        style={{ fontSize: '2.4rem' }}
        className='h-mt-md h-mb-xxs'
        color='primary'
        variant='contained'
        onClick={onRetrySearch}
        data-cy='scanSearchRetry'
      >
        {intl.formatMessage({ id: 'button.retry' })}
      </Button>
      <Button style={{ fontSize: '2.2rem' }} color='primary' variant='text' onClick={onRetryUpload}>
        {intl.formatMessage({ id: 'fileupload.uploaded.button' })}
      </Button>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      position: 'relative',
      outline: 'none',
    },
    terms: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      textAlign: 'center',
    },
  },
});
