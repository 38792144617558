import React from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import PanelLoader from 'views/components/Loader/PanelLoader';
import TeamActivityTable from './TeamActivityTable';
import ErrorPageContent from 'views/components/error/ErrorPageContent';
import { camelCaseToWords } from 'utils/text';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { TeamActivityProps } from './hooks/useTeamActivity';

type Filter = {
  key: string;
  value: string;
};
interface ITeamActivityTableContainer {
  paginationProps: { [key: string]: any };
  filters: Filter[];
  prettiedFilters: Filter[];
  toggleFilters: () => void;
  teamActivityProps: TeamActivityProps;
}

const TeamActivityTableContainer = ({
  prettiedFilters,
  filters,
  toggleFilters,
  paginationProps,
  teamActivityProps,
}: ITeamActivityTableContainer) => {
  const { classes } = useStyles();
  const { data, error, loading, more, refetch } = teamActivityProps;

  if (error) {
    return (
      <ErrorPageContent className='h-mt-lg h-mb-lg' heading='Error' text={error}></ErrorPageContent>
    );
  }
  if (loading) return <PanelLoader />;

  return (
    data && (
      <div css={classes.container}>
        <div css={classes.tags}>
          {prettiedFilters
            .filter((item) => Boolean(item.value))
            .map((item) => (
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  toggleFilters();
                }}
                css={classes.tag}
                key={item.key}
              >
                {camelCaseToWords(item.key)}: {item.value}
              </span>
            ))}
        </div>
        <TeamActivityTable
          results={data}
          filters={filters}
          more={more}
          refetch={refetch}
          paginationProps={paginationProps}
        />
      </div>
    )
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      paddingRight: '3rem',
      position: 'relative',
      margin: '1.5rem 0',
    },
    tags: {
      display: 'flex',
      gap: '1rem',
      marginBottom: '2rem',
      padding: '0 3rem',
    },
    tag: {
      background: '#D9D9D9',
      color: styles.color.black,
      padding: '0.4rem 1.8rem',
      borderRadius: '2rem',
      fontSize: '1.4rem',
      fontWeight: 600,
      cursor: 'pointer',
    },
  },
  light: {},
  dark: {},
});

export default TeamActivityTableContainer;
