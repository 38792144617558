import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { Resizable, ResizeCallback } from 're-resizable';
import AceEditor, { IAnnotation } from 'react-ace';
import { defineEditorTheme } from './editorTheme';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';

export type RulesetError = {
  line: string;
  msg: string;
};

export type RulesetValidationResponse = {
  errors?: RulesetError[];
  result: string | null;
  status: string;
};

interface Props {
  value: string;
  placeholder?: string;
  isFocused?: boolean;
  validationResult?: RulesetValidationResponse;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
}

const HEIGHT = window.innerHeight / 2.4 || 350;

// Globally define AceEditor theme
// Required by AceEditor component
defineEditorTheme();

const CodeEditor = ({
  value,
  placeholder,
  isFocused,
  validationResult,
  onFocus,
  onBlur,
  onChange,
}: Props) => {
  const [editorHeight, setEditorHeight] = useState(`${String(HEIGHT)}px`);
  const [editorErrors, setEditorErrors] = useState<IAnnotation[]>([]);

  const { classes } = useStyles(isFocused);

  const _handleResize: ResizeCallback = (_e, _d, el) =>
    setEditorHeight(`${String(el.clientHeight)}px`);

  const _handleFocus = () => onFocus && onFocus(value);

  const _handleBlur = () => onBlur && onBlur(value);

  const _handleChange = (val: string) => {
    onChange && onChange(val);
    if (setEditorErrors.length) {
      setEditorErrors([]);
    }
  };

  useEffect(() => {
    if (validationResult && validationResult.errors) {
      setEditorErrors(
        validationResult.errors.map((error) => ({
          row: Number(error.line) - 1,
          column: 0,
          text: error.msg,
          type: 'error',
        }))
      );
    }
  }, [validationResult]);

  return (
    <Resizable
      css={classes.root}
      enable={{ bottom: true }}
      onResize={_handleResize}
      defaultSize={{
        width: '100%',
        height: HEIGHT + 7,
      }}>
      <AceEditor
        mode='text'
        theme='github'
        css={classes.editor}
        debounceChangePeriod={10}
        placeholder={placeholder}
        annotations={editorErrors}
        value={value}
        width='100%'
        height={editorHeight}
        onFocus={_handleFocus}
        onBlur={_handleBlur}
        onChange={_handleChange}
      />
    </Resizable>
  );
};

const useStyles = makeStyles((isFocused?: boolean) => ({
  base: {
    root: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderRadius: styles.border.radius,
      overflow: 'hidden',
      paddingTop: '2px',
      transition: `border-color ${styles.easing.time} ${styles.easing.main}`,
    },
    editor: {
      borderRadius: styles.border.radius,
      fontFamily: styles.font.family.mono,
      '.ace_print-margin': {
        display: 'none',
      },
    },
  },
  light: {
    root: {
      backgroundColor: styles.color.xxLightPurple,
      borderColor: isFocused ? styles.color.purple : styles.color.lightPurple,
      ':focus-within': {
        borderColor: styles.color.purple,
      },
    },
    editor: {
      backgroundColor: `${styles.color.xxLightPurple} !important`,
      color: `${styles.color.black} !important`,
      '.ace_cursor': {
        color: `${styles.color.black} !important`,
      },
      '.ace_gutter': {
        backgroundColor: `${styles.color.ulPurple} !important`,
        color: `${styles.color.lightGrey} !important`,
      },
      '.ace_active-line': {
        backgroundColor: `${styles.color.xxxLightPurple} !important`,
      },
      '.ace_gutter-active-line': {
        backgroundColor: `${styles.color.xxxLightPurple} !important`,
      },
    },
  },
  dark: {
    root: {
      backgroundColor: styles.color.darkOffBlack,
      borderColor: isFocused ? styles.color.lightBlue : styles.color.darkGrey,
      ':focus-within': {
        borderColor: styles.color.lightBlue,
      },
    },
    editor: {
      backgroundColor: `${styles.color.darkOffBlack} !important`,
      color: `${styles.color.white} !important`,
      '.ace_cursor': {
        color: `${styles.color.white} !important`,
      },
      '.ace_gutter': {
        backgroundColor: `${styles.color.darkBlack} !important`,
        color: `${styles.color.xLightGrey} !important`,
      },
      '.ace_active-line': {
        backgroundColor: `${styles.color.darkXBlack} !important`,
      },
      '.ace_gutter-active-line': {
        backgroundColor: `${styles.color.darkXBlack} !important`,
      },
    },
  },
}));

export default CodeEditor;
