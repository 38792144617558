import { ChangLanguageAction, SettingsActionName, ChangeThemeAction } from './types';
import { LocaleState } from 'utils/constants';
import { ThemeState } from 'models/Theme';

export const changeLanguage = (locale: LocaleState): ChangLanguageAction => ({
  type: SettingsActionName.CHANGE_LANGUAGE,
  locale,
});

export const changeTheme = (theme: ThemeState): ChangeThemeAction => ({
  type: SettingsActionName.CHANGE_THEME,
  theme,
});
