import React, { FC, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Setting, { ISetting } from './Setting/Setting';

interface ISettings {
  groups: ISettingsGroup[];
}

interface ISettingsGroup {
  heading: string;
  danger?: boolean;
  isShown?: boolean;
  settings: ISetting[];
}

const Settings: FC<ISettings> = ({ groups }) => {
  const { classes } = useStyles();
  return (
    <Fragment>
      {groups.map(({ heading, danger, isShown, settings }) =>
        isShown === false ? null : (
          <div key={heading.toLowerCase().replace(' ', '-')} css={classes.root}>
            <div>
              {heading && (
                <h6 css={classes.heading} style={danger ? { color: styles.color.red } : {}}>
                  {heading}
                </h6>
              )}
            </div>
            {settings.map((setting) => (
              <Setting key={setting.text.toLowerCase().replace(' ', '-')} {...setting} />
            ))}
          </div>
        )
      )}
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      margin: `0 ${styles.spacing.grid}`,
      padding: `${styles.spacing.grid} 0 ${styles.spacing.xs}`,

      '&:last-child': {
        borderBottom: 'none',
      },

      button: {
        fontSize: '1.6rem',
      },
    },
    heading: {
      fontSize: '1.6rem',
      fontWeight: styles.font.weight.medium,
      marginBottom: styles.spacing.xxs,
    },
  },
  light: {
    root: {
      borderBottomColor: styles.border.color.grey,
    },
    heading: {
      color: styles.color.black,
    },
  },
  dark: {
    root: {
      borderBottomColor: styles.border.color.darkPurple,
    },
    heading: {
      color: styles.color.white,
    },
  },
});

export default Settings;
