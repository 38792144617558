import React, { useEffect, Fragment } from 'react';
import AppLoader from 'views/components/request/AppLoader';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { useDevelopmentResults } from '../hooks/useDevelopmentResults';
import DevelopmentResultsTable from './DevelopmentResultsTable';
import Docs from './Docs';
import { useDevelopmentCommunity } from 'views/pages/MicroenginesPage/hooks';

interface Params {
  engine: string | null;
  community: string;
}

const DevelopmentResults = (params: Params) => {
  const { engine, community } = params;
  const { developmentResults, loading, refetch } = useDevelopmentResults(engine);
  const { developmentCommunity, getDevelopmentCommunity } = useDevelopmentCommunity();

  useEffect(() => {
    if (community !== developmentCommunity.name) {
      getDevelopmentCommunity(community);
    }
  }, [community, developmentCommunity.name, getDevelopmentCommunity]);

  return (
    <AppLoader loadingActions={[]}>
      {() => {
        if (loading) {
          return <PanelLoader />;
        } else if (!developmentResults) {
          return null;
        }
        return (
          <Fragment>
            <DevelopmentResultsTable developmentResults={developmentResults} refresh={refetch} />
            {developmentCommunity.apiKey && (
              <Docs apiKey={developmentCommunity.apiKey} communityId={community} />
            )}
          </Fragment>
        );
      }}
    </AppLoader>
  );
};

export default DevelopmentResults;
