import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-token' viewBox='0 0 32 32'>
      <path d='M16 29.333c-7.333 0-13.333-6-13.333-13.333s6-13.333 13.333-13.333c7.333 0 13.333 6 13.333 13.333s-6 13.333-13.333 13.333zM16 5.333c-5.867 0-10.667 4.8-10.667 10.667s4.8 10.667 10.667 10.667 10.667-4.8 10.667-10.667-4.8-10.667-10.667-10.667z' />
      <path d='M20 22.667h-8l-4-6.667 4-6.667h8l4 6.667-4 6.667zM13.467 20h5.067l2.4-4-2.4-4h-4.933l-2.4 4 2.267 4z' />
    </symbol>

    <Global
      styles={css`
        .icon-token {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
