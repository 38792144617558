import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { TeamMemberInvitation } from 'models/Team';
import { PageQuery } from 'models/Page';
import { extractPageQueryParams } from 'utils/pagination';
import { Dispatch } from 'state/types/thunk';
import { RootState } from 'state/root';
import { openModal } from 'state/modal/actions';
import { InviteTeamMemberModalParams } from 'views/pages/TeamDetailPage/modals/InviteTeamMemberModal';
import { TeamActionName } from 'state/team/types';
import {
  getTeamInvitations,
  archiveTeamInvitation,
  resendTeamInvitation,
} from 'state/team/actions';
import { ReactComponent as EmailIcon } from 'assets/images/email-icon.svg';
import AppLoader from 'views/components/request/AppLoader';
import PanelContent from 'views/components/layout/PanelContent';
import TeamInvitationsTable from './TeamInvitationsTable';
import { IColumnSort, ESortDirection } from 'views/components/table/InfiniteScrollTable';

const messages = defineMessages({
  heading: {
    id: 'teams.invitations.placeholder.heading',
    defaultMessage: 'Invite a new team member',
  },
  text: {
    id: 'teams.invitations.placeholder.text',
    defaultMessage:
      'There are no pending invitations at this time. Click the button below to invite a new member to your team.',
  },
  button: {
    id: 'teams.invitations.placeholder.button',
    defaultMessage: 'Invite team member',
  },
});

const TeamInvitationsTab = () => {
  const [sort, setSort] = useState<IColumnSort<keyof TeamMemberInvitation>>({
    orderBy: 'name',
    direction: ESortDirection.ASC,
  });

  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();

  const { invitations } = useSelector((state: RootState) => state.team);

  const _getTeamInvitations = (refresh: boolean, query?: PageQuery<TeamMemberInvitation>) => {
    const params = Object.assign({}, extractPageQueryParams(invitations), query);
    dispatch(getTeamInvitations(params, refresh));
  };

  const _onLoadMore = () => {
    _getTeamInvitations(false, { offset: invitations.offset });
    return Promise.resolve();
  };

  const _inviteTeamMember = () =>
    dispatch(
      openModal<InviteTeamMemberModalParams>('INVITE_TEAM_MEMBER', {
        queryParams: extractPageQueryParams(invitations),
        refresh: true,
      })
    );

  const _archiveTeamInvitation = async (invitationId: number) => {
    await dispatch(archiveTeamInvitation(invitationId));
    _getTeamInvitations(true);
  };

  const _resendTeamInvitation = async (invitationId: number) => {
    await dispatch(resendTeamInvitation(invitationId));
    _getTeamInvitations(true);
  };

  const _handleSort = ({ orderBy, direction }: any) => {
    setSort({ orderBy, direction });
    _getTeamInvitations(true, {
      orderBy,
      direction,
    });
  };

  useEffect(() => {
    _getTeamInvitations(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppLoader
      loadingActions={[TeamActionName.REFRESH_TEAM_INVITATIONS]}
      onReload={() => _getTeamInvitations(true)}
    >
      {() => {
        if (!invitations.results) {
          return null;
        }
        return Array.isArray(invitations.results) && invitations.results.length === 0 ? (
          <PanelContent
            imageComponent={<EmailIcon />}
            heading={intl.formatMessage(messages.heading)}
            text={intl.formatMessage(messages.text)}
            buttons={[
              {
                text: intl.formatMessage(messages.button),
                onClick: _inviteTeamMember,
              },
            ]}
          />
        ) : (
          <TeamInvitationsTable
            invitations={invitations}
            sort={sort}
            onSort={_handleSort}
            onInviteTeamMember={_inviteTeamMember}
            onArchiveTeamInvitation={_archiveTeamInvitation}
            onResendTeamInvitation={_resendTeamInvitation}
            onLoadMore={_onLoadMore}
          />
        );
      }}
    </AppLoader>
  );
};

export default TeamInvitationsTab;
