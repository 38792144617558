import React, { useState, useRef } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useIntl, defineMessages } from 'react-intl';
import { RequestError, translateError, ErrorKeys } from 'utils/error';
import { onEnterKeyDown } from 'views/components/utils';
import { btnMessages } from 'views/components/Button';
import styles from 'views/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Tooltip } from '@material-ui/core';

interface IScanSearchInput {
  disabled?: boolean;
  initialValue?: string;
  error?: RequestError;
  onSubmit?: (value: string) => void;
  onCancel?: () => void;
  onChange?: () => void;
  onFileSelect?: (file: File) => void;
}

const messages = defineMessages({
  input: {
    id: 'scanPage.fileupload.search.input',
    defaultMessage: 'Type URL and press Enter',
  },
  label: {
    id: 'scanPage.fileupload.search.label',
    defaultMessage: 'Scan a URL or QR code',
  },
});

const errorKeys: ErrorKeys = {
  invalid_url: 'Invalid URL. Make sure the protocol "https://" or "http://" is set.',
};

const ScanSearchInput = ({
  disabled,
  initialValue,
  error,
  onSubmit,
  onCancel,
  onChange,
  onFileSelect,
}: IScanSearchInput) => {
  const [value, setValue] = useState(initialValue || '');

  const intl = useIntl();

  const { classes } = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange();
    setValue(e.currentTarget.value);
  };

  const _handleSearch = () => {
    if (!value) {
      inputRef.current && inputRef.current.focus();
    } else if (onSubmit) {
      onSubmit(value);
    }
  };

  return (
    <div css={classes.root}>
      {disabled ? (
        <textarea
          css={classes.textarea}
          value={value}
          placeholder={intl.formatMessage(messages.input)}
          disabled
          data-cy='scanSearchInput'
        />
      ) : (
        <TextField
          inputRef={inputRef}
          fullWidth={true}
          label={intl.formatMessage(messages.label)}
          placeholder={intl.formatMessage(messages.input)}
          value={value}
          error={!!error}
          helperText={translateError(intl, errorKeys, error)}
          onKeyDown={onEnterKeyDown(_handleSearch)}
          onChange={_handleChange}
          data-cy='scanSearchInput'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <Tooltip
                    placement='top'
                    title='Scan QR code image file. Only QR Codes with URLs are supported.'
                  >
                    <button
                      css={classes.searchBtn}
                      onClick={() => {
                        fileInputRef.current && fileInputRef.current.click();
                      }}
                      data-cy='scanSearchSubmit'
                    >
                      <Icon name='qr-code' title='QR Code' />
                    </button>
                  </Tooltip>
                  <button
                    css={classes.searchBtn}
                    onClick={_handleSearch}
                    data-cy='scanSearchSubmit'
                  >
                    <Icon name='search' title={intl.formatMessage(btnMessages.search)} />
                  </button>
                </div>
              </InputAdornment>
            ),
          }}
        />
      )}
      {/* hidden file input */}
      <input
        ref={fileInputRef}
        type='file'
        accept='.jpg,.jpeg,.png,.gif'
        style={{ display: 'none' }}
        onChange={(e) => onFileSelect && e.target.files?.[0] && onFileSelect(e.target.files?.[0])}
      />
      {disabled && onCancel && (
        <div css={classes.cancelWrap}>
          <button css={classes.cancelBtn} onClick={onCancel} data-cy='cancelScanSearch'>
            <Icon name='close' title='cancel' />
          </button>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      width: '100%',
      maxWidth: '88rem',
      marginLeft: '1.75rem',
    },
    searchBtn: {
      cursor: 'pointer',
      fontSize: '0.8rem',
    },
    cancelWrap: {
      marginLeft: '1rem',
    },
    cancelBtn: {
      display: 'block',
      cursor: 'pointer',
      color: styles.color.xLightGrey,
      fontSize: '0.512rem',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    textarea: {
      borderRadius: styles.border.radiusMd,
      outline: 0,
      padding: '1.4rem 1.7rem',
      width: '100%',
      resize: 'none',
      color: styles.color.lightGrey,
      fontFamily: styles.font.family.mono,
      lineHeight: 1.5,
      fontSize: styles.font.size.p1,
      '&::placeholder': {
        color: styles.color.lightGrey,
      },
      '&[disabled]': {
        backgroundColor: styles.color.xxLightGrey,
        borderColor: styles.color.xxLightGrey,
      },
    },
  },
  light: {
    searchBtn: {
      color: styles.color.purple,
    },
  },
  dark: {
    searchBtn: {
      color: styles.color.lightBlue,
    },
  },
});

export default ScanSearchInput;
