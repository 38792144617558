import color from './color';

const border = {
  // radius
  radius: '6px',
  radiusMd: '12px',
  radiusLg: '40px',
  radiusXl: '100px',

  // color
  color: {
    grey: color.xxxLightGrey,
    purple: color.xLightPurple,
    medDarkPurple: color.medDarkPurple,
    darkPurple: color.xDarkPurple,
  },
};

export default border;
