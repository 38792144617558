import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';

interface Props {
  filename: string;
  filesize: string;
  onCancel: () => void;
}

const FileDetails = ({ filename, filesize, onCancel }: Props) => {
  const { classes } = useStyles();
  return (
    <div css={classes.root} data-cy='fileDetails'>
      <div css={classes.filename}>
        <span css={classes.filenameText} data-cy='fileName'>
          {filename}
        </span>
        <button css={classes.cancelBtn} onClick={onCancel} data-cy='cancelUpload'>
          <Icon name='close' />
        </button>
      </div>
      <span css={classes.filesize} data-cy='fileSize'>
        {filesize}
      </span>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      fontSize: '1rem',
      display: 'inline-block',
      textAlign: 'center',
      width: '100%',
    },
    filename: {
      position: 'relative',
      gap: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    filenameText: {
      fontSize: '1.6em',
      fontWeight: styles.font.weight.bold,
    },
    filesize: {
      fontSize: '1.4em',
    },
    cancelBtn: {
      cursor: 'pointer',
      display: 'block',
      fontSize: '0.375em',
    },
  },
  light: {
    filename: {
      color: styles.color.black,
    },
    filesize: {
      color: styles.color.medGrey,
    },
    cancelBtn: {
      color: styles.color.xLightGrey,
    },
  },
  dark: {
    filesize: {
      color: styles.color.xLightGrey,
    },
    cancelBtn: {
      color: styles.color.xLightGrey,
    },
    filename: {
      color: styles.color.xxLightGrey,
    },
  },
});

export default FileDetails;
