import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { FormattedMessage } from 'react-intl';
import styles from 'views/styles';
import FormInput from 'views/components/form/FormInput';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';

interface IUsernameFieldProps {
  name: string;
  label: string;
  className?: string;
}

const UsernameField = ({ name, label, className }: IUsernameFieldProps) => {
  return (
    <FormInput
      name={name}
      label={label}
      className={className}
      InputProps={{
        endAdornment: (
          <Tooltip
            title={
              <Fragment>
                <h4 css={style.heading}>
                  <FormattedMessage
                    id='signup.username.requirements'
                    defaultMessage='Username requirements'
                  />
                </h4>
                <ul>
                  <li>
                    <p className='p1 h-mb-tiny'>
                      <FormattedMessage
                        id='signup.username.rule1'
                        defaultMessage='At least 3 characters (max 15)'
                      />
                    </p>
                  </li>
                  <li>
                    <p className='p1'>
                      <FormattedMessage
                        id='signup.username.rule2'
                        defaultMessage='Can only contain alphanumeric characters (letters A-Z, numbers 0-9) and underscores'
                      />
                    </p>
                  </li>
                </ul>
              </Fragment>
            }
          >
            <IconButton>
              <Info />
            </IconButton>
          </Tooltip>
        ),
      }}
      autoFocus
    />
  );
};

const style = {
  root: css`
    background-color: ${styles.color.white};
    color: ${styles.color.black};
    padding: ${styles.spacing.xs};
    width: 300px;
  `,
  heading: css`
    font-weight: ${styles.font.weight.bold};
    margin-bottom: ${styles.spacing.tiny};
  `,
};

export default UsernameField;
