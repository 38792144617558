import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { TeamMemberInvitation } from 'models/Team';
import { PageAttributes } from 'models/Page';
import { RootState } from 'state/root';
import { ModalState } from 'state/modal';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { translateError, ErrorKeys, errorMessages } from 'utils/error';
import { TeamActionName } from 'state/team/types';
import { inviteTeamMember } from 'state/team/actions';
import { closeModal, openModal } from 'state/modal/actions';
import styles from 'views/styles';
import InviteTeamMemberForm from 'views/pages/TeamDetailPage/forms/InviteTeamMemberForm';
import Modal from 'views/components/Modal';
import { resetRequest } from 'state/requests/actions';
import { useUser } from 'views/components/providers/UserProvider';
import { SetupMfaPanel } from 'views/components/SetupMfaPanel/SetupMfaPanel';
import { hasMadeTeamMFARecently, loginWriteTeams } from 'state/auth/actions';

export interface InviteTeamMemberModalParams {
  queryParams?: PageAttributes<TeamMemberInvitation>;
  refresh?: boolean;
}

const messages = defineMessages({
  heading: {
    id: 'teams.modal.invite',
    defaultMessage: 'Invite a team member',
  },
});

const errorKeys: ErrorKeys = {
  already_exists: errorMessages.teamNameExists.id,
  invitation_exists: errorMessages.invitationExists.id,
  team_member_exists: errorMessages.teamMemberExists.id,
};

const InviteTeamMemberModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useUser();
  const location = useLocation();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [TeamActionName.INVITE_TEAM_MEMBER]);
  const error = errorSelector(requests, [TeamActionName.INVITE_TEAM_MEMBER]);

  // eslint-disable-next-line prefer-const
  let { queryParams, refresh } = useSelector(
    ({ modal }: { modal: ModalState<InviteTeamMemberModalParams> }) => modal.params
  );

  const _onClose = () => {
    dispatch(closeModal());
    dispatch(resetRequest(TeamActionName.INVITE_TEAM_MEMBER));
  };

  if (!user.mfaEnabled)
    return (
      <Modal>
        <SetupMfaPanel onSetup={_onClose} onCancel={_onClose} />
      </Modal>
    );

  if (!hasMadeTeamMFARecently()) {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({ modal: 'INVITE_TEAM_MEMBER', location: location.pathname })
          );
        },
      })
    );
  }

  return (
    <Modal>
      <InviteTeamMemberForm
        css={ownStyle}
        heading={intl.formatMessage(messages.heading)}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        onSubmit={(memberValues) =>
          dispatch(inviteTeamMember(memberValues, queryParams, refresh))
        }
        onCancel={_onClose}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default InviteTeamMemberModal;
