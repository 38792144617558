import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Big, { BigSource } from 'big.js';

export const Balance = ({
  truncatedValue,
  value,
}: {
  truncatedValue: BigSource;
  value: BigSource;
}) => {
  const { classes } = useStyles();
  const [truncatedInt, decimals = ''] = Big(truncatedValue).toFixed().split('.');
  return (
    <Tooltip title={value} placement='top'>
      <div css={classes.balanceContainer}>
        <span
          css={[classes.balance, truncatedInt.length > 5 ? classes.mediumFont : classes.bigFont]}>
          {truncatedInt}
          {`.${decimals.padEnd(4, '0')}`}{' '}
        </span>
        <span css={classes.nct}>NCT</span>
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  base: {
    balanceContainer: {
      marginTop: '1.5rem',
      zIndex: 999,
    },
    balance: {
      fontWeight: 600,
    },
    bigFont: {
      fontSize: '3rem',
    },
    mediumFont: {
      fontSize: '2.3rem',
    },
    nct: {
      color: styles.color.green,
      fontWeight: 600,
      fontSize: '2rem',
      display: 'block',
    },
  },
});
