import React from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import FilterModal from 'views/pages/HuntPage/modals/FilterModal';
import ActionPopover from 'components/ActionPopover';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import ability from 'config/ability';

interface IFilter {
  isFilterOpen: boolean;
  onFilterOpen: () => void;
  onFilterClose: () => void;
}

export const Filter = ({ isFilterOpen, onFilterOpen, onFilterClose }: IFilter) => {
  const { classes } = useStyles();

  const hasRulesetAbility =
    ability.can('live_hunt', 'Artifact') || ability.can('historical_hunt', 'Artifact');

  const onToggleFilter = () => {
    onFilterOpen && onFilterOpen();
  };

  return hasRulesetAbility ? (
    <div css={classes.root}>
      <ActionPopover
        isOpen={isFilterOpen}
        onClose={onFilterClose}
        onToggle={onToggleFilter}
        name={'Filter'}
      >
        <FilterModal onClose={onFilterClose} />
      </ActionPopover>
    </div>
  ) : (
    <></>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      position: 'absolute',
      right: '4rem',
      top: '2rem',
    },
  },
});
