import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-run' viewBox='0 0 32 32'>
      <path d='M31.106 7.451l-0.074-0.074c-0.696-0.696-1.831-0.698-2.532 0.002l-2.726 2.754c-0.144 0.144-0.373 0.15-0.523 0.012l-3.822-3.528c-0.371-0.343-0.854-0.532-1.36-0.532h-5.685c-0.134 0-0.263 0.051-0.36 0.143l-4.818 4.575c-0.706 0.706-0.754 1.845-0.108 2.538 0.346 0.371 0.812 0.575 1.314 0.575h0.009c0.498-0.003 0.976-0.214 1.306-0.575l3.403-3.601h1.218l-7.941 8.875h-4.898c-1.161 0-2.116 0.868-2.174 1.977-0.031 0.579 0.172 1.129 0.57 1.549 0.393 0.414 0.944 0.651 1.515 0.651h7.309c0.144 0 0.282-0.059 0.381-0.165l3.745-3.997 3.149 3.293-0.951 6.076c-0.24 1.007 0.243 2.032 1.148 2.436 0.279 0.125 0.575 0.188 0.869 0.188 0.317 0 0.632-0.073 0.922-0.216 0.561-0.278 0.964-0.786 1.11-1.417l1.624-8.712c0.031-0.169-0.022-0.344-0.145-0.465l-4.335-4.329 3.444-3.438 2.4 2.4c0.67 0.67 1.839 0.67 2.509 0l4.504-4.503c0.333-0.333 0.516-0.775 0.516-1.245s-0.183-0.912-0.516-1.245z'></path>
      <path d='M25.348 7.652c1.728 0 3.132-1.405 3.132-3.132s-1.405-3.132-3.132-3.132c-1.727 0-3.132 1.405-3.132 3.132s1.405 3.132 3.132 3.132z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-run {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
