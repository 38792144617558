import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-integrations' viewBox='0 0 165 177'>
      <path d='M82.049 107.476V15.368M82.049 64.373l30.775-15.548V25.227M82.049 64.373 51.273 48.825V25.227' />
    </symbol>

    <Global
      styles={css`
        .icon-integrations {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
