import { AxiosError, AxiosResponse } from 'axios';
import { IntlShape, MessageDescriptor } from 'react-intl';
import { Thunk } from 'state/types/thunk';
import { showNotification } from 'state/notification/actions';
import { errorMessages } from '.';

export interface IBackendError<T = any> extends AxiosError {
  response?: AxiosResponse<{
    data?: T;
    message?: string;
    statusCode?: number;
    errorType?: string;
  }>;
}

export type RequestError<T = any> = Error | IBackendError<T>;

export type ErrorKeys = { [errorType: string]: string };

export const isAxiosError = <T = any>(error: RequestError): error is IBackendError<T> =>
  !!(error as IBackendError<T>).isAxiosError;

export const getErrorMessage = (error: RequestError): string => {
  if (isAxiosError(error)) {
    if (error.response?.status === 403) {
      const data = error.response.data;
      if (
        data.errorType === 'forbidden' &&
        data.message &&
        data.message.indexOf('invalid claim or wrong account') >= 0
      ) {
        return 'Invalid credentials ‒ Check your 2FA, and make sure your browser is allowing local storage.';
      }
    }
    if (error.response && error.response.data.message) {
      return error.response.data.message;
    }
  }
  return error.message;
};

export const getErrorMessageAndFieldValidations = (error: RequestError): string => {
  let message = getErrorMessage(error);
  const data = getErrorData(error);
  const errorFields = getErrorFields(data);
  if (errorFields) {
    const errorMessages = getErrorMessages(data);
    for (const field of errorFields) {
      if (field && errorMessages[field]) {
        if (!isNaN(field)) {
          message += ` — ${errorMessages[field]}`;
        } else {
          message += ` — ${field.charAt(0).toUpperCase()}${field.slice(1)}: ${errorMessages[field]}`;
        }
      }
    }
  }
  return message;
};

export const getErrorFields = (errorData?: any): any[] =>
  errorData?.fields ? Object.keys(errorData.fields) : [errorData?.field];

export const getErrorMessages = (errorData: any | null | undefined, errorMessage?: string | null) =>
  errorData?.fields ? errorData.fields : { [errorData?.field]: errorMessage };

export const formatErrorMessage = (message: string | string[]): string => {
  return Array.isArray(message) ? message.join(' .') : message;
};

export const getErrorType = (error: RequestError) =>
  isAxiosError(error) && error.response && error.response.data.errorType
    ? error.response.data.errorType
    : error.message;

export const isErrorOfType = (type: string, error: RequestError) => getErrorType(error) === type;

export const getErrorStatus = (error: RequestError) =>
  isAxiosError(error) && error.response && error.response.status
    ? error.response.status
    : undefined;

export const isErrorOfStatus = (status: number, error: RequestError) =>
  getErrorStatus(error) === status;

export const getErrorData = <T = any>(error: RequestError<T>) =>
  isAxiosError(error) && error.response && error.response.data.data
    ? error.response.data.data
    : undefined;

export const translateError = (intl: IntlShape, errorKeys: ErrorKeys, error?: RequestError) => {
  if (error) {
    const errorType = getErrorType(error);

    if (errorType && errorKeys[errorType]) {
      return intl.formatMessage({ id: errorKeys[errorType] });
    } else if (isAxiosError(error) && error.response && error.response.data.message) {
      return error.response.data.message;
    } else if (error.message) {
      return error.message;
    } else {
      return intl.formatMessage(errorMessages.server);
    }
  }
};

export const reduxErrorBanner =
  (error: RequestError, errorKeys: ErrorKeys, defaultMessage: MessageDescriptor): Thunk =>
  (dispatch) => {
    const errorType = getErrorType(error);
    if (errorType && errorKeys[errorType]) {
      dispatch(showNotification({ status: 'failure', message: { id: errorKeys[errorType] } }));
    } else {
      dispatch(showNotification({ status: 'failure', message: defaultMessage }));
    }
  };
