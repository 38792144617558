import { css } from '@emotion/core';
import { ThemeState } from 'models/Theme';
import font from './font';
import color from './color';
import easing from './easing';
import spacing from './spacing';

const typography = (theme: ThemeState) => {
  let linkColor;
  let linkHoverColor;
  let linkActiveColor;
  let headingColor;
  let olColor;
  switch (theme) {
    case 'dark':
      linkColor = color.lightBlue;
      linkHoverColor = color.xLightBlue;
      linkActiveColor = color.xxLightBlue;
      headingColor = color.white;
      olColor = color.lightBlue;
      break;
    default:
      linkColor = color.purple;
      linkHoverColor = color.medPurple;
      linkActiveColor = color.darkPurple;
      headingColor = color.black;
      olColor = color.purple;
      break;
  }

  return css`
    .h1 {
      font-family: ${font.family.heading};
      font-size: ${font.size.h1};
      line-height: 1.3;
      color: ${headingColor};
    }

    .h2 {
      font-weight: ${font.weight.black};
      font-size: ${font.size.h2};
      line-height: 1.2;
      color: ${headingColor};
    }

    .h3 {
      font-weight: ${font.weight.bold};
      font-size: ${font.size.h3};
      color: ${headingColor};
    }

    .h4 {
      font-weight: ${font.weight.bold};
      font-size: ${font.size.h4};
      color: ${headingColor};
    }

    .h5 {
      font-weight: ${font.weight.bold};
      font-size: ${font.size.h5};
      color: ${headingColor};
    }

    .h6 {
      font-weight: ${font.weight.bold};
      font-size: ${font.size.h6};
      color: ${headingColor};
    }

    .p {
      line-height: 1.9;
    }

    .p1 {
      font-size: ${font.size.p1};
    }

    .a {
      text-decoration: none;
      color: ${linkColor};
      transition: color ${easing.time} ${easing.main};
      &:hover {
        color: ${linkHoverColor};
      }
      &:active {
        color: ${linkActiveColor};
      }
    }

    .ol {
      counter-reset: ps-counter;
      list-style: none;
      padding-left: 3rem;
      li {
        counter-increment: ps-counter;
        position: relative;
        margin-bottom: ${spacing.xxs};
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: counter(ps-counter) '. ';
          font-weight: ${font.weight.bold};
          color: ${olColor};
          position: absolute;
          top: 0;
          left: -3rem;
        }
      }
    }
  `;
};

export default typography;
