import React, { Fragment } from 'react';

interface IPricingSidebarStep {
  step: number;
  currentStep: number;
}

const FormStep: React.FC<IPricingSidebarStep> = ({ step, currentStep, children }) => {
  if (step !== currentStep) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default FormStep;
