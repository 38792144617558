import styled from '@emotion/styled';
import styles from 'views/styles';

const TableFooter = styled.div`
  text-align: right;
  padding-top: 15px;
  padding-right: ${styles.spacing.grid};
`;

export default TableFooter;
