import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-switch' viewBox='0 0 32 32'>
      <path d='M24.584 12c-0.933-1.976-2.554-3.619-4.665-4.553-3.95-1.748-8.584-0.556-11.201 2.881l-0.76 1.009 3.434 2.587-7.473 1.050-1.056-7.513 3.012 2.27 0.721-1.003c3.988-5.286 11.504-6.342 16.795-2.36 1.977 1.485 3.366 3.462 4.125 5.633h-2.931z'></path>
      <path d='M7.853 21.333c0.961 1.726 2.47 3.151 4.379 3.996 3.95 1.748 8.584 0.556 11.201-2.881l0.76-1.009-3.433-2.587 7.473-1.050 1.056 7.513-3.012-2.27-0.721 1.003c-3.988 5.286-11.504 6.342-16.795 2.36-1.806-1.357-3.122-3.125-3.915-5.076h3.006z'></path>
    </symbol>

    <Global
      styles={css`
        .icon-switch {
          width: 3.2em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
