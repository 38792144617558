import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { Link } from 'react-router-dom';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';

interface BreadcrumbProps {
  className?: string;
  to?: string;
  onClick?: () => void;
  text: string;
  dataCy?: string;
}

const Breadcrumb = ({ className, to, onClick, text, dataCy }: BreadcrumbProps) => {
  const { classes } = useStyles();
  return (
    <Fragment>
      {to ? (
        <Link className={`p1 ${className || ''}`} css={classes.root} to={to} data-cy={dataCy}>
          <Icon css={classes.icon} name='arrow' />
          {text}
        </Link>
      ) : (
        <button
          className={`p1 ${className || ''}`}
          css={classes.root}
          onClick={onClick}
          data-cy={dataCy}>
          <Icon css={classes.icon} name='arrow' />
          {text}
        </button>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      cursor: 'pointer',
      padding: '0.4rem 0',
      fontFamily: styles.font.family.main,
      lineHeight: 1.5,
      color: styles.color.black,
      fontWeight: styles.font.weight.medium,
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
    },
    icon: {
      fontSize: '0.34rem',
      transform: 'rotate(90deg)',
      marginRight: styles.spacing.tiny,
      marginLeft: '-0.34rem',
    },
  },
  light: {
    root: {
      color: styles.color.black,
    },
  },
  dark: {
    root: {
      color: styles.color.xxLightGrey,
    },
  },
});

export default Breadcrumb;
