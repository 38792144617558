import type { Config } from 'tenants/config';

const config: Config = {
  name: 'Polyswarm',
  fullName: 'Polyswarm Pte. Ltd.',
  title: 'PolySwarm - Crowdsourced threat detection',
  description:
    'PolySwarm is a crowdsourced threat detection marketplace where security experts & AV companies compete to protect you.',
  author: '@PolySwarm',
  image: 'https://polyswarm.io/assets/images/social/twitter-card.png',
  enabledPages: 'all',
  disabledPages: [],
};

export default config;
