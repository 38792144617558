import accentureUS from './sites/accenture/custom-pages';
import { getTenant } from './utils';

const tenants = {
  accenture: accentureUS,
};

const currentTenants = getTenant() as keyof typeof tenants;
const customPages = tenants[currentTenants] || {};

export default customPages;
