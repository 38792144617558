import React, { useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { PricingPlan } from './types';
import styles from 'views/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'views/components/Icon';

type ComparisonTableProps = {
  plans: PricingPlan[];
};

export const ComparisonTable = ({ plans }: ComparisonTableProps) => {
  const { classes } = useStyles();
  const [showTable, setShowTable] = useState(false);

  return (
    <div css={classes.container}>
      <h2 css={classes.title} onClick={() => setShowTable(!showTable)}>
        Compare Plans
      </h2>
      <table css={[classes.table, !showTable && { display: 'none' }]}>
        <thead css={classes.tableHeader}>
          <tr>
            <th></th>
            {plans.map((plan) => (
              <th css={classes.mainColumns} key={plan.name}>
                {plan.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(plans[0].comparisonSections).map((sectionKey, sectionIdx) => (
            <>
              {!['polySwarmApi', 'polySwarmIntelligence'].includes(sectionKey) ? (
                <tr key={sectionKey} css={classes.groupStart}>
                  <td colSpan={plans.length + 1} css={classes.groupTitle}>
                    {sectionKey.replace(/([A-Z])/g, ' $1')}
                  </td>
                </tr>
              ) : null}
              {plans[0].comparisonSections[
                sectionKey as keyof PricingPlan['comparisonSections']
              ].map((feature, idx) => (
                <tr
                  key={feature.label}
                  css={[
                    classes.tableRow,
                    idx ===
                      plans[0].comparisonSections[
                        sectionKey as keyof PricingPlan['comparisonSections']
                      ].length -
                        1 &&
                      sectionIdx !== Object.keys(plans[0].comparisonSections).length - 1 &&
                      classes.groupEnd,
                  ]}
                >
                  <td
                    css={[
                      classes.tableCell,
                      classes.firstColumn,
                      feature.bold && classes.hightlight,
                    ]}
                  >
                    <div css={classes.cellInfo}>
                      {feature.label}
                      <Tooltip placement='top' title={feature.info || feature.label}>
                        <div css={classes.featureIcon}>
                          <Icon css={classes.helpIcon} name='info' />
                        </div>
                      </Tooltip>
                    </div>
                  </td>
                  {plans.map((plan) => {
                    const featureData =
                      plan.comparisonSections[
                        sectionKey as keyof PricingPlan['comparisonSections']
                      ][idx];
                    return (
                      <td key={plan.name} css={[classes.tableCell, feature.bold && classes.bold]}>
                        {featureData.included ? (
                          featureData.limit || <Icon name='check-alt' css={classes.tickIcon} />
                        ) : (
                          <Icon name='close' css={classes.closeIcon} />
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      padding: '4rem',
      borderRadius: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '4rem',
      fontFamily: styles.font.family.main,
    },
    title: {
      textAlign: 'center',
      fontSize: '2.5rem',
      color: 'var(--main)',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    groupTitle: {
      fontSize: '1.7rem',
      color: 'var(--main)',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    hightlight: {
      fontSize: '1.7rem',
      color: 'var(--main)',
      fontWeight: 600,
      height: '8rem !important',
      display: 'flex',
      alignItems: 'flex-end',
      padding: '2rem 0',
    },
    bold: {
      fontWeight: 600,
    },
    firstColumn: {
      textAlign: 'right',
      width: '24rem',
      fontSize: '1.4rem',
      fontWeight: 600,
    },
    mainColumns: {
      textAlign: 'center',
      fontSize: '1.7rem',
      fontWeight: 600,
      paddingBottom: '2rem',
      color: 'var(--main)',
      borderBottom: '2px solid #e0e0e0',
    },
    table: {
      width: '100%',
    },
    tableHeader: {},
    groupStart: {
      height: '8rem !important',
      display: 'flex',
      alignItems: 'center',
    },
    groupEnd: {
      height: '6rem !important',
      borderBottom: '2px solid #e0e0e0',
    },
    tickIcon: {
      width: '0.9em !important',
      height: '0.9em !important',
      color: 'var(--main)',
      marginRight: '0.25rem',
    },
    closeIcon: {
      width: '0.9em !important',
      height: '0.9em !important',
      color: styles.color.red,
      marginRight: '0.25rem',
    },
    tableRow: {
      height: '4rem',
    },
    tableCell: {
      textAlign: 'center',
      fontSize: '1.4rem',
      fontWeight: 100,
    },
    cellInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    featureIcon: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'flex-end',
    },
    helpIcon: {
      cursor: 'help',
      fontSize: '0.8rem',
      paddingLeft: '1rem',
      position: 'relative',
      color: styles.color.xLightGrey,
    },
  },
  light: {
    container: {
      backgroundColor: styles.color.white,
    },
    tableCell: {
      color: styles.color.black,
    },
    firstColumn: {
      color: styles.color.black,
    },
  },
  dark: {
    container: {
      backgroundColor: styles.color.xDarkPurple,
    },
    tableCell: {
      color: styles.color.xLightGrey,
    },
    firstColumn: {
      color: styles.color.xLightGrey,
    },
  },
});
