import React from 'react';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Settings from 'views/components/Settings';

interface IAdvancedSettings {
  isPasswordUser?: boolean;
  isMfaEnabled: boolean;
  onMfaToggle: (event: React.ChangeEvent, checked: boolean) => void;
  onArchiveUser: () => void;
}

const messages = {
  mfaHeading: 'Secure Authentication',
  mfaText: 'Enable Two-Factor Authentication (2FA) for Secure Login',
  archiveHeading: 'Delete Account',
  archiveText: 'Permanently delete your PolySwarm account and information',
  archiveButton: 'Delete Account',
};

const AdvancedSettings = ({ isMfaEnabled, onMfaToggle, onArchiveUser }: IAdvancedSettings) => {
  return (
    <Settings
      groups={[
        {
          heading: messages.mfaHeading,
          isShown: true,
          settings: [
            {
              text: messages.mfaText,
              action: <Switch onChange={onMfaToggle} checked={isMfaEnabled} data-cy='mfaBtn' />,
            },
          ],
        },
        {
          heading: messages.archiveHeading,
          danger: true,
          settings: [
            {
              text: messages.archiveText,
              action: (
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={onArchiveUser}
                  data-cy='archiveUserBtn'
                >
                  {messages.archiveButton}
                </Button>
              ),
            },
          ],
        },
      ]}
    />
  );
};

export default AdvancedSettings;
