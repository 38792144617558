import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-arrow-solid' viewBox='0 0 51 32'>
      <path d='M4.525 10.925l18.812 18.812c1.25 1.25 3.276 1.25 4.525 0l18.812-18.812c2.499-2.499 2.499-6.552 0-9.051-1.2-1.2-2.828-1.875-4.525-1.875h-33.098c-3.535 0-6.4 2.865-6.4 6.4 0 1.697 0.674 3.325 1.875 4.525z' />
    </symbol>

    <Global
      styles={css`
        .icon-arrow-solid {
          width: 5.1em;
          height: 3.2em;
        }
      `}
    />
  </Fragment>
);
